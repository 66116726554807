import React, { Component } from "react";
import moment from "moment";
import * as DataTypes from "store/types";
import { showModal } from "store/app/actions";
import {
  MultiSelectInput,
  StarControl,
  AltitudeSelector,
  TextInput,
  RichTextInput,
} from "ui";
import { DropdownControl, Avatar } from "ui";
import { AuthService } from "utils/auth";
import TimeAgo from "timeago-react";
import { Link } from "react-router-dom";
import { Helpers } from "utils";
import { connect } from "react-redux";
import { createInsightsTraitsRequest } from "store/insight_trait/actions";
import {
  createInsightNoteRequest,
  deleteNoteRequest,
} from "store/insight/actions";
import { InsightConfidenceSelector } from "ui/insight_confidence/selector";
import { ShareInsight } from "./modals/share_insight";
import { updateInsightRequest } from "store/insight/actions";

const Altitudes = [
  {
    icon: "/img/altitudes/satellite.png",
    name: "satellite",
    description:
      "This insight informs us about behavioral economics principles that influence our customers' decision making.",
  },
  {
    icon: "/img/altitudes/jetstream.png",
    name: "jetstream",
    description:
      "This insight informs us about general customer personas, reinforcing broader assumptions about our customers.",
  },
  {
    icon: "/img/altitudes/summit.png",
    name: "summit",
    description:
      "This insight informs us about our customers' state of mind, helping us understand them as people.",
  },
  {
    icon: "/img/altitudes/basecamp.png",
    name: "basecamp",
    description:
      "This insight informs us about usability and design elements, helping us understand what our customers prefer to engage with.",
  },
  {
    icon: "/img/altitudes/groundlevel.png",
    name: "groundlevel",
    description:
      'This insight informs us about "what worked" in a test and is tactical in nature.',
  },
];

const Confidences = [
  {
    name: "Low",
    value: "low",
    description: "We're in early stages of building supporting evidence.",
  },
  {
    name: "Medium-Low",
    value: "medium_low",
    description: "We've got supporting evidence, but want more.",
  },
  {
    name: "Medium-High",
    value: "medium_high",
    description: "With just a little more evidence, we'll feel confident.",
  },
  {
    name: "High",
    value: "high",
    description:
      "We've proven this insight to be true and are confident in it.",
  },
];

interface IProps {
  insight: DataTypes.Insight | null;
  account: DataTypes.Account | null;
  traits: { [key: string]: Array<DataTypes.Trait> } | null;
  onDelete: (id: string) => void;
  onChange: (
    id: string,
    key: string,
    value: string | Array<any> | boolean | null
  ) => void;
  createInsightsTraitsRequest: (
    insightId: string,
    traitName: string,
    values: string[]
  ) => void;
  createInsightNoteRequest: (insightId: string, value: string) => void;
  deleteNoteRequest: (noteId: string) => void;
  showModal: (component: React.ComponentType<any>, options: any) => void;
  updateInsightRequest: (options: any) => void;
}

class Aside extends Component<IProps> {
  private saveNote = (value: string) => {
    const { insight } = this.props;

    if (!insight || !value || !value.trim()) {
      return;
    }

    this.props.createInsightNoteRequest(insight.id, value);
  };

  private onChangeTrait = (
    insightId: string,
    traitName: any,
    value: string[]
  ) => {
    this.props.createInsightsTraitsRequest(insightId, traitName, value);
  };

  private deleteNote = (noteId: string | undefined) => {
    if (!noteId) {
      return;
    }

    this.props.deleteNoteRequest(noteId);
  };

  private shareInsight = () => {
    const { insight } = this.props;

    if (!insight) {
      return;
    }

    this.props.showModal(ShareInsight, {
      className: "Share",
      insight: insight,
      onSubmit: this.updateShare,
    });
  };

  private updateShare = (
    id: string,
    passwordProtected: boolean,
    password?: string
  ) => {
    if (passwordProtected && password) {
      this.props.updateInsightRequest({
        ...this.props.insight,
        ...{ password_protected: passwordProtected, password: password },
      });
    } else {
      this.props.updateInsightRequest({
        ...this.props.insight,
        ...{ password_protected: passwordProtected },
      });
    }
  };

  render() {
    const { insight, traits, onChange, onDelete, account } = this.props;
    if (!insight) {
      return null;
    }

    const user = insight.last_editor || null;
    const currentUser = AuthService.getActiveUser();
    const isReadOnly = currentUser.role === "read-only";

    const contributors = [...insight.contributors];

    return (
      <div className="Insight">
        <section className="header">
          <div className="left">
            <button
              key={["key", insight.id].join("_")}
              className={
                insight.key === true
                  ? "btn btn-link btn-key active"
                  : "btn btn-link btn-key"
              }
              type="button"
              onClick={() => onChange(insight.id, "key", !insight.key)}
            >
              <i className="fa fa-key" />
              key insight
            </button>
          </div>
          <div className="right">
            <div className="controls">
              <StarControl
                key={["star", insight.id].join("_")}
                starred={insight.star}
                onClick={() => onChange(insight.id, "star", !insight.star)}
              />
              {isReadOnly ? null : (
                <DropdownControl
                  controlItems={[
                    {
                      name: "Share Insight",
                      icon: "fas fa-share-alt",
                      onClick: () => this.shareInsight(),
                    },
                    {
                      name: "Delete Insight",
                      icon: "far fa-trash-alt",
                      onClick: () => onDelete(insight.id as string),
                      className: "danger",
                    },
                  ]}
                />
              )}
            </div>
          </div>
        </section>

        <section className="details">
          <div className="insight">
            <div className="bar"></div>
            <TextInput
              key={["value_name", insight.id].join("_")}
              value={insight.value || ""}
              onChange={(value) =>
                this.props.onChange(insight.id || "", "value", value)
              }
            >
              <h1>{insight.value}</h1>
            </TextInput>
          </div>

          <div className="userInfo">
            <div className="img">
              <div className="avatar-stack">
                {contributors.map((user, idx) => {
                  const left = 8 * idx - (contributors.length === 1 ? 8 : 16);
                  return (
                    <div
                      style={{ position: "relative", left: `${left}px` }}
                      key={`user_${user.id}`}
                    >
                      <Avatar user={user} showHover={true} />
                    </div>
                  );
                })}

                {(insight.author || insight.is_illuminate_created) && (
                  <div style={{ position: "relative" }}>
                    <Avatar
                      isIlluminate={insight.is_illuminate_created}
                      user={insight.author}
                      showHover={true}
                      className="focus author"
                    />
                  </div>
                )}
              </div>
            </div>

            <div className="desc">
              <p className="name">
                {user !== null ? (
                  <span>
                    Last updated by {user.first_name} {user.last_name}
                  </span>
                ) : (
                  <span>Last updated</span>
                )}
              </p>
              <p>{moment(insight.updated_at).format("MMMM Do YYYY")}</p>
            </div>
          </div>
        </section>

        <hr className="dashed" />

        {account && account.settings.use_bb_methods && (
          <React.Fragment>
            <section className="attributes">
              <div className="attr">
                <label>
                  Insight Confidence<sup>&reg;</sup> Customer Insights Tool
                </label>

                <InsightConfidenceSelector
                  key={["altitude", insight.id].join("_")}
                  confidences={Confidences}
                  value={insight.confidence}
                  name="altitude"
                  onChange={(value) =>
                    onChange(insight.id, "confidence", value)
                  }
                />
              </div>
            </section>

            <hr className="dashed" />
          </React.Fragment>
        )}

        <section className="attributes">
          {account && account.settings.use_bb_methods && (
            <div className="attr">
              <label>
                Insight Altitude<sup>&reg;</sup> Customer Insights Tool
              </label>
              <AltitudeSelector
                key={["altitude", insight.id].join("_")}
                altitudes={Altitudes}
                value={insight.altitude}
                name="altitude"
                onChange={(value) => onChange(insight.id, "altitude", value)}
              />
            </div>
          )}

          {account && account.settings.brands && (
            <div className="attr">
              <label>brands</label>
              <MultiSelectInput
                key={["brands", insight.id].join("_")}
                activeItems={insight.brands || []}
                name="brands"
                items={
                  traits && traits["brands"]
                    ? Helpers.uniqueArray(
                        traits["brands"].map((t) => {
                          return t.name;
                        })
                      )
                    : []
                }
                onChange={(values) =>
                  this.onChangeTrait(insight.id, "brands", values)
                }
              />
            </div>
          )}
          {account && account.settings.teams && (
            <div className="attr">
              <label>teams</label>
              <MultiSelectInput
                key={["teams", insight.id].join("_")}
                activeItems={insight.teams || []}
                name="teams"
                items={
                  traits && traits["teams"]
                    ? Helpers.uniqueArray(
                        traits["teams"].map((t) => {
                          return t.name;
                        })
                      )
                    : []
                }
                onChange={(values) =>
                  this.onChangeTrait(insight.id, "teams", values)
                }
              />
            </div>
          )}
          {account && account.settings.channels && (
            <div className="attr">
              <label>Channels</label>
              <MultiSelectInput
                key={["channels", insight.id].join("_")}
                activeItems={insight.channels || []}
                name="channels"
                items={
                  traits && traits["channels"]
                    ? Helpers.uniqueArray(
                        traits["channels"].map((t) => {
                          return t.name;
                        })
                      )
                    : []
                }
                onChange={(values) =>
                  this.onChangeTrait(insight.id, "channels", values)
                }
              />
            </div>
          )}
          <div className="attr">
            <label>tags</label>
            <MultiSelectInput
              key={["tags", insight.id].join("_")}
              activeItems={insight.tags}
              name="tags"
              items={
                traits && traits["tags"]
                  ? Helpers.uniqueArray(
                      traits["tags"].map((t) => {
                        return t.name;
                      })
                    )
                  : []
              }
              onChange={(values) =>
                this.onChangeTrait(insight.id, "tags", values)
              }
            />
          </div>
          <div className="attr">
            <label>supporting evidence</label>
            <ul className="Experiments">
              {insight.experiments.map((experiment) => {
                return (
                  <li key={`experiment_${experiment.id}`}>
                    <div className="body">
                      <p className="title">
                        <Link to={`/tests/${experiment.id}`}>
                          {experiment.name}
                        </Link>
                      </p>
                    </div>
                  </li>
                );
              })}

              {insight.uxrs.map((uxr) => {
                return (
                  <li key={`experiment_${uxr.id}`}>
                    <div className="body">
                      <p className="title">
                        <Link to={`/uxr/${uxr.id}`}>{uxr.name}</Link>
                      </p>
                    </div>
                  </li>
                );
              })}
            </ul>
          </div>
          {insight.author && (
            <div className="attr">
              <label>Creator</label>
              <p>
                {insight.is_illuminate_created
                  ? "illuminate"
                  : `${insight.author.first_name} ${insight.author.last_name}`}
              </p>
            </div>
          )}
          <div className="attr">
            <label>notes</label>
            <ul className="Notes">
              {insight.notes.map((note) => {
                return (
                  <li key={`note_${note.id}`}>
                    <div>
                      <Avatar
                        isIlluminate={note.is_illuminate_created}
                        user={note.user}
                      />
                    </div>
                    <div className="body">
                      <p>{note.value}</p>
                      <p className="date">
                        {note.updated_at && (
                          <TimeAgo datetime={note.updated_at} live={false} />
                        )}
                      </p>
                    </div>
                    <div className="controls">
                      <DropdownControl
                        controlItems={[
                          {
                            name: "Delete",
                            icon: "far fa-trash-alt",
                            onClick: () => this.deleteNote(note.id),
                            className: "danger",
                          },
                        ]}
                      />
                    </div>
                  </li>
                );
              })}
            </ul>
            {isReadOnly ? null : (
              <div
                className={
                  insight.notes.length === 0 ? "AddNote no-notes" : "AddNote"
                }
              >
                <Avatar user={currentUser} />
                <RichTextInput
                  value={""}
                  onChange={(value) => this.saveNote(value)}
                  placeholder="Write a note about this insight..."
                />
              </div>
            )}
          </div>
        </section>
      </div>
    );
  }
}

const mapStateToProps = ({ router }: DataTypes.ApplicationState) => ({
  router,
});

const mapDispatchToProps = {
  createInsightsTraitsRequest,
  createInsightNoteRequest,
  deleteNoteRequest,
  showModal,
  updateInsightRequest,
};

const connectedPage = connect(mapStateToProps, mapDispatchToProps)(Aside);

export { connectedPage as Aside };
