import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import * as API from "../../api";
import * as Actions from "./actions";
import { ActionTypes } from "./types";
import * as AccountActions from "../account/actions";
import * as UxrTraits from "../uxr_trait/actions";
import * as AppTypes from "../app/types";
import { history } from "utils";

function* initUxrCreate(action: any) {
  try {
    const { traits, apps } = yield all({
      traits: call(API.Account.traits),
      apps: call(API.Apps.index),
    });
    yield all([
      put(AccountActions.getAccountTraitsSuccess(traits.body)),
      put(AccountActions.getAccountAppsSuccess(apps.body)),
      put(Actions.initUxrCreateSuccess()),
    ]);
  } catch (error: any) {
    yield put(Actions.initUxrCreateError(error));
  }
}

function* createUxr(action: any) {
  try {
    // @ts-ignore
    const res = yield call(API.Uxrs.create, action.payload.body);
    yield put(AccountActions.getAccountSummaryRequest());
    yield put(AccountActions.getAccountTraitsRequestSaga());
    yield put(Actions.createUxrSuccess(res.body));

    history.push("/uxr");
    yield put({
      type: AppTypes.App.ALERT_SHOW,
      content: "Ux Research created successfully",
    });
  } catch (error: any) {
    yield put(Actions.createUxrError(error));
  }
}

function* updateUxr(action: any) {
  try {
    // @ts-ignore
    const res = yield call(
      API.Uxrs.update,
      action.payload.id,
      action.payload.body
    );
    yield put(AccountActions.getAccountTraitsRequestSaga());

    yield put(Actions.updateUxrSuccess(res.body));

    yield put({
      type: AppTypes.App.ALERT_SHOW,
      content: "UX Research updated successfully",
    });
  } catch (error: any) {
    yield put(Actions.updateUxrError(error));
  }
}

function* addImage(action: any) {
  try {
    const { uxrId, file } = action.payload;

    // @ts-ignore
    const res = yield call(API.Uxrs.addImage, uxrId, file);

    yield put(Actions.addImageSuccess(res.body));
    yield put(Actions.updateUxrSuccess(res.body));
    yield put({
      type: AppTypes.App.ALERT_SHOW,
      content: "Image added successfully",
    });
  } catch (error: any) {
    yield put(Actions.addImageError(error));
  }
}

function* addDocument(action: any) {
  try {
    const { uxrId, file } = action.payload;

    // @ts-ignore
    const res = yield call(API.Uxrs.addDocument, uxrId, file);

    yield put(Actions.addDocumentSuccess(res.body));
    yield put(Actions.updateUxrSuccess(res.body));
    yield put({
      type: AppTypes.App.ALERT_SHOW,
      content: "Attachement added successfully",
    });
    yield put({ type: AppTypes.App.MODAL_HIDE });
  } catch (error: any) {
    yield put(Actions.addDocumentError(error));
  }
}

function* updateUpload(action: any) {
  try {
    const { uxrId, uploadId, body } = action.payload;

    // @ts-ignore
    const res = yield call(API.Uxrs.updateUpload, uxrId, uploadId, body);

    yield put(Actions.updateUploadSuccess(res.body));
    yield put(Actions.updateUxrSuccess(res.body));
    yield put({
      type: AppTypes.App.ALERT_SHOW,
      content: "Upload updated successfully",
    });
  } catch (error: any) {
    yield put(Actions.updateUploadError(error));
  }
}

function* updateSupportingDataUpload(action: any) {
  try {
    const { uxrId, supportingDataId, uploadId, body } = action.payload;

    // @ts-ignore
    const res = yield call(
      API.Uxrs.updateSupportingDataUpload,
      uxrId,
      supportingDataId,
      uploadId,
      body
    );

    yield put(Actions.updateSupportingDataUploadSuccess(res.body));
    yield put(Actions.updateUxrSuccess(res.body));
    yield put({
      type: AppTypes.App.ALERT_SHOW,
      content: "Upload updated successfully",
    });
  } catch (error: any) {
    yield put(Actions.updateSupportingDataUploadError(error));
  }
}

function* deleteUxr(action: any) {
  try {
    yield put({ type: AppTypes.App.MODAL_SAVING });
    // @ts-ignore
    const res = yield call(API.Uxrs.destroy, action.payload.id);
    yield put(UxrTraits.getUxrsTraitRequestSaga());
    yield put(AccountActions.getAccountSummaryRequest());
    yield put(Actions.deleteUxrSuccess(res.body));
    yield put({ type: AppTypes.App.MODAL_HIDE });
    history.push("/uxr");

    yield put({
      type: AppTypes.App.ALERT_SHOW,
      content: "UX Research deleted successfully",
    });
  } catch (error: any) {
    yield put(Actions.deleteUxrError(error));
  }
}

function* addUploadComment(action: any) {
  try {
    const { uxrId, uploadId, body } = action.payload;

    // @ts-ignore
    const res = yield call(API.Uxrs.addUploadComment, uxrId, uploadId, body);

    yield put(Actions.addUploadCommentSuccess(res.body));
    yield put(Actions.updateUxrSuccess(res.body));
    yield put({
      type: AppTypes.App.ALERT_SHOW,
      content: "Comment added successfully",
    });
  } catch (error: any) {
    yield put(Actions.addUploadCommentError(error));
  }
}

function* addSupportingDataUploadComment(action: any) {
  try {
    const { uxrId, supportingDataId, uploadId, body } = action.payload;

    // @ts-ignore
    const res = yield call(
      API.Uxrs.addSupportingDataUploadComment,
      uxrId,
      supportingDataId,
      uploadId,
      body
    );

    yield put(Actions.addSupportingDataUploadCommentSuccess(res.body));
    yield put(Actions.updateUxrSuccess(res.body));
    yield put({
      type: AppTypes.App.ALERT_SHOW,
      content: "Comment added successfully",
    });
  } catch (error: any) {
    yield put(Actions.addSupportingDataUploadCommentError(error));
  }
}

function* getUxrs(action: any) {
  try {
    // @ts-ignore
    const res = yield call(API.Uxrs.index, action.payload.options);
    const append =
      action.payload.scrollOpts && action.payload.scrollOpts.append;
    yield put(Actions.getUxrsSuccess(res.body, append));
  } catch (error: any) {
    yield put(Actions.getUxrsError(error));
  }
}

function* showUxr(action: any) {
  try {
    const { id } = action.payload;
    // @ts-ignore
    const res = yield call(API.Uxrs.show, id);
    yield put(Actions.showUxrSuccess(res.body));

    if (action.payload.callback) {
      action.payload.callback(res.body);
    }
  } catch (error: any) {
    yield put(Actions.showUxrError(error));
  }
}

function* clearUxr(action: any) {
  try {
    yield put(Actions.clearIdeaSuccess());
  } catch (error: any) {
    yield put(Actions.clearIdeaError(error));
  }
}

function* reorderUxr(action: any) {
  try {
    const { id, position, currentOrder } = action.payload;

    // @ts-ignore
    const res = yield call(API.Uxrs.reorder, id, position + 1);
    yield put(Actions.reorderUxrSuccess(res.body, currentOrder));
  } catch (error: any) {
    yield put(Actions.reorderUxrError(error));
  }
}

function* deleteUpload(action: any) {
  try {
    const { uxrId, uploadId } = action.payload;

    // @ts-ignore
    const res = yield call(API.Uxrs.deleteUpload, uxrId, uploadId);

    yield put(Actions.deleteUploadSuccess(res.body));
    yield put(Actions.updateUxrSuccess(res.body));
    yield put({
      type: AppTypes.App.ALERT_SHOW,
      content: "Attatchement deleted successfully",
    });
  } catch (error: any) {
    yield put({
      type: AppTypes.App.ALERT_SHOW,
      content: "Something went wrong, try again later",
      error: true,
    });
    yield put(Actions.deleteUploadError(error));
  }
}

function* deleteDocument(action: any) {
  try {
    const { uxrId, uploadId } = action.payload;

    // @ts-ignore
    const res = yield call(API.Uxrs.deleteDocument, uxrId, uploadId);

    yield put(Actions.deleteDocumentSuccess(res.body));
    yield put(Actions.updateUxrSuccess(res.body));
    yield put({
      type: AppTypes.App.ALERT_SHOW,
      content: "Attatchement deleted successfully",
    });
    yield put({ type: AppTypes.App.MODAL_HIDE });
  } catch (error: any) {
    yield put({
      type: AppTypes.App.ALERT_SHOW,
      content: "Something went wrong, try again later",
      error: true,
    });
    yield put(Actions.deleteDocumentError(error));
  }
}

function* addAreaOfFocus(action: any) {
  try {
    const { uxrId, callback } = action.payload;

    // @ts-ignore
    const res = yield call(API.Uxrs.createAreaOfFocus, uxrId);

    yield put(Actions.addAreaOfFocusSuccess(res.body));

    yield put(Actions.updateUxrSuccess(res.body));
    yield put({
      type: AppTypes.App.ALERT_SHOW,
      content: "Area of Focus created successfully",
    });

    if (callback) {
      const { area_of_focus: areasOfFocus } = res.body;

      callback(areasOfFocus[areasOfFocus.length - 1]);
    }
  } catch (error: any) {
    yield put(Actions.addAreaOfFocusError(error));
  }
}

function* updateAreaOfFocus(action: any) {
  try {
    // @ts-ignore
    const res = yield call(
      API.Uxrs.updateAreaOfFocus,
      action.payload.uxrId,
      action.payload.areaId,
      action.payload.body
    );

    yield put(Actions.updateAreaOfFocusSuccess(res.body));

    yield put(Actions.updateUxrSuccess(res.body));
    yield put({
      type: AppTypes.App.ALERT_SHOW,
      content: "Area of Focus updated successfully",
    });
  } catch (error: any) {
    yield put(Actions.updateAreaOfFocusError(error));
  }
}

function* deleteAreaOfFocus(action: any) {
  try {
    // @ts-ignore
    const res = yield call(
      API.Uxrs.deleteAreaOfFocus,
      action.payload.uxrId,
      action.payload.areaId
    );

    yield put(Actions.deleteAreaOfFocusSuccess(res.body));

    yield put(Actions.updateUxrSuccess(res.body));
    yield put({ type: AppTypes.App.MODAL_HIDE });

    yield put({
      type: AppTypes.App.ALERT_SHOW,
      content: "Area of Focus deleted successfully",
    });
  } catch (error: any) {
    yield put(Actions.deleteAreaOfFocusError(error));
  }
}

function* addSupportingData(action: any) {
  try {
    const { callback } = action.payload;

    // @ts-ignore
    const res = yield call(
      API.Uxrs.addSupportingData,
      action.payload.uxrId,
      action.payload.type
    );

    yield put(Actions.addSupportingDataSuccess(res.body));

    yield put(Actions.updateUxrSuccess(res.body));
    yield put({
      type: AppTypes.App.ALERT_SHOW,
      content: "Supporting data created successfully",
    });

    if (callback) {
      const { supporting_datas: supportinDatas } = res.body;

      callback(supportinDatas[supportinDatas.length - 1]);
    }
  } catch (error: any) {
    yield put(Actions.addSupportingDataError(error));
  }
}

function* updateSupportingData(action: any) {
  try {
    // @ts-ignore
    const res = yield call(
      API.Uxrs.updateSupportingData,
      action.payload.uxrId,
      action.payload.dataId,
      action.payload.body
    );

    yield put(Actions.updateSupportingDataSuccess(res.body));

    yield put(Actions.updateUxrSuccess(res.body));
    yield put({
      type: AppTypes.App.ALERT_SHOW,
      content: "Supporting data updated successfully",
    });
  } catch (error: any) {
    yield put(Actions.updateSupportingDataError(error));
  }
}

function* deleteSupportingData(action: any) {
  try {
    // @ts-ignore
    const res = yield call(
      API.Uxrs.deleteSupportingData,
      action.payload.uxrId,
      action.payload.dataId
    );

    yield put(Actions.deleteSupportingDataSuccess(res.body));

    yield put(Actions.updateUxrSuccess(res.body));
    yield put({ type: AppTypes.App.MODAL_HIDE });
    yield put({
      type: AppTypes.App.ALERT_SHOW,
      content: "Supporting data deleted successfully",
    });
  } catch (error: any) {
    yield put(Actions.deleteSupportingDataError(error));
  }
}

function* addSupportingDataImage(action: any) {
  try {
    const { uxrId, dataId, file } = action.payload;

    // @ts-ignore
    const res = yield call(
      API.Uxrs.addSupportingDataImage,
      uxrId,
      dataId,
      file
    );

    yield put(Actions.addSupportingDataImageSuccess(res.body));
    yield put(Actions.updateUxrSuccess(res.body));
    yield put({
      type: AppTypes.App.ALERT_SHOW,
      content: "Image added successfully",
    });
  } catch (error: any) {
    yield put(Actions.addSupportingDataImageError(error));
  }
}

function* deleteSupportingDataUpload(action: any) {
  try {
    const { uxrId, dataId, uploadId } = action.payload;

    // @ts-ignore
    const res = yield call(
      API.Uxrs.deleteSupportingDataUpload,
      uxrId,
      dataId,
      uploadId
    );

    yield put(Actions.deleteSupportingDataUploadSuccess(res.body));
    yield put(Actions.updateUxrSuccess(res.body));
    yield put({
      type: AppTypes.App.ALERT_SHOW,
      content: "Attatchement deleted successfully",
    });
  } catch (error: any) {
    yield put({
      type: AppTypes.App.ALERT_SHOW,
      content: "Something went wrong, try again later",
      error: true,
    });
    yield put(Actions.deleteSupportingDataUploadError(error));
  }
}

function* onLinkInsights(action: any) {
  try {
    yield put({ type: AppTypes.App.MODAL_SAVING });
    const { id, insights } = action.payload;

    const promises = insights.map((insight: any) =>
      call(API.Uxrs.addInsight, id, insight.id || "")
    );
    // @ts-ignore
    const resList = yield all(promises);

    yield put(Actions.onLinkInsightsSuccess(resList[0].body));
    yield put(Actions.updateUxrSuccess(resList[0].body));

    yield put({ type: AppTypes.App.MODAL_HIDE });
    yield put({
      type: AppTypes.App.ALERT_SHOW,
      content: "Insights added successfully",
    });
  } catch (error: any) {
    yield put(Actions.onLinkInsightsError(error));
  }
}

function* unlinkInsights(action: any) {
  try {
    const { id, insightId } = action.payload;

    // @ts-ignore
    const res = yield call(API.Uxrs.removeInsight, id, insightId);

    yield put(Actions.onUnLinkInsightsSuccess(res.body));
    yield put(Actions.updateUxrSuccess(res.body));

    yield put({ type: AppTypes.App.MODAL_HIDE });
    yield put({
      type: AppTypes.App.ALERT_SHOW,
      content: "Insight removed successfully",
    });
  } catch (error: any) {
    yield put(Actions.onUnLinkInsightsError(error));
  }
}

function* getShare(action: any) {
  try {
    const { id: uxrId } = action.payload;

    // @ts-ignore
    const res = yield call(API.Uxrs.createShare, uxrId);

    yield put(Actions.getShareSuccess(res.body));
  } catch (error: any) {
    yield put(Actions.getShareError(error));
  }
}

function* updateShare(action: any) {
  try {
    if (!action.payload.disableHideModal) {
      yield put({ type: AppTypes.App.MODAL_SAVING });
    }

    const { id: uxrId } = action.payload;
    const { id: shareId } = action.payload.body.share;

    // @ts-ignore
    const res = yield call(
      API.Uxrs.updateShare,
      uxrId,
      shareId,
      action.payload.body.share
    );

    yield put(Actions.updateShareSuccess(res.body));

    if (!action.payload.disableHideModal) {
      yield put({ type: AppTypes.App.MODAL_HIDE });
    }

    yield put({
      type: AppTypes.App.ALERT_SHOW,
      content: "Research share updated successfully",
    });
  } catch (error: any) {
    yield put(Actions.updateShareError(error));
  }
}

function* deleteUploadComment(action: any) {
  try {
    const { uxrId, uploadId, commentId } = action.payload;

    // @ts-ignore
    const res = yield call(
      API.Uxrs.deleteUploadComment,
      uxrId,
      uploadId,
      commentId
    );

    yield put(Actions.deleteUploadCommentSuccess(res.body));
    yield put(Actions.updateUxrSuccess(res.body));

    yield put({
      type: AppTypes.App.ALERT_SHOW,
      content: "Comment deleted successfully",
    });
  } catch (error: any) {
    yield put(Actions.deleteUploadCommentError(error));
  }
}

function* deleteSupportingDataUploadComment(action: any) {
  try {
    const { uxrId, supportingDataId, uploadId, commentId } = action.payload;

    // @ts-ignore
    const res = yield call(
      API.Uxrs.deleteSupportingDataUploadComment,
      uxrId,
      supportingDataId,
      uploadId,
      commentId
    );

    yield put(Actions.deleteSupportingDataUploadCommentSuccess(res.body));
    yield put(Actions.updateUxrSuccess(res.body));
    yield put({
      type: AppTypes.App.ALERT_SHOW,
      content: "Comment deleted successfully",
    });
  } catch (error: any) {
    yield put(Actions.deleteSupportingDataUploadCommentError(error));
  }
}

function* getShareShare(action: any) {
  try {
    // @ts-ignore
    let res = yield call(
      API.Share.uxr,
      action.payload.slug,
      action.payload.uuid,
      action.payload.password
    );

    yield put(Actions.getShareShareSuccess(res.body));
  } catch (error: any) {
    yield put(Actions.getShareShareError(error));
  }
}

function* uploadInsights(action: any) {
  try {
    const { uxrId, file } = action.payload;

    // @ts-ignore
    const res = yield call(API.Uxrs.uploadInsights, uxrId, file);

    yield put(Actions.uploadInsightsSuccess(res));
    yield put(Actions.updateUxrSuccess(res.body));
    yield put({ type: AppTypes.App.MODAL_HIDE });
    yield put({
      type: AppTypes.App.ALERT_SHOW,
      content: "Insights uploaded successfully",
    });
  } catch (error: any) {
    yield put({
      type: AppTypes.App.ALERT_SHOW,
      content: "Something went wrong, review your csv and try again.",
      error: true,
    });
    yield put(Actions.uploadInsightsError(error));
  }
}

function* watchDeleteSupportingDataUploadComment() {
  yield takeEvery(
    ActionTypes.DELETE_SUPPORTING_DATA_UPLOAD_COMMENT_REQUEST,
    deleteSupportingDataUploadComment
  );
}

function* watchAddSupportingDataUploadComment() {
  yield takeEvery(
    ActionTypes.ADD_SUPPORTING_DATA_UPLOAD_COMMENT_REQUEST,
    addSupportingDataUploadComment
  );
}

function* watchUpdateSupportingDataUpload() {
  yield takeEvery(
    ActionTypes.UPDATE_SUPPORTING_DATA_UPLOAD_REQUEST,
    updateSupportingDataUpload
  );
}

function* watchDeleteUploadComment() {
  yield takeEvery(
    ActionTypes.DELETE_UPLOAD_COMMENT_REQUEST,
    deleteUploadComment
  );
}

function* watchAddUploadComment() {
  yield takeEvery(ActionTypes.ADD_UPLOAD_COMMENT_REQUEST, addUploadComment);
}

function* watchUpdateUpload() {
  yield takeEvery(ActionTypes.UPDATE_UPLOAD_REQUEST, updateUpload);
}

function* watchGetShareShare() {
  yield takeEvery(ActionTypes.GET_SHARE_SHARE_REQUEST, getShareShare);
}

function* watchUpdateShare() {
  yield takeEvery(ActionTypes.UPDATE_SHARE_REQUEST, updateShare);
}

function* watchGetShare() {
  yield takeEvery(ActionTypes.GET_SHARE_REQUEST, getShare);
}

function* watchUnlinkInsights() {
  yield takeEvery(ActionTypes.UNLINK_INSIGHTS_REQUEST, unlinkInsights);
}

function* watchOnLinkInsights() {
  yield takeEvery(ActionTypes.LINK_INSIGHTS_REQUEST, onLinkInsights);
}

function* watchDeleteSupportingDataUpload() {
  yield takeEvery(
    ActionTypes.DELETE_SUPPORTING_DATA_UPLOAD_REQUEST,
    deleteSupportingDataUpload
  );
}

function* wathAddSupportingDataImage() {
  yield takeEvery(
    ActionTypes.ADD_SUPPORTING_DATA_IMAGE_REQUEST,
    addSupportingDataImage
  );
}

function* watchAddSupportingData() {
  yield takeEvery(ActionTypes.ADD_SUPPORTING_DATA_REQUEST, addSupportingData);
}

function* watchUpdateSupportingData() {
  yield takeEvery(
    ActionTypes.UPDATE_SUPPORTING_DATA_REQUEST,
    updateSupportingData
  );
}

function* watchDeleteSupportingData() {
  yield takeEvery(
    ActionTypes.DELETE_SUPPORTING_DATA_REQUEST,
    deleteSupportingData
  );
}

function* watchDeleteAreaOfFocus() {
  yield takeEvery(ActionTypes.DELETE_AREA_OF_FOCUS_REQUEST, deleteAreaOfFocus);
}

function* watchUpdateAreaOfFocus() {
  yield takeEvery(ActionTypes.UPDATE_AREA_OF_FOCUS_REQUEST, updateAreaOfFocus);
}

function* watchAddAreaOfFocus() {
  yield takeEvery(ActionTypes.ADD_AREA_OF_FOCUS_REQUEST, addAreaOfFocus);
}

function* watchDeleteUpload() {
  yield takeEvery(ActionTypes.DELETE_UPLOAD_REQUEST, deleteUpload);
}

function* watchInitUxrCreate() {
  yield takeEvery(ActionTypes.INIT_UXR_CREATE_REQUEST, initUxrCreate);
}

function* watchUxrCreate() {
  yield takeEvery(ActionTypes.CREATE_UXR_REQUEST, createUxr);
}

function* watchUxrUpdate() {
  yield takeEvery(ActionTypes.UPDATE_UXR_REQUEST, updateUxr);
}

function* watchUxrDelete() {
  yield takeEvery(ActionTypes.DELETE_UXR_REQUEST, deleteUxr);
}

function* watchGetUxrs() {
  yield takeEvery(ActionTypes.GET_UXRS_REQUEST, getUxrs);
}

function* watchShowUxr() {
  yield takeEvery(ActionTypes.SHOW_UXR_REQUEST, showUxr);
}

function* watchClearUxr() {
  yield takeEvery(ActionTypes.CLEAR_UXR_REQUEST, clearUxr);
}

function* watchReorderUxr() {
  yield takeEvery(ActionTypes.REORDER_REQUEST, reorderUxr);
}

function* watchAddImage() {
  yield takeEvery(ActionTypes.ADD_IMAGE_REQUEST, addImage);
}

function* watchAddDocument() {
  yield takeEvery(ActionTypes.ADD_DOCUMENT_REQUEST, addDocument);
}

function* watchDeleteDocument() {
  yield takeEvery(ActionTypes.DELETE_DOCUMENT_REQUEST, deleteDocument);
}

function* watchUploadInsights() {
  yield takeEvery(ActionTypes.UPLOAD_INSIGHTS_REQUEST, uploadInsights);
}

function* uxrsSaga() {
  yield all([
    fork(watchDeleteSupportingDataUploadComment),
    fork(watchAddSupportingDataUploadComment),
    fork(watchUpdateSupportingDataUpload),
    fork(watchDeleteUploadComment),
    fork(watchAddUploadComment),
    fork(watchUpdateUpload),
    fork(watchGetShareShare),
    fork(watchUpdateShare),
    fork(watchGetShare),
    fork(watchUnlinkInsights),
    fork(watchOnLinkInsights),
    fork(watchDeleteSupportingDataUpload),
    fork(wathAddSupportingDataImage),
    fork(watchUpdateSupportingData),
    fork(watchDeleteSupportingData),
    fork(watchAddSupportingData),
    fork(watchDeleteAreaOfFocus),
    fork(watchUpdateAreaOfFocus),
    fork(watchAddAreaOfFocus),
    fork(watchDeleteUpload),
    fork(watchGetUxrs),
    fork(watchInitUxrCreate),
    fork(watchUxrCreate),
    fork(watchUxrUpdate),
    fork(watchUxrDelete),
    fork(watchShowUxr),
    fork(watchClearUxr),
    fork(watchReorderUxr),
    fork(watchAddImage),
    fork(watchAddDocument),
    fork(watchDeleteDocument),
    fork(watchUploadInsights),
  ]);
}

export default uxrsSaga;
