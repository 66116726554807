import React from 'react';

interface IProps {
  show: boolean;
  position: { top: number; left: number };
  editorState: any;
  onToggle(type: string, style: any): void;
}

const INLINE_STYLES = [
  { label: 'B', icon: 'fas fa-bold', style: 'BOLD' },
  { label: 'I', icon: 'fas fa-italic', style: 'ITALIC' },
  { label: 'U', icon: 'fas fa-underline', style: 'UNDERLINE' }
];

const HEADER_STYLES = [
  { label: 'H1', icon: 'fas fa-heading', style: 'header-one' },
  { label: 'H2', icon: 'fas fa-heading', style: 'header-two' }
];

const LIST_STYLES = [
  { label: 'UL', icon: 'fas fa-list-ul', style: 'unordered-list-item' },
  { label: 'OL', icon: 'fas fa-list-ol', style: 'ordered-list-item' }
];

export const Toolbar = ({ show, position, editorState, onToggle }: IProps) => {
  const currentStyle = editorState.getCurrentInlineStyle();
  const contentState = editorState.getSelection();
  const anchorKey = contentState.getAnchorKey();

  const currentContentBlock = editorState.getCurrentContent().getBlockForKey(anchorKey);

  if (!show) return null;

  return (
    <div className="Toolbar" style={position}>
      <ul className="toolbar-items">
        {HEADER_STYLES.map(type => (
          <li
            key={type.label}
            className={`toolbar-item ${type.style.toLowerCase()} ${currentContentBlock.getType() === type.style ? 'active' : ''}`}
            onMouseDown={e => {
              e.preventDefault();
              onToggle('block', type.style);
            }}
          >
            <label>{type.label}</label>
          </li>
        ))}
      </ul>
      <ul className="toolbar-items">
        {INLINE_STYLES.map(type => (
          <li
            key={type.label}
            className={`toolbar-item ${type.style.toLowerCase()} ${currentStyle.has(type.style) ? 'active' : ''}`}
            onMouseDown={e => {
              e.preventDefault();
              onToggle('inline', type.style);
            }}
          >
            <i className={type.icon} />
          </li>
        ))}
      </ul>
      <ul className="toolbar-items">
        {LIST_STYLES.map(type => (
          <li
            key={type.label}
            className={`toolbar-item ${type.style.toLowerCase()} ${currentContentBlock.getType() === type.style ? 'active' : ''}`}
            onMouseDown={e => {
              e.preventDefault();
              onToggle('block', type.style);
            }}
          >
            <i className={type.icon} />
          </li>
        ))}
      </ul>
    </div>
  );
};
