import React, { Component } from 'react';
import * as DataTypes from 'store/types';

interface IProps {
  experiment: DataTypes.Experiment | null;
  saving: boolean;
  onSubmit(): void;
}

class BestInClass extends Component<IProps> {
  render() {
    return (
      <React.Fragment>
        <div className="header flex">
          <div className='mr__sm'>
            <i className='fas fa-trophy' style={{ color: "#f5a623", fontSize: '80px' }}></i>
          </div>

          <div>
            <h4 style={{ fontWeight: 300 }}>Best in Class</h4>
            <h3>Research</h3>
          </div>
        </div>
        <div style={{ maxWidth: '600px' }} className="mt__sm">
          <p>illuminate creates a Sharable Study Brief for your research with one click. No more decks! To ensure your study brief shines, here are some tips:</p>

          <ul style={{ listStyle: 'disc' }}>
            <li><p>Use the Summary to convey the inspiration, execution, results and next steps.</p></li>
            <li><p>Use rich text editor to <strong>bullet, bold and more.</strong></p></li>
            <li><p>Use the target icon in the image comment box to <strong>draw a box around notable elements in your screenshots.</strong></p></li>
            <li><p>You learned a ton. <strong>Roll those learnings up into Areas of Focus</strong> to help you consolidate your themes into an actionable story.</p></li>
            <li><p><strong>Attach your Supporting Data to Areas of Focus</strong> to match your proof with your themes.</p></li>
            <li><p><strong>Use the share icon</strong> in the sharable study to share your case study with anyone. They don’t even need an account.</p></li>
          </ul>

          <div className="flex justify-center align-center mt__sm">
            <a href="https://sandbox.illuminate.app/uxrs/50e527d" target="_blank">
              <img src="/img/best_in_class_uxr.png" alt="Best in Class image" className='shadow__full' height={'106px'} />
            </a>
            <a href="https://sandbox.illuminate.app/uxrs/50e527d" target='_blank' style={{ width: '30%', marginLeft: '24px' }}>Check out this live, best in class example to see it in action!</a>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export { BestInClass };
