import { action } from "typesafe-actions";
import { Dispatch } from "redux";
import { ActionTypes } from "./types";
import * as DataTypes from "../types";

interface RequestError {
  statusCode?: number | undefined;
  message: any;
}

export const showBannerPlacementRequest =
  (bannerPlacementSlug: string) => (dispatch: Dispatch) =>
    dispatch(
      action(ActionTypes.SHOW_BANNER_PLACEMENT_REQUEST, {
        bannerPlacementSlug,
      })
    );
export const showBannerPlacementSuccess = (
  response: DataTypes.ApiRequest<DataTypes.Banner>
) => action(ActionTypes.SHOW_BANNER_PLACEMENT_SUCCESS, { response });
export const showBannerPlacementError = (error: RequestError) =>
  action(ActionTypes.SHOW_BANNER_PLACEMENT_FAILURE, error);
