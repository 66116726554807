import { action } from "typesafe-actions";
import { Dispatch } from "redux";
import { ActionTypes } from "./types";
import * as DataTypes from "../types";
import { OrderDirection } from "ui/order_icon";

interface RequestError {
  statusCode?: number | undefined;
  message: any;
}

// Get Experiments
export const getExperimentsRequest =
  (options: DataTypes.ApiListOptions, scrollOpts?: { append: boolean }) =>
  (dispatch: Dispatch) =>
    dispatch(
      action(ActionTypes.GET_EXPERIMENTS_REQUEST, { options, scrollOpts })
    );
export const getExperimentsSuccess = (
  response: DataTypes.ApiList<DataTypes.Experiment>,
  append: boolean
) => action(ActionTypes.GET_EXPERIMENTS_SUCCESS, { response, append });
export const getExperimentsError = (error: RequestError) =>
  action(ActionTypes.GET_EXPERIMENTS_FAILURE, error);

// Get Experiment by id
export const getExperimentRequest = (id: string) => (dispatch: Dispatch) =>
  dispatch(action(ActionTypes.GET_EXPERIMENT_REQUEST, { id }));
export const getExperimentRequestAction = (id: string) =>
  action(ActionTypes.GET_EXPERIMENT_REQUEST, { id });
export const getExperimentSuccess = (
  response: DataTypes.ApiRequest<DataTypes.Experiment>
) => action(ActionTypes.GET_EXPERIMENT_SUCCESS, { response });
export const getExperimentError = (error: RequestError) =>
  action(ActionTypes.GET_EXPERIMENT_FAILURE, error);

// Init Experiment Create
export const initExpCreateRequest = () => (dispatch: Dispatch) =>
  dispatch(action(ActionTypes.INIT_EXP_CREATE_REQUEST, {}));
export const initExpCreateSuccess = () =>
  action(ActionTypes.INIT_EXP_CREATE_SUCCESS, {});
export const initExpCreateError = (error: RequestError) =>
  action(ActionTypes.INIT_EXP_CREATE_FAILURE, error);

// Create Experiment
export const createExperimentRequest = (body: any) => (dispatch: Dispatch) =>
  dispatch(action(ActionTypes.CREATE_EXPERIMENT_REQUEST, { body }));
export const createExperimentSuccess = (
  response: DataTypes.ApiRequest<DataTypes.Experiment>
) => action(ActionTypes.CREATE_EXPERIMENT_SUCCESS, { response });
export const createExperimentError = (error: RequestError) =>
  action(ActionTypes.CREATE_EXPERIMENT_FAILURE, error);

// Create Experiment
export const createABExperimentRequest = (body: any) => (dispatch: Dispatch) =>
  dispatch(action(ActionTypes.CREATE_EXPERIMENT_AB_REQUEST, { body }));
export const createABExperimentSuccess = (
  response: DataTypes.ApiRequest<DataTypes.Experiment>
) => action(ActionTypes.CREATE_EXPERIMENT_AB_SUCCESS, { response });
export const createABExperimentError = (error: RequestError) =>
  action(ActionTypes.CREATE_EXPERIMENT_AB_FAILURE, error);

export const createAdobeExperimentRequest =
  (body: any) => (dispatch: Dispatch) =>
    dispatch(action(ActionTypes.CREATE_EXPERIMENT_ADOBE_REQUEST, { body }));
export const createAdobeExperimentSuccess = (
  response: DataTypes.ApiRequest<DataTypes.Experiment>
) => action(ActionTypes.CREATE_EXPERIMENT_ADOBE_SUCCESS, { response });
export const createAdobeExperimentError = (error: RequestError) =>
  action(ActionTypes.CREATE_EXPERIMENT_ADOBE_FAILURE, error);

// Update Experiment by id
export const updateExperimentSuccess = (
  response: DataTypes.ApiRequest<DataTypes.Experiment>
) => action(ActionTypes.UPDATE_EXPERIMENT_SUCCESS, { response });
export const updateExperimentError = (error: RequestError) =>
  action(ActionTypes.UPDATE_EXPERIMENT_FAILURE, error);

// Update Experiment by id
export const updateABExperimentRequest =
  (id: string, body: any) => (dispatch: Dispatch) =>
    dispatch(action(ActionTypes.UPDATE_AB_EXPERIMENT_REQUEST, { id, body }));
export const updateABExperimentSuccess = (
  response: DataTypes.ApiRequest<DataTypes.Experiment>
) => action(ActionTypes.UPDATE_AB_EXPERIMENT_SUCCESS, { response });
export const updateABExperimentError = (error: RequestError) =>
  action(ActionTypes.UPDATE_AB_EXPERIMENT_FAILURE, error);

// Update Experiment by id
export const updateAdobeExperimentRequest =
  (id: string, body: any) => (dispatch: Dispatch) =>
    dispatch(action(ActionTypes.UPDATE_ADOBE_EXPERIMENT_REQUEST, { id, body }));
export const updateAdobeExperimentSuccess = (
  response: DataTypes.ApiRequest<DataTypes.Experiment>
) => action(ActionTypes.UPDATE_ADOBE_EXPERIMENT_SUCCESS, { response });
export const updateAdobeExperimentError = (error: RequestError) =>
  action(ActionTypes.UPDATE_ADOBE_EXPERIMENT_FAILURE, error);

// Delete Experiment by id
export const deleteExperimentRequest = (id: string) => (dispatch: Dispatch) =>
  dispatch(action(ActionTypes.DELETE_EXPERIMENT_REQUEST, { id }));
export const deleteExperimentSuccess = (
  response: DataTypes.ApiRequest<DataTypes.Experiment>
) => action(ActionTypes.DELETE_EXPERIMENT_SUCCESS, { response });
export const deleteExperimentError = (error: RequestError) =>
  action(ActionTypes.DELETE_EXPERIMENT_FAILURE, error);

// Delete Experience by id
export const deleteExperienceRequest =
  (id: string, body: any) => (dispatch: Dispatch) =>
    dispatch(action(ActionTypes.DELETE_EXPERIENCE_REQUEST, { id, body }));
export const deleteExperienceSuccess = (
  response: DataTypes.ApiRequest<DataTypes.Experiment>
) => action(ActionTypes.DELETE_EXPERIENCE_SUCCESS, { response });
export const deleteExperienceError = (error: RequestError) =>
  action(ActionTypes.DELETE_EXPERIENCE_FAILURE, error);

// Create Experiment Report
export const createReportRequest =
  (id: string, body: any, callback?: Function) => (dispatch: Dispatch) =>
    dispatch(action(ActionTypes.CREATE_REPORT_REQUEST, { id, body, callback }));
export const createReportSuccess = (
  response: DataTypes.ApiRequest<DataTypes.Experiment>
) => action(ActionTypes.CREATE_REPORT_SUCCESS, { response });
export const createReportError = (error: RequestError) =>
  action(ActionTypes.CREATE_REPORT_FAILURE, error);

export const onDeleteReportRequest =
  (experiment_id: string, report_id: string) => (dispatch: Dispatch) =>
    dispatch(
      action(ActionTypes.DELETE_REPORT_REQUEST, { experiment_id, report_id })
    );
export const onDeleteReportSuccess = (
  response: DataTypes.ApiRequest<DataTypes.Report>
) => action(ActionTypes.DELETE_REPORT_SUCCESS, { response });
export const onDeleteReportError = (error: RequestError) =>
  action(ActionTypes.DELETE_REPORT_FAILURE, error);

export const onUpdateReportStatsRequest =
  (experimentId: string, reportId: string) => (dispatch: Dispatch) =>
    dispatch(
      action(ActionTypes.UPDATE_REPORT_STATS_REQUEST, {
        experimentId,
        reportId,
      })
    );
export const onUpdateReportStatsSuccess = (
  response: DataTypes.ApiRequest<DataTypes.Report>
) => action(ActionTypes.UPDATE_REPORT_STATS_SUCCESS, { response });
export const onUpdateReportStatsError = (error: RequestError) =>
  action(ActionTypes.UPDATE_REPORT_STATS_FAILURE, error);

// Link Insights to Experiment
export const onLinkInsightsRequest =
  (id: string, insights: Array<DataTypes.Insight>) => (dispatch: Dispatch) =>
    dispatch(action(ActionTypes.LINK_INSIGHTS_REQUEST, { id, insights }));
export const onLinkInsightsSuccess = (
  response: DataTypes.ApiRequest<DataTypes.Experiment>
) => action(ActionTypes.LINK_INSIGHTS_SUCCESS, { response });
export const onLinkInsightsError = (error: RequestError) =>
  action(ActionTypes.LINK_INSIGHTS_FAILURE, error);

// Un Link Insights to Experiment
export const onUnLinkInsightsRequest =
  (id: string, insightId: string) => (dispatch: Dispatch) =>
    dispatch(action(ActionTypes.UNLINK_INSIGHTS_REQUEST, { id, insightId }));
export const onUnLinkInsightsSuccess = (
  response: DataTypes.ApiRequest<DataTypes.Experiment>
) => action(ActionTypes.UNLINK_INSIGHTS_SUCCESS, { response });
export const onUnLinkInsightsError = (error: RequestError) =>
  action(ActionTypes.UNLINK_INSIGHTS_FAILURE, error);

// Update Experiment Report
export const updateReportRequest =
  (id: string, report: DataTypes.Report) => (dispatch: Dispatch) =>
    dispatch(action(ActionTypes.UPDATE_REPORT_REQUEST, { id, report }));
export const updateReportSuccess = (
  response: DataTypes.ApiRequest<DataTypes.Experiment>
) => action(ActionTypes.UPDATE_REPORT_SUCCESS, { response });
export const updateReportError = (error: RequestError) =>
  action(ActionTypes.UPDATE_REPORT_FAILURE, error);

// Get Create Case Study by id
export const getCaseStudyRequest = (id: string) => (dispatch: Dispatch) =>
  dispatch(action(ActionTypes.GET_CASE_STUDY_REQUEST, { id }));
export const getCaseStudySuccess = (
  response: DataTypes.ApiRequest<DataTypes.Experiment>
) => action(ActionTypes.GET_CASE_STUDY_SUCCESS, { response });
export const getCaseStudyError = (error: RequestError) =>
  action(ActionTypes.GET_CASE_STUDY_FAILURE, error);

// Update Case Study by experiment id
export const updateCaseStudyRequest =
  (id: string, body: any, disableHideModal?: boolean) => (dispatch: Dispatch) =>
    dispatch(
      action(ActionTypes.UPDATE_CASE_STUDY_REQUEST, {
        id,
        body,
        disableHideModal,
      })
    );
export const updateCaseStudySuccess = (
  response: DataTypes.ApiRequest<DataTypes.Experiment>
) => action(ActionTypes.UPDATE_CASE_STUDY_SUCCESS, { response });
export const updateCaseStudyError = (error: RequestError) =>
  action(ActionTypes.UPDATE_CASE_STUDY_FAILURE, error);

// Get share case study
export const getShareCaseStudyRequest =
  (slug: string, uuid: string, password?: string) => (dispatch: Dispatch) =>
    dispatch(
      action(ActionTypes.GET_SHARE_CASE_STUDY_REQUEST, { slug, uuid, password })
    );
export const getShareCaseStudySuccess = (
  response: DataTypes.ApiRequest<DataTypes.Experiment>
) => action(ActionTypes.GET_SHARE_CASE_STUDY_SUCCESS, { response });
export const getShareCaseStudyError = (error: RequestError) =>
  action(ActionTypes.GET_SHARE_CASE_STUDY_FAILURE, error);

// Get share case study
export const onCreateReportNextStepRequest =
  (experiment: DataTypes.Experiment, reportType: string, step: number) =>
  (dispatch: Dispatch) =>
    dispatch(
      action(ActionTypes.CREATE_REPORT_NEXT_STEP_REQUEST, {
        experiment,
        reportType,
        step,
      })
    );
export const onCreateReportNextStepSuccess = () =>
  action(ActionTypes.CREATE_REPORT_NEXT_STEP_SUCCESS, {});
export const onCreateReportNextStepError = (error: RequestError) =>
  action(ActionTypes.CREATE_REPORT_NEXT_STEP_FAILURE, error);

// Pre upload report
export const preUploadReportRequest =
  (experiment: DataTypes.Experiment, file: File, callback: any) =>
  (dispatch: Dispatch) =>
    dispatch(
      action(ActionTypes.PRE_UPLOAD_REPORT_REQUEST, {
        experimentId: experiment.id,
        file,
        callback,
      })
    );
export const preUploadReportSuccess = () =>
  action(ActionTypes.PRE_UPLOAD_REPORT_SUCCESS, {});
export const preUploadReportError = (error: RequestError) =>
  action(ActionTypes.PRE_UPLOAD_REPORT_FAILURE, error);

// Pre upload report
export const uploadReportRequest =
  (
    experiment: DataTypes.Experiment,
    file: File,
    name: string,
    changedMetrics: any
  ) =>
  (dispatch: Dispatch) =>
    dispatch(
      action(ActionTypes.UPLOAD_REPORT_REQUEST, {
        experiment,
        file,
        name,
        changedMetrics,
      })
    );
export const uploadReportSuccess = () =>
  action(ActionTypes.UPLOAD_REPORT_SUCCESS, {});
export const uploadReportError = (error: RequestError) =>
  action(ActionTypes.UPLOAD_REPORT_FAILURE, error);

export const addUploadRequest =
  (experimentId: string, file: File) => (dispatch: Dispatch) =>
    dispatch(action(ActionTypes.ADD_UPLOAD_REQUEST, { experimentId, file }));
export const addUploadSuccess = (
  response: DataTypes.ApiRequest<DataTypes.Experiment>
) => action(ActionTypes.ADD_UPLOAD_SUCCESS, { response });
export const addUploadError = (error: RequestError) =>
  action(ActionTypes.ADD_UPLOAD_FAILURE, error);

export const deleteUploadRequest =
  (experimentId: string, uploadId: string) => (dispatch: Dispatch) =>
    dispatch(
      action(ActionTypes.DELETE_UPLOAD_REQUEST, { experimentId, uploadId })
    );
export const deleteUploadSuccess = (
  response: DataTypes.ApiRequest<DataTypes.Experiment>
) => action(ActionTypes.DELETE_UPLOAD_SUCCESS, { response });
export const deleteUploadError = (error: RequestError) =>
  action(ActionTypes.DELETE_UPLOAD_FAILURE, error);

export const clearExperiments = () => action(ActionTypes.CLEAR_EXPERIMENTS);

export const reorderExperimentRequest =
  (id: string, position: number, currentOrder: OrderDirection) =>
  (dispatch: Dispatch) =>
    dispatch(
      action(ActionTypes.REORDER_REQUEST, { id, position, currentOrder })
    );
export const reorderExperimentSuccess = (
  response: DataTypes.ApiList<DataTypes.Idea>,
  currentOrder: OrderDirection
) => action(ActionTypes.REORDER_SUCCESS, { response, currentOrder });
export const reorderExperimentError = (error: RequestError) =>
  action(ActionTypes.REORDER_FAILURE, error);

export const uploadInsightsRequest =
  (experimentId: string, file: any) => (dispatch: Dispatch) =>
    dispatch(
      action(ActionTypes.UPLOAD_INSIGHTS_REQUEST, { experimentId, file })
    );
export const uploadInsightsSuccess = (
  response: DataTypes.ApiRequest<DataTypes.Experiment>
) => action(ActionTypes.UPLOAD_INSIGHTS_SUCCESS, { response });
export const uploadInsightsError = (error: RequestError) =>
  action(ActionTypes.UPLOAD_INSIGHTS_FAILURE, error);

export const addProgramGoalRequest =
  (experimentId: string, program_goal: any) => (dispatch: Dispatch) =>
    dispatch(
      action(ActionTypes.ADD_PROGRAM_GOAL_REQUEST, {
        experimentId,
        program_goal,
      })
    );
export const addProgramGoalSuccess = (
  response: DataTypes.ApiRequest<DataTypes.Experiment>
) => action(ActionTypes.ADD_PROGRAM_GOAL_SUCCESS, { response });
export const addProgramGoalError = (error: RequestError) =>
  action(ActionTypes.ADD_PROGRAM_GOAL_FAILURE, error);

export const deleteProgramGoalRequest =
  (experimentId: string, program_goal_id: string) => (dispatch: Dispatch) =>
    dispatch(
      action(ActionTypes.DELETE_PROGRAM_GOAL_REQUEST, {
        experimentId,
        program_goal_id,
      })
    );
export const deleteProgramGoalSuccess = (
  response: DataTypes.ApiRequest<DataTypes.Experiment>
) => action(ActionTypes.DELETE_PROGRAM_GOAL_SUCCESS, { response });
export const deleteProgramGoalError = (error: RequestError) =>
  action(ActionTypes.DELETE_PROGRAM_GOAL_FAILURE, error);

export const updateProgramGoalRequest =
  (experimentId: string, program_goal_id: string, body: any) =>
  (dispatch: Dispatch) =>
    dispatch(
      action(ActionTypes.UPDATE_PROGRAM_GOAL_REQUEST, {
        experimentId,
        program_goal_id,
        body,
      })
    );
export const updateProgramGoalSuccess = (
  response: DataTypes.ApiRequest<DataTypes.Experiment>
) => action(ActionTypes.UPDATE_PROGRAM_GOAL_SUCCESS, { response });
export const updateProgramGoalError = (error: RequestError) =>
  action(ActionTypes.UPDATE_PROGRAM_GOAL_FAILURE, error);
