import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import * as API from "../../api";
import * as Actions from "./actions";
import { ActionTypes } from "./types";

function* getUserAccounts(action: any) {
  try {
    // @ts-ignore
    const res = yield call(API.User.accounts);

    yield put(Actions.getUserAccountSuccess(res.body));
  } catch (error: any) {
    yield put(Actions.getUserAccountError(error));
  }
}

function* watchGetUserAccounts() {
  yield takeEvery(ActionTypes.GET_USER_ACCOUNTS_REQUEST, getUserAccounts);
}

function* userSaga() {
  yield all([fork(watchGetUserAccounts)]);
}

export default userSaga;
