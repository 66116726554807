export enum ActionTypes {
  CLEAR_USER = '@@user/CLEAR_USER',
  LOGIN_REQUEST = '@@user/LOGIN_REQUEST',
  LOGIN_SUCCESS = '@@user/LOGIN_SUCCESS',
  LOGIN_FAILURE = '@@user/LOGIN_FAILURE',

  FORGOT_PWD_REQUEST = '@@user/FORGOT_PWD_REQUEST',
  FORGOT_PWD_SUCCESS = '@@user/FORGOT_PWD_SUCCESS',
  FORGOT_PWD_FAILURE = '@@user/FORGOT_PWD_FAILURE',

  RESET_PWD_REQUEST = '@@user/RESET_PWD_REQUEST',
  RESET_PWD_SUCCESS = '@@user/RESET_PWD_SUCCESS',
  RESET_PWD_FAILURE = '@@user/RESET_PWD_FAILURE',

  SIGNUP_REQUEST = '@@user/SIGNUP_REQUEST',
  SIGNUP_SUCCESS = '@@user/SIGNUP_SUCCESS',
  SIGNUP_FAILURE = '@@user/SIGNUP_FAILURE',

  INIT_INVITED_GOOGLE_REQUEST = '@@user/INIT_INVITED_GOOGLE_REQUEST',
  INIT_INVITED_GOOGLE_SUCCESS = '@@user/INIT_INVITED_GOOGLE_SUCCESS',
  INIT_INVITED_GOOGLE_FAILURE = '@@user/INIT_INVITED_GOOGLE_FAILURE',

  GOOGLE_SIGNUP_REQUEST = '@@user/GOOGLE_SIGNUP_REQUEST',
  GOOGLE_SIGNUP_SUCCESS = '@@user/GOOGLE_SIGNUP_SUCCESS',
  GOOGLE_SIGNUP_FAILURE = '@@user/GOOGLE_SIGNUP_FAILURE',

  LOGIN_GOOGLE_REQUEST = '@@user/LOGIN_GOOGLE_REQUEST',
  LOGIN_GOOGLE_SUCCESS = '@@user/LOGIN_GOOGLE_SUCCESS',
  LOGIN_GOOGLE_FAILURE = '@@user/LOGIN_GOOGLE_FAILURE',

  GET_USER_REQUEST = '@@user/GET_USER_REQUEST',
  GET_USER_SUCCESS = '@@user/GET_USER_SUCCESS',
  GET_USER_FAILURE = '@@user/GET_USER_FAILURE',

  UPDATE_PROFILE_REQUEST = '@@user/UPDATE_PROFILE_REQUEST',
  UPDATE_PROFILE_SUCCESS = '@@user/UPDATE_PROFILE_SUCCESS',
  UPDATE_PROFILE_FAILURE = '@@user/UPDATE_PROFILE_FAILURE',

  UPDATE_PROFILE_IMAGE_REQUEST = '@@user/UPDATE_PROFILE_IMAGE_REQUEST',
  UPDATE_PROFILE_IMAGE_SUCCESS = '@@user/UPDATE_PROFILE_IMAGE_SUCCESS',
  UPDATE_PROFILE_IMAGE_FAILURE = '@@user/UPDATE_PROFILE_IMAGE_FAILURE',

  UPDATE_PASSWORD_REQUEST = '@@user/UPDATE_PASSWORD_REQUEST',
  UPDATE_PASSWORD_SUCCESS = '@@user/UPDATE_PASSWORD_SUCCESS',
  UPDATE_PASSWORD_FAILURE = '@@user/UPDATE_PASSWORD_FAILURE',

  UPDATE_NOTIFICATIONS_REQUEST = '@@user/UPDATE_NOTIFICATIONS_REQUEST',
  UPDATE_NOTIFICATIONS_SUCCESS = '@@user/UPDATE_NOTIFICATIONS_SUCCESS',
  UPDATE_NOTIFICATIONS_FAILURE = '@@user/UPDATE_NOTIFICATIONS_FAILURE',

  CREATE_INVITED_USER_REQUEST = '@@user/CREATE_INVITED_USER_REQUEST',
  CREATE_INVITED_USER_SUCCESS = '@@user/CREATE_INVITED_USER_SUCCESS',
  CREATE_INVITED_USER_FAILURE = '@@user/CREATE_INVITED_USER_FAILURE',

  GET_INVITE_REQUEST = '@@invite/GET_INVITE_REQUEST',
  GET_INVITE_SUCCESS = '@@invite/GET_INVITE_SUCCESS',
  GET_INVITE_FAILURE = '@@invite/GET_INVITE_FAILURE'
}
