import { action } from 'typesafe-actions';
import { Dispatch } from 'redux';
import { ActionTypes } from './types';
import * as DataTypes from '../types';

interface RequestError {
  statusCode?: number | undefined;
  message: any;
}

export const updateReportRequest = (experimentId: string, reportId: string, body: any) => (dispatch: Dispatch) =>
  dispatch(action(ActionTypes.UPDATE_REPORT_REQUEST, { experimentId, reportId, body }));
export const updateReportSuccess = (response: DataTypes.ApiRequest<DataTypes.Experiment>) =>
  action(ActionTypes.UPDATE_REPORT_SUCCESS, { response });
export const updateReportError = (error: RequestError) =>
  action(ActionTypes.UPDATE_REPORT_FAILURE, error);