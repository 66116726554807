import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import * as API from "../../api";
import * as Actions from "./actions";
import * as ExperimentActions from "../experiment/actions";
import * as AppTypes from "../app/types";
import { ActionTypes } from "./types";
import { history } from "utils";

function* createReportSummary(action: any) {
  try {
    const { experimentId, body } = action.payload;

    // @ts-ignore
    const res = yield call(API.ReportSummary.create, experimentId, body);

    history.push(
      `/tests/${experimentId}/results/${
        res.body.reports[res.body.reports.length - 1].id
      }`
    );

    yield put(Actions.createReportSummarySuccess(res.body));
    yield put(ExperimentActions.updateExperimentSuccess(res.body));
    yield put({ type: AppTypes.App.MODAL_HIDE });
  } catch (error: any) {
    yield put(Actions.createReportSummaryError(error));
  }
}

function* watchCreateReportSummary() {
  yield takeEvery(
    ActionTypes.CREATE_REPORT_SUMMARY_REQUEST,
    createReportSummary
  );
}

function* reportSummarySaga() {
  yield all([fork(watchCreateReportSummary)]);
}

export default reportSummarySaga;
