import React, { Component } from "react";
import { createLoadingSelector } from "store/selectors";
import { ApplicationState } from "store/types";
import { connect } from "react-redux";
import * as DataTypes from 'store/types';

import {
  getApplicationRequest,
  createApplicationRequest,
  deleteApplicationRequest,
} from 'store/application/actions';
import { Form, ValidatorTypes, Input, Button } from "comps/form";

interface IProps {
  saving: boolean;
  // onCreate(body: any): void;
  loading: boolean;
  getApplicationRequest: () => void;
  createApplicationRequest: (body: DataTypes.Application) => void;
  deleteApplicationRequest: (id: string) => void;
  applications: DataTypes.ApiList<DataTypes.Application> | null;
  app: any;
}

interface IState {
  copied: boolean;
  application: DataTypes.Application | null;
  creating: boolean;
}

class ApiKeyModal extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      copied: false,
      application: null,
      creating: false,
    };
  }

  componentDidMount = () => {
    this.props.getApplicationRequest();
  }

  private onSubmit = (valid: boolean, dirty: boolean, data: any) => {
    if (valid) {
      const application = {
        name: data.name.value,
      }

      this.props.createApplicationRequest(application);
    }
  };

  private copyKey = () => {
    var copyText = document.getElementById('api_key') as HTMLInputElement;
    copyText.select();
    document.execCommand('Copy');
    this.setState({ ...this.state, copied: true });
  };

  buildApplicationMissing = () => {
    return (
      <div className="panels">
        <div className="left">
          <label>illuminate API</label>
          <div className="header">
            <img src="/img/apps/illuminate.png" alt="api key" />
            <h2>illuminate API</h2>
          </div>
          <p className="mt-2">Our API lets you connect your internal systems with illuminate.</p>

          <p className="mt-2">You can push information into illuminate from an internal system, and you can take information from illuminate to put it into your system.</p>

          <p className="mt-2">Keep in mind that this is a powerful tool and you must have development expertise to use it. Additionally, your API key provides access to all of your data and is highly confidential.</p>

          <p className="mt-2">If you are not familiar with how to use this feature, please seek expert advice before proceeding.</p>

          <p className="mt-2">Technical documentation can be found {this.linkToDocs()}.</p>
        </div>
        <div className="right">
          {this.buildForm()}
        </div>
      </div>
    );
  }

  linkToDocs = (text = 'here') => {
    return <a href={`${process.env.REACT_APP_API_HOST}/v1/api/docs`}>{text}</a>
  }

  buildApplication = (application: DataTypes.Application) => {
    const selectedApplication = this.state.application || this.props.applications?.data[0];

    return <li className={application.id === selectedApplication?.id && !this.state.creating ? 'active' : ''} key={application.id} onClick={() => {
      this.setState({
        creating: false,
        application,
      });
    }}>
      <h6 className="title">
        {application.name}
      </h6>
    </li>;
  }

  buildForm = () => {
    return <Form
    formFields={{
      name: {
        name: 'name',
        value: '',
        validator: { type: ValidatorTypes.REQUIRED, messages: { required: 'Please give your key a valid name' } },
      },
    }}
    onSubmit={this.onSubmit}
    FormComponent={({ fields: { name }, onChange }) => (
      <div>
        <div className="form-group">
          <label>Name</label>
          <Input field={name} className="form-control" onChange={(e) => onChange(name, e.target.value)} />
        </div>

        <Button className="btn btn-primary" text="save" />
      </div>
    )}
   />;
  }

  showApiKeyInfo = (application: DataTypes.Application | null) => {
    return <React.Fragment>
      <label>Name</label>
      <div className="input-group mb-3">
        <input
          id="name"
          type="text"
          className="form-control"
          readOnly={true}
          value={application?.name}
        />
      </div>

      <label>API Key</label>
      <div className="input-group mb-3">
        <input
          id="api_key"
          type="text"
          className="form-control"
          readOnly={true}
          onChange={() => this.setState({})}
          value={application?.key}
        />
        <div className="input-group-append">
          <button className={this.state.copied ? 'btn btn-success' : 'btn btn-primary'} type="button" onClick={this.copyKey}>
            {this.state.copied ? 'copied' : 'copy'}
          </button>
        </div>

        <Button className="btn btn-danger" text="Delete" onClick={() => this.props.deleteApplicationRequest(application?.id || '')}/>
        <p>Find the documentation {this.linkToDocs()}</p>
      </div>
    </React.Fragment>
  }

  buildApplicationFound = () => {
    let application = this.state.application;

    if (!application && this.props.applications?.data && this.props.applications?.data.length > 0) {
      application = this.props.applications?.data[0];
    }

    return (
      <div className="panels">
        <div className="left">
          <ul className="ListItem">
            <li className={this.state.creating ? 'active' : ''} onClick={() => this.setState({ creating: true })}>
              <h6 className="flex justify-between align-center">
                Create API Key
                <i className="fas fa-plus icon circular"></i>
              </h6>
            </li>
            {this.props.applications?.data?.map((application) =>
              this.buildApplication(application)
            )}
          </ul>
        </div>
        <div className="right top">
          {this.state.creating ? this.buildForm() : this.showApiKeyInfo(application)}
        </div>
      </div>
    );
  }

  buildLoading = () => {
    return <p>Loading...</p>;
  }

  render() {
    if (this.props.loading || this.props.saving) {
      return this.buildLoading();
    }

    if (this.props.applications?.data && this.props.applications?.data.length > 0) {
      return this.buildApplicationFound();
    }

    return this.buildApplicationMissing();
  }
}

const initLoader = createLoadingSelector(['@@application/GET_APPLICATION']);
const savingSelector = createLoadingSelector(['@@application/CREATE_APPLICATION']);

const mapStateToProps = ({ router, app, application }: ApplicationState) => ({
  loading: initLoader(app.requests),
  saving: savingSelector(app.requests),
  router,
  applications: application,
  app,
});

const mapDispatchToProps = {
  getApplicationRequest,
  createApplicationRequest,
  deleteApplicationRequest,
};

const connectedPage = connect(mapStateToProps, mapDispatchToProps)(ApiKeyModal);

export { connectedPage as ApiKeyModal };
