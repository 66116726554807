import React, { Component } from 'react';
import moment from 'moment';
import queryString from 'query-string';
import styled from 'styled-components';
import _ from 'lodash';
import { history } from 'utils';
import * as DataTypes from 'store/types';
import { Spacer } from 'comps';
import { Cell } from './cell';
import { DurationBar } from './duration_bar';

interface IProps {
  experiments?: Array<DataTypes.Experiment>;
  location: any;
  getExperimentsRequest: (options: DataTypes.ApiListOptions) => void;
  clearExperiments: () => void;
}
interface IState {
  daysInMonth: number;
  // difference in months from today
  monthDifferential: number;
  blockSize: number;
}

class Gantt extends Component<IProps, IState> {
  state: IState = { daysInMonth: moment().daysInMonth(), monthDifferential: 0, blockSize: 50 };
  chartRef: any = React.createRef<HTMLTableDataCellElement>();

  constructor(props: IProps) {
    super(props);
    this.setBlockSize = _.debounce(this.setBlockSize, 500, { leading: false, trailing: true });
  }

  componentDidMount() {
    const q = queryString.parse(this.props.location.search, { arrayFormat: 'bracket' });

    if (q.month) {
      const monthString: string = typeof q.month === 'string' ? q.month : '';
      const date = `${monthString.substring(0, 2)}-01${monthString.substring(2, 7)}`;
      if (date.length < 7) this.onMonthChange(0);
      else {
        const month = moment(date).diff(moment(`${this.getMonth() + 1}-01-${this.getYear()}`), 'months');
        this.onMonthChange(month);
      }
    } else this.onMonthChange(0);
    window.addEventListener('resize', this.setBlockSize);
    this.setBlockSize();
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.setBlockSize);
  }

  getBlockSize = (daysInMonth?: number) => {
    const chartWidth = this.chartRef?.offsetWidth;
    if (!chartWidth) return 0;
    const blockSize = chartWidth / (daysInMonth || this.state.daysInMonth);
    return blockSize;
  };

  setBlockSize = () => {
    const blockSize = this.getBlockSize();
    if (blockSize) this.setState({ blockSize });
  };

  getStartDay = (experiment: DataTypes.Experiment) => {
    const { startDate: date } = experiment;
    return moment(date).format('MM/DD/YYYY');
  };

  getEndDay = (experiment: DataTypes.Experiment) => {
    const { endDate: date } = experiment;
    return moment(date).format('MM/DD/YYYY');
  };

  getDateOffset = (start: string, end: string) => {
    const startMoment = moment(start);
    const endMoment = moment(end);
    const currentMonth = this.getMonth();
    if (endMoment.date() < startMoment.date()) {
      if (startMoment.month() !== currentMonth) return 1;
    }
    if (endMoment.diff(startMoment, 'months') > 0 && currentMonth === endMoment.month()) return 1;

    return startMoment.date();
  };

  getDuration = (experiment: DataTypes.Experiment) => {
    const { startDate, endDate } = experiment;
    const startMoment = moment(startDate);
    const endMoment = moment(endDate);
    const currentMonth = this.getMonth();
    // Case where experiment wraps the end of the month
    if (endMoment.date() < startMoment.date()) {
      if (startMoment.month() === currentMonth) return this.state.daysInMonth - startMoment.date();
      else return endMoment.date() - 1;
    }
    // Multi month case
    if (endMoment.diff(startMoment, 'months') > 0) {
      if (currentMonth === startMoment.month()) return this.state.daysInMonth - startMoment.date();
      if (currentMonth === endMoment.month()) return endMoment.date() - 1;
    }
    return endMoment.date() - startMoment.date();
  };

  setQuery = (q: any, date: moment.Moment) => {
    const qStr = queryString.stringify(q, { encode: true, arrayFormat: 'bracket' });
    history.push(`?${qStr}`);
  };

  // user pressed left or right on-screen arrow
  onMonthChange = (diff: number) => {
    const q = queryString.parse(this.props.location.search, { arrayFormat: 'bracket' });
    const date = moment().add(this.state.monthDifferential + diff, 'months');
    const lastDayOfMonth = date.daysInMonth();
    const month = date.format('MM-YYYY');
    this.props.clearExperiments();
    this.props.getExperimentsRequest({ q: q.q, month, limit: 100 });
    const blockSize = this.getBlockSize(lastDayOfMonth);
    this.setState({
      monthDifferential: this.state.monthDifferential + diff,
      daysInMonth: lastDayOfMonth,
      blockSize: blockSize || this.state.blockSize,
    });
    q.month = month;
    const qStr = queryString.stringify(q, { encode: true, arrayFormat: 'bracket' });
    history.push(`?${qStr}`);
  };

  getMoment = () => {
    return moment().add(this.state.monthDifferential, 'months');
  };

  getMonth = () => {
    const date = moment().add(this.state.monthDifferential, 'months');
    return date.month();
  };

  getYear = () => {
    const date = moment().add(this.state.monthDifferential, 'months');
    return date.year();
  };

  createDaysInMonth = () => {
    const days: Array<number> = [];
    days.length = this.state.daysInMonth;
    return days.fill(0).map((_, index) => index + 1);
  };

  render() {
    // sort experiments by increasing end date
    const monthlyExperiments =
      this.props.experiments?.sort((a, b) => {
        if (moment(a.end_date).valueOf() < moment(b.end_date).valueOf()) return -1;
        return 1;
      }) || [];

    const { monthDifferential } = this.state;
    const monthIndex = this.getMonth();
    const year = this.getYear();

    const DAYS = this.createDaysInMonth();
    const DAYS_SHIFTED = DAYS.slice(1, this.state.daysInMonth);
    const currentDay = moment().date();

    return (
      <StyledContainer className="local-container">
        <div className="header">
          <div className="navigator">
            <div onClick={() => this.onMonthChange(-1)} className="arrow">
              <i className="fas fa-caret-left"></i>
            </div>
            <div className="date">
              {displayMonth(monthIndex)} {year}
            </div>
            <div onClick={() => this.onMonthChange(1)} className="arrow">
              <i className="fas fa-caret-right"></i>
            </div>
          </div>
          <div className="legend">
            <div>
              <div className="win circle"></div>Win
            </div>
            <div>
              <div className="loss circle"></div>Loss
            </div>
            <div>
              <div className="flat circle"></div>Flat
            </div>
          </div>
        </div>
        <Spacer size={0.5} />
        <section className="gantt-chart">
          <table className="table">
            <thead>
              <tr className="number-line" ref={(ref: any) => (this.chartRef = ref)}>
                {DAYS.map((day) => (
                  <Cell key={day} className={currentDay === day && monthDifferential === 0 ? 'today' : 'day'}>
                    {day}
                  </Cell>
                ))}
              </tr>
            </thead>
            <tbody className="table-body">
              {monthlyExperiments.map((experiment) => {
                const startDay = this.getStartDay(experiment);
                const endDay = this.getEndDay(experiment);
                const duration = this.getDuration(experiment);
                return (
                  <tr key={experiment.id} style={{ position: 'relative', height: 90 }}>
                    <DurationBar
                      name={experiment.name}
                      start={startDay}
                      end={endDay}
                      duration={duration}
                      dateOffset={this.getDateOffset(experiment.startDate, experiment.endDate)}
                      status={experiment.status}
                      total={moment().add(monthDifferential, 'months').daysInMonth()}
                      link={`/tests/${experiment.id}`}
                      blockSize={this.state.blockSize}
                      star={experiment.star}
                    />
                    {DAYS_SHIFTED.map((day) => (
                      <Cell className={currentDay === day && monthDifferential === 0 ? 'today faded' : ''} style={{ borderTop: 0 }} key={day}></Cell>
                    ))}
                  </tr>
                );
              })}
              <StyledEmptyRow style={{ height: Math.max(110 * (6 - monthlyExperiments.length), 0), position: 'relative' }}>
                {DAYS.map((day) => (
                  <Cell className={currentDay === day && monthDifferential === 0 ? 'today faded' : ''} key={day}></Cell>
                ))}
              </StyledEmptyRow>
            </tbody>
          </table>
        </section>
      </StyledContainer>
    );
  }
}

const MONTH = {
  January: 0,
  February: 1,
  March: 2,
  April: 3,
  May: 4,
  June: 5,
  July: 6,
  August: 7,
  September: 8,
  October: 9,
  November: 10,
  December: 11,
};
const displayMonth = (monthIndex: number) => Object.keys(MONTH)[monthIndex];

const StyledContainer = styled.div`
  &.local-container {
    padding-top: 20px;
    width: calc(100% - 220px);

    .header {
      font-size: 20px;
      display: flex;
      justify-content: space-between;

      .navigator {
        display: table;
        width: 200px;
        position: relative;
        top: -4px;
        left: 20px;

        div {
          display: table-cell;
          text-align: center;
          vertical-align: middle;

          &.date {
            font-size: 18px;
          }

          &.arrow {
            width: 20px;
            color: #2a88f2;

            &:hover {
              cursor: pointer;
            }
          }
        }
      }

      .legend {
        display: flex;
        width: 240px;
        font-size: 12px;
        color: grey;

        div {
          display: flex;
          align-items: center;
          padding: 0 10px;
          .circle {
            margin: 0px 5px;
            padding: 4px;
            border-radius: 50%;
          }
          .win {
            background: #26be6f;
          }
          .loss {
            background: #ff5a71;
          }
          .flat {
            background: #f4b974;
          }
        }
      }
    }
    .gantt-chart {
      background: white;
      width: 100%;
      overflow-x: scroll;

      table {
        margin-bottom: 0;
        height: calc(100vh - 60px - 5px - 50px);
        border-top: 1px lightgrey solid;
        .day {
          background: #fbfbfb;
          color: #003f61;
          text-align: center;
        }
        .today {
          background: #2a88f2;
          color: white;
          text-align: center;
        }
        tr.number-line {
          font-weight: 500;
          position: relative;
          border-bottom: 1px lightgrey solid;
          font-size: 14px;
          max-height: 40px;
        }
      }
      @media only screen and (min-width: 1630px) {
        width: 100%;
      }
      .table-body {
        border-top: 1px lightgrey solid;
        .today.faded {
          background: #daecf0;
        }
        .experiment-row {
          height: 110px;
          min-width: 200px;
        }
      }
    }
  }

  tr td {
    border: 0;
  }
`;
const StyledEmptyRow = styled.tr`
  border-top: 0;
  border-bottom: 1px lightgrey solid;

  & td {
    border-top: 0;
  }
`;

export { Gantt };
