import React from 'react';
import styled from 'styled-components';

const StyledCircle: any = styled.div`
  background: ${(props: any) => props.background || '#232323'};
  width: 18px;
  height: 18px;
  border-radius: 50%;
  text-align: center;
  color: white;
  font-size: 10px;
  padding-top: 4px;
  margin: auto;
  font-weight: 500;
  line-height: 12px;
  text-indent: 1px;
`;

const StatusCircle = ({ style, className, status }: any) => {
  const getStatus = () => {
    if (status === 'winner') return { color: '#25BE6F', symbol: 'W' };
    if (status === 'loss') return { color: '#FF5A71', symbol: 'L' };
    if (status === 'flat') return { color: '#F4B974', symbol: 'F' };
    return {};
  };
  if (status === 'undecided') return null;
  const { color, symbol } = getStatus();
  return (
    <StyledCircle background={color} style={style} className={className || ''}>
      {symbol}
    </StyledCircle>
  );
};

export { StatusCircle };
