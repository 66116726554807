import { action } from "typesafe-actions";
import { Dispatch } from "redux";
import { ActionTypes } from "./types";
import * as DataTypes from "../types";
import { OrderDirection } from "ui/order_icon";

interface RequestError {
  statusCode?: number | undefined;
  message: any;
}

// Get Insights
export const getInsightsRequest =
  (options: DataTypes.ApiListOptions, scrollOpts?: { append: boolean }) =>
  (dispatch: Dispatch) =>
    dispatch(action(ActionTypes.GET_INSIGHTS_REQUEST, { options, scrollOpts }));
export const getInsightsSuccess = (
  response: DataTypes.ApiList<DataTypes.Insight>,
  append: boolean
) => action(ActionTypes.GET_INSIGHTS_SUCCESS, { response, append });
export const getInsightsError = (error: RequestError) =>
  action(ActionTypes.GET_INSIGHTS_FAILURE, error);

// Create Insights
export const createInsightRequest =
  (
    insight: DataTypes.InsightRequest,
    cb?: (insight: DataTypes.Insight) => void
  ) =>
  (dispatch: Dispatch) =>
    dispatch(action(ActionTypes.CREATE_INSIGHT_REQUEST, { insight, cb }));
export const createInsightSuccess = (
  response: DataTypes.ApiList<DataTypes.Insight>
) => action(ActionTypes.CREATE_INSIGHT_SUCCESS, { response });
export const createInsightError = (error: RequestError) =>
  action(ActionTypes.CREATE_INSIGHT_FAILURE, error);

// Update Insights
export const updateInsightRequest =
  (insight: DataTypes.Insight, showAlert?: boolean) => (dispatch: Dispatch) =>
    dispatch(
      action(ActionTypes.UPDATE_INSIGHT_REQUEST, { insight, showAlert })
    );
export const updateInsightSuccess = (
  response: DataTypes.ApiList<DataTypes.Insight>
) => action(ActionTypes.UPDATE_INSIGHT_SUCCESS, { response });
export const updateInsightError = (error: RequestError) =>
  action(ActionTypes.UPDATE_INSIGHT_FAILURE, error);

// Update Insights
export const deleteInsightRequest = (id: string) => (dispatch: Dispatch) =>
  dispatch(action(ActionTypes.DELETE_INSIGHT_REQUEST, { id }));
export const deleteInsightSuccess = (
  response: DataTypes.ApiList<DataTypes.Insight>
) => action(ActionTypes.DELETE_INSIGHT_SUCCESS, { response });
export const deleteInsightError = (error: RequestError) =>
  action(ActionTypes.DELETE_INSIGHT_FAILURE, error);

// Get Insights
export const getInsightsTraitsRequest = () => (dispatch: Dispatch) =>
  dispatch(action(ActionTypes.GET_INSIGHTS_TRAITS_REQUEST));
export const getInsightsTraitsSuccess = (
  response: DataTypes.ApiList<DataTypes.Insight>,
  append: boolean
) => action(ActionTypes.GET_INSIGHTS_TRAITS_SUCCESS, { response, append });
export const getInsightsTraitsError = (error: RequestError) =>
  action(ActionTypes.GET_INSIGHTS_TRAITS_FAILURE, error);

export const createInsightNoteRequest =
  (insightId: string, value: string) => (dispatch: Dispatch) =>
    dispatch(
      action(ActionTypes.CREATE_INSIGHT_NOTE_REQUEST, { insightId, value })
    );
export const createInsightNoteSuccess = (
  response: DataTypes.ApiList<DataTypes.Insight>
) => action(ActionTypes.CREATE_INSIGHT_NOTE_SUCCESS, { response });
export const createInsightNoteError = (error: RequestError) =>
  action(ActionTypes.CREATE_INSIGHT_NOTE_FAILURE, error);

export const deleteNoteRequest = (noteId: string) => (dispatch: Dispatch) =>
  dispatch(action(ActionTypes.DELETE_NOTE_REQUEST, { noteId }));
export const deleteNoteSuccess = (
  response: DataTypes.ApiList<DataTypes.Insight>
) => action(ActionTypes.DELETE_NOTE_SUCCESS, { response });
export const deleteNoteError = (error: RequestError) =>
  action(ActionTypes.DELETE_NOTE_FAILURE, error);

export const getInsightShareRequest =
  (slug: string, uuid: string, password: string | null) =>
  (dispatch: Dispatch) =>
    dispatch(
      action(ActionTypes.GET_INSIGHT_SHARE_REQUEST, { uuid, slug, password })
    );
export const getInsightShareSuccess = (response: DataTypes.Insight) =>
  action(ActionTypes.GET_INSIGHT_SHARE_SUCCESS, { response });
export const getInsightShareError = (error: RequestError) =>
  action(ActionTypes.GET_INSIGHT_SHARE_FAILURE, error);

export const clearInsights = () => action(ActionTypes.CLEAR_INSIGHTS);

export const reorderInsightRequest =
  (id: string, position: number, currentOrder: OrderDirection) =>
  (dispatch: Dispatch) =>
    dispatch(
      action(ActionTypes.REORDER_REQUEST, { id, position, currentOrder })
    );
export const reorderInsightSuccess = (
  response: DataTypes.ApiList<DataTypes.Idea>,
  currentOrder: OrderDirection
) => action(ActionTypes.REORDER_SUCCESS, { response, currentOrder });
export const reorderInsightError = (error: RequestError) =>
  action(ActionTypes.REORDER_FAILURE);

export const uploadInsightsRequest = (file: any) => (dispatch: Dispatch) =>
  dispatch(action(ActionTypes.UPLOAD_INSIGHTS_REQUEST, { file }));
export const uploadInsightsSuccess = (
  response: DataTypes.ApiRequest<DataTypes.Experiment>
) => action(ActionTypes.UPLOAD_INSIGHTS_SUCCESS, { response });
export const uploadInsightsError = (error: RequestError) =>
  action(ActionTypes.UPLOAD_INSIGHTS_FAILURE, error);
