import React, { Component } from "react";
import * as DataTypes from "store/types";
import { connect } from "react-redux";
import { SupportingDataSection } from "./supporting_data_section";
import { addSupportingDataRequest } from "store/uxr/actions";

interface IProps {
  account: DataTypes.Account;
  uxr: DataTypes.Uxr;
  currentUser: any;
  addSupportingDataRequest(uxrId: string, type: string, callback: any): void;
}

interface IState {}

class SupportingData extends Component<IProps, IState> {
  render() {
    const { uxr } = this.props;

    if (!uxr || !uxr.supporting_datas) {
      return null;
    }

    return (
      <React.Fragment>
        <SupportingDataSection
          account={this.props.account}
          uxr={uxr}
          currentUser={this.props.currentUser}
          type={"qualitative"}
          items={this.props.uxr.supporting_datas.filter(
            (data) => data.type === "Uxr::SupportingData::Qualitative"
          )}
          ctaLabel={"Add Qualitative Data"}
          onAdd={this.props.addSupportingDataRequest}
          title="Qualitative Data"
          description="Use this section to share and explain the data obtained
          by your study, Map them to Bulleted Explanation of Data Point
          an Area of Focus and Journey/Page	And we’ll organize them for you."
        />
        <hr />

        <SupportingDataSection
          account={this.props.account}
          uxr={uxr}
          currentUser={this.props.currentUser}
          type={"quantitative"}
          items={this.props.uxr.supporting_datas.filter(
            (data) => data.type === "Uxr::SupportingData::Quantitative"
          )}
          ctaLabel={"Add Quantitative Data"}
          onAdd={this.props.addSupportingDataRequest}
          title="Quantitative Data"
          description="Use this section to share and explain the data obtained
          by your study, Map them to Bulleted Explanation of Data Point
          an Area of Focus and Journey/Page And we’ll organize them for you."
        />
      </React.Fragment>
    );
  }
}

const mapStateToProps = ({ router }: DataTypes.ApplicationState) => ({
  router,
});

const mapDispatchToProps = {
  addSupportingDataRequest,
};

const connectedPage = connect(
  mapStateToProps,
  mapDispatchToProps
)(SupportingData);

export { connectedPage as SupportingData };
