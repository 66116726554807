import React from "react";

import { PublicPageProps } from "comps/pages";

interface IProps extends PublicPageProps {}

const Health: React.FC<IProps> = () => {
  return (
    <div>
      <p>I'm alive</p>
    </div>
  );
};

export { Health };
