import React, { Component, useCallback, useEffect } from "react";
import { connect } from "react-redux";
import { ApplicationState } from "store/types";
import { getUserAccountsRequest } from "../../../store/user_accounts/actions";
import {
  createErrorMessageSelector,
  createLoadingSelector,
} from "../../../store/selectors";
import { PageContainer } from "./index.style";
import { AccountLoader } from "../../../ui/loaders";
import { useKeycloak } from "@react-keycloak/web";
import { Button } from "../../../comps";

interface IProps {
  router: any;
  currentUser: any;
  loading: boolean;
  getUserAccountsRequest: () => void;
  userAccounts: any;
  error: any;
}

interface IState {}

const RealCompnonet: React.FC<{
  userAccounts: any;
  getUserAccountsRequest: () => void;
  loading: boolean;
  error: any;
}> = ({ userAccounts, getUserAccountsRequest, loading, error }) => {
  const { keycloak, initialized } = useKeycloak();

  const login = useCallback(() => {
    const redirectUri = `${process.env.REACT_APP_HTTP_PROTO}://chooseaccount.${process.env.REACT_APP_BASE_DOMAIN}/account/choose`;

    keycloak?.login({
      redirectUri,
    });
  }, [keycloak]);

  useEffect(() => {
    if (!keycloak || !initialized || keycloak.authenticated) {
      return;
    }

    login();
  }, [keycloak, initialized]);

  useEffect(() => {
    if (userAccounts?.length === 1) {
      window.location.assign(
        `${process.env.REACT_APP_HTTP_PROTO}://${userAccounts[0].slug}.${process.env.REACT_APP_BASE_DOMAIN}`
      );
    }
  }, [userAccounts]);

  useEffect(() => {
    if (
      localStorage.getItem("user_token") === keycloak.token &&
      !userAccounts
    ) {
      getUserAccountsRequest();
      return;
    }
  }, [keycloak.token, initialized]);

  return (
    <PageContainer>
      <div className="header">
        <img src="/brand/logo_by_brooksbell.svg" alt="illuminate Logo" />

        {error ? (
          <h4>
            It looks like you don't have an account yet, but that's ok! Just
            sign <a href="https://www.illuminate.app/signup">up here</a>.
          </h4>
        ) : (
          <h2>
            Which one of your illuminate accounts would you like to access?
          </h2>
        )}
      </div>

      {error ? (
        <Button
          className="btn btn-primary"
          text="Sing Up"
          onClick={() =>
            window.location.assign(`https://www.illuminate.app/signup`)
          }
        />
      ) : (
        <ul>
          {userAccounts?.map((account: any) => (
            <li
              key={`account-box-${account.slug}`}
              onClick={() => {
                window.location.assign(
                  `${process.env.REACT_APP_HTTP_PROTO}://${account.slug}.${process.env.REACT_APP_BASE_DOMAIN}`
                );
              }}
            >
              <p>{account.name}</p>
              <p className="slug">
                {process.env.REACT_APP_HTTP_PROTO}://{account.slug}.
                {process.env.REACT_APP_BASE_DOMAIN}
              </p>
            </li>
          ))}
        </ul>
      )}
    </PageContainer>
  );
};

class ChooseAccount extends Component<IProps, IState> {
  render() {
    return (
      <AccountLoader loaded={!this.props.loading}>
        <RealCompnonet
          userAccounts={this.props.userAccounts || null}
          getUserAccountsRequest={this.props.getUserAccountsRequest}
          loading={this.props.loading}
          error={this.props.error}
        />
      </AccountLoader>
    );
  }
}

const loadingSelector = createLoadingSelector(["@@user/GET_USER_ACCOUNTS"]);
const errorSelector = createErrorMessageSelector(["@@user/GET_USER_ACCOUNTS"]);

const mapStateToProps = ({ app, collections }: ApplicationState) => ({
  loading: loadingSelector(app.requests),
  userAccounts: collections?.userAccounts,
  error: errorSelector(app.errors),
});

const mapDispatchToProps = {
  getUserAccountsRequest,
};

const connectedPage = connect(
  mapStateToProps,
  mapDispatchToProps
)(ChooseAccount);

export { connectedPage as ChooseAccount };
