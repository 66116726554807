import { action } from 'typesafe-actions';
import { Dispatch } from 'redux';
import { ActionTypes } from './types';
import * as DataTypes from '../types';

interface RequestError {
  statusCode?: number | undefined;
  message: any;
}

export const setIdeaMultipleSelect = (opened: boolean) => action(ActionTypes.SET_IDEA_MULTIPLE_SELECT, { opened });

export const addThemesToIdeasRequest = (themes: any, ideas: any) => (dispatch: Dispatch) => dispatch(action(ActionTypes.ADD_THEMES_TO_IDEAS_REQUEST, { themes, ideas }));
export const addThemesToIdeasSuccess = (response: DataTypes.ApiRequest<DataTypes.Idea>) => action(ActionTypes.ADD_THEMES_TO_IDEAS_SUCCESS, { response });
export const addThemesToIdeasError = (error: RequestError) => action(ActionTypes.ADD_THEMES_TO_IDEAS_FAILURE, error);

export const selectIdeaRequest = (idea: any, clear?: boolean, callback?: any) => (dispatch: Dispatch) => dispatch(action(ActionTypes.SELECT_IDEA_REQUEST, { idea, clear, callback }));
export const selectIdeaSuccess = (idea: any, clear?: boolean) => action(ActionTypes.SELECT_IDEA_SUCCESS, { idea, clear });
export const selectIdeaError = (error: RequestError) => action(ActionTypes.SELECT_IDEA_FAILURE, error);