import { Reducer } from "redux";
import * as DataTypes from "../types";

const initialState: null = null;

const reducer: Reducer<Array<DataTypes.ProgramGoalTarget> | null> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    default: {
      return state;
    }
  }
};

export { reducer as programGoalTargetReducer };
