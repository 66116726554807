import React, { Component } from "react";
import { connect } from "react-redux";
import * as DataTypes from "store/types";
import { ApplicationState } from "store/types";
import { AppPage, AppPageProps } from "comps/pages";
import {
  CommingSoonBadge,
  Grid,
  GridItem,
  NewBadge,
  Page,
} from "./index.style";
import * as AppUtils from "utils/app";
import { history } from "utils/history";

interface IProps extends AppPageProps {
  router: any;
  account: DataTypes.Account | null;
  currentUser: any;
}

interface IState {}

type ResourceType = {
  name: string;
  commingSoon?: boolean;
  subheader?: string;
  new?: boolean;
  onClick?: () => void;
};

class Resources extends Component<IProps, IState> {
  render() {
    const RESOURCES: ResourceType[] = [
      {
        name: "Academy™",
        subheader: "Free, Self-Paced Learning",
        onClick: () => {
          const subDomain = AppUtils.App.getSubDomain();

          const cookieName = "click-academy-redirect-account";
          const cookieValue = subDomain;
          const myDate = new Date();
          myDate.setMonth(myDate.getMonth() + 12);
          document.cookie =
            cookieName +
            "=" +
            cookieValue +
            ";expires=" +
            myDate +
            `;domain=.${process.env.REACT_APP_BASE_URL};path=/`;

          window.open(process.env.REACT_APP_CLICK_ACADEMY_LOGIN_LINK, "_blank");
        },
      },
      {
        name: "Business Review Builder",
        subheader: "Automated Performance Report",
        commingSoon: true,
      },
      {
        name: "Customizations",
        commingSoon: true,
      },
      {
        new: true,
        name: "Goal Setting",
        onClick: () => {
          history.push("/resources/program_goals");
        },
      },
      {
        name: "Insight Effectiveness Index",
        subheader: "Free CX Benchmarking Tool",
        onClick: () => {
          window.open(
            `${process.env.REACT_APP_HTTP_PROTO}://${this.props.account?.slug}.v3.${process.env.REACT_APP_BASE_URL}/selfAssessmentRedirect`,
            "_blank"
          );
        },
      },
      {
        name: "Integrations",
        onClick: () => {
          history.push("/resources/integrations");
        },
      },
      {
        name: "Sample Size Calculator",
        onClick: () => {
          history.push("/tools/calculator");
        },
      },
    ];

    return (
      <Page>
        <Grid>
          {RESOURCES.map((resource: ResourceType) => (
            <GridItem
              clickable={!!resource.onClick}
              key={`reource-section-${resource.name
                .replace(/ /g, "_")
                .toLowerCase()}`}
              onClick={() => {
                if (resource.onClick) {
                  resource.onClick();
                }
              }}
            >
              <NewBadge active={resource.new || false}></NewBadge>

              <span>{resource.name}</span>

              <p>{resource.subheader}</p>

              <CommingSoonBadge
                active={resource.commingSoon || false}
              ></CommingSoonBadge>
            </GridItem>
          ))}
        </Grid>
      </Page>
    );
  }
}

const mapStateToProps = ({ router, app, account }: ApplicationState) => ({
  router,
  account,
});

const mapDispatchToProps = {};

const connectedPage = connect(
  mapStateToProps,
  mapDispatchToProps
)(AppPage(Resources));

export { connectedPage as Resources };
