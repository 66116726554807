import React, { useRef, useState } from 'react';
import { handleOutsideClick } from './utils';

interface IProps {
  message: string;
  className?: string;
}

const ToolTip = ({ message, className }: IProps) => {
  const ref = useRef<any>();
  const [open, toggleOpen] = useState(false);

  handleOutsideClick(ref, () => {
    if (open) {
      toggleOpen(false);
    }
  });

  return (
    <div ref={ref} className={`${className} ${open === true ? 'ToolTip open' : 'ToolTip'}`} onClick={() => toggleOpen(!open)}>
      <div className="Icon">
        <i className="fas fa-question"></i>
      </div>
      <div className="TipBox">
        <p dangerouslySetInnerHTML={{__html: message}}></p>
      </div>
    </div>
  );
};

export { ToolTip };
