import React, { Component } from "react";
import { connect } from "react-redux";
import { ApplicationState } from "store/types";
import * as DataTypes from "store/types";
import { Link } from "react-router-dom";
import { TotalCountStat } from "ui";
import { createLoadingSelector } from "store/selectors";
import { getAccountTraitsRequest } from "store/account/actions";
import { FilterGroup } from "./filter_group";
import "css/Screens.scss";
import { getIdeaBoardsManagementRequest } from "store/idea_board_management/actions";
import { SwitchButton } from "ui/switch_button";
import { Helpers, history } from "utils";
import { OrderDirection, OrderIcon } from "ui/order_icon";
import Collapsible from "react-collapsible";
import { ArrowDownIcon } from "ui/arrow_down_icon";
import { BestInClass } from "./modals/best_in_class";
import { showModal } from "store/app/actions";
import { MarketingBanner } from "comps/banner";

const FilterKeys = [
  "audiences",
  "devices",
  "pages",
  "brands",
  "teams",
  "authors",
  "contributors",
  "channels",
];

type sortingNamesType = {
  [key: string]: string;
};

const sortingNames: sortingNamesType = {
  customOrder: "Drag and Drop",
  updatedAt: "Last Updated",
  createdAt: "Created At",
};

interface IProps {
  router: any;
  currentUser: any;
  account: DataTypes.Account | null;
  accountLoading: boolean;
  getAccountTraitsRequest: () => void;
  selectedFilters: { [key: string]: Array<string> };
  onCheck(checked: boolean, type: string, value: string): void;
  getIdeaBoardsManagementRequest: (
    options?: DataTypes.ApiListOptions,
    scrollOpts?: { append: boolean }
  ) => void;
  onSorting: (orderBy: string, currentDirection: OrderDirection) => void;
  sortingValues: { [key: string]: OrderDirection };
  showModal: (component: React.ComponentType<any>, options: any) => void;
  ideaBoardTrait: any;
  onLimitChange(limit: number): void;
  onClearFilters(): void;
}

interface IState {
  filterSettings: { [key: string]: { showMore: boolean } };
}

const buildFilterSettings = () => {
  let config = {};

  FilterKeys.forEach((key: string) => {
    config = { ...config, [key]: { showMore: false } };
  });

  return config;
};

class FilterBar extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      filterSettings: buildFilterSettings(),
    };
  }

  componentDidMount() {
    this.props.getAccountTraitsRequest();
  }

  private onUpdateFilterSettings = (
    key: string,
    prop: string,
    value: string | boolean
  ) => {
    this.setState({
      ...this.state,
      filterSettings: {
        ...this.state.filterSettings,
        [key]: { ...this.state.filterSettings[key], [prop]: value },
      },
    });
  };

  render() {
    const {
      account,
      accountLoading,
      onCheck,
      selectedFilters,
      ideaBoardTrait,
    } = this.props;
    const { filterSettings } = this.state;

    if (accountLoading) {
      return null;
    }

    const traits = ideaBoardTrait?.traits;

    return (
      <section className="FilterBar">
        <div className="">
          <SwitchButton
            buttons={[
              {
                text: "ideas",
                onClick: () => history.push("/ideas"),
                selected: () => history.location.pathname === "/ideas",
              },
              {
                text: "boards",
                onClick: () => history.push("/ideas/boards"),
                selected: () => history.location.pathname === "/ideas/boards",
              },
            ]}
          />
        </div>
        <div className="viewCtrls"></div>
        <div className="pad20">
          <hr />
        </div>

        <TotalCountStat
          name="Idea boards"
          total={account?.summary?.totals.idea_boards || 0}
        />

        {this.props.currentUser.role === "read-only" ? null : (
          <div className="addCtrls">
            <Link to="/ideas/boards/create" className="btn btn-primary">
              Add Idea Board
            </Link>
          </div>
        )}
        <div className="w--full flex justify-center">
          <button
            className="button button__link p__zero"
            style={{ padding: 0, textDecoration: "none" }}
            onClick={() => this.props.showModal(BestInClass, {})}
          >
            <i className="fas fa-lightbulb"></i> Best in Class Example
          </button>
        </div>
        <div className="pad20">
          <hr />
        </div>

        {/* <MarketingBanner
          containerStyle={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            marginTop: "20px",
            marginLeft: "3px",
          }}
          bannerPlacementSlug={"filter_bar_banners"}
          imageStyle={{ width: "200px", height: "200px" }}
        /> */}

        <button
          onClick={this.props.onClearFilters}
          className="button button__link p__zero"
          style={{ paddingLeft: "20px" }}
        >
          Clear Filters
        </button>
        <div className="sortCtrls Filter">
          <Collapsible
            trigger={
              <h4>
                Sorting <ArrowDownIcon height="18" />
              </h4>
            }
            triggerClassName="cursor--pointer"
            triggerOpenedClassName="cursor--pointer"
          >
            <ul>
              {Object.keys(this.props.sortingValues).map(
                (sortingProperty: string) => (
                  <li key={sortingProperty}>
                    <span
                      className="h--sm w--full flex justify-between align-center cursor--pointer"
                      onClick={() => {
                        this.props.onSorting(
                          sortingProperty,
                          this.props.sortingValues[sortingProperty]
                        );
                      }}
                    >
                      {sortingNames[sortingProperty] ||
                        Helpers.camelToCapitalize(sortingProperty)}
                      <span className="icon">
                        <OrderIcon
                          orderDirection={
                            this.props.sortingValues[sortingProperty]
                          }
                        />
                      </span>
                    </span>
                  </li>
                )
              )}
            </ul>
          </Collapsible>
        </div>

        <Collapsible
          triggerTagName="h4"
          trigger={
            <span>
              Status <ArrowDownIcon height="18" />
            </span>
          }
          triggerClassName="cursor--pointer"
          triggerOpenedClassName="cursor--pointer"
          className="Filter"
          openedClassName="Filter"
        >
          <ul>
            <li>
              <label className="CheckBox">
                <input
                  type="checkbox"
                  defaultChecked={
                    selectedFilters["archived"]?.indexOf("true") >= 0
                      ? true
                      : false
                  }
                  onChange={(e) =>
                    onCheck(e.target.checked, "archived", "true")
                  }
                />
                <span className="checkmark"></span>
              </label>
              <p>
                <i className="fas fa-archive" style={{ color: "#757e8e" }}></i>
                Archived
              </p>
              <p></p>
            </li>
          </ul>
        </Collapsible>

        {traits &&
          Object.keys(traits)
            .filter((key) => FilterKeys.includes(key))
            .map((key) => {
              return (
                <FilterGroup
                  key={`filter_group_${key}`}
                  collapsible={true}
                  name={key}
                  traits={traits[key]}
                  onCheck={onCheck}
                  selectedItems={selectedFilters[key] || []}
                  filterSettings={filterSettings[key] || undefined}
                  onUpdateFilterSettings={this.onUpdateFilterSettings}
                />
              );
            })}

        <Collapsible
          triggerTagName="h4"
          trigger={
            <span>
              Boards Per Page <ArrowDownIcon height="18" />
            </span>
          }
          triggerClassName="cursor--pointer"
          triggerOpenedClassName="cursor--pointer"
          className="Filter"
          openedClassName="Filter"
        >
          <ul>
            <li>
              <label className="CheckBox">
                <input
                  type="radio"
                  defaultChecked={true}
                  name="per_page"
                  onChange={(e) => this.props.onLimitChange(25)}
                />
                <span className="checkmark"></span>
              </label>
              <p>25</p>
              <p></p>
            </li>

            <li>
              <label className="CheckBox">
                <input
                  name="per_page"
                  type="radio"
                  defaultChecked={false}
                  onChange={(e) => this.props.onLimitChange(50)}
                />
                <span className="checkmark"></span>
              </label>
              <p>50</p>
              <p></p>
            </li>

            <li>
              <label className="CheckBox">
                <input
                  name="per_page"
                  type="radio"
                  defaultChecked={false}
                  onChange={(e) => this.props.onLimitChange(100)}
                />
                <span className="checkmark"></span>
              </label>
              <p>100</p>
              <p></p>
            </li>

            <li>
              <label className="CheckBox">
                <input
                  name="per_page"
                  type="radio"
                  defaultChecked={false}
                  onChange={(e) => this.props.onLimitChange(200)}
                />
                <span className="checkmark"></span>
              </label>
              <p>200</p>
              <p></p>
            </li>
          </ul>
        </Collapsible>
      </section>
    );
  }
}

const accountLoadingSelector = createLoadingSelector([
  "@@account/GET_ACCOUNT",
  "@@account/GET_ACCOUNT_TRAITS",
]);

const mapStateToProps = ({
  router,
  app,
  account,
  ideaBoardTrait,
}: ApplicationState) => ({
  router: router,
  accountLoading: accountLoadingSelector(app.requests),
  account: account,
  ideaBoardTrait,
});

const mapDispatchToProps = {
  getAccountTraitsRequest,
  getIdeaBoardsManagementRequest,
  showModal,
};

const connectedPage = connect(mapStateToProps, mapDispatchToProps)(FilterBar);

export { connectedPage as FilterBar };
