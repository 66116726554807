import { action } from 'typesafe-actions';
import { Dispatch } from 'redux';
import * as ActionTypes from './types';
import * as DataTypes from '../types';

interface RequestError {
  statusCode?: number | undefined;
  message: any;
}

// App Clear Errors
export const clearAppErrors = () => (dispatch: Dispatch) => dispatch(action(ActionTypes.App.APP_ERROR_CLEAR, {}));

// initialize App
export const initAppRequest = (slug: string, user: DataTypes.User) => (dispatch: Dispatch) =>
  dispatch(action(ActionTypes.App.INIT_APP_REQUEST, { slug, user }));
export const initAppSuccess = () => action(ActionTypes.App.INIT_APP_SUCCESS, {});
export const initAppError = (error: RequestError) => action(ActionTypes.App.INIT_APP_FAILURE, error);

// Alert
export const hideAlert = () => (dispatch: Dispatch) => dispatch(action(ActionTypes.App.ALERT_HIDE));
export const showAlertError = (content:string) => (dispatch: Dispatch) => dispatch(action(ActionTypes.App.ALERT_SHOW, { content, error: true }));

// Modal
export const showModal = (component: any, options: any) => (dispatch: Dispatch) => dispatch(action(ActionTypes.App.MODAL_SHOW, { component, options }));
export const hideModal = () => (dispatch: Dispatch) => dispatch(action(ActionTypes.App.MODAL_HIDE));

// User Login
export const loginRequest = (slug: string, email: string, password: string) => (dispatch: Dispatch) =>
  dispatch(action(ActionTypes.App.LOGIN_REQUEST, { slug, email, password }));
export const loginSuccess = (data: DataTypes.User) => action(ActionTypes.App.LOGIN_SUCCESS, data);
export const loginError = (error: RequestError) => action(ActionTypes.App.LOGIN_ERROR, error);
