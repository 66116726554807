import React, { Component } from "react";
import { connect } from "react-redux";
import * as DataTypes from "store/types";
import { Form, Select, Button, ValidatorTypes } from "comps/form";
import {
  updateAccountProfileRequest,
  getAccountRequest,
} from "store/account/actions";
import { getSettingsOptionsRequest } from "store/profile_settings_options/actions";

import "css/Setup.scss";
import { ApplicationState } from "store/types";
import { SetupStepsEnum } from "./types";

interface IProps {
  account: DataTypes.Account | null;
  profielSettingsOptions: DataTypes.ProfileSettingsOptions | null;
  updateAccountProfileRequest: (body: any, redirectUrl: string) => void;
}

class AboutYourProgram extends Component<IProps> {
  validator = {
    type: ValidatorTypes.EMAIL,
    messages: { type: "Please enter a valid email address." },
  };

  private onSubmit = (valid: boolean, dirty: boolean, data: any) => {
    if (valid === true) {
      this.props.updateAccountProfileRequest(
        {
          profile: {
            id: this.props.account?.profile.id,
            industry: data.industry.value,
            company_size: data.company_size.value,
            program_type: data.program_type.value,
            avg_exp_per_month: data.avg_exp_per_month.value,
            wins_per_month: data.wins_per_month.value,
            coe: data.coe.value,
          },
        },
        `?step=${SetupStepsEnum.ABOUT_YOUR_TOOLS}`
      );
    }
  };

  render() {
    const { account } = this.props;

    return (
      <React.Fragment>
        <h1>About Your Program</h1>
        <p>
          illuminate was built by testing experts, for testing experts. Tell us
          about your program to influence relevant content and future features.
        </p>
        <Form
          formFields={{
            industry: {
              name: "industry",
              value: account?.profile.industry?.value || "",
              validator: {
                type: ValidatorTypes.REQUIRED,
                messages: { required: "Please select an industry." },
              },
            },
            company_size: {
              name: "company_size",
              value: account?.profile.company_size?.value || "",
              validator: {
                type: ValidatorTypes.REQUIRED,
                messages: { required: "Please select a company size." },
              },
            },
            program_type: {
              name: "program_type",
              value: account?.profile.program_type?.value || "",
              validator: {
                type: ValidatorTypes.REQUIRED,
                messages: { required: "Please select a program structure." },
              },
            },
            avg_exp_per_month: {
              name: "avg_exp_per_month",
              value: account?.profile.avg_exp_per_month?.value || "",
              validator: {
                type: ValidatorTypes.REQUIRED,
                messages: {
                  required:
                    "Please select average experiments launched per month.",
                },
              },
            },
            wins_per_month: {
              name: "wins_per_month",
              value: account?.profile.wins_per_month?.value || "",
              validator: {
                type: ValidatorTypes.REQUIRED,
                messages: {
                  required: "Please select average experiments won per month.",
                },
              },
            },
            coe: {
              name: "coe",
              value: account?.profile.coe?.value || "",
              validator: {
                type: ValidatorTypes.REQUIRED,
                messages: {
                  required: "Please select the location of your testing COE.",
                },
              },
            },
          }}
          onSubmit={this.onSubmit}
          FormComponent={({
            fields: {
              industry,
              company_size,
              program_type,
              avg_exp_per_month,
              wins_per_month,
              coe,
            },
            onChange,
          }) => (
            <div>
              <div className="form-group">
                <label>What industry best represents your company?</label>
                <Select
                  key="industry"
                  className="SelectInput"
                  placeholder="-- Select --"
                  field={industry}
                  options={
                    this.props.profielSettingsOptions?.industry?.map(
                      (indsutryOption: DataTypes.ProfileSettingsOption) =>
                        indsutryOption.value
                    ) || []
                  }
                  onChange={(value: string) => onChange(industry, value)}
                />
              </div>
              <div className="form-group">
                <label>What is your company size?</label>
                <Select
                  key="company_size"
                  className="SelectInput"
                  placeholder="-- Select --"
                  field={company_size}
                  options={
                    this.props.profielSettingsOptions?.company_size?.map(
                      (companySizeOption: DataTypes.ProfileSettingsOption) =>
                        companySizeOption.value
                    ) || []
                  }
                  onChange={(value: string) => onChange(company_size, value)}
                />
              </div>
              <div className="form-group">
                <label>
                  What is the structure of your experimentation program?
                </label>
                <Select
                  key="program_type"
                  className="SelectInput"
                  placeholder="-- Select --"
                  field={program_type}
                  options={
                    this.props.profielSettingsOptions?.program_type?.map(
                      (programTypeOption: DataTypes.ProfileSettingsOption) =>
                        programTypeOption.value
                    ) || []
                  }
                  onChange={(value: string) => onChange(program_type, value)}
                />
              </div>
              <div className="form-group">
                <label>
                  Where does the testing Center of Excellence (COE) sit within
                  your organization?
                </label>
                <Select
                  key="coe"
                  className="SelectInput"
                  placeholder="-- Select --"
                  field={coe}
                  options={
                    this.props.profielSettingsOptions?.coe?.map(
                      (coeOption: DataTypes.ProfileSettingsOption) =>
                        coeOption.value
                    ) || []
                  }
                  onChange={(value: string) => onChange(coe, value)}
                />
              </div>
              <div className="form-group">
                <label>
                  On average, how many tests do you currently launch per month?
                </label>
                <Select
                  key="avg_exp_per_month"
                  className="SelectInput"
                  placeholder="-- Select --"
                  field={avg_exp_per_month}
                  options={
                    this.props.profielSettingsOptions?.avg_exp_per_month?.map(
                      (avgExpPerMonthOption: DataTypes.ProfileSettingsOption) =>
                        avgExpPerMonthOption.value
                    ) || []
                  }
                  onChange={(value: string) =>
                    onChange(avg_exp_per_month, value)
                  }
                />
              </div>
              <div className="form-group">
                <label>On average, how many experiments win each month?</label>
                <Select
                  key="wins_per_month"
                  className="SelectInput"
                  placeholder="-- Select --"
                  field={wins_per_month}
                  options={
                    this.props.profielSettingsOptions?.experiments_win_per_month?.map(
                      (companySizeOption: DataTypes.ProfileSettingsOption) =>
                        companySizeOption.value
                    ) || []
                  }
                  onChange={(value: string) => onChange(wins_per_month, value)}
                />
              </div>
              <hr />
              <Button
                className="btn btn-primary"
                text="next"
                isLoading={false}
              />
            </div>
          )}
        />
      </React.Fragment>
    );
  }
}

const mapStateToProps = ({ profielSettingsOptions }: ApplicationState) => ({
  profielSettingsOptions,
});

const mapDispatchToProps = {
  updateAccountProfileRequest,
  getAccountRequest,
  getSettingsOptionsRequest,
};

const connectedPage = connect(
  mapStateToProps,
  mapDispatchToProps
)(AboutYourProgram);

export { connectedPage as AboutYourProgram };
