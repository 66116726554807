export enum ActionTypes {
  GET_APPLICATION_REQUEST = '@@application/GET_APPLICATION_REQUEST',
  GET_APPLICATION_SUCCESS = '@@application/GET_APPLICATION_SUCCESS',
  GET_APPLICATION_FAILURE = '@@application/GET_APPLICATION_FAILURE',

  CREATE_APPLICATION_REQUEST = '@@application/CREATE_APPLICATION_REQUEST',
  CREATE_APPLICATION_SUCCESS = '@@application/CREATE_APPLICATION_SUCCESS',
  CREATE_APPLICATION_FAILURE = '@@application/CREATE_APPLICATION_FAILURE',

  DELETE_APPLICATION_REQUEST = "@@application/DELETE_APPLICATION_REQUEST",
  DELETE_APPLICATION_SUCCESS = "@@application/DELETE_APPLICATION_SUCCESS",
  DELETE_APPLICATION_FAILURE = "@@application/DELETE_APPLICATION_FAILURE"
}
