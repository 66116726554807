import React, { useState, useRef } from 'react';
import { handleOutsideClick } from './utils';

export interface ControlItem {
  icon: string;
  name: string;
  onClick(): void;
  splitAbove?: boolean;
  className?: string;
}

interface IProps {
  style?: any;
  dropdownClass?: string;
  openLeft?: boolean;
  controlItems: Array<ControlItem>;
  component?: React.ReactNode;
}

const DropdownControl = ({ style, openLeft, controlItems, dropdownClass, component }: IProps) => {
  const ref = useRef<HTMLUListElement>(null);

  const [open, setOpen] = useState(false);
  const addClassNames = (names?: string) => (open ? `dropdown-menu show ${names ? names : ''}` : `dropdown-menu ${names ? names : ''}`);
  handleOutsideClick(ref, () => {
    if (open) setOpen(!open);
  });

  return (
    <div className="dropdown">
      <button className="dropdown-button" style={style} onClick={(e) => setOpen(!open)}>
        {component ? component : <i className="fas fa-ellipsis-h"></i>}
      </button>
      <ul ref={ref} className={addClassNames(dropdownClass)} style={openLeft ? { height: 40, left: -125, top: -10, position: 'absolute' } : undefined}>
        {controlItems.map((item) =>
          item.splitAbove === true ? (
            <li key={item.name} className="dropdown-menu-item split-above">
              <button className={'btn dropdown-menu-item-btn ' + item.className} type="button" onClick={item.onClick}>
                <i className={item.icon} /> {item.name}
              </button>
            </li>
          ) : (
            <li key={item.name} className="dropdown-menu-item">
              <button className={'btn dropdown-menu-item-btn ' + item.className} type="button" onClick={item.onClick}>
                <i className={item.icon} /> {item.name}
              </button>
            </li>
          )
        )}
      </ul>
    </div>
  );
};

export { DropdownControl };
