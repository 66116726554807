import React from 'react';
import { connect } from 'react-redux';
import { AppPage, AppPageProps } from 'comps/pages';
import * as DataTypes from 'store/types';
import { TaskList } from './comps';
import { ApplicationState } from 'store/types';
import { getTasksRequest, deleteTaskRequest } from 'store/task/actions';
import { Spacer } from 'comps/layout';

interface IProps extends AppPageProps {
  getTasksRequest: () => void;
  deleteTaskRequest: (id: string) => void;
  collections: any;
}

class Tasks extends React.Component<IProps> {
  componentDidMount() {
    this.props.getTasksRequest();
  }

  private onDeleteTask = (task: DataTypes.Task) => {
    if (task && task.id) {
      this.props.deleteTaskRequest(task.id);
    }
  };

  render() {
    const { collections } = this.props;
    const tasks = collections.tasks?.data || [];

    return (
      <div className="Tasks">
        <div className="title">Notifications</div>
        <div className="desc">Check here often for tasks and notifications designed to help you get the most value from illuminate.</div>
        <Spacer />
        <TaskList tasks={tasks} onDelete={this.onDeleteTask} />
      </div>
    );
  }
}

const mapStateToProps = ({ collections }: ApplicationState) => ({
  collections,
});

const mapDispatchToProps = {
  getTasksRequest,
  deleteTaskRequest,
};

const connectedPage = connect(mapStateToProps, mapDispatchToProps)(AppPage(Tasks));

export { connectedPage as Tasks };
