import React, { Component } from "react";
import { connect } from "react-redux";
import { ApplicationState } from "store/types";
import * as DataTypes from "store/types";
import { AppPage, AppPageProps } from "comps/pages";
import { createLoadingSelector } from "store/selectors";
import { getAccountStatsRequest } from "store/account/actions";
import { getExperimentsTraitsRequest } from "store/experiment_trait/actions";
import { Helpers } from "utils";
import { SelectInput, MonthPicker } from "ui";
import moment from "moment";
import PieChart from "react-minimal-pie-chart";
import {
  FlexibleXYPlot,
  XAxis,
  YAxis,
  HorizontalGridLines,
  VerticalBarSeries,
  Hint,
} from "react-vis";
import "css/Chart.scss";
import { LeaderBoard } from "./dashboard/comps/leaderboard";
import { InsightBoard } from "./dashboard/comps/insight_board";
import { TodayBoard } from "./dashboard/comps/today_board";
import { DidYouNow } from "./dashboard/comps/did_you_know";

import { getInsightProposalsRequest } from "store/insightProposal/actions";
import { showModal } from "store/app/actions";
import { InsightProposals } from "./home/modals/insight_proposals";

enum DATE_RANGE {
  MONTHS_12 = "12mo",
  MONTHS_6 = "6mo",
  CUSTOM = "custom",
}

interface CustomDatePickerRange {
  start: Date;
  end: Date;
}

interface DatePickerOption {
  name: string;
  value: DATE_RANGE;
}

const DATE_PICKER_OPTIONS: Array<DatePickerOption> = [
  { name: "last 12 months", value: DATE_RANGE.MONTHS_12 },
  { name: "last 6 months", value: DATE_RANGE.MONTHS_6 },
  { name: "custom date range", value: DATE_RANGE.CUSTOM },
];

interface IProps extends AppPageProps {
  account: DataTypes.Account | null;
  loading: boolean;
  experimentTrait: any;
  insightBoardLoading: boolean;
  dashboard: DataTypes.Dashboard | null;
  getInsightProposalsRequest(callback: any): void;
  showModal: (component: React.ComponentType<any>, options: any) => void;
  getAccountStatsRequest(
    startDate: string,
    endDate: string,
    brand?: string,
    team?: string,
    channel?: string
  ): void;
  getExperimentsTraitsRequest: () => void;
}

interface IState {
  programGoal: {
    showMenu: boolean;
    selectedKey?: string;
  };
  datePicker: DatePickerOption;
  selectedBrand?: string;
  selectedTeam?: string;
  selectedChannel?: string;
  hoveredCell: any;
  startDate: Date;
  endDate: Date;
}

interface reportDataItem {
  date: number;
  winner: number;
  loss: number;
  flat: number;
  undecided: number;
}

interface reportElement {
  x: string;
  y: number;
  type: string;
  total: number;
  color: string;
}

const buildReportData = (range: Array<reportDataItem>) => {
  let win: Array<reportElement> = [];
  let loss: Array<reportElement> = [];
  let flat: Array<reportElement> = [];

  range.forEach((item) => {
    win.push({
      x: moment(item.date * 1000)
        .utc()
        .format("MMM/YY"),
      y: item.winner,
      type: "winner",
      total: Math.round(item.winner),
      color: "#89CAD7",
    });
    loss.push({
      x: moment(item.date * 1000)
        .utc()
        .format("MMM/YY"),
      y: item.loss,
      type: "loss",
      total: Math.round(item.loss),
      color: "#FF5A71",
    });
    flat.push({
      x: moment(item.date * 1000)
        .utc()
        .format("MMM/YY"),
      y: item.flat,
      type: "flat",
      total: Math.round(item.flat),
      color: "#A7A0E8",
    });
  });

  return { win, loss, flat };
};

class Home extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      programGoal: {
        showMenu: false,
      },
      datePicker: DATE_PICKER_OPTIONS[0],
      hoveredCell: false,
      startDate: moment().add(-11, "months").toDate(),
      endDate: moment().toDate(),
    };
  }

  componentDidMount = () => {
    this.props.getExperimentsTraitsRequest();

    this.props.getInsightProposalsRequest(() => {
      this.props.showModal(InsightProposals, {});
    });

    this.props.getAccountStatsRequest(
      moment(this.state.startDate).format("MM-YYYY"),
      moment().format("MM-YYYY")
    );
  };

  private onCustomDateChange = (start: Date, end: Date) => {
    const { selectedBrand, selectedTeam } = this.state;

    this.props.getAccountStatsRequest(
      moment(start).format("MM-YYYY"),
      moment(end).format("MM-YYYY"),
      selectedBrand,
      selectedTeam
    );

    this.setState({
      ...this.state,
      startDate: start,
      endDate: end,
    });
  };

  private onDateChange = (date: DatePickerOption) => {
    const { selectedBrand, selectedTeam, startDate, endDate } = this.state;

    if (date.value === DATE_RANGE.MONTHS_12) {
      const _startDate = moment().add(-11, "months").toDate();
      const _endDate = moment().toDate();

      this.props.getAccountStatsRequest(
        moment(_startDate).format("MM-YYYY"),
        moment(_endDate).format("MM-YYYY"),
        selectedBrand,
        selectedTeam
      );

      this.setState({
        ...this.state,
        datePicker: date,
        startDate: _startDate,
        endDate: _endDate,
      });
    }

    if (date.value === DATE_RANGE.MONTHS_6) {
      const _startDate = moment().add(-5, "months").toDate();
      const _endDate = moment().toDate();

      this.props.getAccountStatsRequest(
        moment(_startDate).format("MM-YYYY"),
        moment(_endDate).format("MM-YYYY"),
        selectedBrand,
        selectedTeam
      );

      this.setState({
        ...this.state,
        datePicker: date,
        startDate: _startDate,
        endDate: _endDate,
      });
    }

    if (date.value === DATE_RANGE.CUSTOM) {
      this.onCustomDateChange(startDate, endDate);

      this.setState({ ...this.state, datePicker: date });
    }
  };

  private onBrandChange = (value: string) => {
    const { datePicker } = this.state;
    let brand: string | undefined = value;
    if (value === this.state.selectedBrand) brand = undefined;

    if (datePicker.value === DATE_RANGE.MONTHS_12) {
      this.props.getAccountStatsRequest(
        moment().add(-11, "months").format("MM-YYYY"),
        moment().format("MM-YYYY"),
        brand,
        this.state.selectedTeam
      );
    }

    if (datePicker.value === DATE_RANGE.MONTHS_6) {
      this.props.getAccountStatsRequest(
        moment().add(-5, "months").format("MM-YYYY"),
        moment().format("MM-YYYY"),
        brand,
        this.state.selectedTeam
      );
    }

    if (datePicker.value === DATE_RANGE.CUSTOM) {
      this.props.getAccountStatsRequest(
        moment(this.state.startDate).format("MM-YYYY"),
        moment(this.state.endDate).format("MM-YYYY"),
        brand,
        this.state.selectedTeam
      );
    }

    this.setState({ ...this.state, selectedBrand: brand });
  };

  private onChannelChange = (value: string) => {
    const { datePicker } = this.state;
    let channel: string | undefined = value;
    if (value === this.state.selectedChannel) {
      channel = undefined;
    }

    if (datePicker.value === DATE_RANGE.MONTHS_12) {
      this.props.getAccountStatsRequest(
        moment().add(-11, "months").format("MM-YYYY"),
        moment().format("MM-YYYY"),
        this.state.selectedBrand,
        this.state.selectedTeam,
        channel
      );
    }

    if (datePicker.value === DATE_RANGE.MONTHS_6) {
      this.props.getAccountStatsRequest(
        moment().add(-5, "months").format("MM-YYYY"),
        moment().format("MM-YYYY"),
        this.state.selectedBrand,
        this.state.selectedTeam,
        channel
      );
    }

    if (datePicker.value === DATE_RANGE.CUSTOM) {
      this.props.getAccountStatsRequest(
        moment(this.state.startDate).format("MM-YYYY"),
        moment(this.state.endDate).format("MM-YYYY"),
        this.state.selectedBrand,
        this.state.selectedTeam,
        channel
      );
    }

    this.setState({ ...this.state, selectedChannel: channel });
  };

  private onTeamChange = (value: string) => {
    const { datePicker } = this.state;
    let team: string | undefined = value;
    if (value === this.state.selectedTeam) team = undefined;
    if (datePicker.value === DATE_RANGE.MONTHS_12) {
      this.props.getAccountStatsRequest(
        moment().add(-11, "months").format("MM-YYYY"),
        moment().format("MM-YYYY"),
        this.state.selectedBrand,
        team
      );
    }

    if (datePicker.value === DATE_RANGE.MONTHS_6) {
      this.props.getAccountStatsRequest(
        moment().add(-5, "months").format("MM-YYYY"),
        moment().format("MM-YYYY"),
        this.state.selectedBrand,
        team
      );
    }

    if (datePicker.value === DATE_RANGE.CUSTOM) {
      this.props.getAccountStatsRequest(
        moment(this.state.startDate).format("MM-YYYY"),
        moment(this.state.endDate).format("MM-YYYY"),
        this.state.selectedBrand,
        team
      );
    }

    this.setState({ ...this.state, selectedTeam: team });
  };

  private toggleProgramGoalMenu = (show: boolean) => {
    this.setState({
      ...this.state,
      programGoal: { ...this.state.programGoal, showMenu: show },
    });
  };

  private onSelectProgramGoal = (key: string) => {
    this.setState({
      ...this.state,
      programGoal: {
        ...this.state.programGoal,
        showMenu: false,
        selectedKey: key,
      },
    });
  };

  render() {
    const { account, experimentTrait } = this.props;
    const {
      datePicker,
      selectedBrand,
      selectedTeam,
      selectedChannel,
      hoveredCell,
    } = this.state;

    if (!account?.stats) return null;

    const reportData = buildReportData(account.stats.range);

    return (
      <React.Fragment>
        <div className="Dashboard">
          <section className="header">
            <div>
              <h2>Program Performance</h2>
            </div>
            <div className="ctrls">
              {account &&
                account.settings.teams &&
                experimentTrait?.traits?.teams?.length > 0 && (
                  <SelectInput
                    ignoreReadOnly={true}
                    value={selectedTeam || "all teams"}
                    name="team select"
                    placeholder="select"
                    removeable={true}
                    items={
                      experimentTrait?.traits?.teams
                        ? experimentTrait?.traits?.teams.map((t: any) => {
                            return t.name;
                          })
                        : []
                    }
                    onChange={(value) => this.onTeamChange(value)}
                  />
                )}
              {account &&
                account.settings.brands &&
                experimentTrait?.traits?.brands?.length > 0 && (
                  <SelectInput
                    ignoreReadOnly={true}
                    value={selectedBrand || "all brands"}
                    name="brand select"
                    placeholder="select"
                    removeable={true}
                    items={
                      experimentTrait?.traits?.brands
                        ? experimentTrait?.traits?.brands.map((t: any) => {
                            return t.name;
                          })
                        : []
                    }
                    onChange={(value) => this.onBrandChange(value)}
                  />
                )}
              {account?.settings?.channels &&
                experimentTrait?.traits?.channels?.length > 0 && (
                  <SelectInput
                    ignoreReadOnly={true}
                    value={selectedChannel || "all channels"}
                    name="channel select"
                    placeholder="select"
                    removeable={true}
                    items={
                      experimentTrait?.traits?.channels
                        ? experimentTrait?.traits?.channels.map((t: any) => {
                            return t.name;
                          })
                        : []
                    }
                    onChange={(value) => this.onChannelChange(value)}
                  />
                )}
              <SelectInput
                ignoreReadOnly={true}
                value={datePicker.name}
                name="date_range"
                placeholder="select"
                itemKey="name"
                items={DATE_PICKER_OPTIONS}
                onChange={(value: any) => this.onDateChange(value)}
              />
              {datePicker.value === DATE_RANGE.CUSTOM && (
                <div className="custom-date-range">
                  <MonthPicker
                    key="custom_start_date"
                    startDate={this.state.startDate}
                    endDate={this.state.endDate}
                    className="form-control"
                    onChange={this.onCustomDateChange}
                  />
                </div>
              )}
            </div>
          </section>
          <hr />
          <section className="stats">
            <div className="totals">
              <ProgramGoalSelector
                programGoals={account.stats?.program_goals}
                selectedKey={this.state.programGoal.selectedKey}
                showMenu={this.state.programGoal.showMenu}
                onToggleMenu={this.toggleProgramGoalMenu}
                onSelectProgramGoal={this.onSelectProgramGoal}
              />

              <div className="total">
                <label>Tests</label>
                <div
                  className="values"
                  style={{
                    display: "grid",
                    gridTemplateColumns: "repeat(2, 1fr)",
                  }}
                >
                  <div>
                    <p className="dec">launched</p>
                    <h3>{account?.stats ? account.stats.launched : 0}</h3>
                  </div>
                  <div>
                    <p className="dec">completed</p>
                    <h3>{account?.stats ? account.stats.completed : 0}</h3>
                  </div>
                </div>
              </div>
              <div className="total">
                {/* <label>Insights</label> */}
                <div
                  className="values"
                  style={{
                    display: "grid",
                    gridTemplateColumns: "repeat(2, 1fr)",
                  }}
                >
                  <div>
                    <p className="dec">won</p>
                    <h3>{account?.stats ? account.stats.total_wins : 0}</h3>
                  </div>

                  <div>
                    <p className="dec">insights</p>
                    <h3>
                      {account?.stats?.insights
                        ? account.stats.insights.total
                        : 0}
                    </h3>
                  </div>

                  {/* <div>
                    <p className="dec" style={{ whiteSpace: "nowrap" }}>
                      Insights Per Test
                    </p>
                    <h3>
                      {account?.stats?.insights
                        ? Math.round(account.stats.insights.per_test)
                        : 0}{" "}
                      &nbsp;&nbsp;
                    </h3>
                  </div>
                  <div>
                    <p className="dec" style={{ whiteSpace: "nowrap" }}>
                      Linked to Test
                    </p>
                    <h3>
                      {account?.stats?.insights
                        ? account.stats.insights.linked
                        : 0}
                    </h3>
                  </div> */}
                </div>
              </div>
            </div>
            <div>
              <div className="pieChart">
                <PieChart
                  data={[
                    {
                      value: account?.stats ? account.stats.win_rate : 0,
                      key: 1,
                      color: "#2A88F2",
                    },
                  ]}
                  reveal={account?.stats ? account.stats.win_rate : 0}
                  lineWidth={6}
                  animate
                />
                <div className="label">
                  <div className="val">
                    {account?.stats ? account.stats.win_rate.toFixed(0) : 0}
                    <span>%</span>
                  </div>
                  <label>win rate</label>
                </div>
              </div>
            </div>
            <div className="lineChart">
              <label>Tests</label>
              <p className="dec">monthly tests completed</p>
              <FlexibleXYPlot
                xType="ordinal"
                height={240}
                className="BarChart"
                stackBy="y"
                onMouseLeave={() =>
                  this.setState({ ...this.state, hoveredCell: false })
                }
              >
                <HorizontalGridLines />
                <XAxis
                  hideLine
                  style={{
                    line: { stroke: "#FFF" },
                    ticks: { stroke: "#FFF" },
                    text: {
                      stroke: "none",
                      fill: "#2F4760",
                      fontSize: "12px",
                      fontWeight: 500,
                    },
                  }}
                />
                <YAxis hideLine />
                <VerticalBarSeries
                  animation
                  barWidth={0.2}
                  width={100}
                  data={reportData.win}
                  colorType="literal"
                  onValueMouseOver={(v: any) => {
                    this.setState({ ...this.state, hoveredCell: v });
                  }}
                ></VerticalBarSeries>
                <VerticalBarSeries
                  animation
                  barWidth={0.2}
                  width={100}
                  data={reportData.flat}
                  colorType="literal"
                  onValueMouseOver={(v: any) => {
                    this.setState({ ...this.state, hoveredCell: v });
                  }}
                />
                <VerticalBarSeries
                  animation
                  barWidth={0.2}
                  width={100}
                  data={reportData.loss}
                  colorType="literal"
                  onValueMouseOver={(v: any) => {
                    this.setState({ ...this.state, hoveredCell: v });
                  }}
                />
                {hoveredCell !== false && (
                  <Hint
                    value={hoveredCell}
                    align={{ vertical: "top", horizontal: "left" }}
                  >
                    <div className="Hint">
                      <h3>{hoveredCell.type}:</h3>
                      <p>{Math.round(hoveredCell.total)}</p>
                    </div>
                  </Hint>
                )}
              </FlexibleXYPlot>
            </div>
          </section>
          <hr />
          <section className="targeting">
            <div className="traits">
              <div className="trait">
                <DidYouNow />
                <LeaderBoard />
              </div>

              <InsightBoard />

              <div className="trait">
                {/* {insightsBoard?.latest || insightsBoard?.more_experiments ? (
                  <div className="trait">
                    <label style={{ fontSize: "18px" }}>&nbsp;</label>

                    <div
                      className="shadow body"
                      style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                        marginBottom: "28px",
                        padding: 0,
                        backgroundColor: "transparent",
                      }}
                    >
                      <div style={{ width: "100%" }}>
                        <MarketingBanner
                          imageStyle={{ width: "100%" }}
                          bannerPlacementSlug={"dashboard_banners"}
                        />
                      </div>
                    </div>
                  </div>
                ) : null} */}

                <TodayBoard />
              </div>
            </div>
          </section>
          <section></section>
        </div>
      </React.Fragment>
    );
  }
}

const loadingSelector = createLoadingSelector(["@@account/GET_ACCOUNT_STATS"]);
const insightBoardLoadingSelector = createLoadingSelector([
  "@@dashboard/GET_INSIGHT_BOARD",
]);

const mapStateToProps = ({
  app,
  account,
  experimentTrait,
  dashboard,
}: ApplicationState) => ({
  loading: loadingSelector(app.requests),
  insightBoardLoading: insightBoardLoadingSelector(app.requests),
  account,
  dashboard,
  experimentTrait,
});

const mapDispatchToProps = {
  getAccountStatsRequest,
  getExperimentsTraitsRequest,
  getInsightProposalsRequest,
  showModal,
};

const connectedPage = connect(
  mapStateToProps,
  mapDispatchToProps
)(AppPage(Home));

export { connectedPage as Home };

interface IProgramGoalStats {
  programGoals: { [key: string]: DataTypes.ProgramGoalStat };
  selectedKey?: string;
  showMenu: boolean;
  onToggleMenu(show: boolean): void;
  onSelectProgramGoal(key: string): void;
}

const ProgramGoalSelector = ({
  programGoals,
  selectedKey,
  showMenu,
  onToggleMenu,
  onSelectProgramGoal,
}: IProgramGoalStats) => {
  const selectedItem = selectedKey
    ? programGoals[selectedKey]
    : programGoals[Object.keys(programGoals)[0]];
  if (!selectedItem) return null;
  return (
    <div className="total selector">
      <label onClick={() => onToggleMenu(!showMenu)}>{selectedItem.name}</label>
      <div className="values">
        <div>
          <p className="dec">program goal</p>
          <h3>
            +{" "}
            {Helpers.formatValue(
              selectedItem.total,
              selectedItem.data_type,
              selectedItem.symbol
            )}
          </h3>
        </div>
      </div>
      {showMenu === true && (
        <div className={showMenu === true ? "menu show" : "menu"}>
          <div className="header">
            <label>select program goal</label>
          </div>
          <ul>
            {Object.keys(programGoals).map((key) => {
              return (
                <li key={key} onClick={() => onSelectProgramGoal(key)}>
                  {programGoals[key].name}
                </li>
              );
            })}
          </ul>
        </div>
      )}
    </div>
  );
};
