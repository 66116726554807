import React from 'react';

const Tips = () => (
  <React.Fragment>
    <div className="header">
      <h3>Sample Size Calculator Tips</h3>
      <p>
        You can't increase your traffic or conversion rates to accommodate (that's why we're here in the first place right?), but here are some options to
        reduce your test duration:
      </p>
    </div>
    <div className="body">
      <ul>
        <li>
          <label>Reduce the number of variations</label>
          <p>
            Why? Instead of splitting your traffic/conversions across many variations (resulting in fewer samples in each), you're focusing them into fewer
            variations (resulting more samples in each).
          </p>
        </li>
        <li>
          <label>Increase the Minimum Detectable Effect</label>
          <p>Why? A lower MDE requires more samples since you're trying to detect a small change. A higher MDE is easier to spot, and requires less samples.</p>
        </li>
        <li>
          <label>Strive for the 80% Confidence Threshold</label>
          <p>
            Why? Higher confidence gives you higher...well, confidence. Ask yourself if 95% is required for what you're trying to learn. We wouldn't feel good
            about decisions based on data that was &lt; 80% confident, which is why that's as low as we go.
          </p>
        </li>
      </ul>
    </div>
  </React.Fragment>
);

export { Tips };
