import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

type Params = {
  dataSet: any;
};

const chartOptions = {
  indexAxis: "y" as const,
  plugins: {
    title: {
      display: true,
      text: "Chart.js Bar Chart - Stacked",
    },
  },
  responsive: true,
  maintainAspectRatio: true,
  interaction: {
    mode: "index" as const,
    intersect: false,
  },
};

export const CustomDataVizHorizontalBarChart: React.FC<Params> = ({
  dataSet,
}) => {
  return (
    <Bar
      options={{
        ...chartOptions,
        plugins: {
          title: {
            display: true,
            text: dataSet.title,
            position: "top",
            color: "#2f4760",
            font: {
              weight: "400",
              size: 24,
              family: "Roboto, sans-serif",
            },
          },
        },
      }}
      data={{ labels: dataSet.labels, datasets: dataSet.dataSets }}
    />
  );
};
