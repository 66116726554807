import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Helpers, history } from "utils";
import * as DataTypes from "store/types";
import { DropdownControl, TextInput, UxrIcon } from "ui";
import { SubNav } from "ui/navs";

interface IProps {
  uxr: DataTypes.Uxr | null;
  loading: boolean;
  navIndex: number;
  currentUser: any;
  onClone(): void;
  onDelete(): void;
  onViewAttachment(): void;
  onChange(key: string, value: string): void;
}

class MastHead extends Component<IProps> {
  render() {
    const { loading, uxr, onChange } = this.props;

    if (loading) {
      return (
        <div className="MastHead placeholder">
          <ul className="breadCrumbs">
            <li>
              <p></p>
            </li>
          </ul>
          <div className="body">
            <div>
              <h2>&nbsp;</h2>
              <p className="small"></p>
            </div>
            <div className="controls">
              <p></p>
              <p></p>
            </div>
          </div>
          <div className="subNav">
            <ul>
              <li>
                <p></p>
              </li>
              <li>
                <p></p>
              </li>
            </ul>
          </div>
        </div>
      );
    }

    if (!uxr) {
      return null;
    }

    return (
      <div className="MastHead">
        <ul className="breadCrumbs">
          <li>
            <Link to="/uxr">Research</Link>
          </li>
          <li>{uxr.name}</li>
        </ul>
        <div className="body">
          <div style={{ marginLeft: "26px" }}>
            <UxrIcon />
          </div>
          <section>
            <TextInput
              className="TitleGrow"
              value={uxr.name}
              onChange={(value) => onChange("name", value)}
            >
              <h2>{uxr.name}</h2>
            </TextInput>
            <p className="dates">
              {Helpers.formatDate(uxr.start_date)} -{" "}
              {Helpers.formatDate(uxr.end_date)}
            </p>
          </section>

          <section>
            <div className="controls">
              <div>
                {this.props.currentUser.role !== "read-only" && (
                  <button
                    onClick={() => history.push(`/uxr_share/${uxr.id}`)}
                    type="button"
                    className="btn btn-primary"
                  >
                    Share this Research
                  </button>
                )}
              </div>
              <div>
                <button
                  className="btn btn-ctrl uploads"
                  onClick={this.props.onViewAttachment}
                >
                  {uxr.documents && uxr.documents.length > 0 && (
                    <div className="circle notifications">
                      {uxr.documents.length}
                    </div>
                  )}
                  <i className="fas fa-paperclip"></i>
                </button>
              </div>
              <div>
                {this.props.currentUser.role === "read-only" ? null : (
                  <DropdownControl
                    dropdownClass="w--210"
                    controlItems={[
                      {
                        name: "Delete Research Study",
                        icon: "far fa-trash-alt",
                        onClick: () => this.props.onDelete(),
                        splitAbove: true,
                        className: "danger",
                      },
                    ]}
                  />
                )}
              </div>
            </div>
          </section>
        </div>

        <SubNav
          activeIdx={this.props.navIndex}
          navItems={[
            { name: "Study Design", link: `/uxr/${uxr.id}` },
            { name: "Insights", link: `/uxr/${uxr.id}/insights` },
            { name: "Areas of Focus", link: `/uxr/${uxr.id}/areasoffocus` },
            { name: "Supporting Data", link: `/uxr/${uxr.id}/supportingdata` },
          ]}
        />
      </div>
    );
  }
}

export { MastHead };
