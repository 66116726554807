import React, { useEffect, useMemo, useState } from "react";
import * as DataTypes from "store/types";
import { DeleteMetricContainer, MetricTabContent, TabsContent } from "./style";
import { SelectInput } from "ui";

type Params = {
  active: boolean;
  selectedReports: DataTypes.Report[];
  onSelectMetric: (metrics: DataTypes.DataVizMetric[]) => void;
  previousSelected?: DataTypes.DataVizMetric[];
};

export const MetricTab: React.FC<Params> = ({
  active,
  selectedReports,
  onSelectMetric,
  previousSelected,
}) => {
  const [metrics, setMetrics] = useState<DataTypes.DataVizMetric[]>(() =>
    previousSelected
      ? previousSelected
      : [
          {
            name: "New Metric",
            metric_ids: [],
            kind: "value",
            chart_kind: "horizontal_column",
          },
        ]
  );

  const reportMetrics = useMemo(() => {
    const result: { [k: string]: any } = {};

    selectedReports.forEach((report) => {
      const metricResult: { [k: string]: any } = {};

      report.metrics.forEach((metric) => {
        metricResult[metric.id as string] = metric;
      });

      result[report.id as string] = metricResult;
    });

    return result;
  }, [selectedReports]);

  useEffect(() => {
    onSelectMetric(metrics);
  }, [metrics]);

  const getMetricFromReport = (reportId: string, metricIds: string[]) => {
    return metricIds
      .map((metricId) => {
        if (!reportMetrics[reportId][metricId]) {
          return null;
        }

        return reportMetrics[reportId][metricId].name;
      })
      .filter((e) => e)[0];
  };

  const addMetricIdToMetric = (
    metricId: string,
    index: number,
    reportId: string
  ) => {
    const currentMetrics = metrics[index].metric_ids;
    let newMetrics = [];
    const metricsCopy = [...metrics];

    const currentReportMetrics = Object.keys(reportMetrics[reportId]);

    const metricToRemove = currentMetrics.filter(
      (mId) => currentReportMetrics.lastIndexOf(mId) !== -1
    )[0];

    newMetrics = [
      ...currentMetrics.filter((mId) => mId !== metricToRemove),
      metricId,
    ];

    metricsCopy[index].metric_ids = newMetrics;

    setMetrics(metricsCopy);
  };

  const removeMetric = (index: number) => {
    setMetrics(metrics.filter((_, i) => i !== index));
  };

  const updateMetricName = (index: number, newName: string) => {
    const metricsCopy = [...metrics];

    metricsCopy[index].name = newName;

    setMetrics(metricsCopy);
  };

  const updateMetricKind = (
    index: number,
    newKind: DataTypes.DataVizMetricKind
  ) => {
    const metricsCopy = [...metrics];

    metricsCopy[index].kind = newKind;

    setMetrics(metricsCopy);
  };

  return (
    <TabsContent className={`${active ? "active" : ""}`}>
      <MetricTabContent>
        {metrics.map((metric: DataTypes.DataVizMetric, index: number) => (
          <div key={`data_vis_metric_metric_${index}_${metric.name}`}>
            <DeleteMetricContainer>
              <button
                className="btn btn-control"
                type="button"
                title={`delete metric ${metric.name}`}
                onClick={() => removeMetric(index)}
              >
                <i className="far fa-trash-alt" />
              </button>
            </DeleteMetricContainer>
            <div className="form-group">
              <label htmlFor="custom_data_viz_name">Metric Name *</label>

              <input
                defaultValue={metric.name}
                id="custom_data_viz_name"
                className="form-control"
                placeholder="Add a name here"
                onBlur={(event) => {
                  updateMetricName(index, event.target.value);
                }}
              />
            </div>

            <div className="form-group">
              <label>What to Display *</label>

              <SelectInput
                value={metric.kind}
                name="metric_kind_selector"
                placeholder="select which "
                items={["value", "lift"]}
                onChange={(kind) => updateMetricKind(index, kind)}
              />
            </div>

            <div>
              {selectedReports.map((report: DataTypes.Report) => (
                <div
                  key={`data_vis_metric_metric_${index}_${metric.name}_report_${report.id}`}
                  className="form-group"
                >
                  <label>{report.name}</label>
                  <SelectInput
                    value={getMetricFromReport(
                      report.id as string,
                      metric.metric_ids
                    )}
                    name="metric_selector"
                    placeholder="Select Metric from This Report"
                    itemKey="name"
                    items={
                      selectedReports.filter(
                        (filterReport) => filterReport.id === report.id
                      )[0].metrics || []
                    }
                    onChange={(result: DataTypes.Metric) => {
                      addMetricIdToMetric(
                        result.id as string,
                        index,
                        report.id as string
                      );
                    }}
                  />
                </div>
              ))}
            </div>
          </div>
        ))}
      </MetricTabContent>

      <button
        type="button"
        className="btn btn-primary inverse"
        onClick={() => {
          setMetrics([
            ...metrics,
            {
              name: "New Metric",
              metric_ids: [],
              kind: "value",
              chart_kind: "horizontal_column",
            },
          ]);
        }}
      >
        Add metric
      </button>
    </TabsContent>
  );
};
