import React from 'react';

export enum OrderDirection {
  ASC = 'ASC',
  DESC = 'DESC',
  NONE = ''
};

interface IOrderProps {
  orderDirection?: OrderDirection;
  className?: String;
}

const isUpsideDown = (orderDirection: OrderDirection) => {
  if (orderDirection === OrderDirection.DESC) {
    return '';
  }

  return 'rotate--upsidedown';
}

export const OrderIcon = ({ orderDirection = OrderDirection.DESC, className = '' }: IOrderProps) => {
  if (orderDirection === OrderDirection.NONE) {
    return null;
  }

  return <svg className={ `${className} rotate ${isUpsideDown(orderDirection)}` } height="24" viewBox="0 0 24 24" width="24">
    <path d="M0 0h24v24H0z" fill="none"/>
    <path d="M7 10l5 5 5-5z"/>
  </svg>
};
