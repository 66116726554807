import React from "react";
import { connect } from "react-redux";
import { ApplicationState } from "store/types";
import { AppPage, AppPageProps } from "comps/pages";

import "css/Screens.scss";
import { Scrollable } from "comps/base/scrollable";
import { FilterBar } from "./comps/filter_bar";
import { createLoadingSelector } from "store/selectors";
import {
  getIdeaBoardsManagementRequest,
  clearIdeaBoardManagement,
  reorderIdeaBoardRequest,
} from "store/idea_board_management/actions";
import InfiniteScroll from "comps/infinite_scroll";
import { List } from "./comps/list";
import * as DataTypes from "store/types";
import queryString from "query-string";
import _ from "lodash";
import { history, Helpers } from "utils";
import { OrderDirection } from "ui/order_icon";
import { Sidebar } from "./comps/sidebar";

import { getIdeaBoardsTraitsRequest } from "store/idea_board_trait/actions";
import { CustomLoader } from "ui/loaders";
import { showModal } from "../../../store/app/actions";
import { ExportIdeas } from "./comps/modals/export_ideas";
import { exportIdeasRequest } from "../../../store/idea_board/actions";

interface IProps extends AppPageProps {
  router: any;
  currentUser: any;
  collections: any;
  name: string;
  showModal: (component: React.ComponentType<any>, options: any) => void;
  exportIdeasRequest: (
    queryString: string,
    fields: string[],
    boardId: string
  ) => void;
  loading: boolean;
  reorderLoading: boolean;
  getIdeaBoardsManagementRequest: (
    options?: DataTypes.ApiListOptions,
    scrollOpts?: { append: boolean }
  ) => void;
  clearIdeaBoardManagement: () => void;
  getIdeaBoardsTraitsRequest: () => void;
  reorderIdeaBoardRequest: (
    id: string,
    position: number,
    currentDirection: OrderDirection
  ) => void;
  exportLoading: boolean;
}

interface IState {
  selectedIdx: number;
  selectedFilters: { [key: string]: Array<string> };
  currentOrder: string;
  limit: number;
  search: string;
  resetingFilters: boolean;
  order: {
    [key: string]: OrderDirection;
  };
}

class IdeaBoards extends Scrollable<IProps, IState> {
  static defaultProps: any;

  constructor(props: IProps) {
    super(props);

    const query = queryString.parse(this.props.location.search, {
      arrayFormat: "bracket",
    });
    let selectedFilters: { [key: string]: Array<any> } = {};

    if (Array.isArray(query.q)) {
      selectedFilters = Helpers.getFiltersFromQueryString(query.q);
    }

    const { orderBy, orderDirection } = query;

    let order = {
      customOrder: OrderDirection.NONE,
      name: OrderDirection.NONE,
      description: OrderDirection.NONE,
      createdAt: OrderDirection.NONE,
      updatedAt: OrderDirection.NONE,
    };

    if (orderBy) {
      order = { ...order, [orderBy as any]: orderDirection };
    }

    this.state = {
      selectedFilters,
      selectedIdx: 0,
      search: "",
      currentOrder: (orderBy as string) || "",
      order,
      resetingFilters: false,
      limit: 25,
    };

    this.fetchMore = _.debounce(this.fetchMore, 500, {
      leading: true,
      trailing: false,
    });
  }

  componentDidMount = () => {
    this.props.getIdeaBoardsManagementRequest({ sort: "updated_at" });
    this.props.getIdeaBoardsTraitsRequest();
    super.componentDidMount();
  };

  private getSortString() {
    if (!this.state.currentOrder) {
      return "updated_at";
    }

    const direction =
      this.state.order[this.state.currentOrder] === OrderDirection.ASC
        ? "-"
        : "";

    return `${direction}${Helpers.camelToSnake(this.state.currentOrder)}`;
  }

  private resetetOrders() {
    return Object.keys(this.state.order).reduce(
      (acc, current) => ({ ...acc, [current]: OrderDirection.NONE }),
      {}
    );
  }

  private onExportClick() {
    this.props.showModal(ExportIdeas, {
      onSubmit: this.onExportIdeas,
      saving: this.props.exportLoading,
      bbMethodEnable: this.props.account?.settings.use_bb_methods,
    });
  }

  private onExportIdeas = (fields: string[]) => {
    if (!fields || fields.length === 0) {
      return;
    }

    // this.props.exportIdeasRequest(this.props.location.search, fields);
  };

  private changeOrder = (orderBy: string, currentDirection: OrderDirection) => {
    let newDirection: OrderDirection;

    if (orderBy !== "customOrder") {
      if (currentDirection === OrderDirection.DESC) {
        newDirection = OrderDirection.ASC;
      } else {
        newDirection = OrderDirection.DESC;
      }
    } else {
      newDirection = OrderDirection.ASC;
    }

    const order = { ...this.resetetOrders(), [orderBy]: newDirection };
    this.setState(
      {
        order,
        currentOrder: orderBy,
      },
      () => {
        const query = queryString.parse(this.props.location.search, {
          arrayFormat: "bracket",
        });
        query.orderBy = orderBy;
        query.orderDirection = newDirection;

        const qStr = queryString.stringify(query, {
          encode: true,
          arrayFormat: "bracket",
        });
        history.push(`?${qStr}`);

        const q = queryString.parse(this.props.location.search, {
          arrayFormat: "bracket",
        });
        this.props.clearIdeaBoardManagement();
        this.props.getIdeaBoardsManagementRequest({
          limit: 25,
          sort: this.getSortString(),
          q: q.q,
        });
      }
    );
  };

  private onFilterCheck = (checked: boolean, type: string, value: string) => {
    let q = queryString.parse(this.props.location.search, {
      arrayFormat: "bracket",
    });

    if (checked) {
      if (q.q && Array.isArray(q.q)) {
        q.q.push(`${type}=${value}`);
      } else {
        q.q = [`${type}=${value}`];
      }
    } else {
      if (q.q && Array.isArray(q.q)) {
        q.q = q.q.filter((item: string) => item !== `${type}=${value}`);
      }
    }

    const qStr = queryString.stringify(q, {
      encode: true,
      arrayFormat: "bracket",
    });

    if (Array.isArray(q.q)) {
      this.setState({
        ...this.state,
        selectedFilters: Helpers.getFiltersFromQueryString(q.q),
      });
    }

    history.push(`/ideas/boards?${qStr}`);

    this.props.getIdeaBoardsManagementRequest({
      q: q.q,
      month: q.month as string,
    });
  };

  private clearFilters = () => {
    const qStr = queryString.stringify(
      {},
      {
        encode: true,
        arrayFormat: "bracket",
      }
    );

    history.push(`/ideas/boards?${qStr}`);
    this.setState(
      {
        selectedFilters: {},
        resetingFilters: true,
      },
      () => {
        this.setState({
          resetingFilters: false,
        });
      }
    );
    this.props.getIdeaBoardsManagementRequest({
      q: null,
      sort: this.getSortString(),
    });
  };

  fetchMore = (page: any, append = true) => {
    const {
      collections: { idea_boards_management },
    } = this.props;

    if (
      (idea_boards_management &&
        idea_boards_management.data.length > 0 &&
        idea_boards_management.has_more) ||
      !append
    ) {
      const q = queryString.parse(this.props.location.search, {
        arrayFormat: "bracket",
      });

      const options = {
        q: q.q,
        sort: "updated_at",
        page,
        limit: this.state.limit,
      };

      this.props.getIdeaBoardsManagementRequest(options, { append });
    }
  };

  private onReorder = (id: string, index: number) => {
    this.props.reorderIdeaBoardRequest(
      id,
      index,
      this.state.order[this.state.currentOrder]
    );
  };

  itemSelected = (itemIndex: number) => {
    this.setState({ ...this.state, selectedIdx: -1 }, () => {
      this.setState({ ...this.state, selectedIdx: itemIndex });
    });
  };

  private onSearchSubmit = (search: string) => {
    let q = queryString.parse(this.props.location.search, {
      arrayFormat: "bracket",
    });

    if (search) {
      if (q.q && Array.isArray(q.q)) {
        if (q.q.filter((item: string) => /name=/.test(item)).length)
          // there can only be on search key
          q.q = q.q.map((item: string) =>
            /name=/.test(item) ? `name=${search}` : item
          );
        else q.q.push(`name=${search}`);
      } else {
        q.q = [`name=${search}`];
      }
    } else {
      if (q.q && Array.isArray(q.q)) {
        q.q = q.q.filter((item: string) => !/name=/.test(item));
      }
    }

    if (Array.isArray(q.q))
      this.setState({
        ...this.state,
        search,
        selectedFilters: Helpers.getFiltersFromQueryString(q.q),
      });

    const qStr = queryString.stringify(q, {
      encode: true,
      arrayFormat: "bracket",
    });
    history.push(`/ideas/boards?${qStr}`);
    this.props.getIdeaBoardsManagementRequest(
      { sort: this.getSortString(), q: q.q },
      { append: false }
    );
  };

  buildContent = () => {
    const { collections, loading } = this.props;
    const { selectedIdx } = this.state;

    return (
      <section className="List Ideas">
        <InfiniteScroll
          pageStart={1}
          loadMore={this.fetchMore}
          hasMore={
            (collections.idea_boards_management &&
              collections.idea_boards_management.has_more) ||
            false
          }
          initialLoad={true}
          ref={(ref) => (this.listRef = ref)}
          loader={
            <CustomLoader
              key={"loader"}
              text="Wait while we load more boards for you"
            />
          }
          useWindow={false}
        >
          <List
            currentUser={this.props.currentUser}
            onSearchChange={(search: string) =>
              this.setState({ ...this.state, search })
            }
            onExport={this.onExportClick.bind(this)}
            search={this.state.search}
            onSearchSubmit={this.onSearchSubmit}
            onReorder={this.onReorder}
            reorderable={
              !this.props.reorderLoading &&
              this.state.currentOrder === "customOrder"
            }
            loading={
              loading && collections.idea_boards_management === undefined
            }
            ideaBoards={
              collections.idea_boards_management &&
              collections.idea_boards_management.data
            }
            selectedIdx={selectedIdx}
            onSelect={this.itemSelected}
          />
        </InfiniteScroll>
      </section>
    );
  };

  onLimitChange = (limit = 25) => {
    setTimeout(() => {
      if (this.props.loading) {
        this.onLimitChange(limit);
        return;
      }

      this.setState({ limit: limit }, () => {
        this.fetchMore(1, false);
      });
    }, 500);
  };

  render() {
    const { selectedFilters, selectedIdx } = this.state;
    const { collections } = this.props;

    return (
      <React.Fragment>
        {this.state.resetingFilters ? null : (
          <FilterBar
            currentUser={this.props.currentUser}
            onSorting={this.changeOrder}
            onLimitChange={this.onLimitChange}
            sortingValues={this.state.order}
            onCheck={this.onFilterCheck}
            selectedFilters={selectedFilters}
            onClearFilters={this.clearFilters}
          />
        )}

        {this.buildContent()}

        {collections.idea_boards_management &&
          collections.idea_boards_management.data &&
          collections.idea_boards_management.data[selectedIdx] && (
            <Sidebar
              currentUser={this.props.currentUser}
              ideaBoard={collections.idea_boards_management.data[selectedIdx]}
            />
          )}
      </React.Fragment>
    );
  }
}

IdeaBoards.defaultProps = {
  name: "idea_boards_management",
};

const loadingSelector = createLoadingSelector([
  "@@idea_board_management/GET_IDEA_BOARDS_MANAGEMENT",
]);
const reorderLoadingSelector = createLoadingSelector(["@@idea_board/REORDER"]);
const exportLoadingSelector = createLoadingSelector(["@@idea/EXPORT_IDEAS"]);

const mapStateToProps = ({ router, app, collections }: ApplicationState) => ({
  router: router,
  loading: loadingSelector(app.requests),
  reorderLoading: reorderLoadingSelector(app.requests),
  exportLoadingSelector: exportLoadingSelector(app.requests),
  collections,
});

const mapDispatchToProps = {
  getIdeaBoardsManagementRequest,
  clearIdeaBoardManagement,
  getIdeaBoardsTraitsRequest,
  reorderIdeaBoardRequest,
  showModal,
  exportIdeasRequest,
};

const connectedPage = connect(
  mapStateToProps,
  mapDispatchToProps
)(AppPage(IdeaBoards));

export { connectedPage as IdeaBoards };
