import React, { Component } from "react";
import * as DataTypes from "store/types";
import { TextInput, StarControl, MultiSelectInput, Avatar, UxrIcon } from "ui";
import { Helpers } from "utils";
import TimeAgo from "timeago-react";
import { createUxrsTraitsRequest } from "store/uxr_trait/actions";

import "css/Experiments.scss";
import { EditorInput } from "ui/editor_input/editor_input";
import { connect } from "react-redux";
import { MethodologySelector } from "ui/methodology_selector";

interface IProps {
  loading: boolean;
  currentUser: any;
  account: DataTypes.Account | null;
  uxr: DataTypes.Uxr | null;
  traits: { [key: string]: Array<DataTypes.Trait> } | null;
  onChange(
    uxr: DataTypes.Uxr,
    key: string,
    value: string | Array<string> | boolean
  ): void;
  createUxrsTraitsRequest: (
    uxrId: string,
    traitName: string,
    values: string[]
  ) => void;
}

class Aside extends Component<IProps> {
  render() {
    const { account, loading, uxr, traits } = this.props;

    if (loading) {
      return (
        <div className="Experiment placeholder">
          <section className="header"></section>
          <section className="details">
            <h4>&nbsp;</h4>
            <div className="status"></div>
            <p></p>
            <p></p>
            <p></p>
          </section>
          <hr />
          <section></section>
        </div>
      );
    }

    if (!uxr) return null;

    return (
      <div className="Experiment">
        <section className="header">
          <div className="left"></div>
          <div className="right">
            <div className="controls">
              <StarControl
                starred={uxr.star}
                onClick={(value) => this.props.onChange(uxr, "star", value)}
              />
            </div>
          </div>
        </section>
        <section className="title-bar">
          <div>
            <p className="date">
              Updated <TimeAgo datetime={uxr.updatedAt} live={false} />
            </p>
            <TextInput
              readonly={this.props.currentUser.role === "read-only"}
              key={["name", uxr.id].join("_")}
              value={uxr.name || ""}
              onChange={(value) => this.props.onChange(uxr, "name", value)}
            >
              <h2>{uxr.name}</h2>
            </TextInput>
            <p className="date">
              {Helpers.formatDate(uxr.startDate)} -{" "}
              {Helpers.formatDate(uxr.endDate)}
            </p>
          </div>

          <div>
            <UxrIcon />
          </div>
        </section>

        <section>
          <div className="userInfo">
            <div className="img">
              <div className="avatar-stack">
                {uxr.contributors &&
                  uxr.contributors.reverse().map((contributor: any, idx) => {
                    if (contributor.user === null) {
                      return null;
                    }

                    if (contributor.user) {
                      return (
                        <div
                          style={{
                            position: "relative",
                            left: `${8 * idx - 8}px`,
                          }}
                          key={`user_${contributor.user.id}`}
                        >
                          <Avatar user={contributor.user} showHover={true} />
                        </div>
                      );
                    }
                    return (
                      <div
                        style={{
                          position: "relative",
                          left: `${8 * idx - 8}px`,
                        }}
                        key={`user_${contributor.id}`}
                      >
                        <Avatar user={contributor} showHover={true} />
                      </div>
                    );
                  })}
                {uxr.user && (
                  <div style={{ position: "relative" }}>
                    <Avatar
                      user={uxr.user}
                      showHover={true}
                      className="focus author"
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        </section>

        <section className="questions">
          <div className="question">
            <label>Summary</label>
            <EditorInput
              onBlur={(value) => this.props.onChange(uxr, "description", value)}
              content={uxr.description}
            />
          </div>

          <div className="question" style={{ marginBottom: "100px" }}>
            <label>Methodology</label>
            <div style={{ padding: "0 16px" }}>
              <MethodologySelector
                value={uxr.methodology}
                onChange={(value) =>
                  this.props.onChange(uxr, "methodology", value)
                }
              />
            </div>
          </div>

          <div className="question">
            <label>Methods</label>
            <MultiSelectInput
              key={["methods_", uxr.id].join("_")}
              activeItems={uxr.methodKinds}
              name="methodKinds"
              items={
                traits && traits["method_kinds"]
                  ? Helpers.uniqueArray(
                      traits["method_kinds"].map((t) => {
                        return t.name;
                      })
                    )
                  : []
              }
              onChange={(values) =>
                this.props.createUxrsTraitsRequest(
                  uxr.id || "",
                  "method_kinds",
                  values
                )
              }
            />
          </div>

          <div className="question">
            <label>Pages</label>
            <MultiSelectInput
              key={["pages_", uxr.id].join("_")}
              activeItems={uxr.pages}
              name="pages"
              items={
                traits && traits["pages"]
                  ? Helpers.uniqueArray(
                      traits["pages"].map((t) => {
                        return t.name;
                      })
                    )
                  : []
              }
              onChange={(values) =>
                this.props.createUxrsTraitsRequest(
                  uxr.id || "",
                  "pages",
                  values
                )
              }
            />
          </div>

          {account && account.settings.brands && (
            <div className="question">
              <label>Brands</label>
              <MultiSelectInput
                key={["brands_", uxr.id].join("_")}
                activeItems={uxr.brands || []}
                name="brands"
                items={
                  traits && traits["brands"]
                    ? Helpers.uniqueArray(
                        traits["brands"].map((t) => {
                          return t.name;
                        })
                      )
                    : []
                }
                onChange={(values) =>
                  this.props.createUxrsTraitsRequest(
                    uxr.id || "",
                    "brands",
                    values
                  )
                }
              />
            </div>
          )}
          {account && account.settings.teams && (
            <div className="question">
              <label>Teams</label>

              <MultiSelectInput
                key={["teams_", uxr.id].join("_")}
                activeItems={uxr.teams || []}
                name="teams"
                items={
                  traits && traits["teams"]
                    ? Helpers.uniqueArray(
                        traits["teams"].map((t) => {
                          return t.name;
                        })
                      )
                    : []
                }
                onChange={(values) =>
                  this.props.createUxrsTraitsRequest(
                    uxr.id || "",
                    "teams",
                    values
                  )
                }
              />
            </div>
          )}
          {account && account.settings.channels && (
            <div className="question">
              <label>Channels</label>
              <MultiSelectInput
                key={["channels_", uxr.id].join("_")}
                activeItems={uxr.channels || []}
                name="channels"
                items={
                  traits && traits["channels"]
                    ? Helpers.uniqueArray(
                        traits["channels"].map((t) => {
                          return t.name;
                        })
                      )
                    : []
                }
                onChange={(values) =>
                  this.props.createUxrsTraitsRequest(
                    uxr.id || "",
                    "channels",
                    values
                  )
                }
              />
            </div>
          )}

          {uxr.user && (
            <div className="question">
              <label>Creator</label>
              <p>
                {uxr.user.firstName || uxr.user.first_name}{" "}
                {uxr.user.lastName || uxr.user.last_name}
              </p>
            </div>
          )}
        </section>
      </div>
    );
  }
}

const mapStateToProps = ({ router }: DataTypes.ApplicationState) => ({
  router,
});

const mapDispatchToProps = {
  createUxrsTraitsRequest,
};

const connectedPage = connect(mapStateToProps, mapDispatchToProps)(Aside);

export { connectedPage as Aside };
