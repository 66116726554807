import { ApiClient } from "./client/api_client";

class Rattings {
  static async create(body: any) {
    return await ApiClient.post("/v1/rattings", { ratting: body });
  }

  static async update(id: string, body: any) {
    return await ApiClient.put(`/v1/rattings/${id}`, { ratting: body });
  }

  static async list() {
      return await ApiClient.get(`/v1/rattings`);
  }
}

export { Rattings };
