import React, { Component } from "react";
import TimeAgo from "timeago-react";
import { Link } from "react-router-dom";
import * as DataTypes from "store/types";
import { ExperimentAvatar, ExperimentStatusIcon } from "ui";
import { Helpers } from "utils";
import {
  DragDropContext,
  Draggable,
  Droppable,
  DropResult,
  ResponderProvided,
} from "react-beautiful-dnd";

interface IProps {
  loading: boolean;
  experiments: Array<DataTypes.Experiment> | null;
  selectedIdx: number;
  onSelect(itemIndex: number): void;
  onReorder(id: string, position: number): void;
  reorderable: boolean;
  onSelect(itemIndex: number): void;
}

class List extends Component<IProps> {
  onDragEnd = (result: DropResult, provided: ResponderProvided) => {
    const { draggableId: id, destination, reason } = result;

    if (reason !== "DROP" || !destination) {
      return;
    }

    const { index } = destination;

    this.props.onReorder(id, index);
  };

  getDragStyle = (isDragging: boolean, draggableStyle: any) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: "none",

    // change background colour if dragging
    //background: isDragging ? 'lightgreen' : 'grey',

    // styles we need to apply on draggables

    ...draggableStyle,
  });

  render() {
    const { loading, experiments, selectedIdx } = this.props;

    if (loading) {
      return (
        <ul className="ListItem placeholder">
          <LoadingListItems count={8} />
        </ul>
      );
    }

    return (
      <ul className="ListItem">
        <DragDropContext onDragEnd={this.onDragEnd}>
          <Droppable droppableId="1">
            {(provided, snapshot) => (
              <div className="column" ref={provided.innerRef}>
                {provided.placeholder}

                {experiments &&
                  experiments.map(
                    (experiment: DataTypes.Experiment, idx: number) => (
                      <Draggable
                        isDragDisabled={!this.props.reorderable}
                        key={`dragabble_${experiment.id}`}
                        index={idx}
                        draggableId={experiment.id || ""}
                      >
                        {(provided, snapshot) => (
                          <div
                            className={
                              snapshot.isDragging ? "item dragging" : "item"
                            }
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            style={this.getDragStyle(
                              snapshot.isDragging,
                              provided.draggableProps.style
                            )}
                          >
                            <ListItem
                              key={"li_" + experiment.id}
                              experiment={experiment}
                              selected={idx === selectedIdx}
                              onItemSelect={() => this.props.onSelect(idx)}
                            />
                          </div>
                        )}
                      </Draggable>
                    )
                  )}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </ul>
    );
  }
}

export { List };

interface IListItemProps {
  experiment: DataTypes.Experiment;
  selected: boolean;
  onItemSelect(): void;
}

const ListItem = ({ experiment, selected, onItemSelect }: IListItemProps) => {
  return (
    <li
      id={"experiment_" + experiment.id}
      className={selected === true ? "active" : ""}
      onClick={onItemSelect}
    >
      <div className="left">
        <ExperimentAvatar experiment={experiment} />
      </div>
      <div className="body">
        <h4 className="title">
          <Link to={`/tests/${experiment.id}`}>{experiment.name}</Link>
        </h4>
        <p className="desc">{experiment.description.blocks[0].text}</p>
        <p className="desc mt-2">
          Updated <TimeAgo datetime={experiment.updatedAt} live={false} />
        </p>
      </div>
      <div className="right">
        <i className={experiment.star === true ? "fa fa-star active" : "fa"} />
        <ExperimentStatusIcon status={experiment.status} />
        {experiment.impactValue !== 0 &&
          experiment.programGoals &&
          experiment.programGoals[0] && (
            <React.Fragment>
              <p>
                {Helpers.formatValue(
                  (experiment.programGoals[0] as any).impactValue,
                  (experiment.programGoals[0] as any).programGoal.dataType,
                  (experiment.programGoals[0] as any).programGoal.symbol
                )}
              </p>
              <p className="text-capitalize">
                {(experiment.programGoals[0] as any).programGoal.name}{" "}
                {experiment.programGoals.length > 1
                  ? `+${experiment.programGoals.length - 1}`
                  : ""}
              </p>
            </React.Fragment>
          )}
      </div>
    </li>
  );
};

interface ILoadingListItemProps {
  count: number;
}

const LoadingListItems = ({ count }: ILoadingListItemProps): any => {
  let elem = [];

  for (let i = 0; i < count; i++) {
    elem.push(
      <li key={"li_" + i}>
        <div className="body">
          <h4 className="title">&nbsp;</h4>
          <p></p>
          <p></p>
          <p></p>
        </div>
        <div className="right">
          <div className="status"></div>
          <p></p>
          <p></p>
        </div>
      </li>
    );
  }

  return elem;
};
