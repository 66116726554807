import React, { useMemo, useState } from "react";
import * as DataTypes from "store/types";
import { Helpers } from "../../../utils";

type ProgramGoalProps = {
  experiment: DataTypes.Experiment;
  className?: string;
  style?: any;
};

export const ProgramGoalsList: React.FC<ProgramGoalProps> = ({
  experiment,
  className,
  style,
}) => {
  const [currentGoalIndex, selectCurrentGoalIndex] = useState<number>(0);

  const currentGoal = useMemo(() => {
    return experiment.program_goals[currentGoalIndex];
  }, [currentGoalIndex, experiment]);

  return (
    <div className={className || ""} style={style || {}}>
      {!!currentGoal && !!currentGoal.impact_value && (
        <div className="result">
          <h2 className="positive">
            {Helpers.formatValue(
              currentGoal.impact_value,
              currentGoal.program_goal.data_type,
              currentGoal.program_goal.symbol
            )}
          </h2>
          <label>annualized {currentGoal.program_goal.name}</label>
        </div>
      )}

      {experiment.program_goals.length > 1 && (
        <ul className="ImageIndicator">
          {experiment.program_goals.map((goal, index) => {
            return (
              <li
                style={{
                  cursor: "pointer",
                  backgroundColor:
                    currentGoalIndex === index ? "#0056b3" : "#eaeaea",
                }}
                onClick={() => {
                  selectCurrentGoalIndex(index);
                }}
                key={["case_study_program_goals", goal.id].join("_")}
              ></li>
            );
          })}
        </ul>
      )}
    </div>
  );
};
