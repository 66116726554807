import React, { Component } from "react";
import { FormField } from "./";

interface RadioButtonOption {
  value: string;
  TextComponent?: React.ComponentType<any>;
}

interface Props {
  field: FormField;
  className: string;
  options: RadioButtonOption[];
  hasError?: boolean | null;
  onChange(e: any): any;
}

class RadioButton extends Component<Props> {
  public render() {
    const { className, options, onChange } = this.props;
    const { value, valid, error, name } = this.props.field;
    const cn = valid === false ? className + " error" : className;

    return (
      <>
        <div style={{ display: "flex", flexWrap: "wrap", width: "100%" }}>
          {options.map(
            ({ TextComponent, value: optionValue }: RadioButtonOption) => (
              <div
                style={{ width: "50%" }}
                key={`radio-button-option-${optionValue}`}
              >
                <input
                  type="radio"
                  name={name}
                  id={`radio-button-option-${optionValue}`}
                  className={cn}
                  style={{ width: 20 }}
                  onChange={(event) => onChange(event)}
                  value={optionValue}
                  checked={value === optionValue}
                />
                <p className="d-inline ml-2">
                  {TextComponent ? <TextComponent /> : optionValue}
                </p>
              </div>
            )
          )}
        </div>
        {valid === false && <div className="error">{error}</div>}
        {this.props.hasError && valid && <div className="error">{error}</div>}
      </>
    );
  }
}

export { RadioButton };
