import React, { Component } from "react";
import ReactGA from "react-ga4";
import { Route } from "react-router-dom";

interface IProps {
  location: any;
  options: any;
}

class GoogleAnalytics extends Component<IProps> {
  componentDidMount() {
    this.logPageChange(
      this.props.location.pathname,
      this.props.location.search
    );
  }

  componentDidUpdate(prevProps: IProps) {
    const {
      location: { pathname, search },
    } = this.props;

    const isDifferentPathname = pathname !== prevProps.location.pathname;
    const isDifferentSearch = search !== prevProps.location.search;

    if (isDifferentPathname || isDifferentSearch) {
      this.logPageChange(pathname, search);
    }
  }

  private logPageChange(pathname: any, search = "") {
    const page = pathname + search;
    const { location } = window;
    ReactGA.set({
      page,
      location: `${location.origin}${page}`,
      ...this.props.options,
    });
    ReactGA.send({ hitType: "pageview", page: page });
  }

  render() {
    return null;
  }
}

const RouteTracker = () => <Route component={GoogleAnalytics} />;

const init = (options = {}) => {
  const gaConfigKey = process.env.REACT_APP_GA_4_CONFIG_KEY;

  if (gaConfigKey) {
    ReactGA.initialize(gaConfigKey);
  }

  return !!gaConfigKey;
};

export default {
  GoogleAnalytics,
  RouteTracker,
  init,
};
