import { Reducer } from 'redux';
import * as DataTypes from '../types';
import { ActionTypes } from './types';

const initialState: DataTypes.Worksheet | null = null;

const reducer: Reducer<DataTypes.Worksheet | null> = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.CREATE_WORKSHEET_SUCCESS:
    case ActionTypes.GET_WORKSHEET_SUCCESS:
    case ActionTypes.GET_SHARE_WORKSHEET_SUCCESS:
    case ActionTypes.UPDATE_WORKSHEET_SUCCESS: {
      return action.payload.response;
    }
    case ActionTypes.CHANNEL_UPDATE_WORKSHEET_SUCCESS: {
      if (!state) {
        return state;
      }

      const oldContent = state.content;

      return {
        ...state,
        content: {
          ...oldContent,
          ...action.payload.content,
        },
      }
    }
    case ActionTypes.RESET_WORKSHEET: {
      return null;
    }
    default: {
      return state;
    }
  }
};

export { reducer as worksheetReducer };
