import styled from "styled-components";

export const Page = styled("section")`
  display: flex;
  padding: 1rem;
  justify-content: center;
  width: 100%;
`;

export const Grid = styled("div")`
  margin-top: 4rem;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 1rem 1rem;
  height: fit-content;
  width: 100%;

  @media (min-width: 1200px) {
    max-width: 1140px;
  }

  @media (min-width: 992px) {
    max-width: 960px;
  }

  @media (min-width: 768px)
    max-width: 720px;
  }

  @media (min-width: 576px)
    max-width: 540px;
  }
`;

export const GridItem = styled("div")<{ clickable: boolean }>`
  box-shadow: 6px 6px 6px #eaeaea !important;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: ${(props) => (props.clickable ? "pointer" : "default")};
`;

export const NewBadge = styled("div")<{ active: boolean }>`
  height: 2rem;
  position: relative;
  width: 100%;

  &:before {
    content: "${(props) => (props.active ? "New!" : " ")}";
    left: 0;
    top: 0;
    height: ${(props) => (props.active ? "2rem" : 0)};
    position: absolute;
    background-color: #f5a623;
    padding-right: 1rem;
    padding-left: 1rem;
    border-top-right-radius: 0.5rem;
    border-bottom-right-radius: 0.5rem;
    display: flex;
    font-size: 12px;
    align-items: center;
    justify-content: center;
    color: white;
    font-weight: 700;
  }
`;

export const CommingSoonBadge = styled("div")<{ active: boolean }>`
  height: 2rem;
  position: relative;
  width: 100%;
  margin-bottom: 1rem;

  &:before {
    content: "${(props) => (props.active ? "Coming Soon!" : " ")}";
    right: 0;
    top: 0;
    height: ${(props) => (props.active ? "2rem" : 0)};
    position: absolute;
    background-color: #a79fe8;
    padding-right: 1rem;
    padding-left: 1rem;
    border-top-left-radius: 0.5rem;
    border-bottom-left-radius: 0.5rem;
    display: flex;
    font-size: 12px;
    align-items: center;
    justify-content: center;
    color: white;
    font-weight: 700;
  }
`;
