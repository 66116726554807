import React from 'react';

interface IProps {
  collections: any;
  name: string;
}

interface IState {
  selectedIdx: any;
}
// The distance from the top of scrollable area the list item will scroll to
const VIEW_PORT_VERTICAL_SCROLL_OFFSET = 90;

class Scrollable<P extends IProps, S extends IState> extends React.Component<P, S> {
  listRef: any;

  componentDidMount() {
    document.addEventListener('keydown', this.arrowKeyScroll, false);
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.arrowKeyScroll, false);
  }

  getDomListItem = () => {
    const { name } = this.props;
    let pluralizedName = `${name}s`;

    if (name === 'idea_boards_management') {
      pluralizedName = name;
    }

    if (this.props.collections[pluralizedName] && this.props.collections[pluralizedName].data[this.state.selectedIdx]) {
      const id = this.props.collections[pluralizedName].data[this.state.selectedIdx].id;
      return document.getElementById(`${name}_${id}`);
    }
  };

  arrowKeyScroll = (event: any) => {
    const { selectedIdx } = this.state;
    const { collections, name } = this.props;

    let pluralizedName = `${name}s`;

    if (name === 'idea_boards_management') {
      pluralizedName = name;
    }

    if (collections[pluralizedName] && collections[pluralizedName].data.length > 0) {
      const isArrowKey = (event.keyCode === 38 && selectedIdx > 0) ||
        (event.keyCode === 40 && selectedIdx < collections[pluralizedName].data.length - 1);

      if (isArrowKey) {
        event.preventDefault();
        const listItem: any = this.getDomListItem();
        if (listItem) {
          this.setState({ selectedIdx: -1 }, () => {
            this.setState({ selectedIdx: event.keyCode === 38 ? selectedIdx - 1 : selectedIdx + 1 }, () => {
              this.listRef.scrollComponent.parentElement.scroll({
                top: listItem.offsetTop - listItem.scrollHeight - VIEW_PORT_VERTICAL_SCROLL_OFFSET,
                behavior: 'smooth',
              });
            });
          });
        }
      }
    }
  };
}

export { Scrollable };
