import React, { Component } from "react";
import * as DataTypes from "store/types";
import { ReportTypes } from "config/app";
import { MetricControl, SelectInput } from "ui";
import { AdobePlaceholder } from "ui/adobe_placeholder";
import { connect } from "react-redux";
import {
  createMetricRequest,
  destroyMetricRequest,
  createResultRequest,
  updateMetricOrderRequest,
} from "store/report_metric/actions";
import { createLoadingSelector } from "store/selectors";
import { ApplicationState } from "store/types";
import { AuthService } from "utils/auth";

interface IProps {
  report: DataTypes.Report;
  metrics: Array<DataTypes.Trait> | null;
  experiences: Array<DataTypes.Experience>;
  experiment: DataTypes.Experiment;
  currentUser: any;
  onMetricUpdate(metrics: Array<DataTypes.Metric>, metricId: any): void;
  onSelectedRow(rowIdx: number): void;
  selectedRowIdx: number;
  orderUpdating: boolean;
  onAddMetrics?(): void;
  createMetricRequest(experimentId: string, reportId: string, body: any): void;
  destroyMetricRequest(
    experimentId: string,
    reportId: string,
    metricId: string
  ): void;
  createResultRequest(
    experimentId: string,
    reportId: string,
    experienceId: string,
    metricId: string,
    body: any
  ): void;
  updateMetricOrderRequest(
    experimentId: string,
    reportId: string,
    metric: DataTypes.ApiRequest<DataTypes.Metric>,
    position: number
  ): void;
}

interface IState {
  showNewMetricColumn: boolean;
}

class ResultsTable extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      showNewMetricColumn: false,
    };
  }

  private onMetricChange = (metricIdx: number, key: string, value: any) => {
    const { report } = this.props;
    const { metrics } = report;
    metrics[metricIdx] = { ...metrics[metricIdx], [key]: value };
    this.props.onMetricUpdate(metrics, metricIdx);
  };

  private onMetricDelete = (metricIdx: number) => {
    const { report, experiment } = this.props;

    const { id: experimentId } = experiment;
    const { id: reportId } = report;

    if (!experimentId || !reportId) {
      return;
    }

    const metric = report.metrics[metricIdx];

    this.props.destroyMetricRequest(experimentId, reportId, metric.id || "");
  };

  private setResult = (
    metric: DataTypes.Metric,
    result: DataTypes.Result | undefined,
    value: string,
    key: string,
    experience: DataTypes.Experience
  ) => {
    const experimentId = this.props.experiment.id || "";
    const reportId = this.props.report.id || "";
    const metricId = metric.id || "";

    this.props.createResultRequest(
      experimentId,
      reportId,
      experience.id,
      metricId,
      { id: result?.id, [key]: value }
    );
  };

  private isPrimary = () => {
    const { report } = this.props;
    const { metrics } = report;

    return metrics.length === 0;
  };

  private onAddNewMetric = (value: string) => {
    const { id: experimentId } = this.props.experiment;
    const { id: reportId } = this.props.report;

    if (!reportId || !experimentId) {
      return;
    }

    const metric = {
      name: value,
      metric_type: DataTypes.MetricType.FLOAT,
      primary: this.isPrimary(),
      results: [],
    };

    this.props.createMetricRequest(experimentId, reportId, metric);
  };

  private addNewMetric = () => {
    const { report } = this.props;
    if (report.type === ReportTypes.SUMMARY) {
      this.setState({ ...this.state, showNewMetricColumn: true });
    }
  };

  private onFocus = (rowIdx: number) => {
    this.props.onSelectedRow(rowIdx);
  };

  private onReorder = (
    metric: any,
    direction: string,
    newPosition?: number
  ) => {
    if (!this.props.metrics) {
      return;
    }
    if (metric.custom_order === 1 && direction === "up") {
      return;
    }

    if (
      metric.custom_order === this.props.metrics?.length &&
      direction === "down"
    ) {
      return;
    }

    let position = metric.custom_order + 1;

    if (direction === "up") {
      position = metric.custom_order - 1;
    }

    if (newPosition) {
      position = newPosition;
    }

    const experimentId = this.props.experiment.id || "";
    const reportId = this.props.report.id || "";

    this.props.updateMetricOrderRequest(
      experimentId,
      reportId,
      metric,
      position
    );
  };

  render() {
    const { report, metrics, experiences, selectedRowIdx } = this.props;
    const { showNewMetricColumn } = this.state;

    return (
      <React.Fragment>
        <div className="ResultsTable">
          <div className="ResultsTableWrapper">
            <ul className="ControlColumn">
              <li className="Column">
                <div className="Header"></div>
                <div className="Ledger">
                  <div>metric</div>
                </div>

                <MetricSelectors
                  currentUser={this.props.currentUser}
                  orderUpdating={this.props.orderUpdating}
                  key="metric_selector"
                  onReorder={this.onReorder}
                  report={report}
                  metrics={metrics}
                  onUpdate={this.onMetricChange}
                  onDelete={this.onMetricDelete}
                  selectedRow={selectedRowIdx}
                />

                {showNewMetricColumn === true && (
                  <BlankMetricSelector
                    reportMetrics={report.metrics}
                    metrics={metrics}
                    onSelect={this.onAddNewMetric}
                  />
                )}
              </li>
            </ul>
            <ul
              className="ResultsMetrics"
              style={{ overflowX: "auto", overflowY: "hidden" }}
            >
              {experiences.map((experience: DataTypes.Experience) => {
                return (
                  <ExperienceResult
                    currentUser={this.props.currentUser}
                    key={`exp_${experience.id}`}
                    experience={experience}
                    metrics={report.metrics}
                    reportType={report.type}
                    onSetResult={this.setResult}
                    onFocus={(rowIdx) => this.onFocus(rowIdx)}
                    selectedRow={selectedRowIdx}
                  />
                );
              })}
            </ul>
          </div>
          {this.props.currentUser.role !== "read-only" &&
            report.type === ReportTypes.SUMMARY && (
              <div className="ResultsTableFooter">
                <button
                  className="btn btn-link"
                  type="button"
                  onClick={this.addNewMetric}
                >
                  <i className="fa fa-plus" />
                  Add New Metric
                </button>
              </div>
            )}
        </div>
      </React.Fragment>
    );
  }
}

const updateOrderLoader = createLoadingSelector([
  "@@report_metric/UPDATE_METRIC_ORDER",
]);

const mapStateToProps = ({ app }: ApplicationState) => ({
  orderUpdating: updateOrderLoader(app.requests),
});

const mapDispatchToProps = {
  createMetricRequest,
  destroyMetricRequest,
  createResultRequest,
  updateMetricOrderRequest,
};

const connectedPage = connect(
  mapStateToProps,
  mapDispatchToProps
)(ResultsTable);

export { connectedPage as ResultsTable };

interface IBlankMetricSelectorProps {
  reportMetrics: Array<DataTypes.Metric>;
  metrics: Array<DataTypes.Trait> | null;
  onSelect(value: string): void;
}

const BlankMetricSelector = ({
  reportMetrics,
  metrics,
  onSelect,
}: IBlankMetricSelectorProps) => {
  let report_metrics: Array<string> = reportMetrics
    .filter((item) => item && item.name)
    .map((item) => item.name || "");

  let avail_metrics: Array<DataTypes.Trait> = [];
  metrics?.forEach((item) => {
    if (!report_metrics.includes(item.name)) {
      avail_metrics.push(item);
    }
  });

  return (
    <div className="Metric Control New">
      <SelectInput
        value={""}
        name="name"
        placeholder="select"
        searchEnabled={true}
        items={
          avail_metrics
            ? avail_metrics.map((t) => {
                return t.name;
              })
            : []
        }
        onChange={(value) => onSelect(value)}
      />
    </div>
  );
};

interface IMetricSelectorProps {
  report: DataTypes.Report;
  metrics: Array<DataTypes.Trait> | null;
  selectedRow: number;
  orderUpdating: boolean;
  currentUser: any;
  onUpdate(metricIdx: number, key: string, value: any): void;
  onDelete(metricIdx: number): void;
  onReorder(metric: any, direction: string, position?: number): void;
}

const MetricSelectors = ({
  report,
  metrics,
  onUpdate,
  onDelete,
  selectedRow,
  onReorder,
  orderUpdating,
  currentUser,
}: IMetricSelectorProps) => {
  let report_metrics: Array<string> = [];
  report.metrics.forEach((item) => {
    if (item && item.name) {
      report_metrics.push(item.name);
    }
  });

  let avail_metrics: Array<DataTypes.Trait> = [];
  metrics?.forEach((item) => {
    if (!report_metrics.includes(item.name)) {
      avail_metrics.push(item);
    }
  });

  if (report.type === ReportTypes.SUMMARY) {
    return (
      <React.Fragment>
        {report.metrics.map((metric: DataTypes.Metric, idx: number) => {
          return (
            <div
              key={`m_ctrl_${idx}`}
              className={
                selectedRow === idx ? "Metric Control active" : "Metric Control"
              }
            >
              {report.type === ReportTypes.TARGET ||
              report.type === ReportTypes.ADOBE ? (
                <AdobePlaceholder />
              ) : orderUpdating ? null : (
                <MetricControl
                  currentUser={currentUser}
                  metricsCount={metrics?.length || 0}
                  metric={metric}
                  onReorder={onReorder}
                  onPrimaryChange={(value) => onUpdate(idx, "primary", value)}
                  onMetricTypeChange={(value, symbol) => {
                    onUpdate(idx, "metric_type", value);

                    if (symbol) {
                      onUpdate(idx, "symbol", symbol);
                    }
                  }}
                  onDelete={() => onDelete(idx)}
                />
              )}

              <SelectInput
                buttonProps={{
                  style: {
                    color: "#2F4760",
                  },
                }}
                value={metric.name}
                name="name"
                placeholder="select"
                searchEnabled={true}
                items={
                  avail_metrics
                    ? avail_metrics.map((t) => {
                        return t.name;
                      })
                    : []
                }
                onChange={(value) => onUpdate(idx, "name", value)}
              />
              {metric.primary === true ? (
                <i className="primary fa fa-star" />
              ) : (
                <i className="primary fa " />
              )}
            </div>
          );
        })}
      </React.Fragment>
    );
  }

  if (
    report.type === ReportTypes.ANALYTICS ||
    report.type === ReportTypes.TARGET
  ) {
    return (
      <React.Fragment>
        {report.metrics.map((metric: DataTypes.Metric, idx: number) => {
          return (
            <div
              key={`m_ctrl_${idx}`}
              className={
                selectedRow === idx ? "Metric Control active" : "Metric Control"
              }
            >
              {report.type === ReportTypes.TARGET ||
              report.type === ReportTypes.ADOBE ? (
                <AdobePlaceholder />
              ) : orderUpdating ? null : (
                <MetricControl
                  currentUser={currentUser}
                  onReorder={onReorder}
                  metricsCount={metrics?.length || 0}
                  metric={metric}
                  onPrimaryChange={(value) => onUpdate(idx, "primary", value)}
                  onDelete={() => onDelete(idx)}
                />
              )}
              <div className="SelectInput no-select">
                <button type="button">{metric.name}</button>
              </div>
              {metric.primary === true ? (
                <i className="primary fa fa-star" />
              ) : (
                <i className="primary fa " />
              )}
            </div>
          );
        })}
      </React.Fragment>
    );
  }

  return null;
};

interface IExperienceResultProps {
  experience: DataTypes.Experience;
  metrics: Array<DataTypes.Metric>;
  reportType: string;
  currentUser: any;
  onSetResult(
    metric: DataTypes.Metric,
    result: DataTypes.Result | undefined,
    value: string,
    key: string,
    experience: DataTypes.Experience
  ): void;
  onFocus(row: number): void;
  selectedRow: number;
}

const ExperienceResult = ({
  experience,
  metrics,
  reportType,
  onSetResult,
  onFocus,
  selectedRow,
  currentUser,
}: IExperienceResultProps) => {
  if (experience.type === "Experiment::Experience::Control") {
    return (
      <li className="Column Control">
        <div className="Header">{experience.name}</div>
        <div className="Ledger">
          <div>value</div>
        </div>
        {metrics.map((metric, idx) => {
          const result = metric.results
            ? metric.results.find((r) => r.experience_id === experience.id)
            : undefined;
          return (
            <div
              className="MetricWrapper"
              key={`exp_ctrl_${experience.id}_metric_${metric.id}`}
            >
              <div className={selectedRow === idx ? "Metric active" : "Metric"}>
                <ResultInput
                  value={result && result.value}
                  tabIndex={idx + 1}
                  inputType={metric.metric_type || DataTypes.MetricType.FLOAT}
                  currencySymbol={metric.symbol}
                  onUpdate={(value) =>
                    onSetResult(metric, result, value, "value", experience)
                  }
                  onFocus={() => onFocus(idx)}
                  readOnly={reportType !== ReportTypes.SUMMARY}
                />
              </div>
              <div
                className={
                  selectedRow === idx
                    ? "ResultDescription active"
                    : "ResultDescription"
                }
              >
                <input
                  onChange={() => {}}
                  type="text"
                  placeholder="Description (optional)"
                  defaultValue={result?.description || ""}
                  onBlur={(e) =>
                    onSetResult(
                      metric,
                      result,
                      e.target.value,
                      "description",
                      experience
                    )
                  }
                />
              </div>
            </div>
          );
        })}
      </li>
    );
  }

  return (
    <li key={`exp_${experience.id}`} className="Column">
      <div className="Header">{experience.name}</div>
      <div className="Ledger">
        <div>value</div>
        <div>lift</div>
        <div>confidence</div>
      </div>
      {metrics.map((metric: DataTypes.Metric, idx: number) => {
        const result = metric.results
          ? metric.results.find((r) => r.experience_id === experience.id)
          : undefined;
        return (
          <div
            className="MetricWrapper"
            key={`exp_${experience.id}_metric_${metric.id}`}
          >
            <div className={selectedRow === idx ? "Metric active" : "Metric"}>
              <ResultInput
                value={result && result.value}
                tabIndex={idx + 1}
                inputType={metric.metric_type || DataTypes.MetricType.FLOAT}
                currencySymbol={metric.symbol}
                onUpdate={(value) =>
                  onSetResult(metric, result, value, "value", experience)
                }
                onFocus={() => onFocus(idx)}
                readOnly={reportType !== ReportTypes.SUMMARY}
              />
              <ResultInput
                value={result && result.lift}
                tabIndex={idx + 1}
                inputType={DataTypes.MetricType.PERCENT}
                onUpdate={(value) =>
                  onSetResult(metric, result, value, "lift", experience)
                }
                onFocus={() => onFocus(idx)}
                readOnly={reportType !== ReportTypes.SUMMARY}
              />
              <ResultInput
                value={result && result.confidence}
                tabIndex={idx + 1}
                inputType={DataTypes.MetricType.PERCENT}
                onUpdate={(value) =>
                  onSetResult(metric, result, value, "confidence", experience)
                }
                onFocus={() => onFocus(idx)}
                readOnly={false}
              />
            </div>
            <div
              className={
                selectedRow === idx
                  ? "ResultDescription active"
                  : "ResultDescription"
              }
            >
              <input
                onChange={() => {}}
                style={{ color: "#2f4760" }}
                type="text"
                placeholder="Description (optional)"
                defaultValue={result?.description || ""}
                onBlur={(e) =>
                  onSetResult(
                    metric,
                    result,
                    e.target.value,
                    "description",
                    experience
                  )
                }
              />
            </div>
          </div>
        );
      })}
    </li>
  );
};

interface IResultInputProps {
  inputType: DataTypes.MetricType;
  tabIndex: number;
  value: number | undefined | string;
  onUpdate(value: string): void;
  onFocus(): void;
  readOnly: boolean;
  currencySymbol?: string;
}

interface IResultInputState {
  inputValue: string;
}

class ResultInput extends Component<IResultInputProps, IResultInputState> {
  constructor(props: IResultInputProps) {
    super(props);
    this.state = {
      inputValue:
        this.props.value !== null && this.props.value !== undefined
          ? this.props.value.toString()
          : "",
    };
  }

  private onChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value.toString().split(",").join("").trim();
    const numberRegex = /^[-]?\d*[.|\d]?[\d]*?$/;
    if (numberRegex.test(value) || value === "") {
      this.setState({ ...this.state, inputValue: value.toString() });
    } else {
      e.preventDefault();
    }
  };

  private isReadOnly = () => {
    const user = AuthService.getActiveUser();

    return user.role === "read-only";
  };

  render() {
    const { tabIndex, onUpdate, onFocus, inputType, readOnly, currencySymbol } =
      this.props;
    const { inputValue } = this.state;

    let inputSymbol = "";

    if (inputType === "currency") {
      if (
        !currencySymbol ||
        currencySymbol === DataTypes.CurrencySymbols.DOLLAR
      ) {
        inputSymbol = "dollar";
      }
      if (currencySymbol === DataTypes.CurrencySymbols.EURO) {
        inputSymbol = "euro";
      }

      if (currencySymbol === DataTypes.CurrencySymbols.POUND) {
        inputSymbol = "pound";
      }
    }

    return (
      <div className={`ResultInput ${inputType} ${inputSymbol}`}>
        <input
          style={{ color: "#2f4760" }}
          type="text"
          tabIndex={tabIndex}
          value={inputValue}
          maxLength={inputType === DataTypes.MetricType.PERCENT ? 8 : 10}
          onChange={this.onChangeHandler}
          onBlur={(e) => onUpdate(e.target.value.split(",").join(""))}
          onFocus={(e) => onFocus()}
          readOnly={this.isReadOnly() || readOnly}
        />
      </div>
    );
  }
}
