import React, { useRef } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import * as DataTypes from "store/types";
import { ApplicationState } from "store/types";
import "../css/Navs.scss";
import { Avatar, DropdownControl, DropdownMenu } from "./";

// Navigation
interface NavElem {
  name: string;
  path: string;
  onClick?: () => void;
  external?: boolean;
  hidden?: boolean;
}

const MAIN_NAV: Array<NavElem> = [
  {
    name: "tests",
    path: "/tests",
  },
  {
    name: "research",
    path: "/uxr",
  },
  {
    name: "ideas",
    path: "/ideas",
  },
  {
    name: "insights",
    path: "/insights",
  },
  {
    name: "resources",
    path: "/resources",
  },
];

interface NavItem {
  link: string;
  name: string;
}

const buildMainNav = (nav: Array<NavElem>, navIndex: number) => {
  const showHidden = window.localStorage.getItem("showHidden");

  const navItems = nav
    .filter((item) => {
      if (!showHidden) {
        return !item.hidden;
      }

      return true;
    })
    .map((item, idx) => {
      return (
        <li
          key={"nav_item_" + idx}
          className={navIndex === idx ? "active" : ""}
          style={{ cursor: "pointer" }}
        >
          {item.external ? (
            <a
              onClick={() => {
                if (item.onClick) {
                  item.onClick();
                }
              }}
            >
              {item.name}
            </a>
          ) : (
            <Link to={item.path}>{item.name}</Link>
          )}
        </li>
      );
    });

  return <ul className="nav-items ">{navItems}</ul>;
};

interface IMainNavProps {
  account: DataTypes.Account | null;
  currentUser: any;
  navIndex: number;
  taskCount: number;
  accounts: any;
}

const menuItems = (accounts: any, currentUser: any, currentAccount: any) => {
  let items: any = [
    { name: "profile", icon: "far fa-user-circle", link: "/profile" },
  ];

  if (currentUser.role === "admin") {
    items.push({ name: "settings", icon: "fas fa-cog", link: "/settings" });
  }

  const otherAccounts = accounts?.filter(
    (account: any) => currentAccount.id !== account.id
  );

  if (otherAccounts && otherAccounts.length > 0) {
    items = [
      ...items,
      {
        name: "My Accounts",
        splitAbove: true,
        children: otherAccounts.map((account: any) => {
          return {
            name: account.name,
            link: `${process.env.REACT_APP_HTTP_PROTO}://${account.slug}.${process.env.REACT_APP_BASE_URL}`,
            external: true,
          };
        }),
      },
    ];
  }

  items.push({
    name: "sign out",
    icon: "fas fa-arrow-right",
    link: "/logout",
    splitAbove: true,
  });

  return items;
};

const Main = ({
  account,
  navIndex,
  currentUser,
  taskCount,
  accounts,
}: IMainNavProps) => {
  if (!currentUser) {
    return null;
  }

  return (
    <div className="MainNav">
      <Link to="/" className="logo">
        <img src="/img/logo_sm.svg" alt="illuminate" />
      </Link>
      {buildMainNav(MAIN_NAV, navIndex)}
      <div className="settings">
        <Link to="/tasks">
          <div className="tasks">
            {taskCount > 0 ? (
              <div className="circle notifications">{taskCount}</div>
            ) : null}
            <div className="alert">
              <i className="fas fa-bell" />
            </div>
          </div>
        </Link>
        <div className="profile-toggle">
          <DropdownMenu
            name="user"
            value={currentUser?.name || ""}
            component={<Avatar user={currentUser} />}
            menuItems={menuItems(accounts, currentUser, account)}
          />
        </div>
        {account && <div className="company-display">{account.name}</div>}
      </div>
    </div>
  );
};

const mapStateToProps = ({ collections }: ApplicationState) => ({
  taskCount: collections?.tasks?.data.length || 0,
  accounts: collections?.userAccounts,
});

const connectedComponent = connect(mapStateToProps)(Main);
export { connectedComponent as Main };

// Sub Nav
interface ISubNavProps {
  activeIdx: number;
  navItems: Array<NavItem>;
}

export const SubNav = ({ activeIdx, navItems }: ISubNavProps) => {
  const ref = useRef<HTMLUListElement>(null);

  return (
    <div className="subNav">
      <ul ref={ref}>
        {navItems.map((item, idx) => (
          <li key={item.name} className={idx === activeIdx ? "active" : ""}>
            <Link to={item.link}>{item.name}</Link>
          </li>
        ))}
      </ul>
    </div>
  );
};

interface IShareNavProps {
  currentUser: DataTypes.User | undefined;
  onSignOut(): void;
}

export const ShareNav = ({ currentUser, onSignOut }: IShareNavProps) => {
  const ref = useRef<HTMLDivElement>(null);

  const menuItems: any = [
    { name: "sign out", icon: "fas fa-arrow-right", onClick: onSignOut },
  ];

  return (
    <div ref={ref} className="ShareNav">
      <div className="profile-toggle">
        {currentUser && (
          <DropdownControl
            component={<Avatar user={currentUser} />}
            controlItems={menuItems}
          />
        )}
      </div>
    </div>
  );
};

interface PublicNavProps {}

export const PublicNav = () => {
  const ref = useRef<HTMLDivElement>(null);

  return (
    <div ref={ref} className="PublicNav">
      <div className="PublicNav-container small-logo">
        <img src="/img/logo_sm.svg" alt="illuminate" />
      </div>
      <div className="PublicNav-container">
        <Link className="btn btn-primary" to="/signup">
          Sign up today
        </Link>
      </div>
    </div>
  );
};
