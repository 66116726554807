import React, { Component } from 'react';
import * as DataTypes from 'store/types';

interface IProps {
  experience: DataTypes.Experience;
  saving: boolean;
  onSubmit(experience: DataTypes.Experience): void;
}

class DeleteExperience extends Component<IProps> {
  render() {
    const { onSubmit, saving, experience } = this.props;

    return (
      <React.Fragment>
        <div className="header">
          <h3>Delete Experience</h3>
          <p>
            Are you sure you want to delete the experience <strong>{experience.name}</strong> from this experiment?
          </p>
        </div>
        <div className="cntrl-bar">
          {saving === true ? (
            <button className="btn btn-danger" type="submit" disabled={true}>
              <i className="fas fa-circle-notch fa-spin" />
            </button>
          ) : (
            <button className="btn btn-danger" onClick={() => onSubmit(experience)}>
              delete experience
            </button>
          )}
        </div>
      </React.Fragment>
    );
  }
}

export { DeleteExperience };
