import React, { useCallback, useEffect, useMemo, useState } from "react";
import * as DataTypes from "store/types";
import { Helpers } from "utils";
import {
  ChartsContainer,
  DataStoryTellingArea,
  DataStoryTellingContainer,
} from "pages/app/experiments/comps/show/report/custom_data_viz/index.style";
import { EditorInput } from "ui/editor_input/editor_input";
import { CustomDataVizChartFactory } from "./charts";

type Params = {
  dataViz: DataTypes.CustomDataViz;
  onUpdateDataVizMetric(metricId: string, body: any): void;
};

const colors = [
  "#2a88f2",
  "#89cad7",
  "#cec7e3",
  "#a79fe8",
  "#ff5a71",
  "#8edeb0",
  "#25be6f",
  "#f4b974",
  "#757e8e",
  "#495057",
];

export const CustomDataVizChart: React.FC<Params> = ({
  dataViz,
  onUpdateDataVizMetric,
}) => {
  const [focusedIndex, setFocusedIndex] = useState<number>(-1);
  const [focusing, setFocusing] = useState(false);

  const escFunction = useCallback((event: any) => {
    if (event.keyCode === 27) {
      setFocusedIndex(-1);
    }
  }, []);

  useEffect(() => {
    document.addEventListener("keydown", escFunction, false);

    return () => {
      document.removeEventListener("keydown", escFunction, false);
    };
  });

  const experienceNames = useMemo(() => {
    const names: { [k: string]: string } = {};

    dataViz.reports.forEach((report) => {
      report.metrics.forEach((metric) => {
        metric.results.forEach((result) => {
          names[result.experience_id] = result.experience_name;
        });
      });
    });

    return names;
  }, [dataViz]);

  const metricNames = useMemo(() => {
    const names: { [k: string]: string } = {};

    dataViz.reports.forEach((report) => {
      report.metrics.forEach((metric) => {
        names[metric.agregation_id] = metric.name;
      });
    });

    return names;
  }, [dataViz]);

  const experienceNameLabels = useMemo(
    () =>
      Helpers.uniqueArray(
        Object.keys(experienceNames).map((n) => experienceNames[n])
      ),
    [experienceNames]
  );

  const getResultForMetric = (
    report: DataTypes.CustomDataVizReport,
    metricId: string,
    experienceId: string
  ) => {
    const metric = report.metrics.filter(
      (metric) => metric.agregation_id === metricId
    )[0];

    const result = metric.results.filter(
      (result) => result.experience_id === experienceId
    )[0];

    if (metric.kind === "lift") {
      return result.lift;
    }

    return result.value;
  };

  const getChartKindForMetric = (metricId: string) => {
    let chartKind = null;

    dataViz.reports.forEach((report) => {
      const metric = report.metrics.filter(
        (metric) => metric.agregation_id === metricId
      )[0];

      chartKind = metric.chart_kind;
    });

    return chartKind;
  };

  const getStoryTellingForMetric = (metricId: string) => {
    let storyTelling = null;

    dataViz.reports.forEach((report) => {
      const metric = report.metrics.filter(
        (metric) => metric.agregation_id === metricId
      )[0];

      storyTelling = metric.story_telling;
    });

    return storyTelling;
  };

  const dataSetsByReport = useMemo(() => {
    return Object.keys(metricNames).map((metricId) => {
      const metricName = metricNames[metricId];

      return {
        labels: experienceNameLabels,
        title: metricName,
        chart_kind: getChartKindForMetric(metricId),
        story_telling: getStoryTellingForMetric(metricId),
        metricId,
        dataSets: dataViz.reports.map((report, index) => {
          return {
            label: report.name,
            backgroundColor: colors[index],
            data: experienceNameLabels.map((experienceName) => {
              return getResultForMetric(
                report,
                metricId,
                Object.keys(experienceNames).find(
                  (key) => experienceNames[key] === experienceName
                ) as string
              );
            }),
          };
        }),
      };
    });
  }, [dataViz]);

  useEffect(() => {
    if (focusedIndex) {
      setFocusing(true);
    }
  }, [focusedIndex]);

  useEffect(() => {
    if (focusing) {
      setTimeout(() => {
        setFocusing(false);
      }, 100);
    }
  }, [focusing]);

  const updateStoryTelling = useCallback((metricId: string, content: any) => {
    onUpdateDataVizMetric(metricId, {
      chart_kind: getChartKindForMetric(metricId),
      story_telling: content,
    });
  }, []);

  return (
    <ChartsContainer>
      {dataSetsByReport.map((dataSet, index) => (
        <div
          key={`${dataSet.title}-${dataSet.metricId}`}
          className={index === focusedIndex ? "focus" : ""}
        >
          <div className="chartContainer">
            <div className="buttonContainer">
              <button
                title="expand chart"
                className="btn btn-close"
                type="button"
                onClick={() =>
                  setFocusedIndex(index === focusedIndex ? -1 : index)
                }
              >
                {index === focusedIndex ? (
                  <i className="fas fa-times" />
                ) : (
                  <i className="fas fa-expand" />
                )}
              </button>
            </div>

            <div className="chart">
              {!focusing && <CustomDataVizChartFactory dataSet={dataSet} />}
            </div>

            <DataStoryTellingArea className="w--full">
              <DataStoryTellingContainer className="form-group mt__sm w--full">
                <label>Data Storytelling</label>

                <EditorInput
                  className="z-0 w--full"
                  content={dataSet.story_telling}
                  onBlur={(content: any) =>
                    updateStoryTelling(dataSet.metricId, content)
                  }
                />
              </DataStoryTellingContainer>
            </DataStoryTellingArea>
          </div>
        </div>
      ))}
    </ChartsContainer>
  );
};
