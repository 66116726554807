import React from 'react';

const AdobePlaceholder = () => {
  return (
    <div className="ml__sm mr__sm adobe--circle align-self__center">
      <div>All changes to Adobe imports should be made in Adobe</div>
    </div>
  );
};

export { AdobePlaceholder };
