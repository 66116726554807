import { action } from 'typesafe-actions';
import { Dispatch } from 'redux';
import { ActionTypes } from './types';
import * as DataTypes from '../types';

interface RequestError {
  statusCode?: number | undefined;
  message: any;
}

// Get Tasks
export const getTasksRequest = () => action(ActionTypes.GET_TASKS_REQUEST);
export const getTasksSuccess = (response: DataTypes.ApiList<DataTypes.Task>) => action(ActionTypes.GET_TASKS_SUCCESS, { response });
export const getTasksFailure = (error: RequestError) => action(ActionTypes.GET_TASKS_FAILURE, error);

// Delete Task by id
export const deleteTaskRequest = (id: string) => (dispatch: Dispatch) => dispatch(action(ActionTypes.DELETE_TASK_REQUEST, { id }));
export const deleteTaskSuccess = (response: DataTypes.ApiRequest<DataTypes.Task>) => action(ActionTypes.DELETE_TASK_SUCCESS, { response });
export const deleteTaskError = (error: RequestError) => action(ActionTypes.DELETE_TASK_FAILURE, error);
