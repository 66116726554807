import React from "react";
import { connect } from "react-redux";
import { history, Helpers } from "utils";
import queryString from "query-string";
import { Link } from "react-router-dom";
import _ from "lodash";
import * as DataTypes from "store/types";
import { clearAppErrors } from "store/app/actions";
import {
  getExperimentsRequest,
  clearExperiments,
  updateABExperimentRequest,
  updateAdobeExperimentRequest,
  reorderExperimentRequest,
} from "store/experiment/actions";
import { getExperimentsTraitsRequest } from "store/experiment_trait/actions";
import { createLoadingSelector } from "store/selectors";
import { ApplicationState } from "store/types";
import { AppPage, AppPageProps } from "comps/pages";
import { Scrollable } from "comps/base/scrollable";
import { Filters, List, Aside, Gantt } from "./comps";
import { SearchBar, TotalCountStat } from "ui";
import "css/Experiments.scss";

import moment from "moment";
import { StatusCircle } from "./comps";
import TimeAgo from "timeago-react";
import { OrderIcon, OrderDirection } from "ui/order_icon";
import { CustomLoader } from "ui/loaders";
import { AuthService } from "utils/auth";
import GraphQLInfiniteScroll from "comps/infinite_scroll/graphql_scroll";
import { showModal } from "store/app/actions";
import { BestInClass } from "./comps/modals/best_in_class";

const DEFAULT_LIMIT = 25;

enum ViewType {
  TABLE = "table",
  LIST = "list",
  GANTT = "gantt",
}

interface IProps extends AppPageProps {
  router: any;
  account: DataTypes.Account | null;
  currentUser: any;
  clearAppErrors: () => void;
  getExperimentsTraitsRequest: () => void;
  getExperimentsRequest: (
    options: DataTypes.ApiListOptions,
    scrollOpts?: { append: boolean }
  ) => void;
  updateABExperimentRequest: (id: string, body: any) => void;
  updateAdobeExperimentRequest: (id: string, body: any) => void;
  clearExperiments: () => void;
  reorderExperimentRequest: (
    id: string,
    position: number,
    currentOrder: OrderDirection
  ) => void;
  showModal: (component: React.ComponentType<any>, options: any) => void;
  reorderLoading: boolean;
  loading: boolean;
  accountLoading: boolean;
  collections: any;
  name: string;
  experimentTrait: any;
}

interface IState {
  selectedIdx: number;
  selectedFilters: { [key: string]: Array<string> };
  ids: any;
  view: ViewType;
  search: string;
  currentOrder: string;
  limit: number;
  resetingFilters: boolean;
  order: {
    [key: string]: OrderDirection;
  };
}

class Experiments extends Scrollable<IProps, IState> {
  static defaultProps: any;
  listRef: any;

  constructor(props: IProps) {
    super(props);
    const q = queryString.parse(this.props.location.search, {
      arrayFormat: "bracket",
    });
    let selectedFilters: { [key: string]: Array<any> } = {};
    if (Array.isArray(q.q))
      selectedFilters = Helpers.getFiltersFromQueryString(q.q);

    const { orderBy, orderDirection } = q;
    let order = {
      customOrder: OrderDirection.NONE,
      name: OrderDirection.NONE,
      startDate: OrderDirection.NONE,
      endDate: OrderDirection.NONE,
      status: OrderDirection.NONE,
      impactValue: OrderDirection.NONE,
      updatedAt: OrderDirection.NONE,
    };

    if (orderBy) {
      order = { ...order, [orderBy as any]: orderDirection };
    }

    this.state = {
      ids: q.ids || null,
      selectedIdx: 0,
      selectedFilters: selectedFilters,
      view: this.props.router.location.query.view || ViewType.LIST,
      search: q.search,
      currentOrder: (orderBy as string) || "",
      order,
      limit: DEFAULT_LIMIT,
      resetingFilters: false,
    };

    this.fetchMore = _.debounce(this.fetchMore, 500, {
      leading: true,
      trailing: false,
    });
  }

  private getSortString() {
    if (!this.state.currentOrder) {
      return "updated_at";
    }

    const direction =
      this.state.order[this.state.currentOrder] === OrderDirection.ASC
        ? "-"
        : "";

    return `${direction}${Helpers.camelToSnake(this.state.currentOrder)}`;
  }

  private fetchMore = (endCursor: string, append = true) => {
    const {
      collections: { experiments },
    } = this.props;
    if (
      (experiments && experiments.data.length > 0 && experiments.has_more) ||
      !append
    ) {
      let q = queryString.parse(this.props.location.search, {
        arrayFormat: "bracket",
      });
      const options = {
        q: q.q,
        sort: this.getSortString(),
        search: q.search,
        starting_after: endCursor,
        limit: this.state.limit,
      };

      this.props.getExperimentsRequest(options, { append });
    }
  };

  private resetetOrders() {
    return Object.keys(this.state.order).reduce(
      (acc, current) => ({ ...acc, [current]: OrderDirection.NONE }),
      {}
    );
  }

  private changeOrder(orderBy: string, currentDirection: OrderDirection) {
    let newDirection: OrderDirection;

    if (orderBy !== "customOrder") {
      if (currentDirection === OrderDirection.DESC) {
        newDirection = OrderDirection.ASC;
      } else {
        newDirection = OrderDirection.DESC;
      }
    } else {
      newDirection = OrderDirection.ASC;
    }

    const order = { ...this.resetetOrders(), [orderBy]: newDirection };
    this.setState(
      {
        order,
        currentOrder: orderBy,
      },
      () => {
        const query = queryString.parse(this.props.location.search, {
          arrayFormat: "bracket",
        });
        query.orderBy = orderBy;
        query.orderDirection = newDirection;

        const qStr = queryString.stringify(query, {
          encode: true,
          arrayFormat: "bracket",
        });
        history.push(`?${qStr}`);

        const q = queryString.parse(this.props.location.search, {
          arrayFormat: "bracket",
        });
        this.props.clearExperiments();
        this.props.getExperimentsRequest({
          limit: DEFAULT_LIMIT,
          sort: this.getSortString(),
          q: q.q,
        });
      }
    );
  }

  changeView = (view: ViewType) => {
    const q = queryString.parse(this.props.location.search, {
      arrayFormat: "bracket",
    });
    q.view = view;
    if (view !== ViewType.GANTT) {
      delete q.month;
      this.props.getExperimentsRequest({
        limit: DEFAULT_LIMIT,
        sort: this.getSortString(),
        q: q.q,
      });
    }

    const qStr = queryString.stringify(q, {
      encode: true,
      arrayFormat: "bracket",
    });
    history.push(`?${qStr}`);
    this.setState({ view: view });
  };

  itemSelected = (itemIndex: number) => {
    this.setState(
      {
        ...this.state,
        selectedIdx: -1,
      },
      () => {
        this.setState({ ...this.state, selectedIdx: itemIndex });
      }
    );
  };

  componentDidMount = () => {
    const q = queryString.parse(this.props.location.search, {
      arrayFormat: "bracket",
    });

    let selectedFilters: { [key: string]: Array<any> } = {};
    if (Array.isArray(q.q))
      selectedFilters = Helpers.getFiltersFromQueryString(q.q);
    this.setState({ ...this.state, selectedFilters });

    this.props.clearAppErrors();
    this.props.getExperimentsTraitsRequest();
    this.props.getExperimentsRequest({
      limit: DEFAULT_LIMIT,
      sort: this.getSortString(),
      ids: q.ids,
      q: q.q,
      search: q.search,
      month: q.month as string,
    });
    super.componentDidMount();
  };

  private onFilterCheck = (checked: boolean, type: string, value: string) => {
    let q = queryString.parse(this.props.location.search, {
      arrayFormat: "bracket",
    });

    if (checked) {
      if (q.q && Array.isArray(q.q)) {
        q.q.push(`${type}=${value}`);
      } else {
        q.q = [`${type}=${value}`];
      }
    } else {
      if (q.q && Array.isArray(q.q)) {
        q.q = q.q.filter((item: string) => item !== `${type}=${value}`);
      }
    }

    const qStr = queryString.stringify(q, {
      encode: true,
      arrayFormat: "bracket",
    });

    if (Array.isArray(q.q)) {
      this.setState({
        ...this.state,
        selectedFilters: Helpers.getFiltersFromQueryString(q.q),
      });
    }

    history.push(`/tests?${qStr}`);
    this.props.getExperimentsRequest({
      q: q.q,
      month: q.month as string,
      sort: this.getSortString(),
    });
  };

  private clearFilters = () => {
    const qStr = queryString.stringify(
      {},
      {
        encode: true,
        arrayFormat: "bracket",
      }
    );

    this.setState(
      {
        resetingFilters: true,
        selectedFilters: {},
      },
      () => {
        this.setState({ resetingFilters: false });
      }
    );

    history.push(`/tests?${qStr}`);

    this.props.getExperimentsRequest({
      q: null,
      sort: this.getSortString(),
    });
  };

  private onChange = (
    experiment: DataTypes.Experiment,
    key: string,
    value: string | Array<string> | boolean
  ) => {
    if (this.isReadOnly()) {
      return;
    }

    if (experiment.type === "Experiment::Ab") {
      this.props.updateABExperimentRequest(experiment.id || "", {
        experiment: { [key]: value },
      });
      return;
    }

    if (experiment.type === "Experiment::Adobe") {
      this.props.updateAdobeExperimentRequest(experiment.id || "", {
        experiment: { [key]: value },
      });
    }
  };

  private isReadOnly = () => {
    const user = AuthService.getActiveUser();

    return user?.role === "read-only";
  };

  private onSearchSubmit = (search: string) => {
    let q = queryString.parse(this.props.location.search, {
      arrayFormat: "bracket",
    });

    if (search) {
      q = { ...q, search };
    } else {
      delete q.search;
    }

    const qStr = queryString.stringify(q, {
      encode: true,
      arrayFormat: "bracket",
    });
    history.push(`/tests?${qStr}`);
    this.props.getExperimentsRequest(
      { sort: this.getSortString(), q: q.q, search },
      { append: false }
    );
  };

  onLimitChange = (limit = DEFAULT_LIMIT) => {
    setTimeout(() => {
      if (this.props.loading) {
        this.onLimitChange(limit);
        return;
      }

      this.setState({ limit: limit }, () => {
        this.fetchMore("", false);
      });
    }, 500);
  };

  private onReorder = (id: string, index: number) => {
    this.props.reorderExperimentRequest(
      id,
      index,
      this.state.order[this.state.currentOrder]
    );
  };

  render() {
    const { accountLoading, loading, collections, account, experimentTrait } =
      this.props;
    const { view, selectedIdx, selectedFilters } = this.state;

    if (account?.summary?.totals.experiments === 0) {
      return <EmptyState />;
    }

    return (
      <React.Fragment>
        <section className="FilterBar">
          <div className="viewCtrls">
            <button
              className={
                view === ViewType.LIST ? "btn btn-icon active" : "btn btn-icon"
              }
              onClick={(e) => this.changeView(ViewType.LIST)}
            >
              <i className="fas fa-bars" />
            </button>
            <button
              className={
                view === ViewType.TABLE ? "btn btn-icon active" : "btn btn-icon"
              }
              onClick={(e) => this.changeView(ViewType.TABLE)}
            >
              <i className="fas fa-table" />
            </button>
            <button
              className={
                view === ViewType.GANTT ? "btn btn-icon active" : "btn btn-icon"
              }
              onClick={(e) => this.changeView(ViewType.GANTT)}
            >
              <i className="far fa-calendar" />
            </button>
          </div>
          <div className="pad20">
            <hr />
          </div>
          <TotalCountStat
            name="tests"
            total={account?.summary?.totals.experiments || 0}
          />
          {this.props.currentUser.role === "read-only" ? null : (
            <div className="addCtrls">
              <Link to="/tests/create" className="btn btn-primary">
                Add Test
              </Link>
            </div>
          )}
          <div className="w--full flex justify-center">
            <button
              className="button button__link p__zero"
              style={{ padding: 0, textDecoration: "none" }}
              onClick={() => this.props.showModal(BestInClass, {})}
            >
              <i className="fas fa-lightbulb"></i> Best in Class Example
            </button>
          </div>

          <div className="pad20">
            <hr />
          </div>

          {/* <MarketingBanner
            containerStyle={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              marginTop: "20px",
              marginLeft: "3px",
            }}
            bannerPlacementSlug={"filter_bar_banners"}
            imageStyle={{ width: "200px", height: "200px" }}
          /> */}

          {this.state.resetingFilters ? null : (
            <Filters
              onSorting={this.changeOrder.bind(this)}
              sortingValues={this.state.order}
              loading={accountLoading}
              traits={experimentTrait?.traits}
              onCheck={this.onFilterCheck}
              onLimitChange={this.onLimitChange}
              selectedFilters={selectedFilters}
              onClearFilters={this.clearFilters}
            />
          )}
        </section>

        {view === ViewType.LIST && (
          <React.Fragment>
            <div className="Experiments list-view">
              <div className="SearchBar">
                <SearchBar
                  onChange={(search) =>
                    this.setState({ ...this.state, search })
                  }
                  value={this.state.search}
                  placeholder="Search for tests"
                  onSubmit={this.onSearchSubmit}
                  enableReset
                />
              </div>
              <section className="Experiments List">
                {collections.experiments && collections.experiments.metadata ? (
                  <GraphQLInfiniteScroll
                    pageStart={1}
                    startcursor={collections.experiments.metadata.startCursor}
                    endcursor={collections.experiments.metadata.endCursor}
                    loadMore={this.fetchMore}
                    hasMore={
                      (collections.experiments &&
                        collections.experiments.has_more) ||
                      false
                    }
                    initialLoad={true}
                    loader={
                      <CustomLoader
                        key={"loader"}
                        text="Wait while we load more tests for you"
                      />
                    }
                    ref={(ref) => (this.listRef = ref)}
                    useWindow={false}
                  >
                    <List
                      key="list-experiments"
                      reorderable={
                        !this.props.reorderLoading &&
                        this.state.currentOrder === "customOrder" &&
                        this.props.currentUser.role !== "read-only"
                      }
                      onReorder={this.onReorder}
                      loading={loading && collections.experiments === undefined}
                      experiments={
                        collections.experiments && collections.experiments.data
                      }
                      selectedIdx={selectedIdx}
                      onSelect={this.itemSelected}
                    />
                  </GraphQLInfiniteScroll>
                ) : null}
              </section>
            </div>
            <section className="Aside exp">
              {this.state.selectedIdx !== -1 && (
                <Aside
                  currentUser={this.props.currentUser}
                  loading={loading}
                  account={account}
                  onChange={this.onChange}
                  traits={experimentTrait?.traits}
                  experiment={
                    collections.experiments && collections.experiments.data
                      ? collections.experiments.data[selectedIdx]
                      : null
                  }
                />
              )}
            </section>
          </React.Fragment>
        )}
        {view === ViewType.GANTT && (
          <Gantt
            experiments={
              collections.experiments && collections.experiments.data
            }
            location={this.props.location}
            getExperimentsRequest={this.props.getExperimentsRequest}
            clearExperiments={this.props.clearExperiments}
          />
        )}
        {view === ViewType.TABLE && (
          <section className="Experiments Table">
            <div className="SearchBar">
              <SearchBar
                onChange={(search) => this.setState({ ...this.state, search })}
                value={this.state.search}
                placeholder="Search for tests"
                onSubmit={this.onSearchSubmit}
                enableReset
              />
            </div>
            <div className="table-wrapper">
              <table className="table">
                <thead>
                  <tr>
                    <th style={{ maxWidth: "20px" }}></th>
                    <th style={{ maxWidth: "20px" }}></th>
                    <th
                      className="cursor--pointer"
                      style={{ minWidth: "300px" }}
                      onClick={() =>
                        this.changeOrder("name", this.state.order.name)
                      }
                    >
                      <span className="h--sm" style={{ lineHeight: "24px" }}>
                        name
                      </span>
                      <OrderIcon orderDirection={this.state.order.name} />
                    </th>
                    <th>
                      <span className="h--sm" style={{ lineHeight: "24px" }}>
                        type
                      </span>
                    </th>
                    <th
                      className="center cursor--pointer"
                      onClick={() =>
                        this.changeOrder(
                          "startDate",
                          this.state.order.startDate
                        )
                      }
                    >
                      <span className="h--sm" style={{ lineHeight: "24px" }}>
                        start date
                      </span>
                      <OrderIcon orderDirection={this.state.order.startDate} />
                    </th>
                    <th
                      className="center cursor--pointer"
                      onClick={() =>
                        this.changeOrder("endDate", this.state.order.endDate)
                      }
                    >
                      <span className="h--sm" style={{ lineHeight: "24px" }}>
                        end date
                      </span>

                      <OrderIcon orderDirection={this.state.order.endDate} />
                    </th>
                    <th
                      className="center cursor--pointer"
                      onClick={() =>
                        this.changeOrder("status", this.state.order.status)
                      }
                    >
                      <span className="h--sm" style={{ lineHeight: "24px" }}>
                        status
                      </span>
                      <OrderIcon orderDirection={this.state.order.status} />
                    </th>
                    <th
                      className="center cursor--pointer"
                      onClick={() =>
                        this.changeOrder(
                          "impactValue",
                          this.state.order.impactValue
                        )
                      }
                    >
                      <span className="h--sm" style={{ lineHeight: "24px" }}>
                        impact
                      </span>
                      <OrderIcon
                        orderDirection={this.state.order.impactValue}
                      />
                    </th>
                    <th
                      className="centered cursor--pointer"
                      onClick={() =>
                        this.changeOrder(
                          "updatedAt",
                          this.state.order.updatedAt
                        )
                      }
                    >
                      <span className="h--sm" style={{ lineHeight: "24px" }}>
                        last updated
                      </span>
                      <OrderIcon orderDirection={this.state.order.updatedAt} />
                    </th>
                  </tr>
                </thead>
                <GraphQLInfiniteScroll
                  loadMore={this.fetchMore}
                  pageStart={1}
                  startcursor={collections.experiments?.metadata?.startCursor}
                  endcursor={collections.experiments?.metadata?.endCursor}
                  hasMore={
                    (collections.experiments &&
                      collections.experiments.has_more) ||
                    false
                  }
                  initialLoad={true}
                  loader={
                    <tr key={0}>
                      <td colSpan={9}>Loading</td>
                    </tr>
                  }
                  key={"table"}
                  useWindow={false}
                  element={"tbody"}
                  className={"table-body"}
                  ref={(ref) => (this.listRef = ref)}
                  useSelf={true}
                >
                  {collections.experiments &&
                    collections.experiments.data?.map(
                      (experiment: DataTypes.Experiment, idx: number) => {
                        const {
                          id,
                          name,
                          startDate,
                          endDate,
                          status,
                          impactValue,
                          programGoals,
                          updatedAt,
                        } = experiment;

                        return (
                          <tr key={id}>
                            <td
                              style={{ maxWidth: "20px" }}
                              className="centered"
                            >
                              {experiment.adobe && (
                                <img src="/img/adobe_sm.png" alt="adobe" />
                              )}
                            </td>
                            <td
                              style={{ maxWidth: "20px" }}
                              className="centered"
                            >
                              {experiment.star && (
                                <i className="fa fa-star active"></i>
                              )}
                            </td>
                            <td style={{ minWidth: "300px" }}>
                              <Link to={`/tests/${id}`}>{name}</Link>
                            </td>
                            <td className="centered">
                              {Helpers.getExperimentType(experiment.type)}
                            </td>
                            <td className="centered">
                              {moment(startDate).format("MM-DD-YYYY")}
                            </td>
                            <td className="centered">
                              {moment(endDate).format("MM-DD-YYYY")}
                            </td>
                            <td className="centered">
                              <StatusCircle status={status} />
                            </td>
                            {/* FIXME: Fix this */}
                            {/* <td className="centered">
                              {(programGoal as any)?.impactValue !== null && (
                                <React.Fragment>
                                  {Helpers.formatValue(
                                    (programGoal as any).impactValue,
                                    (programGoal as any).programGoal.dataType,
                                    (programGoal as any).programGoal.symbol
                                  )}
                                  <p className="text-capitalize">
                                    <small>{programGoal.name}</small>
                                  </p>
                                </React.Fragment>
                              )}
                            </td> */}
                            <td className="centered">
                              <TimeAgo datetime={updatedAt} live={false} />
                            </td>
                          </tr>
                        );
                      }
                    )}
                </GraphQLInfiniteScroll>
              </table>
            </div>
          </section>
        )}
      </React.Fragment>
    );
  }
}

Experiments.defaultProps = {
  name: "experiment",
};

const loadingSelector = createLoadingSelector(["@@experiment/GET_EXPERIMENTS"]);
const accountLoadingSelector = createLoadingSelector([
  "@@account/GET_ACCOUNT",
  "@@experiment_traits/GET_EXPERIMENTS_TRAITS",
]);
const reorderLoading = createLoadingSelector(["@@experiment/REORDER"]);

const mapStateToProps = ({
  router,
  app,
  account,
  collections,
  experimentTrait,
}: ApplicationState) => ({
  router,
  collections,
  account,
  experimentTrait,
  loading: loadingSelector(app.requests),
  accountLoading: accountLoadingSelector(app.requests),
  reorderLoading: reorderLoading(app.requests),
});

const mapDispatchToProps = {
  clearAppErrors,
  getExperimentsTraitsRequest,
  getExperimentsRequest,
  updateABExperimentRequest,
  updateAdobeExperimentRequest,
  clearExperiments,
  reorderExperimentRequest,
  showModal,
};

const connectedPage = connect(
  mapStateToProps,
  mapDispatchToProps
)(AppPage(Experiments));

export { connectedPage as Experiments };

const EmptyState = () => {
  return (
    <div className="Experiments empty-state">
      <div className="wrapper">
        <img src="/img/experiments_empty.png" alt="add new experiment" />
        <h1>Tests</h1>
        <h4>
          Your tests are making an impact! Don’t bury them in a spreadsheet or
          presentation.
        </h4>
        <p>
          Use illuminate to generate professional, easy-to-read case studies
          detailing the inspiration, hypothesis, experiences and results of your
          tests. Share them with just a click, and easily reference them later
          using the searchable/filterable repository. Get started by adding a
          test.
        </p>
        <button
          className="btn btn-primary"
          type="button"
          onClick={() => history.push("/tests/create")}
        >
          Create Test
        </button>
        <p className="mt-2">
          <i>
            Pro Tip: Check out illuminate’s integrations in your account
            settings to make data entry even simpler.
          </i>
        </p>
      </div>
    </div>
  );
};
