import React, { useMemo } from "react";
import * as DataTypes from "store/types";
import {
  TargetCurrentProgressBar,
  TargetCurrentProgressContainer,
  TargetRow,
  TargetTable,
} from "./index.style";
import moment from "moment";
import { Helpers } from "../../../../../../utils";
import { TargetListContainer } from "../goal_metric.style";

type Props = {
  programGoal: DataTypes.ProgramGoal;
  onDelete: (targetId: string) => void;
  expired?: boolean;
};

export const TargetCurrentProgress: React.FC<{
  currentValue: number;
  value: number;
  type: string;
  expired?: boolean;
  symbol?: string;
}> = ({ currentValue, value, type, expired, symbol }) => {
  const percentage = useMemo(() => {
    if (currentValue > value) {
      return 100;
    }

    if (value === 0) {
      return 0;
    }

    return (currentValue / value) * 100;
  }, [currentValue, value]);

  return (
    <TargetCurrentProgressContainer
      expired={expired || false}
      percentage={percentage}
    >
      <TargetCurrentProgressBar
        percentage={percentage}
        expired={expired || false}
      ></TargetCurrentProgressBar>
      <div>
        <p>
          {Helpers.formatValue(currentValue, type, symbol)} /{" "}
          {Helpers.formatValue(value, type, symbol)}
        </p>
      </div>
    </TargetCurrentProgressContainer>
  );
};

export const ProgramGoalTargetList: React.FC<Props> = ({
  programGoal,
  onDelete,
  expired,
}) => {
  const targets = useMemo(() => {
    if (expired) {
      return programGoal.expired_targets || [];
    }

    return programGoal.targets || [];
  }, [expired, programGoal]);

  return (
    <TargetListContainer>
      <h4>Targets</h4>

      <TargetTable>
        <TargetRow>
          <p>
            <strong>Name</strong>
          </p>
          <p>
            <strong>Timing</strong>
          </p>
          <p>
            <strong>Progress</strong>
          </p>
          <div></div>
        </TargetRow>

        {targets.map((target) => (
          <TargetRow key={target.id}>
            <p>{target.name}</p>
            <p>
              {moment(target.starts_at).format("MM/DD/YYYY")} -{" "}
              {moment(target.ends_at).format("MM/DD/YYYY")}
            </p>

            <TargetCurrentProgress
              expired={expired}
              currentValue={target.current_value || 0}
              value={target.value}
              type={programGoal.data_type || ""}
              symbol={programGoal.symbol}
            />

            <div className="actions">
              <button
                className="dropdown-button"
                onClick={(e) => onDelete(target.id)}
              >
                <i className="far fa-trash-alt"></i>
              </button>
            </div>
          </TargetRow>
        ))}
      </TargetTable>
    </TargetListContainer>
  );
};
