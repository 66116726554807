import styled from "styled-components";

export const Container = styled("div")`
  .react-date-picker {
    border: 2px solid #eaeaea;
    padding: 0;
    width: 280px;

    .react-date-picker__calendar--open {
      top: 100% !important;
      bottom: unset !important;
    }

    .react-date-picker__wrapper {
      padding: 0.375rem 0.75rem;
      border: 0;

      .react-date-picker__inputGroup {
        position: relative;
        top: -5px;

        input {
          color: #495057;
          font-size: 16px;
        }

        &:hover {
          cursor: pointer;
        }
      }
    }
  }
`;
