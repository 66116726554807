import React from "react";
import { connect } from "react-redux";
import queryString from "query-string";
import _ from "lodash";
import InfiniteScroll from "comps/infinite_scroll";
import { ApplicationState } from "store/types";
import * as DataTypes from "store/types";
import { createLoadingSelector } from "store/selectors";
import { getInsightsTraitsRequest } from "store/insight_trait/actions";
import {
  getInsightsRequest,
  createInsightRequest,
  updateInsightRequest,
  deleteInsightRequest,
  reorderInsightRequest,
  uploadInsightsRequest,
} from "store/insight/actions";
import { Filters } from "./comps";
import { Scrollable } from "comps/base/scrollable";
import { AppPage, AppPageProps } from "comps/pages";
import { history, Helpers } from "utils";
import { clearAppErrors, showModal, hideModal } from "store/app/actions";
import * as Modals from "./comps/modals";
import { InsightsList, Aside } from "./comps";
import { SearchBar, TotalCountStat } from "ui";
import "css/Insights.scss";
import { OrderDirection } from "ui/order_icon";
import { CustomLoader } from "ui/loaders";
import { AuthService } from "utils/auth";
import { BestInClass } from "./comps/modals/best_in_class";

enum ViewType {
  LIST = "list",
  CHART = "chart",
}

enum PageActions {
  NONE = "none",
  DELETE = "delete",
  UPLOAD_INSIGHTS = "upload_insights",
}

interface IProps extends AppPageProps {
  router: any;
  account: DataTypes.Account | null;
  user: DataTypes.User | null;
  currentUser: any;
  clearAppErrors: () => void;
  getInsightsRequest: (
    options: DataTypes.ApiListOptions,
    scrollOpts: { append: boolean }
  ) => void;
  createInsightRequest: (options: DataTypes.InsightRequest) => void;
  updateInsightRequest: (options: any) => void;
  deleteInsightRequest: (id: string) => void;
  uploadInsightsRequest: (file: any) => void;
  showModal: (component: React.ComponentType<any>, options: any) => void;
  getInsightsTraitsRequest: () => void;
  reorderInsightRequest: (
    id: string,
    position: number,
    currentDirection: OrderDirection
  ) => void;
  reorderLoading: boolean;
  loading: boolean;
  accountLoading: boolean;
  collections: any;
  name: string;
  insightTrait: any;
}

interface IState {
  selectedIdx: number;
  view: ViewType;
  selectedFilters: { [key: string]: Array<string> };
  resetintFilter: boolean;
  sort: string;
  search: string;
  currentOrder: string;
  limit: number;
  order: {
    [key: string]: OrderDirection;
  };
}

const SORT = {
  MOST_RECENT: "updated_at",
  LEAST_RECENT: "-updated_at",
};

class Insights extends Scrollable<IProps, IState> {
  static defaultProps: any;
  listRef: any;

  constructor(props: IProps) {
    super(props);

    const query = queryString.parse(this.props.location.search, {
      arrayFormat: "bracket",
    });

    const { orderBy, orderDirection } = query;

    let order = {
      customOrder: OrderDirection.NONE,
      value: OrderDirection.NONE,
      createdAt: OrderDirection.NONE,
      updatedAt: OrderDirection.NONE,
      confidence: OrderDirection.NONE,
    };

    if (orderBy) {
      order = { ...order, [orderBy as any]: orderDirection };
    }

    this.state = {
      selectedIdx: 0,
      limit: 25,
      view: ViewType.LIST,
      selectedFilters: {},
      sort: SORT.MOST_RECENT,
      search: "",
      currentOrder: (orderBy as string) || "",
      order,
      resetintFilter: false,
    };

    this.fetchMore = _.debounce(this.fetchMore, 1000, {
      leading: true,
      trailing: false,
    });
  }

  private getSortString() {
    if (!this.state.currentOrder) {
      return "updated_at";
    }

    const direction =
      this.state.order[this.state.currentOrder] === OrderDirection.ASC
        ? "-"
        : "";

    return `${direction}${Helpers.camelToSnake(this.state.currentOrder)}`;
  }

  private resetetOrders() {
    return Object.keys(this.state.order).reduce(
      (acc, current) => ({ ...acc, [current]: OrderDirection.NONE }),
      {}
    );
  }

  private changeOrder = (orderBy: string, currentDirection: OrderDirection) => {
    let newDirection: OrderDirection;

    if (orderBy !== "customOrder") {
      if (currentDirection === OrderDirection.DESC) {
        newDirection = OrderDirection.ASC;
      } else {
        newDirection = OrderDirection.DESC;
      }
    } else {
      newDirection = OrderDirection.ASC;
    }

    const order = { ...this.resetetOrders(), [orderBy]: newDirection };
    this.setState(
      {
        order,
        currentOrder: orderBy,
      },
      () => {
        const query = queryString.parse(this.props.location.search, {
          arrayFormat: "bracket",
        });
        query.orderBy = orderBy;
        query.orderDirection = newDirection;

        const qStr = queryString.stringify(query, {
          encode: true,
          arrayFormat: "bracket",
        });
        history.push(`?${qStr}`);

        const q = queryString.parse(this.props.location.search, {
          arrayFormat: "bracket",
        });
        this.props.getInsightsRequest(
          {
            limit: 25,
            sort: this.getSortString(),
            q: q.q,
          },
          { append: false }
        );
      }
    );
  };

  itemSelected = (itemIndex: number) => {
    this.setState({ selectedIdx: itemIndex });
  };

  private setPageAction = (
    action: PageActions,
    insight: DataTypes.Insight | null
  ) => {
    this.props.clearAppErrors();

    // Show delete modal
    if (action === PageActions.DELETE) {
      this.props.showModal(Modals.Delete, {
        insight,
        onSubmit: this.props.deleteInsightRequest,
      });
    }

    if (action === PageActions.UPLOAD_INSIGHTS) {
      this.props.showModal(Modals.InsightsCsv, {
        account: this.props.account,
        uploadLoading: false,
        onSubmit: this.onInsightsCsvUpload,
      });
    }
  };

  private onInsightsCsvUpload = (file: any) => {
    if (!file) {
      return;
    }

    this.props.uploadInsightsRequest(file);
  };

  componentDidMount = () => {
    let q = queryString.parse(this.props.location.search, {
      arrayFormat: "bracket",
    });

    if (Array.isArray(q.q)) {
      this.setState({
        selectedFilters: Helpers.getFiltersFromQueryString(q.q),
      });
    }

    this.props.getInsightsTraitsRequest();
    this.props.getInsightsRequest(
      { sort: this.getSortString(), q: q.q },
      { append: false }
    );
    super.componentDidMount();
  };

  changeView = (view: ViewType) => {
    history.push("?view=" + view);
    this.setState({ view: view });
  };

  private onChange = (
    id: string,
    key: string,
    value: string | Array<string> | boolean | null
  ) => {
    if (this.isReadOnly()) {
      return;
    }

    this.props.updateInsightRequest({ id, [key]: value });
  };

  private isReadOnly = () => {
    const user = AuthService.getActiveUser();

    return user.role === "read-only";
  };

  private clearFilters = () => {
    const qStr = queryString.stringify(
      {},
      {
        encode: true,
        arrayFormat: "bracket",
      }
    );
    this.setState(
      {
        selectedFilters: {},
        resetintFilter: true,
      },
      () => {
        this.setState({
          resetintFilter: false,
        });
      }
    );
    history.push(`/insights?${qStr}`);

    this.props.getInsightsRequest(
      {
        q: null,
        sort: this.state.sort,
      },
      { append: false }
    );
  };

  private onFilterCheck = (
    checked: boolean,
    type: string,
    value: string,
    remove = false
  ) => {
    let q = queryString.parse(this.props.location.search, {
      arrayFormat: "bracket",
    });

    if (remove && q.q) {
      q.q = q.q.filter((item: string) => !new RegExp(`${type}=*.`).test(item));
    }

    if (checked) {
      if (q.q && Array.isArray(q.q)) {
        q.q.push(`${type}=${value}`);
      } else {
        q.q = [`${type}=${value}`];
      }
    } else {
      if (q.q && Array.isArray(q.q)) {
        q.q = q.q.filter((item: string) => item !== `${type}=${value}`);
      }
    }

    const qStr = queryString.stringify(q, {
      encode: true,
      arrayFormat: "bracket",
    });

    if (Array.isArray(q.q)) {
      this.setState({
        selectedFilters: Helpers.getFiltersFromQueryString(q.q),
      });
    }

    history.push(`/insights?${qStr}`);
    this.props.getInsightsRequest(
      { sort: this.state.sort, q: q.q },
      { append: false }
    );
  };

  private onSearchSubmit = (search: string) => {
    let q = queryString.parse(this.props.location.search, {
      arrayFormat: "bracket",
    });

    if (search) {
      if (q.q && Array.isArray(q.q)) {
        if (q.q.filter((item: string) => /value=/.test(item)).length)
          // there can only be on search key
          q.q = q.q.map((item: string) =>
            /value=/.test(item) ? `value=${search}` : item
          );
        else q.q.push(`value=${search}`);
      } else {
        q.q = [`value=${search}`];
      }
    } else {
      if (q.q && Array.isArray(q.q)) {
        q.q = q.q.filter((item: string) => !/value=/.test(item));
      }
    }

    if (Array.isArray(q.q))
      this.setState({
        search,
        selectedFilters: Helpers.getFiltersFromQueryString(q.q),
      });

    const qStr = queryString.stringify(q, {
      encode: true,
      arrayFormat: "bracket",
    });
    history.push(`/insights?${qStr}`);
    this.props.getInsightsRequest(
      { sort: this.state.sort, q: q.q },
      { append: false }
    );
  };

  private fetchMore = (page?: number, append = true) => {
    const {
      collections: { insights },
    } = this.props;
    if (
      (insights && insights.data.length > 0 && insights.has_more) ||
      !append
    ) {
      let q = queryString.parse(this.props.location.search, {
        arrayFormat: "bracket",
      });
      const options = {
        q: q.q,
        sort: this.state.sort,
        page,
        limit: this.state.limit,
      };

      this.props.getInsightsRequest(options, { append });
    }
  };

  private openInsightsAltitude = () => {
    this.props.showModal(Modals.InsightAltitude, {
      className: "InsightAltitude",
    });
  };

  onLimitChange = (limit = 25) => {
    setTimeout(() => {
      if (this.props.loading) {
        this.onLimitChange(limit);
        return;
      }

      this.setState({ limit: limit }, () => {
        this.fetchMore(1, false);
      });
    }, 500);
  };

  private onReorder = (id: string, index: number) => {
    this.props.reorderInsightRequest(
      id,
      index,
      this.state.order[this.state.currentOrder]
    );
  };

  render() {
    const { loading, accountLoading, account, collections, insightTrait } =
      this.props;
    const { view, selectedFilters, selectedIdx } = this.state;

    if (account?.summary?.totals.insights === 0) {
      return <EmptyState />;
    }

    return (
      <React.Fragment>
        <section className="FilterBar">
          <div className="viewCtrls">
            <button
              className={
                view !== ViewType.CHART ? "btn btn-icon active" : "btn btn-icon"
              }
              onClick={(e) => this.changeView(ViewType.LIST)}
            >
              <i className="fas fa-bars" />
            </button>
          </div>

          <div className="pad20">
            <hr />
          </div>

          <TotalCountStat
            name="insights"
            total={account?.summary?.totals.insights || 0}
          />

          {this.props.currentUser.role === "read-only" ? null : (
            <div className="addCtrls">
              <button
                className="btn btn-primary"
                onClick={() => history.push("/insights/create")}
              >
                Add Insight
              </button>
            </div>
          )}

          {this.props.currentUser.role === "read-only" ? null : (
            <div className="addCtrls">
              <button
                className="btn btn-primary"
                onClick={() =>
                  this.setPageAction(PageActions.UPLOAD_INSIGHTS, null)
                }
              >
                Batch Upload
              </button>
            </div>
          )}

          <div className="w--full flex justify-center">
            <button
              className="button button__link p__zero"
              style={{ padding: 0, textDecoration: "none" }}
              onClick={() => this.props.showModal(BestInClass, {})}
            >
              <i className="fas fa-lightbulb"></i> Best in Class Example
            </button>
          </div>
          <div className="pad20">
            <hr />
          </div>

          {/* <MarketingBanner
            containerStyle={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              marginTop: "20px",
              marginLeft: "3px",
            }}
            bannerPlacementSlug={"filter_bar_banners"}
            imageStyle={{ width: "200px", height: "200px" }}
          /> */}

          {this.state.resetintFilter ? null : (
            <Filters
              onSorting={this.changeOrder}
              sortingValues={this.state.order}
              useBBMethods={account?.settings.use_bb_methods || false}
              loading={accountLoading}
              traits={insightTrait?.traits}
              onCheck={this.onFilterCheck}
              selectedFilters={selectedFilters}
              onLimitChange={this.onLimitChange}
              onClearFilters={this.clearFilters}
            />
          )}

          <div className="cta">
            <img
              src="/img/insight_alt_cta_sm.png"
              alt="Insight Altitude® Customer Insights Tool"
            />
            <button
              onClick={this.openInsightsAltitude}
              className="btn btn-info-link"
            >
              Discover meaningful insights using the Insight Altitude
              <sup>®</sup> Customer Insights Tool <br />
              by Brooks Bell
            </button>
          </div>
        </section>

        {view !== ViewType.CHART && (
          <React.Fragment>
            <section className="List Insights">
              <SearchBar
                onChange={(search) => this.setState({ search })}
                value={this.state.search}
                placeholder="Search for insights"
                onSubmit={this.onSearchSubmit}
                enableReset
              />

              <div className="scroll-container">
                <InfiniteScroll
                  pageStart={1}
                  loadMore={this.fetchMore}
                  hasMore={
                    (collections.insights && collections.insights.has_more) ||
                    false
                  }
                  initialLoad={true}
                  loader={
                    <CustomLoader
                      key={"loader"}
                      text="Wait while we load more insights for you"
                    />
                  }
                  ref={(ref) => (this.listRef = ref)}
                  useWindow={false}
                >
                  <InsightsList
                    reorderable={
                      !this.props.reorderLoading &&
                      this.state.currentOrder === "customOrder" &&
                      this.props.currentUser.role !== "read-only"
                    }
                    onReorder={this.onReorder}
                    useBBMethods={account?.settings.use_bb_methods || false}
                    selectedIdx={selectedIdx}
                    loading={loading && collections.insights === undefined}
                    insights={
                      (collections.insights && collections.insights.data) || []
                    }
                    onSelect={this.itemSelected}
                  />
                </InfiniteScroll>
              </div>
            </section>
            <section className="Aside">
              <Aside
                insight={
                  collections.insights && collections.insights.data
                    ? collections.insights.data[this.state.selectedIdx]
                    : null
                }
                account={account}
                traits={insightTrait?.traits}
                onChange={this.onChange}
                onDelete={() =>
                  this.setPageAction(
                    PageActions.DELETE,
                    collections.insights &&
                      collections.insights.data &&
                      collections.insights.data[this.state.selectedIdx]
                  )
                }
              />
            </section>
          </React.Fragment>
        )}
      </React.Fragment>
    );
  }
}

Insights.defaultProps = {
  name: "insight",
};

const loadingSelector = createLoadingSelector(["@@insight/GET_INSIGHTS"]);
const accountLoadingSelector = createLoadingSelector([
  "@@account/GET_ACCOUNT",
  "@@insight_traits/GET_INSIGHTS_TRAITS",
]);
const reorderLoadingSelector = createLoadingSelector(["@@insight/REORDER"]);

const mapStateToProps = ({
  router,
  app,
  account,
  user,
  collections,
  insightTrait,
}: ApplicationState) => ({
  router,
  loading: loadingSelector(app.requests),
  accountLoading: accountLoadingSelector(app.requests),
  reorderLoading: reorderLoadingSelector(app.requests),
  collections,
  account,
  user,
  insightTrait,
});

const mapDispatchToProps = {
  clearAppErrors,
  getInsightsTraitsRequest,
  getInsightsRequest,
  createInsightRequest,
  updateInsightRequest,
  deleteInsightRequest,
  reorderInsightRequest,
  showModal,
  hideModal,
  uploadInsightsRequest,
};

const connectedPage = connect(
  mapStateToProps,
  mapDispatchToProps
)(AppPage(Insights));

export { connectedPage as Insights };

const EmptyState = () => {
  return (
    <div className="Insights empty-state">
      <div className="wrapper">
        <img src="/img/insights_empty.png" alt="add new insight" />
        <h1>Insights</h1>
        <h4>Uncover customer insights and manage them here.</h4>
        <p>
          This is your hub for generating insights, adding supporting evidence
          to provide context, and marking your key insights so they’ll stand
          out. Use the Brooks Bell Insight Altitude<sup>®</sup> Customer
          Insights Tool, or use our free form option to build your own. Either
          way, you’ll be on your way to better understanding your customers. Get
          started by adding an insight.
        </p>
        <button
          className="btn btn-primary"
          type="button"
          onClick={() => history.push("/insights/create")}
        >
          Add Insight
        </button>
      </div>
    </div>
  );
};
