import React, { Component } from "react";
import * as DataTypes from "store/types";
import {
  Avatar,
  SelectInput,
  ScoreSelector,
  TextInput,
  DropdownControl,
  StarControl,
  MultiSelectInput,
  PainsGainsSelectInput,
} from "ui";
import TimeAgo from "timeago-react";
import { Helpers, history } from "utils";
import { createIdeasTraitsRequest } from "store/idea_trait/actions";
import { selectIdeaRequest } from "store/idea_management/actions";

import "css/Ideas.scss";
import { connect } from "react-redux";
import { EditorInput } from "ui/editor_input/editor_input";
import queryString from "query-string";

interface IProps {
  account: DataTypes.Account | null;
  currentUser: any;
  idea: DataTypes.Idea | null;
  boards: Array<DataTypes.IdeaBoard> | null;
  traits: { [key: string]: Array<DataTypes.Trait> } | null;
  onChange(
    id: string,
    key: string,
    value: string | Array<string> | boolean | Array<DataTypes.PainGain>
  ): void;
  onDelete(idea: DataTypes.Idea): void;
  onArchive(idea: DataTypes.Idea): void;
  createIdeasTraitsRequest: (
    ideaId: string,
    traitName: string,
    values: string[]
  ) => void;
  selectIdeaRequest: (idea: any, clear?: boolean) => void;
}

class Aside extends Component<IProps> {
  onBoardChange = (idea: DataTypes.Idea | undefined, board: any) => {
    if (idea && idea.id && board.id) {
      if (idea.idea_board_id === board.id) {
        this.props.onChange(idea.id, "idea_board_id", "");
      } else {
        this.props.onChange(idea.id, "idea_board_id", board.id);
      }
    }
  };

  private mergedIdeas = () => {
    const { idea } = this.props;

    const mergedNames = idea?.merged_from.map((item: any) => item.name);

    if (mergedNames.length === 2) {
      return mergedNames.join(" and ");
    }

    const length = mergedNames.length;
    return `${mergedNames.slice(0, length - 1).join(", ")} and ${
      mergedNames[length - 1]
    }`;
  };

  private showPromoted = () => {
    if (!this.props.idea) {
      return;
    }

    const { experiments } = this.props.idea;

    if (experiments && experiments.length === 1) {
      history.push(`/tests/${experiments[0].id}`);
      return;
    }

    if (experiments && experiments.length > 0) {
      const query = { ids: experiments.map((exp) => exp.id) };
      const q = queryString.stringify(query, { arrayFormat: "bracket" });
      history.push(`/tests?${q}`);
    }
  };

  private isReadOnly = () => {
    return this.props.currentUser.role === "read-only";
  };

  render() {
    const { idea, traits, boards, account } = this.props;
    if (!idea) return null;

    const useBBMethods = account?.settings.use_bb_methods;

    let selectedBoard: DataTypes.IdeaBoard | undefined = undefined;
    if (idea.idea_board_id) {
      selectedBoard = boards?.find((b) => b.id === idea.idea_board_id);
    }

    let controlItems = [
      {
        name: "Delete Idea",
        icon: "far fa-trash-alt",
        onClick: () => this.props.onDelete(idea),
        splitAbove: false,
        className: "danger",
      },
      {
        name: "Promote to test",
        icon: "fas fa-flask",
        splitAbove: false,
        onClick: () => {
          this.props.selectIdeaRequest(idea, true);
          history.push(`/tests/create/idea`);
        },
        className: "",
      },
    ];

    if (idea.archived) {
      controlItems = [
        {
          name: "Restore",
          icon: "fas fa-undo",
          onClick: () => this.props.onArchive(idea),
          splitAbove: true,
          className: "",
        },
        ...controlItems,
      ];
    } else {
      controlItems = [
        {
          name: "Archive",
          icon: "fas fa-archive",
          onClick: () => this.props.onArchive(idea),
          splitAbove: true,
          className: "",
        },
        ...controlItems,
      ];
    }

    return (
      <div className="Idea">
        <section className="header">
          <div className="left">
            <div className="status-btns">
              <button
                key={["status_love_", idea.id].join("_")}
                className={`btn btn-status ${idea.status}`}
                type="button"
                onClick={() =>
                  this.props.onChange(
                    idea.id || "",
                    "status",
                    idea.status === "love" ? "none" : "love"
                  )
                }
              >
                <i className="fas fa-heart" />
              </button>
              <button
                key={["status_backburner_", idea.id].join("_")}
                className={`btn btn-status ${idea.status}`}
                type="button"
                onClick={() =>
                  this.props.onChange(
                    idea.id || "",
                    "status",
                    idea.status === "back_burner" ? "none" : "back_burner"
                  )
                }
              >
                <i className="fas fa-fire-alt" />
              </button>
              {idea.experiments && idea.experiments.length > 0 && (
                <button
                  className={`btn btn-status ${idea.status}`}
                  type="button"
                  onClick={() => this.showPromoted()}
                >
                  <i className="fas fa-flask text__primary"></i>
                </button>
              )}
            </div>
          </div>
          <div className="right">
            <div className="controls">
              <StarControl
                key={["starred_", idea.id].join("_")}
                starred={idea.star}
                onClick={(value) =>
                  this.props.onChange(idea.id || "", "star", value)
                }
              />
              {!this.isReadOnly() && (
                <DropdownControl controlItems={controlItems} />
              )}
            </div>
          </div>
        </section>
        <section className="title-bar">
          <div>
            <p className="date">
              Updated <TimeAgo datetime={idea.updated_at} live={false} />
            </p>
            <TextInput
              key={["name", idea.id].join("_")}
              value={idea.name || ""}
              onChange={(value) =>
                this.props.onChange(idea.id || "", "name", value)
              }
            >
              <h2>{idea.name}</h2>
            </TextInput>
          </div>
        </section>
        {idea.merged_from?.length > 0 ? (
          <section>
            <p>
              This idea is a hybrid of the following ideas: {this.mergedIdeas()}
            </p>
          </section>
        ) : null}
        <section>
          <div className="userInfo">
            <div className="img">
              <div className="avatar-stack">
                {idea.contributors &&
                  idea.contributors.reverse().map((user, idx) => {
                    return (
                      <div
                        style={{
                          position: "relative",
                          left: `${8 * idx - 8}px`,
                        }}
                        key={`user_${user.id}`}
                      >
                        <Avatar user={user} showHover={true} />
                      </div>
                    );
                  })}
                {idea.author && (
                  <div style={{ position: "relative" }}>
                    <Avatar
                      user={idea.author}
                      className="focus author"
                      showHover={true}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        </section>
        <section className="questions">
          <div className="question">
            <label>What problem/opportunity do you want to address?</label>
            <EditorInput
              content={idea.problem}
              onBlur={(e: any) =>
                this.props.onChange(idea.id || "", "problem", e)
              }
            />
          </div>
          <div className="question">
            {useBBMethods ? (
              <label>
                Whypothesis<sup>&reg;</sup> Customer Insights Tool
              </label>
            ) : (
              <label>Hypothesis</label>
            )}
            <EditorInput
              content={idea.hypothesis}
              onBlur={(e: any) =>
                this.props.onChange(idea.id || "", "hypothesis", e)
              }
            />
          </div>
          <div className="question">
            <label>
              What is the proposed variation that would address the
              problem/opportunity you identified?
            </label>
            <EditorInput
              content={idea.proposed}
              onBlur={(e: any) =>
                this.props.onChange(idea.id || "", "proposed", e)
              }
            />
          </div>

          <div className="question">
            <label className="m-0">Themes</label>
            <p className="m-0">
              <small>Add a theme to help you group similar ideas</small>
            </p>
            <MultiSelectInput
              key={["themes_", idea.id].join("_")}
              activeItems={idea.themes}
              name="themes"
              items={
                traits && traits["themes"]
                  ? Helpers.uniqueArray(
                      traits["themes"].map((t) => {
                        return t.name;
                      })
                    )
                  : []
              }
              onChange={(values) =>
                this.props.createIdeasTraitsRequest(
                  idea.id || "",
                  "themes",
                  values
                )
              }
            />
          </div>
          <div className="question">
            <label className="m-0">Audiences</label>
            <p className="m-0">
              <small>Specify what audience(s) your test should target.</small>
            </p>
            <MultiSelectInput
              key={["audiences", idea.id].join("_")}
              activeItems={idea.audiences}
              name="audiences"
              items={
                traits && traits["audiences"]
                  ? Helpers.uniqueArray(
                      traits["audiences"].map((t) => {
                        return t.name;
                      })
                    )
                  : []
              }
              onChange={(values) =>
                this.props.createIdeasTraitsRequest(
                  idea.id || "",
                  "audiences",
                  values
                )
              }
            />
          </div>
          <div className="question">
            <label className="m-0">Devices</label>
            <p className="m-0">
              <small>
                Track which device(s) should be included in your test.
              </small>
            </p>
            <MultiSelectInput
              key={["devices_", idea.id].join("_")}
              activeItems={idea.devices}
              name="devices"
              items={
                traits && traits["devices"]
                  ? Helpers.uniqueArray(
                      traits["devices"].map((t) => {
                        return t.name;
                      })
                    )
                  : []
              }
              onChange={(values) =>
                this.props.createIdeasTraitsRequest(
                  idea.id || "",
                  "devices",
                  values
                )
              }
            />
          </div>
          <div className="question">
            <label className="m-0">Pages</label>
            <p className="m-0">
              <small>Specify the page(s) that should display your test.</small>
            </p>

            <MultiSelectInput
              key={["pages_", idea.id].join("_")}
              activeItems={idea.pages}
              name="pages"
              items={
                traits && traits["pages"]
                  ? Helpers.uniqueArray(
                      traits["pages"].map((t) => {
                        return t.name;
                      })
                    )
                  : []
              }
              onChange={(values) =>
                this.props.createIdeasTraitsRequest(
                  idea.id || "",
                  "pages",
                  values
                )
              }
            />
          </div>
          {idea.author && (
            <div className="question">
              <label>Creator</label>
              <p>
                {idea.author.first_name} {idea.author.last_name}
              </p>
            </div>
          )}
          {boards && boards.length > 0 && (
            <div className="question board">
              <label>Board</label>
              <SelectInput
                key={["board_", idea.id].join("_")}
                value={selectedBoard ? selectedBoard.name : ""}
                name="board"
                removeable={true}
                placeholder="select board"
                searchEnabled={true}
                disableAddNew={true}
                itemKey="name"
                items={
                  boards
                    ? boards.map((t) => {
                        return t;
                      })
                    : []
                }
                onChange={(value) => this.onBoardChange(idea, value)}
              />
            </div>
          )}
          {account && account.settings.brands && (
            <div className="question">
              <label className="m-0">Brands</label>
              <MultiSelectInput
                key={["brands", idea.id].join("_")}
                activeItems={idea.brands || []}
                name="brands"
                items={
                  traits && traits["brands"]
                    ? Helpers.uniqueArray(
                        traits["brands"].map((t) => {
                          return t.name;
                        })
                      )
                    : []
                }
                onChange={(values) =>
                  this.props.createIdeasTraitsRequest(
                    idea.id || "",
                    "brands",
                    values
                  )
                }
              />
            </div>
          )}
          {account && account.settings.teams && (
            <div className="question">
              <label className="m-0">Teams</label>
              <MultiSelectInput
                key={["teams", idea.id].join("_")}
                activeItems={idea.teams || []}
                name="teams"
                items={
                  traits && traits["teams"]
                    ? Helpers.uniqueArray(
                        traits["teams"].map((t) => {
                          return t.name;
                        })
                      )
                    : []
                }
                onChange={(values) =>
                  this.props.createIdeasTraitsRequest(
                    idea.id || "",
                    "teams",
                    values
                  )
                }
              />
            </div>
          )}
          {account && account.settings.channels && (
            <div className="question">
              <label className="m-0">Channels</label>
              <MultiSelectInput
                key={["channels", idea.id].join("_")}
                activeItems={idea.channels || []}
                name="channels"
                items={
                  traits && traits["channels"]
                    ? Helpers.uniqueArray(
                        traits["channels"].map((t) => {
                          return t.name;
                        })
                      )
                    : []
                }
                onChange={(values) =>
                  this.props.createIdeasTraitsRequest(
                    idea.id || "",
                    "channels",
                    values
                  )
                }
              />
            </div>
          )}

          <div className="question">
            <label className="m-0">Pains & Gains</label>
            <PainsGainsSelectInput
              activeItems={this.props.idea?.tactics || []}
              name="pains_gains"
              onChange={(value) =>
                this.props.onChange(idea.id || "", "tactics", value)
              }
            />
          </div>
        </section>

        <hr className="dashed" />
        <section className="score">
          <div>
            <div className="controls">
              <div className="control">
                <label>level of effort</label>
                <ScoreSelector
                  key={["level_of_effort", idea.id].join("_")}
                  value={idea.level_of_effort}
                  onChange={(value) =>
                    this.props.onChange(idea.id || "", "level_of_effort", value)
                  }
                />
              </div>
              <div className="control">
                <label>business impact</label>
                <ScoreSelector
                  key={["business_impact", idea.id].join("_")}
                  value={idea.business_impact}
                  onChange={(value) =>
                    this.props.onChange(idea.id || "", "business_impact", value)
                  }
                />
              </div>
              <div className="control">
                <label>win likelihood</label>
                <ScoreSelector
                  key={["win_likelyhood", idea.id].join("_")}
                  value={idea.win_likelyhood}
                  onChange={(value) =>
                    this.props.onChange(idea.id || "", "win_likelyhood", value)
                  }
                />
              </div>
              <div className="control">
                <label>value of learning</label>
                <ScoreSelector
                  key={["value_of_learning", idea.id].join("_")}
                  value={idea.value_of_learning}
                  onChange={(value) =>
                    this.props.onChange(
                      idea.id || "",
                      "value_of_learning",
                      value
                    )
                  }
                />
              </div>
            </div>
          </div>
          <div className="total">
            <div className="box">
              <h2>{idea.score}</h2>
              <p>score</p>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

const mapStateToProps = ({ router }: DataTypes.ApplicationState) => ({
  router,
});

const mapDispatchToProps = {
  createIdeasTraitsRequest,
  selectIdeaRequest,
};

const connectedPage = connect(mapStateToProps, mapDispatchToProps)(Aside);

export { connectedPage as Aside };
