import React, { Component } from "react";
import { connect } from "react-redux";
import { ApplicationState } from "store/types";

import * as DataTypes from "store/types";
import { clearAppErrors, showModal, hideModal } from "store/app/actions";
import { getUsersRequest, deleteUserRequest } from "store/account/actions";
import { updateProfileRequest } from "store/user/actions";
import { createLoadingSelector } from "store/selectors";

import { AppPage, AppPageProps } from "comps/pages";
import { SubNav } from "ui/navs";
import { UserList } from "./comps/user_list";
import { UserDeleteModal, UserChangeRoleModal } from "./comps";
import { SectionLoader } from "ui/loaders";
import InfiniteScroll from "comps/infinite_scroll";

enum PageActions {
  NONE = "none",
  DELETE_USER = "delete_user",
  CHANGE_ROLE = "change_role",
}

interface IProps extends AppPageProps {
  clearAppErrors: () => void;
  getUsersRequest: (
    options: DataTypes.ApiListOptions,
    append?: boolean
  ) => void;
  updateProfileRequest: (id: string, user: any) => void;
  deleteUserRequest: (user: DataTypes.User) => void;
  showModal: (component: React.ComponentType<any>, options: any) => void;
  hideModal: () => void;
  loading: boolean;
  loadError: boolean;
  collections: any;
}

interface IState {
  pageAction: PageActions;
}

class Users extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      pageAction: PageActions.NONE,
    };
  }

  componentDidMount = () => {
    this.props.clearAppErrors();
    this.props.getUsersRequest({});
  };

  private setPageAction = (
    action: PageActions,
    user: DataTypes.User,
    role?: String
  ) => {
    this.props.clearAppErrors();

    // Show delete user modal
    if (action === PageActions.DELETE_USER) {
      this.props.showModal(UserDeleteModal, {
        user: user,
        onSubmit: this.onDeleteUser,
      });
    }

    // Show change user role modal
    if (action === PageActions.CHANGE_ROLE) {
      if (role === "read-only") {
        if (user.role === "read-only") {
          this.props.updateProfileRequest(user.id, { role: "write" });
        } else {
          this.props.updateProfileRequest(user.id, { role: "read-only" });
        }
      } else {
        if (user.role === "admin") {
          this.props.showModal(UserChangeRoleModal, {
            user: user,
            onConfirm: () => this.removeAdmin(user),
          });
        } else {
          this.props.updateProfileRequest(user.id, { role: "admin" });
        }
      }
    }
  };
  private removeAdmin = (user: DataTypes.User) =>
    this.props.updateProfileRequest(user.id, { role: "write" });

  private onDeleteUser = (user: DataTypes.User) => {
    this.props.deleteUserRequest(user);
  };

  fetchMore = (page: any) => {
    this.props.getUsersRequest({ page }, true);
  };

  render() {
    const { loading, collections } = this.props;

    return (
      <React.Fragment>
        <div className="Settings">
          <div className="container">
            <SubNav
              activeIdx={1}
              navItems={[
                { name: "account", link: "/settings" },
                { name: "users", link: "/settings/users" },
                { name: "invites", link: "/settings/invites" },
              ]}
            />
            <div className="header">
              <h4>Users</h4>
              <p>Use this section to manage users and user permissions.</p>
            </div>
            {/* <SectionLoader loaded={loading}> */}
            {collections && collections.users && (
              <section>
                <InfiniteScroll
                  skipDebounce
                  pageStart={1}
                  loadMore={this.fetchMore}
                  hasMore={
                    (collections.users && collections.users.has_more) || false
                  }
                  initialLoad={true}
                  loader={
                    <div className="loader" key={0}>
                      Loading ...
                    </div>
                  }
                  useWindow={false}
                >
                  <UserList
                    users={collections.users.data}
                    onChangeRole={(user, role) =>
                      this.setPageAction(PageActions.CHANGE_ROLE, user, role)
                    }
                    onDelete={(user) =>
                      this.setPageAction(PageActions.DELETE_USER, user)
                    }
                  ></UserList>
                </InfiniteScroll>
              </section>
            )}
            {/* </SectionLoader> */}
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const loadingSelector = createLoadingSelector(["@@account/GET_USERS"]);

const mapStateToProps = ({ app, collections }: ApplicationState) => ({
  loading: loadingSelector(app.requests),
  collections: collections,
});

const mapDispatchToProps = {
  clearAppErrors,
  getUsersRequest,
  deleteUserRequest,
  updateProfileRequest,
  showModal,
  hideModal,
};

const connectedPage = connect(
  mapStateToProps,
  mapDispatchToProps
)(AppPage(Users));

export { connectedPage as Users };
