import styled from "styled-components";

export const Container = styled.li`
  padding-top: 12px;
  padding-bottom: 12px;
  display: flex;
  flex-direction: row;
  label {
    padding-left: 24px;
  }
`;
