import React, { Component } from 'react';
import * as DataTypes from '../../../../store/types';
import { Avatar, DropdownControl } from '../../../../ui';
import { AuthService } from 'utils/auth';

interface IProps {
  users: Array<DataTypes.User>;
  onDelete(user: DataTypes.User): void;
  onChangeRole(user: DataTypes.User, role?: string): void;
}

class UserList extends Component<IProps> {
  currentUser: DataTypes.User;

  constructor(props: IProps) {
    super(props);
    this.currentUser = AuthService.getActiveUser() || {};
  }

  createUserActions = (user: DataTypes.User) => {
    const controlItems: any = [
      {
        name: 'Delete',
        icon: 'far fa-trash-alt',
        onClick: () => this.props.onDelete(user),
        splitAbove: true,
        className: 'danger'
      }
    ];
    if (user.id !== this.currentUser.id) {
      controlItems.unshift({
        name: user.role === 'admin' ? 'Remove Admin' : 'Make Admin',
        icon: 'fas fa-user-tag',
        onClick: () => this.props.onChangeRole(user),
      });

      controlItems.unshift({
        name: user.role === 'read-only' ? 'Remove Read Only' : 'Make Read Only',
        icon: 'fas fa-user-tag',
        onClick: () => this.props.onChangeRole(user, 'read-only'),
      });
    }
    return controlItems;
  };
  render() {
    const { users } = this.props;
    if (!users) return null;

    return (
      <ul className="UserList">
        {users.map((user: DataTypes.User, idx: number) => {
          const controlItems = this.createUserActions(user);
          return (
            <li key={'user' + user.id}>
              <div>
                <Avatar user={user} />
              </div>
              <div className="w-100">
                <h4>
                  <span className="text-capitalize">
                    {user.first_name} {user.last_name}
                  </span>
                </h4>
                <p>
                  <small>{user.email}</small>
                </p>
              </div>
              <div className={`${user.id === this.currentUser.id ? 'pr__14' : ''}`}>
                {
                  (user.role === 'admin' || user.role === 'read-only') &&
                  <div className={`role ${user.role === 'read-only' ? 'read-only' : ''}`}>
                    {user.role}
                  </div>
                }
              </div>
              <div>
                <div className="control">{user.id !== this.currentUser.id && <DropdownControl controlItems={controlItems} />}</div>
              </div>
            </li>
          );
        })}
      </ul>
    );
  }
}

export { UserList };
