import React, { Component } from "react";
import * as DataTypes from "store/types";

interface IProps {
  experiment: DataTypes.Experiment | null;
  saving: boolean;
  onSubmit(): void;
}

class DeleteAreaOfFocus extends Component<IProps> {
  render() {
    const { onSubmit, saving } = this.props;

    return (
      <React.Fragment>
        <div className="header">
          <h3>DELETE AREA OF FOCUS</h3>
          <p>
            Are you sure you want to delete this area of focus? This action
            cannot be undone and this area of focus will be{" "}
            <strong>permanently deleted </strong>
            from illuminate.
          </p>
        </div>
        <div className="cntrl-bar">
          {saving === true ? (
            <button className="btn btn-danger" type="submit" disabled={true}>
              <i className="fas fa-circle-notch fa-spin" />
            </button>
          ) : (
            <button
              className="btn btn-danger  btn-no-transform"
              onClick={() => onSubmit()}
            >
              Yes, delete this AREA OF FOCUS.{" "}
            </button>
          )}
        </div>
      </React.Fragment>
    );
  }
}

export { DeleteAreaOfFocus };
