import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import * as API from "../../api";
import * as AppTypes from "../app/types";
import * as Actions from "./actions";
import { ActionTypes } from "./types";
import { history } from "utils";

function* createAccount(action: any) {
  try {
    // @ts-ignore
    const res = yield call(API.Account.create, action.payload.body);
    const { slug } = res.body;
    yield put(Actions.createAccountSuccess(res.body));

    window.location.href = `${process.env.REACT_APP_HTTP_PROTO}://${slug}.${process.env.REACT_APP_BASE_URL}/`;
  } catch (error: any) {
    yield put({
      type: AppTypes.App.ALERT_SHOW,
      content: error.body?.errors?.params,
      error: true,
    });

    yield put(Actions.createAccountError(error));
  }
}

function* getAccount(action: any) {
  try {
    // @ts-ignore
    const res = yield call(API.Account.get, action.payload.slug);

    yield put(Actions.getAccountSuccess(res.body));

    if (action.payload.callback) {
      action.payload.callback();
    }
  } catch (error: any) {
    yield put(Actions.getAccountError(error));
  }
}

function* getAccountSummary(action: any) {
  try {
    // @ts-ignore
    const res = yield call(API.Account.summary);

    yield put(Actions.getAccountSummarySuccess(res.body));
  } catch (error: any) {
    yield put(Actions.getAccountSummaryError(error));
  }
}

function* getAccountStats(action: any) {
  try {
    // @ts-ignore
    const res = yield call(
      API.Account.stats,
      action.payload.start_date,
      action.payload.end_date,
      action.payload.brand,
      action.payload.team,
      action.payload.channel
    );

    yield put(Actions.getAccountStatsSuccess(res.body));
  } catch (error: any) {
    yield put(Actions.getAccountStatsError(error));
  }
}

function* getAccountTraits(action: any) {
  try {
    // @ts-ignore
    const res = yield call(API.Account.traits);

    yield put(Actions.getAccountTraitsSuccess(res.body));
  } catch (error: any) {
    yield put(Actions.getAccountTraitsError(error));
  }
}

function* getShareAccountTraits(action: any) {
  try {
    // @ts-ignore
    const res = yield call(API.Share.traits, action.payload.slug);

    yield put(Actions.getShareAccountTraitsSuccess(res.body));
  } catch (error: any) {
    yield put(Actions.getShareAccountTraitsError(error));
  }
}

function* getAccountApps(action: any) {
  try {
    // @ts-ignore
    const res = yield call(API.Apps.index);
    yield put(Actions.getAccountAppsSuccess(res.body));
  } catch (error: any) {
    yield put(Actions.getAccountAppsError(error));
  }
}

function* createApp(action: any) {
  try {
    // @ts-ignore
    const res = yield call(API.Apps.createAdobe);
    yield put({ type: AppTypes.App.MODAL_OPTIONS, options: { app: res.body } });
    yield put({ type: ActionTypes.GET_ACCOUNT_APPS_REQUEST });
    yield put(Actions.createAppSuccess(res.body));
  } catch (error: any) {
    yield put(Actions.createAppError(error));
  }
}

function* updateApp(action: any) {
  try {
    yield put({ type: AppTypes.App.MODAL_SAVING });
    // @ts-ignore
    const res = yield call(
      API.Apps.updateAdobe,
      action.payload.id,
      action.payload.body
    );

    if (res.body.valid === false) {
      yield put(Actions.updateAppSuccess(res.body));
      yield put({
        type: AppTypes.App.ALERT_SHOW,
        content:
          "We could not validate your credentials, please review and try again",
        error: true,
      });
      return;
    }

    yield put({ type: ActionTypes.GET_ACCOUNT_APPS_REQUEST });
    yield put({ type: AppTypes.App.MODAL_HIDE });
    yield put({
      type: AppTypes.App.ALERT_SHOW,
      content: "App updated successfully",
    });
    yield put(Actions.updateAppSuccess(res.body));
  } catch (error: any) {
    yield put(Actions.updateAppError(error));
  }
}

function* getAppExperiments(action: any) {
  try {
    // @ts-ignore
    const res = yield call(API.Apps.experiments, action.payload.type);
    yield put(Actions.getAppExpsSuccess(action.payload.type, res.body));
  } catch (error: any) {
    yield put({
      type: AppTypes.App.ALERT_SHOW,
      error: true,
      content:
        "Unable to get app experiments. Please verify integration is working correctly.",
    });
    yield put(Actions.getAppExpsError(error));
  }
}

function* getAppMetrics(action: any) {
  try {
    // @ts-ignore
    const res = yield call(
      API.Apps.metrics,
      action.payload.type,
      action.payload.rsid
    );
    yield put(Actions.getAppMetricsSuccess(action.payload.type, res.body));
  } catch (error: any) {
    yield put(Actions.getAppMetricsError(error));
  }
}

function* getAccountUsers(action: any) {
  try {
    const { page } = action.payload.options;
    // @ts-ignore
    const res = yield call(
      API.Account.users,
      action.payload.limit,
      action.payload.sort,
      page
    );
    yield put(Actions.getUsersSuccess(res.body, action.payload.append));
  } catch (error: any) {
    yield put(Actions.getUsersError(error));
  }
}

function* deleteAccountUser(action: any) {
  try {
    yield put({ type: AppTypes.App.MODAL_SAVING });
    // @ts-ignore
    const res = yield call(API.User.destroy, action.payload.user.id);
    yield put(Actions.deleteUserSuccess(res.body));
    yield put({
      type: ActionTypes.GET_USERS_REQUEST,
      payload: { options: { offset: 0 } },
    });
    yield put({ type: AppTypes.App.MODAL_HIDE });
    yield put({
      type: AppTypes.App.ALERT_SHOW,
      content: "User deleted successfully",
    });
  } catch (error: any) {
    yield put({ type: AppTypes.App.MODAL_ERROR });
    yield put(Actions.deleteUserError(error));
  }
}

function* updateAccountProfile(action: any) {
  try {
    const { body } = action.payload;

    // @ts-ignore
    const res = yield call(API.AccountProfile.update, body.profile.id, body);

    if (action.payload.redirectUrl) {
      history.push(action.payload.redirectUrl);
    }

    yield put(Actions.updateAccountProfileSuccess(res.body));
  } catch (error: any) {
    yield put(Actions.updateAccountProfileError(error));
  }
}

function* updateAccountProgramGoals(action: any) {
  try {
    if (!action.payload.redirectUrl) {
      yield put({ type: AppTypes.App.MODAL_SAVING });
    }

    // @ts-ignore
    const res = yield call(API.ProgramGoals.create, action.payload.body);

    if (action.payload.redirectUrl) {
      history.push(action.payload.redirectUrl);
    }

    yield put(Actions.updateAccountProgramGoalsSuccess(res.body));

    if (!action.payload.redirectUrl) {
      yield put({ type: AppTypes.App.MODAL_HIDE });
    }
  } catch (error: any) {
    yield put(Actions.updateAccountProgramGoalsError(error));
  }
}

function* updateProgramGoal(action: any) {
  try {
    // @ts-ignore
    const res = yield call(
      API.ProgramGoals.update,
      action.payload.id,
      action.payload.body
    );

    yield put(Actions.updateProgramGoalSuccess(res.body));

    // @ts-ignore
    const accountRes = yield call(
      API.Account.get,
      window.location.hostname.split(".")[0]
    );

    yield put(Actions.getAccountSuccess(accountRes.body));
    yield put({
      type: AppTypes.App.ALERT_SHOW,
      content: "Program goal updated successfully",
    });
    yield put({ type: AppTypes.App.MODAL_HIDE });
  } catch (error: any) {
    yield put(Actions.updateProgramGoalError(error));
    yield put({
      type: AppTypes.App.ALERT_SHOW,
      content: "Something went wrong.",
      error: true,
    });
  }
}

function* updateAccountSettings(action: any) {
  try {
    const { settings } = action.payload;

    // @ts-ignore
    const res = yield call(API.AccountSettings.update, settings.id, {
      settings,
    });

    if (action.payload.redirectUrl) {
      history.push(action.payload.redirectUrl);
    }

    if (action.payload.settings.global_case_study_password) {
      yield put({
        type: AppTypes.App.ALERT_SHOW,
        content: "Password updated successfully",
      });
    } else {
      yield put({
        type: AppTypes.App.ALERT_SHOW,
        content: "Account settings changed successfully",
      });
    }

    yield put(Actions.updateAccountSettingsSuccess(res.body));
  } catch (error: any) {
    yield put(Actions.updateAcccountSettingsError(error));
  }
}

function* inviteNewUser(action: any) {
  try {
    yield put({ type: AppTypes.App.MODAL_SAVING });

    // @ts-ignore
    const res = yield call(API.UserInvite.create, action.payload.invite);

    yield put(Actions.inviteNewUserSuccess(res.body));
    yield put({ type: AppTypes.App.MODAL_HIDE });
    yield put({
      type: AppTypes.App.ALERT_SHOW,
      content: "User Invited Successfully",
    });
    yield put({ type: ActionTypes.GET_PENDING_INVITES_REQUEST });
  } catch (error: any) {
    yield put({ type: AppTypes.App.MODAL_ERROR });

    const content =
      error.statusCode === 401
        ? error.body.errors.auth
        : "Email already exists";
    yield put({ type: AppTypes.App.ALERT_SHOW, content, error: true });

    yield put(Actions.inviteNewUserError(error));
  }
}

function* inviteNewUsers(action: any) {
  try {
    const inviteRequests = action.payload.invites.map((invite: string) =>
      call(API.UserInvite.create, { email: invite, role: "write" })
    );
    // @ts-ignore
    const resList = yield all(inviteRequests);

    yield put(Actions.inviteNewUsersSuccess(resList));

    history.push(action.payload.url);
  } catch (error: any) {
    yield put({ type: AppTypes.App.MODAL_ERROR });

    const content =
      error.statusCode === 401
        ? error.body.errors.auth
        : "Email already exists";
    yield put({ type: AppTypes.App.ALERT_SHOW, content, error: true });

    yield put(Actions.inviteNewUsersError(error));
  }
}

function* getPendingInvites() {
  try {
    // @ts-ignore
    const res = yield call(API.Account.getPendingInvites);
    yield put(Actions.getPendingInvitesSuccess(res.body));
  } catch (error: any) {
    yield put(Actions.getPendingInvitesError(error));
  }
}

function* deletePendingInvite(action: any) {
  try {
    yield put({ type: AppTypes.App.MODAL_SAVING });
    // @ts-ignore
    const res = yield call(
      API.Account.deletePendingInvite,
      action.payload.invite.id
    );
    yield put(Actions.deletePendingInviteSuccess(res.body));
    yield put({ type: AppTypes.App.MODAL_HIDE });
    yield put({
      type: AppTypes.App.ALERT_SHOW,
      content: `Deleted Invitation for ${res.body.email}`,
    });
    yield put({ type: ActionTypes.GET_PENDING_INVITES_REQUEST });
  } catch (error: any) {
    yield put(Actions.deletePendingInviteError(error));
    yield put({
      type: AppTypes.App.ALERT_SHOW,
      error: true,
      content: "Something went wrong",
    });
  }
}

function* watchCreateAccount() {
  yield takeEvery(ActionTypes.CREATE_ACCOUNT_REQUEST, createAccount);
}

function* watchGetAccount() {
  yield takeEvery(ActionTypes.GET_ACCOUNT_REQUEST, getAccount);
}

function* watchGetAccountSummary() {
  yield takeEvery(ActionTypes.GET_ACCOUNT_SUMMARY_REQUEST, getAccountSummary);
}

function* watchGetAccountStats() {
  yield takeEvery(ActionTypes.GET_ACCOUNT_STATS_REQUEST, getAccountStats);
}

function* watchGetAccountTraits() {
  yield takeEvery(ActionTypes.GET_ACCOUNT_TRAITS_REQUEST, getAccountTraits);
}

function* watchGetAccountUsers() {
  yield takeEvery(ActionTypes.GET_USERS_REQUEST, getAccountUsers);
}

function* watchDeleteAccountUsers() {
  yield takeEvery(ActionTypes.DELETE_USER_REQUEST, deleteAccountUser);
}

function* watchUpdateSettingsAccount() {
  yield takeEvery(
    ActionTypes.UPDATE_ACCOUNT_SETTINGS_REQUEST,
    updateAccountSettings
  );
}

function* watchInviteNewUser() {
  yield takeEvery(ActionTypes.INVITE_NEW_USER_REQUEST, inviteNewUser);
}

function* watchInviteNewUsers() {
  yield takeEvery(ActionTypes.INVITE_NEW_USERS_REQUEST, inviteNewUsers);
}

function* watchGetPendingInvites() {
  yield takeEvery(ActionTypes.GET_PENDING_INVITES_REQUEST, getPendingInvites);
}

function* watchDeletePendingInvite() {
  yield takeEvery(
    ActionTypes.DELETE_PENDING_INVITE_REQUEST,
    deletePendingInvite
  );
}

function* watchGetAccountApps() {
  yield takeEvery(ActionTypes.GET_ACCOUNT_APPS_REQUEST, getAccountApps);
}

function* watchCreateApp() {
  yield takeEvery(ActionTypes.CREATE_APP_REQUEST, createApp);
}

function* watchUpdateApp() {
  yield takeEvery(ActionTypes.UPDATE_APP_REQUEST, updateApp);
}

function* watchGetAppExperiments() {
  yield takeEvery(ActionTypes.GET_APP_EXPS_REQUEST, getAppExperiments);
}

function* watchGetAppMetrics() {
  yield takeEvery(ActionTypes.GET_APP_METRICS_REQUEST, getAppMetrics);
}

function* watchUpdateAccountProfile() {
  yield takeEvery(
    ActionTypes.UPDATE_ACCOUNT_PROFILE_REQUEST,
    updateAccountProfile
  );
}

function* watchUpdateAccountProgramGoals() {
  yield takeEvery(
    ActionTypes.UPDATE_ACCOUNT_PGS_REQUEST,
    updateAccountProgramGoals
  );
}

function* watchGetShareAccountTraits() {
  yield takeEvery(
    ActionTypes.GET_SHARE_ACCOUNT_TRAITS_REQUEST,
    getShareAccountTraits
  );
}

function* watchUpdateProgramGoal() {
  yield takeEvery(ActionTypes.UPDATE_PROGRAM_GOAL_REQUEST, updateProgramGoal);
}

function* accountSaga() {
  yield all([
    fork(watchCreateAccount),
    fork(watchGetAccount),
    fork(watchGetAccountSummary),
    fork(watchGetAccountTraits),
    fork(watchGetAccountStats),
    fork(watchGetAccountUsers),
    fork(watchDeleteAccountUsers),
    fork(watchUpdateSettingsAccount),
    fork(watchInviteNewUser),
    fork(watchInviteNewUsers),
    fork(watchGetPendingInvites),
    fork(watchDeletePendingInvite),
    fork(watchCreateApp),
    fork(watchUpdateApp),
    fork(watchGetAccountApps),
    fork(watchGetAppExperiments),
    fork(watchGetAppMetrics),
    fork(watchUpdateAccountProfile),
    fork(watchUpdateAccountProgramGoals),
    fork(watchGetShareAccountTraits),
    fork(watchUpdateProgramGoal),
  ]);
}

export default accountSaga;
