import React, { Component } from 'react';
import { Form, Input, InputTypes, Button, ValidatorTypes } from 'comps/form';
import * as DataTypes from 'store/types';

interface IProps {
  board: DataTypes.IdeaBoard;
  saving: boolean;
  onSubmit(id: string, passwordProtected: boolean, password?: string): void;
}

interface IState {
  passwordProtect: boolean;
  copied: boolean;
}

class ShareBoard extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    const { board } = this.props;

    this.state = {
      copied: false,
      passwordProtect: board.share && board.share.password_protected ? board.share.password_protected : false,
    };
  }

  private copyUrl = () => {
    var copyText = document.getElementById('shareUrl') as HTMLInputElement;
    copyText.select();
    document.execCommand('Copy');
    this.setState({ ...this.state, copied: true });
  };

  private togglePasswordProtect = (checked: boolean) => {
    const { board } = this.props;
    if (board.id) {
      if (checked === false && board.share?.password_protected !== false) {
        this.props.onSubmit(board.id, false);
      }
      this.setState({ ...this.state, passwordProtect: checked });
    }
  };

  private updateBoard = (valid: boolean, dirty: boolean, data: any) => {
    const { board } = this.props;
    if (board.id && valid) {
      this.props.onSubmit(board.id, true, data.password.value);
    }
  };

  render() {
    const { board, saving } = this.props;
    const { passwordProtect, copied } = this.state;
    return (
      <React.Fragment>
        <div className="header">
          <h3>Share Idea Board</h3>
          <p>Share this board to collect ideas from your team.</p>
        </div>
        <div className="body">
          <div className="form-group mt-2 mb-2">
            <label>copy the share link below</label>
            <div className="input-group mb-3">
              <input
                id="shareUrl"
                type="text"
                className="form-control"
                readOnly={true}
                value={`${window.location.protocol}//${window.location.host}/ib/${board.share?.uuid}`}
              />
              <div className="input-group-append">
                <button className={copied ? 'btn btn-success' : 'btn btn-primary'} type="button" onClick={this.copyUrl}>
                  {copied ? 'copied' : 'copy'}
                </button>
              </div>
            </div>
          </div>
          <React.Fragment>
            <div className="control">
              <div className="toggle">
                <label className="switch">
                  <input type="checkbox" defaultChecked={passwordProtect} onChange={(e) => this.togglePasswordProtect(e.target.checked)} />
                  <span className="slider round"></span>
                </label>
              </div>
              <div className="desc">
                <p>Password protect this idea board</p>
              </div>
            </div>
            {passwordProtect === true && (
              <Form
                formFields={{
                  password: {
                    name: 'password',
                    value: board.share?.password || '',
                    validator: { type: ValidatorTypes.REQUIRED, messages: { required: 'Please enter a valid password.' } },
                  },
                }}
                onSubmit={this.updateBoard}
                FormComponent={({ fields: { password }, onChange }) => (
                  <React.Fragment>
                    <div className="form-group mt-2">
                      <label>password</label>
                      <Input field={password} type={InputTypes.PASSWORD} className="form-control" onChange={(e) => onChange(password, e.target.value)} />
                    </div>
                    <div className="cntrl-bar">
                      <Button className="btn btn-primary" text="save" isLoading={saving} />
                    </div>
                  </React.Fragment>
                )}
              />
            )}
          </React.Fragment>
        </div>
      </React.Fragment>
    );
  }
}

export { ShareBoard };
