import React, { Component } from "react";
import Collapsible from "react-collapsible";
import styled from "styled-components";
import * as DataTypes from "store/types";
import { OrderDirection, OrderIcon } from "ui/order_icon";
import { Helpers } from "utils/helpers";
import { ArrowDownIcon } from "ui/arrow_down_icon";

const FilterKeys = [
  "audiences",
  "devices",
  "pages",
  "brands",
  "teams",
  "tags",
  "channels",
  "competitors",
];

type sortingNamesType = {
  [key: string]: string;
};

const sortingNames: sortingNamesType = {
  updatedAt: "Last Updated",
  status: "Win Status",
  impactValue: "Impact",
  customOrder: "Drag and Drop",
};

const buildFilterSettings = () => {
  let config = {};

  FilterKeys.forEach((key: string) => {
    config = { ...config, [key]: { showMore: false } };
  });

  return config;
};

interface IProps {
  loading: boolean;
  traits: { [key: string]: Array<DataTypes.Trait> } | null;
  selectedFilters: { [key: string]: Array<string> };
  onCheck(checked: boolean, type: string, value: string): void;
  sortingValues: { [key: string]: OrderDirection };
  onSorting(property: string, direction: OrderDirection): void;
  onLimitChange(limit: number): void;
  onClearFilters(): void;
}

interface IState {
  filterSettings: { [key: string]: { showMore: boolean } };
  maxScore: number;
}

class Filters extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      maxScore: 5,
      filterSettings: buildFilterSettings(),
    };
  }

  private onMethodologyChange = (value: string) => {
    this.setState({ ...this.state, maxScore: Number(value) });
    this.props.onCheck(true, "methodology", Number(value).toString());
  };

  private onUpdateFilterSettings = (
    key: string,
    prop: string,
    value: string | boolean
  ) => {
    this.setState({
      ...this.state,
      filterSettings: {
        ...this.state.filterSettings,
        [key]: { ...this.state.filterSettings[key], [prop]: value },
      },
    });
  };

  onCheckAuthor = (checked: boolean, type: string, value: string) => {
    const authors = this.props.traits?.authors;
    const author = authors?.filter((author) => author.name === value)[0];
    this.props.onCheck(checked, "author_id", author?.id || "");
  };

  onCheckContributor = (checked: boolean, type: string, value: string) => {
    const contributors = this.props.traits?.contributors;
    const contributor = contributors?.filter(
      (contributor) => contributor.name === value
    )[0];
    this.props.onCheck(checked, "contributor_ids", contributor?.id || "");
  };

  render() {
    const { loading, traits, onCheck, selectedFilters } = this.props;
    const { filterSettings } = this.state;

    if (loading || !traits) {
      return (
        <React.Fragment>
          <div className="Filter placeholder">
            <h4>&nbsp;</h4>
            <ul>
              <li>
                <p></p>
              </li>
              <li>
                <p></p>
              </li>
              <li>
                <p></p>
              </li>
            </ul>
          </div>
          <div className="Filter placeholder">
            <h4>&nbsp;</h4>
            <ul>
              <li>
                <p></p>
              </li>
              <li>
                <p></p>
              </li>
              <li>
                <p></p>
              </li>
            </ul>
          </div>
          <div className="Filter placeholder">
            <h4>&nbsp;</h4>
            <ul>
              <li>
                <p></p>
              </li>
              <li>
                <p></p>
              </li>
              <li>
                <p></p>
              </li>
            </ul>
          </div>
          <div className="Filter placeholder">
            <h4>&nbsp;</h4>
            <ul>
              <li>
                <p></p>
              </li>
              <li>
                <p></p>
              </li>
              <li>
                <p></p>
              </li>
            </ul>
          </div>
        </React.Fragment>
      );
    }

    return (
      <React.Fragment>
        <button
          onClick={this.props.onClearFilters}
          className="button button__link p__zero"
          style={{ paddingLeft: "20px" }}
        >
          Clear Filters
        </button>
        <div className="Filter">
          <Collapsible
            trigger={
              <h4>
                Sorting <ArrowDownIcon height="18" />
              </h4>
            }
            triggerClassName="cursor--pointer"
            triggerOpenedClassName="cursor--pointer"
          >
            <ul>
              {Object.keys(this.props.sortingValues).map(
                (sortingProperty: string) => (
                  <li key={sortingProperty}>
                    <span
                      className="h--sm w--full flex justify-between align-center cursor--pointer"
                      onClick={() =>
                        this.props.onSorting(
                          sortingProperty,
                          this.props.sortingValues[sortingProperty]
                        )
                      }
                    >
                      {sortingNames[sortingProperty] ||
                        Helpers.camelToCapitalize(sortingProperty)}
                      <span className="icon">
                        <OrderIcon
                          orderDirection={
                            this.props.sortingValues[sortingProperty]
                          }
                        />
                      </span>
                    </span>
                  </li>
                )
              )}
            </ul>
          </Collapsible>
        </div>

        <Collapsible
          triggerTagName="h4"
          trigger={
            <span>
              Methodology <ArrowDownIcon height="18" />
            </span>
          }
          triggerClassName="cursor--pointer"
          triggerOpenedClassName="cursor--pointer"
          className="Filter"
          openedClassName="Filter"
        >
          <ul>
            <li>
              <div className="w-100">
                <div className="ScoreSelector">
                  <input
                    style={{ backgroundColor: "#fbfcfc" }}
                    type="range"
                    min="0"
                    max="5"
                    step="0"
                    list="ticks"
                    onChange={(e) => this.onMethodologyChange(e.target.value)}
                  />
                  <datalist id="ticks">
                    <option>0</option>
                    <option>1</option>
                    <option>2</option>
                    <option>3</option>
                    <option>4</option>
                    <option>5</option>
                  </datalist>
                </div>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <p>Evaluative</p>
                  <p>Generative</p>
                </div>
              </div>
            </li>
          </ul>
        </Collapsible>

        <FilterGroup
          collapsable={true}
          key={"filter_group_method_kinds"}
          name={"method_kinds"}
          displayName={"methods"}
          traits={traits.method_kinds}
          onCheck={onCheck}
          selectedItems={selectedFilters.method_kinds || []}
          filterSettings={filterSettings.method_kinds}
          onUpdateFilterSettings={this.onUpdateFilterSettings}
        />

        {Object.keys(traits)
          .filter((k) => FilterKeys.includes(k))
          .map((key) => {
            return (
              <FilterGroup
                collapsable={true}
                key={`filter_group_${key}`}
                name={key}
                traits={traits[key]}
                onCheck={onCheck}
                selectedItems={selectedFilters[key] || []}
                filterSettings={filterSettings[key] || undefined}
                onUpdateFilterSettings={this.onUpdateFilterSettings}
              />
            );
          })}

        <FilterGroup
          collapsable={true}
          key={"filter_group_author"}
          name={"Creator"}
          traits={traits.authors}
          onCheck={this.onCheckAuthor}
          selectedItems={selectedFilters["authors"] || []}
          filterSettings={filterSettings["authors"] || undefined}
          onUpdateFilterSettings={this.onUpdateFilterSettings}
        />

        <FilterGroup
          collapsable={true}
          key={"filter_group_contributors"}
          name="Contributors"
          traits={traits.contributors}
          onCheck={this.onCheckContributor}
          selectedItems={selectedFilters["authors"] || []}
          filterSettings={filterSettings["authors"] || undefined}
          onUpdateFilterSettings={this.onUpdateFilterSettings}
        />

        <Collapsible
          triggerTagName="h4"
          trigger={
            <span>
              Studies Per Page <ArrowDownIcon height="18" />
            </span>
          }
          triggerClassName="cursor--pointer"
          triggerOpenedClassName="cursor--pointer"
          className="Filter"
          openedClassName="Filter"
        >
          <ul>
            <li>
              <label className="CheckBox">
                <input
                  type="radio"
                  defaultChecked={true}
                  name="per_page"
                  onChange={(e) => this.props.onLimitChange(25)}
                />
                <span className="checkmark"></span>
              </label>
              <p>25</p>
              <p></p>
            </li>

            <li>
              <label className="CheckBox">
                <input
                  name="per_page"
                  type="radio"
                  defaultChecked={false}
                  onChange={(e) => this.props.onLimitChange(50)}
                />
                <span className="checkmark"></span>
              </label>
              <p>50</p>
              <p></p>
            </li>

            <li>
              <label className="CheckBox">
                <input
                  name="per_page"
                  type="radio"
                  defaultChecked={false}
                  onChange={(e) => this.props.onLimitChange(100)}
                />
                <span className="checkmark"></span>
              </label>
              <p>100</p>
              <p></p>
            </li>

            <li>
              <label className="CheckBox">
                <input
                  name="per_page"
                  type="radio"
                  defaultChecked={false}
                  onChange={(e) => this.props.onLimitChange(200)}
                />
                <span className="checkmark"></span>
              </label>
              <p>200</p>
              <p></p>
            </li>
          </ul>
        </Collapsible>
      </React.Fragment>
    );
  }
}

interface IFilterGroupProps {
  name: string;
  displayName?: string;
  traits: Array<DataTypes.Trait>;
  onCheck(checked: boolean, type: string, value: string): void;
  selectedItems: Array<string>;
  filterSettings?: { showMore: boolean };
  onUpdateFilterSettings(
    key: string,
    prop: string,
    value: string | boolean
  ): void;
  collapsable?: boolean;
}

const FilterGroup = ({
  name,
  traits,
  displayName,
  onCheck,
  selectedItems,
  filterSettings,
  onUpdateFilterSettings,
  collapsable = false,
}: IFilterGroupProps) => {
  if (traits.length <= 0) return null;

  const options = (
    <ul>
      {traits.map((t, idx) => {
        if (filterSettings && filterSettings.showMore === false && idx > 4) {
          return null;
        }
        const id = `filter_${name}_${idx}`;
        return (
          <StyledFilter key={`filter_group_trait_${idx}`}>
            <label htmlFor={id} className="CheckBox">
              <input
                type="checkbox"
                id={id}
                defaultChecked={
                  selectedItems.indexOf(t.name) >= 0 ? true : false
                }
                onChange={(e) => onCheck(e.target.checked, name, t.name)}
              />
              <span className="checkmark"></span>
            </label>
            <p>{t.name}</p>
            <p>{t.total}</p>
          </StyledFilter>
        );
      })}
      {traits.length > 5 && (
        <li>
          {filterSettings && filterSettings.showMore === false ? (
            <button
              className="btn btn-link"
              type="button"
              onClick={() => onUpdateFilterSettings(name, "showMore", true)}
            >
              show more
            </button>
          ) : (
            <button
              className="btn btn-link"
              type="button"
              onClick={() => onUpdateFilterSettings(name, "showMore", false)}
            >
              show less
            </button>
          )}
        </li>
      )}
    </ul>
  );

  if (collapsable) {
    return (
      <Collapsible
        triggerTagName="h4"
        trigger={
          <span>
            {displayName || name} <ArrowDownIcon height="18" />
          </span>
        }
        triggerClassName="cursor--pointer"
        triggerOpenedClassName="cursor--pointer"
        className="Filter"
        openedClassName="Filter"
      >
        {options}
      </Collapsible>
    );
  }

  return (
    <div className="Filter">
      <h4>{name}</h4>
      {options}
    </div>
  );
};

export { Filters };

const StyledFilter = styled.li`
  .CheckBox input:checked ~ .checkmark {
    background-color: #2a88f2;
    border: 2px solid #2a88f2;
  }
`;
