import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import * as API from "../../api";
import * as Actions from "./actions";
import * as ExperimentActions from "../experiment/actions";
import { ActionTypes } from "./types";
import * as AppTypes from "../app/types";

function* createMetric(action: any) {
  try {
    const { experimentId, reportId, body } = action.payload;

    // @ts-ignore
    const res = yield call(
      API.ReportMetric.create,
      experimentId,
      reportId,
      body
    );

    yield put(Actions.createMetricSuccess(res.body));
    yield put(ExperimentActions.updateExperimentSuccess(res.body));
  } catch (error: any) {
    yield put(Actions.createMetricError(error));
  }
}

function* destroyMetric(action: any) {
  try {
    const { experimentId, reportId, metricId } = action.payload;

    // @ts-ignore
    const res = yield call(
      API.ReportMetric.destroy,
      experimentId,
      reportId,
      metricId
    );

    yield put(Actions.destroyMetricSuccess(res.body));
    yield put(ExperimentActions.updateExperimentSuccess(res.body));

    yield put({
      type: AppTypes.App.ALERT_SHOW,
      content: "Report metric removed successfully",
    });
  } catch (error: any) {
    yield put(Actions.destroyMetricError(error));
  }
}

function* createResult(action: any) {
  try {
    const { experimentId, metricId, reportId, body, experienceId } =
      action.payload;

    let res;
    if (body.id) {
      // @ts-ignore
      res = yield call(
        API.MetricResults.update,
        experimentId,
        reportId,
        metricId,
        body.id,
        body
      );
    }
    // @ts-ignore
    res = yield call(
      API.MetricResults.create,
      experimentId,
      reportId,
      metricId,
      {
        ...body,
        experiment_experiences_id: experienceId,
      }
    );

    yield put(Actions.createResultSuccess(res.body));
    yield put(ExperimentActions.updateExperimentSuccess(res.body));
  } catch (error: any) {
    yield put(Actions.createResultError(error));
  }
}

function* updateMetric(action: any) {
  try {
    const { experimentId, metric, report } = action.payload;

    // @ts-ignore
    const res = yield call(
      API.ReportMetric.update,
      experimentId,
      report.id,
      metric
    );

    yield put(ExperimentActions.updateExperimentSuccess(res.body));
    yield put(Actions.updateMetricSuccess(res.body));

    yield put({
      type: AppTypes.App.ALERT_SHOW,
      content: "Report metric updated successfully",
    });
  } catch (error: any) {
    yield put(Actions.updateMetricError(error));
  }
}

function* updateMetricOrder(action: any) {
  try {
    const { metric, position, experimentId, reportId } = action.payload;

    // @ts-ignore
    const res = yield call(
      API.ReportMetric.updateOrder,
      experimentId,
      reportId,
      metric.id,
      position
    );

    yield put(ExperimentActions.updateExperimentSuccess(res.body));
    yield put(Actions.updateMetricOrderSuccess(res.body));
    yield put({
      type: AppTypes.App.ALERT_SHOW,
      content: "Report metric updated successfully",
    });
  } catch (error: any) {
    yield put(Actions.updateMetricOrderError(error));
  }
}

function* watchUpdateMetric() {
  yield takeEvery(ActionTypes.UPDATE_METRIC_REQUEST, updateMetric);
}

function* watchUpdateMetricOrder() {
  yield takeEvery(ActionTypes.UPDATE_METRIC_ORDER_REQUEST, updateMetricOrder);
}

function* watchCreateMetric() {
  yield takeEvery(ActionTypes.CREATE_METRIC_REQUEST, createMetric);
}

function* watchDestroyMetric() {
  yield takeEvery(ActionTypes.DESTROY_METRIC_REQUEST, destroyMetric);
}

function* watchCreateResult() {
  yield takeEvery(ActionTypes.ADD_RESULT_REQUEST, createResult);
}

function* reportMetricSaga() {
  yield all([
    fork(watchCreateMetric),
    fork(watchDestroyMetric),
    fork(watchCreateResult),
    fork(watchUpdateMetric),
    fork(watchUpdateMetricOrder),
  ]);
}

export default reportMetricSaga;
