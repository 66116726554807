import React, { useEffect, useState, useRef } from 'react';
import { handleOutsideClick, onEnterPress } from './utils';
import { PainGain } from 'store/types';
import { AuthService } from 'utils/auth';

export const PainsGains: Array<PainGain> = [
  {
    category: 'anxiety',
    value: 'ability to cancel any time',
  },
  {
    category: 'anxiety',
    value: 'company history/longevity',
  },
  {
    category: 'anxiety',
    value: 'personalized messaging',
  },
  {
    category: 'anxiety',
    value: 'easy returns',
  },
  {
    category: 'anxiety',
    value: 'industry research',
  },
  {
    category: 'anxiety',
    value: 'no contract',
  },
  {
    category: 'anxiety',
    value: 'privacy assurances',
  },
  {
    category: 'anxiety',
    value: 'ratings',
  },
  {
    category: 'anxiety',
    value: 'reviews',
  },
  {
    category: 'anxiety',
    value: 'security badging',
  },
  {
    category: 'anxiety',
    value: 'social proof',
  },
  {
    category: 'anxiety',
    value: 'offers',
  },
  {
    category: 'anxiety',
    value: 'free returns',
  },
  {
    category: 'mental effort',
    value: 'content visible on load',
  },
  {
    category: 'mental effort',
    value: 'action-based language',
  },
  {
    category: 'mental effort',
    value: 'new paths for discovery',
  },
  {
    category: 'mental effort',
    value: 'anchored placement',
  },
  {
    category: 'mental effort',
    value: 'categorization of content',
  },
  {
    category: 'mental effort',
    value: 'color usage',
  },
  {
    category: 'mental effort',
    value: 'call to action frequency',
  },
  {
    category: 'mental effort',
    value: 'call to action design',
  },
  {
    category: 'mental effort',
    value: 'call to action copy',
  },
  {
    category: 'mental effort',
    value: 'call to action placement',
  },
  {
    category: 'mental effort',
    value: 'default selection',
  },
  {
    category: 'mental effort',
    value: 'design consistency',
  },
  {
    category: 'mental effort',
    value: 'content alignment',
  },
  {
    category: 'mental effort',
    value: 'font changes',
  },
  {
    category: 'mental effort',
    value: 'hierarchy',
  },
  {
    category: 'mental effort',
    value: 'image size',
  },
  {
    category: 'mental effort',
    value: 'live chat',
  },
  {
    category: 'mental effort',
    value: 'message consistency',
  },
  {
    category: 'mental effort',
    value: 'number of form fields',
  },
  {
    category: 'mental effort',
    value: 'number of images',
  },
  {
    category: 'mental effort',
    value: 'number of steps',
  },
  {
    category: 'mental effort',
    value: 'number of options',
  },
  {
    category: 'mental effort',
    value: 'page load speed',
  },
  {
    category: 'mental effort',
    value: 'phone number',
  },
  {
    category: 'mental effort',
    value: 'field pre-population',
  },
  {
    category: 'mental effort',
    value: 'product recommendations',
  },
  {
    category: 'mental effort',
    value: 'copy simplification',
  },
  {
    category: 'mental effort',
    value: 'image removal',
  },
  {
    category: 'mental effort',
    value: 'link removal',
  },
  {
    category: 'mental effort',
    value: 'navigation removal',
  },
  {
    category: 'mental effort',
    value: 'sign in',
  },
  {
    category: 'mental effort',
    value: 'video functionality',
  },
  {
    category: 'mental effort',
    value: 'decision wizards',
  },
  {
    category: 'mental effort',
    value: 'form field instructions',
  },
  {
    category: 'mental effort',
    value: 'image addition',
  },
  {
    category: 'mental effort',
    value: 'link addition',
  },
  {
    category: 'mental effort',
    value: 'navigation addition',
  },
  {
    category: 'mental effort',
    value: 'log in',
  },
  {
    category: 'mental effort',
    value: 'navigation reorganization',
  },
  {
    category: 'money',
    value: 'offer presence',
  },
  {
    category: 'money',
    value: 'offer appearance',
  },
  {
    category: 'money',
    value: 'buy one get one offer',
  },
  {
    category: 'money',
    value: 'discount amount',
  },
  {
    category: 'money',
    value: 'dollar vs. percentage off',
  },
  {
    category: 'money',
    value: 'free shipping',
  },
  {
    category: 'money',
    value: 'free trial',
  },
  {
    category: 'money',
    value: 'pricing appearance',
  },
  {
    category: 'money',
    value: 'pricing appearance',
  },
  {
    category: 'money',
    value: 'free download',
  },
  {
    category: 'money',
    value: 'price reveal',
  },
  {
    category: 'time',
    value: 'limited stock',
  },
  {
    category: 'time',
    value: 'limited-time offer countdown clock',
  },
  {
    category: 'time',
    value: 'limited-time offer expiration date',
  },
  {
    category: 'time',
    value: 'limited-time offer',
  },
  {
    category: 'time',
    value: 'one-time offer',
  },
  {
    category: 'time',
    value: 'progress bar',
  },
  {
    category: 'time',
    value: 'time saving messaging',
  },
  {
    category: 'time',
    value: 'urgency messaging',
  },
  {
    category: 'value',
    value: 'monetary value',
  },
  {
    category: 'value',
    value: 'best value messaging',
  },
  {
    category: 'value',
    value: 'company mission',
  },
  {
    category: 'value',
    value: 'competitor differentiation',
  },
  {
    category: 'value',
    value: 'emotional benefits',
  },
  {
    category: 'value',
    value: 'company exclusivity',
  },
  {
    category: 'value',
    value: 'customer exclusivity',
  },
  {
    category: 'value',
    value: 'functional benefits',
  },
  {
    category: 'value',
    value: 'new product',
  },
  {
    category: 'value',
    value: 'comparison messaging',
  },
  {
    category: 'value',
    value: 'benefit to the user',
  },
  {
    category: 'value',
    value: 'video presence',
  },
  {
    category: 'value',
    value: 'video content',
  },
];

interface IProps {
  name: string;
  activeItems: Array<PainGain>;
  onChange?(values: Array<PainGain>): void;
}

const PainsGainsSelectInput = ({ name, activeItems, onChange }: IProps) => {
  const ref = useRef<any>(null);

  const [edit, setEdit] = useState(false);
  const [searchVal, setSearch] = useState('');
  const [hoverIdx, setHoverIndex] = useState(0);
  const [results, setResults] = useState([...new Set(PainsGains)]);
  const [active, setActiveItems] = useState(activeItems);
  const [exactMatch, setExactMatch] = useState(false);

  onEnterPress(ref, () => {
    open(false);
    if (searchVal !== '' && exactMatch === false) {
      let currentActiveItems = active;
      //currentActiveItems.push(searchVal);
      setSearch('');
      setActiveItems(currentActiveItems);
      if (onChange) onChange(currentActiveItems);
    }
  });

  handleOutsideClick(ref, () => {
    if (edit) {
      setSearch('');
      setHoverIndex(0);
      open(!edit);
    }
  });

  useEffect(() => {
    setResults(PainsGains);
  }, []);

  const setSearchValue = (value: string) => {
    setHoverIndex(-1);
    setSearch(value);

    const results = PainsGains.filter((i) => i.value.toLowerCase().startsWith(value.toLowerCase()) || i.category.toLowerCase().startsWith(value.toLowerCase()));
    const exact = PainsGains.filter((i) => i.value.toLowerCase() === value.toLowerCase()).length > 0;
    setExactMatch(exact);

    if (exact === true) setHoverIndex(0);

    setResults(results);
  };

  const onAddActiveItems = (value: PainGain) => {
    let currentActiveItems = active;
    if (currentActiveItems.includes(value)) {
      const itemIdx = currentActiveItems.indexOf(value);
      currentActiveItems = currentActiveItems.slice(0, itemIdx).concat(activeItems.slice(itemIdx + 1, PainsGains.length));
    } else {
      currentActiveItems.push(value);
    }

    setActiveItems(currentActiveItems);
    setSearch('');
    if (onChange) onChange(currentActiveItems);
  };

  const onRemoveActiveItems = (itemIdx: number) => {
    let currentActiveItems = active;
    currentActiveItems = currentActiveItems.slice(0, itemIdx).concat(activeItems.slice(itemIdx + 1, PainsGains.length));
    setActiveItems(currentActiveItems);
    if (onChange) onChange(currentActiveItems);
  };

  const open = (value: any) => {
    if (isReadOnly()) {
      return;
    }

    setEdit(value);
  }

  const isReadOnly = () => {
    const user = AuthService.getActiveUser();

    return user.role === 'read-only';
  }

  return (
    <div ref={ref} className={edit ? 'pandg multiselect_input edit' : 'pandg multiselect_input'}>
      <ul className="input_box" onClick={(e) => !edit && open(!edit)}>
        {active.map((item, idx) => (
          <li key={`item_${name}_${item}_${idx}`}>
            <span>
              {item.category}::{item.value}
            </span>
            {edit === true && <button type="button" onClick={(e) => onRemoveActiveItems(idx)}></button>}
          </li>
        ))}
      </ul>
      <div className="menu" style={{ zIndex: 10 }}>
        <div className="search">
          <input type="text" placeholder="Search" ref={(input) => input && input.focus()} value={searchVal} onChange={(e) => setSearchValue(e.target.value)} />
        </div>

        <div className="results">
          <ul>
            {results.map((item, idx) => (
              <li
                key={`result_${name}_${item}_${idx}`}
                className={hoverIdx === idx ? (active.includes(item) ? 'over active' : 'over') : ''}
                onMouseOver={() => setHoverIndex(idx)}
                onClick={() => onAddActiveItems(item)}
              >
                <div className="category">
                  <div className="tag">{item.category}</div>
                </div>
                {item.value}
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export { PainsGainsSelectInput };
