import React, { Component } from "react";
import { FormField } from ".";
import { ValidatorTypes } from "comps/form";
import { Validator } from "./validator";

interface Props {
  field: FormField;
  className?: string;
  placeholder?: string;
  options: Array<any>;
  onChange(e: any, idx?: number): any;
  validator?: { type?: ValidatorTypes };
  showSymbols?: boolean;
}

interface State {
  results: Array<any>;
  edit: boolean;
  hoverIdx: number;
  field: FormField;
}

class Select extends Component<Props, State> {
  validator: Validator;

  constructor(props: Props) {
    super(props);
    this.validator = new Validator();
    this.state = {
      edit: false,
      field: this.props.field,
      results: this.props.options,
      hoverIdx: 0,
    };
  }

  static getDerivedStateFromProps = (nextProps: Props) => {
    return { field: nextProps.field };
  };

  private setEdit = (edit: boolean) => {
    this.setState({ ...this.state, edit: edit });
  };

  private setHoverIndex = (idx: number) => {
    this.setState({ ...this.state, hoverIdx: idx });
  };

  public validate = () => {
    this.validator.validateField(this.state.field);
    this.setState({ field: this.state.field });
  };

  private onSelect = (value: any, idx: number) => {
    this.props.onChange(value, idx);
    this.setEdit(!this.state.edit);
  };

  private unFocus = (e: any) => {
    var currentTarget = e.currentTarget;
    setTimeout(() => {
      if (!currentTarget.contains(document.activeElement)) {
        this.validate();
      }
    }, 0);
  };

  public render() {
    const { placeholder, className } = this.props;
    const { edit, results, hoverIdx } = this.state;
    const { value, valid, error } = this.state.field;

    const cn = valid === false ? `${className || ""} error` : className;

    return (
      <div
        tabIndex={1}
        className={edit ? cn + " edit" : cn}
        onBlur={(e) => this.unFocus(e)}
      >
        <button
          type="button"
          onClick={(e) => this.setEdit(!edit)}
          className={`${
            this.props.showSymbols ? "has-symbol " + value.toLowerCase() : ""
          }`}
        >
          {value ? (
            this.props.showSymbols ? (
              [
                value.charAt(0).toUpperCase(),
                value.slice(1).toLowerCase(),
              ].join("")
            ) : (
              value
            )
          ) : (
            <span className="placeholder">{placeholder}</span>
          )}
        </button>
        <div className="menu">
          <div className="results">
            <ul>
              {results.map((item, idx) => (
                <li
                  key={`${item}_${idx}`}
                  className={`${hoverIdx === idx ? "over" : ""} ${
                    this.props.showSymbols ? "has-symbol" : ""
                  }`}
                  onMouseOver={(e) => this.setHoverIndex(idx)}
                  onClick={(e) => this.onSelect(item, idx)}
                >
                  {this.props.showSymbols
                    ? [
                        item.charAt(0).toUpperCase(),
                        item.slice(1).toLowerCase(),
                      ].join("")
                    : item}
                  {this.props.showSymbols ? (
                    <div className={`symbol ${item.toLowerCase()}`}></div>
                  ) : null}
                </li>
              ))}
            </ul>
          </div>
        </div>
        {valid === false && <div className="error">{error}</div>}
      </div>
    );
  }
}

export { Select };
