import { Reducer } from "redux";

const initialState: any = null;

const reducer: Reducer<any> = (state = initialState, action) => {
  switch (action.type) {
    default: {
      return state;
    }
  }
};

export { reducer as customDataVizReduccer };
