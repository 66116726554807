import { ApiClient } from './client/api_client';
import * as DataTypes from 'store/types';

class Tools {
  static sampleSize = async (body: DataTypes.SampleSize) => {
    return await ApiClient.post('/v1/tools/sample_size', { sample_size: body });
  }
}

export { Tools };
