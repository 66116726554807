import { ApiClient } from './client/api_client';

class Worksheets {
  static async create(idea_board_id: string, body: any) {
    return await ApiClient.post(`/v1/idea_board/${idea_board_id}/worksheet`, body);
  }

  static async update(id: string, body: any) {
    return await ApiClient.put(`/v1/idea_board/${id}/worksheet`, body);
  }

  static async show(board_id: string) {
    return await ApiClient.get(`/v1/idea_board/${board_id}/worksheet`);
  }

  static async notifyEditing(boardId: string) {
    return await ApiClient.post(`/v1/idea_board/${boardId}/worksheet/editing`, {});
  }
}

export { Worksheets };
