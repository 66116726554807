import React, { Component } from 'react';
import * as DataTypes from 'store/types';
import { Avatar } from 'ui';

interface IProps {
  uxr: DataTypes.Uxr;
  saving: boolean;
  onSubmit(selectedInsights: Array<string>): void;
}

interface IState {
  selectedInsights: Array<string>;
}

class SelectInsights extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    const { uxr } = this.props;

    this.state = {
      selectedInsights: uxr.share?.insights || [],
    };
  }

  private onCheck = (checked: boolean, insight: DataTypes.Insight) => {
    const sInsights = this.state.selectedInsights;
    if (checked === true) {
      sInsights.push(insight.id);
    } else {
      const iIdx = sInsights.findIndex((i) => i === insight.id);
      if (iIdx >= 0) sInsights.splice(iIdx, 1);
    }

    this.setState({ ...this.state, selectedInsights: sInsights });
  };

  render() {
    const { uxr, onSubmit, saving } = this.props;
    const { selectedInsights } = this.state;

    return (
      <React.Fragment>
        <div className="header">
          <h3>Select Insights</h3>
          <p>Use the checkboxes to select the insights you would like to include in your case study.</p>
        </div>

        <div className="body">
          {uxr.insights.map((insight) => {
            return (
              <div key={`insight_${insight.id}`} className="InsightSelector">
                <div className="selector">
                  <label className="CheckBox">
                    <input type="checkbox" defaultChecked={selectedInsights.includes(insight.id)} onChange={(e) => this.onCheck(e.target.checked, insight)} />
                    <span className="checkmark"></span>
                  </label>
                </div>
                <div className="insight">
                  <p>{insight.value}</p>
                </div>
                <div className="avatar">
                  <div className="avatar-stack">
                    {insight.contributors.reverse().map((user, idx) => {
                      return (
                        <div style={{ position: 'relative', left: `${Math.abs(8 * idx)}px` }} key={`user_${user.id}`}>
                          <Avatar user={user} />
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
        <div className="cntrl-bar">
          {saving === true ? (
            <button className="btn btn-primary" type="submit" disabled={true}>
              <i className="fas fa-circle-notch fa-spin" />
            </button>
          ) : (
            <button className="btn btn-primary" onClick={() => onSubmit(selectedInsights)}>
              save
            </button>
          )}
        </div>
      </React.Fragment>
    );
  }
}

export { SelectInsights };
