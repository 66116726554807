import React, { Component } from 'react';
import { PublicPage, PublicPageProps } from 'comps/pages';
import { PublicNav } from 'ui/navs';

interface IProps extends PublicPageProps {}

class PrivacyPolicy extends Component<IProps> {
  render() {
    return (
      <React.Fragment>
        <div className="Privacy">
          <PublicNav />
          <div className="d-flex">
            <div className="leftNav">
              <div>
                <a href="/terms">Terms of Service</a>
              </div>
              <div className="selected">
                <a href="/privacy_policy">Privacy Policy</a>
              </div>
            </div>
            <div className="body">
              <h1>Privacy Policy</h1>
              <p className="preface">
                Brooks Bell Interactive, Inc. (“Brooks Bell Interactive”) respects your concerns about privacy. This Privacy Notice applies to personal
                information we collect through our websites. By using our websites you agree to our collection, use, disclosure and transfer of information as
                described here.
              </p>
              <p>
                This Privacy Notice describes the types of personal information we collect or receive, how we may use that information and with whom we may
                share it. The Privacy Notice also describes the measures we take to protect the security of the personal information. We also tell you how you
                can reach us to ask us to (i) access, change or not use the personal information you have provided to us, (ii) withdraw any consent you
                previously provided to us, (iii) refrain from sending you email alerts, and (iv) answer any questions you may have about our privacy practices.
              </p>
              <p>
                <h6>Our Collection and Use of Information</h6>
                We generally collect or receive information in two ways: (1) information you provide to us, and (2) information collected via automated means.
                Each of these is described in more detail below.
              </p>
              <p>
                <h6>(1) Information You Provide to Us</h6>
                You may choose to provide personal information to us when you use our websites. Because we change our offerings and features from time to time,
                the options you have to provide us with information also may change, but here are some examples of situations in which you may decide to provide
                information to us:
                <ul>
                  <li>Registering with our websites</li>
                  <li>Creating an online account</li>
                  <li>Signing up for email alerts or subscribing to receive information</li>
                  <li>Completing a survey</li>
                  <li>Entering contests, participating in promotions, and accepting prizes</li>
                  <li>Contacting us for any reason, such as by email, including for technical support or customer service</li>
                  <li>Using our websites or online services when personal information is required for use or participation</li>
                </ul>
              </p>
              <p>
                The personal information we collect from you will vary depending on the service you are using, the site you are visiting, and how you access it.
                However, the information you provide generally may include your name, email address, phone number (which may include mobile phone number), and
                postal address.
              </p>
              <p>
                Because we directly ask you to provide information in these cases, you often will be able to tell from our specific requests what types of
                information we receive from you.
              </p>
              <p>
                We collect this information for reasons such as:
                <ul>
                  <li>To provide you with access to the site, service or feature you requested</li>
                  <li>To provide you with technical support or customer service</li>
                  <li>To communicate with you, respond to your requests, or provide you with updates and information</li>
                  <li>To administer contests and promotions, and to award prizes</li>
                  <li>To manage your account</li>
                  <li>To better understand our users, their interests and their preferences</li>
                  <li>To develop, deliver, and improve our products, services, and other offerings</li>
                </ul>
              </p>
              <p>
                <h6>(2) Information Collected by Automated Means</h6>
                We obtain certain information by automated means when you visit our sites. Usually, the information we collect does not identify you
                specifically as an individual, and we do not use this data to attempt to identify you at an individual user level. Usually, we aggregate the
                data and use it in statistical form to determine trends among groups of users. The type of information we collect by automated means may vary,
                but generally includes technical information about your computer, such as IP address or other device identifier. The information we collect also
                may include usage information and statistics about your interaction with our websites. That information may include the URLs of our web pages
                that users visited, URLs of referring and exiting pages, page views, time spent on a page, number of clicks, platform type, and other
                information about how you used our websites.
              </p>
              <p>
                In cases where you use a mobile device to access our sites we will receive information about the location of your device. In some cases, even if
                you are not using a mobile device, information about your general location may be discernable from your device’s IP address or the URLs we
                receive.
              </p>
              <p>
                We use information we collect by automated means to understand our users as a group, sometimes based on group traits such as demographics, to
                determine their preferences. As a result, we can develop and deliver new or modified offerings, improved with our users’ preferences in mind. As
                described below, we also may use it to provide a more personalized user experience and to manage advertising.
              </p>
              <p>Here are some of the types of automated data collection that may occur when you use our sites:</p>
              <p>
                <strong>
                  <i>Web Browsers</i>
                </strong>
              </p>
              <p>
                When you visit one of our websites, your browser automatically sends us your Internet protocol (“IP”) address so that the web pages you request
                can be sent to your computer or device. We use your IP address to determine additional information, such as whether the computer or device has
                ever been used to visit our site before, which site features were used the most often, and how much time was spent on a page.
              </p>
              <p>
                <strong>
                  <i>Cookies</i>
                </strong>
              </p>
              <p>
                We may automatically collect information through “cookies,” which are text files placed on your device’s hard drive when you visit websites.
                Cookies may enhance your online experience by saving your preferences while you are visiting a particular site. We use cookies, for example, for
                authentication purposes and to measure session activity on the site and determine which areas and features of the site are the most popular.
                Most browsers will tell you how to stop accepting new cookies, how to be notified when you receive a new cookie and how to disable existing
                cookies. Without cookies, however, you may not be able to take full advantage of all our site features.
              </p>
              <p>
                We also may use “flash cookies,” also called “local shared objects.” Like other cookies, flash cookies are files stored on your computer, but
                flash cookies can store information that is more complex than simple text. These cookies can be helpful to us in storing your preferences
                regarding feature settings, and personalizing your experience. In some cases local shared objects also are used to prevent fraud. Unlike
                “regular” cookies, browsers generally cannot be used to manage cookie placement. To learn more about managing flash cookies, you may wish to
                consult Adobe’s website and change the privacy settings they make available to users who wish to manage flash cookies. Like regular cookies,
                flash cookies improve your experience with our site, so disabling them may limit or eliminate features we make available to you.
              </p>
              <p>
                <h6>Information We Share</h6>
                We may share personal information we collect with our trusted business partners. We also will share information with service providers that
                perform services on our behalf. These partners and service providers are not authorized by us to use or disclose the information except as
                necessary to perform services on our behalf or comply with legal requirements.
              </p>
              <p>
                We also may disclose information about you (i) if we are required to do so by law or legal process, (ii) to law enforcement authorities or other
                government officials, (iii) when we believe disclosure is necessary or appropriate to prevent physical harm or financial loss or in connection
                with an investigation of suspected or actual illegal activity, or (iv) if necessary to protect the vital interests of another individual. We
                also reserve the right to transfer personal information we have about you in the event we sell or transfer all or a portion of our business or
                assets.
              </p>
              <p>When you use our websites, you agree that we may share information about you as described here.</p>
              <p>
                <h6>Your Choices</h6>
                In certain cases, you may have choices about how we use and disclose your information. For example, we have to ask you before we can use your
                personal information for any purpose incompatible with those purposes identified in this Notice. If we provide you with any marketing messages,
                we also will give you an opportunity to opt out of receiving further messages.
              </p>
              <p>
                You also can choose not to give us the information we request, as described in the “Information You Provide to Us” section of this Notice. In
                some cases, if you decide not to provide information we will not be able to provide you with access to the site you requested, or we may not be
                able to provide you with the service, feature or information you requested.
              </p>
              <p>
                In some cases, you can stop or limit the information we collect by automated means. Please review the sections of this Notice titled
                “Information Collected by Automated Means” to learn more about how you may be able to stop or limit our receipt of that information.
              </p>
              <p>You may exercise and amend your choices by sending an email to us as specified in the “How to Contact Us” section below.</p>
              <p>
                <h6>Access and Correction</h6>
                We will provide you with the ability to access and update certain information we have about you, such as your contact information. Your right to
                access or amend personal information may be limited in some circumstances. Please submit your request by sending an email or letter to the
                addresses specified in the “How to Contact Us” section below. Please include your name, email address and postal address in your request so that
                we may respond appropriately and promptly.
              </p>
              <p>
                <h6>How We Protect Personal Information</h6>
                We maintain appropriate administrative, technical and physical safeguards to protect your personal information from accidental, unlawful or
                unauthorized destruction, loss, alteration, access, disclosure or use and other unlawful forms of processing.
              </p>
              <p>
                <h6>Links to Other Sites</h6>
                We may provide links to other sites for your convenience and information. These sites may be operated by organizations not affiliated with
                Brooks Bell Interactive so we cannot control and are not responsible for their content, operation, or privacy practices. Those organizations may
                have their own privacy policies, which we strongly suggest you review if you use other sites. This Notice does not apply to those sites, even if
                we link to them or they link to us.
              </p>
              <p>
                <h6>Children’s Privacy</h6>
                Brooks Bell Interactive does not direct its websites to children, and we do not knowingly collect personal information from children. If we
                become aware that we have inadvertently received personal information from a user who is under the age of thirteen, we will delete the
                information from our records.
              </p>
              <p>
                <h6>Transfers of Information</h6>
                As part of our international operations, we may transfer information about you to any jurisdiction where we do business. Even if those
                jurisdictions do not have the same privacy laws as the jurisdiction where you reside, we will treat your information as subject to the
                protections described in this Notice. When you use our websites, you agree that we may transfer information about you as described here.
              </p>
              <p>
                <h6>GDPR</h6>
                Brooks Bell does not collect or store the personal data of European Union citizens; if any EU citizen data is processed by Brooks Bell it is
                aggregated and comes to us by way of our clients. Brooks Bell protects its data in accordance with the EU’s General Data Protection Regulation
                and supports the 8 basic GDPR rights afforded to users; we also expect the same of all the clients we work with. None of our data is used to
                make automated decisions and we do not transfer any data internationally. In compliance with GDPR Brooks Bell has appointed the IT Support
                Specialist position as the Data Controller (contact information can be found below).
              </p>
              <p>
                <h6>Updates to Our Privacy Notice</h6>
                This Privacy Notice may be updated periodically to you to reflect changes in our personal information practices or relevant laws. We will
                indicate at the top of the Notice when it was updated. Please review this Notice any time you access or use our sites to make sure you have
                reviewed the most recent version.
              </p>
              <p>
                <h6>How to Contact Us</h6>
                If you have any questions or comments about this Privacy Notice or if you would like us to update information we have about you or your
                preferences, please update your data privacy options here or contact us at info@brooksbell.com.
              </p>
              <p>You may also contact us via postal mail at the following address:</p>
              <div>
                <div className="address">
                  <p>Brooks Bell Interactive, Inc.</p>
                  <p>711 Hillsborough Street #200</p>
                  <p>Raleigh, North Carolina 27603</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const page = PublicPage(PrivacyPolicy);
export { page as PrivacyPolicy };
