export enum ActionTypes {
  SET_IDEA_MULTIPLE_SELECT = '@@idea_management/SET_IDEA_MULTIPLE_SELECT',

  ADD_THEMES_TO_IDEAS_REQUEST = '@@idea_management/ADD_THEMES_TO_IDEAS_REQUEST',
  ADD_THEMES_TO_IDEAS_SUCCESS = '@@idea_management/ADD_THEMES_TO_IDEAS_SUCCESS',
  ADD_THEMES_TO_IDEAS_FAILURE = '@@idea_management/ADD_THEMES_TO_IDEAS_FAILURE',

  SELECT_IDEA_REQUEST = '@@idea_management/SELECT_IDEA_REQUEST',
  SELECT_IDEA_SUCCESS = '@@idea_management/SELECT_IDEA_SUCCESS',
  SELECT_IDEA_FAILURE = '@@idea_management/SELECT_IDEA_FAILURE',

}
