import { ApiClient } from './client/api_client';

class Experiences {
  static async create(experimentId: string, body: any) {
    return await ApiClient.post(`/v1/experiment/${experimentId}/experience`, body);
  }

  static async update(experimentId: string, experienceId: string, body: any) {
    return await ApiClient.put(`/v1/experiment/${experimentId}/experience/${experienceId}`, body);
  }

  static async destroy(experimentId: string, experienceId: string) {
    return await ApiClient.delete(`/v1/experiment/${experimentId}/experience/${experienceId}`);
  }

  static async addImage(experimentId: string, experienceId: string, file: File) {
    const formData = new FormData();
    formData.append('image', file);

    return await ApiClient.postMultipart(`/v1/experiment/${experimentId}/experience/${experienceId}/image`, formData);
  }

  static async deleteImage(experimentId: string, experienceId: string, imageId: string) {
    return await ApiClient.delete(`/v1/experiment/${experimentId}/experience/${experienceId}/image/${imageId}`);
  }

  static async updateImage(experimentId: string, experienceId: string, imageId: string, body: any) {
    return await ApiClient.put(`/v1/experiment/${experimentId}/experience/${experienceId}/image/${imageId}`, { image: body });
  }

  static async addImageComment(experimentId: string, experienceId: string, imageId: string, body: any) {
    return await ApiClient.post(`/v1/experiment/${experimentId}/experience/${experienceId}/image/${imageId}/comment`, { comment: body });
  }

  static async deleteImageComment(experimentId: string, experienceId: string, imageId: string, commentId: string) {
    return await ApiClient.delete(`/v1/experiment/${experimentId}/experience/${experienceId}/image/${imageId}/comment/${commentId}`);
  }
}

export { Experiences };
