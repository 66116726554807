import React, { Component, useEffect } from "react";
import { connect } from "react-redux";
import { ApplicationState } from "store/types";
import { Redirect } from "react-router-dom";
import { history } from "utils";
import {
  createLoadingSelector,
  createErrorMessageSelector,
} from "store/selectors";
import { initAppRequest, hideModal, hideAlert } from "store/app/actions";
import { AccountLoader } from "ui/loaders";
import { Modal, Alert } from "ui";
import * as AppUtils from "utils/app";
import { getUserAccountsRequest } from "store/user_accounts/actions";
import * as DataTypes from "store/types";
import { useKeycloak } from "@react-keycloak/web";

interface IRouteProps {
  pageType: "public" | "private";
  currentUser: any;
  location: any;
  path: string;
  computedMatch: any;
  navIndex: number;
  fullScreen: boolean;
}

interface IAppProps {
  app: DataTypes.AppState;
  account: DataTypes.Account | null;
  hideModal: () => void;
  hideAlert: () => void;
  initAppRequest: (slug: string, user: DataTypes.User) => void;
  appLoading: boolean;
  appError: any;
  Page: any;
  routerProps: IRouteProps;
  getUserAccountsRequest: () => void;
}

interface IState {}

type IProps = IAppProps;

declare global {
  interface Window {
    Intercom: any;
    safari?: any;
    FS?: any;
  }
}

const InitAppComponent: React.FC<{ callback: any }> = ({ callback }) => {
  const { initialized } = useKeycloak();

  useEffect(() => {
    if (!initialized) {
      return;
    }

    callback();
  }, [initialized]);

  return null;
};

class App extends Component<IProps, IState> {
  historyListener: any;
  mounted: boolean = false;

  constructor(props: IProps) {
    super(props);

    // Initialize Intercom
    if (
      process.env.NODE_ENV === "production" &&
      this.props.routerProps.currentUser
    ) {
      window.Intercom("boot", {
        app_id: "e6nio2u9",
        account: AppUtils.App.getSubDomain(),
        name:
          this.props.routerProps.currentUser.first_name +
          " " +
          this.props.routerProps.currentUser.last_name,
        email: this.props.routerProps.currentUser.email,
      });
    }
  }

  componentDidMount = () => {
    // Listen for location change & clear screen
    this.historyListener = history.listen(() => {
      const { app } = this.props;
      if (app.modal.show === true) this.props.hideModal();
    });
    this.mounted = true;
  };

  componentWillUnmount = () => {
    this.historyListener();
  };

  private closeModal = () => {
    this.props.hideModal();
  };

  private initAppFlow = () => {
    this.props.initAppRequest(
      AppUtils.App.getSubDomain(),
      this.props.routerProps.currentUser
    );
    this.props.getUserAccountsRequest();
  };

  render() {
    const { Page, routerProps, app, account, appLoading, appError } =
      this.props;
    const { currentUser } = routerProps;

    if (this.props.appError) throw appError;

    if (
      currentUser?.role === "admin" &&
      account?.setup_complete === false &&
      routerProps.pageType === "private" &&
      routerProps.path !== "/setup"
    ) {
      return <Redirect to="/setup" />;
    }

    return (
      <>
        <InitAppComponent callback={this.initAppFlow} />
        <AccountLoader loaded={this.mounted && !appLoading}>
          <Alert
            text={app.alert.content}
            show={app.alert.show}
            error={app.alert.error}
            onTimeOut={this.props.hideAlert}
          />
          <Modal
            show={app.modal.show}
            saving={app.modal.saving}
            errors={app.errors}
            component={app.modal.component}
            onClose={this.closeModal}
            {...app.modal.options}
          />
          <Page {...routerProps} account={account} />
        </AccountLoader>
      </>
    );
  }
}

const loadingSelector = createLoadingSelector(["@@app/INIT_APP"]);
const errorSelector = createErrorMessageSelector(["@@app/INIT_APP"]);

const mapStateToProps = ({ app, account, router }: ApplicationState) => ({
  router: router,
  app: app,
  appLoading: loadingSelector(app.requests),
  appError: errorSelector(app.errors),
  account: account,
});

const mapDispatchToProps = {
  initAppRequest,
  getUserAccountsRequest,
  hideModal,
  hideAlert,
};

const connectedPage = connect(mapStateToProps, mapDispatchToProps)(App);

export { connectedPage as App };
