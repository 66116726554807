import React, { Component, RefObject } from "react";
import Dropzone, { DropEvent } from "react-dropzone";
import { connect } from "react-redux";
import { createLoadingSelector } from "store/selectors";
import * as DataTypes from "store/types";
import { ApplicationState } from "store/types";

interface IProps {
  account: DataTypes.Account;
  experiment: DataTypes.Experiment | null;
  uploadLoading: boolean;
  onSubmit(file: any): void;
  resourceName: string;
}

interface IState {
  file: any;
  pristine: any;
}

class InsightsCsv extends Component<IProps, IState> {
  uploadFileButton: RefObject<any>;
  downloadTemplateLink: RefObject<any>;

  constructor(props: IProps) {
    super(props);

    this.downloadTemplateLink = React.createRef();
    this.uploadFileButton = React.createRef();
    this.state = {
      file: null,
      pristine: true,
    };
  }

  private instructionsForBBMethodology = () => {
    if (!this.props.account?.settings?.use_bb_methods) {
      return [];
    }

    return [
      [
        "Insight Confidence value options are low or medium_low or medium_high or high. One selection only.",
      ],
      [
        "Insight Altitude value options are basecamp or summit or jetstream or satellite. One selection only.",
      ],
    ];
  };

  private csvTemplateFields = () => {
    let fields = ["value", "tags"];

    if (this.props.account?.settings?.use_bb_methods) {
      fields = [...fields, "confidence", "altitude"];
    }

    if (this.props.account?.settings?.brands) {
      fields = [...fields, "brands"];
    }

    if (this.props.account?.settings?.teams) {
      fields = [...fields, "teams"];
    }

    if (this.props.account?.settings?.channels) {
      fields = [...fields, "channels"];
    }

    return fields;
  };

  private exportCsvTemplate = () => {
    const rows = [
      [
        "Please delete these instructions before upload so that your file begins with the row of headers.",
      ],
      [
        "Do not change the headers. Doing so will prevent your data from being imported.",
      ],
      ["Simply add the values below each header."],
      ["To add multiple values to one cell use a semicolon to separate."],
      ...this.instructionsForBBMethodology(),
      [""],
      this.csvTemplateFields(),
    ];

    const csvContent = "data:text/csv;charset=utf-8,";

    const file =
      csvContent +
      encodeURIComponent(rows.map((row) => row.join(",")).join("\r\n"));

    this.downloadTemplateLink.current.href = file;
    this.downloadTemplateLink.current.download = `illuminate - insights template.csv`;

    this.downloadTemplateLink.current.click();
  };

  private onFileUpload = (
    acceptedFiles: File[],
    rejectedFiles: File[],
    event: DropEvent
  ) => {
    if (!acceptedFiles || acceptedFiles.length === 0) {
      return;
    }

    this.setState({
      file: acceptedFiles[0],
    });

    this.props.onSubmit(acceptedFiles[0]);
  };

  render() {
    const fileInvalid = !this.state.file && !this.state.pristine;

    return (
      <div className="panels">
        <div className="left">
          <label>Insights Batch Upload</label>
          <div className="header">
            <img src="/img/apps/illuminate.png" alt="api key" />
            <h2>Insights Batch Upload</h2>
          </div>

          <p className="mt-2">
            Use{" "}
            <a href="#" onClick={this.exportCsvTemplate}>
              this CSV template
            </a>{" "}
            to upload insights that you'd like to link to this {this.props.resourceName}.
            Follow the directions in the CSV to ensure your upload is
            successful.
          </p>

          <a ref={this.downloadTemplateLink} href="#"></a>
        </div>
        <div className="right">
          <Dropzone onDrop={this.onFileUpload} accept=".csv">
            {({ getRootProps, getInputProps }) => (
              <div className="">
                <div
                  {...getRootProps()}
                  className={`file_uploader ${
                    this.state.file ? "active" : ""
                  } ${fileInvalid ? "error" : ""}`}
                >
                  <div className="item-dropbox">
                    <input {...getInputProps()} />

                    <i className="fas fa-cloud-upload-alt"></i>

                    {fileInvalid ? (
                      <p>Please select a valid file</p>
                    ) : this.state.file?.name ? (
                      <p>{this.state.file.name}</p>
                    ) : (
                      <p>
                        Drag 'n drop your CSV file here, or click to select file
                      </p>
                    )}
                    <p></p>
                  </div>
                </div>
              </div>
            )}
          </Dropzone>
        </div>
      </div>
    );
  }
}

const uploadSelector = createLoadingSelector(["@@experiment/UPLOAD_REPORT"]);

const mapStateToProps = ({ app }: ApplicationState) => ({
  uploadLoading: uploadSelector(app.requests),
});

const mapDispatchToProps = {};

const connectedPage = connect(mapStateToProps, mapDispatchToProps)(InsightsCsv);

export { connectedPage as InsightsCsv };
