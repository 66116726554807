import { ApiClient } from './client/api_client';
import { ApiGetOptions } from './types';

class Ideas {
  static async uploadIdeas(file: any) {
    const formData = new FormData();
    formData.append('file', file);

    return await ApiClient.postMultipart('/v1/idea/csv', formData);
  }

  static async index(options?: ApiGetOptions) {
    return await ApiClient.get('/v1/idea', options);
  }

  static async create(body: any) {
    return await ApiClient.post('/v1/idea', body);
  }

  static async update(id: string, body: any) {
    return await ApiClient.put(`/v1/idea/${id}`, body);
  }

  static async destroy(id: string) {
    return await ApiClient.delete(`/v1/idea/${id}`);
  }

  static async show(id: string) {
    return await ApiClient.get(`/v1/idea/${id}`);
  }

  static async traits() {
    return await ApiClient.get('/v1/idea/traits');
  }

  static async shareTraits(slug: string) {
    return await ApiClient.get(`/v1/share/${slug}/idea_board/traits`)
  }

  static async addTraits(ideaId: string, trait: string, values: string[]) {
    return await ApiClient.post(`/v1/idea/${ideaId}/traits`, {
      trait,
      values,
    });
  }

  static async reorder(id: string, position: number) {
    return await ApiClient.put(`/v1/idea/${id}/order/${position}`, {});
  }

  static async exportIdeas(queryString: string, fields: string[]) {
    return ApiClient.post('/v1/idea/export', {
      export: {
        query_string: queryString,
        fields
      }
    })
  }
}

export { Ideas };
