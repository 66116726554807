import { Reducer } from "redux";
import * as DataTypes from "../types";
import { ActionTypes } from "./types";

interface uxrTraitState {
  traits: { [key: string]: Array<DataTypes.Trait> };
}

const initialState: uxrTraitState | null = null;

const reducer: Reducer<uxrTraitState | null> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case ActionTypes.GET_UXRS_TRAITS_SUCCESS: {
      return {
        ...state,
        traits: action.payload.response.traits,
      };
    }
    default: {
      return state;
    }
  }
};

export { reducer as uxrTraitReducer };
