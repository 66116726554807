import React, { Component } from "react";
import { connect } from "react-redux";
import { ApplicationState } from "store/types";
import * as DataTypes from "store/types";
import { AppPage, AppPageProps } from "comps/pages";
import { SubNav } from "ui/navs";
import {
  createLoadingSelector,
  createErrorMessageSelector,
} from "store/selectors";
import { showModal } from "store/app/actions";
import {
  inviteNewUserRequest,
  getPendingInvitesRequest,
  deletePendingInviteRequest,
} from "store/account/actions";
import { PendingInvitesList } from "./comps/pending_invites_list";
import { InviteDeleteModal, InviteNewUser, InviteCopyLink } from "./modals";
import InfiniteScroll from "comps/infinite_scroll";

enum PageActions {
  NONE = "none",
  CREATE_INVITE = "CREATE_INVITE",
  DELETE_INVITE = "DELETE_INVITE",
  COPY_INVITE_LINK = "COPY_INVITE_LINK",
}

interface IProps extends AppPageProps {
  showModal: (component: React.ComponentType<any>, options: any) => void;
  inviteNewUserRequest: (invite: { email: string; role: string }) => void;
  inviteNewUserLoading: typeof inviteNewUserLoading;
  getPendingInvitesRequest: (opts?: any) => void;
  deletePendingInviteRequest: (invite: DataTypes.Invite) => void;
  collections: any;
  loading: boolean;
}

interface IState {
  pageAction: PageActions;
}

class Invites extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      pageAction: PageActions.NONE,
    };
  }

  componentDidMount = () => {
    this.props.getPendingInvitesRequest();
  };

  private setPageAction = (action: PageActions, invite?: DataTypes.Invite) => {
    // Show delete invite modal
    if (action === PageActions.DELETE_INVITE) {
      this.props.showModal(InviteDeleteModal, {
        invite: invite,
        onSubmit: this.onDeleteInvite,
      });
    }

    if (action === PageActions.CREATE_INVITE) {
      this.props.showModal(InviteNewUser, { onSubmit: this.onCreateInvite });
    }

    if (action === PageActions.COPY_INVITE_LINK) {
      this.props.showModal(InviteCopyLink, { invite });
    }
  };

  private onCreateInvite = (invite: DataTypes.Invite) => {
    this.props.inviteNewUserRequest(invite);
  };

  private onDeleteInvite = (invite: DataTypes.Invite) => {
    this.props.deletePendingInviteRequest(invite);
  };

  private fetchMore = () => {
    const invites = this.props.collections.pendingInvites.data;
    const last = invites[invites.length - 1];
    this.props.getPendingInvitesRequest({ starting_after: last.id });
  };

  render() {
    const { collections, loading } = this.props;

    if (loading) return null;

    return (
      <div className="Settings">
        <div className="container">
          <SubNav
            activeIdx={2}
            navItems={[
              { name: "account", link: "/settings" },
              { name: "users", link: "/settings/users" },
              { name: "invites", link: "/settings/invites" },
            ]}
          />
          {loading === false &&
          collections.pendingInvites &&
          collections.pendingInvites.data.length === 0 ? (
            <EmptyState
              onCreate={() => this.setPageAction(PageActions.CREATE_INVITE)}
            />
          ) : (
            <React.Fragment>
              <div className="header">
                <button
                  className="btn btn-primary"
                  type="button"
                  onClick={() => this.setPageAction(PageActions.CREATE_INVITE)}
                >
                  Invite new user
                </button>
                <h4>Invites</h4>
                <p>Invite new users & manage invitations.</p>
              </div>
              <section>
                <InfiniteScroll
                  pageStart={0}
                  loadMore={this.fetchMore}
                  hasMore={
                    (collections.pendingInvites &&
                      collections.pendingInvites.has_more) ||
                    false
                  }
                  initialLoad={true}
                  loader={
                    <div className="loader" key={0}>
                      Loading ...
                    </div>
                  }
                  useWindow={false}
                >
                  <PendingInvitesList
                    pendingInvites={
                      collections.pendingInvites
                        ? collections.pendingInvites.data
                        : []
                    }
                    onDelete={(invite) =>
                      this.setPageAction(PageActions.DELETE_INVITE, invite)
                    }
                    onCopy={(invite) =>
                      this.setPageAction(PageActions.COPY_INVITE_LINK, invite)
                    }
                  />
                </InfiniteScroll>
              </section>
            </React.Fragment>
          )}
        </div>
      </div>
    );
  }
}

const loadingSelector = createLoadingSelector([
  "@@account/GET_PENDING_INVITES",
]);
const inviteNewUserLoading = createLoadingSelector([
  "@@account/INVITE_NEW_USER",
]);
const inviteNewUserError = createErrorMessageSelector([
  "@@account/INVITE_NEW_USER",
]);

const mapStateToProps = ({ app, collections }: ApplicationState) => ({
  loading: loadingSelector(app.requests),
  inviteNewUserLoading: inviteNewUserLoading(app.requests),
  inviteNewUserError: inviteNewUserError(app.errors),
  collections,
});

const mapDispatchToProps = {
  showModal,
  inviteNewUserRequest,
  getPendingInvitesRequest,
  deletePendingInviteRequest,
};

const connectedPage = connect(
  mapStateToProps,
  mapDispatchToProps
)(AppPage(Invites));

export { connectedPage as Invites };

interface IEmptyStateProps {
  onCreate(): void;
}

const EmptyState = ({ onCreate }: IEmptyStateProps) => {
  return (
    <div className="Insights empty-state">
      <div className="wrapper">
        <img src="/img/invite_empty.png" alt="invite new user" />
        <h2>Invites</h2>
        <h4>It's always better working with friends.</h4>
        <p>
          Invite a new user to this illuminate account,{" "}
          <strong>{window.location.host}</strong>, by clicking the button below
        </p>
        <button className="btn btn-primary" type="button" onClick={onCreate}>
          Invite new user
        </button>
      </div>
    </div>
  );
};
