import React, { Component, RefObject, createRef } from 'react';
import { AuthService } from 'utils/auth';

interface IProps {
  onChange(value: string): void;
  value: string;
  children: React.ReactNode;
  placeholder?: string;
  className?: string;
  dataType?: string;
  readonly?: boolean;
}

interface IState {
  edit: boolean;
  newValue: any;
}

class TextInput extends Component<IProps, IState> {
  ref: RefObject<any>;

  static defaultProps = {
    readonly: false,
  };

  constructor(props: IProps) {
    super(props);

    this.ref = createRef();
    this.state = {
      edit: false,
      newValue: this.props.value,
    }
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.outsideClick);
  }

  componentDidUpdate() {
    this.handleEnterPress();
    this.handleOutsideClick();
  }

  componentDidMount() {
    this.handleEnterPress();
    this.handleOutsideClick();
  }

  setEdit = (value: any) => {
    this.setState({
      edit: value,
    });
  }

  setNewValue = (value: any) => {
    this.setState({
      newValue: value,
    })
  }

  handleEnterPress = () => {
    if (!this.ref.current) {
      return;
    }

    this.ref.current.addEventListener('keyup', this.enterPress);
  };

  handleOutsideClick = () => {
    document.removeEventListener('click', this.outsideClick);
    document.addEventListener('click', this.outsideClick);
  }

  enterPress = (e: any) => {
    if (e.key === 'Enter') {
      if (this.ref.current) {
        if (this.state.edit) {
          this.setState({
            edit: !this.state.edit,
          }, () => {
            this.props.onChange(this.state.newValue);
          });
        }
      }
    }
  }

  outsideClick = (e: any) => {
    if (this.ref.current && !this.ref.current.contains(e.target)) {
      if (this.state.edit) {
        this.setState({
          edit: !this.state.edit,
        }, () => {
          this.props.onChange(this.state.newValue);
        });
      }
    }
  }

  private isReadOnly = () => {
    const user = AuthService.getActiveUser();

    return user.role === 'read-only';
  }

  render() {
    if (this.state.edit === false || this.props.readonly || this.isReadOnly()) {
      return (
        <div ref={this.ref} className={`text_input ${this.props.className || ''}`} onClick={(e) => this.setEdit(!this.state.edit)}>
          {this.props.children}
        </div>
      );
    } else {
      return (
        <div ref={this.ref} className={`text_input edit ${this.props.className || ''}`}>
          <div className={`input ${this.props.dataType}`}>
            <input
              autoFocus={this.state.edit}
              ref={this.ref}
              placeholder={this.props.placeholder}
              className="form-control"
              defaultValue={this.props.value}
              onChange={(e) => this.setNewValue(e.target.value)} />
          </div>
        </div>
      );
    }
  }
};

export { TextInput };
