import { ApiClient } from './client/api_client';

class ProfileSettings {
  static async industry() {
    return await ApiClient.get('/v1/profile_settings/industry');
  }

  static async avgExpPerMonth() {
    return await ApiClient.get('/v1/profile_settings/avg_exp_per_month')
  }
  static async coe() {
    return await ApiClient.get('/v1/profile_settings/coe')
  }
  static async companySize() {
    return await ApiClient.get('/v1/profile_settings/company_size')
  }
  static async experimentsWinPerMonth() {
    return await ApiClient.get('/v1/profile_settings/experiments_win_per_month')
  }
  static async kindOfTest() {
    return await ApiClient.get('/v1/profile_settings/kind_of_test')
  }
  static async kindOfTool() {
    return await ApiClient.get('/v1/profile_settings/kind_of_tool')
  }
  static async primaryAnalyticsTool() {
    return await ApiClient.get('/v1/profile_settings/primary_analytics_tool')
  }
  static async primaryTestingTool() {
    return await ApiClient.get('/v1/profile_settings/primary_testing_tool')
  }
  static async programType() {
    return await ApiClient.get('/v1/profile_settings/program_type')
  }
  static async testingChallenge() {
    return await ApiClient.get('/v1/profile_settings/testing_challenge')
  }
  static async testingProgramReach() {
    return await ApiClient.get('/v1/profile_settings/testing_program_reach')
  }
}

export { ProfileSettings };
