import { Reducer } from 'redux';
import * as DataTypes from '../types';
import { ActionTypes } from './types';

interface UploadError {
  line_number: number;
  message: string;
}

interface UploadMessage {
  errors: UploadError[];
}

const initialState: DataTypes.Idea | UploadMessage | null = null;

const reducer: Reducer<DataTypes.Idea | null> = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.CREATE_IDEA_SUCCESS:
    case ActionTypes.SHOW_IDEA_SUCCESS:
    case ActionTypes.UPLOAD_IDEAS_SUCCESS:
    case ActionTypes.CREATE_SHARE_IDEA_SUCCESS: {
      return action.payload.response;
    }
    case ActionTypes.CLEAR_IDEA_SUCCESS: {
      return null;
    }
    default: {
      return state;
    }
  }
};

export { reducer as ideaReducer };
