import React, { Component } from "react";
import { connect } from "react-redux";
import * as DataTypes from "store/types";
import { ApplicationState } from "store/types";
import "css/Base.scss";
import "css/Screens.scss";
import { getTodayBoardRequest } from "store/dashboard/actions";
import "css/Insights.scss";
import { Link } from "react-router-dom";
import { createLoadingSelector } from "store/selectors";
import { TodayBoardLoading } from "./loading/todayboard_loading";

interface IProps {
  router: any;
  loading: boolean;
  dashboard: DataTypes.Dashboard | null;
  getTodayBoardRequest: () => void;
}

interface IState {}

class TodayBoard extends Component<IProps, IState> {
  componentDidMount() {
    this.props.getTodayBoardRequest();
  }

  buildLiveTests = (today: DataTypes.TodayBoard) => {
    const live = [...(today.live || [])];

    return (
      <React.Fragment>
        <label className="text_sm">Live</label>
        {live && live.length > 0 ? (
          <ul>
            {live.splice(0, 3).map((experiment) => (
              <li key={experiment.id}>
                <h6 className="title">
                  <Link to={`/tests/${experiment.id}`} className="m0">
                    {experiment.name}
                  </Link>
                </h6>
              </li>
            ))}
            {today.live && today.live?.length > 3 && (
              <li>
                <Link
                  style={{ fontSize: "13px" }}
                  to="/tests?q[]=test_status=live"
                >
                  see more
                </Link>
              </li>
            )}
          </ul>
        ) : (
          <p>
            No tests are currently live.{" "}
            <Link to="/tests/create">Create a new test?</Link>
          </p>
        )}
      </React.Fragment>
    );
  };

  buildAnalysisTests = (today: DataTypes.TodayBoard) => {
    const analysis = [...(today.analysis || [])];

    return (
      <React.Fragment>
        <label className="text_sm">Analysis</label>
        {today.analysis && today.analysis.length > 0 ? (
          <ul>
            {analysis.splice(0, 3).map((experiment) => (
              <li key={experiment.id}>
                <h6 className="title">
                  <Link to={`/tests/${experiment.id}`} className="m0">
                    {experiment.name}
                  </Link>
                </h6>
              </li>
            ))}

            {today.analysis && today.analysis?.length > 3 && (
              <li>
                <Link
                  style={{ fontSize: "13px" }}
                  to="/tests?q[]=test_status=analysis"
                >
                  see more
                </Link>
              </li>
            )}
          </ul>
        ) : (
          <p>
            All experiments have been completed.{" "}
            <Link to="/insights">Uncover insights?</Link>
          </p>
        )}
      </React.Fragment>
    );
  };

  buildPreLauchTests = (today: DataTypes.TodayBoard) => {
    const preLaunch = [...(today.pre_launch || [])];

    return (
      <React.Fragment>
        <label className="text_sm">Pre-Launch</label>
        {today.pre_launch && today.pre_launch.length > 0 ? (
          <ul>
            {preLaunch.splice(0, 3).map((experiment) => (
              <li key={experiment.id}>
                <h6 className="title">
                  <Link to={`/tests/${experiment.id}`} className="m0">
                    {experiment.name}
                  </Link>
                </h6>
              </li>
            ))}

            {today.pre_launch && today.pre_launch?.length > 3 && (
              <li>
                <Link
                  style={{ fontSize: "13px" }}
                  to="/tests?q[]=test_status=pre_launch"
                >
                  see more
                </Link>
              </li>
            )}
          </ul>
        ) : (
          <p>
            No tests on deck. <Link to="/ideas/create">Create test ideas?</Link>
          </p>
        )}
      </React.Fragment>
    );
  };

  displayMonth = (monthIndex: number) => {
    const MONTH = {
      January: 0,
      February: 1,
      March: 2,
      April: 3,
      May: 4,
      June: 5,
      July: 6,
      August: 7,
      September: 8,
      October: 9,
      November: 10,
      December: 11,
    };

    return Object.keys(MONTH)[monthIndex];
  };

  buildDate = () => {
    const today = new Date();

    return `${this.displayMonth(
      today.getMonth()
    )} ${today.getDate()}, ${today.getFullYear()}`;
  };

  render() {
    if (this.props.loading) {
      return <TodayBoardLoading />;
    }

    const { dashboard } = this.props;

    if (!dashboard || !dashboard.today) {
      return null;
    }

    const { today } = dashboard;

    return (
      <div className="trait">
        <label style={{ fontSize: "18px" }}>
          Today’s Update <small>{this.buildDate()}</small>
        </label>
        <div className="shadow body">
          {this.buildLiveTests(today)}
          {this.buildAnalysisTests(today)}
          {this.buildPreLauchTests(today)}
        </div>
      </div>
    );
  }
}

const loadingSelector = createLoadingSelector(["@@dashboard/GET_TODAY_BOARD"]);

const mapStateToProps = ({ router, dashboard, app }: ApplicationState) => ({
  router,
  loading: loadingSelector(app.requests),
  dashboard,
});

const mapDispatchToProps = {
  getTodayBoardRequest,
};

const connectedPage = connect(mapStateToProps, mapDispatchToProps)(TodayBoard);

export { connectedPage as TodayBoard };
