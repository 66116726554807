import { action } from 'typesafe-actions';
import { Dispatch } from 'redux';
import { ActionTypes } from './types';

interface RequestError {
  statusCode?: number | undefined;
  message: any;
}

export const getUserAccountsRequest = () => (dispatch: Dispatch) => dispatch(action(ActionTypes.GET_USER_ACCOUNTS_REQUEST, {}));
export const getUserAccountSuccess = (data: any) => action(ActionTypes.GET_USER_ACCOUNTS_SUCCESS, data);
export const getUserAccountError = (error: RequestError) => action(ActionTypes.GET_USER_ACCOUNTS_FAILURE, error);
