import { action } from "typesafe-actions";
import { Dispatch } from "redux";
import { ActionTypes } from "./types";
import * as DataTypes from "../types";
import { OrderDirection } from "ui/order_icon";

interface RequestError {
  statusCode?: number | undefined;
  message: any;
}

// Init Idea Create
export const initUxrCreateRequest = () => (dispatch: Dispatch) =>
  dispatch(action(ActionTypes.INIT_UXR_CREATE_REQUEST, {}));
export const initUxrCreateSuccess = () =>
  action(ActionTypes.INIT_UXR_CREATE_SUCCESS, {});
export const initUxrCreateError = (error: RequestError) =>
  action(ActionTypes.INIT_UXR_CREATE_FAILURE, error);

// Create Idea
export const createUxrRequest = (body: any) => (dispatch: Dispatch) =>
  dispatch(action(ActionTypes.CREATE_UXR_REQUEST, { body }));
export const createUxrSuccess = (
  response: DataTypes.ApiRequest<DataTypes.Uxr>
) => action(ActionTypes.CREATE_UXR_SUCCESS, { response });
export const createUxrError = (error: RequestError) =>
  action(ActionTypes.CREATE_UXR_FAILURE, error);

// Update Idea by id
export const updateUxrRequest =
  (id: string, body: any) => (dispatch: Dispatch) =>
    dispatch(action(ActionTypes.UPDATE_UXR_REQUEST, { id, body }));
export const updateUxrSuccess = (
  response: DataTypes.ApiRequest<DataTypes.Idea>
) => action(ActionTypes.UPDATE_UXR_SUCCESS, { response });
export const updateUxrError = (error: RequestError) =>
  action(ActionTypes.UPDATE_UXR_FAILURE, error);

// Update Idea by id
export const archiveIdeaRequest =
  (id: string, body: any) => (dispatch: Dispatch) =>
    dispatch(action(ActionTypes.ARCHIVE_UXR_REQUEST, { id, body }));
export const archiveIdeaSuccess = (
  response: DataTypes.ApiRequest<DataTypes.Idea>
) => action(ActionTypes.ARCHIVE_UXR_SUCCESS, { response });
export const archiveIdeaError = (error: RequestError) =>
  action(ActionTypes.ARCHIVE_UXR_FAILURE, error);

// Delete Idea by id
export const deleteUxrRequest = (id: string) => (dispatch: Dispatch) =>
  dispatch(action(ActionTypes.DELETE_UXR_REQUEST, { id }));
export const deleteUxrSuccess = (
  response: DataTypes.ApiRequest<DataTypes.Uxr>
) => action(ActionTypes.DELETE_UXR_SUCCESS, { response });
export const deleteUxrError = (error: RequestError) =>
  action(ActionTypes.DELETE_UXR_FAILURE, error);

// Get Ideas
export const getUxrsRequest = (
  options?: DataTypes.ApiListOptions,
  scrollOpts?: { append: boolean }
) => action(ActionTypes.GET_UXRS_REQUEST, { options, scrollOpts });
export const getUxrsSuccess = (
  response: DataTypes.ApiList<DataTypes.Idea>,
  append?: boolean
) => action(ActionTypes.GET_UXRS_SUCCESS, { response, append });
export const getUxrsError = (error: RequestError) =>
  action(ActionTypes.GET_UXRS_FAILURE, error);

// Show Idea
export const showUxrRequest =
  (id: string, callback?: Function) => (dispatch: Dispatch) =>
    dispatch(action(ActionTypes.SHOW_UXR_REQUEST, { id, callback }));
export const showUxrSuccess = (response: DataTypes.Uxr) =>
  action(ActionTypes.SHOW_UXR_SUCCESS, { response });
export const showUxrError = (error: RequestError) =>
  action(ActionTypes.SHOW_UXR_FAILURE, error);

export const clearIdeaRequest = () => (dispatch: Dispatch) =>
  dispatch(action(ActionTypes.CLEAR_UXR_REQUEST, {}));
export const clearIdeaSuccess = () => action(ActionTypes.CLEAR_UXR_SUCCESS, {});
export const clearIdeaError = (error: RequestError) =>
  action(ActionTypes.CLEAR_UXR_FAILURE, error);

export const reorderUxrRequest =
  (id: string, position: number, currentOrder: OrderDirection) =>
  (dispatch: Dispatch) =>
    dispatch(
      action(ActionTypes.REORDER_REQUEST, { id, position, currentOrder })
    );
export const reorderUxrSuccess = (
  response: DataTypes.ApiList<DataTypes.Uxr>,
  currentOrder: OrderDirection
) => action(ActionTypes.REORDER_SUCCESS, { response, currentOrder });
export const reorderUxrError = (error: RequestError) =>
  action(ActionTypes.REORDER_FAILURE);

export const addImageRequest =
  (uxrId: string, file: File) => (dispatch: Dispatch) =>
    dispatch(
      action(ActionTypes.ADD_IMAGE_REQUEST, {
        uxrId,
        file,
      })
    );
export const addDocumentSuccess = (
  response: DataTypes.ApiRequest<DataTypes.Uxr>
) => action(ActionTypes.ADD_DOCUMENT_SUCCESS, { response });
export const addDocumentError = (error: RequestError) =>
  action(ActionTypes.ADD_DOCUMENT_FAILURE, error);

export const addDocumentRequest =
  (uxrId: string, file: File) => (dispatch: Dispatch) =>
    dispatch(
      action(ActionTypes.ADD_DOCUMENT_REQUEST, {
        uxrId,
        file,
      })
    );
export const addImageSuccess = (
  response: DataTypes.ApiRequest<DataTypes.Uxr>
) => action(ActionTypes.ADD_IMAGE_SUCCESS, { response });
export const addImageError = (error: RequestError) =>
  action(ActionTypes.ADD_IMAGE_FAILURE, error);

export const deleteUploadRequest =
  (uxrId: string, uploadId: string) => (dispatch: Dispatch) =>
    dispatch(action(ActionTypes.DELETE_UPLOAD_REQUEST, { uxrId, uploadId }));
export const deleteUploadSuccess = (
  response: DataTypes.ApiRequest<DataTypes.Experiment>
) => action(ActionTypes.DELETE_UPLOAD_SUCCESS, { response });
export const deleteUploadError = (error: RequestError) =>
  action(ActionTypes.DELETE_UPLOAD_FAILURE, error);

export const deleteDocumentRequest =
  (uxrId: string, uploadId: string) => (dispatch: Dispatch) =>
    dispatch(action(ActionTypes.DELETE_DOCUMENT_REQUEST, { uxrId, uploadId }));
export const deleteDocumentSuccess = (
  response: DataTypes.ApiRequest<DataTypes.Experiment>
) => action(ActionTypes.DELETE_DOCUMENT_SUCCESS, { response });
export const deleteDocumentError = (error: RequestError) =>
  action(ActionTypes.DELETE_DOCUMENT_FAILURE, error);

export const updateUploadRequest =
  (uxrId: string, uploadId: string, body: any) => (dispatch: Dispatch) =>
    dispatch(
      action(ActionTypes.UPDATE_UPLOAD_REQUEST, {
        uxrId,
        uploadId,
        body,
      })
    );
export const updateUploadSuccess = (
  response: DataTypes.ApiRequest<DataTypes.Experiment>
) => action(ActionTypes.UPDATE_UPLOAD_SUCCESS, { response });
export const updateUploadError = (error: RequestError) =>
  action(ActionTypes.UPDATE_UPLOAD_FAILURE, error);

export const deleteUploadCommentRequest =
  (uxrId: string, uploadId: string, commentId: string) =>
  (dispatch: Dispatch) =>
    dispatch(
      action(ActionTypes.DELETE_UPLOAD_COMMENT_REQUEST, {
        uxrId,
        uploadId,
        commentId,
      })
    );
export const deleteUploadCommentSuccess = (
  response: DataTypes.ApiRequest<DataTypes.Experiment>
) => action(ActionTypes.DELETE_UPLOAD_COMMENT_SUCCESS, { response });
export const deleteUploadCommentError = (error: RequestError) =>
  action(ActionTypes.DELETE_UPLOAD_COMMENT_FAILURE, error);

export const addUploadCommentRequest =
  (uxrId: string, uploadId: string, body: any) => (dispatch: Dispatch) =>
    dispatch(
      action(ActionTypes.ADD_UPLOAD_COMMENT_REQUEST, {
        uxrId,
        uploadId,
        body,
      })
    );
export const addUploadCommentSuccess = (
  response: DataTypes.ApiRequest<DataTypes.Experiment>
) => action(ActionTypes.ADD_UPLOAD_COMMENT_SUCCESS, { response });
export const addUploadCommentError = (error: RequestError) =>
  action(ActionTypes.ADD_UPLOAD_COMMENT_FAILURE, error);

export const addAreaOfFocusRequest =
  (uxrId: string, callback: any) => (dispatch: Dispatch) =>
    dispatch(
      action(ActionTypes.ADD_AREA_OF_FOCUS_REQUEST, { uxrId, callback })
    );
export const addAreaOfFocusSuccess = (
  response: DataTypes.ApiRequest<DataTypes.Uxr>
) => action(ActionTypes.ADD_AREA_OF_FOCUS_SUCCESS, { response });
export const addAreaOfFocusError = (error: RequestError) =>
  action(ActionTypes.ADD_AREA_OF_FOCUS_FAILURE, error);

export const updateAreaOfFocusRequest =
  (uxrId: string, areaId: string, body: any) => (dispatch: Dispatch) =>
    dispatch(
      action(ActionTypes.UPDATE_AREA_OF_FOCUS_REQUEST, { uxrId, areaId, body })
    );
export const updateAreaOfFocusSuccess = (
  response: DataTypes.ApiRequest<DataTypes.Uxr>
) => action(ActionTypes.UPDATE_AREA_OF_FOCUS_SUCCESS, { response });
export const updateAreaOfFocusError = (error: RequestError) =>
  action(ActionTypes.UPDATE_AREA_OF_FOCUS_FAILURE, error);

export const deleteAreaOfFocusRequest =
  (uxrId: string, areaId: string) => (dispatch: Dispatch) =>
    dispatch(
      action(ActionTypes.DELETE_AREA_OF_FOCUS_REQUEST, { uxrId, areaId })
    );
export const deleteAreaOfFocusSuccess = (
  response: DataTypes.ApiRequest<DataTypes.Uxr>
) => action(ActionTypes.DELETE_AREA_OF_FOCUS_SUCCESS, { response });
export const deleteAreaOfFocusError = (error: RequestError) =>
  action(ActionTypes.DELETE_AREA_OF_FOCUS_FAILURE, error);

export const addSupportingDataRequest =
  (uxrId: string, type: string, callback: any) => (dispatch: Dispatch) =>
    dispatch(
      action(ActionTypes.ADD_SUPPORTING_DATA_REQUEST, { uxrId, type, callback })
    );
export const addSupportingDataSuccess = (
  response: DataTypes.ApiRequest<DataTypes.Uxr>
) => action(ActionTypes.ADD_SUPPORTING_DATA_SUCCESS, { response });
export const addSupportingDataError = (error: RequestError) =>
  action(ActionTypes.ADD_SUPPORTING_DATA_FAILURE, error);

export const updateSupportingDataRequest =
  (uxrId: string, dataId: string, body: any) => (dispatch: Dispatch) =>
    dispatch(
      action(ActionTypes.UPDATE_SUPPORTING_DATA_REQUEST, {
        uxrId,
        dataId,
        body,
      })
    );
export const updateSupportingDataSuccess = (
  response: DataTypes.ApiRequest<DataTypes.Uxr>
) => action(ActionTypes.UPDATE_SUPPORTING_DATA_SUCCESS, { response });
export const updateSupportingDataError = (error: RequestError) =>
  action(ActionTypes.UPDATE_SUPPORTING_DATA_FAILURE, error);

export const deleteSupportingDataRequest =
  (uxrId: string, dataId: string) => (dispatch: Dispatch) =>
    dispatch(
      action(ActionTypes.DELETE_SUPPORTING_DATA_REQUEST, { uxrId, dataId })
    );
export const deleteSupportingDataSuccess = (
  response: DataTypes.ApiRequest<DataTypes.Uxr>
) => action(ActionTypes.DELETE_SUPPORTING_DATA_SUCCESS, { response });
export const deleteSupportingDataError = (error: RequestError) =>
  action(ActionTypes.DELETE_SUPPORTING_DATA_FAILURE, error);

export const addSupportingDataImageRequest =
  (uxrId: string, dataId: string, file: File) => (dispatch: Dispatch) =>
    dispatch(
      action(ActionTypes.ADD_SUPPORTING_DATA_IMAGE_REQUEST, {
        uxrId,
        dataId,
        file,
      })
    );
export const addSupportingDataImageSuccess = (
  response: DataTypes.ApiRequest<DataTypes.Uxr>
) => action(ActionTypes.ADD_SUPPORTING_DATA_IMAGE_SUCCESS, { response });
export const addSupportingDataImageError = (error: RequestError) =>
  action(ActionTypes.ADD_SUPPORTING_DATA_IMAGE_FAILURE, error);

export const deleteSupportingDataUploadRequest =
  (uxrId: string, dataId: string, uploadId: string) => (dispatch: Dispatch) =>
    dispatch(
      action(ActionTypes.DELETE_SUPPORTING_DATA_UPLOAD_REQUEST, {
        uxrId,
        uploadId,
        dataId,
      })
    );
export const deleteSupportingDataUploadSuccess = (
  response: DataTypes.ApiRequest<DataTypes.Experiment>
) => action(ActionTypes.DELETE_SUPPORTING_DATA_UPLOAD_SUCCESS, { response });
export const deleteSupportingDataUploadError = (error: RequestError) =>
  action(ActionTypes.DELETE_SUPPORTING_DATA_UPLOAD_FAILURE, error);

// Link Insights
export const onLinkInsightsRequest =
  (id: string, insights: Array<DataTypes.Insight>) => (dispatch: Dispatch) =>
    dispatch(action(ActionTypes.LINK_INSIGHTS_REQUEST, { id, insights }));
export const onLinkInsightsSuccess = (
  response: DataTypes.ApiRequest<DataTypes.Experiment>
) => action(ActionTypes.LINK_INSIGHTS_SUCCESS, { response });
export const onLinkInsightsError = (error: RequestError) =>
  action(ActionTypes.LINK_INSIGHTS_FAILURE, error);

// Un Link Insights
export const onUnLinkInsightsRequest =
  (id: string, insightId: string) => (dispatch: Dispatch) =>
    dispatch(action(ActionTypes.UNLINK_INSIGHTS_REQUEST, { id, insightId }));
export const onUnLinkInsightsSuccess = (
  response: DataTypes.ApiRequest<DataTypes.Experiment>
) => action(ActionTypes.UNLINK_INSIGHTS_SUCCESS, { response });
export const onUnLinkInsightsError = (error: RequestError) =>
  action(ActionTypes.UNLINK_INSIGHTS_FAILURE, error);

// Get share by id
export const getShareRequest = (id: string) => (dispatch: Dispatch) =>
  dispatch(action(ActionTypes.GET_SHARE_REQUEST, { id }));
export const getShareSuccess = (
  response: DataTypes.ApiRequest<DataTypes.Experiment>
) => action(ActionTypes.GET_SHARE_SUCCESS, { response });
export const getShareError = (error: RequestError) =>
  action(ActionTypes.GET_SHARE_FAILURE, error);

// Update Case Study by experiment id
export const updateShareRequest =
  (id: string, body: any, disableHideModal?: boolean) => (dispatch: Dispatch) =>
    dispatch(
      action(ActionTypes.UPDATE_SHARE_REQUEST, {
        id,
        body,
        disableHideModal,
      })
    );
export const updateShareSuccess = (
  response: DataTypes.ApiRequest<DataTypes.Experiment>
) => action(ActionTypes.UPDATE_SHARE_SUCCESS, { response });
export const updateShareError = (error: RequestError) =>
  action(ActionTypes.UPDATE_SHARE_FAILURE, error);

export const getShareShareRequest =
  (slug: string, uuid: string, password?: string) => (dispatch: Dispatch) =>
    dispatch(
      action(ActionTypes.GET_SHARE_SHARE_REQUEST, { slug, uuid, password })
    );
export const getShareShareSuccess = (
  response: DataTypes.ApiRequest<DataTypes.Experiment>
) => action(ActionTypes.GET_SHARE_SHARE_SUCCESS, { response });
export const getShareShareError = (error: RequestError) =>
  action(ActionTypes.GET_SHARE_SHARE_FAILURE, error);

export const updateSupportingDataUploadRequest =
  (uxrId: string, supportingDataId: string, uploadId: string, body: any) =>
  (dispatch: Dispatch) =>
    dispatch(
      action(ActionTypes.UPDATE_SUPPORTING_DATA_UPLOAD_REQUEST, {
        uxrId,
        supportingDataId,
        uploadId,
        body,
      })
    );
export const updateSupportingDataUploadSuccess = (
  response: DataTypes.ApiRequest<DataTypes.Experiment>
) => action(ActionTypes.UPDATE_SUPPORTING_DATA_UPLOAD_SUCCESS, { response });
export const updateSupportingDataUploadError = (error: RequestError) =>
  action(ActionTypes.UPDATE_SUPPORTING_DATA_UPLOAD_FAILURE, error);

export const deleteSupportingDataUploadCommentRequest =
  (
    uxrId: string,
    supportingDataId: string,
    uploadId: string,
    commentId: string
  ) =>
  (dispatch: Dispatch) =>
    dispatch(
      action(ActionTypes.DELETE_SUPPORTING_DATA_UPLOAD_COMMENT_REQUEST, {
        uxrId,
        supportingDataId,
        uploadId,
        commentId,
      })
    );
export const deleteSupportingDataUploadCommentSuccess = (
  response: DataTypes.ApiRequest<DataTypes.Experiment>
) =>
  action(ActionTypes.DELETE_SUPPORTING_DATA_UPLOAD_COMMENT_SUCCESS, {
    response,
  });
export const deleteSupportingDataUploadCommentError = (error: RequestError) =>
  action(ActionTypes.DELETE_SUPPORTING_DATA_UPLOAD_COMMENT_FAILURE, error);

export const addSupportingDataUploadCommentRequest =
  (uxrId: string, supportingDataId: string, uploadId: string, body: any) =>
  (dispatch: Dispatch) =>
    dispatch(
      action(ActionTypes.ADD_SUPPORTING_DATA_UPLOAD_COMMENT_REQUEST, {
        uxrId,
        supportingDataId,
        uploadId,
        body,
      })
    );
export const addSupportingDataUploadCommentSuccess = (
  response: DataTypes.ApiRequest<DataTypes.Experiment>
) =>
  action(ActionTypes.ADD_SUPPORTING_DATA_UPLOAD_COMMENT_SUCCESS, { response });
export const addSupportingDataUploadCommentError = (error: RequestError) =>
  action(ActionTypes.ADD_SUPPORTING_DATA_UPLOAD_COMMENT_FAILURE, error);

export const uploadInsightsRequest =
  (uxrId: string, file: any) => (dispatch: Dispatch) =>
    dispatch(
      action(ActionTypes.UPLOAD_INSIGHTS_REQUEST, { uxrId, file })
    );
export const uploadInsightsSuccess = (
  response: DataTypes.ApiRequest<DataTypes.Experiment>
) => action(ActionTypes.UPLOAD_INSIGHTS_SUCCESS, { response });
export const uploadInsightsError = (error: RequestError) =>
  action(ActionTypes.UPLOAD_INSIGHTS_FAILURE, error);
