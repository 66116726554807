import React, { Component } from "react";
import * as DataTypes from "store/types";

const EXPORT_AVAILABLE_FIELDS = [
  { name: "Name", value: "name" },
  { name: "Problem", value: "problem" },
  { name: "Proposed Idea", value: "proposed" },
  { name: "Hypothesis", value: "hypothesis" },
  { name: "Theme(s)", value: "theme_names" },
  { name: "Audience(s)", value: "audience_names" },
  { name: "Device(s)", value: "device_names" },
  { name: "Page(s)", value: "page_names" },
  { name: "Brand(s)", value: "brand_names" },
  { name: "Team(s)", value: "team_names" },
  { name: "Channel(s)", value: "channel_names" },
  { name: "Pains & Gains", value: "tactics_names" },
  { name: "Created on", value: "created_at" },
  { name: "Author", value: "author_names" },
  { name: "Contributor", value: "contributor_names" },
  { name: "Last Updated on", value: "updated_at" },
  { name: "Level of Effort Score", value: "level_of_effort" },
  { name: "Business Impact Score  ", value: "business_impact" },
  { name: "Win Likelihood Score", value: "win_likelyhood" },
  { name: "Value of Learning Score", value: "value_of_learning" },
  { name: "Overall Prioritization Score", value: "score" },
  { name: "Status", value: "status" },
  { name: "Star", value: "star" },
  { name: "Archived", value: "archived" },
  {
    name: "Idea Board Name",
    value: "idea_board_name",
  },
  {
    name: "Idea Board ID (created by illuminate)",
    value: "idea_board_id",
  },
  { name: "Idea ID (created by illuminate)", value: "id" },
];

interface IProps {
  board: DataTypes.IdeaBoard;
  saving: boolean;
  bbMethodEnable: boolean;
  onSubmit(fields: string[]): void;
}

interface IState {
  selectedFields: string[];
  availableFields: { name: string; value: string }[];
}

class ExportIdeas extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      selectedFields: EXPORT_AVAILABLE_FIELDS.map((field) => field.value),
      availableFields: [
        { name: "Name", value: "name" },
        { name: "Problem", value: "problem" },
        { name: "Proposed Idea", value: "proposed" },
        {
          name: this.props.bbMethodEnable ? "Whypothesis®" : "Hypothesis",
          value: "hypothesis",
        },
        { name: "Theme(s)", value: "theme_names" },
        { name: "Audience(s)", value: "audience_names" },
        { name: "Device(s)", value: "device_names" },
        { name: "Page(s)", value: "page_names" },
        { name: "Brand(s)", value: "brand_names" },
        { name: "Team(s)", value: "team_names" },
        { name: "Channel(s)", value: "channel_names" },
        { name: "Pains & Gains", value: "tactics_names" },
        { name: "Created on", value: "created_at" },
        { name: "Author", value: "author_names" },
        { name: "Contributor", value: "contributor_names" },
        { name: "Last Updated on", value: "updated_at" },
        { name: "Level of Effort Score", value: "level_of_effort" },
        { name: "Business Impact Score  ", value: "business_impact" },
        { name: "Win Likelihood Score", value: "win_likelyhood" },
        { name: "Value of Learning Score", value: "value_of_learning" },
        { name: "Overall Prioritization Score", value: "score" },
        { name: "Status", value: "status" },
        { name: "Star", value: "star" },
        { name: "Archived", value: "archived" },
        {
          name: "Idea Board Name",
          value: "idea_board_name",
        },
        {
          name: "Idea Board ID (created by illuminate)",
          value: "idea_board_id",
        },
        { name: "Idea ID (created by illuminate)", value: "id" },
      ],
    };
  }

  private toggleSelection(field: string) {
    if (this.state.selectedFields.lastIndexOf(field) !== -1) {
      this.setState({
        selectedFields: this.state.selectedFields.filter((f) => f !== field),
      });
      return;
    }

    this.setState({
      selectedFields: [...this.state.selectedFields, field],
    });
  }

  render() {
    const { onSubmit, saving } = this.props;

    return (
      <React.Fragment>
        <div className="header">
          <h3>Export Your Ideas</h3>
          <p></p>

          <p className="mt-2">
            The idea list displayed on the previous screen will be exported. If
            you would like to export a refined list of ideas, please go back to
            adjust your filters before proceeding.
          </p>

          <p className="mt-2">Select the fields you'd like included in your export. Then, your export will be emailed to you.</p>

          <ul
            style={{ display: "grid", gridTemplateColumns: "repeat(3, 1fr)" }}
          >
            {this.state.availableFields.map((field, idx) => {
              return (
                <li
                  key={["export_idea_field_", field.value].join("_")}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    minHeight: "48px",
                  }}
                >
                  <label className="CheckBox">
                    <input
                      type="checkbox"
                      defaultChecked={
                        this.state.selectedFields.lastIndexOf(field.value) !==
                        -1
                      }
                      onChange={(e) => this.toggleSelection(field.value)}
                    />
                    <span className="checkmark"></span>
                  </label>
                  <p style={{ marginBottom: 0 }}>{field.name}</p>
                </li>
              );
            })}
          </ul>
        </div>
        <div className="cntrl-bar">
          {saving === true ? (
            <button className="btn btn-export" type="submit" disabled={true}>
              <i className="fas fa-circle-notch fa-spin" />
            </button>
          ) : (
            <button
              className="btn btn-primary"
              onClick={() => onSubmit(this.state.selectedFields)}
            >
              Export
            </button>
          )}
        </div>
      </React.Fragment>
    );
  }
}

export { ExportIdeas };
