import { ApiClient } from "./client/api_client";

class Dashboard {
  static async insights() {
    return await ApiClient.get("/v1/dashboard/insight");
  }

  static async leaderBoard() {
    return await ApiClient.get("/v1/dashboard/leader_board");
  }

  static async today() {
    return await ApiClient.get("/v1/dashboard/today");
  }

  static async didYouKnow() {
    return await ApiClient.get("/v1/dashboard/did_you_know");
  }
}

export { Dashboard };
