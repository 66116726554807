import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import * as API from "../../api";
import * as AppTypes from "../app/types";
import * as Actions from "./actions";
import { ActionTypes } from "./types";

function* getSettingsOptions(action: any) {
  try {
    yield all([
      put(Actions.getSettingsOptionsAvgExperimentsPerMonthRequest()),
      put(Actions.getSettingsOptionsCoeRequest()),
      put(Actions.getSettingsOptionsCompanySizeRequest()),
      put(Actions.getSettingsOptionsCompanyExperimentsWinPerMonthRequest()),
      put(Actions.getSettingsOptionsIndustryhRequest()),
      put(Actions.getSettingsOptionsKindOfTestRequest()),
      put(Actions.getSettingsOptionsKindOfToolRequest()),
      put(Actions.getSettingsOptionsPrimaryAnalyticsToolRequest()),
      put(Actions.getSettingsOptionsPrimaryTestingToolRequest()),
      put(Actions.getSettingsOptionsProgramTypeRequest()),
      put(Actions.getSettingsOptionsTestingChallengRequest()),
      put(Actions.getSettingsOptionsTestingProgramReachRequest()),
    ]);

    yield put(Actions.getSettingsOptionsSuccess());
  } catch (error: any) {
    yield put({
      type: AppTypes.App.ALERT_SHOW,
      error: true,
      content: "something went wrong",
    });
    yield put(Actions.getSettingsOptionsError(error));
  }
}

function* getSettingsOptionsAvgExperimentsPerMonth(action: any) {
  try {
    // @ts-ignore
    const res = yield call(API.ProfileSettings.avgExpPerMonth);

    yield put(
      Actions.getSettingsOptionsAvgExperimentsPerMonthSuccess(res.body)
    );
  } catch (error: any) {
    yield put(Actions.getSettingsOptionsAvgExperimentsPerMonthError(error));
    yield put({
      type: AppTypes.App.ALERT_SHOW,
      error: true,
      content: "something went wrong",
    });
  }
}

function* getSettingsOptionsCoe(action: any) {
  try {
    // @ts-ignore
    const res = yield call(API.ProfileSettings.coe);

    yield put(Actions.getSettingsOptionsCoeSuccess(res.body));
  } catch (error: any) {
    yield put({
      type: AppTypes.App.ALERT_SHOW,
      error: true,
      content: "something went wrong",
    });
    yield put(Actions.getSettingsOptionsCoeError(error));
  }
}

function* getSettingsOptionsCompanySize(action: any) {
  try {
    // @ts-ignore
    const res = yield call(API.ProfileSettings.companySize);

    yield put(Actions.getSettingsOptionsCompanySizeSuccess(res.body));
  } catch (error: any) {
    yield put({
      type: AppTypes.App.ALERT_SHOW,
      error: true,
      content: "something went wrong",
    });
    yield put(Actions.getSettingsOptionsCompanySizeError(error));
  }
}

function* getSettingsOptionsCompanyExperimentsWinPerMonth(action: any) {
  try {
    // @ts-ignore
    const res = yield call(API.ProfileSettings.experimentsWinPerMonth);

    yield put(
      Actions.getSettingsOptionsCompanyExperimentsWinPerMonthSuccess(res.body)
    );
  } catch (error: any) {
    yield put({
      type: AppTypes.App.ALERT_SHOW,
      error: true,
      content: "something went wrong",
    });

    yield put(
      Actions.getSettingsOptionsCompanyExperimentsWinPerMonthError(error)
    );
  }
}

function* getSettingsOptionsIndustry(action: any) {
  try {
    // @ts-ignore
    const res = yield call(API.ProfileSettings.industry);

    yield put(Actions.getSettingsOptionsIndustryhSuccess(res.body));
  } catch (error: any) {
    yield put({
      type: AppTypes.App.ALERT_SHOW,
      error: true,
      content: "something went wrong",
    });
    yield put(Actions.getSettingsOptionsIndustryhError(error));
  }
}

function* getSettingsOptionsKindOfTest(action: any) {
  try {
    // @ts-ignore
    const res = yield call(API.ProfileSettings.kindOfTest);

    yield put(Actions.getSettingsOptionsKindOfTestSuccess(res.body));
  } catch (error: any) {
    yield put({
      type: AppTypes.App.ALERT_SHOW,
      error: true,
      content: "something went wrong",
    });
    yield put(Actions.getSettingsOptionsKindOfTestError(error));
  }
}

function* getSettingsOptionsKindOfTool(action: any) {
  try {
    // @ts-ignore
    const res = yield call(API.ProfileSettings.kindOfTool);

    yield put(Actions.getSettingsOptionsKindOfToolSuccess(res.body));
  } catch (error: any) {
    yield put({
      type: AppTypes.App.ALERT_SHOW,
      error: true,
      content: "something went wrong",
    });
    yield put(Actions.getSettingsOptionsKindOfToolError(error));
  }
}

function* getSettingsOptionsPrimaryAnalyticsTool(action: any) {
  try {
    // @ts-ignore
    const res = yield call(API.ProfileSettings.primaryAnalyticsTool);

    yield put(Actions.getSettingsOptionsPrimaryAnalyticsToolSuccess(res.body));
  } catch (error: any) {
    yield put({
      type: AppTypes.App.ALERT_SHOW,
      error: true,
      content: "something went wrong",
    });
    yield put(Actions.getSettingsOptionsPrimaryAnalyticsToolError(error));
  }
}

function* getSettingsOptionsPrimaryTestingTool(action: any) {
  try {
    // @ts-ignore
    const res = yield call(API.ProfileSettings.primaryTestingTool);

    yield put(Actions.getSettingsOptionsPrimaryTestingToolSuccess(res.body));
  } catch (error: any) {
    yield put({
      type: AppTypes.App.ALERT_SHOW,
      error: true,
      content: "something went wrong",
    });

    yield put(Actions.getSettingsOptionsPrimaryTestingToolError(error));
  }
}

function* getSettingsOptionsProgramType(action: any) {
  try {
    // @ts-ignore
    const res = yield call(API.ProfileSettings.programType);

    yield put(Actions.getSettingsOptionsProgramTypeSuccess(res.body));
  } catch (error: any) {
    yield put({
      type: AppTypes.App.ALERT_SHOW,
      error: true,
      content: "something went wrong",
    });

    yield put(Actions.getSettingsOptionsProgramTypeError(error));
  }
}

function* getSettingsOptionsTestingChallenge(action: any) {
  try {
    // @ts-ignore
    const res = yield call(API.ProfileSettings.testingChallenge);

    yield put(Actions.getSettingsOptionsTestingChallengSuccess(res.body));
  } catch (error: any) {
    yield put({
      type: AppTypes.App.ALERT_SHOW,
      error: true,
      content: "something went wrong",
    });

    yield put(Actions.getSettingsOptionsTestingChallengError(error));
  }
}

function* getSettingsOptionsTestingProgramReach(action: any) {
  try {
    // @ts-ignore
    const res = yield call(API.ProfileSettings.testingProgramReach);

    yield put(Actions.getSettingsOptionsTestingProgramReachSuccess(res.body));
  } catch (error: any) {
    yield put({
      type: AppTypes.App.ALERT_SHOW,
      error: true,
      content: "something went wrong",
    });
    yield put(Actions.getSettingsOptionsTestingProgramReachError(error));
  }
}

function* watchGetSettingsOptions() {
  yield takeEvery(ActionTypes.GET_SETTINGS_OPTIONS_REQUEST, getSettingsOptions);
}
function* watchGetSettingsOptionsAvgExperimentsPerMonth() {
  yield takeEvery(
    ActionTypes.GET_SETTINGS_OPTIONS_AVG_EXPERIMENTS_PER_MONTH_REQUEST,
    getSettingsOptionsAvgExperimentsPerMonth
  );
}
function* watchGetSettingsOptionsCoe() {
  yield takeEvery(
    ActionTypes.GET_SETTINGS_OPTIONS_COE_REQUEST,
    getSettingsOptionsCoe
  );
}
function* watchGetSettingsOptionsCompanySize() {
  yield takeEvery(
    ActionTypes.GET_SETTINGS_OPTIONS_COMPANY_SIZE_REQUEST,
    getSettingsOptionsCompanySize
  );
}
function* watchGetSettingsOptionsCompanyExperimentsWinPerMonth() {
  yield takeEvery(
    ActionTypes.GET_SETTINGS_OPTIONS_COMPANY_EXPERIMENTS_WIN_PER_MONTH_REQUEST,
    getSettingsOptionsCompanyExperimentsWinPerMonth
  );
}
function* watchGetSettingsOptionsIndustry() {
  yield takeEvery(
    ActionTypes.GET_SETTINGS_OPTIONS_INDUSTRY_REQUEST,
    getSettingsOptionsIndustry
  );
}
function* watchGetSettingsOptionsKindOfTest() {
  yield takeEvery(
    ActionTypes.GET_SETTINGS_OPTIONS_KIND_OF_TEST_REQUEST,
    getSettingsOptionsKindOfTest
  );
}
function* watchGetSettingsOptionsKindOfTool() {
  yield takeEvery(
    ActionTypes.GET_SETTINGS_OPTIONS_KIND_OF_TOOL_REQUEST,
    getSettingsOptionsKindOfTool
  );
}
function* watchGetSettingsOptionsPrimaryAnalyticsTool() {
  yield takeEvery(
    ActionTypes.GET_SETTINGS_OPTIONS_PRIMARY_ANALYTICS_TOOL_REQUEST,
    getSettingsOptionsPrimaryAnalyticsTool
  );
}
function* watchGetSettingsOptionsPrimaryTestingTool() {
  yield takeEvery(
    ActionTypes.GET_SETTINGS_OPTIONS_PRIMARY_TESTING_TOOL_REQUEST,
    getSettingsOptionsPrimaryTestingTool
  );
}
function* watchGetSettingsOptionsProgramType() {
  yield takeEvery(
    ActionTypes.GET_SETTINGS_OPTIONS_PROGRAM_TYPE_REQUEST,
    getSettingsOptionsProgramType
  );
}
function* watchGetSettingsOptionsTestingChalleng() {
  yield takeEvery(
    ActionTypes.GET_SETTINGS_OPTIONS_TESTING_CHALLENGE_REQUEST,
    getSettingsOptionsTestingChallenge
  );
}
function* watchGetSettingsOptionsTestingProgramReach() {
  yield takeEvery(
    ActionTypes.GET_SETTINGS_OPTIONS_TESTING_PROGRAM_REACH_REQUEST,
    getSettingsOptionsTestingProgramReach
  );
}

function* profileSettingsOptionsSaga() {
  yield all([
    fork(watchGetSettingsOptions),
    fork(watchGetSettingsOptionsAvgExperimentsPerMonth),
    fork(watchGetSettingsOptionsCoe),
    fork(watchGetSettingsOptionsCompanySize),
    fork(watchGetSettingsOptionsCompanyExperimentsWinPerMonth),
    fork(watchGetSettingsOptionsIndustry),
    fork(watchGetSettingsOptionsKindOfTest),
    fork(watchGetSettingsOptionsKindOfTool),
    fork(watchGetSettingsOptionsPrimaryAnalyticsTool),
    fork(watchGetSettingsOptionsPrimaryTestingTool),
    fork(watchGetSettingsOptionsProgramType),
    fork(watchGetSettingsOptionsTestingChalleng),
    fork(watchGetSettingsOptionsTestingProgramReach),
  ]);
}

export default profileSettingsOptionsSaga;
