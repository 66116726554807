import { ApiError, ApiSystemError, ApiResponse, ApiGetOptions } from "../types";
import { AuthService } from "utils/auth";
import queryString from "query-string";

export class ApiClient {
  static async get(path: string, options?: ApiGetOptions) {
    try {
      const response = await fetch(
        ApiClient.buildPath(path, options),
        ApiClient.getProps(path)
      );
      return this.handleResponse(response);
    } catch (err) {
      console.error(err);
      throw err;
    }
  }

  static async post(path: string, body: object) {
    try {
      const response = await fetch(
        this.fullPath(path),
        ApiClient.postProps(path, ApiClient.removeKeys(body))
      );

      return this.handleResponse(response);
    } catch (err) {
      console.error(err);
      throw err;
    }
  }

  static async put(path: string, body: object) {
    try {
      const response = await fetch(
        this.fullPath(path),
        ApiClient.putProps(path, ApiClient.removeKeys(body))
      );
      return this.handleResponse(response);
    } catch (err) {
      console.error(err);
      throw err;
    }
  }

  static async putMultipart(path: string, body: FormData) {
    try {
      const response = await fetch(this.fullPath(path), {
        method: "PUT",
        headers: {
          Authorization: "Bearer " + localStorage.getItem("user_token"),
          "Service-Name": "simpatic.io",
          "x-timestamp": new Date().toISOString(),
        },
        mode: "cors",
        cache: "default",
        body,
      });

      return this.handleResponse(response);
    } catch (err) {
      console.error(err);
      throw err;
    }
  }

  static async postMultipart(path: string, body: FormData) {
    try {
      const response = await fetch(this.fullPath(path), {
        method: "POST",
        headers: {
          Authorization: "Bearer " + localStorage.getItem("user_token"),
          "Service-Name": "simpatic.io",
          "x-timestamp": new Date().toISOString(),
        },
        mode: "cors",
        cache: "default",
        body,
      });

      return this.handleResponse(response);
    } catch (err) {
      console.error(err);
      throw err;
    }
  }

  static async delete(path: string) {
    try {
      const response = await fetch(
        this.fullPath(path),
        ApiClient.deleteProps(path)
      );

      return this.handleResponse(response);
    } catch (err) {
      console.error(err);
      throw err;
    }
  }

  static buildPath(path: string, options?: ApiGetOptions) {
    if (options) {
      const q = queryString.stringify(options, {
        encode: true,
        arrayFormat: "bracket",
      });

      return `${this.fullPath(path)}?${q}`;
    }

    return this.fullPath(path);
  }

  static getProps(path: string): RequestInit {
    return {
      method: "GET",
      headers: ApiClient.defaultHeaders(path),
      mode: "cors",
      cache: "default",
    };
  }

  static postProps(path: string, body: Object): RequestInit {
    return {
      method: "POST",
      headers: ApiClient.defaultHeaders(path),
      mode: "cors",
      cache: "default",
      body: JSON.stringify(body),
    };
  }

  static putProps(path: string, body: object): RequestInit {
    return {
      method: "PUT",
      headers: ApiClient.defaultHeaders(path),
      mode: "cors",
      cache: "default",
      body: JSON.stringify(body),
    };
  }

  static deleteProps(path: string): RequestInit {
    return {
      method: "DELETE",
      headers: ApiClient.defaultHeaders(path),
      mode: "cors",
      cache: "default",
    };
  }

  static defaultHeaders(path: string): Headers {
    return new Headers({
      Accept: "application/json",
      Authorization: "Bearer " + localStorage.getItem("user_token"),
      "Service-Name": "simpatic.io",
      "Content-Type": "application/json",
      "x-timestamp": new Date().toISOString(),
    });
  }

  static buildQuery(offset: number, limit: number, options: queryOptions = {}) {
    let query = "?limit=" + limit + "&offset=" + offset;
    if (options.sort) {
      query = query + "&sort=" + options.sort;
    }

    if (options.q) {
      options.q.forEach((item: string) => {
        query = query + "&q[]=" + item;
      });
    }

    if (options.qstr) {
      query = query + "&" + options.qstr;
    }

    return query;
  }

  static fullPath(path: string) {
    return `${process.env.REACT_APP_API_HOST}${path}`;
  }

  static async handleResponse(response: Response) {
    if (!response.ok && response.status < 500) {
      throw new ApiError(
        response.statusText,
        response.status,
        await response.json()
      );
    }

    if (!response.ok && response.status >= 500) {
      throw new ApiSystemError(response.statusText, response.status, {
        errors: [{ system: response.statusText }],
      });
    }

    const hdr_jwt = response.headers.get("x-jwt");
    if (hdr_jwt) {
      AuthService.refeshToken(hdr_jwt);
    }
    return new ApiResponse(response.status, await response.json());
  }

  // Remove read only keys from json post/put requests
  static removeKeys = (obj: any): any => {
    var index;
    for (var prop in obj) {
      if (obj.hasOwnProperty(prop)) {
        switch (typeof obj[prop]) {
          case "string":
            index = REMOVEABLE_KEYS.indexOf(prop);
            if (index > -1) {
              delete obj[prop];
            }
            break;
          case "object":
            index = REMOVEABLE_KEYS.indexOf(prop);
            if (index > -1) {
              delete obj[prop];
            } else {
              ApiClient.removeKeys(obj[prop]);
            }
            break;
        }
      }
    }
    return obj;
  };
}

const REMOVEABLE_KEYS = [
  "created_at",
  "updated_at",
  "deleted_at",
  "content_type",
];

interface queryOptions {
  sort?: string;
  qstr?: string;
  q?: any;
}
