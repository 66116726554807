import styled from "styled-components";

export const TabsContainer = styled.div`
  display: flex;

  .tab-content {
    max-height: 39vh;
  }

  .tab_list {
    padding-right: 1rem;
    border-right: 1px solid rgba(0, 0, 0, 0.1);

    li {
      padding: 0.5rem;

      .step-circle {
        margin-right: 16px;
        display: inline-block;
        height: 30px;
        width: 30px;
        color: #fff;
        text-align: center;
        line-height: 30px;
        border-radius: 50%;
        font-weight: 500;
        font-size: 14px;
        background-color: #959595;
      }

      &.active {
        .step-circle {
          background-color: #2a88f2;
        }
      }
    }
  }
`;

export const TabsContent = styled.div`
  display: none;
  padding-left: 1rem;
  padding-right: 1rem;
  flex-grow: 1;
  width: 60vw;
  max-height: 60vh;

  &.overflow {
    overflow-y: scroll;
  }

  &.active {
    height: 100%;
    display: block;
  }
`;

export const ReportTabContent = styled.div`
  width: 100%;
  display: flex;
  gap: 4rem;
`;

export const MetricTabContent = styled.div`
  max-height: 80%;
  padding-bottom: 4rem;
  overflow: scroll;
  display: flex;
  flex-direction: column;
  gap: 3rem;

  .metricOnReport {
    display: flex;

    p {
      flex-grow: 1;
      margin: auto;
    }
  }
`;

export const ReportList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;

  div {
    display: flex;
    align-items: center;

    input[type="checkbox"] {
      height: 1.5rem;
      width: 1.5rem;
      cursor: pointer;
    }

    label {
      padding-left: 1rem;
      margin: 0;
      cursor: pointer;
    }
  }
`;

export const ExperienceList = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  gap: 0.2rem;

  .report {
    cursor: pointer;
    padding: 1rem;
    overflow-y: scroll;

    &.active {
      background-color: #2a88f2;
      color: white;
    }
  }
`;

export const DeleteMetricContainer = styled.div`
  display: flex;
  justify-content: flex-end;

  button {
    width: 3rem;
    height: 3rem;
    border-radius: 3rem;
    padding: 0;
    color: #b0bac7;

    &:hover {
      background-color: #eaeaea;
      color: #b0bac7;
      box-shadow: 0px 0px transparent;
    }
  }
`;
