import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import React from 'react';
import ReactDOM from 'react-dom';
import { Main } from './main';
import configureStore from './config/store';
import { history } from './utils';
import * as serviceWorker from './serviceWorker';


const initialState = window.initialReduxState;
const store = configureStore(history, initialState);
// <ActionCableProvider url={ process.env.REACT_APP_CABLE_URL }>

ReactDOM.render(
  <Main store={store} history={history} />,
  document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();