import React, { Component } from 'react';
import * as DataTypes from 'store/types';
import { Form, Input, Button, ValidatorTypes } from 'comps/form';
import { AuthService } from 'utils/auth';

interface IProps {
  idea: DataTypes.Idea;
  saving: boolean;
  onSubmit(user: any, idea: DataTypes.Idea): void;
}

class SignInUser extends Component<IProps> {
  private onSubmit = (valid: boolean, dirty: boolean, data: any) => {
    const { idea } = this.props;
    if (valid) {
      AuthService.shareLogIn(data.first_name.value, data.last_name.value, data.email.value);
      this.props.onSubmit({ first_name: data.first_name.value, last_name: data.last_name.value, email: data.email.value }, idea);
    }
  };

  render() {
    const { saving } = this.props;

    return (
      <React.Fragment>
        <div className="header">
          <h3>Tell us about yourself to share your idea.</h3>
          <p>Use the form below to tell us who has come up with this great idea.</p>
        </div>
        <Form
          formFields={{
            first_name: {
              name: 'first_name',
              value: '',
              validator: { type: ValidatorTypes.REQUIRED, messages: { required: 'Please enter your first name' } },
            },
            last_name: {
              name: 'last_name',
              value: '',
              validator: { type: ValidatorTypes.REQUIRED, messages: { required: 'Please enter your last name' } },
            },
            email: {
              name: 'email',
              value: '',
              validator: { type: ValidatorTypes.EMAIL, messages: { type: 'Whoops! Please enter a valid email address.' } },
            },
          }}
          onSubmit={this.onSubmit}
          FormComponent={({ fields: { first_name, last_name, email }, onChange }) => (
            <React.Fragment>
              <div className="body">
                <div className="form-group">
                  <label>first name</label>
                  <Input field={first_name} className="form-control" onChange={(e) => onChange(first_name, e.target.value)} />
                </div>
                <div className="form-group">
                  <label>last name</label>
                  <Input field={last_name} className="form-control" onChange={(e) => onChange(last_name, e.target.value)} />
                </div>
                <div className="form-group">
                  <label>email</label>
                  <Input field={email} className="form-control" onChange={(e) => onChange(email, e.target.value)} />
                </div>
              </div>
              <div className="cntrl-bar">
                <Button className="btn btn-primary" text="save" isLoading={saving} />
              </div>
            </React.Fragment>
          )}
        />
      </React.Fragment>
    );
  }
}

export { SignInUser };
