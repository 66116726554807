import React, { Component } from "react";
import { connect } from "react-redux";
import * as DataTypes from "store/types";
import {
  Form,
  Button,
  RadioButton,
  FormField,
  ValidatorTypes,
  FormFields,
} from "comps/form";
import {
  updateAccountProfileRequest,
  getAccountRequest,
} from "store/account/actions";
import { getSettingsOptionsRequest } from "store/profile_settings_options/actions";

import "css/Setup.scss";
import { ApplicationState } from "store/types";
import { SetupStepsEnum } from "./types";
import { CheckBoxGroup } from "comps/form/checkbox_group";
import { Validator } from "comps/form/validator";
import { values } from "lodash";

const OTHER_OPTION_VALUE = "Other";

interface IProps {
  account: DataTypes.Account;
  profielSettingsOptions: DataTypes.ProfileSettingsOptions | null;
  updateAccountProfileRequest: (body: any, redirectUrl: string) => void;
}

interface IState {
  primaryTestingToolOther: string;
  primaryAnalyticsToolOther: string;
  kindOfToolsOther: string;
  kindOfTestsOther: string;
  testingChallengeOther: string;
  testingProgramReachOther: string;
  fields: FormFields;
}

class AboutYourTools extends Component<IProps, IState> {
  private validator: Validator;

  constructor(props: IProps) {
    super(props);

    this.validator = new Validator();

    const {
      primary_testing_tool,
      primary_analytics_tool,
      kind_of_tools,
      kind_of_tests,
      testing_challenges,
      testing_program_reaches,
    } = this.props.account.profile;
    const { account } = this.props;

    this.state = {
      primaryTestingToolOther: primary_testing_tool?.preset
        ? ""
        : primary_testing_tool?.value || "",
      primaryAnalyticsToolOther: primary_analytics_tool?.preset
        ? ""
        : primary_analytics_tool?.value || "",
      kindOfToolsOther: this.getOtherValue(kind_of_tools),
      kindOfTestsOther: this.getOtherValue(kind_of_tests),
      testingChallengeOther: this.getOtherValue(testing_challenges),
      testingProgramReachOther: this.getOtherValue(testing_program_reaches),

      fields: {
        primaryTestingTool: {
          name: "primaryTestingTool",
          value: primary_testing_tool
            ? primary_testing_tool?.preset
              ? primary_testing_tool?.value
              : OTHER_OPTION_VALUE
            : "",
          validator: {
            type: ValidatorTypes.CUSTOM,
            messages: {
              custom:
                'Please select your primary website testing tool. If you selected "Other," please specify.',
            },
            customValidator: (value: string) =>
              this.singleValueValidator(
                value,
                this.state.primaryTestingToolOther
              ),
          },
        },
        primaryAnalyticsTool: {
          name: "primaryAnalyticsTool",
          value: primary_analytics_tool
            ? primary_analytics_tool?.preset
              ? primary_analytics_tool?.value
              : OTHER_OPTION_VALUE
            : "",
          validator: {
            type: ValidatorTypes.CUSTOM,
            messages: {
              custom:
                'Please select your primary web analytics tool. If you selected "Other," please specify.',
            },
            customValidator: (value: string) =>
              this.singleValueValidator(
                value,
                this.state.primaryAnalyticsToolOther
              ),
          },
        },
        kindOfTools: {
          name: "kindOfTools",
          value: account?.profile.kind_of_tools?.map((tool) =>
            tool.preset ? tool.value : OTHER_OPTION_VALUE
          ),
          validator: {
            type: ValidatorTypes.CUSTOM,
            messages: {
              custom:
                'Please select the tools in your tool stack. If you selected "Other," please specify.',
            },
            customValidator: (value: string[]) =>
              this.multipleValuesValidator(value, this.state.kindOfToolsOther),
          },
        },
        kindOfTests: {
          value: account?.profile.kind_of_tests?.map((kind) =>
            kind.preset ? kind.value : OTHER_OPTION_VALUE
          ),
          name: "kindOfTests",
          validator: {
            type: ValidatorTypes.CUSTOM,
            messages: {
              custom:
                'Please select the types of testing your organization is doing. If you selected "Other," please specify.',
            },
            customValidator: (value: string[]) =>
              this.multipleValuesValidator(value, this.state.kindOfTestsOther),
          },
        },
        testingChallenges: {
          value: account?.profile.testing_challenges?.map((challenge) =>
            challenge.preset ? challenge.value : OTHER_OPTION_VALUE
          ),
          name: "testingChallenges",
          validator: {
            type: ValidatorTypes.CUSTOM,
            messages: {
              custom:
                'Please select your biggest testing challenges. If you selected "Other," please specify.',
            },
            customValidator: (value: string[]) =>
              this.multipleValuesValidator(
                value,
                this.state.testingChallengeOther
              ),
          },
        },
        testingProgramReaches: {
          value: account?.profile.testing_program_reaches?.map((reach) =>
            reach.preset ? reach.value : OTHER_OPTION_VALUE
          ),
          name: "testingProgramReaches",
          validator: {
            type: ValidatorTypes.CUSTOM,
            messages: {
              custom:
                'Please select the reach of your testing program. If you selected "Other," please specify.',
            },
            customValidator: (value: string[]) =>
              this.multipleValuesValidator(
                value,
                this.state.testingProgramReachOther
              ),
          },
        },
      },
    };
  }

  private getOtherValue = (items: DataTypes.ProfileSettingsOption[] | null) => {
    return items?.filter((item) => !item.preset)[0]?.value || "";
  };

  private onSubmit = (valid: boolean, dirty: boolean, data: any) => {
    const validator = new Validator();

    Object.keys(data).forEach((key) => validator.validateField(data[key]));

    if (valid === true) {
      this.props.updateAccountProfileRequest(
        {
          profile: {
            primary_testing_tool:
              data.primaryTestingTool.value === OTHER_OPTION_VALUE
                ? this.state.primaryTestingToolOther
                : data.primaryTestingTool.value,
            primary_analytics_tool:
              data.primaryAnalyticsTool.value === OTHER_OPTION_VALUE
                ? this.state.primaryAnalyticsToolOther
                : data.primaryAnalyticsTool.value,
            kind_of_tools: this.kindOfToolsRequest(data),
            kind_of_tests: this.kindOfTestsRequest(data),
            testing_challenges: this.testingChallengesRequest(data),
            testing_program_reaches: this.testingProgramReachRequest(data),
          },
        },
        `?step=${SetupStepsEnum.ABOUT_YOUR_GOALS}`
      );
    }
  };

  private kindOfToolsRequest(data: any) {
    return data.kindOfTools.value.map((tool: string) => {
      if (tool === OTHER_OPTION_VALUE) {
        return this.state.kindOfToolsOther;
      }

      return tool;
    });
  }

  private kindOfTestsRequest(data: any) {
    return data.kindOfTests.value.map((tool: string) => {
      if (tool === OTHER_OPTION_VALUE) {
        return this.state.kindOfTestsOther;
      }

      return tool;
    });
  }

  private testingChallengesRequest(data: any) {
    return data.testingChallenges.value.map((tool: string) => {
      if (tool === OTHER_OPTION_VALUE) {
        return this.state.testingChallengeOther;
      }

      return tool;
    });
  }

  private testingProgramReachRequest(data: any) {
    return data.testingProgramReaches.value.map((tool: string) => {
      if (tool === OTHER_OPTION_VALUE) {
        return this.state.testingProgramReachOther;
      }

      return tool;
    });
  }

  private primaryTestingToolOptions(onChange: any, field: any) {
    if (
      !this.props.profielSettingsOptions ||
      !this.props.profielSettingsOptions.primary_testing_tool
    ) {
      return [];
    }

    const tools = this.props.profielSettingsOptions.primary_testing_tool.map(
      (testingTool: DataTypes.ProfileSettingsOption) => ({
        value: testingTool.value,
        text: testingTool.value,
      })
    );

    return [
      ...tools,
      {
        value: OTHER_OPTION_VALUE,
        TextComponent: () => (
          <span>
            Other:{" "}
            <input
              style={{
                borderWidth: "0 0 1px 0",
                borderColor: "#71798b",
                color: "#71798b",
                backgroundColor: "transparent",
              }}
              defaultValue={this.state.primaryTestingToolOther}
              onBlur={(e) => {
                e.stopPropagation();
                this.setState(
                  { primaryTestingToolOther: e.target.value },
                  () => {
                    onChange(field, OTHER_OPTION_VALUE);
                  }
                );
              }}
              type="text"
            />
          </span>
        ),
      },
    ];
  }

  private primaryAnalyticsToolOptions(onChange: any, field: any) {
    if (
      !this.props.profielSettingsOptions ||
      !this.props.profielSettingsOptions.primary_analytics_tool
    ) {
      return [];
    }

    const tools = this.props.profielSettingsOptions.primary_analytics_tool.map(
      (analyticsTool: DataTypes.ProfileSettingsOption) => ({
        value: analyticsTool.value,
        text: analyticsTool.value,
      })
    );

    return [
      ...tools,
      {
        value: OTHER_OPTION_VALUE,
        TextComponent: () => (
          <span>
            Other:{" "}
            <input
              style={{
                borderWidth: "0 0 1px 0",
                borderColor: "#71798b",
                color: "#71798b",
                backgroundColor: "transparent",
              }}
              defaultValue={this.state.primaryAnalyticsToolOther}
              onBlur={(e) => {
                e.stopPropagation();
                this.setState(
                  { primaryAnalyticsToolOther: e.target.value },
                  () => {
                    onChange(field, OTHER_OPTION_VALUE);
                  }
                );
              }}
              type="text"
            />
          </span>
        ),
      },
    ];
  }

  private kindOfToolsOptions() {
    if (
      !this.props.profielSettingsOptions ||
      !this.props.profielSettingsOptions.kind_of_tool
    ) {
      return [];
    }

    const tools = this.props.profielSettingsOptions.kind_of_tool.map(
      (analyticsTool: DataTypes.ProfileSettingsOption) => ({
        value: analyticsTool.value,
        text: analyticsTool.value,
      })
    );

    return [
      ...tools,
      {
        value: OTHER_OPTION_VALUE,
        TextComponent: () => (
          <span>
            Other:{" "}
            <input
              style={{
                borderWidth: "0 0 1px 0",
                borderColor: "#71798b",
                color: "#71798b",
                backgroundColor: "transparent",
              }}
              defaultValue={this.state.kindOfToolsOther}
              onBlur={(e) => {
                e.stopPropagation();
                this.setState({ kindOfToolsOther: e.target.value });
              }}
              type="text"
            />
          </span>
        ),
      },
    ];
  }

  private kindOfTestsOptions() {
    if (
      !this.props.profielSettingsOptions ||
      !this.props.profielSettingsOptions.kind_of_test
    ) {
      return [];
    }

    const tools = this.props.profielSettingsOptions.kind_of_test.map(
      (analyticsTool: DataTypes.ProfileSettingsOption) => ({
        value: analyticsTool.value,
        text: analyticsTool.value,
      })
    );

    return [
      ...tools,
      {
        value: OTHER_OPTION_VALUE,
        TextComponent: () => (
          <span>
            Other:{" "}
            <input
              style={{
                borderWidth: "0 0 1px 0",
                borderColor: "#71798b",
                color: "#71798b",
                backgroundColor: "transparent",
              }}
              defaultValue={this.state.kindOfTestsOther}
              onBlur={(e) => {
                e.stopPropagation();
                this.setState({ kindOfTestsOther: e.target.value });
              }}
              type="text"
            />
          </span>
        ),
      },
    ];
  }

  private testingChallengesOptions() {
    if (
      !this.props.profielSettingsOptions ||
      !this.props.profielSettingsOptions.testing_challenge
    ) {
      return [];
    }

    const tools = this.props.profielSettingsOptions.testing_challenge.map(
      (analyticsTool: DataTypes.ProfileSettingsOption) => ({
        value: analyticsTool.value,
        text: analyticsTool.value,
      })
    );

    return [
      ...tools,
      {
        value: OTHER_OPTION_VALUE,
        TextComponent: () => (
          <span>
            Other:{" "}
            <input
              style={{
                borderWidth: "0 0 1px 0",
                borderColor: "#71798b",
                color: "#71798b",
                backgroundColor: "transparent",
              }}
              defaultValue={this.state.testingChallengeOther}
              onBlur={(e) => {
                e.stopPropagation();
                this.setState({ testingChallengeOther: e.target.value });
              }}
              type="text"
            />
          </span>
        ),
      },
    ];
  }

  private testingProgramReachesOptions() {
    if (
      !this.props.profielSettingsOptions ||
      !this.props.profielSettingsOptions.testing_program_reach
    ) {
      return [];
    }

    const tools = this.props.profielSettingsOptions.testing_program_reach.map(
      (analyticsTool: DataTypes.ProfileSettingsOption) => ({
        value: analyticsTool.value,
        text: analyticsTool.value,
      })
    );

    return [
      ...tools,
      {
        value: OTHER_OPTION_VALUE,
        TextComponent: () => (
          <span>
            Other:{" "}
            <input
              style={{
                borderWidth: "0 0 1px 0",
                borderColor: "#71798b",
                color: "#71798b",
                backgroundColor: "transparent",
              }}
              defaultValue={this.state.testingProgramReachOther}
              onBlur={(e) => {
                e.stopPropagation();
                this.setState({ testingProgramReachOther: e.target.value });
              }}
              type="text"
            />
          </span>
        ),
      },
    ];
  }

  private onCheckboxChange(field: FormField, event: any) {
    const { value } = event.target;

    if (field.value.lastIndexOf(value) === -1) {
      return [...field.value, value];
    }

    return field.value.filter((item: string) => item !== value);
  }

  private singleValueValidator = (value: string, otherValue: string) => {
    if (!value || (value === OTHER_OPTION_VALUE && !otherValue)) {
      return false;
    }

    return true;
  };

  private multipleValuesValidator = (values: string[], otherValue: string) => {
    if (!values || values.length === 0) {
      return false;
    }

    const other = values.filter((value) => value === OTHER_OPTION_VALUE)[0];

    if (other && !otherValue) {
      return false;
    }

    return true;
  };

  render() {
    const { account } = this.props;

    if (!account) {
      return;
    }

    const { primary_testing_tool, primary_analytics_tool } = account.profile;

    return (
      <React.Fragment>
        <h1>About Your Program</h1>
        <p>
          illuminate was built by testing experts, for testing experts. Tell us
          about your program to influence relevant content and future features.
        </p>
        <Form
          onChange={(dirty: boolean, data: FormFields) => {
            this.validator.validateForm(data);
          }}
          formFields={this.state.fields}
          onSubmit={this.onSubmit}
          FormComponent={({
            fields: {
              primaryTestingTool,
              primaryAnalyticsTool,
              kindOfTools,
              kindOfTests,
              testingChallenges,
              testingProgramReaches,
            },
            onChange,
          }) => (
            <div className="AboutYourTools">
              <div className="form-group">
                <label>What is your primary website testing tool?</label>
                <RadioButton
                  field={primaryTestingTool}
                  className={"SelectInput"}
                  options={this.primaryTestingToolOptions(
                    onChange,
                    primaryTestingTool
                  )}
                  onChange={(e: any) => {
                    this.validator.validateField(primaryTestingTool);
                    onChange(primaryTestingTool, e.target.value);
                  }}
                />
              </div>

              <div className="form-group">
                <label>What is your primary web analytics tool?</label>
                <RadioButton
                  field={primaryAnalyticsTool}
                  className={"SelectInput"}
                  options={this.primaryAnalyticsToolOptions(
                    onChange,
                    primaryAnalyticsTool
                  )}
                  onChange={(e: any) => {
                    onChange(primaryAnalyticsTool, e.target.value);
                  }}
                />
              </div>

              <div className="form-group">
                <label>
                  What kinds of tools do you have in your tool stack?
                </label>
                <CheckBoxGroup
                  field={kindOfTools}
                  className={"CheckBox"}
                  options={this.kindOfToolsOptions()}
                  onChange={(e: any) => {
                    onChange(
                      kindOfTools,
                      this.onCheckboxChange(kindOfTools, e)
                    );
                  }}
                />
              </div>

              <div className="form-group">
                <label>What kinds of testing is your organization doing?</label>
                <CheckBoxGroup
                  field={kindOfTests}
                  className={"CheckBox"}
                  options={this.kindOfTestsOptions()}
                  onChange={(e: any) => {
                    onChange(
                      kindOfTests,
                      this.onCheckboxChange(kindOfTests, e)
                    );
                  }}
                />
              </div>

              <div className="form-group">
                <label>What are your biggest testing challenges?</label>
                <CheckBoxGroup
                  field={testingChallenges}
                  className={"CheckBox"}
                  options={this.testingChallengesOptions()}
                  onChange={(e: any) => {
                    onChange(
                      testingChallenges,
                      this.onCheckboxChange(testingChallenges, e)
                    );
                  }}
                />
              </div>

              <div className="form-group">
                <label>What is the reach of your testing program?</label>
                <CheckBoxGroup
                  field={testingProgramReaches}
                  className={"CheckBox"}
                  options={this.testingProgramReachesOptions()}
                  onChange={(e: any) => {
                    onChange(
                      testingProgramReaches,
                      this.onCheckboxChange(testingProgramReaches, e)
                    );
                  }}
                />
              </div>

              <hr />
              <Button
                className="btn btn-primary"
                text="next"
                isLoading={false}
              />
            </div>
          )}
        />
      </React.Fragment>
    );
  }
}

const mapStateToProps = ({ profielSettingsOptions }: ApplicationState) => ({
  profielSettingsOptions,
});

const mapDispatchToProps = {
  updateAccountProfileRequest,
  getAccountRequest,
  getSettingsOptionsRequest,
};

const connectedPage = connect(
  mapStateToProps,
  mapDispatchToProps
)(AboutYourTools);

export { connectedPage as AboutYourTools };
