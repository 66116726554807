import React, { Component } from "react";
import { connect } from "react-redux";
import { ApplicationState } from "store/types";
import * as DataTypes from "store/types";
import { HTTPCodes } from "config/app";
import { clearAppErrors } from "store/app/actions";
import {
  createLoadingSelector,
  createErrorMessageSelector,
} from "store/selectors";
import { AppPage, AppPageProps } from "comps/pages";
import { Form, Input, Button, ValidatorTypes, InputTypes } from "comps/form";
import { AuthService } from "utils/auth";
import "css/InsightShare.scss";
import { getInsightShareRequest } from "store/insight/actions";
import { InsightConfidenceIcon } from "ui/insight_confidence";

interface IProps extends AppPageProps {
  router: any;
  computedMatch: any;
  loading: boolean;
  error: any;
  insight: DataTypes.Insight | null;
  clearAppErrors: () => void;
  account: DataTypes.Account | null;
  getInsightShareRequest: (
    slug: string,
    uuid: string,
    password: string | null
  ) => void;
}

interface IState {
  activeReport?: DataTypes.Report;
  loadingReport?: boolean;
}

class ShareInsight extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      loadingReport: false,
    };
  }

  componentDidMount = () => {
    this.props.clearAppErrors();

    let pwd;
    const sharedPwd = AuthService.getSharePwd();

    if (sharedPwd) {
      const json = JSON.parse(sharedPwd);
      if (json.key === this.props.computedMatch.params.uuid) {
        pwd = json.pwd;
      } else {
        AuthService.clearSharePwd();
      }
    }

    const { account } = this.props;
    if (account) {
      this.props.getInsightShareRequest(
        account.slug,
        this.props.computedMatch.params.uuid,
        pwd || null
      );
    }
  };

  private onSubmit = (valid: boolean, dirty: boolean, data: any) => {
    const { account } = this.props;
    this.props.clearAppErrors();

    if (account && valid) {
      AuthService.setSharePwd(
        JSON.stringify({
          key: this.props.computedMatch.params.uuid,
          pwd: data.password.value,
        })
      );
      this.props.getInsightShareRequest(
        account.slug,
        this.props.computedMatch.params.uuid,
        data.password.value
      );
    }
  };

  private confidence = () => {
    return [
      {
        name: "Low",
        value: "low",
        description: "We're in early stages of building supporting evidence.",
      },
      {
        name: "Medium-Low",
        value: "medium_low",
        description: "We've got supporting evidence, but want more.",
      },
      {
        name: "Medium-High",
        value: "medium_high",
        description: "With just a little more evidence, we'll feel confident.",
      },
      {
        name: "High",
        value: "high",
        description:
          "We've proven this insight to be true and are confident in it.",
      },
    ].filter(
      (confidence) => confidence.value === this.props.insight?.confidence
    )[0];
  };

  private altitude = () => {
    return [
      {
        icon: "/img/altitudes/satellite.png",
        name: "satellite",
        description:
          "This insight informs us about behavioral economics principles that influence our customers' decision making.",
      },
      {
        icon: "/img/altitudes/jetstream.png",
        name: "jetstream",
        description:
          "This insight informs us about general customer personas, reinforcing broader assumptions about our customers.",
      },
      {
        icon: "/img/altitudes/summit.png",
        name: "summit",
        description:
          "This insight informs us about our customers' state of mind, helping us understand them as people.",
      },
      {
        icon: "/img/altitudes/basecamp.png",
        name: "basecamp",
        description:
          "This insight informs us about usability and design elements, helping us understand what our customers prefer to engage with.",
      },
      {
        icon: "/img/altitudes/groundlevel.png",
        name: "groundlevel",
        description:
          'This insight informs us about "what worked" in a test and is tactical in nature.',
      },
    ].filter((altitude) => altitude.name === this.props.insight?.altitude)[0];
  };

  private buildTraits = (traitName: string, traits: any) => {
    if (!traits || traits.length === 0) {
      return null;
    }

    return (
      <ul>
        <li>
          <p className="trait-kind">{traitName}:</p>
          <ul className="traits">
            {traits.map((trait: any) => (
              <li key={`${traitName}-${trait}`}>{trait}</li>
            ))}
          </ul>
        </li>
      </ul>
    );
  };

  private buildContributors = () => {
    const { insight } = this.props;

    if (!insight || !insight.contributors || !insight.contributors.length) {
      return null;
    }

    const names = insight.contributors.map((contributor) =>
      [contributor.first_name, contributor.last_name].join(" ")
    );

    if (names.length === 1) {
      return `${names[0]} has also contributed.`;
    }

    if (names.length === 2) {
      return `${names[0]} and ${names[1]} have also contributed.`;
    }

    return `${names.slice(0, -1).join(", ")} and ${names[names.length - 1]
      } have also contributed.`;
  };

  private buildConfidence = () => {
    const confidence = this.confidence();
    const { insight } = this.props;

    if (!insight || !confidence) {
      return null;
    }

    return (
      <div className="left">
        <h5>
          The Insight Confidence<sup>&reg;</sup> for this insight is:
        </h5>

        <div style={{ display: "flex" }}>
          <InsightConfidenceIcon
            height={50}
            width={50}
            selected={insight?.confidence}
          />
          <span style={{ marginLeft: "5px", textTransform: "none" }}>
            <strong>{confidence.name}</strong> - {confidence.description}
          </span>
        </div>
      </div>
    );
  };

  private buildAltitude = () => {
    const altitude = this.altitude();
    const { insight } = this.props;

    if (!insight || !altitude) {
      return null;
    }

    return (
      <div className="right" style={{ marginLeft: "17px" }}>
        <h5>
          The Insight Altitude<sup>®</sup> level for this insight is:
        </h5>
        <div style={{ display: "flex", alignItems: "center" }}>
          <img
            style={{ height: "50px" }}
            src={altitude.icon}
            alt={altitude.name}
          />
          <span style={{ marginLeft: "5px", textTransform: "none" }}>
            <strong className="capitalize">{altitude.name}</strong> -{" "}
            {altitude.description}
          </span>
        </div>
      </div>
    );
  };

  private buildBrooksBellInsightFramework = () => {
    if (!this.props.account?.settings.use_bb_methods) {
      return null;
    }

    if (!this.altitude() && !this.confidence()) {
      return null;
    }

    return (
      <section className="bg-blue">
        <div className="content Details">
          <div>
            <h2>
              We use Brooks Bell's Insight Framework to categorize our insights.
            </h2>
          </div>
        </div>

        <div className="content Details">
          {this.buildConfidence()}

          {this.buildAltitude()}
        </div>
      </section>
    );
  };

  private buildExperiments = () => {
    const { insight } = this.props;

    if (!insight) {
      return null;
    }

    const { experiments } = insight;

    if (!experiments || !experiments.length) {
      return null;
    }

    return experiments.map((experiment: DataTypes.Experiment) => {
      const url = `${window.location.protocol}//${window.location.host}/cs/${experiment.case_study_uuid}`;

      const linkElement = experiment.case_study_uuid ? (
        <a href={url} target="_blank" rel="noopener noreferrer">
          {experiment.name}
        </a>
      ) : (
        <a href="#" target="_blank" rel="noopener noreferrer">
          {experiment.name}
        </a>
      );

      return (
        <li key={["insight_", insight.id].join("_")}>
          <div className="insight">{linkElement}</div>
        </li>
      );
    });
  };

  private buildUxrs = () => {
    const { insight } = this.props;

    if (!insight) {
      return null;
    }

    const { uxrs } = insight;

    if (!uxrs || !uxrs.length) {
      return null;
    }

    return uxrs.map((uxr: DataTypes.Uxr) => {
      const url = `${window.location.protocol}//${window.location.host}/uxrs/${uxr.share_uuid}`;

      const linkElement =
        uxr.share_uuid && uxr.share_uuid ? (
          <a href={url} target="_blank" rel="noopener noreferrer">
            {uxr.name}
          </a>
        ) : (
          <a href="#" target="_blank" rel="noopener noreferrer">
            {uxr.name}
          </a>
        );

      return (
        <li key={["insight_", insight.id].join("_")}>
          <div className="insight">{linkElement}</div>
        </li>
      );
    });
  };

  private buildPasswordForm = () => {
    const { loading } = this.props;

    return (
      <div className="ShareLogin">
        <div className="body">
          <img src="/img/logo.png" alt="illuminate" />
          <h3>This link is password protected</h3>
          <p>Enter the password below to view</p>
          <Form
            formFields={{
              password: {
                name: "password",
                value: "",
                validator: {
                  type: ValidatorTypes.REQUIRED,
                  messages: {
                    required: "Please enter a password to view the case study.",
                  },
                },
              },
            }}
            onSubmit={this.onSubmit}
            FormComponent={({ fields: { password }, onChange }) => (
              <div>
                <div className="form-group">
                  <Input
                    field={password}
                    placeholder="Enter password"
                    type={InputTypes.PASSWORD}
                    className="form-control"
                    onChange={(e) => onChange(password, e.target.value)}
                  />
                </div>
                <Button
                  className="btn btn-primary"
                  text="view insight"
                  isLoading={loading}
                />
              </div>
            )}
          />
        </div>
      </div>
    );
  };

  private safeTraitLength = (trait: any) => {
    if (!trait) {
      return 0;
    }

    return trait.length;
  };

  private buildTraitSection = () => {
    const { insight } = this.props;

    if (!insight) {
      return null;
    }

    if (
      !this.safeTraitLength(insight.brands) &&
      !this.safeTraitLength(insight.teams) &&
      !this.safeTraitLength(insight.channels)
    ) {
      return null;
    }

    return (
      <section>
        <div className="content Traits">
          <h2>This insight applies to:</h2>

          {this.buildTraits("Brands", insight.brands)}
          {this.buildTraits("Teams", insight.teams)}
          {this.buildTraits("Channels", insight.channels)}
        </div>
      </section>
    );
  };

  render() {
    const { loading, error } = this.props;

    if (error) {
      if (error.statusCode === HTTPCodes.FORBIDDEN) {
        return this.buildPasswordForm();
      }

      if (error.statusCode === HTTPCodes.NOT_FOUND) {
        return (
          <div className="ShareLogin">
            <div className="body">
              <img src="/img/logo.png" alt="illuminate" />
              <h3>The link you have requested is invalid</h3>
              <p>Please check the link and try again</p>
            </div>
          </div>
        );
      }
      return null;
    }

    const { insight } = this.props;

    if (loading || !insight) return null;

    return (
      <div className="InsightShare">
        <div className="header">
          <div className="left">
            <img
              src="/img/logo_sm.svg"
              alt="illuminate"
              className="center mt-4"
            />
          </div>
        </div>
        <div className="wrapper">
          <section>
            <div className="content Details">
              <div className="insight">
                <div className="bar"></div>
                <h1>{insight?.value}</h1>
              </div>
            </div>
          </section>

          {this.buildBrooksBellInsightFramework()}

          <section>
            <div className="content Experiments">
              <h2>Check out our supporting evidence for this insight:</h2>
              <ul>
                {this.buildExperiments()}
                {this.buildUxrs()}
              </ul>
            </div>
          </section>

          {this.buildTraitSection()}

          <section className="bg-blue">
            <div className="content Details">
              <p>
                This insight was originally created by{" "}
                {`${insight.author?.first_name} ${insight.author?.last_name}`}.
                {this.buildContributors()}
              </p>
            </div>
          </section>
        </div>
      </div>
    );
  }
}

const loadingSelector = createLoadingSelector(["@@insight/GET_INSIGHT_SHARE"]);
const errorSelector = createErrorMessageSelector([
  "@@insight/GET_INSIGHT_SHARE",
]);

const mapStateToProps = ({
  router,
  app,
  account,
  insight,
}: ApplicationState) => ({
  router,
  account,
  insight,
  loading: loadingSelector(app.requests),
  error: errorSelector(app.errors),
});

const mapDispatchToProps = {
  clearAppErrors,
  getInsightShareRequest,
};

const connectedPage = connect(
  mapStateToProps,
  mapDispatchToProps
)(AppPage(ShareInsight));

export { connectedPage as ShareInsight };
