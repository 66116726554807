import React, { useState, useRef } from 'react';
import * as Dp from 'react-date-picker';
import { handleOutsideClick } from './utils';
import { Helpers } from 'utils';
import moment from 'moment';
import { AuthService } from 'utils/auth';

interface IProps {
  date: Date[] | Date;
  className?: string;
  onChange(value: string): void;
  minDate?: Date;
  maxDate?: Date;
}

const DatePicker = ({ date, className, onChange, minDate, maxDate }: IProps) => {
  const ref = useRef<any>(null);
  const [edit, setEdit] = useState(false);

  handleOutsideClick(ref, () => {
    if (edit) {
      open(!edit);
    }
  });

  const onChangeValue = (date: Date[] | Date) => {
    const val = moment(Array.isArray(date) ? date[0] : date).format('YYYY-MM-DD');

    onChange(val);
    open(!edit);
  };

  const open = (value: any) => {
    if (isReadOnly()) {
      return;
    }

    setEdit(value);
  }

  const isReadOnly = () => {
    const user = AuthService.getActiveUser();

    return user.role === 'read-only';
  }

  if (edit === false) {
    return (
      <div ref={ref} className="DatePicker" onClick={e => open(!edit)}>
        <p>{Helpers.formatDate(moment(Array.isArray(date) ? date[0] : date).format('YYYY-MM-DD'))}</p>
      </div>
    );
  } else {
    return (
      <div ref={ref} className="DatePicker active">
        <Dp.default
          className={className}
          onChange={val => onChangeValue(val)}
          calendarType={'US'}
          clearIcon={null}
          value={date}
          calendarIcon={<i className="far fa-calendar" />}
          minDate={minDate}
          maxDate={maxDate}
        />
      </div>
    );
  }
};

export { DatePicker };
