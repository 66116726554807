import React from 'react';

interface IButton {
  text: string;
  onClick: () => void;
  selected: () => boolean;
}

interface ISwitchButtonProps {
  buttons: Array<IButton>;
}

export const SwitchButton = ({ buttons }: ISwitchButtonProps) => {
  return <div className="switch_button">
    {
      buttons.map((button) =>
        <button
          key={button.text}
          onClick={button.onClick}
          className={`${button.selected() ? 'active' : ''}`}>
            {button.text}
        </button>
      )
    }
  </div>;
};
