import { ApiClient } from './client/api_client';

class AbExperiments {
  static async create(body: any) {
    return await ApiClient.post('/v1/experiment/ab', { experiment: body });
  }

  static async update(id: string, body: any) {
    return await ApiClient.put(`/v1/experiment/ab/${id}`, body);
  }
}

export { AbExperiments };
