import { Button, Form, Input, ValidatorTypes } from "comps";
import { CharacterMetadata } from "draft-js";
import React from "react";

interface IProps {
  show: boolean;
  position: { top: number; left: number };
  editorState: any;
  onToggle(type: string, style: any): void;
  showModal: any;
  hideModal: () => void;
}

const INLINE_STYLES = [
  { label: "B", icon: "fas fa-bold", style: "BOLD" },
  { label: "I", icon: "fas fa-italic", style: "ITALIC" },
  { label: "U", icon: "fas fa-underline", style: "UNDERLINE" },
];

const HEADER_STYLES = [
  { label: "H1", icon: "fas fa-heading", style: "header-one" },
  { label: "H2", icon: "fas fa-heading", style: "header-two" },
];

const LIST_STYLES = [
  { label: "UL", icon: "fas fa-list-ul", style: "unordered-list-item" },
  { label: "OL", icon: "fas fa-list-ol", style: "ordered-list-item" },
];

const isLink = (currentContentBlock: any, content: any) => {
  let hasLink = false;
  const entityType = "LINK";

  currentContentBlock.findEntityRanges(
    (character: CharacterMetadata) => {
      if (!character || !character.getEntity()) {
        return false;
      }

      const entity = content.getEntity(character.getEntity());

      if (!entity) {
        return false;
      }

      return entity.getType() === entityType;
    },
    () => {
      hasLink = true;
    }
  );

  return hasLink;
};

const urlModal = ({ onToggle, hide }: { onToggle: any; hide: any }) => {
  const onSubmit = (valid: boolean, dirty: boolean, data: any) => {
    onToggle(data.url.value);
    hide();
  };

  return (
    <Form
      formFields={{
        url: {
          name: "url",
          value: "",
          validator: {
            type: ValidatorTypes.REQUIRED,
            messages: { required: "Please enter a valid test name" },
          },
        },
      }}
      onSubmit={onSubmit}
      FormComponent={({ fields: { url }, onChange }) => (
        <div>
          <section>
            <div className="form-group">
              <label className="required">Add Link</label>
              <Input
                autoFocus={true}
                field={url}
                className="form-control"
                onChange={(e) => onChange(url, e.target.value)}
              />
            </div>
          </section>
          <Button className="btn btn-primary" text="Save Link" />
        </div>
      )}
    />
  );
};

export const EditorToolbar = ({
  hideModal,
  show,
  position,
  editorState,
  onToggle,
  showModal,
}: IProps) => {
  const currentStyle = editorState.getCurrentInlineStyle();
  const contentState = editorState.getSelection();
  const anchorKey = contentState.getAnchorKey();

  const content = editorState.getCurrentContent();
  const currentContentBlock = editorState
    .getCurrentContent()
    .getBlockForKey(anchorKey);

  let hasLink = isLink(currentContentBlock, content);

  if (!show) {
    return null;
  }

  return (
    <div className="Toolbar" style={position}>
      <ul className="toolbar-items">
        {HEADER_STYLES.map((type) => (
          <li
            key={type.label}
            className={`toolbar-item ${type.style.toLowerCase()} ${
              currentContentBlock.getType() === type.style ? "active" : ""
            }`}
            onMouseDown={(e) => {
              e.preventDefault();
              onToggle("block", type.style);
            }}
          >
            <label>{type.label}</label>
          </li>
        ))}
      </ul>
      <ul className="toolbar-items">
        {INLINE_STYLES.map((type) => (
          <li
            key={type.label}
            className={`toolbar-item ${type.style.toLowerCase()} ${
              currentStyle.has(type.style) ? "active" : ""
            }`}
            onMouseDown={(e) => {
              e.preventDefault();
              onToggle("inline", type.style);
            }}
          >
            <i className={type.icon} />
          </li>
        ))}
      </ul>
      <ul className="toolbar-items">
        {LIST_STYLES.map((type) => (
          <li
            key={type.label}
            className={`toolbar-item ${type.style.toLowerCase()} ${
              currentContentBlock.getType() === type.style ? "active" : ""
            }`}
            onMouseDown={(e) => {
              e.preventDefault();
              onToggle("block", type.style);
            }}
          >
            <i className={type.icon} />
          </li>
        ))}
      </ul>
      <ul className="toolbar-items">
        <li
          className={`toolbar-item ${hasLink ? "active" : ""}`}
          onMouseDown={(e) => {
            e.preventDefault();
            if (!hasLink) {
              showModal(urlModal, {
                hide: hideModal,
                onToggle: (url: string) => onToggle("link", url),
              });

              return;
            }
            onToggle("removeLink", "link");
          }}
        >
          <i className="fas fa-link" />
        </li>
      </ul>
    </div>
  );
};
