import React, { Component } from "react";
import * as DataTypes from "store/types";

interface IProps {
  programGoal: DataTypes.ProgramGoal;
  saving: boolean;
  onSubmit(programGoal: DataTypes.ProgramGoal): void;
}

interface IState {
  disabled: boolean;
}

class ProgramGoalDelete extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      disabled: true,
    };
  }

  private toggleChecked = (checked: boolean) => {
    this.setState({ ...this.state, disabled: !checked });
  };

  render() {
    const { programGoal, onSubmit, saving } = this.props;

    return (
      <React.Fragment>
        <div className="header">
          <h3>Delete Program Goal</h3>

          <p className="mt-2">
            This program goal is associated with{" "}
            <strong>{programGoal.total_experiments}</strong> tests. By deleting
            this program goal, you will remove this assocation.
          </p>
          <p className="mt-1">
            This action can not be undone and this program goal will no longer
            be an available option for your program.
          </p>
          <p className="mt-2">
            Are you sure you want to delete the program goal{" "}
            <strong>
              <span>{programGoal.name}</span>
            </strong>
            ?
          </p>
        </div>
        <div className="cntrl-bar">
          <div className="d-flex mr-4 mt-2 align-center">
            <input
              type="checkbox"
              defaultChecked={false}
              style={{ position: "relative", top: "1px" }}
              onChange={(e) => this.toggleChecked(e.target.checked)}
            />
            <p className="ml-2 mb-0">Please check the box to confirm.</p>
          </div>
          {saving === true ? (
            <button className="btn btn-danger" type="submit" disabled={true}>
              <i className="fas fa-circle-notch fa-spin" />
            </button>
          ) : (
            <button
              className="btn btn-danger"
              onClick={() => onSubmit(programGoal)}
              disabled={this.state.disabled}
            >
              delete program goal
            </button>
          )}
        </div>
      </React.Fragment>
    );
  }
}

export { ProgramGoalDelete };
