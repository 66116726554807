import { ApiClient } from './client/api_client';

class Apps {
  static async index() {
    return await ApiClient.get('/v1/apps');
  }

  static async createAdobe() {
    return await ApiClient.post('/v1/apps/adobe', { });
  }

  static async updateAdobe(id: string, body: any) {
    return await ApiClient.put(`/v1/apps/adobe/${id}`, { app: body });
  }

  static async experiments(type: string) {
    return await ApiClient.get(`/v1/apps/${type}/experiment`);
  }

  static async metrics(type: string, rsid: string) {
    return await ApiClient.get(`/v1/apps/${type}/metrics?rsid=${rsid}`);
  }
}

export { Apps };
