import { action } from 'typesafe-actions';
import { Dispatch } from 'redux';
import { ActionTypes } from './types';
import * as DataTypes from '../types';

interface RequestError {
  statusCode?: number | undefined;
  message: any;
}

// Fetch leaderboard data
export const getLeaderBoardRequest = () => (dispatch: Dispatch) => dispatch(action(ActionTypes.GET_LEADER_BOARD_REQUEST));
export const getLeaderBoardSuccess = (response: DataTypes.LeaderBoard) => action(ActionTypes.GET_LEADER_BOARD_SUCCESS, response);
export const getLeaderBoardError = (error: RequestError) => action(ActionTypes.GET_LEADER_BOARD_ERROR, error);

// Fetch insight data
export const getInsightsBoardRequest = () => (dispatch: Dispatch) => dispatch(action(ActionTypes.GET_INSIGHT_BOARD_REQUEST));
export const getInsightsBoardSuccess = (response: DataTypes.InsightBoard) => action(ActionTypes.GET_INSIGHT_BOARD_SUCCESS, response);
export const getInsightsBoardError = (error: RequestError) => action(ActionTypes.GET_INSIGHT_BOARD_ERROR, error);

// Fetch today data
export const getTodayBoardRequest = () => (dispatch: Dispatch) => dispatch(action(ActionTypes.GET_TODAY_BOARD_REQUEST));
export const getTodayBoardSuccess = (response: DataTypes.TodayBoard) => action(ActionTypes.GET_TODAY_BOARD_SUCCESS, response);
export const getTodayBoardError = (error: RequestError) => action(ActionTypes.GET_TODAY_BOARD_ERROR, error);

// Fetch today data
export const getDidYouKnowRequest = () => (dispatch: Dispatch) => dispatch(action(ActionTypes.GET_DID_YOU_KNOW_REQUEST));
export const getDidYouKnowSuccess = (response: DataTypes.DidYouKnow) => action(ActionTypes.GET_DID_YOU_KNOW_SUCCESS, response);
export const getDidYouKnowError = (error: RequestError) => action(ActionTypes.GET_DID_YOU_KNOW_ERROR, error);
