import { action } from "typesafe-actions";
import { Dispatch } from "redux";
import { ActionTypes } from "./types";
import * as DataTypes from "../types";

interface RequestError {
  statusCode?: number | undefined;
  message: any;
}

// Create Account
export const createAccountRequest =
  (body: DataTypes.AccountRequest) => (dispatch: Dispatch) =>
    dispatch(action(ActionTypes.CREATE_ACCOUNT_REQUEST, { body }));
export const createAccountSuccess = (
  response: DataTypes.ApiRequest<DataTypes.Account>
) => action(ActionTypes.CREATE_ACCOUNT_SUCCESS, response);
export const createAccountError = (error: RequestError) =>
  action(ActionTypes.CREATE_ACCOUNT_FAILURE, error);

// Get Account
export const getAccountRequest =
  (slug: string, callback: any) => (dispatch: Dispatch) =>
    dispatch(action(ActionTypes.GET_ACCOUNT_REQUEST, { slug, callback }));
export const getAccountSuccess = (
  response: DataTypes.ApiRequest<DataTypes.Account>
) => action(ActionTypes.GET_ACCOUNT_SUCCESS, response);
export const getAccountError = (error: RequestError) =>
  action(ActionTypes.GET_ACCOUNT_FAILURE, error);

// Get Account Summary
export const getAccountSummaryRequest = () =>
  action(ActionTypes.GET_ACCOUNT_SUMMARY_REQUEST, {});
export const getAccountSummarySuccess = (
  response: DataTypes.ApiRequest<DataTypes.AccountSummary>
) => action(ActionTypes.GET_ACCOUNT_SUMMARY_SUCCESS, response);
export const getAccountSummaryError = (error: RequestError) =>
  action(ActionTypes.GET_ACCOUNT_SUMMARY_FAILURE, error);

// Get Account Traits
export const getAccountTraitsRequestSaga = () =>
  action(ActionTypes.GET_ACCOUNT_TRAITS_REQUEST, {});
export const getAccountTraitsRequest = () => (dispatch: Dispatch) =>
  dispatch(action(ActionTypes.GET_ACCOUNT_TRAITS_REQUEST, {}));
export const getAccountTraitsSuccess = (
  response: DataTypes.ApiRequest<DataTypes.Trait>
) => action(ActionTypes.GET_ACCOUNT_TRAITS_SUCCESS, response);
export const getAccountTraitsError = (error: RequestError) =>
  action(ActionTypes.GET_ACCOUNT_TRAITS_FAILURE, error);

// Get Account Apps
export const getAccountAppsRequest = () => (dispatch: Dispatch) =>
  dispatch(action(ActionTypes.GET_ACCOUNT_APPS_REQUEST, {}));
export const getAccountAppsSuccess = (
  response: DataTypes.ApiRequest<DataTypes.App>
) => action(ActionTypes.GET_ACCOUNT_APPS_SUCCESS, response);
export const getAccountAppsError = (error: RequestError) =>
  action(ActionTypes.GET_ACCOUNT_APPS_FAILURE, error);

// Create Account App
export const createAppRequest = (body: any) => (dispatch: Dispatch) =>
  dispatch(action(ActionTypes.CREATE_APP_REQUEST, { body }));
export const createAppSuccess = (
  response: DataTypes.ApiRequest<DataTypes.App>
) => action(ActionTypes.CREATE_APP_SUCCESS, response);
export const createAppError = (error: RequestError) =>
  action(ActionTypes.CREATE_APP_FAILURE, error);

// Update Account App
export const updateAppRequest =
  (id: string, body: any) => (dispatch: Dispatch) =>
    dispatch(action(ActionTypes.UPDATE_APP_REQUEST, { id, body }));
export const updateAppSuccess = (
  response: DataTypes.ApiRequest<DataTypes.App>
) => action(ActionTypes.UPDATE_APP_SUCCESS, response);
export const updateAppError = (error: RequestError) =>
  action(ActionTypes.UPDATE_APP_FAILURE, error);

// Get App Experiments
export const getAppExpsRequest = (type: string) => (dispatch: Dispatch) =>
  dispatch(action(ActionTypes.GET_APP_EXPS_REQUEST, { type }));
export const getAppExpsSuccess = (
  type: string,
  response: DataTypes.ApiRequest<DataTypes.App>
) => action(ActionTypes.GET_APP_EXPS_SUCCESS, { type, response });
export const getAppExpsError = (error: RequestError) =>
  action(ActionTypes.GET_APP_EXPS_FAILURE, error);

// Get App Metrics
export const getAppMetricsRequest =
  (type: string, rsid: string) => (dispatch: Dispatch) =>
    dispatch(action(ActionTypes.GET_APP_METRICS_REQUEST, { type, rsid }));
export const getAppMetricsSuccess = (
  type: string,
  response: DataTypes.ApiRequest<DataTypes.App>
) => action(ActionTypes.GET_APP_METRICS_SUCCESS, { type, response });
export const getAppMetricsError = (error: RequestError) =>
  action(ActionTypes.GET_APP_METRICS_FAILURE, error);

// Get Account Users
export const getUsersRequest = (
  options: DataTypes.ApiListOptions,
  append?: boolean
) => action(ActionTypes.GET_USERS_REQUEST, { options, append });
export const getUsersSuccess = (
  response: DataTypes.ApiList<DataTypes.User>,
  append?: boolean
) => action(ActionTypes.GET_USERS_SUCCESS, { response, append });
export const getUsersError = (error: RequestError) =>
  action(ActionTypes.GET_USERS_FAILURE, error);

// Delete Account User
export const deleteUserRequest = (user: DataTypes.User) =>
  action(ActionTypes.DELETE_USER_REQUEST, { user });
export const deleteUserSuccess = (user: DataTypes.User) =>
  action(ActionTypes.DELETE_USER_SUCCESS, { user });
export const deleteUserError = (error: RequestError) =>
  action(ActionTypes.DELETE_USER_FAILURE, error);

// Update Account Profile
export const updateAccountProfileRequest =
  (body: any, redirectUrl?: string) => (dispatch: Dispatch) =>
    dispatch(
      action(ActionTypes.UPDATE_ACCOUNT_PROFILE_REQUEST, { body, redirectUrl })
    );
export const updateAccountProfileSuccess = (
  response: DataTypes.ApiRequest<DataTypes.Account>
) => action(ActionTypes.UPDATE_ACCOUNT_PROFILE_SUCCESS, { response });
export const updateAccountProfileError = (error: RequestError) =>
  action(ActionTypes.UPDATE_ACCOUNT_PROFILE_FAILURE, error);

// Update Account Program Goals
export const updateAccountProgramGoalsRequest =
  (body: any, redirectUrl?: string) => (dispatch: Dispatch) =>
    dispatch(
      action(ActionTypes.UPDATE_ACCOUNT_PGS_REQUEST, { body, redirectUrl })
    );
export const updateAccountProgramGoalsSuccess = (
  response: DataTypes.ApiRequest<DataTypes.Account>
) => action(ActionTypes.UPDATE_ACCOUNT_PGS_SUCCESS, { response });
export const updateAccountProgramGoalsError = (error: RequestError) =>
  action(ActionTypes.UPDATE_ACCOUNT_PGS_FAILURE, error);

export const updateProgramGoalRequest =
  (id: string, body: any) => (dispatch: Dispatch) =>
    dispatch(action(ActionTypes.UPDATE_PROGRAM_GOAL_REQUEST, { id, body }));
export const updateProgramGoalSuccess = (
  response: DataTypes.ApiRequest<DataTypes.Account>
) => action(ActionTypes.UPDATE_PROGRAM_GOAL_SUCCESS, { response });
export const updateProgramGoalError = (error: RequestError) =>
  action(ActionTypes.UPDATE_PROGRAM_GOAL_FAILURE, error);

// Update Account Setting
export const updateAccountSettingsRequest =
  (settings: DataTypes.AccountSettings, redirectUrl?: string) =>
  (dispatch: Dispatch) =>
    dispatch(
      action(ActionTypes.UPDATE_ACCOUNT_SETTINGS_REQUEST, {
        settings,
        redirectUrl,
      })
    );
export const updateAccountSettingsSuccess = (
  settings: DataTypes.AccountSettings
) => action(ActionTypes.UPDATE_ACCOUNT_SETTINGS_SUCCESS, { settings });
export const updateAcccountSettingsError = (error: RequestError) =>
  action(ActionTypes.UPDATE_ACCOUNT_SETTINGS_FAILURE, error);

// Invite New User
export const inviteNewUserRequest =
  (invite: { email: string }) => (dispatch: Dispatch) =>
    dispatch(action(ActionTypes.INVITE_NEW_USER_REQUEST, { invite }));
export const inviteNewUserSuccess = (invite: any) =>
  action(ActionTypes.INVITE_NEW_USER_SUCCESS, { invite });
export const inviteNewUserError = (error: RequestError) =>
  action(ActionTypes.INVITE_NEW_USER_FAILURE, error);

// Invite Multi User
export const inviteNewUsersRequest = (
  invites: Array<{ email: string }>,
  url: string
) => action(ActionTypes.INVITE_NEW_USERS_REQUEST, { invites, url });
export const inviteNewUsersSuccess = (invites: any) =>
  action(ActionTypes.INVITE_NEW_USERS_SUCCESS, { invites });
export const inviteNewUsersError = (error: RequestError) =>
  action(ActionTypes.INVITE_NEW_USERS_FAILURE, error);

// Get Pending Invites
export const getPendingInvitesRequest = () => (dispatch: Dispatch) =>
  dispatch(action(ActionTypes.GET_PENDING_INVITES_REQUEST));
export const getPendingInvitesSuccess = (response: any) =>
  action(ActionTypes.GET_PENDING_INVITES_SUCCESS, { response });
export const getPendingInvitesError = (error: RequestError) =>
  action(ActionTypes.GET_PENDING_INVITES_FAILURE, error);

// Delete Pending Invites
export const deletePendingInviteRequest =
  (invite: DataTypes.Invite) => (dispatch: Dispatch) =>
    dispatch(action(ActionTypes.DELETE_PENDING_INVITE_REQUEST, { invite }));
export const deletePendingInviteSuccess = (response: any) =>
  action(ActionTypes.DELETE_PENDING_INVITE_SUCCESS, { response });
export const deletePendingInviteError = (error: RequestError) =>
  action(ActionTypes.DELETE_PENDING_INVITE_FAILURE, error);

// Get Account Stats
export const getAccountStatsRequest =
  (
    start_date: string,
    end_date: string,
    brand?: string,
    team?: string,
    channel?: string
  ) =>
  (dispatch: Dispatch) =>
    dispatch(
      action(ActionTypes.GET_ACCOUNT_STATS_REQUEST, {
        start_date,
        end_date,
        brand,
        team,
        channel,
      })
    );
export const getAccountStatsSuccess = (
  response: DataTypes.ApiRequest<DataTypes.Account>
) => action(ActionTypes.GET_ACCOUNT_STATS_SUCCESS, response);
export const getAccountStatsError = (error: RequestError) =>
  action(ActionTypes.GET_ACCOUNT_STATS_FAILURE, error);

// Get Share Account Traits
export const getShareAccountTraitsRequest =
  (slug: string) => (dispatch: Dispatch) =>
    dispatch(action(ActionTypes.GET_SHARE_ACCOUNT_TRAITS_REQUEST, { slug }));
export const getShareAccountTraitsSuccess = (
  response: DataTypes.ApiRequest<DataTypes.Trait>
) => action(ActionTypes.GET_SHARE_ACCOUNT_TRAITS_SUCCESS, response);
export const getShareAccountTraitsError = (error: RequestError) =>
  action(ActionTypes.GET_SHARE_ACCOUNT_TRAITS_FAILURE, error);
