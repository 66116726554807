import React, { Component } from "react";
import moment from "moment";
import * as DataTypes from "store/types";
import { MultiSelectInput, DatePicker, TextInput } from "ui";
import { Helpers } from "utils";
import { EditorInput } from "ui/editor_input/editor_input";
import { createUxrsTraitsRequest } from "store/uxr_trait/actions";
import { updateExperienceRequest } from "store/experience/actions";
import { connect } from "react-redux";
import { MethodologySelector } from "ui/methodology_selector";
import { Uploader } from "./uploader";

interface IProps {
  account: DataTypes.Account;
  uxr: DataTypes.Uxr;
  currentUser: any;
  traits: { [key: string]: Array<DataTypes.Trait> } | null;
  onChange(key: string, value: string | Array<string>): void;
  onViewImage(image: DataTypes.Image): void;
  deleteImage: DataTypes.Image | null;
  onImageDrop(files: any): void;
  onToggleDelete(
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    image: DataTypes.Image | null
  ): void;
  onDeleteImage(
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    image: DataTypes.Image
  ): void;
  createUxrsTraitsRequest: (
    experimentId: string,
    traitName: string,
    values: string[]
  ) => void;
}

interface IState {
  minimumEndDate?: Date;
  maximumStartDate?: Date;
}

class Design extends Component<IProps, IState> {
  state: IState = {};

  private onDateChange = (name: string) => (value: string) => {
    const { onChange } = this.props;
    if (name === "start_date") {
      const minimumEndDate = moment(value).toDate();
      this.setState({ minimumEndDate });
    }
    if (name === "end_date") {
      const maximumStartDate = moment(value).toDate();
      this.setState({ maximumStartDate });
    }
    return onChange(name, value.toString());
  };

  componentDidMount() {
    const { uxr } = this.props;

    if (uxr.start_date && uxr.end_date)
      this.setState({
        minimumEndDate: moment(uxr.start_date).toDate(),
        maximumStartDate: moment(uxr.end_date).toDate(),
      });
  }

  render() {
    const {
      account,
      uxr,
      traits,
      currentUser,
      deleteImage,
      onChange,
      onDeleteImage,
      onToggleDelete,
      onViewImage,
      onImageDrop,
    } = this.props;

    return (
      <React.Fragment>
        <div className="Details">
          <section className="General">
            <div className="left">
              <div className="form-group">
                <ul className="DateRangePicker">
                  <li>
                    <label>start date</label>
                    <DatePicker
                      key="start_date"
                      className="form-control"
                      onChange={this.onDateChange("start_date")}
                      date={
                        uxr.start_date
                          ? moment(uxr.start_date, "YYYY-MM-DD").toDate()
                          : moment().toDate()
                      }
                      maxDate={this.state.maximumStartDate}
                    />
                  </li>
                  <li>
                    <label>end date</label>
                    <DatePicker
                      key="end_date"
                      className="form-control"
                      onChange={this.onDateChange("end_date")}
                      date={
                        uxr.end_date
                          ? moment(uxr.end_date, "YYYY-MM-DD").toDate()
                          : moment().toDate()
                      }
                      minDate={this.state.minimumEndDate}
                    />
                  </li>
                </ul>
              </div>
              <div className="form-group">
                <label>summary</label>
                <EditorInput
                  content={uxr.description}
                  onBlur={(value: any) => onChange("description", value)}
                />
              </div>

              <div className="d-flex flex-row">
                <div className="form-group w--full">
                  <div className="control" style={{ marginBottom: "40px" }}>
                    <label>Methodology</label>
                    <MethodologySelector
                      value={Number(uxr.methodology)}
                      onChange={(value) => onChange("methodology", value)}
                    />
                  </div>
                </div>
              </div>

              <div className="form-group" style={{ marginTop: "24px" }}>
                <label>methods</label>
                <MultiSelectInput
                  activeItems={uxr.method_kinds}
                  name="method_kinds"
                  items={
                    traits && traits["method_kinds"]
                      ? Helpers.uniqueArray([
                          ...traits["method_kinds"].map((t) => {
                            return t.name;
                          }),
                          "Unmoderated",
                          "Moderated",
                          "Survey",
                          "Field Study",
                          "Tree Testing",
                          "Journey Map",
                          "Card Sort",
                          "Info Architecture",
                          "Wevo®",
                        ])
                      : []
                  }
                  onChange={(values) =>
                    this.props.createUxrsTraitsRequest(
                      uxr.id || "",
                      "method_kinds",
                      values
                    )
                  }
                />
              </div>
            </div>
          </section>
        </div>

        <hr />

        <div
          className="d-flex flex-row"
          style={{
            marginTop: "24px",
            padding: "20px 0px 20px 26px",
          }}
        >
          <div style={{ width: "300px" }}>
            <h4
              style={{
                fontSize: "16px",
                textTransform: "capitalize",
                color: "#757e8e",
              }}
            >
              Scope of Study
            </h4>

            <p style={{ fontSize: "12px" }}>
              Define who your study is designed to learn more about, the
              criteria required in order to participate, and how many
              participants you had. Additionally, share any competitors and/or
              pages/journeys the study explored.
            </p>
          </div>

          <div
            className="w__calc--300"
            style={{ paddingLeft: "12px", display: "flex", flexWrap: "wrap" }}
          >
            <div className="form-group w--50 p__sm">
              <label className="text__xsm" style={{ fontWeight: 500 }}>
                Participants
              </label>
              <p className="text__xsm">
                How many participants were involved in this study?
              </p>
              <TextInput
                className="TitleGrow"
                value={uxr.participants || "  "}
                onChange={(value) => onChange("participants", value)}
              >
                <p>{uxr.participants || ""}</p>
              </TextInput>
            </div>

            <div className="form-group w--50  p__sm">
              <label
                className="text__xsm"
                style={{ textTransform: "none", fontWeight: 500 }}
              >
                Competitor(s)
              </label>
              <p className="text__xsm">
                If applicable, list which competitor(s) were evaluated in this
                study.
              </p>
              <MultiSelectInput
                activeItems={uxr.competitors}
                name="competitors"
                items={
                  traits && traits["competitors"]
                    ? Helpers.uniqueArray(
                        traits["competitors"].map((t) => {
                          return t.name;
                        })
                      )
                    : []
                }
                onChange={(values) =>
                  this.props.createUxrsTraitsRequest(
                    uxr.id || "",
                    "competitors",
                    values
                  )
                }
              />
            </div>

            <div className="form-group w--50 p__sm">
              <label
                className="text__xsm"
                style={{ textTransform: "none", fontWeight: 500 }}
              >
                Audience(s)
              </label>
              <p className="text__xsm">
                Specify what audience(s) are targeted in this research.
              </p>
              <MultiSelectInput
                activeItems={uxr.audiences}
                name="audiences"
                items={
                  traits && traits["audiences"]
                    ? Helpers.uniqueArray(
                        traits["audiences"].map((t) => {
                          return t.name;
                        })
                      )
                    : []
                }
                onChange={(values) =>
                  this.props.createUxrsTraitsRequest(
                    uxr.id || "",
                    "audiences",
                    values
                  )
                }
              />
            </div>

            <div className="form-group w--50 p__sm">
              <label
                className="text__xsm"
                style={{ textTransform: "none", fontWeight: 500 }}
              >
                Page(s)
              </label>
              <p className="text__xsm" style={{ textTransform: "none" }}>
                Specify the page(s) that are targeted in this research.
              </p>
              <MultiSelectInput
                activeItems={uxr.pages}
                name="pages"
                items={
                  traits && traits["pages"]
                    ? Helpers.uniqueArray(
                        traits["pages"].map((t) => {
                          return t.name;
                        })
                      )
                    : []
                }
                onChange={(values) =>
                  this.props.createUxrsTraitsRequest(
                    uxr.id || "",
                    "pages",
                    values
                  )
                }
              />
            </div>

            <div className="form-group w--full p__sm">
              <label
                className="text__xsm"
                style={{ textTransform: "none", fontWeight: 500 }}
              >
                Audience Criteria
              </label>
              <p className="text__xsm">
                What qualifiers were used to determine eligibility for this
                study?
              </p>

              <EditorInput
                content={uxr.audience_criteria}
                onBlur={(value: any) => onChange("audience_criteria", value)}
              />
            </div>
          </div>
        </div>

        <hr />

        <div
          className="d-flex flex-row"
          style={{
            marginTop: "24px",
            padding: "20px 0px 20px 26px",
          }}
        >
          <div style={{ width: "300px" }}>
            <h4
              style={{
                fontSize: "16px",
                textTransform: "capitalize",
                color: "#757e8e",
              }}
            >
              Relevant Visuals
            </h4>

            <p style={{ fontSize: "12px" }}>
              Provide visuals to help the reader understand what was being
              researched.
            </p>
          </div>

          <div
            className="w__calc--300"
            style={{ paddingLeft: "12px", display: "flex", flexWrap: "wrap" }}
          >
            <Uploader
              currentUser={currentUser}
              deleteImage={deleteImage}
              onDeleteImage={onDeleteImage}
              onImageDrop={onImageDrop}
              onToggleDelete={onToggleDelete}
              onView={onViewImage}
              uxr={uxr}
            />
          </div>
        </div>

        <hr />

        <div
          className="d-flex flex-row"
          style={{
            marginTop: "24px",
            padding: "20px 0px 20px 26px",
          }}
        >
          <div style={{ width: "300px" }}>
            <h4
              style={{
                fontSize: "16px",
                textTransform: "capitalize",
                color: "#757e8e",
              }}
            >
              For Your Reference
            </h4>

            <p style={{ fontSize: "12px" }}>
              Select attributes to make this study easier to reference later
            </p>
          </div>

          <div
            className="w__calc--300"
            style={{ paddingLeft: "12px", display: "flex", flexWrap: "wrap" }}
          >
            {account && account.settings.brands && (
              <div className="form-group w--50 p__sm">
                <label
                  className="text__xsm"
                  style={{ textTransform: "none", fontWeight: 500 }}
                >
                  Brands
                </label>
                <p className="text__xsm">
                  Specify the brand(s) that are targeted in this research.
                </p>
                <MultiSelectInput
                  activeItems={uxr.brands}
                  name="brands"
                  items={
                    traits && traits["brands"]
                      ? Helpers.uniqueArray(
                          traits["brands"].map((t) => {
                            return t.name;
                          })
                        )
                      : []
                  }
                  onChange={(values) =>
                    this.props.createUxrsTraitsRequest(
                      uxr.id || "",
                      "brands",
                      values
                    )
                  }
                />
              </div>
            )}

            {account && account.settings.teams && (
              <div className="form-group w-50 p__sm">
                <label
                  className="text__xsm"
                  style={{ textTransform: "none", fontWeight: 500 }}
                >
                  Teams
                </label>
                <p className="text__xsm">
                  Specify the team(s) that are targeted in this research.
                </p>
                <MultiSelectInput
                  activeItems={uxr.teams}
                  name="teams"
                  items={
                    traits && traits["teams"]
                      ? Helpers.uniqueArray(
                          traits["teams"].map((t) => {
                            return t.name;
                          })
                        )
                      : []
                  }
                  onChange={(values) =>
                    this.props.createUxrsTraitsRequest(
                      uxr.id || "",
                      "teams",
                      values
                    )
                  }
                />
              </div>
            )}

            {account?.settings?.channels && (
              <div className="form-group w-50 p__sm">
                <label
                  className="text__xsm"
                  style={{ textTransform: "none", fontWeight: 500 }}
                >
                  Channels
                </label>
                <p className="text__xsm">
                  Specify the channel(s) that are targeted in this research.
                </p>
                <MultiSelectInput
                  activeItems={uxr.channels}
                  name="tags"
                  items={
                    traits && traits["channels"]
                      ? Helpers.uniqueArray(
                          traits["channels"].map((t) => {
                            return t.name;
                          })
                        )
                      : []
                  }
                  onChange={(values) =>
                    this.props.createUxrsTraitsRequest(
                      uxr.id || "",
                      "channels",
                      values
                    )
                  }
                />
              </div>
            )}

            <div className={`form-group w--50 p__sm`}>
              <label
                className="text__xsm"
                style={{ textTransform: "none", fontWeight: 500 }}
              >
                Tags
              </label>
              <p className="text__xsm">
                Specify the tag(s) that are targeted in this research.
              </p>
              <MultiSelectInput
                activeItems={uxr.tags}
                name="tags"
                items={
                  traits && traits["tags"]
                    ? Helpers.uniqueArray(
                        traits["tags"].map((t) => {
                          return t.name;
                        })
                      )
                    : []
                }
                onChange={(values) =>
                  this.props.createUxrsTraitsRequest(
                    uxr.id || "",
                    "tags",
                    values
                  )
                }
              />
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = ({ uxrTrait }: DataTypes.ApplicationState) => ({
  uxrTrait,
});

const mapDispatchToProps = {
  updateExperienceRequest,
  createUxrsTraitsRequest,
};

const connectedPage = connect(mapStateToProps, mapDispatchToProps)(Design);

export { connectedPage as Design };
