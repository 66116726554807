import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { ApplicationState } from "store/types";
import * as DataTypes from "store/types";
import { HTTPCodes } from "config/app";
import { clearAppErrors, showModal } from "store/app/actions";
import {
  createLoadingSelector,
  createErrorMessageSelector,
} from "store/selectors";
import { getShareIdeaBoardRequest } from "store/idea_board/actions";
import { createShareIdeaRequest } from "store/idea/actions";
import { getShareIdeasTraitsRequest } from "store/idea_trait/actions";
import { AppPage, AppPageProps } from "comps/pages";
import {
  Form,
  Input,
  InputTypes,
  ValidatorTypes,
  MultiSelect,
  Button,
} from "comps/form";
import { AuthService } from "utils/auth";
import * as Modals from "./comps/modals";
import { Helpers } from "utils";

import * as Nav from "ui/navs";
import "../../css/Screens.scss";
import { EditorInput } from "ui/editor_input/editor_input";
import Collapsible from "react-collapsible";
import { ArrowDownIcon } from "ui/arrow_down_icon";
import { PainsGainsSelectInput } from "ui";
import { convertFromRaw, EditorState } from "draft-js";

interface IProps extends AppPageProps {
  router: any;
  computedMatch: any;
  loading: boolean;
  saving: boolean;
  error: any;
  clearAppErrors: () => void;
  getShareIdeasTraitsRequest: (slug: string) => void;
  getShareIdeaBoardRequest: (
    slug: string,
    uuid: string,
    password?: string
  ) => void;
  createShareIdeaRequest: (
    slug: string,
    idea_board_id: string,
    body: any,
    password?: string
  ) => void;
  showModal: (component: React.ComponentType<any>, options: any) => void;
  idea_board: DataTypes.IdeaBoard | null;
  account: DataTypes.Account | null;
  ideaTrait: any;
}

interface IState {
  tactics: Array<DataTypes.PainGain>;
  isTacticsOpen: boolean;
  submitted: boolean;
}

class ShareIdeaBoard extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      tactics: [],
      isTacticsOpen: false,
      submitted: false,
    };
  }

  componentDidMount = () => {
    this.props.clearAppErrors();

    let pwd;
    const sharedPwd = AuthService.getSharePwd();

    if (sharedPwd) {
      const json = JSON.parse(sharedPwd);
      if (json.key === this.props.computedMatch.params.uuid) {
        pwd = json.pwd;
      } else {
        AuthService.clearSharePwd();
      }
    }

    const { account } = this.props;
    if (account) {
      this.props.getShareIdeasTraitsRequest(account.slug);
      this.props.getShareIdeaBoardRequest(
        account.slug,
        this.props.computedMatch.params.uuid,
        pwd || undefined
      );
    }
  };

  private onSubmit = (valid: boolean, dirty: boolean, data: any) => {
    const { account } = this.props;
    this.props.clearAppErrors();

    if (account && valid) {
      AuthService.setSharePwd(
        JSON.stringify({
          key: this.props.computedMatch.params.uuid,
          pwd: data.password.value,
        })
      );
      this.props.getShareIdeaBoardRequest(
        account.slug,
        this.props.computedMatch.params.uuid,
        data.password.value
      );
    }
  };

  private onSubmitIdea = (valid: boolean, dirty: boolean, data: any) => {
    const { account } = this.props;

    let problemStateValid = false;
    if (data.problem.value) {
      problemStateValid = EditorState.createWithContent(
        convertFromRaw(data.problem.value)
      )
        .getCurrentContent()
        .hasText();
    }

    let proposedStateValid = false;
    if (data.proposed.value) {
      proposedStateValid = EditorState.createWithContent(
        convertFromRaw(data.proposed.value)
      )
        .getCurrentContent()
        .hasText();
    }

    if (account && valid && problemStateValid && proposedStateValid) {
      const currentUser = AuthService.getActiveShareUser();

      if (currentUser) {
        const idea = {
          name: data.name.value,
          problem: data.problem.value,
          proposed: data.proposed.value,
          audiences: data.audiences.value,
          devices: data.devices.value,
          pages: data.pages.value,
          tactics: this.state.tactics,
        };

        const user = {
          first_name: currentUser.fn,
          last_name: currentUser.ln,
          email: currentUser.email,
        };

        this.saveIdea(user, idea);
      } else {
        const idea = {
          name: data.name.value,
          problem: data.problem.value,
          proposed: data.proposed.value,
          audiences: data.audiences.value,
          devices: data.devices.value,
          pages: data.pages.value,
        };

        this.props.showModal(Modals.SignInUser, {
          className: "SignInUser",
          idea: idea,
          onSubmit: this.saveIdea,
        });
      }
    } else {
      this.setState({ submitted: true });
    }
  };

  private saveIdea = (user: any, idea: any) => {
    const { account } = this.props;
    const sharedPwd = AuthService.getSharePwd();

    if (!account) {
      return;
    }

    let pwd;
    if (sharedPwd) {
      const json = JSON.parse(sharedPwd);
      if (json.key === this.props.computedMatch.params.uuid) {
        pwd = json.pwd;
      }
    }

    this.props.createShareIdeaRequest(
      account.slug,
      this.props.computedMatch.params.uuid,
      {
        idea: {
          ...idea,
          user,
        },
      },
      pwd || undefined
    );
  };

  private setTactic = (tactics: any) => {
    this.setState({
      tactics: tactics,
    });
  };

  private onSignOut = () => {
    AuthService.shareLogOut();
    this.setState({ ...this.state });
  };

  private tacticsCategory = () => {
    return this.state.tactics
      .map((tactic) => tactic.category)
      .filter((category, index, self) => self.indexOf(category) === index);
  };

  private getTacticsMessage = () => {
    const categories = this.tacticsCategory();
    if (categories.length === 0) {
      return null;
    }

    if (categories.length === 1) {
      switch (categories[0]) {
        case "anxiety":
          return (
            <span>
              "Anxiety" strategies can teach you so much about what motivates
              and/or stresses your customers. Spend some extra time to detail
              the “why” behind this strategy.
            </span>
          );
        case "mental effort":
          return (
            <span>
              "Mental Effort" strategies are the most frequently used Pains
              &amp; Gains<sup>&trade;</sup>, supporting the notion that making
              it simple for your customers is key.
            </span>
          );
        case "money":
          return (
            <span>
              There's more to resolving "Money" concerns than just offering
              discounts. Use these Pains &amp; Gains<sup>&trade;</sup> to
              alleviate money-related concerns without getting your finance
              department involved.
            </span>
          );
        case "value":
          return (
            <span>
              Communicating the "benefit to the user" is the most used "Value"
              based Pain &amp; Gain<sup>&trade;</sup>. Why should your customer
              say yes? Tell them!
            </span>
          );
        default:
          return null;
      }
    }

    return (
      <p>
        Pains &amp; Gains<sup>&trade;</sup> were designed to help you leverage
        knowledge from thousands of tests in our database. Use them to help you
        build your ideas.
      </p>
    );
  };

  render() {
    const { loading, idea_board, account, error, saving, ideaTrait } =
      this.props;

    const currentUser = AuthService.getActiveShareUser();

    if (error) {
      if (error.statusCode === HTTPCodes.UNAUTHORIZED) {
        return (
          <div className="ShareLogin">
            <div className="body">
              <img src="/img/logo.png" alt="illuminate" />
              <h3>This link is password protected</h3>
              <p>Enter the password below to view</p>
              <Form
                formFields={{
                  password: {
                    name: "password",
                    value: "",
                    validator: {
                      type: ValidatorTypes.REQUIRED,
                      messages: {
                        required:
                          "Please enter a password to view the case study.",
                      },
                    },
                  },
                }}
                onSubmit={this.onSubmit}
                FormComponent={({ fields: { password }, onChange }) => (
                  <div>
                    <div className="form-group">
                      <Input
                        field={password}
                        placeholder="Enter password"
                        type={InputTypes.PASSWORD}
                        className="form-control"
                        onChange={(e) => onChange(password, e.target.value)}
                      />
                    </div>
                    <Button
                      className="btn btn-primary"
                      text="view case study"
                      isLoading={loading}
                    />
                  </div>
                )}
              />
            </div>
          </div>
        );
      }

      if (error.statusCode === HTTPCodes.NOT_FOUND) {
        return (
          <div className="ShareLogin">
            <div className="body">
              <img src="/img/logo.png" alt="illuminate" />
              <h3>The link you have requested is invalid</h3>
              <p>Please check the link and try again</p>
            </div>
          </div>
        );
      }
      return null;
    }

    if (loading) return null;
    if (!idea_board || !account) return null;

    const useBBMethods = account?.settings.use_bb_methods;

    return (
      <div className="FullScreen">
        <div className="header mt-4">
          <img src="/img/logo_sm.svg" alt="illuminate" className="logo" />
          {currentUser ? (
            <Nav.ShareNav
              currentUser={currentUser}
              onSignOut={this.onSignOut}
            />
          ) : null}
        </div>
        <div className="form-body">
          <div className="content simple">
            <div className="title">
              <h2>{idea_board.name}</h2>
              <EditorInput
                content={idea_board.description}
                readOnly={true}
                onBlur={() => {}}
              />

              <div className="mt-2">
                {idea_board.worksheet && (
                  <Link to={`/ib/${idea_board.share?.uuid}/worksheet`}>
                    View Worksheet
                  </Link>
                )}
              </div>
            </div>
            <hr />
            <div className="title">
              <h2>Create Idea</h2>
              <p>Use the form below to create a new test idea!</p>
            </div>
            <Form
              formFields={{
                name: {
                  name: "name",
                  value: "",
                  validator: {
                    type: ValidatorTypes.REQUIRED,
                    messages: { required: "Please enter a valid idea name" },
                  },
                },
                problem: {
                  name: "problem",
                  value: "",
                  validator: {
                    type: ValidatorTypes.REQUIRED,
                    messages: {
                      required:
                        "Please tell us what problem or opportunity led you to this idea",
                    },
                  },
                },
                proposed: {
                  name: "proposed",
                  value: "",
                  validator: {
                    type: ValidatorTypes.REQUIRED,
                    messages: {
                      required:
                        "Please describe the variation you’d like to test",
                    },
                  },
                },
                audiences: {
                  name: "audiences",
                  value: idea_board.audiences ? idea_board.audiences : [],
                },
                devices: {
                  name: "devices",
                  value: idea_board.devices ? idea_board.devices : [],
                },
                pages: {
                  name: "pages",
                  value: idea_board.pages ? idea_board.pages : [],
                },
                themes: {
                  name: "themes",
                  value: [],
                },
                hypothesis: {
                  name: "hypothesis",
                  value: null,
                },
              }}
              onSubmit={this.onSubmitIdea}
              FormComponent={({
                fields: {
                  name,
                  problem,
                  proposed,
                  audiences,
                  devices,
                  pages,
                  hypothesis,
                  themes,
                },
                onChange,
              }) => (
                <div>
                  <section>
                    <div className="title">
                      <div className="step-circle">1</div>
                      <h4>overview</h4>
                    </div>
                    <div className="body">
                      <div className="form-group">
                        <label>name</label>
                        <Input
                          field={name}
                          className="form-control"
                          onChange={(e) => onChange(name, e.target.value)}
                        />
                        <p>
                          This is the name of the idea that will be displayed
                          across illuminate, so use something unique, yet
                          intuitive.
                        </p>
                      </div>
                      <div className="form-group">
                        <label>
                          What problem/opportunity do you want to address?
                        </label>
                        <EditorInput
                          submitted={this.state.submitted}
                          content={problem.value}
                          errorMessage="Please tell us what problem or opportunity led you to this idea"
                          required={true}
                          onBlur={(e: any) => onChange(problem, e)}
                          area={true}
                          readOnly={false}
                        />
                        <p>
                          What did you uncover that makes you think this test
                          idea will win? Whether it is a data point, an insight,
                          an observation, or just a hunch - let us know.
                        </p>
                      </div>
                      <div className="form-group">
                        <label>
                          What is the proposed variation that would address the
                          problem/opportunity you identified?
                        </label>
                        <EditorInput
                          submitted={this.state.submitted}
                          content={proposed.value}
                          onBlur={(e: any) => onChange(proposed, e)}
                          area={true}
                          required={true}
                          errorMessage="Please describe the variation you’d like to test"
                          readOnly={false}
                        />

                        <p>
                          Describe the variation you think could beat the
                          control. Please be as specific as possible.
                        </p>
                      </div>
                    </div>
                  </section>
                  <section>
                    <div className="title">
                      <div className="step-circle">2</div>
                      <h4>targeting</h4>
                    </div>
                    <div className="body">
                      <div className="form-group">
                        <label>audiences</label>
                        <MultiSelect
                          field={audiences}
                          options={
                            ideaTrait?.traits && ideaTrait?.traits["audiences"]
                              ? Helpers.uniqueArray(
                                  ideaTrait?.traits["audiences"].map(
                                    (t: any) => {
                                      return t.name;
                                    }
                                  )
                                )
                              : []
                          }
                          className="multiselect_input create"
                          onChange={(value) => onChange(audiences, value)}
                        />
                        <p>Specify what audience(s) your test should target.</p>
                      </div>
                      <div className="form-group">
                        <label>devices</label>
                        <MultiSelect
                          field={devices}
                          options={
                            ideaTrait?.traits && ideaTrait?.traits["devices"]
                              ? Helpers.uniqueArray(
                                  ideaTrait?.traits["devices"].map((t: any) => {
                                    return t.name;
                                  })
                                )
                              : []
                          }
                          className="multiselect_input create"
                          onChange={(value) => onChange(devices, value)}
                        />
                        <p>
                          Track which device(s) should be included in your test.
                        </p>
                      </div>
                      <div className="form-group">
                        <label>pages</label>
                        <MultiSelect
                          field={pages}
                          options={
                            ideaTrait?.traits && ideaTrait?.traits["pages"]
                              ? Helpers.uniqueArray(
                                  ideaTrait?.traits["pages"].map((t: any) => {
                                    return t.name;
                                  })
                                )
                              : []
                          }
                          className="multiselect_input create"
                          onChange={(value) => onChange(pages, value)}
                        />
                        <p>
                          Specify the page(s) that should display your test.
                        </p>
                      </div>
                    </div>
                  </section>

                  <section className="Advanced">
                    <Collapsible
                      triggerTagName="label"
                      onOpen={() => {
                        this.setState({ isTacticsOpen: true });
                      }}
                      onClose={() => {
                        this.setState({ isTacticsOpen: false });
                      }}
                      open={this.state.isTacticsOpen}
                      triggerWhenOpen={
                        <div className="title">
                          <div className="step-circle">3</div>
                          <h4 style={{ cursor: "pointer" }}>
                            Advanced Options{" "}
                            <ArrowDownIcon
                              className="rotate--upsidedown"
                              height="18"
                            />
                          </h4>
                        </div>
                      }
                      trigger={
                        <div className="title">
                          <div className="step-circle">3</div>
                          <h4 style={{ cursor: "pointer" }}>
                            Advanced Options <ArrowDownIcon height="18" />
                          </h4>
                        </div>
                      }
                    >
                      <div className="body">
                        <div className="form-group">
                          <label>
                            Brooks Bell Pains &amp; Gains<sup>&trade;</sup>
                          </label>
                          <PainsGainsSelectInput
                            activeItems={this.state.tactics}
                            name="pains_gains"
                            onChange={(value) => this.setTactic(value)}
                          />
                          <p>
                            Customers holding back due to Anxiety, Mental
                            Effort, Money, Time or Value? We've got you covered.
                          </p>
                          {this.state.tactics.length > 0 ? (
                            <p style={{ color: "#25be6f" }}>
                              <i className="fas fa-lightbulb" />{" "}
                              {this.getTacticsMessage()}
                            </p>
                          ) : null}
                        </div>

                        <div className="form-group">
                          {useBBMethods ? (
                            <label>
                              Whypothesis<sup>&reg;</sup> Customer Insights Tool
                            </label>
                          ) : (
                            <label>Hypothesis</label>
                          )}

                          <EditorInput
                            content={hypothesis.value}
                            onBlur={(e) => onChange(hypothesis, e)}
                            area={true}
                          />
                          <p>
                            Use a simple if/then statement to define the idea
                            you propose and the outcome your expect.
                          </p>
                        </div>

                        <div className="form-group">
                          <label>Theme</label>
                          <MultiSelect
                            field={themes}
                            options={
                              ideaTrait?.traits && ideaTrait.traits["themes"]
                                ? Helpers.uniqueArray(
                                    ideaTrait.traits["themes"].map((t: any) => {
                                      return t.name;
                                    })
                                  )
                                : []
                            }
                            className="multiselect_input create"
                            onChange={(value) => onChange(themes, value)}
                          />
                          <p>
                            Categorize your idea with a theme like a project
                            reference or the overarching concept of the idea.
                          </p>
                        </div>
                      </div>
                    </Collapsible>
                  </section>

                  <hr />
                  <Button
                    className="btn btn-primary"
                    text="save idea"
                    isLoading={saving}
                  />
                </div>
              )}
            />
          </div>
        </div>
      </div>
    );
  }
}

const loadingSelector = createLoadingSelector([
  "@@idea_board/GET_SHARE_IDEA_BOARD",
]);
const errorSelector = createErrorMessageSelector([
  "@@idea_board/GET_SHARE_IDEA_BOARD",
]);

const savingSelector = createLoadingSelector(["@@idea/CREATE_SHARE_IDEA"]);

const mapStateToProps = ({
  router,
  app,
  account,
  idea_board,
  ideaTrait,
}: ApplicationState) => ({
  router: router,
  account: account,
  loading: loadingSelector(app.requests),
  error: errorSelector(app.errors),
  idea_board: idea_board,
  saving: savingSelector(app.requests),
  ideaTrait,
});

const mapDispatchToProps = {
  clearAppErrors,
  getShareIdeaBoardRequest,
  createShareIdeaRequest,
  getShareIdeasTraitsRequest,
  showModal,
};

const connectedPage = connect(
  mapStateToProps,
  mapDispatchToProps
)(AppPage(ShareIdeaBoard));

export { connectedPage as ShareIdeaBoard };
