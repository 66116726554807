import React, { Component } from 'react';
import { FormField } from './';
import { Validator } from './validator';

interface Props {
  field: FormField;
  className: string;
  text?: string;
  TextComponent?: React.ComponentType<any>;
  onChange(e: any): any;
}

interface State {
  field: FormField;
}

class Checkbox extends Component<Props, State> {
  validator: Validator;

  constructor(props: Props) {
    super(props);
    this.validator = new Validator();
    this.state = {
      field: this.props.field
    };
  }

  static getDerivedStateFromProps = (nextProps: Props) => {
    return { field: nextProps.field };
  };

  private validate = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.validator.validateField(this.state.field);
    this.setState({ field: this.state.field });
  };

  public render() {
    const { className, text, TextComponent, onChange } = this.props;
    const { value, valid, error } = this.state.field;
    const cn = valid === false ? className + ' error' : className;

    return (
      <>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <input type="checkbox" className={cn} style={{ width: 20 }} onChange={onChange} checked={value === true ? true : false} onBlur={this.validate} />
          <p className="d-inline ml-2">{TextComponent ? <TextComponent /> : text}</p>
        </div>
        {valid === false && <div className="error">{error}</div>}
      </>
    );
  }
}

export { Checkbox };
