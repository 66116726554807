import { action } from 'typesafe-actions';
import { Dispatch } from 'redux';
import { ActionTypes } from './types';
import * as DataTypes from '../types';

interface RequestError {
  statusCode?: number | undefined;
  message: any;
}

export const getInsightsTraitRequestSaga = () => action(ActionTypes.GET_INSIGHTS_TRAITS_REQUEST, {});
export const getInsightsTraitsRequest = () => (dispatch: Dispatch) =>
  dispatch(action(ActionTypes.GET_INSIGHTS_TRAITS_REQUEST));
export const getInsightsTraitsSuccess = (response: DataTypes.ApiRequest<DataTypes.Trait>) =>
  action(ActionTypes.GET_INSIGHTS_TRAITS_SUCCESS, { response });
export const getInsightsTraitsError = (error: RequestError) => action(ActionTypes.GET_INSIGHTS_TRAITS_FAILURE, error);

export const createInsightsTraitsRequest = (insightId: string, traitName: string, values: string[]) => (dispatch: Dispatch) =>
  dispatch(action(ActionTypes.CREATE_INSIGHTS_TRAITS_REQUEST, { insightId, traitName, values }));
export const createInsightsTraitsSuccess = (response: DataTypes.ApiList<DataTypes.Insight>) =>
  action(ActionTypes.CREATE_INSIGHTS_TRAITS_SUCCESS, { response });
export const createInsightsTraitsError = (error: RequestError) =>
  action(ActionTypes.CREATE_INSIGHTS_TRAITS_FAILURE, error);