import React, { Component } from 'react';
import * as DataTypes from 'store/types';
import { history } from 'utils';
import 'css/Ideas.scss';
import { connect } from 'react-redux';
import { Avatar } from 'ui';
import TimeAgo from 'timeago-react';
import { showModal } from 'store/app/actions';
import * as Modals from './modals';
import { addThemesToIdeasRequest } from 'store/idea_management/actions';

interface IProps {
  account: DataTypes.Account | null;
  ideaManagement: any;
  ideaTrait: any;
  addThemesToIdeasRequest: (themes: any, ideas: any) => void;
  showModal: (component: React.ComponentType<any>, options: any) => void;
}

class AsideMultiselect extends Component<IProps> {
  private onSubmitAddTheme = (themes: any) => {
    const ideas = this.props.ideaManagement.selected.map((idea: any) => idea.id);

    this.props.addThemesToIdeasRequest(themes, ideas);
  }

  private addTheme = () => {
    if (this.props.ideaManagement.selected.length === 0) {
      return;
    }

    this.props.showModal(Modals.AddTheme, { ideaTrait: this.props.ideaTrait?.traits, onSubmit: this.onSubmitAddTheme });
  }

  render() {

    return (
      <div className="Idea">
        <section className="header">
          <div className="left">
          </div>
          <div className="right">
            <div className="controls">
            </div>
          </div>
        </section>
        <section className="title-bar">
          {this.props.ideaManagement.selected.length === 0 ? <h2>You haven't selected any ideas yet.</h2> : <h2>You have selected the following ideas:</h2>}
        </section>

        <section className="userInfo">
          <ul className="ListItem">
            <div className="column" >
              {this.props.ideaManagement.selected &&
                this.props.ideaManagement.selected.map((idea: DataTypes.Idea, idx: number) =>
                  <li
                    onClick={() => {}}
                    key={'idea_' + idea.id}
                    id={'idea_' + idea.id}
                    tabIndex={1}>
                    <div className="left">
                      <div className="flex align-center">
                        <div>
                          <Avatar user={idea.author} />
                        </div>
                      </div>
                    </div>
                    <div className="body">
                      <p className="title">{idea.name}</p>

                      <p className="description-aside">
                        {idea.problem?.blocks.map((block: any) => block.text).join(' ') || ''}
                      </p>

                      <div>
                        <p>
                          <small>
                            <TimeAgo datetime={idea.updated_at} live={false} />
                          </small>
                        </p>
                      </div>
                    </div>
                    <div className="right">
                      <div className="score">
                        {idea.star === true && <i className="fa fa-star active" />}
                        <React.Fragment>
                          <h3>{idea.score || '0.0'}</h3>
                          <p>score</p>
                        </React.Fragment>
                      </div>
                    </div>
                  </li>
                )
              }
            </div>
          </ul>
        </section>

        <section>
          <p>Select multiple ideas to:</p>

          <button style={{ fontSize: '13px', textAlign: 'left', padding: 0 }} className={`btn btn-link ${this.props.ideaManagement.selected.length === 0 ? 'disabled': ''}`} onClick={this.addTheme}>Add a Theme to Multiple Ideas at Once</button>
          <button style={{ fontSize: '13px', textAlign: 'left', padding: 0 }} className={`btn btn-link ${this.props.ideaManagement.selected.length <= 1 ? 'disabled': ''}`} onClick={() => history.push('/ideas/merge')}>Merge Multiple Ideas into one New Idea</button>
          <button style={{ fontSize: '13px', textAlign: 'left', padding: 0 }} className={`btn btn-link ${this.props.ideaManagement.selected.length === 0 ? 'disabled': ''}`} onClick={() => history.push('/tests/create/idea')}>Promote Multiple Ideas to a New Test</button>

        </section>
      </div>
    );
  }
}

const mapStateToProps = ({ router, account, ideaManagement, ideaTrait }: DataTypes.ApplicationState) => ({
  router,
  account,
  ideaManagement,
  ideaTrait,
});

const mapDispatchToProps = {
  showModal,
  addThemesToIdeasRequest,
};

const connectedPage = connect(mapStateToProps, mapDispatchToProps)(AsideMultiselect);

export { connectedPage as AsideMultiselect };