import React from 'react';
import styled from 'styled-components';

const BLOCK_SIZE_PX = '50px';

const StyledCell = styled.td`
  &&& {
    border-right: 1px lightgrey solid;
    border-left: ${(props: any) => (props.border ? '1px lightgrey solid' : 0)};
    min-width: ${BLOCK_SIZE_PX};
    padding: 10px;
  }
`;

const Cell = ({ style, border, children, className }: any) => (
  <StyledCell style={style} className={className || ''}>
    {children}
  </StyledCell>
);

export { Cell };
