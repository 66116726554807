import { Reducer } from "redux";
import * as DataTypes from "../types";

const initialState: DataTypes.Insight | null = null;

const reducer: Reducer<DataTypes.Insight | null> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    default: {
      return { ...state, ...action?.payload?.response };
    }
  }
};

export { reducer as insightReducer };
