import React, { Component, useCallback, useEffect } from "react";
import { connect } from "react-redux";
import { ApplicationState } from "store/types";
import { AppPageProps, PublicPage } from "comps/pages";
import { User } from "api";
import { history } from "utils";

const SocialLoginExecutor: React.FC<{ token: string }> = ({ token }) => {
  const executeSoccialLoginSuccess = useCallback(async () => {
    try {
      await User.createInvitedSocialUser(token);

      history.push("/");
    } catch (e) {
      setTimeout(executeSoccialLoginSuccess, 1000);
    }
  }, []);

  useEffect(() => {
    executeSoccialLoginSuccess();
  }, []);

  return null;
};

interface IProps extends AppPageProps {
  computedMatch: { params: any };
}

interface IState {}

class InviteSocialSuccess extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {};
  }

  async componentDidMount() {}

  render() {
    const { inviteToken } = this.props.computedMatch.params;

    if (!inviteToken) {
      return null;
    }

    return (
      <div>
        <SocialLoginExecutor token={inviteToken} />
      </div>
    );
  }
}

const mapStateToProps = ({}: ApplicationState) => ({});

const mapDispatchToProps = {};

const connectedPage = connect(
  mapStateToProps,
  mapDispatchToProps
)(PublicPage(InviteSocialSuccess));

export { connectedPage as InviteSocialSuccess };
