import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import * as API from "../../api";
import * as Actions from "./actions";
import * as IdeasActions from "../idea/actions";
import * as AccountActions from "../account/actions";
import { ActionTypes } from "./types";
import * as AppTypes from "../app/types";
import { history } from "utils";

function* getIdeaBoards(action: any) {
  try {
    // @ts-ignore
    const res = yield call(API.IdeaBoards.index, action.payload.options);

    yield put(Actions.getIdeaBoardsSuccess(res.body));
  } catch (error: any) {
    yield put(Actions.getIdeaBoardsError(error));
  }
}

function* getIdeaBoard(action: any) {
  try {
    // @ts-ignore
    const res = yield call(API.IdeaBoards.show, action.payload.board_id);
    yield put(IdeasActions.getIdeasRequest(action.payload.options));
    yield put(Actions.getIdeaBoardSuccess(res.body));
  } catch (error: any) {
    yield put(Actions.getIdeaBoardError(error));
  }
}

function* getShareIdeaBoard(action: any) {
  try {
    // @ts-ignore
    let res = yield call(
      API.Share.idea_board,
      action.payload.slug,
      action.payload.uuid,
      action.payload.password
    );
    yield put(Actions.getShareIdeaBoardSuccess(res.body));
  } catch (error: any) {
    yield put(Actions.getShareIdeaBoardError(error));
  }
}

function* updateIdeaBoardShare(action: any) {
  try {
    yield put({ type: AppTypes.App.MODAL_SAVING });
    // @ts-ignore
    const res = yield call(
      API.IdeaBoards.update,
      action.payload.id,
      action.payload.body
    );
    yield put(Actions.updateIdeaBoardShareSuccess(res.body));
    yield put({ type: AppTypes.App.MODAL_HIDE });
    yield put({
      type: AppTypes.App.ALERT_SHOW,
      content: "Idea board updated successfully",
    });
  } catch (error: any) {
    yield put(Actions.updateIdeaBoardShareError(error));
  }
}

function* createIdeaBoard(action: any) {
  try {
    yield put({ type: AppTypes.App.MODAL_SAVING });
    // @ts-ignore
    const res = yield call(API.IdeaBoards.create, action.payload.body);
    yield put(Actions.getIdeaBoardsRequest({ limit: 200, sort: "updated_at" }));
    yield put(Actions.createIdeaBoardSuccess(res.body));
    history.push(`/ideas/board/${res.body.id}`);
    yield put({ type: AppTypes.App.MODAL_HIDE });
    yield put({
      type: AppTypes.App.ALERT_SHOW,
      content: "Idea board created successfully",
    });
  } catch (error: any) {
    yield put(Actions.createIdeaBoardError(error));
  }
}

function* deleteIdeaBoard(action: any) {
  try {
    yield put({ type: AppTypes.App.MODAL_SAVING });
    // @ts-ignore
    const res = yield call(API.IdeaBoards.destroy, action.payload.id);

    yield put(Actions.getIdeaBoardsRequest({ limit: 200, sort: "updated_at" }));
    yield put(IdeasActions.getIdeasRequest());
    yield put(AccountActions.getAccountTraitsRequestSaga());
    yield put(Actions.deleteIdeaBoardSuccess(res.body));
    yield put({ type: AppTypes.App.MODAL_HIDE });
    history.push(`/ideas`);
    yield put({
      type: AppTypes.App.ALERT_SHOW,
      content: "Idea board deleted successfully",
    });
  } catch (error: any) {
    yield put(Actions.deleteIdeaBoardError(error));
  }
}

function* exportIdeas(action: any) {
  try {
    const { queryString, fields, boardId } = action.payload;

    // @ts-ignore
    const res = yield call(API.IdeaBoards.export, boardId, queryString, fields);
    yield put({
      type: AppTypes.App.ALERT_SHOW,
      content: "We are working on your export and will send via email shortly.",
    });
    yield put(Actions.exportIdeasSuccess(res.body));
    yield put({ type: AppTypes.App.MODAL_HIDE });
  } catch (error: any) {
    yield put(Actions.exportIdeasError(error));
  }
}

function* watchGetIdeaBoards() {
  yield takeEvery(ActionTypes.GET_IDEA_BOARDS_REQUEST, getIdeaBoards);
}

function* watchGetIdeaBoard() {
  yield takeEvery(ActionTypes.GET_IDEA_BOARD_REQUEST, getIdeaBoard);
}

function* watchCreateIdeaBoard() {
  yield takeEvery(ActionTypes.CREATE_IDEA_BOARD_REQUEST, createIdeaBoard);
}

function* watchDeleteIdeaBoard() {
  yield takeEvery(ActionTypes.DELETE_IDEA_BOARD_REQUEST, deleteIdeaBoard);
}

function* watchUpdateIdeaBoardShare() {
  yield takeEvery(
    ActionTypes.UPDATE_IDEA_BOARD_SHARE_REQUEST,
    updateIdeaBoardShare
  );
}

function* watchGetShareIdeaBoard() {
  yield takeEvery(ActionTypes.GET_SHARE_IDEA_BOARD_REQUEST, getShareIdeaBoard);
}

function* watchExportIdeas() {
  yield takeEvery(ActionTypes.EXPORT_IDEAS_REQUEST, exportIdeas);
}

function* ideaBoardsSaga() {
  yield all([
    fork(watchGetIdeaBoards),
    fork(watchCreateIdeaBoard),
    fork(watchGetIdeaBoard),
    fork(watchDeleteIdeaBoard),
    fork(watchUpdateIdeaBoardShare),
    fork(watchGetShareIdeaBoard),
    fork(watchExportIdeas),
  ]);
}

export default ideaBoardsSaga;
