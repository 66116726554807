export enum ActionTypes {
  CREATE_CUSTOM_DATA_VIZ_REQUEST = "@@customDataViz/CREATE_CUSTOM_DATA_VIZ_REQUEST",
  CREATE_CUSTOM_DATA_VIZ_SUCCESS = "@@customDataViz/CREATE_CUSTOM_DATA_VIZ_SUCCESS",
  CREATE_CUSTOM_DATA_VIZ_FAILURE = "@@customDataViz/CREATE_CUSTOM_DATA_VIZ_FAILURE",

  UPDATE_CUSTOM_DATA_VIZ_REQUEST = "@@customDataViz/UPDATE_CUSTOM_DATA_VIZ_REQUEST",
  UPDATE_CUSTOM_DATA_VIZ_SUCCESS = "@@customDataViz/UPDATE_CUSTOM_DATA_VIZ_SUCCESS",
  UPDATE_CUSTOM_DATA_VIZ_FAILURE = "@@customDataViz/UPDATE_CUSTOM_DATA_VIZ_FAILURE",

  DELETE_CUSTOM_DATA_VIZ_REQUEST = "@@customDataViz/DELETE_CUSTOM_DATA_VIZ_REQUEST",
  DELETE_CUSTOM_DATA_VIZ_SUCCESS = "@@customDataViz/DELETE_CUSTOM_DATA_VIZ_SUCCESS",
  DELETE_CUSTOM_DATA_VIZ_FAILURE = "@@customDataViz/DELETE_CUSTOM_DATA_VIZ_FAILURE",

  UPDATE_CUSTOM_DATA_VIZ_METRIC_AGGREGATION_REQUEST = "@@customDataViz/UPDATE_CUSTOM_DATA_VIZ_METRIC_AGGREGATION_REQUEST",
  UPDATE_CUSTOM_DATA_VIZ_METRIC_AGGREGATION_SUCCESS = "@@customDataViz/UPDATE_CUSTOM_DATA_VIZ_METRIC_AGGREGATION_SUCCESS",
  UPDATE_CUSTOM_DATA_VIZ_METRIC_AGGREGATION_FAILURE = "@@customDataViz/UPDATE_CUSTOM_DATA_VIZ_METRIC_AGGREGATION_FAILURE",
}
