import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import * as API from "../../api";
import * as Actions from "./actions";
import * as AppTypes from "../app/types";
import { ActionTypes } from "./types";

function* postSampleSize(action: any) {
  try {
    // @ts-ignore
    const res = yield call(API.Tools.sampleSize, action.payload.request);
    yield put(Actions.postSampleSizeSuccess(res.body.results));
  } catch (error: any) {
    yield put(Actions.postSampleSizeError(error));
    yield put({
      type: AppTypes.App.ALERT_SHOW,
      content: "Something went wrong.",
      error: true,
    });
  }
}

function* watchPostSampleSize() {
  yield takeEvery(ActionTypes.POST_SAMPLE_SIZE_REQUEST, postSampleSize);
}

function* calculatorSaga() {
  yield all([fork(watchPostSampleSize)]);
}

export default calculatorSaga;
