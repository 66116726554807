import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import * as API from "../../api";
import * as Actions from "./actions";
import { ActionTypes } from "./types";

function* getRattings(action: any) {
  try {
    // @ts-ignore
    const res = yield call(API.Rattings.list);

    yield put(Actions.getRattingsSuccess(res.body));
  } catch (error: any) {
    yield put(Actions.getRattingsError(error));
  }
}

function* createRatting(action: any) {
  try {
    // @ts-ignore
    const res = yield call(API.Rattings.create, action.payload);

    yield put(Actions.createRattingsSuccess(res.body));
    yield put(Actions.getRattingRequestSaga());
  } catch (error: any) {
    yield put(Actions.getRattingsError(error));
  }
}

function* watchGetRattings() {
  yield takeEvery(ActionTypes.GET_RATTING_REQUEST, getRattings);
}

function* watchCreateRatting() {
  yield takeEvery(ActionTypes.CREATE_RATTING_REQUEST, createRatting);
}

function* rattingSaga() {
  yield all([fork(watchGetRattings), fork(watchCreateRatting)]);
}

export default rattingSaga;
