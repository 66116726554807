import React, { Component } from "react";
import * as DataTypes from "store/types";
import {
  DragDropContext,
  Draggable,
  Droppable,
  DropResult,
  ResponderProvided,
} from "react-beautiful-dnd";
import { Avatar } from "ui";
import { Link } from "react-router-dom";
import TimeAgo from "timeago-react";

interface IProps {
  loading: boolean;
  uxrs: Array<DataTypes.Uxr> | null;
  selectedIdx: number;
  onSelect(itemIndex: number): void;
  onReorder(id: string, position: number): void;
  reorderable: boolean;
  onSelect(itemIndex: number): void;
}

class List extends Component<IProps> {
  onDragEnd = (result: DropResult, provided: ResponderProvided) => {
    const { draggableId: id, destination, reason } = result;

    if (reason !== "DROP" || !destination) {
      return;
    }

    const { index } = destination;

    this.props.onReorder(id, index);
  };

  getDragStyle = (isDragging: boolean, draggableStyle: any) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: "none",

    // change background colour if dragging
    //background: isDragging ? 'lightgreen' : 'grey',

    // styles we need to apply on draggables

    ...draggableStyle,
  });

  render() {
    const { loading, uxrs, selectedIdx } = this.props;

    if (loading) {
      return (
        <ul className="ListItem placeholder">
          <LoadingListItems count={8} />
        </ul>
      );
    }

    return (
      <ul className="ListItem">
        <DragDropContext onDragEnd={this.onDragEnd}>
          <Droppable droppableId="1">
            {(provided, snapshot) => (
              <div className="column" ref={provided.innerRef}>
                {provided.placeholder}

                {uxrs &&
                  uxrs.map((uxr: DataTypes.Uxr, idx: number) => (
                    <Draggable
                      isDragDisabled={!this.props.reorderable}
                      key={`dragabble_${uxr.id}`}
                      index={idx}
                      draggableId={uxr.id || ""}
                    >
                      {(provided, snapshot) => (
                        <div
                          className={
                            snapshot.isDragging ? "item dragging" : "item"
                          }
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          style={this.getDragStyle(
                            snapshot.isDragging,
                            provided.draggableProps.style
                          )}
                        >
                          <ListItem
                            key={"li_" + uxr.id}
                            uxr={uxr}
                            selected={idx === selectedIdx}
                            onItemSelect={() => this.props.onSelect(idx)}
                          />
                        </div>
                      )}
                    </Draggable>
                  ))}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </ul>
    );
  }
}

export { List };

interface IListItemProps {
  uxr: DataTypes.Uxr;
  selected: boolean;
  onItemSelect(): void;
}

const ListItem = ({ uxr, selected, onItemSelect }: IListItemProps) => {
  return (
    <li
      id={"experiment_" + uxr.id}
      className={selected === true ? "active" : ""}
      onClick={onItemSelect}
    >
      <div className="left">
        <Avatar user={uxr.user} />
      </div>
      <div className="body">
        <h4 className="title">
          <Link to={`/uxr/${uxr.id}`}>{uxr.name}</Link>
        </h4>
        <p className="desc">{uxr.description.blocks[0].text}</p>
        <p className="desc mt-2">
          Updated <TimeAgo datetime={uxr.updatedAt} live={false} />
        </p>
      </div>
      <div className="right">
        <i className={uxr.star === true ? "fa fa-star active" : "fa"} />
      </div>
    </li>
  );
};

interface ILoadingListItemProps {
  count: number;
}

const LoadingListItems = ({ count }: ILoadingListItemProps): any => {
  let elem = [];

  for (let i = 0; i < count; i++) {
    elem.push(
      <li key={"li_" + i}>
        <div className="body">
          <h4 className="title">&nbsp;</h4>
          <p></p>
          <p></p>
          <p></p>
        </div>
        <div className="right">
          <div className="status"></div>
          <p></p>
          <p></p>
        </div>
      </li>
    );
  }

  return elem;
};
