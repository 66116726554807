import React, { Component } from 'react';
import * as DataTypes from 'store/types';
import Collapsible from 'react-collapsible';
import { OrderIcon, OrderDirection } from 'ui/order_icon';

interface IProps {
  experiment: DataTypes.Experiment | null;
}

interface IState {
  arrowDirection: OrderDirection;
}

class ExperimentLifts extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      arrowDirection: OrderDirection.DESC,
    };
  }

  experienceWinner = () => {
    return this.props.experiment?.experiences.filter((experience) => experience.overall_winner)[0];
  }

  experimentLifts = () => {
    const winnerExperience = this.experienceWinner();

    const experiment = this.props.experiment;

    if (!experiment || !experiment.reports || experiment.reports.length === 0) {
      return null;
    }

    if (experiment.status !== 'winner') {
      return null;
    }

    const { reports } = experiment;

    return reports.map(({ id, metrics }) => {
      if (!metrics) {
        return null;
      }

      const results = metrics
      .filter(metric => metric.primary)
      .map((metric) => {
        return {
          id,
          name: metric.name,
          results: metric.results,
        };
      })[0];

      if (!results) {
        return null;
      }

      const winnerLift = results?.results?.filter((result) => result.experience_id === winnerExperience?.id)[0];

      if (!winnerLift) {
        return null;
      }

      const lilf = winnerLift.lift;

      if (!lilf) {
        return null;
      }

      return {
        id: results?.id,
        name: results?.name,
        lift: results?.results?.filter((result) => result.experience_id === winnerExperience?.id)[0].lift,
      }
    }).filter(experiment => experiment);
  }

  onOpen = () => {
    this.setState({
      arrowDirection: OrderDirection.ASC,
    });
  }

  onClose = () => {
    this.setState({
      arrowDirection: OrderDirection.DESC,
    });
  }

  render() {
    const experimentReports = this.experimentLifts();

    if (!experimentReports) {
      return null;
    }

    if (experimentReports.length === 1) {
      const experiment = experimentReports[0];

      return (
        <section className="ExperimentLifts">
           <p>
              <span className={ experiment?.lift && experiment?.lift > 0 ? 'success' : 'fail' }>{experiment?.lift}% </span>
              Lift in <strong>{experiment?.name}</strong>
            </p>
        </section>
      );
    }



  if (experimentReports.length > 0) {
    const firstResult = experimentReports.shift();

    return (
      <section className="ExperimentLifts">
        <Collapsible
          triggerTagName="label"
          onOpening={this.onOpen}
          onClosing={this.onClose}
          trigger={
          <p>
            <span className={ firstResult?.lift && firstResult.lift > 0 ? 'success' : 'fail' }>{firstResult?.lift}% </span>
            Lift in <strong>{firstResult?.name}</strong>
            <OrderIcon orderDirection={this.state.arrowDirection} className="arrow" />
          </p>
          } >
        {
          experimentReports?.map((experiment) => (
            <p key={experiment?.id} className="more-reports">
              <span className={ experiment?.lift && experiment?.lift > 0 ? 'success' : 'fail' }>{experiment?.lift}% </span>
              Lift in <strong>{experiment?.name}</strong>
            </p>
          ))
        }
        </Collapsible>
      </section>
    );
  }

  return null;
  }
}

export { ExperimentLifts }
