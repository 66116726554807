import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import * as API from "../../api";
import * as Actions from "./actions";
import { ActionTypes } from "./types";
import * as AppTypes from "../app/types";
import * as AccountActions from "../account/actions";
import * as IdeaTraitActions from "../idea_trait/actions";

function* addThemesToIdeas(action: any) {
  try {
    const { ideas, themes } = action.payload;

    // @ts-ignore
    const res = yield call(API.IdeaThemes.create, themes, ideas);

    yield put(Actions.addThemesToIdeasSuccess(res.body));
    yield put({ type: AppTypes.App.MODAL_HIDE });
    yield put(IdeaTraitActions.getIdeasTraitRequestSaga());
    yield put(AccountActions.getAccountSummaryRequest());
    yield put(Actions.setIdeaMultipleSelect(false));
  } catch (error: any) {
    yield put(Actions.addThemesToIdeasError(error));
  }
}

function* setIdeas(action: any) {
  try {
    const { idea, clear, callback } = action.payload;

    yield put(Actions.selectIdeaSuccess(idea, clear));
    if (callback) {
      callback();
    }
  } catch (error: any) {
    yield put(Actions.selectIdeaError(error));
  }
}

function* watchAddThemesToIdeas() {
  yield takeEvery(ActionTypes.ADD_THEMES_TO_IDEAS_REQUEST, addThemesToIdeas);
}

function* watchSetIdeas() {
  yield takeEvery(ActionTypes.SELECT_IDEA_REQUEST, setIdeas);
}

function* ideaManagementSaga() {
  yield all([fork(watchAddThemesToIdeas), fork(watchSetIdeas)]);
}

export default ideaManagementSaga;
