import React from 'react';
import Collapsible from 'react-collapsible';
import * as DataTypes from 'store/types';
import { ArrowDownIcon } from 'ui/arrow_down_icon';

interface IFilterGroupProps {
  name: string;
  traits: Array<DataTypes.Trait>;
  onCheck(checked: boolean, type: string, value: string): void;
  selectedItems: Array<string>;
  filterSettings?: { showMore: boolean };
  onUpdateFilterSettings(key: string, prop: string, value: string | boolean): void;
  collapsible?: boolean;
}

const FilterGroup = ({
  name,
  traits,
  onCheck,
  selectedItems,
  filterSettings,
  onUpdateFilterSettings,
  collapsible = false
}: IFilterGroupProps) => {

  if (traits.length <= 0) {
    return null;
  }

  const options = <ul>
    {traits
      .map((t, idx) => {
        if (filterSettings && filterSettings.showMore === false && idx > 4) {
          return null;
        }

        if (name === 'authors') {
          return (
            <li key={`filter_group_trait_${idx}`}>
              <label className="CheckBox">
                <input
                  type="checkbox"
                  defaultChecked={selectedItems.indexOf(t.name) >= 0 ? true : false}
                  onChange={(e) => onCheck(e.target.checked, 'author', t.id)} />
                <span className="checkmark"></span>
              </label>
              <p>{t.name}</p>
              <p>{t.total}</p>
            </li>
          )
        }

        return (
          <li key={`filter_group_trait_${idx}`}>
            <label className="CheckBox">
              <input
                type="checkbox"
                defaultChecked={selectedItems.indexOf(t.name) >= 0 ? true : false}
                onChange={(e) => onCheck(e.target.checked, name, t.name)} />
              <span className="checkmark"></span>
            </label>
            <p>{t.name}</p>
            <p>{t.total}</p>
          </li>
        );
      })}

    {traits.length > 5 && (
      <li>
        {filterSettings && filterSettings.showMore === false ? (
          <button
            className="btn btn-link"
            type="button"
            onClick={() => onUpdateFilterSettings(name, 'showMore', true)}>
                show more
          </button>
        ) : (
            <button
              className="btn btn-link"
              type="button" onClick={() => onUpdateFilterSettings(name, 'showMore', false)}>
                show less
            </button>
          )}
      </li>
    )}
  </ul>;

  if (collapsible) {
    return <Collapsible
      triggerTagName="h4"
      trigger={<span>{name === 'authors' ? 'Creators' : name} <ArrowDownIcon height="18" /></span>}
      triggerClassName="cursor--pointer"
      triggerOpenedClassName="cursor--pointer"
      className="Filter"
      openedClassName="Filter">
      {options}
    </Collapsible>
  }

  return (
    <div className="Filter">
      <h4>{name === 'authors' ? 'Creators' : name}</h4>
      { options }
    </div>
  );
};

export { FilterGroup }