import React, { Component } from "react";
import * as DataTypes from "store/types";

interface IProps {
  insight: DataTypes.Insight;
  saving: boolean;
  onSubmit(id: string): void;
}

class Delete extends Component<IProps> {
  render() {
    const { insight, onSubmit, saving } = this.props;

    return (
      <React.Fragment>
        <div className="header">
          <h3>DELETE INSIGHT</h3>
          <p>
            Are you sure you want to delete this insight? This action cannot be
            undone and this insight will be <strong>permanently deleted</strong>{" "}
            from illuminate.
          </p>
        </div>
        <div className="cntrl-bar">
          {saving === true ? (
            <button className="btn btn-danger" type="submit" disabled={true}>
              <i className="fas fa-circle-notch fa-spin" />
            </button>
          ) : (
            <button
              className="btn btn-danger"
              onClick={() => onSubmit(insight.id)}
            >
              Yes, delete this INSIGHT.
            </button>
          )}
        </div>
      </React.Fragment>
    );
  }
}

export { Delete };
