import React, { Component, useEffect, useState } from "react";
import { PublicPage, PublicPageProps } from "comps/pages";
import { User } from "api";
import { AccountLoader } from "ui/loaders";
import { Container, RotatingImages } from "./index.style";
import "../../../css/Base.scss";

interface IProps extends PublicPageProps {}

const ClickAcademyRedirectComponent: React.FC = () => {
  const [currentImage, setCurrentImage] = useState(1);
  const [accounts, setAccounts] = useState<any[]>([]);

  const fetchAccounts = async () => {
    const response = await User.accounts();

    setAccounts(response.body);

    if (response.body.length === 1) {
      window.location.assign(
        `${process.env.REACT_APP_HTTP_PROTO}://${response.body[0].slug}.${process.env.REACT_APP_BASE_URL}`
      );
    }
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      if (currentImage === 3) {
        setCurrentImage(1);
        return;
      }

      setCurrentImage(currentImage + 1);
    }, 5000);

    return () => clearTimeout(timer);
  });

  useEffect(() => {
    fetchAccounts();
  }, []);

  return (
    <Container>
      <AccountLoader loaded={accounts.length !== 0}>
        <div>
          <div className="header">
            <img src="img/logo.png" alt="logo" />

            <h1>
              Which one of your illuminate accounts would you like to access?
            </h1>
          </div>

          <ul>
            {accounts.map((account) => (
              <li
                key={`account-box-${account.slug}`}
                onClick={() => {
                  window.location.assign(
                    `${process.env.REACT_APP_HTTP_PROTO}://${account.slug}.${process.env.REACT_APP_BASE_URL}`
                  );
                }}
              >
                <p>{account.name}</p>
                <p className="slug">{account.slug}</p>
              </li>
            ))}
          </ul>
        </div>

        <RotatingImages>
          <img
            src="img/bg_1.jpg"
            alt="illuminage backgorund 1"
            className={currentImage === 1 ? "" : "hidden"}
          />
          <img
            src="img/bg_2.jpg"
            className={currentImage === 2 ? "" : "hidden"}
            alt="illuminage backgorund 2"
          />
          <img
            src="img/bg_3.jpg"
            alt="illuminage backgorund 3"
            className={currentImage === 3 ? "" : "hidden"}
          />
        </RotatingImages>
      </AccountLoader>
    </Container>
  );
};

class ClickAcademyRedirect extends Component<IProps> {
  render() {
    return <ClickAcademyRedirectComponent />;
  }
}

const page = PublicPage(ClickAcademyRedirect);

export { page as ClickAcademyRedirect };
