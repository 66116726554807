import { Reducer } from "redux";
import * as DataTypes from "../types";
import { ActionTypes } from "./types";

const initialState: DataTypes.Dashboard | null = {};

const reducer: Reducer<DataTypes.Dashboard | null> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case ActionTypes.GET_LEADER_BOARD_SUCCESS: {
      return { ...state, leaderBoard: action.payload };
    }
    case ActionTypes.GET_INSIGHT_BOARD_SUCCESS: {
      return { ...state, insightsBoard: action.payload };
    }
    case ActionTypes.GET_TODAY_BOARD_SUCCESS: {
      return { ...state, today: action.payload };
    }
    case ActionTypes.GET_DID_YOU_KNOW_SUCCESS: {
      return { ...state, didYouKnow: action.payload };
    }
    default: {
      return state;
    }
  }
};

export { reducer as dashboardReducer };
