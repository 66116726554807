import { action } from "typesafe-actions";
import { Dispatch } from "redux";
import { ActionTypes } from "./types";
import * as DataTypes from "../types";

interface RequestError {
  statusCode?: number | undefined;
  message: any;
}

export const createCustomDataVizRequest =
  (experimentId: string, body: any) => (dispatch: Dispatch) =>
    dispatch(
      action(ActionTypes.CREATE_CUSTOM_DATA_VIZ_REQUEST, {
        experimentId,
        body,
      })
    );
export const createCustomDataVizSuccess = (
  response: DataTypes.ApiRequest<DataTypes.CustomDataViz>
) => action(ActionTypes.CREATE_CUSTOM_DATA_VIZ_SUCCESS, { response });
export const createCustomDataVizError = (error: RequestError) =>
  action(ActionTypes.CREATE_CUSTOM_DATA_VIZ_FAILURE, error);

export const updateCustomDataVizRequest =
  (experimentId: string, customDataVizId: string, body: any) =>
  (dispatch: Dispatch) =>
    dispatch(
      action(ActionTypes.UPDATE_CUSTOM_DATA_VIZ_REQUEST, {
        experimentId,
        customDataVizId,
        body,
      })
    );
export const updateCustomDataVizSuccess = (
  response: DataTypes.ApiRequest<DataTypes.CustomDataViz>
) => action(ActionTypes.UPDATE_CUSTOM_DATA_VIZ_SUCCESS, { response });
export const updateCustomDataVizError = (error: RequestError) =>
  action(ActionTypes.UPDATE_CUSTOM_DATA_VIZ_FAILURE, error);

export const deleteCustomDataVizRequest =
  (experimentId: string, customDataVizId: string) => (dispatch: Dispatch) =>
    dispatch(
      action(ActionTypes.DELETE_CUSTOM_DATA_VIZ_REQUEST, {
        experimentId,
        customDataVizId,
      })
    );
export const deleteCustomDataVizSuccess = (
  response: DataTypes.ApiRequest<{}>
) => action(ActionTypes.DELETE_CUSTOM_DATA_VIZ_SUCCESS, { response });
export const deleteCustomDataVizError = (error: RequestError) =>
  action(ActionTypes.DELETE_CUSTOM_DATA_VIZ_FAILURE, error);

export const updateCustomDataVizMetricAggregationRequest =
  (experimentId: string, aggregationId: string, body: any) =>
  (dispatch: Dispatch) =>
    dispatch(
      action(ActionTypes.UPDATE_CUSTOM_DATA_VIZ_METRIC_AGGREGATION_REQUEST, {
        experimentId,
        aggregationId,
        body,
      })
    );
export const updateCustomDataVizMetricAggregationSuccess = (
  response: DataTypes.ApiRequest<DataTypes.CustomDataViz>
) =>
  action(ActionTypes.UPDATE_CUSTOM_DATA_VIZ_METRIC_AGGREGATION_SUCCESS, {
    response,
  });
export const updateCustomDataVizMetricAggregationError = (
  error: RequestError
) =>
  action(ActionTypes.UPDATE_CUSTOM_DATA_VIZ_METRIC_AGGREGATION_FAILURE, error);
