import React, { Component } from "react";
import * as DataTypes from "store/types";
import { connect } from "react-redux";
import {
  updateAreaOfFocusRequest,
  deleteAreaOfFocusRequest,
} from "store/uxr/actions";
import { DropdownControl, TextInput } from "ui";
import { EditorInput } from "ui/editor_input/editor_input";
import { showModal } from "../../../../../store/app/actions";
import * as Modals from "./modals";

interface IProps {
  area: DataTypes.AreaOfFocus | null;
  uxr: DataTypes.Uxr;
  selectArea: any;
  selectedAreaId: string | null;
  updateAreaOfFocusRequest(uxrId: string, areaId: string, body: any): void;
  deleteAreaOfFocusRequest(uxrId: string, areaId: string): void;
  showModal: (component: React.ComponentType<any>, options: any) => void;
}

interface IState {}

class AreasOfFocusItem extends Component<IProps, IState> {
  private onChange = (key: string, value: any) => {
    const { uxr, area } = this.props;

    this.props.updateAreaOfFocusRequest(uxr.id, area?.id || "", {
      [key]: value,
    });
  };

  private onDelete = (areaId: string) => {
    const { uxr } = this.props;

    this.props.showModal(Modals.DeleteAreaOfFocus, {
      uxr: uxr,
      onSubmit: () => {
        this.props.deleteAreaOfFocusRequest(uxr.id, areaId);
        this.props.selectArea(uxr.area_of_focus[0]);
      },
    });
  };

  render() {
    const { area } = this.props;

    if (!area) {
      return null;
    }

    return (
      <React.Fragment>
        <div className="Experience">
          <div className="header" style={{ height: "auto" }}>
            <div className="form-group w--full">
              <label>Theme:</label>
              <TextInput
                value={area.theme}
                onChange={(value) => this.onChange("theme", value)}
              >
                <p style={{ display: "table-cell" }}>
                  {area.theme || "New area of focus"}
                </p>
              </TextInput>
            </div>

            <div className="controls">
              <DropdownControl
                dropdownClass="w--200 left--166-neg"
                controlItems={[
                  {
                    name: "Delete Area of Focus",
                    icon: "far fa-trash-alt",
                    onClick: () => {
                      this.onDelete(area.id);
                    },
                    splitAbove: true,
                    className: "danger",
                  },
                ]}
              />
            </div>
          </div>

          <div className="form-group">
            <label className="normal">
              Explanations and/or Recommendations
            </label>
            {this.props.selectedAreaId && this.props.selectedAreaId !== "" && (
              <EditorInput
                content={area.explanation}
                onBlur={(e: any) => this.onChange("explanation", e)}
                area={true}
              />
            )}
          </div>
          <hr />
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = ({ router }: DataTypes.ApplicationState) => ({
  router,
});

const mapDispatchToProps = {
  updateAreaOfFocusRequest,
  deleteAreaOfFocusRequest,
  showModal,
};

const connectedPage = connect(
  mapStateToProps,
  mapDispatchToProps
)(AreasOfFocusItem);

export { connectedPage as AreasOfFocusItem };
