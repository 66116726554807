import { Reducer } from 'redux';
import * as DataTypes from '../types';
import { ActionTypes } from './types';

interface experimentTraitState {
  traits: { [key: string]: Array<DataTypes.Trait> };
}

const initialState: experimentTraitState | null = null;

const reducer: Reducer<experimentTraitState | null > = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.GET_EXPERIMENTS_TRAITS_SUCCESS: {
      return {
        ...state,
        traits: action.payload.response.traits,
      };
    }
    default: {
      return state;
    }
  }
};

export { reducer as experimentTraitReducer };
