import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import * as API from "../../api";
import * as Actions from "./actions";
import { ActionTypes } from "./types";

function* authSlack(action: any) {
  try {
    const { code, kind } = action.payload;

    yield call(API.Slack.auth, code, kind);
    yield put(Actions.listSlackRequestSaga());
  } catch (error: any) {
    yield put(Actions.authSlackError(error));
  }
}

function* listSlack(action: any) {
  try {
    // @ts-ignore
    const res = yield call(API.Slack.index);

    yield put(Actions.listSlackSuccess(res.body));
  } catch (error: any) {
    yield put(Actions.listSlackError(error));
  }
}

function* deleteSlack(action: any) {
  try {
    const { id } = action.payload;

    // @ts-ignore
    const res = yield call(API.Slack.delete, id);

    yield put(Actions.deleteSlackSuccess(res.body));
    yield put(Actions.listSlackRequestSaga());
  } catch (error: any) {
    yield put(Actions.deleteSlackError(error));
  }
}

function* saveSlackSettings(action: any) {
  try {
    const { domainName } = action.payload;

    // @ts-ignore
    const res = yield call(API.Slack.saveSettings, domainName);

    yield put(Actions.saveSlackSettingsSuccess(res.body));
    yield put(Actions.getSlackSettingsRequestSaga());
  } catch (error: any) {
    yield put(Actions.saveSlackSettingsError(error));
  }
}

function* getSlackSettings(action: any) {
  try {
    // @ts-ignore
    const res = yield call(API.Slack.getSettings);

    yield put(Actions.getSlackSettingsSuccess(res.body));
  } catch (error: any) {
    yield put(Actions.getSlackSettingsError(error));
  }
}

function* watchAuthSlack() {
  yield takeEvery(ActionTypes.AUTH_SLACK_REQUEST, authSlack);
}

function* watchListSlack() {
  yield takeEvery(ActionTypes.LIST_SLACK_REQUEST, listSlack);
}

function* watchDeleteSlack() {
  yield takeEvery(ActionTypes.DELETE_SLACK_REQUEST, deleteSlack);
}

function* watchSaveSlackSettings() {
  yield takeEvery(ActionTypes.SAVE_SLACK_SETTINGS_REQUEST, saveSlackSettings);
}

function* watchGetSlackSettings() {
  yield takeEvery(ActionTypes.GET_SLACK_SETTINGS_REQUEST, getSlackSettings);
}

function* ideasSaga() {
  yield all([
    fork(watchAuthSlack),
    fork(watchListSlack),
    fork(watchDeleteSlack),
    fork(watchSaveSlackSettings),
    fork(watchGetSlackSettings),
  ]);
}

export default ideasSaga;
