import React, { useState, useRef } from 'react';
import { handleOutsideClick, onEnterPress } from './utils';

interface IProps {
  onChange(value: string): void;
  placeholder?: string;
  value: string;
  disableOnBlur?: boolean;
}

const RichTextInput = ({ value, placeholder, onChange, disableOnBlur }: IProps) => {
  const ref = useRef<any>(null);

  const [edit, setEdit] = useState(false);
  const [newValue, setNewValue] = useState(value);

  onEnterPress(ref, () => {
    if (edit) {
      onChange(newValue);
      setEdit(!edit);
    }
  });

  handleOutsideClick(ref, (e: MouseEvent) => {
    if (edit) {
      if (disableOnBlur === undefined || disableOnBlur !== true) {
        onChange(newValue);
      }
      setEdit(!edit);
    }
  });

  const onValueChange = (value: string) => {
    setNewValue(value);
  };

  const onEdit = (edit: boolean) => {
    setEdit(edit);
  };

  React.useEffect(() => {
    if (edit) {
      ref.current.focus();
    }
  }, [edit]);


  if (edit === false) {
    if (value === null || value.trim() === '') {
      return (
        <p ref={ref} className="rich_text_input placeholder" onClick={(e) => onEdit(!edit)}>
          <i>{placeholder}</i>
        </p>
      );
    }
    return (
      <p ref={ref} className="rich_text_input" onClick={(e) => onEdit(!edit)}>
        {value}
      </p>
    );
  } else {
    return (
      <div className="rich_text_input">
        <textarea
          ref={ref}
          className="form-control"
          placeholder={placeholder}
          defaultValue={value}
          onChange={(e) => onValueChange(e.target.value)}></textarea>
      </div>
    );
  }
};

export { RichTextInput };
