import { Reducer } from 'redux';
import * as DataTypes from '../types';
import { ActionTypes } from './types';

const initialState: DataTypes.IdeaBoard | null = null;

const reducer: Reducer<DataTypes.IdeaBoard | null> = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.CREATE_IDEA_BOARD_SUCCESS: {
      return action.payload.response;
    }
    case ActionTypes.GET_SHARE_IDEA_BOARD_SUCCESS: {
      return action.payload.response;
    }
    default: {
      return state;
    }
  }
};

export { reducer as ideaBoardReducer };
