import React, { Component } from "react";
import moment from "moment";
import _ from "lodash";
import queryString from "query-string";
import { history } from "utils";
import { connect } from "react-redux";
import * as DataTypes from "store/types";
import { ApplicationState } from "store/types";
import {
  createInsightRequest,
  updateInsightRequest,
} from "store/insight/actions";
import { AppPage, AppPageProps } from "comps/pages";
import { Avatar } from "ui";
import "css/Screens.scss";

interface IProps extends AppPageProps {
  createInsightRequest: (
    insight: DataTypes.InsightRequest,
    cb?: (insight: DataTypes.Insight) => void
  ) => void;
  updateInsightRequest: (
    insight: DataTypes.Insight,
    showAlert: boolean
  ) => void;
  user: DataTypes.User | null;
  account: DataTypes.Account | null;
}

interface IState {
  insight: DataTypes.Insight | null;
  insightValue: string;
  isCreated: boolean;
}

const ESCAPE_KEY = 27;
const ENTER_KEY = 13;

class InsightCreate extends Component<IProps, IState> {
  state: IState = { insightValue: "", isCreated: false, insight: null };
  timeout?: NodeJS.Timeout;
  ref: any;

  constructor(props: IProps) {
    super(props);
    this.createInsight = _.debounce(this.createInsight, 500, {
      leading: true,
      trailing: false,
    });
    this.updateInsight = _.debounce(this.updateInsight, 500, {
      leading: true,
      trailing: false,
    });
  }

  keyDown = (event: any) => {
    if (event.keyCode === ENTER_KEY && (event.ctrlKey || event.shiftKey)) {
      event.preventDefault();
    }
  };

  keyUp = (event: any) => {
    if (event.keyCode === ESCAPE_KEY) {
      return this.navigateBackToReferer();
    }
    if (event.keyCode === ENTER_KEY && (event.ctrlKey || event.shiftKey)) {
      this.close();
    }
  };

  componentDidMount = () => {
    document.addEventListener("keydown", this.keyDown, false);
    document.addEventListener("keyup", this.keyUp, false);
    this.ref && this.ref.focus();
  };

  componentWillUnmount = () => {
    document.removeEventListener("keydown", this.keyDown, false);
    document.removeEventListener("keyUp", this.keyUp, false);
  };

  private close = () => {
    if (!this.state.insightValue) return this.navigateBackToReferer();
    if (this.timeout) clearTimeout(this.timeout);

    this.state.isCreated ? this.updateInsight() : this.createInsight();
    this.navigateBackToReferer();
  };

  private navigateBackToReferer = () => {
    const q = queryString.parse(this.props.location.search, {
      arrayFormat: "bracket",
    });

    if (q && q.experiment) {
      return history.push(`/tests/${q.experiment}/insights`);
    }

    if (q && q.uxr) {
      return history.push(`/uxr/${q.uxr}/insights`);
    }
    history.push("/insights");
  };
  createInsight = () => {
    if (!this.state.insightValue) return;

    let q = queryString.parse(this.props.location.search, {
      arrayFormat: "bracket",
    });
    const experiment_ids = [];
    const uxr_ids = [];
    if (q && q.experiment) experiment_ids.push(q.experiment?.toString());
    if (q && q.uxr) uxr_ids.push(q.uxr?.toString());

    const insight: DataTypes.InsightRequest = {
      value: this.state.insightValue,
      experiment_ids: experiment_ids,
      uxr_ids,
      key: false,
      audiences: [],
      devices: [],
      pages: [],
      tags: [],
    };

    const cb = (insight: DataTypes.Insight) =>
      this.setState({ isCreated: true, insight });
    this.props.createInsightRequest(insight, cb);
    this.setState({ isCreated: true });
  };

  updateInsight = () => {
    if (this.state.insight === null) return;
    const {
      insight: { id },
    } = this.state;
    this.props.updateInsightRequest(
      { id, value: this.state.insightValue } as DataTypes.Insight,
      true
    );
  };

  render() {
    const { account, user } = this.props;
    if (!account) return null;

    const { first_name, last_name } = user as DataTypes.User;
    return (
      <div className="FullScreen">
        <div className="header">
          <img src="/img/logo_sm.svg" alt="illuminate" className="logo" />
          <button type="button" className="btn btn-close" onClick={this.close}>
            <i className="fas fa-times" />
          </button>
        </div>
        <div className="form-body full">
          <div className="content Insight">
            <div className="Create">
              <div className="bar" />
              <textarea
                ref={(ref) => (this.ref = ref)}
                value={this.state.insightValue}
                onChange={(e) =>
                  this.setState({ insightValue: e.currentTarget.value })
                }
                rows={4}
                className="form-control"
                placeholder="Start typing to create your insight"
                onBlur={() =>
                  this.state.isCreated
                    ? this.updateInsight()
                    : this.createInsight()
                }
              />
            </div>
            <div className="userInfo">
              <div className="img">
                <Avatar user={user as DataTypes.User} />
              </div>
              <div className="desc">
                <p className="name">
                  Added by {first_name} {last_name}
                </p>
                <p>{moment().format("MMMM Do YYYY")}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ app, user, account }: ApplicationState) => ({
  account,
  user,
});

const mapDispatchToProps = {
  createInsightRequest,
  updateInsightRequest,
};

const connectedPage = connect(
  mapStateToProps,
  mapDispatchToProps
)(AppPage(InsightCreate));

export { connectedPage as InsightCreate };
