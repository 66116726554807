import { ValidatorRules } from "./validator";

export interface Option {
  name: string;
  value: string | boolean | number | any;
}

export interface FormField {
  name: string;
  value: string | boolean | any;
  validator?: ValidatorRules;
  valid?: boolean;
  error?: string;
  dirty?: boolean;
  onChange?: void;
}

export type FormFields = { [key: string]: FormField };

export enum InputTypes {
  TEXT = "text",
  PASSWORD = "password",
  NUMBER = "number",
}

export enum ButtonTypes {
  SUBMIT = "submit",
  BUTTON = "button",
}

export enum ValidatorTypes {
  NONE = "none",
  REQUIRED = "required",
  EMAIL = "email",
  BOOLEAN = "boolean",
  PASSWORD = "password",
  URL_SAFE = "urlSafe",
  CHECKBOX_REQUIRED = "checkboxRequired",
  MIN_LENGTH_3 = "minLength3",
  TEXT_BOX_REQUIRED = "textBoxRequired",
  CUSTOM = "customValidator",
}

export * from "./form";
export * from "./input";
export * from "./checkbox";
export * from "./button";
export * from "./textarea";
export * from "./date_picker";
export * from "./multi_select";
export * from "./select";
export * from "./radio_button";
