import React, { Component } from 'react';
import { connect } from 'react-redux';
import { AppPage, AppPageProps } from 'comps/pages';
import { Form, ValidatorTypes } from 'comps/form';
import { postSampleSizeRequest } from 'store/calculator/actions';
import { ApplicationState } from 'store/types';
import { showModal } from 'store/app/actions';
import { SampleSizeForm, SampleSizeResults } from './comps';
import { history } from 'utils';
import { Tips } from './comps/modal/tips';

interface IProps extends AppPageProps {
  postSampleSizeRequest: (data: any) => void;
  sampleSizeList: any;
  showModal: (component: React.ComponentType<any>, options: any) => void;
}

interface IState {
  durationType: RADIO;
}

enum RADIO {
  Proportions,
  Continuous,
}

class Calculator extends Component<IProps, IState> {
  state = { durationType: RADIO.Proportions };
  close = () => {
    history.goBack();
  };
  modalTips = () => {
    this.props.showModal(Tips, {});
  };
  onSubmit = (valid: boolean, dirty: boolean, data: any) => {
    if (!valid) return;
    const body = {
      duration_type: this.state.durationType === RADIO.Proportions ? 1 : 2,
      adjust: data.adjust.value ? 1 : 0,
      minimum_lift: data.minimum_lift.value / 100,
      power: data.power.value / 100,
      challengers: data.challengers.value,
      traffic: this.numberDecoder(data.traffic.value),
      mean: data.mean.value,
      standard_deviation: data.standard_deviation.value,
      response_rate: data.response_rate.value / 100,
    };

    this.props.postSampleSizeRequest(body);
  };

  numberDecoder = (largeNumber: string) => largeNumber.replace(',', '');

  render() {
    return (
      <div className="FullScreen Calculator">
        <div className="header">
          <img src="/img/logo_sm.svg" alt="illuminate" className="logo" />
          <button type="button" className="btn btn-close" onClick={this.close}>
            <i className="fas fa-times" />
          </button>
        </div>
        <div className="form-body full">
          <h1 className="title">Sample Size Calculator</h1>
          <p>
            Use this calculator to identify the right experiment criteria to fit your situation.{' '}
            <span className="link" style={ { color: '#2a88f2', cursor: 'pointer' } } onClick={this.modalTips}>
              Use these tips
            </span>{' '}
            for adjusting your inputs if your test duration is too long.
          </p>
          <div className="form-group">
            <div className="col-6">
              <Form
                formFields={{
                  challengers: {
                    name: 'challengers',
                    value: 4,
                    validator: {
                      type: ValidatorTypes.REQUIRED,
                      messages: {
                        required: 'Please select a variation.',
                      },
                    },
                  },
                  traffic: {
                    name: 'traffic',
                    value: '10,000',
                    validator: {
                      type: ValidatorTypes.REQUIRED,
                      messages: {
                        required: 'Please select a traffic amount.',
                      },
                    },
                  },
                  mean: {
                    name: 'mean',
                    value: 1.01,
                    validator: {
                      type: ValidatorTypes.REQUIRED,
                      messages: {
                        required: 'Please select a mean value.',
                      },
                    },
                  },
                  standard_deviation: {
                    name: 'standard_deviation',
                    value: 2.01,
                    validator: {
                      type: ValidatorTypes.REQUIRED,
                      messages: {
                        required: 'Please select a standard deviation.',
                      },
                    },
                  },
                  minimum_lift: {
                    name: 'minimum_lift',
                    value: 5.01,
                    validator: {
                      type: ValidatorTypes.REQUIRED,
                      messages: {
                        required: 'Please provide a minimum detectable effect.',
                      },
                    },
                  },
                  power: {
                    name: 'power',
                    value: 80,
                    validator: {
                      type: ValidatorTypes.REQUIRED,
                      messages: {
                        required: 'Please provide a power percentage.',
                      },
                    },
                  },
                  response_rate: {
                    name: 'response_rate',
                    value: 1,
                    validator: {
                      type: ValidatorTypes.REQUIRED,
                      messages: {
                        required: 'Please provide a response rate.',
                      },
                    },
                  },
                  adjust: {
                    name: 'adjust',
                    value: 1,
                  },
                }}
                onSubmit={this.onSubmit}
                FormComponent={({ fields: { challengers, traffic, mean, standard_deviation, minimum_lift, power, adjust, response_rate }, onChange }) => {
                  const radioOnChange = (durationType: RADIO) => {
                    this.setState({ durationType });
                  };
                  return (
                    <SampleSizeForm
                      durationType={this.state.durationType}
                      power={power}
                      traffic={traffic}
                      minimum_lift={minimum_lift}
                      mean={mean}
                      challengers={challengers}
                      standard_deviation={standard_deviation}
                      response_rate={response_rate}
                      adjust={adjust}
                      onChange={onChange}
                      radioOnChange={radioOnChange}
                    />
                  );
                }}
              />
            </div>
            <div className="col-6">
              <SampleSizeResults results={this.props.sampleSizeList} />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ calculator }: ApplicationState) => ({
  sampleSizeList: calculator,
});

const mapDispatchToProps = {
  postSampleSizeRequest,
  showModal,
};

const connectedPage = connect(mapStateToProps, mapDispatchToProps)(AppPage(Calculator));

export { connectedPage as Calculator };
