import React, { Component } from "react";
import { connect } from "react-redux";
import * as DataTypes from "store/types";
import { ApplicationState } from "store/types";
import { AppPage, AppPageProps } from "comps/pages";
import { createLoadingSelector } from "store/selectors";
import { showModal } from "store/app/actions";
import {
  updateAccountProgramGoalsRequest,
  updateProgramGoalRequest,
} from "store/account/actions";
import { GoalMetric } from "./goal_metric";
import { ProgramGoalDelete } from "./program_goal_delete";
import { AddGoal } from "./add_goal";
import {
  createProgramGoalTargetRequest,
  deleteProgramGoalTargetRequest,
} from "../../../../../store/program_goal_target/actions";
import { DeleteGoalTarget } from "./delete_goal_target";
import { UpdateGoal } from "./update_goal";

interface IProps extends AppPageProps {
  showModal: (component: React.ComponentType<any>, options: any) => void;
  collections: any;
  loading: boolean;
  updateLoading: boolean;
  createProgramGoalTargetRequest: (
    programGoalId: string,
    payload: Omit<DataTypes.ProgramGoalTarget, "id">
  ) => void;
  updateAccountProgramGoalsRequest: (body: any, redirect?: string) => void;
  updateProgramGoalRequest: (id: string, body: any) => void;
  deleteProgramGoalTargetRequest: (goalId: string, targetId: string) => void;
}

interface IState {}

class ProgramGoals extends Component<IProps, IState> {
  componentDidMount = () => {};

  private openGoalModal = () => {
    const { updateAccountProgramGoalsRequest, account } = this.props;
    this.props.showModal(AddGoal, {
      account,
      updateAccountProgramGoalsRequest,
    });
  };

  private openUpdateGoalModal = (programGoal: DataTypes.ProgramGoal) => {
    this.props.showModal(UpdateGoal, {
      updateProgramGoalRequest: this.props.updateProgramGoalRequest,
      programGoal,
      saving: this.props.updateLoading,
    });
  };

  private onDeleteProgramGoal = (programGoal: DataTypes.ProgramGoal) => {
    const { account } = this.props;
    if (account && account.program_goals) {
      this.props.showModal(ProgramGoalDelete, {
        programGoal: programGoal,
        onSubmit: this.deleteProgramGoal,
      });
    }
  };

  private deleteProgramGoal = (programGoal: DataTypes.ProgramGoal) => {
    const { account } = this.props;
    if (account && account.program_goals) {
      const program_goals = account.program_goals.filter(
        (goal) => goal.id !== programGoal.id
      );
      this.props.updateAccountProgramGoalsRequest({
        program_goals: program_goals,
      });
    }
  };

  private onDeleteTarget = (goalId: string, targetId: string) => {
    this.props.showModal(DeleteGoalTarget, {
      targetId: targetId,
      onSubmit: (targetId: string) => {
        this.props.deleteProgramGoalTargetRequest(goalId, targetId);
      },
    });
  };

  render() {
    const { loading, account } = this.props;

    if (loading) return null;
    return (
      <div className="Settings">
        <div className="container">
          <React.Fragment>
            <div className="header">
              <h4>Program Goals</h4>
              <p>
                What metric is your testing program ultimately responsible for
                influencing? You'll have the ability to specify the impact that
                each test has on this metric, and we'll track it for you on the
                dashboard.
              </p>
            </div>
            <section>
              {account?.program_goals.map((goal) => {
                return (
                  <React.Fragment key={goal.id}>
                    <GoalMetric
                      openUpdateGoalModal={this.openUpdateGoalModal}
                      onCreate={this.props.createProgramGoalTargetRequest}
                      programGoal={goal}
                      onDelete={this.onDeleteProgramGoal}
                      onDeleteTarget={(targetId: string) =>
                        this.onDeleteTarget(goal.id || "", targetId)
                      }
                    />
                  </React.Fragment>
                );
              })}
            </section>
            <div className="add-goal">
              <button
                className="btn btn-primary"
                type="button"
                onClick={this.openGoalModal}
              >
                Add New Program Goal
              </button>
            </div>
            <section></section>
          </React.Fragment>
        </div>
      </div>
    );
  }
}

const loadingSelector = createLoadingSelector([
  "@@account/GET_PENDING_INVITES",
]);

const updateSelector = createLoadingSelector([
  "@@account/UPDATE_PROGRAM_GOAL",
  "@@account/GET_ACCOUNT_REQUEST",
]);

const mapStateToProps = ({ app, collections, account }: ApplicationState) => ({
  loading: loadingSelector(app.requests),
  updateLoading: updateSelector(app.requests),
  collections,
  account,
});

const mapDispatchToProps = {
  showModal,
  updateAccountProgramGoalsRequest,
  updateProgramGoalRequest,
  createProgramGoalTargetRequest,
  deleteProgramGoalTargetRequest,
};

const connectedPage = connect(
  mapStateToProps,
  mapDispatchToProps
)(AppPage(ProgramGoals));

export { connectedPage as ProgramGoals };
