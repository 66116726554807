export enum ActionTypes {
  CREATE_IDEA_BOARD_REQUEST = '@@idea_board/CREATE_IDEA_BOARD_REQUEST',
  CREATE_IDEA_BOARD_SUCCESS = '@@idea_board/CREATE_IDEA_BOARD_SUCCESS',
  CREATE_IDEA_BOARD_FAILURE = '@@idea_board/CREATE_IDEA_BOARD_FAILURE',

  DELETE_IDEA_BOARD_REQUEST = '@@idea_board/DELETE_IDEA_BOARD_REQUEST',
  DELETE_IDEA_BOARD_SUCCESS = '@@idea_board/DELETE_IDEA_BOARD_SUCCESS',
  DELETE_IDEA_BOARD_FAILURE = '@@idea_board/DELETE_IDEA_BOARD_FAILURE',

  GET_IDEA_BOARDS_REQUEST = '@@idea_board/GET_IDEA_BOARDS_REQUEST',
  GET_IDEA_BOARDS_SUCCESS = '@@idea_board/GET_IDEA_BOARDS_SUCCESS',
  GET_IDEA_BOARDS_FAILURE = '@@idea_board/GET_IDEA_BOARDS_FAILURE',

  GET_IDEA_BOARD_REQUEST = '@@idea_board/GET_IDEA_BOARD_REQUEST',
  GET_IDEA_BOARD_SUCCESS = '@@idea_board/GET_IDEA_BOARD_SUCCESS',
  GET_IDEA_BOARD_FAILURE = '@@idea_board/GET_IDEA_BOARD_FAILURE',

  CREATE_WORKSHEET_REQUEST = '@@idea_board/CREATE_WORKSHEET_REQUEST',
  CREATE_WORKSHEET_SUCCESS = '@@idea_board/CREATE_WORKSHEET_SUCCESS',
  CREATE_WORKSHEET_FAILURE = '@@idea_board/CREATE_WORKSHEET_FAILURE',

  GET_WORKSHEET_REQUEST = '@@idea_board/GET_WORKSHEET_REQUEST',
  GET_WORKSHEET_SUCCESS = '@@idea_board/GET_WORKSHEET_SUCCESS',
  GET_WORKSHEET_FAILURE = '@@idea_board/GET_WORKSHEET_FAILURE',

  UPDATE_IDEA_BOARD_SHARE_REQUEST = '@@idea_board/UPDATE_IDEA_BOARD_SHARE_REQUEST',
  UPDATE_IDEA_BOARD_SHARE_SUCCESS = '@@idea_board/UPDATE_IDEA_BOARD_SHARE_SUCCESS',
  UPDATE_IDEA_BOARD_SHARE_FAILURE = '@@idea_board/UPDATE_IDEA_BOARD_SHARE_FAILURE',

  GET_SHARE_IDEA_BOARD_REQUEST = '@@idea_board/GET_SHARE_IDEA_BOARD_REQUEST',
  GET_SHARE_IDEA_BOARD_SUCCESS = '@@idea_board/GET_SHARE_IDEA_BOARD_SUCCESS',
  GET_SHARE_IDEA_BOARD_FAILURE = '@@idea_board/GET_SHARE_IDEA_BOARD_FAILURE',

  EXPORT_IDEAS_REQUEST = "@@idea_board/EXPORT_IDEAS_REQUEST",
  EXPORT_IDEAS_SUCCESS = "@@idea_board/EXPORT_IDEAS_SUCCESS",
  EXPORT_IDEAS_FAILURE = "@@idea_board/EXPORT_IDEAS_FAILURE"
}
