export enum ActionTypes {
  GET_EXPERIMENTS_REQUEST = "@@experiment/GET_EXPERIMENTS_REQUEST",
  GET_EXPERIMENTS_SUCCESS = "@@experiment/GET_EXPERIMENTS_SUCCESS",
  GET_EXPERIMENTS_FAILURE = "@@experiment/GET_EXPERIMENTS_FAILURE",

  GET_EXPERIMENT_REQUEST = "@@experiment/GET_EXPERIMENT_REQUEST",
  GET_EXPERIMENT_SUCCESS = "@@experiment/GET_EXPERIMENT_SUCCESS",
  GET_EXPERIMENT_FAILURE = "@@experiment/GET_EXPERIMENT_FAILURE",

  INIT_EXP_CREATE_REQUEST = "@@experiment/INIT_EXP_CREATE_REQUEST",
  INIT_EXP_CREATE_SUCCESS = "@@experiment/INIT_EXP_CREATE_SUCCESS",
  INIT_EXP_CREATE_FAILURE = "@@experiment/INIT_EXP_CREATE_FAILURE",

  CREATE_EXPERIMENT_REQUEST = "@@experiment/CREATE_EXPERIMENT_REQUEST",
  CREATE_EXPERIMENT_SUCCESS = "@@experiment/CREATE_EXPERIMENT_SUCCESS",
  CREATE_EXPERIMENT_FAILURE = "@@experiment/CREATE_EXPERIMENT_FAILURE",

  CREATE_EXPERIMENT_AB_REQUEST = "@@experiment/CREATE_EXPERIMENT_AB_REQUEST",
  CREATE_EXPERIMENT_AB_SUCCESS = "@@experiment/CREATE_EXPERIMENT_AB_SUCCESS",
  CREATE_EXPERIMENT_AB_FAILURE = "@@experiment/CREATE_EXPERIMENT_AB_FAILURE",

  CREATE_EXPERIMENT_ADOBE_REQUEST = "@@experiment/CREATE_EXPERIMENT_ADOBE_REQUEST",
  CREATE_EXPERIMENT_ADOBE_SUCCESS = "@@experiment/CREATE_EXPERIMENT_ADOBE_SUCCESS",
  CREATE_EXPERIMENT_ADOBE_FAILURE = "@@experiment/CREATE_EXPERIMENT_ADOBE_FAILURE",

  UPDATE_EXPERIMENT_REQUEST = "@@experiment/UPDATE_EXPERIMENT_REQUEST",
  UPDATE_EXPERIMENT_SUCCESS = "@@experiment/UPDATE_EXPERIMENT_SUCCESS",
  UPDATE_EXPERIMENT_FAILURE = "@@experiment/UPDATE_EXPERIMENT_FAILURE",

  UPDATE_AB_EXPERIMENT_REQUEST = "@@experiment/UPDATE_AB_EXPERIMENT_REQUEST",
  UPDATE_AB_EXPERIMENT_SUCCESS = "@@experiment/UPDATE_AB_EXPERIMENT_SUCCESS",
  UPDATE_AB_EXPERIMENT_FAILURE = "@@experiment/UPDATE_AB_EXPERIMENT_FAILURE",

  DELETE_EXPERIMENT_REQUEST = "@@experiment/DELETE_EXPERIMENT_REQUEST",
  DELETE_EXPERIMENT_SUCCESS = "@@experiment/DELETE_EXPERIMENT_SUCCESS",
  DELETE_EXPERIMENT_FAILURE = "@@experiment/DELETE_EXPERIMENT_FAILURE",

  DELETE_EXPERIENCE_REQUEST = "@@experiment/DELETE_EXPERIENCE_REQUEST",
  DELETE_EXPERIENCE_SUCCESS = "@@experiment/DELETE_EXPERIENCE_SUCCESS",
  DELETE_EXPERIENCE_FAILURE = "@@experiment/DELETE_EXPERIENCE_FAILURE",

  CREATE_REPORT_REQUEST = "@@experiment/CREATE_REPORT_REQUEST",
  CREATE_REPORT_SUCCESS = "@@experiment/CREATE_REPORT_SUCCESS",
  CREATE_REPORT_FAILURE = "@@experiment/CREATE_REPORT_FAILURE",

  DELETE_REPORT_REQUEST = "@@experiment/DELETE_REPORT_REQUEST",
  DELETE_REPORT_SUCCESS = "@@experiment/DELETE_REPORT_SUCCESS",
  DELETE_REPORT_FAILURE = "@@experiment/DELETE_REPORT_FAILURE",

  UPDATE_REPORT_STATS_REQUEST = "@@experiment/UPDATE_REPORT_STATS_REQUEST",
  UPDATE_REPORT_STATS_SUCCESS = "@@experiment/UPDATE_REPORT_STATS_SUCCESS",
  UPDATE_REPORT_STATS_FAILURE = "@@experiment/UPDATE_REPORT_STATS_FAILURE",

  UPDATE_REPORT_REQUEST = "@@experiment/UPDATE_REPORT_REQUEST",
  UPDATE_REPORT_SUCCESS = "@@experiment/UPDATE_REPORT_SUCCESS",
  UPDATE_REPORT_FAILURE = "@@experiment/UPDATE_REPORT_FAILURE",

  LINK_INSIGHTS_REQUEST = "@@experiment/LINK_INSIGHTS_REQUEST",
  LINK_INSIGHTS_SUCCESS = "@@experiment/LINK_INSIGHTS_SUCCESS",
  LINK_INSIGHTS_FAILURE = "@@experiment/LINK_INSIGHTS_FAILURE",

  UNLINK_INSIGHTS_REQUEST = "@@experiment/UNLINK_INSIGHTS_REQUEST",
  UNLINK_INSIGHTS_SUCCESS = "@@experiment/UNLINK_INSIGHTS_SUCCESS",
  UNLINK_INSIGHTS_FAILURE = "@@experiment/UNLINK_INSIGHTS_FAILURE",

  GET_CASE_STUDY_REQUEST = "@@experiment/GET_CASE_STUDY_REQUEST",
  GET_CASE_STUDY_SUCCESS = "@@experiment/GET_CASE_STUDY_SUCCESS",
  GET_CASE_STUDY_FAILURE = "@@experiment/GET_CASE_STUDY_FAILURE",

  UPDATE_CASE_STUDY_REQUEST = "@@experiment/UPDATE_CASE_STUDY_REQUEST",
  UPDATE_CASE_STUDY_SUCCESS = "@@experiment/UPDATE_CASE_STUDY_SUCCESS",
  UPDATE_CASE_STUDY_FAILURE = "@@experiment/UPDATE_CASE_STUDY_FAILURE",

  GET_SHARE_CASE_STUDY_REQUEST = "@@experiment/GET_SHARE_CASE_STUDY_REQUEST",
  GET_SHARE_CASE_STUDY_SUCCESS = "@@experiment/GET_SHARE_CASE_STUDY_SUCCESS",
  GET_SHARE_CASE_STUDY_FAILURE = "@@experiment/GET_SHARE_CASE_STUDY_FAILURE",

  CREATE_REPORT_NEXT_STEP_REQUEST = "@@experiment/CREATE_REPORT_NEXT_STEP_REQUEST",
  CREATE_REPORT_NEXT_STEP_SUCCESS = "@@experiment/CREATE_REPORT_NEXT_STEP_SUCCESS",
  CREATE_REPORT_NEXT_STEP_FAILURE = "@@experiment/CREATE_REPORT_NEXT_STEP_FAILURE",

  CLEAR_EXPERIMENTS = "@@experiment/CLEAR_EXPERIMENTS",

  PRE_UPLOAD_REPORT_REQUEST = "@@experiment/PRE_UPLOAD_REPORT_REQUEST",
  PRE_UPLOAD_REPORT_SUCCESS = "@@experiment/PRE_UPLOAD_REPORT_SUCCESS",
  PRE_UPLOAD_REPORT_FAILURE = "@@experiment/PRE_UPLOAD_REPORT_FAILURE",

  UPLOAD_REPORT_REQUEST = "@@experiment/UPLOAD_REPORT_REQUEST",
  UPLOAD_REPORT_SUCCESS = "@@experiment/UPLOAD_REPORT_SUCCESS",
  UPLOAD_REPORT_FAILURE = "@@experiment/UPLOAD_REPORT_FAILURE",

  ADD_UPLOAD_REQUEST = "@@experiment/ADD_UPLOAD_REQUEST",
  ADD_UPLOAD_SUCCESS = "@@experiment/ADD_UPLOAD_SUCCESS",
  ADD_UPLOAD_FAILURE = "@@experiment/ADD_UPLOAD_FAILURE",

  DELETE_UPLOAD_REQUEST = "@@experiment/DELETE_UPLOAD_REQUEST",
  DELETE_UPLOAD_SUCCESS = "@@experiment/DELETE_UPLOAD_SUCCESS",
  DELETE_UPLOAD_FAILURE = "@@experiment/DELETE_UPLOAD_FAILURE",

  UPDATE_ADOBE_EXPERIMENT_REQUEST = "@@experiment/UPDATE_ADOBE_EXPERIMENT_REQUEST",
  UPDATE_ADOBE_EXPERIMENT_SUCCESS = "@@experiment/UPDATE_ADOBE_EXPERIMENT_SUCCESS",
  UPDATE_ADOBE_EXPERIMENT_FAILURE = "@@experiment/UPDATE_ADOBE_EXPERIMENT_FAILURE",

  REORDER_REQUEST = "@@experiment/REORDER_REQUEST",
  REORDER_SUCCESS = "@@experiment/REORDER_SUCCESS",
  REORDER_FAILURE = "@@experiment/REORDER_FAILURE",

  UPLOAD_INSIGHTS_REQUEST = "@@experiment/UPLOAD_INSIGHTS_REQUEST",
  UPLOAD_INSIGHTS_SUCCESS = "@@experiment/UPLOAD_INSIGHTS_SUCCESS",
  UPLOAD_INSIGHTS_FAILURE = "@@experiment/UPLOAD_INSIGHTS_FAILURE",

  ADD_PROGRAM_GOAL_REQUEST = "@@experiment/ADD_PROGRAM_GOAL_REQUEST",
  ADD_PROGRAM_GOAL_SUCCESS = "@@experiment/ADD_PROGRAM_GOAL_SUCCESS",
  ADD_PROGRAM_GOAL_FAILURE = "@@experiment/ADD_PROGRAM_GOAL_FAILURE",

  DELETE_PROGRAM_GOAL_REQUEST = "@@experiment/DELETE_PROGRAM_GOAL_REQUEST",
  DELETE_PROGRAM_GOAL_SUCCESS = "@@experiment/DELETE_PROGRAM_GOAL_SUCCESS",
  DELETE_PROGRAM_GOAL_FAILURE = "@@experiment/DELETE_PROGRAM_GOAL_FAILURE",

  UPDATE_PROGRAM_GOAL_REQUEST = "@@experiment/UPDATE_PROGRAM_GOAL_REQUEST",
  UPDATE_PROGRAM_GOAL_SUCCESS = "@@experiment/UPDATE_PROGRAM_GOAL_SUCCESS",
  UPDATE_PROGRAM_GOAL_FAILURE = "@@experiment/UPDATE_PROGRAM_GOAL_FAILURE",
}
