import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import * as API from "../../api";
import * as Actions from "./actions";
import * as ExperimentActions from "../experiment/actions";
import { ActionTypes } from "./types";
import * as AppTypes from "../app/types";

function* updateReport(action: any) {
  try {
    const { experimentId, reportId, body } = action.payload;

    // @ts-ignore
    const res = yield call(API.Reports.update, experimentId, reportId, {
      report: body,
    });

    yield put(Actions.updateReportSuccess(res.body));
    yield put(ExperimentActions.updateExperimentSuccess(res.body));
    yield put({
      type: AppTypes.App.ALERT_SHOW,
      content: "Report updated successfully",
    });
  } catch (error: any) {
    yield put(Actions.updateReportSuccess(error));
  }
}

function* watchUpdateReport() {
  yield takeEvery(ActionTypes.UPDATE_REPORT_REQUEST, updateReport);
}

function* reportSummarySaga() {
  yield all([fork(watchUpdateReport)]);
}

export default reportSummarySaga;
