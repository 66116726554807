import { appConfig } from "../config/app";
import decode from "jwt-decode";

class AuthService {
  static isLoggedIn = (): boolean => {
    const token = localStorage.getItem(appConfig.USER_TOKEN);

    if (token === null || token === "undefined") {
      return false;
    }

    const expired = AuthService.isTokenExpired(token);
    return !expired;
  };

  static getToken = (): string | undefined => {
    const token = localStorage.getItem(appConfig.USER_TOKEN);
    if (token === null) return undefined;
    return token;
  };

  static logIn = (jwt: string): boolean => {
    localStorage.setItem(appConfig.USER_TOKEN, jwt);
    return true;
  };

  static refeshToken = (newToken: string) => {
    const token = localStorage.getItem(appConfig.USER_TOKEN);
    if (token === null) return false;
    let date = new Date(0);
    date.setHours(date.getHours() - 1);
    const expired = AuthService.isTokenExpired(token, date);
    if (expired) AuthService.logIn(newToken);
  };

  static logOut = (): boolean => {
    localStorage.removeItem(appConfig.USER_TOKEN);
    localStorage.removeItem(appConfig.ILLUMINATE_TOKEN);
    return true;
  };

  static getActiveUser = (): any | undefined => {
    const jwt = localStorage.getItem(appConfig.ILLUMINATE_TOKEN);
    if (jwt === null) return undefined;
    const token = decode<{
      id: string;
      fn: string;
      ln: string;
      e: string;
      r: string;
      a: string;
      i: string;
    }>(jwt);
    if (token === null) return undefined;
    return {
      id: token.id,
      first_name: token.fn,
      last_name: token.ln,
      email: token.e,
      role: token.r,
      account_id: token.a,
      image_url: token.i,
    };
  };

  static getActiveShareUser = (): any | undefined => {
    const jwt = localStorage.getItem(appConfig.USER_TOKEN);

    if (jwt && jwt !== "undefined") {
      const token = decode<{
        exp: number;
        iat: number;
        auth_time: number;
        jti: string;
        iss: string;
        aud: string;
        sub: string;
        typ: string;
        azp: string;
        nonce: string;
        session_state: string;
        acr: string;
        "allowed-origins": string[];
        resource_access: any;
        scope: string;
        sid: string;
        email_verified: boolean;
        roles: string[];
        name: string;
        preferred_username: string;
        given_name: string;
        family_name: string;
        email: string;
      }>(jwt);

      if (token !== null) {
        return {
          id: token.sub,
          first_name: token.given_name,
          last_name: token.family_name,
          email: token.email,
          role: token.roles,
          // account_id: token.a,
          // image_url: token.i,
        };
      }
    }

    // Check if we have a share user
    const share_user = localStorage.getItem(appConfig.SHARE_USER);
    if (share_user === null) {
      return undefined;
    }
    const su = JSON.parse(share_user);
    return { first_name: su.fn, last_name: su.ln, email: su.email };
  };

  static shareLogIn = (
    first_name: string,
    last_name: string,
    email: string
  ): boolean => {
    localStorage.setItem(
      appConfig.SHARE_USER,
      JSON.stringify({ fn: first_name, ln: last_name, email: email })
    );
    return true;
  };

  static shareLogOut = (): boolean => {
    localStorage.removeItem(appConfig.USER_TOKEN);
    localStorage.removeItem(appConfig.SHARE_USER);
    return true;
  };

  static getAccountSlug = (): string | undefined => {
    const jwt = localStorage.getItem(appConfig.USER_TOKEN);
    if (jwt === null) return undefined;
    const token = decode<{
      id: string;
      fn: string;
      ln: string;
      e: string;
      r: string;
      a: string;
    }>(jwt);
    if (token === null) return undefined;
    return token.a;
  };

  private static isTokenExpired = (token: string, compDate?: any): boolean => {
    const t: any = decode(token);
    if (!t.exp) return true;

    let date = new Date(0);
    if (compDate) date = compDate;
    date.setUTCSeconds(t.exp);

    return date < new Date();
  };

  static setSharePwd = (pwd: string) => {
    localStorage.setItem(appConfig.SHARE_PWD, pwd);
  };

  static getSharePwd = (): string | null => {
    const pwd = localStorage.getItem(appConfig.SHARE_PWD);
    return pwd;
  };

  static clearSharePwd = (): boolean => {
    localStorage.removeItem(appConfig.SHARE_PWD);
    return true;
  };
}

export { AuthService };
