import React, { useEffect, useState, useRef } from 'react';
import { AuthService } from 'utils/auth';
import { InsightConfidenceIcon } from '.';
import { handleOutsideClick, onEnterPress } from '../utils';

interface IProps {
  confidences: Array<{ name: string, value: string, description: string }>;
  name?: string;
  value: any;
  onChange?(value: string): void;
}

const InsightConfidenceSelector = ({ name, value, confidences, onChange }: IProps) => {
  const ref = useRef<any>();

  const [edit, setEdit] = useState(false);
  const [searchVal, setSearch] = useState('');
  const [hoverIdx, setHoverIndex] = useState(0);
  const [results, setResults] = useState(confidences);

  const [exactMatch, setExactMatch] = useState(false);

  useEffect(() => {
    setResults(confidences);
  }, [confidences]);

  onEnterPress(ref, () => {
    if (searchVal !== '' && exactMatch === false) {
      if (onChange) onChange(searchVal);
    } else {
      if (results[hoverIdx]) {
        if (onChange) onChange(results[hoverIdx].value);
      }
    }
    open(false);
  });

  handleOutsideClick(ref, () => {
    if (edit) {
      setSearch('');
      setHoverIndex(0);
      open(!edit);
    }
  });

  const setSearchValue = (value: string) => {
    setHoverIndex(-1);
    setSearch(value);

    let exact;
    let results;

    results = confidences.filter(val => val.name.toLowerCase().startsWith(value.toLowerCase()));
    exact = confidences.filter(val => val.name.toLowerCase() === value.toLowerCase()).length > 0;

    setExactMatch(exact);

    if (exact === true) setHoverIndex(0);

    setResults(results);
  };

  const setValue = (value: any) => {
    let name = value.value;
    if (name === selectedItem?.value) name = null;
    if (onChange) onChange(name);
    open(!edit);
  };

  const open = (value: any) => {
    if (isReadOnly()) {
      return;
    }

    setEdit(value);
  }

  const isReadOnly = () => {
    const user = AuthService.getActiveUser();

    return user.role === 'read-only';
  }

  const selectedItem = confidences.find(a => a.value === value);

  return (
    <div
      ref={ref}
      className={`${edit
        ? selectedItem
          ? 'SelectInput Confidences edit'
          : 'SelectInput Confidences edit noVal'
        : selectedItem
          ? 'SelectInput Confidences'
          : 'SelectInput Confidences noVal'
        }`}
    >
      <button type="button" onClick={e => open(!edit)}>
        {selectedItem ? (
          <div style={{ display: 'inline' }}>
            <InsightConfidenceIcon style={{ marginTop: '10px' }} height={30} width={30} selected={selectedItem.value} />
            <span style={{ marginLeft: '5px', textTransform: 'none' }}><strong>{selectedItem.name}</strong> - {selectedItem.description}</span>
          </div>
        ) : (
            <span className="placeholder">Select an insight confidence</span>
          )}
      </button>
      <div className="menu">
        <div className="search">
          <input type="text" placeholder={'Search'} value={searchVal} onChange={e => setSearchValue(e.target.value)} />
        </div>

        <div className="results">
          <ul>
            {results.map((item, idx) => (
              <li
                key={`${name}_${item}_${idx}`}
                className={hoverIdx === idx ? (selectedItem?.name === item.name ? 'over active' : 'over') : ''}
                onMouseOver={e => setHoverIndex(idx)}
                onClick={e => setValue(item)}
              >
                <div style={{ display: 'flex', textTransform: 'none', }}>
                  <InsightConfidenceIcon height={30} width={30} selected={item.value} />
                  <span><strong>{item.name}</strong> - {item.description}</span>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export { InsightConfidenceSelector };
