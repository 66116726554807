import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";
import { all, fork } from "redux-saga/effects";
import { History } from "history";

import * as DataTypes from "./types";

// App
import appSaga from "./app/sagas";
import { appReducer, collectionsReducer } from "./app/reducer";

// Application
import applicationSaga from "./application/sagas";
import { applicationReducer } from "./application/reducer";

// Account
import accountSaga from "./account/sagas";
import { accountReducer } from "./account/reducer";

// Experiments
import experimentSaga from "./experiment/sagas";
import { experimentReducer } from "./experiment/reducer";

import experimentTraitSaga from "./experiment_trait/sagas";
import { experimentTraitReducer } from "./experiment_trait/reducer";

import experienceSaga from "./experience/sagas";
import { experienceReducer } from "./experience/reducer";

import reportSaga from "./report/sagas";
import { reportReduccer } from "./report/reducer";

import reportSummarySaga from "./report_summary/sagas";
import { reportSummaryReducer } from "./report_summary/reducer";

import reportTargetSaga from "./report_target/sagas";
import { reportTargetReducer } from "./report_target/reducer";

import reportAnalyticsSaga from "./report_analytics/sagas";
import { reportAnalyticsReducer } from "./report_analytics/reducer";

import reportMetricSaga from "./report_metric/sagas";

import reportTraitSaga from "./report_trait/sagas";
import { reportsTraitReducer } from "./report_trait/reducer";

// Ideas
import ideaSaga from "./idea/sagas";
import { ideaReducer } from "./idea/reducer";

// Idea Management
import ideaManagementSaga from "./idea_management/sagas";
import { ideaManagementReducer } from "./idea_management/reducer";

import ideaTraitSaga from "./idea_trait/sagas";
import { ideaTraitReducer } from "./idea_trait/reducer";

// Idea Boards
import ideaBoardsSaga from "./idea_board/sagas";
import { ideaBoardReducer } from "./idea_board/reducer";

import ideaBoardsTraitsSaga from "./idea_board_trait/sagas";
import { ideaBoardsTraitReducer } from "./idea_board_trait/reducer";

// Worksheet
import worksheetSaga from "./worksheet/sagas";
import { worksheetReducer } from "./worksheet/reducer";

// Insights
import insightSaga from "./insight/sagas";
import { insightReducer } from "./insight/reducer";

import insightsTraitsSaga from "./insight_trait/sagas";
import { insightTraitReducer } from "./insight_trait/reducer";

// User
import userSaga from "./user/sagas";
import { userReducer } from "./user/reducer";

// Tasks
import taskSaga from "./task/sagas";

// Calculator
import calculatorSaga from "./calculator/sagas";
import { calculatorReducer } from "./calculator/reducer";

import dashboardSaga from "./dashboard/sagas";
import { dashboardReducer } from "./dashboard/reducer";

// Idea Board Management
import ideaBoardManagementSaga from "./idea_board_management/sagas";
import { ideaBoardManagementReducer } from "./idea_board_management/reducers";

import slackSaga from "./slack/sagas";
import { slackReducer } from "./slack/reducer";

import userAccountsSaga from "./user_accounts/sagas";

// Uxr
import uxrSaga from "./uxr/sagas";
import { uxrReducer } from "./uxr/reducer";

import uxrsTraitsSaga from "./uxr_trait/sagas";
import { uxrTraitReducer } from "./uxr_trait/reducer";

import rattingsSaga from "./ratting/sagas";
import { rattingReducer } from "./ratting/reducer";

import profileSettingsOptionsSaga from "./profile_settings_options/sagas";
import { profielSettingsOptionsReducer } from "./profile_settings_options/reducer";

import { bannerReducer } from "./banner_placement/reducer";
import bannerPlacementSaga from "./banner_placement/sagas";

import { insightsWordCloudReducer } from "./insightWordCloud/reducer";
import insightsWordCloudSaga from "./insightWordCloud/sagas";

import { insightsProposalsReducer } from "./insightProposal/reducer";
import insightsProposalsSaga from "./insightProposal/sagas";
import { customDataVizReduccer } from "./custom_data_viz/reducer";
import customDataVizSaga from "./custom_data_viz/sagas";
import { programGoalTargetReducer } from "./program_goal_target/reducer";
import programGoalTargetSaga from "./program_goal_target/sagas";

export const rootReducer = (history: History) =>
  combineReducers<DataTypes.ApplicationState>({
    router: connectRouter(history),
    app: appReducer,
    collections: collectionsReducer,
    account: accountReducer,
    experiment: experimentReducer,
    experimentTrait: experimentTraitReducer,
    idea: ideaReducer,
    idea_board: ideaBoardReducer,
    worksheet: worksheetReducer,
    insight: insightReducer,
    insightTrait: insightTraitReducer,
    user: userReducer,
    calculator: calculatorReducer,
    application: applicationReducer,
    dashboard: dashboardReducer,
    ideaBoardManagement: ideaBoardManagementReducer,
    ideaTrait: ideaTraitReducer,
    ideaBoardTrait: ideaBoardsTraitReducer,
    experience: experienceReducer,
    reportSummary: reportSummaryReducer,
    reportTarget: reportTargetReducer,
    reportAnalytics: reportAnalyticsReducer,
    report: reportReduccer,
    reportTrait: reportsTraitReducer,
    slack: slackReducer,
    ideaManagement: ideaManagementReducer,
    uxr: uxrReducer,
    uxrTrait: uxrTraitReducer,
    ratting: rattingReducer,
    profielSettingsOptions: profielSettingsOptionsReducer,
    bannerPlacement: bannerReducer,
    insightsWordCloud: insightsWordCloudReducer,
    insightProposals: insightsProposalsReducer,
    customDataViz: customDataVizReduccer,
    programGoalTarget: programGoalTargetReducer,
  });

export function* rootSaga() {
  yield all([
    fork(appSaga),
    fork(accountSaga),
    fork(experimentSaga),
    fork(experimentTraitSaga),
    fork(ideaSaga),
    fork(ideaBoardsSaga),
    fork(worksheetSaga),
    fork(insightSaga),
    fork(userSaga),
    fork(taskSaga),
    fork(calculatorSaga),
    fork(applicationSaga),
    fork(dashboardSaga),
    fork(ideaBoardManagementSaga),
    fork(insightsTraitsSaga),
    fork(ideaTraitSaga),
    fork(ideaBoardsTraitsSaga),
    fork(experienceSaga),
    fork(reportSummarySaga),
    fork(reportSaga),
    fork(reportMetricSaga),
    fork(reportTraitSaga),
    fork(reportTargetSaga),
    fork(reportAnalyticsSaga),
    fork(slackSaga),
    fork(ideaManagementSaga),
    fork(userAccountsSaga),
    fork(uxrSaga),
    fork(uxrsTraitsSaga),
    fork(rattingsSaga),
    fork(profileSettingsOptionsSaga),
    fork(bannerPlacementSaga),
    fork(insightsWordCloudSaga),
    fork(insightsProposalsSaga),
    fork(customDataVizSaga),
    fork(programGoalTargetSaga),
  ]);
}
