import React, { Component } from "react";
import * as DataTypes from "store/types";
import { connect } from "react-redux";
import { SupportingDataItem } from "./supporting_data_item";

interface IProps {
  account: DataTypes.Account;
  uxr: DataTypes.Uxr;
  items: any;
  currentUser: any;
  ctaLabel: string;
  type: string;
  title: string;
  description: string;
  onAdd(uxrId: string, type: string, callback: any): void;
}

interface IState {
  selectedDataId: string | null;
}

class SupportingDataSection extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      selectedDataId: this.props.items ? this.props.items[0]?.id : null,
    };
  }

  private isReadOnly = () => {
    return this.props.currentUser.role === "read-only";
  };

  private addData = () => {
    this.props.onAdd(this.props.uxr.id, this.props.type, this.selectData);
  };

  private selectedData = () => {
    if (!this.state.selectedDataId) {
      return null;
    }

    return this.props.uxr.supporting_datas.filter(
      (data) => data.id === this.state.selectedDataId
    )[0];
  };

  private selectData = (data: any) => {
    if (!data) {
      return;
    }
    /**
     * FIXME: This was made to avoid saving data on the wrong experience.
     * The user would type something on any experience field an click on the link to the next experience
     * The saved data would be included on the seccond visited experience instead of the first.
     *
     * The cause:
     *    The setState function is beeing called before the on change for the child component, this makes the request
     *    to be sent with the wrong id.
     **/
    this.setState({ ...this.state, selectedDataId: "" });

    setTimeout(() => {
      this.setState({ ...this.state, selectedDataId: data.id });
    }, 100);
  };

  render() {
    const { uxr, items } = this.props;
    const { selectedDataId } = this.state;

    return (
      <React.Fragment>
        <section className="Experiences">
          <div className="left">
            <h4>{this.props.title}</h4>
            <p>{this.props.description}</p>
            {!this.isReadOnly() ? (
              <button className="btn btn-primary-light" onClick={this.addData}>
                <i className="fa fa-plus" />
                {this.props.ctaLabel}
              </button>
            ) : (
              <div className="mt-4"></div>
            )}

            <ul className="Experiences">
              {items.map((item: any) => {
                return (
                  <li
                    key={`ex_${item.id}`}
                    className={
                      selectedDataId && selectedDataId === item.id
                        ? "active"
                        : ""
                    }
                  >
                    <button
                      type="button"
                      onClick={(e) => this.selectData(item)}
                    >
                      {item.sumarized_data || `New ${this.props.type} data`}
                    </button>
                  </li>
                );
              })}
            </ul>
          </div>
          <div className="right">
            <div className="grey_box">
              {this.selectedData() ? (
                <SupportingDataItem
                  currentUser={this.props.currentUser}
                  uxr={uxr}
                  type={this.props.type}
                  selectData={this.selectData}
                  data={this.selectedData()}
                />
              ) : this.props.type === "qualitative" ? (
                <QualitativeEmptyState
                  uxrId={this.props.uxr.id}
                  onAdd={this.addData}
                />
              ) : (
                <QuantitativeEmptyState
                  uxrId={this.props.uxr.id}
                  onAdd={this.addData}
                />
              )}
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}

const mapStateToProps = ({ router }: DataTypes.ApplicationState) => ({
  router,
});

const mapDispatchToProps = {};

const connectedPage = connect(
  mapStateToProps,
  mapDispatchToProps
)(SupportingDataSection);

export { connectedPage as SupportingDataSection };

const QualitativeEmptyState = ({
  onAdd,
  uxrId,
}: {
  onAdd(uxrId: string, type: string): void;
  uxrId: string;
}) => {
  return (
    <div className="Experiments empty-state">
      <div className="wrapper">
        <h4>Show me the data!</h4>

        <p>
          Document your qualitative data points AND map them to relevant Areas
          of Focus and Pages/Journey.
        </p>
        <button
          className="btn btn-primary"
          type="button"
          onClick={() => onAdd(uxrId, "qualitative")}
        >
          Add Qualitative Data
        </button>
        <p className="mt-2">
          <i>
            Pro Tip: The sharable study we’ll create for you is interactive and
            will allow the reader to navigate information based on how it’s
            mapped, so be thorough!
          </i>
        </p>
      </div>
    </div>
  );
};

const QuantitativeEmptyState = ({
  onAdd,
  uxrId,
}: {
  onAdd(uxrId: string, type: string): void;
  uxrId: string;
}) => {
  return (
    <div className="Experiments empty-state">
      <div className="wrapper">
        <p>
          Store the quantitative data points relevant to this study AND map them
          to relevant Areas of Focus and Pages/Journey.
        </p>
        <button
          className="btn btn-primary"
          type="button"
          onClick={() => onAdd(uxrId, "quantitative")}
        >
          Add Quantitative Data
        </button>
        <p className="mt-2">
          <i>
            Pro Tip: Got tons of data and aren’t sure how to decide what to
            include here?
          </i>
        </p>
      </div>
    </div>
  );
};
