import React, { Component } from "react";
import { connect } from "react-redux";
import { history } from "utils";
import { ApplicationState } from "store/types";
import * as DataTypes from "store/types";
import { AppPage, AppPageProps } from "comps/pages";
import { WorkSheet } from "ui/worksheet";
import debounce from "debounce";

import { createLoadingSelector } from "store/selectors";
import {
  getWorksheetRequest,
  updateWorksheetRequest,
  channelUpdateWorksheetRequest,
  notifyEditingRequest,
} from "store/worksheet/actions";

import "../../../css/Screens.scss";
import { WSClient } from "api/client/ws_client";
import { Avatar } from "ui";

interface IProps extends AppPageProps {
  computedMatch: any;
  worksheet: DataTypes.Worksheet | null;
  loading: boolean;
  user: any;
  getWorksheetRequest: (board_id: string) => void;
  updateWorksheetRequest: (board_id: string, body: any) => void;
  channelUpdateWorksheetRequest: (content: any) => void;
  notifyEditingRequest: (ideaBoard: any) => void;
}

interface IState {
  pristine: boolean;
  updatedContents: any[];
  usersEditing: any;
}

class IdeaWorkSheet extends Component<IProps, IState> {
  private subscription: any;
  private editingSubscription: any;
  private cable: any;

  constructor(props: IProps) {
    super(props);

    this.cable = WSClient.connect();
    this.onChange = debounce(this.onChange, 800, false);
    this.notifyEditing = debounce(this.notifyEditing, 1000, true);

    this.state = {
      pristine: true,
      updatedContents: [],
      usersEditing: [],
    };
  }

  componentDidMount = () => {
    this.props.getWorksheetRequest(this.props.computedMatch.params.board_id);
    document.addEventListener("keydown", this.escFunction, false);
    this.createSubscription();
    this.createEditingSubscription();
  };

  consumer: any;

  componentWillUnmount = () => {
    document.removeEventListener("keydown", this.escFunction, false);

    if (!this.subscription) {
      return;
    }

    this.subscription.unsubscribe();
    this.editingSubscription.unsubscribe();
  };

  escFunction = (event: any) => {
    if (event.keyCode === 27) {
      history.goBack();
    }
  };

  private setPristine = (pristine: boolean) => {
    this.setState({
      ...this.state,
      pristine,
    });

    if (pristine) {
      this.setState({
        updatedContents: [],
      });
    }
  };

  private close = () => {
    history.goBack();
  };

  private notifyEditing = () => {
    this.props.notifyEditingRequest({
      id: this.props.computedMatch.params.board_id,
    });
  };

  private mergeContent = (currentContent: any, newContent: any) => {
    let currentBlocks = currentContent.blocks;
    let newBlocks = newContent.blocks;

    newBlocks.forEach((block: any, index: any) => {
      const currentIndex = currentBlocks.findIndex(
        (item: any) => item.key === block.key
      );

      if (currentIndex === -1) {
        currentBlocks.splice(index, 0, block);
      }
    });

    return {
      ...currentContent,
      ...newContent,
      blocks: currentBlocks,
    };
  };

  private onChange = (key: string, value: any) => {
    this.setState({ pristine: true });

    if (key === "content" && this.state.updatedContents?.length !== 0) {
      let newContent = value;

      this.state.updatedContents.forEach((content: any) => {
        newContent = this.mergeContent(newContent, content);
      });

      this.props.updateWorksheetRequest(
        this.props.computedMatch.params.board_id,
        { worksheet: { [key]: newContent } }
      );

      return;
    }

    this.props.updateWorksheetRequest(
      this.props.computedMatch.params.board_id,
      { worksheet: { [key]: value } }
    );

    this.setState({
      updatedContents: [],
    });
  };

  private clearEditingUser = (user: any) => {
    if (!this.props.user || this.props.user.id === user.id) {
      return;
    }

    const index = this.state.usersEditing.findIndex(
      (item: any) => item.id === user.id
    );

    if (index === -1) {
      return;
    }

    this.setState({
      usersEditing: this.state.usersEditing.filter(
        (item: any) => item.id !== user.id
      ),
    });
  };

  private onReceived = (response: any) => {
    this.clearEditingUser(response.updated_by);

    if (this.state.pristine) {
      this.props.channelUpdateWorksheetRequest(response.content);
      this.setState({
        updatedContents: [],
      });

      return;
    }

    this.setState({
      updatedContents: [...this.state.updatedContents, response.content],
    });
  };

  private onReceivedEditing = (response: any) => {
    if (!this.props.user || this.props.user.id === response.user.id) {
      return;
    }

    const index = this.state.usersEditing.findIndex(
      (user: any) => user.id === response.user.id
    );

    if (index !== -1) {
      return;
    }

    this.setState({
      usersEditing: [
        ...this.state.usersEditing,
        { ...response.user, editing_at: new Date(response.editing_at) },
      ],
    });
  };

  private createSubscription = () => {
    if (this.subscription) {
      return;
    }

    if (!this.props.worksheet) {
      setTimeout(() => this.createSubscription(), 500);
      return;
    }

    this.subscription = this.cable.subscriptions.create(
      {
        channel: "WorksheetUpdateChannel",
        worksheet_id: this.props.worksheet?.id,
      },
      { received: this.onReceived }
    );
  };

  private createEditingSubscription = () => {
    if (this.editingSubscription) {
      return;
    }

    if (!this.props.worksheet) {
      setTimeout(() => this.createEditingSubscription(), 500);
      return;
    }

    this.editingSubscription = this.cable.subscriptions.create(
      {
        channel: "WorksheetEditingChannel",
        worksheet_id: this.props.worksheet?.id,
      },
      { received: this.onReceivedEditing }
    );
  };

  render() {
    const { loading, worksheet } = this.props;

    if (loading && loading === true && worksheet === null) return null;

    return (
      <div className="FullScreen">
        <div className="header">
          <img src="/img/logo_sm.svg" alt="illuminate" className="logo" />

          <div
            style={{
              width: "800px",
              margin: "auto",
              position: "relative",
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            {this.state.usersEditing.map((user: any, idx: any) => {
              return (
                <div
                  style={{ position: "relative", left: `${8 * idx - 8}px` }}
                  key={`user_${user.id}`}
                >
                  <Avatar user={user} className="sm" showHover={false} />
                </div>
              );
            })}
          </div>

          <button type="button" className="btn btn-close" onClick={this.close}>
            <i className="fas fa-times" />
          </button>
        </div>
        <div className="form-body">
          {this.state.updatedContents.length > 0 && (
            <div
              style={{
                backgroundColor: "#f4b974",
                width: "800px",
                margin: "auto",
                position: "relative",
                textAlign: "center",
                borderRadius: "9px",
              }}
            >
              <p
                style={{
                  fontWeight: "bold",
                  color: "#2f4760",
                  paddingTop: "10px",
                }}
              >
                Note: Someone has made an update to this doc.
              </p>
              <small
                style={{
                  paddingBottom: "10px",
                  display: "block",
                  color: "#2f4760",
                }}
              >
                <span style={{ display: "block" }}>
                  {" "}
                  When you click in the margin to save, we'll merge your inputs
                  with theirs and you can format
                </span>
                <span style={{ display: "block" }}>
                  them to read well together. Save often to keep your
                  experiences synced.
                </span>
              </small>
            </div>
          )}
          <WorkSheet
            setPristine={this.setPristine}
            changeOnType={true}
            title={worksheet?.title || ""}
            content={worksheet?.content}
            onInput={this.notifyEditing}
            onChange={this.onChange}
          />
        </div>
      </div>
    );
  }
}

const initLoader = createLoadingSelector(["@@worksheet/GET_WORKSHEET"]);

const mapStateToProps = ({
  router,
  app,
  account,
  worksheet,
  user,
}: ApplicationState) => ({
  router: router,
  loading: initLoader(app.requests),
  account: account,
  worksheet: worksheet,
  user: user,
});

const mapDispatchToProps = {
  getWorksheetRequest,
  updateWorksheetRequest,
  channelUpdateWorksheetRequest,
  notifyEditingRequest,
};

const connectedPage = connect(
  mapStateToProps,
  mapDispatchToProps
)(AppPage(IdeaWorkSheet));

export { connectedPage as IdeaWorkSheet };
