import React, { Component } from "react";
import { connect } from "react-redux";
import * as DataTypes from "store/types";
import { ApplicationState } from "store/types";
import "css/Base.scss";
import "css/Screens.scss";
import { getInsightsBoardRequest } from "store/dashboard/actions";
import TimeAgo from "timeago-react";
import "css/Insights.scss";
import { createLoadingSelector } from "store/selectors";
import { InsightBoardLoading } from "./loading/insightboard_loading";

interface IProps {
  router: any;
  loading: boolean;
  dashboard: DataTypes.Dashboard | null;
  account: DataTypes.Account | null;
  getInsightsBoardRequest: () => void;
}

interface IState {}

class InsightBoard extends Component<IProps, IState> {
  componentDidMount() {
    this.props.getInsightsBoardRequest();
  }

  buildInsight = (
    insight: DataTypes.Insight,
    title: string,
    showLine = true
  ) => {
    const useBBMethods = this.props.account?.settings.use_bb_methods;

    let style: any = { paddingTop: "5px", paddingBottom: "5px" };

    if (!showLine) {
      style = { ...style, borderBottom: "none" };
    }

    return (
      <section
        className="List Insights"
        ref={(el) => {
          if (el) {
            el.style.setProperty("min-width", "unset", "important");
            el.style.setProperty("width", "auto", "important");
          }
        }}
      >
        <ul className="ListItem">
          <label className="text__sm">{title}</label>
          <li style={style} className="cursor--default">
            <div className="body">
              {useBBMethods === true && insight.altitude && (
                <img
                  src={`/img/altitudes/${insight.altitude}.png`}
                  alt={insight.altitude}
                  style={{
                    height: "24px",
                    width: "24px",
                    top: "-4px",
                    left: "4px",
                    position: "relative",
                    float: "right",
                  }}
                />
              )}
              <p className="insight">{insight.value}</p>
              <div className="status">
                <p className="date">
                  <TimeAgo datetime={insight.created_at} live={false} />
                </p>
                <div className="indicators">
                  {insight.experiments && insight.experiments.length > 0 && (
                    <div>
                      <p>{insight.experiments.length}</p>{" "}
                      <i className="fas fa-flask" />
                    </div>
                  )}
                  {insight.star === true && (
                    <div>
                      <i className="fas fa-star" />
                    </div>
                  )}
                  {insight.key === true && (
                    <div>
                      <i className="fas fa-key" />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </li>
        </ul>
      </section>
    );
  };

  render() {
    if (this.props.loading) {
      return <InsightBoardLoading />;
    }

    const { dashboard } = this.props;

    if (!dashboard || !dashboard.insightsBoard) {
      return null;
    }

    const { insightsBoard } = dashboard;

    if (!insightsBoard.latest && !insightsBoard.more_experiments) {
      return null;
    }

    // if (!insightsBoard.latest && !insightsBoard.more_experiments) {
    //   return (
    //     <div className="trait">
    //       <label style={{ fontSize: "18px" }}>&nbsp;</label>

    //       <div className="shadow body">
    //         <MarketingBanner
    //           containerStyle={{
    //             width: "100%",
    //             display: "flex",
    //             justifyContent: "center",
    //             padding: 0,
    //             backgroundColor: "transparent",
    //           }}
    //           imageStyle={{ width: "100%" }}
    //           bannerPlacementSlug={"dashboard_banners"}
    //         />
    //       </div>
    //     </div>
    //   );
    // }

    return (
      <div className="trait">
        <label style={{ fontSize: "18px" }}>Insights</label>
        <div className="body shadow">
          <ul>
            {insightsBoard.latest &&
              this.buildInsight(
                insightsBoard.latest,
                "Recent Insight",
                !!insightsBoard.more_experiments
              )}
            {insightsBoard.more_experiments &&
              this.buildInsight(
                insightsBoard.more_experiments,
                "Insight with the Most Supporting Evidence"
              )}
          </ul>
        </div>
      </div>
    );
  }
}

const loadingSelector = createLoadingSelector([
  "@@dashboard/GET_INSIGHT_BOARD",
]);

const mapStateToProps = ({
  router,
  dashboard,
  account,
  app,
}: ApplicationState) => ({
  router,
  loading: loadingSelector(app.requests),
  dashboard,
  account,
});

const mapDispatchToProps = {
  getInsightsBoardRequest,
};

const connectedPage = connect(
  mapStateToProps,
  mapDispatchToProps
)(InsightBoard);

export { connectedPage as InsightBoard };
