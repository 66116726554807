import React from 'react';

interface IProps {
  className?: string;
  placeholder?: string;
  onChange(e: any): any;
  name: string;
  isSelected: boolean;
}

const Radio = (props:IProps) => {

  const { className, onChange, placeholder, name, isSelected } = props;
  return (
    <>
      <input
        className={className || ''}
        type="radio"
        onChange={onChange}
        placeholder={placeholder}
        name={name}
        id={name}
        checked={isSelected}
      />
      <label htmlFor={name}>{name}</label>
    </>
  );
}

export { Radio };
