import React, { Component } from 'react';
import * as DataTypes from 'store/types';

interface IProps {
  idea: DataTypes.Idea;
  saving: boolean;
  onSubmit(idea: DataTypes.Idea): void;
}

class Archive extends Component<IProps> {
  render() {
    const { idea, onSubmit, saving } = this.props;

    return (
      <React.Fragment>
        <div className="header">
          {
            idea.archived ?
            <h3>Restore Idea</h3> :
            <h3>Archive Idea</h3>
          }

          {
            idea.archived ?
            <p>
              Just making sure you want to restore this idea. Doing so will bring it back into your active idea list. You can archive it again later if you choose.
            </p> :
            <p>
              Are you sure you want to archive this idea? You can still access it using the Status filter. You can also restore this idea from there.
            </p>
          }

        </div>
        <div className="cntrl-bar">
          {saving === true ? (
            <button className="btn btn-danger" type="submit" disabled={true}>
              <i className="fas fa-circle-notch fa-spin" />
            </button>
          ) : (
            <button className="btn btn-danger" onClick={() => onSubmit(idea)}>
              { idea.archived ? 'Restore Idea' : 'Archive Idea' }
            </button>
          )}
        </div>
      </React.Fragment>
    );
  }
}

export { Archive };
