import React, { useMemo, useState } from "react";
import * as DataTypes from "store/types";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { Helpers } from "utils";
import { DataVizChartFactory } from "./charts/factory";
import { DataTable } from "pages/app/experiments/comps/show/report/custom_data_viz/DataTable";
import { EditorInput } from "ui/editor_input/editor_input";

interface IProps {
  dataViz: DataTypes.CustomDataViz;
}

const sliderSettings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  arrows: true,
};

const colors = [
  "#2a88f2",
  "#89cad7",
  "#cec7e3",
  "#a79fe8",
  "#ff5a71",
  "#8edeb0",
  "#25be6f",
  "#f4b974",
  "#757e8e",
  "#495057",
];

export const CustomDataViz: React.FC<IProps> = ({ dataViz }) => {
  let sliderRef: any = null;
  const [currentSlide, setCurrentSlide] = useState(0);

  const experienceNames = useMemo(() => {
    const names: { [k: string]: string } = {};

    dataViz.reports.forEach((report) => {
      report.metrics.forEach((metric) => {
        metric.results.forEach((result) => {
          names[result.experience_id] = result.experience_name;
        });
      });
    });

    return names;
  }, [dataViz]);

  const metricNames = useMemo(() => {
    const names: { [k: string]: string } = {};

    dataViz.reports.forEach((report) => {
      report.metrics.forEach((metric) => {
        names[metric.agregation_id] = metric.name;
      });
    });

    return names;
  }, [dataViz]);

  const experienceNameLabels = useMemo(
    () =>
      Helpers.uniqueArray(
        Object.keys(experienceNames).map((n) => experienceNames[n])
      ),
    [experienceNames]
  );

  const getResultForMetric = (
    report: DataTypes.CustomDataVizReport,
    metricId: string,
    experienceId: string
  ) => {
    const metric = report.metrics.filter(
      (metric) => metric.agregation_id === metricId
    )[0];

    const result = metric.results.filter(
      (result) => result.experience_id === experienceId
    )[0];

    if (metric.kind === "lift") {
      return result.lift;
    }

    return result.value;
  };

  const getChartKindForMetric = (metricId: string) => {
    let chartKind = null;

    dataViz.reports.forEach((report) => {
      const metric = report.metrics.filter(
        (metric) => metric.agregation_id === metricId
      )[0];

      chartKind = metric.chart_kind;
    });

    return chartKind;
  };

  const getStoryTellingForMetric = (metricId: string) => {
    let storyTelling = null;

    dataViz.reports.forEach((report) => {
      const metric = report.metrics.filter(
        (metric) => metric.agregation_id === metricId
      )[0];

      storyTelling = metric.story_telling;
    });

    return storyTelling;
  };

  const dataSetsByReport = useMemo(() => {
    return Object.keys(metricNames).map((metricId) => {
      const metricName = metricNames[metricId];

      return {
        labels: experienceNameLabels,
        title: metricName,
        chart_kind: getChartKindForMetric(metricId),
        story_telling: getStoryTellingForMetric(metricId),
        metricId,
        dataSets: dataViz.reports.map((report, index) => {
          return {
            label: report.name,
            backgroundColor: colors[index],
            data: experienceNameLabels.map((experienceName) => {
              return getResultForMetric(
                report,
                metricId,
                Object.keys(experienceNames).find(
                  (key) => experienceNames[key] === experienceName
                ) as string
              );
            }),
          };
        }),
      };
    });
  }, [dataViz]);

  const goToSlider = (index: number) => {
    sliderRef.slickGoTo(index);
  };

  return (
    <>
      <Slider
        ref={(slider) => (sliderRef = slider)}
        {...sliderSettings}
        afterChange={(newSlide) => setCurrentSlide(newSlide)}
        nextArrow={
          <div>
            <i className="fas fa-chevron-right" />
          </div>
        }
        prevArrow={
          <div>
            <i className="fas fa-chevron-left" />
          </div>
        }
      >
        {dataSetsByReport.map((dataSet) => (
          <div key={`${dataSet.title}-${dataSet.metricId}`}>
            <DataVizChartFactory dataSet={dataSet} />

            {dataSet.story_telling && (
              <section className="form-group mt__sm">
                <label>Data Storytelling</label>

                <EditorInput
                  onBlur={() => {}}
                  readOnly={true}
                  content={dataSet.story_telling}
                />
              </section>
            )}
          </div>
        ))}
      </Slider>

      <DataTable
        dataViz={dataViz}
        onMetricClick={(index) => goToSlider(index)}
        currentIndex={currentSlide}
      />
    </>
  );
};
