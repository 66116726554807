import { Button } from "comps";
import React, { Component } from "react";
import { connect } from "react-redux";
import { hideModal } from "store/app/actions";
import { createInsightProposalsRequest } from "store/insightProposal/actions";
import { createLoadingSelector } from "store/selectors";
import { ApplicationState } from "store/types";
import { InsightList } from "./comps/insight_list";

interface IProps {
  insightProposals: any;
  saving: boolean;
  hideModal: () => void;
  createInsightProposalsRequest: (unselected: string[]) => void;
}

interface IState {
  unselected: string[];
}

class InsightProposals extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      unselected: [],
    };
  }

  select = (id: string) => {
    if (this.state.unselected.includes(id)) {
      this.setState({
        unselected: this.state.unselected.filter(
          (unselectedId: string) => id !== unselectedId
        ),
      });
      return;
    }

    this.setState({
      unselected: [...this.state.unselected, id],
    });
  };

  selectAll = () => {
    this.setState({
      unselected: [],
    });
  };

  skip = () => {
    window.localStorage.setItem("INSIGHTS_PROPOSAL_DONT_SHOW_AGAIN", "true");
    this.props.hideModal();
  };

  render() {
    if (!this.props.insightProposals) {
      return;
    }

    return (
      <React.Fragment>
        <div className="header flex">
          <div className="mr__sm"></div>

          <div className="flex column">
            <img
              style={{ height: "40px", marginBottom: "16px" }}
              src="/img/logo_sm.svg"
              className="user"
              alt="user"
            />

            <p style={{ textAlign: "center" }}>
              To thank you for using illuminate, we're providing you with some
              insights from 2022.
            </p>
            <p style={{ textAlign: "center" }}>
              Simply check "Add Insights" at the bottom of the modal to have
              these added to your Insights section.
            </p>
          </div>
        </div>

        <div style={{ maxWidth: "600px" }} className="mt__sm">
          <InsightList
            onSelect={this.select}
            unselected={this.state.unselected}
            name={"General Insights"}
            items={this.props.insightProposals.general_proposals}
          />

          <InsightList
            unselected={this.state.unselected}
            onSelect={this.select}
            name="Industry Insights"
            items={this.props.insightProposals.industry_proposals}
          />
        </div>

        <div className="cntrl-bar" style={{ display: "flex", gap: "12px" }}>
          <div style={{ flexGrow: 1, display: "flex" }}>
            <Button
              className="button button__secondary"
              text="Dont't show this again"
              onClick={this.skip}
            />
          </div>

          <Button
            className="button button__primary-reverse"
            text="Select All"
            onClick={this.selectAll}
          />

          <Button
            className="button button__primary"
            text="Add Insights"
            isLoading={this.props.saving}
            onClick={() =>
              this.props.createInsightProposalsRequest(this.state.unselected)
            }
          />
        </div>
      </React.Fragment>
    );
  }
}

const loadingSelector = createLoadingSelector([
  "@@insight_proposals/CREATE_INSIGHTS_PROPOSALS",
]);

const mapStateToProps = ({ app, insightProposals }: ApplicationState) => ({
  insightProposals,
  saving: loadingSelector(app.requests),
});

const mapDispatchToProps = {
  createInsightProposalsRequest,
  hideModal,
};

const connectedPage = connect(
  mapStateToProps,
  mapDispatchToProps
)(InsightProposals);

export { connectedPage as InsightProposals };
