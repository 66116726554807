import React, { useState } from 'react'
import { Input, FormField, InputTypes } from 'comps/form'

interface IProps {
  field: FormField;
  onChange: (field: FormField, value:string) => void
}

const VisiblePasswordInput = (props: IProps) => {
  const { field, onChange } = props;
  const [showPassword, setShowPassword] = useState(false);
  return (
  <div className="password-field">
    <button type="button" className="btn btn-icon" onClick={() => setShowPassword(!showPassword)}>
      { showPassword ?
        (<i className="fa fa-eye-slash" />) :
        (<i className="fa fa-eye" /> )
      }
    </button>
      <Input 
        field={field}
        onChange={(e) => onChange(field, e.target.value)}
        className="form-control"
        type={showPassword ? InputTypes.TEXT : InputTypes.PASSWORD}
      />
    </div>
    )
}

export { VisiblePasswordInput }
