import { Reducer } from "redux";
import { ActionTypes } from "./types";

const initialState: any = null;

const reducer: Reducer<any> = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.SHOW_BANNER_PLACEMENT_SUCCESS:
      return {
        ...state,
        [action.payload.response.banner_placement]: action.payload.response,
      };
    default: {
      return state;
    }
  }
};

export { reducer as bannerReducer };
