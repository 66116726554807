import React, { Component, RefObject } from "react";
import Dropzone from "react-dropzone";
import { connect } from "react-redux";
import { createLoadingSelector } from "store/selectors";
import * as DataTypes from "store/types";
import { ApplicationState } from "store/types";

interface IProps {
  account: DataTypes.Account | null;
  uploadLoading: boolean;
  onSubmit(file: any): void;
  idea: any;
}

interface IState {
  file: any;
  pristine: any;
}

class IdeaCsv extends Component<IProps, IState> {
  uploadFileButton: RefObject<any>;
  downloadTemplateLink: RefObject<any>;

  constructor(props: IProps) {
    super(props);

    this.downloadTemplateLink = React.createRef();
    this.uploadFileButton = React.createRef();
    this.state = {
      file: null,
      pristine: true,
    };
  }

  private csvTemplateFields = () => {
    const useBBMethods = this.props.account?.settings?.use_bb_methods;
    let fields = ["idea name", "problem or opportunity", "proposed variation"];

    if (useBBMethods) {
      fields = [...fields, "whypothesis"];
    } else {
      fields = [...fields, "hypothesis"];
    }

    fields = [...fields, "themes", "audiences", "devices", "pages"];

    if (this.props.account?.settings?.teams) {
      fields = [...fields, "teams"];
    }

    if (this.props.account?.settings?.channels) {
      fields = [...fields, "channels"];
    }

    if (this.props.account?.settings?.brands) {
      fields = [...fields, "brands"];
    }

    fields = [
      ...fields,
      "level of effort",
      "business impact",
      "win likelihood",
      "value of learning",
    ];

    return fields;
  };

  private exportCsvTemplate = () => {
    const rows = [
      [
        "Please delete these instructions before upload so that your file begins with the row of headers.",
      ],
      [
        "Do not change the headers. Doing so will prevent your data from being imported.",
      ],
      ["Simply add the values below each header."],
      ["To add multiple values to one cell use a semicolon to separate."],
      [
        "Prioritization criteria columns should be marked with a value 1 through 5. 1 is low and 5 is high.",
      ],
      [""],
      this.csvTemplateFields(),
    ];

    const csvContent = "data:text/csv;charset=utf-8,";

    const file =
      csvContent +
      encodeURIComponent(rows.map((row) => row.join(",")).join("\r\n"));

    this.downloadTemplateLink.current.href = file;
    this.downloadTemplateLink.current.download = `illuminate - ideas template.csv`;

    this.downloadTemplateLink.current.click();
  };

  private onFileUpload = (acceptedFiles: File[]) => {
    if (!acceptedFiles || acceptedFiles.length === 0) {
      return;
    }

    this.setState({
      file: acceptedFiles[0],
    });

    this.props.onSubmit(acceptedFiles[0]);
  };

  render() {
    const fileInvalid = !this.state.file && !this.state.pristine;

    return (
      <React.Fragment>
        <div className="panels" style={{ maxWidth: "800px" }}>
          <div className="left">
            <label>Ideas Batch Upload</label>
            <div className="header">
              <img src="/img/apps/illuminate.png" alt="api key" />
              <h2>Ideas Batch Upload</h2>
            </div>

            <p className="mt-2">
              Use{" "}
              <a href="#" onClick={this.exportCsvTemplate}>
                this CSV template
              </a>{" "}
              to upload ideas. Follow the directions in the CSV to ensure your
              upload is successful.
            </p>

            <a ref={this.downloadTemplateLink} href="#"></a>
          </div>
          <div className="right">
            <Dropzone onDrop={this.onFileUpload} accept=".csv">
              {({ getRootProps, getInputProps }) => (
                <div className="">
                  <div
                    {...getRootProps()}
                    className={`file_uploader ${
                      this.state.file ? "active" : ""
                    } ${fileInvalid || this.props.idea?.errors ? "error" : ""}`}
                  >
                    <div className="item-dropbox">
                      <input {...getInputProps()} />

                      <i className="fas fa-cloud-upload-alt"></i>

                      {fileInvalid ? (
                        <p>Please select a valid file</p>
                      ) : this.state.file?.name ? (
                        <p>{this.state.file.name}</p>
                      ) : (
                        <p>
                          Drag 'n drop your CSV file here, or click to select
                          file
                        </p>
                      )}
                      <p></p>
                    </div>
                  </div>
                </div>
              )}
            </Dropzone>
          </div>
        </div>
        {this.props.idea && this.props.idea.errors.length > 0 && (
          <ul
            style={{
              paddingLeft: "20px",
              display: "flex",
              flexWrap: "wrap",
              maxWidth: "800px",
            }}
          >
            <li style={{ width: "100%" }}>
              <p style={{ color: "#ff5a71" }}>
                Something went wrong with your upload, see the reasons below:
              </p>
            </li>
            {this.props.idea.errors.map((error: any) => (
              <li key={error.line_number} style={{ width: "50%" }}>
                <p
                  style={{
                    marginBottom: 0,
                    fontSize: "12px",
                    fontWeight: "bold",
                  }}
                >
                  Line {error.line_number}
                </p>

                <ul style={{ paddingLeft: "15px", marginBottom: "12px" }}>
                  {error.messages.map((message: string) => (
                    <p
                      style={{ marginBottom: 0, fontSize: "12px" }}
                      key={message}
                    >
                      {message}
                    </p>
                  ))}
                </ul>
              </li>
            ))}
          </ul>
        )}
      </React.Fragment>
    );
  }
}

const uploadSelector = createLoadingSelector(["@@idea/UPLOAD_IDEAS"]);

const mapStateToProps = ({ app, account, idea }: ApplicationState) => ({
  uploadLoading: uploadSelector(app.requests),
  account,
  idea,
});

const mapDispatchToProps = {};

const connectedPage = connect(mapStateToProps, mapDispatchToProps)(IdeaCsv);

export { connectedPage as IdeaCsv };
