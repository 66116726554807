import React from 'react';
import { Droppable, Draggable } from 'react-beautiful-dnd';

interface IColHeaderProps {
  icon?: string;
  name: string;
}

interface IColProps {
  id: string;
  header: IColHeaderProps;
  items: Array<any>;
  currentUser: any;
}

export const KanbanColumn = ({ id, header, items, currentUser }: IColProps) => {
  return (
    <Droppable droppableId={id} isDropDisabled={currentUser.role === 'read-only'}>
      {(provided, snapshot) => (
        <div className="column" ref={provided.innerRef}>
          {header.icon ? (
            <div className="head">
              <i className={header.icon} />
              <span>{header.name}</span>
            </div>
          ) : (
            <div className="head">
              <span>header.name</span>
            </div>
          )}
          <div className="items">
            {items}
            {provided.placeholder}
          </div>
        </div>
      )}
    </Droppable>
  );
};

export interface IItemProps {
  item: any;
  index: number;
  children: any;
  currentUser: any;
}

export const KanbanItem = ({ item, index, children, currentUser }: IItemProps) => {
  return (
    <Draggable key={item.id} index={index} draggableId={item.id} isDragDisabled={currentUser.role === 'read-only'}>
      {(provided, snapshot) => (
        <div
          className={snapshot.isDragging ? 'item dragging' : 'item'}
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          style={getDragStyle(snapshot.isDragging, provided.draggableProps.style)}
        >
          {children}
        </div>
      )}
    </Draggable>
  );
};

const getDragStyle = (isDragging: boolean, draggableStyle: any) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: 'none',

  // change background colour if dragging
  //background: isDragging ? 'lightgreen' : 'grey',

  // styles we need to apply on draggables

  ...draggableStyle
});
