import { Reducer } from 'redux';
import * as DataTypes from '../types';
import { ActionTypes } from './types';

const initialState: DataTypes.ProfileSettingsOptions | null = null;

const reducer: Reducer<DataTypes.ProfileSettingsOptions | null> = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.GET_SETTINGS_OPTIONS_AVG_EXPERIMENTS_PER_MONTH_SUCCESS:
      return {
        ...state,
        avg_exp_per_month: action.payload,
      };
    case ActionTypes.GET_SETTINGS_OPTIONS_COE_SUCCESS:
      return {
        ...state,
        coe: action.payload,
      };
    case ActionTypes.GET_SETTINGS_OPTIONS_COMPANY_SIZE_SUCCESS:
      return {
        ...state,
        company_size: action.payload,
      };
    case ActionTypes.GET_SETTINGS_OPTIONS_COMPANY_EXPERIMENTS_WIN_PER_MONTH_SUCCESS:
      return {
        ...state,
        experiments_win_per_month: action.payload,
      };
    case ActionTypes.GET_SETTINGS_OPTIONS_INDUSTRY_SUCCESS:
      return {
        ...state,
        industry: action.payload,
      };
    case ActionTypes.GET_SETTINGS_OPTIONS_KIND_OF_TEST_SUCCESS:
      return {
        ...state,
        kind_of_test: action.payload,
      };
    case ActionTypes.GET_SETTINGS_OPTIONS_KIND_OF_TOOL_SUCCESS:
      return {
        ...state,
        kind_of_tool: action.payload,
      };
    case ActionTypes.GET_SETTINGS_OPTIONS_PRIMARY_ANALYTICS_TOOL_SUCCESS:
      return {
        ...state,
        primary_analytics_tool: action.payload,
      };
    case ActionTypes.GET_SETTINGS_OPTIONS_PRIMARY_TESTING_TOOL_SUCCESS:
      return {
        ...state,
        primary_testing_tool: action.payload,
      };
    case ActionTypes.GET_SETTINGS_OPTIONS_PROGRAM_TYPE_SUCCESS:
      return {
        ...state,
        program_type: action.payload,
      };
    case ActionTypes.GET_SETTINGS_OPTIONS_TESTING_CHALLENGE_SUCCESS:
      return {
        ...state,
        testing_challenge: action.payload,
      };
    case ActionTypes.GET_SETTINGS_OPTIONS_TESTING_PROGRAM_REACH_SUCCESS:
      return {
        ...state,
        testing_program_reach: action.payload,
      };

    default: {
      return state;
    }
  }
};

export { reducer as profielSettingsOptionsReducer };
