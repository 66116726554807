import React from 'react';
import styled from 'styled-components';
import { Spacer } from 'comps/layout';
import { Input, Button, FormField } from 'comps/form';
import { Radio } from 'ui';

interface IProps {
  durationType: RADIO;
  challengers: FormField;
  traffic: FormField;
  mean: FormField;
  standard_deviation: FormField;
  minimum_lift: FormField;
  power: FormField;
  adjust: FormField;
  response_rate: FormField;
  onChange: (field: FormField, value: any) => void;
  radioOnChange: (durationType: RADIO) => void;
}
enum RADIO {
  Proportions,
  Continuous
}

const SampleSizeForm = (props: IProps) => {
  const { challengers, mean, standard_deviation, traffic, power, durationType, onChange, radioOnChange, minimum_lift, adjust, response_rate } = props;

  return (
    <div>
      <div className="form-group">
        <div>
          <RadioDurationType isSelected={durationType === RADIO.Proportions} onChange={radioOnChange} name="Proportions" />
          <p>
            Use this for binary conversion metrics, which are typically rates or percentage based. This method relies on aggregate data, as opposed to user
            level data.
          </p>
          <Spacer size={2} />
          <RadioDurationType isSelected={durationType === RADIO.Continuous} onChange={radioOnChange} name="Continuous" />
          <div>
            <p>
              Use for non-binary conversion metrics, like revenue. This method relies on user level data and requires input of the mean and standard deviation.
            </p>
          </div>
        </div>
      </div>
      <div className="form-group flex-row">
        <Input field={challengers} maxLength={3} onChange={e => onChange(challengers, e.target.value)} className="form-control" />
        <Spacer />
        <div>
          <div className="subtitle">Variations</div>
          <p>Include the number of variations/ challengers you'd like to run, not including the control.</p>
        </div>
      </div>
      <div className="form-group">
        <Input
          field={traffic}
          onChange={e => {
            onChange(traffic, e.target.value);
          }}
          value={traffic.value}
          className="form-control"
          maxLength={8}
          controlled
        />
        <Spacer />
        <div>
          <div className="subtitle">Daily Traffic</div>
          <p>Provide the daily traffic volume that you expect to have during the experiment.</p>
        </div>
      </div>
      {durationType === RADIO.Continuous && (
        <>
          <div className="form-group">
            <Input field={mean} onChange={e => onChange(mean, e.target.value)} className="form-control" />
            <Spacer />
            <div>
              <div className="subtitle">Mean</div>
              <p>Enter the mean value for the primary metric you'll be optimizing.</p>
            </div>
          </div>
          <div className="form-group">
            <Input field={standard_deviation} onChange={e => onChange(standard_deviation, e.target.value)} className="form-control" />
            <Spacer />
            <div>
              <div className="subtitle">Standard Deviation</div>
              <p>Enter the standard deviation for the primary metric you'll be optimizing.</p>
            </div>
          </div>
        </>
      )}
      <div className="form-group">
        <div className="relative">
          <Input field={minimum_lift} onChange={e => onChange(minimum_lift, e.target.value)} className="form-control" />
          <p>%</p>
        </div>
        <Spacer />
        <div>
          <div className="subtitle">Minimum Detectable Effect</div>
          <p>Specify the minimum improvement over the Control that you're willing to detect in the experiment.</p>
        </div>
      </div>
      <div className="form-group">
        <div className="relative">
          <Input field={power} onChange={e => onChange(power, e.target.value)} className="form-control" />
          <p>%</p>
        </div>
        <Spacer />
        <div>
          <div className="subtitle">Power</div>
          <p>We've defaulted to 80% Power as the standard, but feel free to adjust.</p>
        </div>
      </div>
      {durationType === RADIO.Proportions && (
        <div className="form-group">
          <div className="relative">
            <Input field={response_rate} onChange={e => onChange(response_rate, e.target.value)} className="form-control" />
            <p>%</p>
          </div>
          <Spacer />
          <div>
            <div className="subtitle">Response Rate</div>
            <p>Enter the current conversion rate for the primary metric you'll be optimizing.</p>
          </div>
        </div>
      )}

      <div className="form-group align-items">
        <div className="toggle">
          <label className="switch">
            <input type="checkbox" defaultChecked={adjust.value} onChange={e => onChange(adjust, e.target.checked)} />
            <span className="slider round"></span>
          </label>
        </div>
        <Spacer />
        <Spacer />
        <div>
          <div className="subtitle">Adjust</div>
          <p>Counteract the multiple comparison problem by allowing our calculation to adjust for it.</p>
        </div>
      </div>
      <hr className="boundary" />
      <div className="submit">
        <Button className="btn btn-primary" text="Calculate" isLoading={false} />
      </div>
    </div>
  );
};

export { SampleSizeForm };

const RadioDurationType = (props: { isSelected: boolean; onChange: (value: any) => void; name: string }) => {
  const { isSelected, onChange, name } = props;
  const changeHandler = () => (name === 'Proportions' ? onChange(RADIO.Proportions) : onChange(RADIO.Continuous));
  return (
    <StyledRadio>
      <Radio onChange={changeHandler} name={name} isSelected={isSelected} />
    </StyledRadio>
  );
};

const StyledRadio = styled.div`
  display: inline-block;
  border-radius: 999px;
  margin: 0px;

  &:hover {
    background-color: rgba(#7256f8, 0.1);
    &::before {
      border: 2px solid #7256f8;
    }
  }
  input {
    opacity: 0;
    position: absolute;
  }

  input ~ label {
    &:before {
      border: 1px solid lightgrey;
    }
  }
  input:checked ~ label {
    &:before {
      background-color: #2a88f2;
      border: 2px solid #2a88f2;
    }
    &:after {
      width: 5px;
      height: 5px;
      top: 32%;
      left: 6%;
      background: #fff;
    }
  }

  label {
    border-radius: 50%;
    padding: 3px 15px 3px 20px;
    cursor: pointer;
    position: relative;
    transition: all 0.5s;
    &::before,
    &::after {
      content: '';
      border-radius: 50%;
      width: 16px;
      height: 16px;
      margin: 3px 0;
      position: absolute;
      z-index: 1;
    }
    &::before {
      border: 2px solid #dcdcdc;
      top: 4px;
      left: 2px;
      transition: all 0.5s;
    }
    &::after {
      top: 6px;
      left: 12px;
      transition: all 0.15s;
      transition-timing-function: ease-out;
    }
  }
`;
