import React, { Component } from "react";
import { connect } from "react-redux";
import { createLoadingSelector } from "store/selectors";
import * as DataTypes from "store/types";
import { ApplicationState } from "store/types";
import { TabsContainer } from "../style";
import { ReportTab } from "../report_tab";
import { MetricTab } from "../metric_tab";
import { createCustomDataVizRequest } from "store/custom_data_viz/actions";
import { NameTab } from "../name_tab";
import { ExperiencesTab } from "../experience_tab";
import { VisualsTab } from "../visuals_tab";

interface IProps {
  experiment: DataTypes.Experiment | null;
  createCustomDataVizRequest(experimentId: string, body: any): void;
  creatingLoading: boolean;
}

type TabsDefnition = {
  REPORTS: string;
  METRICS: string;
  NAME: string;
  EXPERIENCES: string;
  VISUALS: string;
};
const Tabs: TabsDefnition = {
  NAME: "Name",
  REPORTS: "Reports",
  EXPERIENCES: "Experiences",
  METRICS: "Metrics",
  VISUALS: "Visuals",
};

interface IState {
  name: string;
  activeTab: keyof TabsDefnition;
  reports: DataTypes.Report[];
  experiences: DataTypes.Experience[];
  metrics: DataTypes.DataVizMetric[];
  errors: any;
}

class CreateCustomDataViz extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      name: "",
      activeTab: "NAME",
      reports: props.experiment?.reports || [],
      experiences: props.experiment?.experiences || [],
      metrics: [],
      errors: {},
    };
  }

  private submit = () => {
    if (!this.props.experiment?.id) {
      return;
    }

    const body = {
      name: this.state.name,
      chart_kind: "pieChart",
      report_ids: this.state.reports.map((report) => report.id),
      experience_ids: this.state.experiences.map((experience) => experience.id),
      metrics: this.state.metrics,
    };

    this.props.createCustomDataVizRequest(this.props.experiment?.id, body);
  };

  private isValid = () => {
    if (this.state.activeTab === "NAME") {
      if (!this.state.name || this.state.name === "") {
        return false;
      }
    }

    if (this.state.activeTab === "REPORTS") {
      if (!this.state.reports || this.state.reports.length === 0) {
        return false;
      }
    }

    if (this.state.activeTab === "EXPERIENCES") {
      if (!this.state.experiences || this.state.experiences.length === 0) {
        return false;
      }
    }

    if (this.state.activeTab === "METRICS") {
      if (!this.state.metrics || this.state.metrics.length === 0) {
        return false;
      }

      let hasError = false;

      this.state.metrics.forEach((metric) => {
        if (metric.metric_ids.length !== this.state.reports.length) {
          hasError = true;
        }
      });

      if (hasError) {
        return false;
      }
    }

    return true;
  };

  render() {
    if (!this.props.experiment) {
      return null;
    }

    return (
      <React.Fragment>
        <div className="header">
          <h3>Create a Custom Data Visualization </h3>
        </div>
        <div className="body">
          <div className="header">
            <p>
              You decide what visual best represents the data! Create a "report"
              that uses existing report data with the visualization of your
              choice. This is a great way to compare metrics across reports.
            </p>
          </div>
        </div>

        <TabsContainer>
          <ul className="tab_list">
            {Object.keys(Tabs).map((tab, index) => (
              <li
                key={`custom_data_viz_tab_${tab}`}
                className={this.state.activeTab === tab ? "active" : ""}
              >
                <div className="step-circle">{index + 1}</div>
                <span>{Tabs[tab as keyof TabsDefnition]}</span>
              </li>
            ))}
          </ul>

          <div className="tab-content">
            <NameTab
              active={this.state.activeTab === "NAME"}
              name={this.state.name}
              onChangeName={(name: string) => this.setState({ name })}
            />

            <ReportTab
              active={this.state.activeTab === "REPORTS"}
              experiment={this.props.experiment}
              onSelectReport={(reports) => this.setState({ reports })}
            />

            <ExperiencesTab
              active={this.state.activeTab === "EXPERIENCES"}
              experiment={this.props.experiment}
              onSelectExperience={(experiences) =>
                this.setState({ experiences })
              }
            />

            <MetricTab
              active={this.state.activeTab === "METRICS"}
              selectedReports={this.state.reports}
              onSelectMetric={(metrics) => this.setState({ metrics })}
            />

            <VisualsTab
              active={this.state.activeTab === "VISUALS"}
              metrics={this.state.metrics}
              onChangeChartKind={(metrics) => this.setState({ metrics })}
            />
          </div>
        </TabsContainer>

        <div className="cntrl-bar">
          {this.state.activeTab !== "NAME" ? (
            <button
              type="button"
              className="btn btn-link"
              onClick={() => {
                const tabKeys = Object.keys(Tabs);
                const currentIndex = tabKeys.lastIndexOf(this.state.activeTab);

                this.setState({
                  activeTab: tabKeys[currentIndex - 1] as keyof TabsDefnition,
                });
              }}
            >
              Back
            </button>
          ) : null}

          {this.state.activeTab === "VISUALS" ? (
            this.props.creatingLoading ? (
              <button
                aria-label="loading"
                className="btn btn-primary"
                type="button"
                disabled={true}
              >
                <i className="fas fa-circle-notch fa-spin" />
              </button>
            ) : (
              <button
                type="button"
                disabled={this.props.creatingLoading}
                className="btn btn-primary"
                onClick={() => this.submit()}
              >
                Create
              </button>
            )
          ) : (
            <button
              type="button"
              className="btn btn-primary"
              disabled={!this.isValid()}
              onClick={() => {
                const tabKeys = Object.keys(Tabs);
                const currentIndex = tabKeys.lastIndexOf(this.state.activeTab);

                this.setState({
                  activeTab: tabKeys[currentIndex + 1] as keyof TabsDefnition,
                });
              }}
            >
              Next
            </button>
          )}
        </div>
      </React.Fragment>
    );
  }
}

const creatingLoaderSelector = createLoadingSelector([
  "@@customDataViz/CREATE_CUSTOM_DATA_VIZ",
]);

const mapStateToProps = ({ app }: ApplicationState) => ({
  creatingLoading: creatingLoaderSelector(app.requests),
});

const mapDispatchToProps = {
  createCustomDataVizRequest,
};

const connectedPage = connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateCustomDataViz);

export { connectedPage as CreateCustomDataViz };
