import { ApiClient } from "./client/api_client";

class ProgramGoals {
  static async create(body: any) {
    return await ApiClient.post("/v1/account/program_goal", body);
  }

  static async update(id: string, body: any) {
    return ApiClient.put(`/v1/account/program_goal/${id}`, {
      program_goal: body,
    });
  }
}

export { ProgramGoals };
