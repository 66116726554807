import { action } from 'typesafe-actions';
import { Dispatch } from 'redux';
import { ActionTypes } from './types';
import * as DataTypes from '../types';
import { OrderDirection } from 'ui/order_icon';

interface RequestError {
  statusCode?: number | undefined;
  message: any;
}

// Init Idea Create
export const initIdeaCreateRequest = () => (dispatch: Dispatch) => dispatch(action(ActionTypes.INIT_IDEA_CREATE_REQUEST, {}));
export const initIdeaCreateSuccess = () => action(ActionTypes.INIT_IDEA_CREATE_SUCCESS, {});
export const initIdeaCreateError = (error: RequestError) => action(ActionTypes.INIT_IDEA_CREATE_FAILURE, error);

// Create Idea
export const createIdeaRequest = (body: any) => (dispatch: Dispatch) => dispatch(action(ActionTypes.CREATE_IDEA_REQUEST, { body }));
export const createIdeaSuccess = (response: DataTypes.ApiRequest<DataTypes.Idea>) => action(ActionTypes.CREATE_IDEA_SUCCESS, { response });
export const createIdeaError = (error: RequestError) => action(ActionTypes.CREATE_IDEA_FAILURE, error);

// Update Idea by id
export const updateIdeaRequest = (id: string, body: any) => (dispatch: Dispatch) => dispatch(action(ActionTypes.UPDATE_IDEA_REQUEST, { id, body }));
export const updateIdeaSuccess = (response: DataTypes.ApiRequest<DataTypes.Idea>) => action(ActionTypes.UPDATE_IDEA_SUCCESS, { response });
export const updateIdeaError = (error: RequestError) => action(ActionTypes.UPDATE_IDEA_FAILURE, error);

// Update Idea by id
export const archiveIdeaRequest = (id: string, body: any) => (dispatch: Dispatch) => dispatch(action(ActionTypes.ARCHIVE_IDEA_REQUEST, { id, body }));
export const archiveIdeaSuccess = (response: DataTypes.ApiRequest<DataTypes.Idea>) => action(ActionTypes.ARCHIVE_IDEA_SUCCESS, { response });
export const archiveIdeaError = (error: RequestError) => action(ActionTypes.ARCHIVE_IDEA_FAILURE, error);

// Delete Idea by id
export const deleteIdeaRequest = (id: string) => (dispatch: Dispatch) => dispatch(action(ActionTypes.DELETE_IDEA_REQUEST, { id }));
export const deleteIdeaSuccess = (response: DataTypes.ApiRequest<DataTypes.Idea>) => action(ActionTypes.DELETE_IDEA_SUCCESS, { response });
export const deleteIdeaError = (error: RequestError) => action(ActionTypes.DELETE_IDEA_FAILURE, error);

// Get Ideas
export const getIdeasRequest = (options?: DataTypes.ApiListOptions, scrollOpts?: { append: boolean }) =>
  action(ActionTypes.GET_IDEAS_REQUEST, { options, scrollOpts });
export const getIdeasSuccess = (response: DataTypes.ApiList<DataTypes.Idea>, append?: boolean) => action(ActionTypes.GET_IDEAS_SUCCESS, { response, append });
export const getIdeasError = (error: RequestError) => action(ActionTypes.GET_IDEAS_FAILURE, error);

// Show Idea
export const showIdeaRequest = (id: string, callback: Function) => (dispatch: Dispatch) => dispatch(action(ActionTypes.SHOW_IDEA_REQUEST, { id, callback }));
export const showIdeaSuccess = (response: DataTypes.Idea) => action(ActionTypes.SHOW_IDEA_SUCCESS, { response });
export const showIdeaError = (error: RequestError) => action(ActionTypes.SHOW_IDEA_FAILURE, error);

// Create Share Idea
export const createShareIdeaRequest = (slug: string, idea_board_id: string, body: any, password?: string) => (dispatch: Dispatch) =>
  dispatch(action(ActionTypes.CREATE_SHARE_IDEA_REQUEST, { slug, idea_board_id, body, password }));
export const createShareIdeaSuccess = (response: DataTypes.ApiRequest<DataTypes.Idea>) => action(ActionTypes.CREATE_SHARE_IDEA_SUCCESS, { response });
export const createShareIdeaError = (error: RequestError) => action(ActionTypes.CREATE_SHARE_IDEA_FAILURE, error);

export const clearIdeaRequest = () => (dispatch: Dispatch) => dispatch(action(ActionTypes.CLEAR_IDEA_REQUEST, {}));
export const clearIdeaSuccess = () => action(ActionTypes.CLEAR_IDEA_SUCCESS, {});
export const clearIdeaError = (error: RequestError) => action(ActionTypes.CLEAR_IDEA_FAILURE, error);

export const reorderIdeaRequest = (id: string, position: number, currentOrder: OrderDirection) =>
  (dispatch: Dispatch) => dispatch(action(ActionTypes.REORDER_REQUEST, { id, position, currentOrder }));
export const reorderIdeaSuccess = (response: DataTypes.ApiList<DataTypes.Idea>, currentOrder: OrderDirection) =>
  action(ActionTypes.REORDER_SUCCESS, { response, currentOrder });
export const reorderIdeaError = (error: RequestError) => action(ActionTypes.REORDER_FAILURE);

export const uploadIdeasRequest = (file: any) =>
  (dispatch: Dispatch) => dispatch(action(ActionTypes.UPLOAD_IDEAS_REQUEST, { file }));
export const uploadIdeasSuccess = (response: DataTypes.ApiRequest<DataTypes.Idea>) =>
  action(ActionTypes.UPLOAD_IDEAS_SUCCESS, { response });
export const uploadIdeasError = (error: RequestError) =>
  action(ActionTypes.UPLOAD_IDEAS_FAILURE, error);

export const exportIdeasRequest = (queryString: string, fields: string[]) =>
  (dispatch: Dispatch) => dispatch(action(ActionTypes.EXPORT_IDEAS_REQUEST, { queryString, fields }));
export const exportIdeasSuccess = (response: DataTypes.ApiRequest<void>) =>
  action(ActionTypes.EXPORT_IDEAS_SUCCESS, { response });
export const exportIdeasError = (error: RequestError) =>
  action(ActionTypes.EXPORT_IDEAS_FAILURE, error);