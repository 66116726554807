"use client";

import styled from "styled-components";

export const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 1rem;
  gap: 2rem;

  .header {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 1rem;
  }

  ul {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    align-items: center;
    overflow-y: auto;
    padding-bottom: 2rem;

    li {
      box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
      padding: 1rem;
      width: 80%;
      border-radius: 2px;
      cursor: pointer;

      p {
        font-size: 0.875rem;
        font-weight: 400;
        color: #2f4760;
        line-height: 1.2rem;

        &.slug {
          font-size: 0.765rem;
        }
      }
    }
  }
`;

export const LayoutContainer = styled.div`
  display: flex;
  align-items: center;
  height: 100vh;
  width: 100vw;

  .illustration {
    display: none;

    @media (min-width: 62rem) {
      display: block;
    }
  }
`;
