import React, { Component } from "react";
import * as DataTypes from "store/types";

interface IProps {
  uxr: DataTypes.Uxr;
  saving: boolean;
  onSubmit(selectedExperiences: Array<string>): void;
}

interface IState {
  selectedAreaOFFocus: Array<string>;
}

class SelectAreaOfFocus extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    const { uxr } = this.props;

    this.state = {
      selectedAreaOFFocus: uxr.share?.area_of_focus || [],
    };
  }

  private onCheck = (checked: boolean, data: DataTypes.AreaOfFocus) => {
    const datas = this.state.selectedAreaOFFocus;

    if (checked === true) {
      datas.push(data.id);
    } else {
      const iIdx = datas.findIndex((i) => i === data.id);
      if (iIdx >= 0) datas.splice(iIdx, 1);
    }

    this.setState({ ...this.state, selectedAreaOFFocus: datas });
  };

  render() {
    const { uxr, onSubmit, saving } = this.props;
    const { selectedAreaOFFocus } = this.state;
    return (
      <React.Fragment>
        <div className="header">
          <h3>Select Qualitative Data</h3>
          <p>Select the experiences to show in the case study.</p>
        </div>
        <div className="body">
          {uxr.area_of_focus.map((data, idx) => {
            return (
              <div key={`exp_${data.id}`} className="ExperienceSelector">
                <div className="selector">
                  <label className="CheckBox">
                    <input
                      type="checkbox"
                      defaultChecked={selectedAreaOFFocus.includes(data.id)}
                      onChange={(e) => this.onCheck(e.target.checked, data)}
                    />
                    <span className="checkmark"></span>
                  </label>
                </div>
                <div className="experience">
                  <div className="head">
                    <div className="hexagon">
                      <label style={{ paddingLeft: "24px" }}>
                        {data.theme || 'New Area of Focus'}
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
        <div className="cntrl-bar">
          {saving === true ? (
            <button className="btn btn-primary" type="submit" disabled={true}>
              <i className="fas fa-circle-notch fa-spin" />
            </button>
          ) : (
            <button
              className="btn btn-primary"
              onClick={() => onSubmit(selectedAreaOFFocus)}
            >
              save
            </button>
          )}
        </div>
      </React.Fragment>
    );
  }
}

export { SelectAreaOfFocus };
