import React, { Component } from "react";
import * as DataTypes from "store/types";

interface IProps {
  dataViz: DataTypes.CustomDataViz;
  saving: boolean;
  onSubmit(dataViz: DataTypes.CustomDataViz): void;
}

class DeleteCustomDataViz extends Component<IProps> {
  render() {
    const { onSubmit, saving } = this.props;

    return (
      <React.Fragment>
        <div className="header">
          <h3>Delete Data Visualization</h3>
          <p>
            Are you sure you want to delete this data visualization from this
            experiment?
          </p>
        </div>
        <div className="cntrl-bar">
          {saving === true ? (
            <button
              title="close"
              className="btn btn-danger"
              type="submit"
              disabled={true}
            >
              <i className="fas fa-circle-notch fa-spin" />
            </button>
          ) : (
            <button
              className="btn btn-danger"
              onClick={() => onSubmit(this.props.dataViz)}
            >
              delete data visualization
            </button>
          )}
        </div>
      </React.Fragment>
    );
  }
}

export { DeleteCustomDataViz };
