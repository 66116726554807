import { Reducer } from "redux";
import * as ActionTypes from "./types";
import { ActionTypes as UserActionTypes } from "../user/types";
import * as DataTypes from "../types";
import * as Account from "../account/types";
import * as Experiment from "../experiment/types";
import * as Insight from "../insight/types";
import * as Idea from "../idea/types";
import * as IdeaBoard from "../idea_board/types";
import * as Tasks from "../task/types";
import * as IdeaBoardManagement from "../idea_board_management/types";
import * as IdeaManagement from "../idea_management/types";
import * as UserAccount from "../user_accounts/types";
import * as Uxr from "../uxr/types";
import { OrderDirection } from "ui/order_icon";

const initialState: DataTypes.AppState = {
  requests: {},
  errors: {},
  alert: {
    show: false,
    content: undefined,
  },
  modal: {
    show: false,
    component: undefined,
    options: {},
    saving: false,
  },
};

export const appReducer: Reducer<DataTypes.AppState> = (
  state = initialState,
  action
) => {
  const { type, payload } = action;

  switch (type) {
    case UserActionTypes.SIGNUP_SUCCESS: {
      return { ...state, signupStep: "pending" };
    }
    case ActionTypes.App.MODAL_SHOW: {
      return {
        ...state,
        modal: {
          show: true,
          saving: false,
          className: action.payload.className,
          component: action.payload.component,
          options: action.payload.options,
        },
      };
    }
    case ActionTypes.App.MODAL_SAVING: {
      return {
        ...state,
        modal: { ...state.modal, saving: true },
      };
    }
    case ActionTypes.App.MODAL_SAVE_COMPLETE: {
      return {
        ...state,
        modal: { ...state.modal, saving: false },
      };
    }
    case ActionTypes.App.MODAL_ERROR: {
      return {
        ...state,
        modal: { ...state.modal, saving: false },
      };
    }
    case ActionTypes.App.MODAL_HIDE: {
      return {
        ...state,
        modal: {
          show: false,
          saving: false,
          component: undefined,
          options: {},
        },
      };
    }
    case ActionTypes.App.MODAL_OPTIONS: {
      return {
        ...state,
        modal: {
          ...state.modal,
          saving: false,
          options: { ...state.modal.options, ...action.options },
        },
      };
    }
    case ActionTypes.App.ALERT_SHOW: {
      if (!action) return state;
      const { content, error } = action.payload ? action.payload : action;
      return content
        ? {
            ...state,
            alert: { show: true, content, error },
          }
        : state;
    }
    case ActionTypes.App.ALERT_HIDE: {
      return {
        ...state,
        alert: { show: false, content: undefined },
      };
    }
    default: {
      const matches = /(.*)_(REQUEST|SUCCESS|FAILURE)/.exec(type);
      if (!matches) return state;
      const [, requestName, requestState] = matches;
      return {
        ...state,
        requests: {
          ...state.requests,
          [requestName]: {
            loading: requestState === "REQUEST",
            error: requestState === "FAILURE" ? payload : null,
          },
        },
        errors: {
          ...state.errors,
          [requestName]: requestState === "FAILURE" ? payload : null,
        },
      };
    }
  }
};

const reorderSort = (list: any, direction: any) => {
  list.sort((a: any, b: any) => {
    if (direction === OrderDirection.ASC) {
      return (
        (a.custom_order || a.customOrder) - (b.custom_order || b.customOrder)
      );
    }

    return (
      (b.custom_order || b.customOrder) - (a.custom_order || a.customOrder)
    );
  });
};

const reorderReAsing = (list: any, item: any) => {
  const idx = list.data.findIndex((value: any) => item.id === value.id);
  const oldPosition = list.data[idx].custom_order || list.data[idx].customOrder;
  const newPosition = item.custom_order || item.data[idx].customOrder;
  const positionGrew = newPosition > oldPosition;

  return list.data.map((mapItem: any) => {
    if (mapItem.id === item.id) {
      return item;
    }

    const itemPosition = mapItem.custom_order || mapItem.customOrder;

    if (positionGrew) {
      if (oldPosition < itemPosition && itemPosition <= newPosition) {
        return {
          ...mapItem,
          custom_order: itemPosition - 1,
          customOrder: itemPosition - 1,
        };
      }
    } else {
      if (itemPosition >= newPosition && itemPosition < oldPosition) {
        return {
          ...mapItem,
          custom_order: itemPosition + 1,
          customOrder: itemPosition + 1,
        };
      }
    }

    return mapItem;
  });
};

export const collectionsReducer: Reducer<any> = (state = {}, action) => {
  switch (action.type) {
    case UserAccount.ActionTypes.GET_USER_ACCOUNTS_SUCCESS: {
      return { ...state, userAccounts: action.payload };
    }
    case Account.ActionTypes.GET_USERS_SUCCESS: {
      if (action.payload.append) {
        return {
          ...state,
          users: {
            metadata: action.payload.response.metadata,
            data: [...state.users.data, ...action.payload.response.data],
            has_more: action.payload.response.has_more,
          },
        };
      }

      return { ...state, users: action.payload.response };
    }
    case Experiment.ActionTypes.GET_EXPERIMENTS_REQUEST: {
      if (action.payload.scrollOpts?.append === true) {
        return { ...state };
      }
      return { ...state, experiments: undefined };
    }
    case Uxr.ActionTypes.DELETE_UXR_SUCCESS: {
      const uxrs: DataTypes.ApiList<DataTypes.Uxr> = state.uxrs;
      const idx = uxrs.data.findIndex(
        (uxrs) => uxrs.id === action.payload.response.id
      );

      if (idx >= 0) uxrs.data.splice(idx, 1);
      return {
        ...state,
        uxrs,
      };
    }
    case Uxr.ActionTypes.GET_UXRS_SUCCESS: {
      if (action.payload.append === true) {
        return {
          ...state,
          uxrs: {
            ...state.uxrs,
            ...action.payload.response.data.uxrs.nodes,
            has_more: action.payload.response.data.uxrs.pageInfo.hasNextPage,
            metadata: action.payload.response.data.uxrs.pageInfo,
            data: state.uxrs.data.concat(
              action.payload.response.data.uxrs.nodes
            ),
          },
        };
      }
      return {
        ...state,
        uxrs: {
          has_more: action.payload.response.data.uxrs.pageInfo.hasNextPage,
          metadata: action.payload.response.data.uxrs.pageInfo,
          data: action.payload.response.data.uxrs.nodes,
        },
      };
    }
    case Uxr.ActionTypes.UPDATE_UXR_SUCCESS: {
      const uxrs: DataTypes.ApiList<DataTypes.Experiment> = state.uxrs;
      if (uxrs) {
        const idx = uxrs.data.findIndex(
          (uxr) => uxr.id === action.payload.response.id
        );

        if (idx >= 0) {
          uxrs.data[idx] = {
            ...uxrs.data[idx],
            ...action.payload.response,
          };
        }

        return {
          ...state,
          uxrs,
        };
      } else {
        return { ...state };
      }
    }
    case Experiment.ActionTypes.GET_EXPERIMENTS_SUCCESS: {
      if (action.payload.append === true) {
        return {
          ...state,
          experiments: {
            ...state.experiments,
            ...action.payload.response.data.experiments.nodes,
            has_more:
              action.payload.response.data.experiments.pageInfo.hasNextPage,
            metadata: action.payload.response.data.experiments.pageInfo,
            data: state.experiments.data.concat(
              action.payload.response.data.experiments.nodes
            ),
          },
        };
      }
      return {
        ...state,
        experiments: {
          has_more:
            action.payload.response.data.experiments.pageInfo.hasNextPage,
          metadata: action.payload.response.data.experiments.pageInfo,
          data: action.payload.response.data.experiments.nodes,
        },
      };
    }
    case Experiment.ActionTypes.UPDATE_AB_EXPERIMENT_SUCCESS:
    case Experiment.ActionTypes.UPDATE_ADOBE_EXPERIMENT_SUCCESS:
    case Experiment.ActionTypes.UPDATE_EXPERIMENT_SUCCESS: {
      const experiments: DataTypes.ApiList<DataTypes.Experiment> =
        state.experiments;
      if (experiments) {
        const idx = experiments.data.findIndex(
          (experiment) => experiment.id === action.payload.response.id
        );

        if (idx >= 0) {
          experiments.data[idx] = {
            ...experiments.data[idx],
            ...action.payload.response,
          };
        }

        return {
          ...state,
          experiments: experiments,
        };
      } else {
        return { ...state };
      }
    }
    case Uxr.ActionTypes.REORDER_SUCCESS: {
      const uxrs: DataTypes.ApiList<DataTypes.Uxr> = state.uxrs;
      let newUxr = action.payload.response;
      const currentOrder = action.payload.currentOrder;
      const idx = uxrs.data.findIndex((uxr) => uxr.id === newUxr.id);

      if (idx < 0) {
        return { ...state };
      }

      const newList = reorderReAsing(uxrs, newUxr);
      reorderSort(newList, currentOrder);

      return {
        ...state,
        uxrs: { ...uxrs, data: newList },
      };
    }
    case Experiment.ActionTypes.REORDER_SUCCESS: {
      const experiments: DataTypes.ApiList<DataTypes.Experiment> =
        state.experiments;
      let newExperiment = action.payload.response;
      const currentOrder = action.payload.currentOrder;
      const idx = experiments.data.findIndex(
        (experiment) => experiment.id === newExperiment.id
      );

      if (idx < 0) {
        return { ...state };
      }

      const newList = reorderReAsing(experiments, newExperiment);
      reorderSort(newList, currentOrder);

      return {
        ...state,
        experiments: { ...experiments, data: newList },
      };
    }
    case Experiment.ActionTypes.CLEAR_EXPERIMENTS: {
      return { ...state, experiments: [] };
    }
    case IdeaBoardManagement.ActionTypes.CLEAR_IDEA_BOARDS_MANAGEMENT: {
      return { ...state, idea_boards_management: {} };
    }
    case Insight.ActionTypes.CLEAR_INSIGHTS: {
      return { ...state, insights: null };
    }
    case Insight.ActionTypes.GET_INSIGHTS_SUCCESS: {
      if (action.payload.append === true) {
        return {
          ...state,
          insights: {
            ...state.insights,
            ...action.payload.response,
            data: state.insights.data.concat(action.payload.response.data),
          },
        };
      }
      return { ...state, insights: action.payload.response };
    }
    case Insight.ActionTypes.CREATE_INSIGHT_NOTE_SUCCESS:
    case Insight.ActionTypes.DELETE_NOTE_SUCCESS:
    case Insight.ActionTypes.UPDATE_INSIGHT_SUCCESS: {
      const insights: DataTypes.ApiList<DataTypes.Insight> = state.insights;
      const idx = insights.data.findIndex(
        (insight) => insight.id === action.payload.response.id
      );

      if (idx >= 0) {
        insights.data[idx] = {
          ...insights.data[idx],
          ...action.payload.response,
        };
      }

      return {
        ...state,
        insights: insights,
      };
    }
    case Insight.ActionTypes.REORDER_SUCCESS: {
      const insights: DataTypes.ApiList<DataTypes.Insight> = state.insights;
      let newInsight = action.payload.response;
      const currentOrder = action.payload.currentOrder;
      const idx = insights.data.findIndex((idea) => idea.id === newInsight.id);

      if (idx < 0) {
        return { ...state };
      }

      const newList = reorderReAsing(insights, newInsight);
      reorderSort(newList, currentOrder);

      return {
        ...state,
        insights: { ...insights, data: newList },
      };
    }
    case Idea.ActionTypes.GET_IDEAS_SUCCESS: {
      if (action.payload.append === true) {
        return {
          ...state,
          ideas: {
            ...state.ideas,
            ...action.payload.response,
            data: state.ideas.data.concat(action.payload.response.data),
          },
        };
      }
      return { ...state, ideas: action.payload.response };
    }
    case Idea.ActionTypes.UPDATE_IDEA_REQUEST: {
      const ideas: DataTypes.ApiList<DataTypes.Idea> = state.ideas;
      const idx = ideas.data.findIndex((idea) => idea.id === action.payload.id);

      if (idx >= 0) {
        ideas.data[idx] = { ...ideas.data[idx], ...action.payload.body.idea };
      }

      return {
        ...state,
        ideas: ideas,
      };
    }
    case Idea.ActionTypes.UPDATE_IDEA_SUCCESS: {
      const ideas: DataTypes.ApiList<DataTypes.Idea> = state.ideas;
      const idx = ideas.data.findIndex(
        (idea) => idea.id === action.payload.response.id
      );

      if (idx >= 0) {
        ideas.data[idx] = { ...ideas.data[idx], ...action.payload.response };
      }

      return {
        ...state,
        ideas: ideas,
      };
    }
    case Idea.ActionTypes.REORDER_SUCCESS: {
      const ideas: DataTypes.ApiList<DataTypes.Idea> = state.ideas;
      let newIdea = action.payload.response;
      const currentOrder = action.payload.currentOrder;
      const idx = ideas.data.findIndex((idea) => idea.id === newIdea.id);

      if (idx < 0) {
        return { ...state };
      }

      const newList = reorderReAsing(ideas, newIdea);
      reorderSort(newList, currentOrder);

      return {
        ...state,
        ideas: { ...ideas, data: newList },
      };
    }
    case IdeaManagement.ActionTypes.ADD_THEMES_TO_IDEAS_SUCCESS: {
      const updatedIdeas = action.payload.response;

      const newData = state.ideas.data.map((idea: any) => {
        const newIdea = updatedIdeas.find((i: any) => i.id === idea.id);

        if (newIdea) {
          return newIdea;
        }

        return idea;
      });

      return {
        ...state,
        ideas: {
          ...state.ideas,
          data: newData,
        },
      };
    }
    case Idea.ActionTypes.ARCHIVE_IDEA_SUCCESS:
    case Idea.ActionTypes.DELETE_IDEA_SUCCESS: {
      const ideas: DataTypes.ApiList<DataTypes.Idea> = state.ideas;
      const idx = ideas.data.findIndex(
        (idea) => idea.id === action.payload.response.id
      );

      if (idx >= 0) ideas.data.splice(idx, 1);
      return {
        ...state,
        ideas: ideas,
      };
    }
    case IdeaBoard.ActionTypes.GET_IDEA_BOARDS_SUCCESS: {
      return { ...state, idea_boards: action.payload.response };
    }
    case IdeaBoardManagement.ActionTypes.REORDER_SUCCESS: {
      const ideaBoards: DataTypes.ApiList<DataTypes.IdeaBoard> =
        state.idea_boards_management;
      let newIdeaBoard = action.payload.response;
      const currentOrder = action.payload.currentOrder;
      const idx = ideaBoards.data.findIndex(
        (idea) => idea.id === newIdeaBoard.id
      );

      if (idx < 0) {
        return { ...state };
      }

      const newList = reorderReAsing(ideaBoards, newIdeaBoard);
      reorderSort(newList, currentOrder);

      return {
        ...state,
        idea_boards_management: { ...ideaBoards, data: newList },
      };
    }
    case IdeaBoardManagement.ActionTypes.GET_IDEA_BOARDS_MANAGEMENT_SUCCESS: {
      if (action.payload.append) {
        return {
          ...state,
          idea_boards_management: {
            ...state.idea_boards_management,
            ...action.payload.response,
            data: state.idea_boards_management.data.concat(
              action.payload.response.data
            ),
          },
        };
      }

      return { ...state, idea_boards_management: action.payload.response };
    }
    case IdeaBoardManagement.ActionTypes.UPDATE_IDEA_BOARD_MANAGEMENT_SUCCESS: {
      const ideaBoards: DataTypes.ApiList<DataTypes.IdeaBoard> =
        state.idea_boards_management;
      const idx = ideaBoards.data.findIndex(
        (board) => board.id === action.payload.response.id
      );

      if (idx >= 0) {
        ideaBoards.data[idx] = {
          ...ideaBoards.data[idx],
          ...action.payload.response,
        };
      }

      return {
        ...state,
        idea_boards_management: ideaBoards,
      };
    }
    case IdeaBoardManagement.ActionTypes.ARCHIVE_IDEA_BOARD_MANAGEMENT_SUCCESS:
    case IdeaBoardManagement.ActionTypes.DELETE_IDEA_BOARD_MANAGEMENT_SUCCESS: {
      const ideaBoards: DataTypes.ApiList<DataTypes.IdeaBoard> =
        state.idea_boards_management;
      const idx = ideaBoards.data.findIndex(
        (ideaBoard) => ideaBoard.id === action.payload.response.id
      );

      if (idx >= 0) {
        ideaBoards.data.splice(idx, 1);
      }

      return {
        ...state,
        idea_boards_management: ideaBoards,
      };
    }
    case Account.ActionTypes.GET_PENDING_INVITES_SUCCESS: {
      return { ...state, pendingInvites: action.payload.response };
    }
    case Tasks.ActionTypes.GET_TASKS_SUCCESS: {
      return { ...state, tasks: action.payload.response };
    }
    default: {
      return state;
    }
  }
};
