import React from 'react';
import { connect } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import * as d3 from "d3";

const LINE_ANGLE_RANGE = Math.PI / 4;
const HEIGHT_MULTIPLIER = 0.5
const OPACITY_UNSELECTED = '50';

interface IState {
  id: string;
}

interface IProps {
  height: number;
  width: number;
  selected: string | null;
  style?: any;
}

class InsightConfidenceIcon extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      id: uuidv4(),
    };
  }

  componentDidMount() {
    this.draw();
  }

  componentDidUpdate() {
    this.clear();
    this.draw();
  }

  archWidth = () => {
    return this.props.height * HEIGHT_MULTIPLIER * 0.2;
  }

  calculateTranslate = () => {
    const y = this.props.height / 2;
    const x = this.props.width / 2;

    return `translate(${x}, ${y})`;
  }

  firstArcHeight = () => {
    return {
      innerRadius: 0,
      outerRadius: this.props.height * HEIGHT_MULTIPLIER / 2,
    };
  }

  secondArcHeight = () => {
    return {
      innerRadius: (this.props.height * HEIGHT_MULTIPLIER) / 2,
      outerRadius: (this.props.height * HEIGHT_MULTIPLIER) / 2 + this.archWidth(),
    }
  }

  thirdArcHeight = () => {
    return {
      innerRadius: (this.props.height * HEIGHT_MULTIPLIER) / 2 + this.archWidth(),
      outerRadius: ((this.props.height * HEIGHT_MULTIPLIER) / 2 + this.archWidth()) + this.archWidth(),
    };
  }

  semiCircle = (svg: any) => {
    const heights = this.firstArcHeight();

    const arc = d3.arc()
      .outerRadius(heights.outerRadius)
      .innerRadius(heights.innerRadius)
      .startAngle(-Math.PI / 2)
      .endAngle(Math.PI / 2)

    svg.append('path')
      .attr('transform', this.calculateTranslate())
      .attr('d', arc)
      .attr('fill', `#b5b3b4${OPACITY_UNSELECTED}`)
      .style('stroke', `#b5b3b4${OPACITY_UNSELECTED}`)
      .style('shape-rendering', 'geometricPrecision')
      .style('stroke-width', '0');

    return svg;
  };

  lines = (svg: any, props: any) => {
    const arc = d3.arc()
      .outerRadius(props.outerRadius)
      .innerRadius(props.innerRadius)
      .startAngle(props.start)
      .endAngle(props.end);

    svg.append('path')
      .attr('transform', this.calculateTranslate())
      .attr('d', arc)
      .attr('fill', props.color)
      .style('stroke', props.color)
      .style('shape-rendering', 'geometricPrecision')
      .style('stroke-width', '0');

    return svg;
  }

  lowLines = (svg: any) => {
    const startAngle = -Math.PI / 2;
    const endAngle = startAngle + LINE_ANGLE_RANGE;
    const base = {
      start: startAngle,
      end: endAngle
    }

    const firstHeights = this.firstArcHeight();
    const secondHeights = this.secondArcHeight();
    const thirdHeights = this.thirdArcHeight();

    const opacity = this.props.selected === 'low' ? 'ff' : OPACITY_UNSELECTED;
    let props = [
      {
        outerRadius: secondHeights.outerRadius,
        innerRadius: secondHeights.innerRadius,
        color: this.props.selected === 'low' ? `#D40071${opacity}` : '#b5b5b5',
      },
      {
        outerRadius: thirdHeights.outerRadius,
        innerRadius: thirdHeights.innerRadius,
        color: this.props.selected === 'low' ? `#F84F6B${opacity}` : '#cccccc',
      },
    ];

    if (this.props.selected === 'low') {
      props = [...props, {
        outerRadius: firstHeights.outerRadius,
        innerRadius: firstHeights.innerRadius,
        color: '#871D82',
      }];
    }

    props.forEach((prop) => {
      this.lines(svg, { ...prop, ...base });
    });

    return svg;
  }

  mediumLowLines = (svg: any) => {
    const startAngle = (-Math.PI / 2) + LINE_ANGLE_RANGE;
    const endAngle = startAngle + LINE_ANGLE_RANGE;

    const base = {
      start: startAngle,
      end: endAngle,
    };

    const firstHeights = this.firstArcHeight();
    const secondHeights = this.secondArcHeight();
    const thirdHeights = this.thirdArcHeight();

    const opacity = this.props.selected === 'medium_low' ? 'ff' : OPACITY_UNSELECTED;

    let props = [
      {
        outerRadius: secondHeights.outerRadius,
        innerRadius: secondHeights.innerRadius,
        color: this.props.selected === 'medium_low' ? `#F76600${opacity}` : '#d0cecf',
      },
      {
        outerRadius: thirdHeights.outerRadius,
        innerRadius: thirdHeights.innerRadius,
        color: this.props.selected === 'medium_low' ? `#F8B700${opacity}` : '#e5e3e4',
      },
    ];

    if (this.props.selected === 'medium_low') {
      props = [...props, {
        outerRadius: firstHeights.outerRadius,
        innerRadius: firstHeights.innerRadius,
        color: '#F82100',
      }];
    }

    props.forEach((prop) => {
      this.lines(svg, { ...prop, ...base });
    });

    return svg;
  }

  mediumHighLines = (svg: any) => {
    const startAngle = (-Math.PI / 2) + LINE_ANGLE_RANGE * 2;
    const endAngle = startAngle + LINE_ANGLE_RANGE;

    const base = {
      start: startAngle,
      end: endAngle,
    };

    const firstHeights = this.firstArcHeight();
    const secondHeights = this.secondArcHeight();
    const thirdHeights = this.thirdArcHeight();

    const opacity = this.props.selected === 'medium_high' ? 'ff' : OPACITY_UNSELECTED;

    let props = [
      {
        outerRadius: secondHeights.outerRadius,
        innerRadius: secondHeights.innerRadius,
        color: this.props.selected === 'medium_high' ? `#007BD0${opacity}` : '#c8c6c7',
      },
      {
        outerRadius: thirdHeights.outerRadius,
        innerRadius: thirdHeights.innerRadius,
        color: this.props.selected === 'medium_high' ? `#51BDE5${opacity}` : '#dfdfdf',
      },
    ];

    if (this.props.selected === 'medium_high') {
      props = [...props, {
        outerRadius: firstHeights.outerRadius,
        innerRadius: firstHeights.innerRadius,
        color: '#1C60AA',
      }];
    }

    props.forEach((prop) => {
      this.lines(svg, { ...prop, ...base });
    });

    return svg;
  }

  highLines = (svg: any) => {
    const startAngle = (-Math.PI / 2) + LINE_ANGLE_RANGE * 3;
    const endAngle = startAngle + LINE_ANGLE_RANGE;

    const base = {
      start: startAngle,
      end: endAngle,
    };

    const firstHeights = this.firstArcHeight();
    const secondHeights = this.secondArcHeight();
    const thirdHeights = this.thirdArcHeight();
    const opacity = this.props.selected === 'high' ? 'ff' : OPACITY_UNSELECTED;

    let props = [
      {
        outerRadius: secondHeights.outerRadius,
        innerRadius: secondHeights.innerRadius,
        color: this.props.selected === 'high' ? `#50BF7B${opacity}` : '#dddddd',
      },
      {
        outerRadius: thirdHeights.outerRadius,
        innerRadius: thirdHeights.innerRadius,
        color: this.props.selected === 'high' ? `#7DEC77${opacity}` : '#ededed',
      },
    ];

    if (this.props.selected === 'high') {
      props = [...props, {
        outerRadius: firstHeights.outerRadius,
        innerRadius: firstHeights.innerRadius,
        color: '#51967C',
      }];
    }

    props.forEach((prop) => {
      this.lines(svg, { ...prop, ...base });
    });

    return svg;
  }

  draw = () => {
    const svg = d3.select(`#insight-${this.state.id}`)
      .append('svg')
      .attr('width', this.props.width)
      .attr('height', this.props.height);

    this.semiCircle(svg);
    this.lowLines(svg);
    this.mediumLowLines(svg);
    this.mediumHighLines(svg);
    this.highLines(svg);
  };

  clear = () => {
    const container = document.querySelector(`#insight-${this.state.id}`)

    if (!container) {
      return;
    }

    container.innerHTML = '';
  }

  render() {
    const style = this.props.style || {};

    return (
      <div style={{ ...style, display: 'inline' }} id={`insight-${this.state.id}`}></div>
    )
  }
};

const mapStateToProps = () => ({
});

const mapDispatchToProps = {};

const connectedPage = connect(mapStateToProps, mapDispatchToProps)(InsightConfidenceIcon);

export { connectedPage as InsightConfidenceIcon };