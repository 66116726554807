import React, { Component } from 'react';
import { connect } from 'react-redux';
import { ApplicationState } from 'store/types';

interface IProps {
  router: any;
}

interface IState {}

class LeaderBoardLoading extends Component<IProps, IState> {
  render() {
    return <div className="flex fcol-3 column">
      <div className="experiment_placeholder">
        <div className="image"></div>
        <div className="text"></div>
        <div className="status">
          <div></div>
        </div>
      </div>

      <div className="text_placeholder"></div>
      <ul className="list_placeholder">
        <li></li>
        <li></li>
        <li></li>
      </ul>

      <div className="text_placeholder"></div>

      <div className="avatar_text_placeholder">
        <div className="avatar_placeholder"></div>
        <div className="text_placeholder"></div>
      </div>

      <div className="avatar_text_placeholder">
        <div className="avatar_placeholder"></div>
        <div className="text_placeholder"></div>
      </div>

      <div className="avatar_text_placeholder">
        <div className="avatar_placeholder"></div>
        <div className="text_placeholder"></div>
      </div>
    </div>;
  }
}

const mapStateToProps = ({ router }: ApplicationState) => ({
  router,
});

const mapDispatchToProps = {
};

const connectedPage = connect(mapStateToProps, mapDispatchToProps)(LeaderBoardLoading);

export { connectedPage as LeaderBoardLoading };