import { ApiClient } from "./client/api_client";

class Share {
  static async case_study(slug: string, uuid: string, password?: string) {
    if (password)
      return await ApiClient.get(
        "/v1/share/" + slug + "/case_study/" + uuid + "?password=" + password
      );

    return await ApiClient.get("/v1/share/" + slug + "/case_study/" + uuid);
  }

  static async uxr(slug: any, uuid: any, password: any) {
    if (password)
      return await ApiClient.get(
        `/v1/share/${slug}/uxr/${uuid}?password=${password}`
      );

    return await ApiClient.get(`/v1/share/${slug}/uxr/${uuid}`);
  }

  static async idea_board(slug: string, uuid: string, password?: string) {
    let passwordParam = "";

    if (password) {
      passwordParam = `?password=${password}`;
    }

    return await ApiClient.get(
      `/v1/share/${slug}/idea_board/${uuid}${passwordParam}`
    );
  }

  static async worksheet(slug: string, uuid: string, password?: string) {
    if (password)
      return await ApiClient.get(
        "/v1/share/" +
          slug +
          "/idea_board/" +
          uuid +
          "/worksheet?password=" +
          password
      );

    return await ApiClient.get(
      "/v1/share/" + slug + "/idea_board/" + uuid + "/worksheet"
    );
  }

  static async create_idea(
    slug: string,
    uuid: string,
    body: any,
    password?: string
  ) {
    let passwordParam = "";

    if (password) {
      passwordParam = `?password=${password}`;
    }

    return await ApiClient.post(
      `/v1/share/${slug}/idea_board/${uuid}/idea${passwordParam}`,
      body
    );
  }

  static async traits(slug: string) {
    return await ApiClient.get("/v1/share/" + slug + "/traits");
  }

  static async insight(slug: string, uuid: string, password: string) {
    let passwordParam = "";

    if (password) {
      passwordParam = `?password=${password}`;
    }

    return await ApiClient.get(
      `/v1/share/${slug}/insight/${uuid}${passwordParam}`
    );
  }
}

export { Share };
