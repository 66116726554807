import { ApiClient } from './client/api_client';
import { ApiGetOptions } from './types';

class IdeaBoards {
  static async index(options?: ApiGetOptions) {
    return await ApiClient.get('/v1/idea_board', options);
  }

  static async create(body: any) {
    return await ApiClient.post('/v1/idea_board', body);
  }

  static async update(id: string, body: any) {
    return await ApiClient.put(`/v1/idea_board/${id}`, body);
  }

  static async show(id: string) {
    return await ApiClient.get(`/v1/idea_board/${id}`);
  }

  static async destroy(id: string) {
    return await ApiClient.delete(`/v1/idea_board/${id}`);
  }

  static async export(id: string, queryString: string, fields: string[]) {
    return ApiClient.post(`/v1/idea_board/${id}/export`,  {
      export: {
        query_string: queryString,
        fields
      }
    })
  }

  static async traits() {
    return await ApiClient.get('/v1/idea_board/traits');
  }

  static async addTraits(ideaBoardId: string, trait: string, values: string[]) {
    return await ApiClient.post(`/v1/idea_board/${ideaBoardId}/traits`, {
      trait,
      values,
    });
  }

  static async reorder(id: string, position: number) {
    return await ApiClient.put(`/v1/idea_board/${id}/order/${position}`, {});
  }
}

export { IdeaBoards };
