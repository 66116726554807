import React from "react";
import * as DataTypes from "store/types";
import { TabsContent } from "./style";
import { SelectInput } from "ui";

type Params = {
  active: boolean;
  metrics: DataTypes.DataVizMetric[];
  onChangeChartKind: (metrics: DataTypes.DataVizMetric[]) => void;
};

const chartKinds: { name: string; slug: string }[] = [
  {
    name: "Horizontal Bar Chart",
    slug: "horizontal_column",
  },
  {
    name: "Vertical Bar Chart",
    slug: "vertical_column",
  },
];

export const VisualsTab: React.FC<Params> = ({
  active,
  metrics,
  onChangeChartKind,
}) => {
  const selectKind = (kindName: string, index: number) => {
    const kind = chartKinds.find((k) => k.name === kindName);

    if (!kind) {
      return;
    }

    onChangeChartKind(
      metrics.map((metric, i) => {
        if (index === i) {
          return {
            ...metric,
            chart_kind: kind.slug as DataTypes.DataVizChartKind,
          };
        }

        return metric;
      })
    );
  };

  return (
    <TabsContent className={`${active ? "active" : ""} overflow`}>
      {metrics.map((metric, index) => (
        <div
          className="form-group"
          key={`custom_data_viz_visual_tab_${metric.name}-${index}`}
        >
          <label>{metric.name}</label>

          <SelectInput
            value={
              chartKinds.filter((kind) => metric.chart_kind === kind.slug)[0]
                .name
            }
            name="metric_chart_axis_selector"
            placeholder="select which "
            items={chartKinds.map((kind) => kind.name)}
            onChange={(kind) => selectKind(kind, index)}
          />
        </div>
      ))}
    </TabsContent>
  );
};
