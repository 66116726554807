import { action } from "typesafe-actions";
import { Dispatch } from "redux";
import { ActionTypes } from "./types";
import * as DataTypes from "../types";

interface RequestError {
  statusCode?: number | undefined;
  message: any;
}

export const getSettingsOptionsRequest = () => (dispatch: Dispatch) =>
  dispatch(action(ActionTypes.GET_SETTINGS_OPTIONS_REQUEST, {}));
export const getSettingsOptionsSuccess = () =>
  action(ActionTypes.GET_SETTINGS_OPTIONS_SUCCESS);
export const getSettingsOptionsError = (error: RequestError) =>
  action(ActionTypes.GET_SETTINGS_OPTIONS_FAILURE, error);

export const getSettingsOptionsAvgExperimentsPerMonthRequest = () =>
  action(
    ActionTypes.GET_SETTINGS_OPTIONS_AVG_EXPERIMENTS_PER_MONTH_REQUEST,
    {}
  );
export const getSettingsOptionsAvgExperimentsPerMonthSuccess = (
  response: DataTypes.ApiRequest<DataTypes.ProfileSettingsOption[]>
) =>
  action(
    ActionTypes.GET_SETTINGS_OPTIONS_AVG_EXPERIMENTS_PER_MONTH_SUCCESS,
    response
  );
export const getSettingsOptionsAvgExperimentsPerMonthError = (
  error: RequestError
) =>
  action(
    ActionTypes.GET_SETTINGS_OPTIONS_AVG_EXPERIMENTS_PER_MONTH_REQUEST,
    error
  );

export const getSettingsOptionsCoeRequest = () =>
  action(ActionTypes.GET_SETTINGS_OPTIONS_COE_REQUEST, {});
export const getSettingsOptionsCoeSuccess = (
  response: DataTypes.ApiRequest<DataTypes.ProfileSettingsOption[]>
) => action(ActionTypes.GET_SETTINGS_OPTIONS_COE_SUCCESS, response);
export const getSettingsOptionsCoeError = (error: RequestError) =>
  action(ActionTypes.GET_SETTINGS_OPTIONS_COE_FAILURE, error);

export const getSettingsOptionsCompanySizeRequest = () =>
  action(ActionTypes.GET_SETTINGS_OPTIONS_COMPANY_SIZE_REQUEST, {});
export const getSettingsOptionsCompanySizeSuccess = (
  response: DataTypes.ApiRequest<DataTypes.ProfileSettingsOption[]>
) => action(ActionTypes.GET_SETTINGS_OPTIONS_COMPANY_SIZE_SUCCESS, response);
export const getSettingsOptionsCompanySizeError = (error: RequestError) =>
  action(ActionTypes.GET_SETTINGS_OPTIONS_COMPANY_SIZE_FAILURE, error);

export const getSettingsOptionsCompanyExperimentsWinPerMonthRequest = () =>
  action(
    ActionTypes.GET_SETTINGS_OPTIONS_COMPANY_EXPERIMENTS_WIN_PER_MONTH_REQUEST,
    {}
  );
export const getSettingsOptionsCompanyExperimentsWinPerMonthSuccess = (
  response: DataTypes.ApiRequest<DataTypes.ProfileSettingsOption[]>
) =>
  action(
    ActionTypes.GET_SETTINGS_OPTIONS_COMPANY_EXPERIMENTS_WIN_PER_MONTH_SUCCESS,
    response
  );
export const getSettingsOptionsCompanyExperimentsWinPerMonthError = (
  error: RequestError
) =>
  action(
    ActionTypes.GET_SETTINGS_OPTIONS_COMPANY_EXPERIMENTS_WIN_PER_MONTH_FAILURE,
    error
  );

export const getSettingsOptionsIndustryhRequest = () =>
  action(ActionTypes.GET_SETTINGS_OPTIONS_INDUSTRY_REQUEST, {});
export const getSettingsOptionsIndustryhSuccess = (
  response: DataTypes.ApiRequest<DataTypes.ProfileSettingsOption[]>
) => action(ActionTypes.GET_SETTINGS_OPTIONS_INDUSTRY_SUCCESS, response);
export const getSettingsOptionsIndustryhError = (error: RequestError) =>
  action(ActionTypes.GET_SETTINGS_OPTIONS_INDUSTRY_FAILURE, error);

export const getSettingsOptionsKindOfTestRequest = () =>
  action(ActionTypes.GET_SETTINGS_OPTIONS_KIND_OF_TEST_REQUEST, {});
export const getSettingsOptionsKindOfTestSuccess = (
  response: DataTypes.ApiRequest<DataTypes.ProfileSettingsOption[]>
) => action(ActionTypes.GET_SETTINGS_OPTIONS_KIND_OF_TEST_SUCCESS, response);
export const getSettingsOptionsKindOfTestError = (error: RequestError) =>
  action(ActionTypes.GET_SETTINGS_OPTIONS_KIND_OF_TEST_FAILURE, error);

export const getSettingsOptionsKindOfToolRequest = () =>
  action(ActionTypes.GET_SETTINGS_OPTIONS_KIND_OF_TOOL_REQUEST, {});
export const getSettingsOptionsKindOfToolSuccess = (
  response: DataTypes.ApiRequest<DataTypes.ProfileSettingsOption[]>
) => action(ActionTypes.GET_SETTINGS_OPTIONS_KIND_OF_TOOL_SUCCESS, response);
export const getSettingsOptionsKindOfToolError = (error: RequestError) =>
  action(ActionTypes.GET_SETTINGS_OPTIONS_KIND_OF_TOOL_FAILURE, error);

export const getSettingsOptionsPrimaryAnalyticsToolRequest = () =>
  action(ActionTypes.GET_SETTINGS_OPTIONS_PRIMARY_ANALYTICS_TOOL_REQUEST, {});
export const getSettingsOptionsPrimaryAnalyticsToolSuccess = (
  response: DataTypes.ApiRequest<DataTypes.ProfileSettingsOption[]>
) =>
  action(
    ActionTypes.GET_SETTINGS_OPTIONS_PRIMARY_ANALYTICS_TOOL_SUCCESS,
    response
  );
export const getSettingsOptionsPrimaryAnalyticsToolError = (
  error: RequestError
) =>
  action(
    ActionTypes.GET_SETTINGS_OPTIONS_PRIMARY_ANALYTICS_TOOL_FAILURE,
    error
  );

export const getSettingsOptionsPrimaryTestingToolRequest = () =>
  action(ActionTypes.GET_SETTINGS_OPTIONS_PRIMARY_TESTING_TOOL_REQUEST, {});
export const getSettingsOptionsPrimaryTestingToolSuccess = (
  response: DataTypes.ApiRequest<DataTypes.ProfileSettingsOption[]>
) =>
  action(
    ActionTypes.GET_SETTINGS_OPTIONS_PRIMARY_TESTING_TOOL_SUCCESS,
    response
  );
export const getSettingsOptionsPrimaryTestingToolError = (
  error: RequestError
) =>
  action(ActionTypes.GET_SETTINGS_OPTIONS_PRIMARY_TESTING_TOOL_FAILURE, error);

export const getSettingsOptionsProgramTypeRequest = () =>
  action(ActionTypes.GET_SETTINGS_OPTIONS_PROGRAM_TYPE_REQUEST, {});
export const getSettingsOptionsProgramTypeSuccess = (
  response: DataTypes.ApiRequest<DataTypes.ProfileSettingsOption[]>
) => action(ActionTypes.GET_SETTINGS_OPTIONS_PROGRAM_TYPE_SUCCESS, response);
export const getSettingsOptionsProgramTypeError = (error: RequestError) =>
  action(ActionTypes.GET_SETTINGS_OPTIONS_PROGRAM_TYPE_FAILURE, error);

export const getSettingsOptionsTestingChallengRequest = () =>
  action(ActionTypes.GET_SETTINGS_OPTIONS_TESTING_CHALLENGE_REQUEST, {});
export const getSettingsOptionsTestingChallengSuccess = (
  response: DataTypes.ApiRequest<DataTypes.ProfileSettingsOption[]>
) =>
  action(ActionTypes.GET_SETTINGS_OPTIONS_TESTING_CHALLENGE_SUCCESS, response);
export const getSettingsOptionsTestingChallengError = (error: RequestError) =>
  action(ActionTypes.GET_SETTINGS_OPTIONS_TESTING_CHALLENGE_FAILURE, error);

export const getSettingsOptionsTestingProgramReachRequest = () =>
  action(ActionTypes.GET_SETTINGS_OPTIONS_TESTING_PROGRAM_REACH_REQUEST, {});
export const getSettingsOptionsTestingProgramReachSuccess = (
  response: DataTypes.ApiRequest<DataTypes.ProfileSettingsOption[]>
) =>
  action(
    ActionTypes.GET_SETTINGS_OPTIONS_TESTING_PROGRAM_REACH_SUCCESS,
    response
  );
export const getSettingsOptionsTestingProgramReachError = (
  error: RequestError
) =>
  action(ActionTypes.GET_SETTINGS_OPTIONS_TESTING_PROGRAM_REACH_FAILURE, error);
