import React, { useState, useRef } from 'react';

interface IProps {
  onSubmit(value: string): void;
  annotateOn: boolean;
  onAnnotateToggle?(on: boolean): void;
  value: string;
}

const CommentInput = ({ value, onSubmit, annotateOn, onAnnotateToggle }: IProps) => {
  const ref = useRef<any>(null);

  const [edit, setEdit] = useState(false);
  const [newValue, setNewValue] = useState(value);

  const postComment = (value: string) => {
    onSubmit(value);
    setEdit(!edit);
  };

  if (edit === false) {
    if (value === null || value.trim() === '') {
      return (
        <p ref={ref} className="comment_input placeholder" onClick={e => setEdit(!edit)}>
          <i>Leave a comment</i>
        </p>
      );
    }
    return (
      <p ref={ref} className="comment_input" onClick={e => setEdit(!edit)}>
        {value}
      </p>
    );
  } else {
    return (
      <div ref={ref} className="comment_input">
        <div className="input">
          <textarea className="form-control" placeholder={'Leave a comment'} defaultValue={value} onChange={e => setNewValue(e.target.value)}></textarea>
          <div className="ctrls">
            <button className={annotateOn === true ? 'btn btn-ctrl active' : 'btn btn-ctrl'} onClick={() => onAnnotateToggle && onAnnotateToggle(!annotateOn)}>
              <i className="fas fa-crosshairs" />
            </button>
          </div>
        </div>
        <div className="ctrl-bar">
          <button
            className={newValue === null || newValue.trim() === '' ? 'btn btn-primary disabled' : 'btn btn-primary'}
            onClick={() => postComment(newValue)}
          >
            Post Comment
          </button>
        </div>
      </div>
    );
  }
};

export { CommentInput };
