import { Reducer } from 'redux';
import * as DataTypes from '../types';
import { ActionTypes } from './types';

const initialState: DataTypes.User | null = null;

const reducer: Reducer<DataTypes.User | null> = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.CLEAR_USER: {
      return null;
    }
    case ActionTypes.LOGIN_SUCCESS:
    case ActionTypes.UPDATE_PROFILE_SUCCESS:
    case ActionTypes.UPDATE_PASSWORD_SUCCESS:
    case ActionTypes.UPDATE_NOTIFICATIONS_SUCCESS:
    case ActionTypes.CREATE_INVITED_USER_SUCCESS: {
      return action.payload;
    }
    case ActionTypes.GET_USER_SUCCESS: {
      return action.payload;
    }
    case ActionTypes.RESET_PWD_SUCCESS: {
      return { ...action.payload, passwordReset: true };
    }
    case ActionTypes.FORGOT_PWD_SUCCESS: {
      return { ...action.payload, forgotPassword: true };
    }
    default: {
      return state;
    }
  }
};

export { reducer as userReducer };
