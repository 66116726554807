import { RouterState } from "connected-react-router";
import { DefaultIdeaBoardManagementState } from "./idea_board_management/reducers";

export interface RequestError {
  statusCode?: number | undefined;
  message: any;
}

export enum MetricType {
  PERCENT = "percent",
  FLOAT = "float",
  CURRENCY = "currency",
}

export interface ApplicationState {
  router: RouterState;
  account: Account | null;
  experiment: Experiment | null;
  experimentTrait: any;
  idea: Idea | null;
  ideaManagement: any;
  idea_board: IdeaBoard | null;
  worksheet: Worksheet | null;
  app: AppState;
  collections: Collections;
  user: User | null;
  calculator: Array<SampleSizeResult> | null;
  application: ApiList<Application> | null;
  dashboard: Dashboard | null;
  ideaBoardManagement: DefaultIdeaBoardManagementState;
  insightTrait: any;
  insight: any;
  ideaTrait: any;
  ideaBoardTrait: any;
  reportTrait: any;
  experience: any;
  reportSummary: any;
  reportAnalytics: any;
  reportTarget: any;
  report: any;
  slack: any;
  uxr: any;
  uxrTrait: any;
  ratting: any;
  profielSettingsOptions: ProfileSettingsOptions | null;
  bannerPlacement: any;
  insightsWordCloud: any;
  insightProposals: any;
  customDataViz: any;
  programGoalTarget: any;
}

export interface ProfileSettingsOption {
  id: string;
  value: string;
  enabled: boolean;
  preset: boolean;
}

export interface ProfileSettingsOptions {
  avg_exp_per_month?: ProfileSettingsOption[];
  industry?: ProfileSettingsOption[];
  coe?: ProfileSettingsOption[];
  company_size?: ProfileSettingsOption[];
  experiments_win_per_month?: ProfileSettingsOption[];
  kind_of_test?: ProfileSettingsOption[];
  kind_of_tool?: ProfileSettingsOption[];
  primary_analytics_tool?: ProfileSettingsOption[];
  primary_testing_tool?: ProfileSettingsOption[];
  program_type?: ProfileSettingsOption[];
  testing_challenge?: ProfileSettingsOption[];
  testing_program_reach?: ProfileSettingsOption[];
}

export interface Ratting {
  feature_name: string;
  liked_it: boolean;
}

export interface AppState {
  readonly alert: Alert;
  readonly modal: Modal;
  readonly requests: RequestState;
  readonly errors?: ErrorState;
  readonly signupStep?: string;
}

export interface RequestState {
  [key: string]: { loading: boolean };
}

export interface ErrorState {
  [key: string]: any;
}

export interface Collections {
  [key: string]: ApiList<User> | ApiList<Experiment>;
}

export interface ApiListOptions {
  starting_after?: string;
  ending_before?: string;
  ids?: string[];
  search?: string;
  page?: number;
  limit?: number;
  sort?: string;
  q?: string | string[] | null | undefined;
  group?: string;
  month?: string;
}

export interface ApiList<T> {
  object: string;
  url: string;
  has_more: boolean;
  data: Array<T>;
}

export interface ApiRequest<T> {
  state: string;
  code: number;
  data: T | {};
}

export interface Alert {
  show: boolean;
  error?: boolean;
  content: string | undefined;
}

export interface Modal {
  show: boolean;
  component: React.ComponentClass<any> | undefined;
  options: any;
  saving: boolean;
}

export interface User {
  id: string;
  first_name: string;
  firstName: String;
  last_name: string;
  lastName: String;
  email: string;
  image?: string;
  role: string;
  notification_settings: {
    experiment_created: boolean;
    experiment_completed: boolean;
    newsletter: boolean;
  };
  image_url?: string;
  imageUrl?: string;
  forgotPassword?: boolean;
  passwordReset?: boolean;
  readonly created_at?: string;
  readonly updated_at?: string;
  readonly deleted_at?: string;
}

export interface Invite {
  id: string;
  email: string;
  accepted: boolean;
  role: string;
  sender: User;
  readonly created_at?: string;
  readonly updated_at?: string;
  readonly deleted_at?: string;
}

export interface Idea {
  id: string | undefined;
  name: string;
  merged_from: any;
  problem: any;
  hypothesis: any;
  proposed: any;
  program_goal: string;
  archived: boolean;
  audiences: Array<string>;
  brands: Array<string>;
  themes: Array<string>;
  devices: Array<string>;
  channels: Array<string>;
  pages: Array<string>;
  tags: Array<string>;
  custom_order: number;
  teams: Array<string>;
  star: boolean;
  status: string;
  index: number;
  score: number;
  tactics: Array<PainGain>;
  author: User;
  idea_board_id: string | null;
  level_of_effort: string;
  business_impact: string;
  win_likelyhood: string;
  value_of_learning: string;
  experiments: Array<any> | null;
  experiences: Array<any> | null;
  contributors: Array<User> | null;
  readonly created_at: string;
  readonly updated_at: string;
  readonly deleted_at?: string;
}

export interface Uxr {
  id: string;
  name: string;
  description: any;
  unformattedDescriion: string;
  audience_criteria: any;
  audienceCriteria: any;
  participants: string;
  start_date: string;
  startDate: string;
  end_date: string;
  endDate: string;
  methodology: number;
  user: User;
  star: boolean;
  audiences: Array<string>;
  brands: Array<string>;
  themes: Array<string>;
  devices: Array<string>;
  channels: Array<string>;
  insights: Array<Insight>;
  pages: Array<string>;
  tags: Array<string>;
  contributors: Array<User> | null;
  competitors: Array<string>;
  teams: Array<string>;
  methodKinds: Array<string>;
  method_kinds: Array<string>;
  uploads: Array<Upload>;
  documents: Array<Upload>;
  area_of_focus: Array<AreaOfFocus>;
  supporting_datas: Array<SupportingData>;
  share?: UxrShare;
  share_uuid: string;
  readonly created_at: string;
  readonly updated_at: string;
  readonly deleted_at: string;
  readonly createdAt: string;
  readonly updatedAt: string;
  readonly deletedAt: string;
}

export interface UxrShare {
  id: string;
  uuid: string;
  password_protected: boolean;
  password: string;
  insights: Array<string> | null;
  qualitatives: Array<string> | null;
  quantitatives: Array<string> | null;
  area_of_focus: Array<string> | null;
  readonly created_at: string;
  readonly updated_at: string;
  readonly deleted_at: string;
  readonly createdAt: string;
  readonly updatedAt: string;
  readonly deletedAt: string;
}

export interface SupportingData {
  id: string;
  explanation: any;
  sumarized_data: string;
  type: string;
  area_of_focus: AreaOfFocus;
  uploads: Array<Upload>;
  readonly created_at: string;
  readonly updated_at: string;
  readonly deleted_at: string;
  readonly createdAt: string;
  readonly updatedAt: string;
  readonly deletedAt: string;
}

export interface AreaOfFocus {
  id: string;
  theme: string;
  explanation: any;
  readonly created_at: string;
  readonly updated_at: string;
  readonly deleted_at: string;
  readonly createdAt: string;
  readonly updatedAt: string;
  readonly deletedAt: string;
}

export interface IdeaBoard {
  id: string | undefined;
  name: string;
  description: any;
  archived: boolean;
  total_ideas: number;
  worksheet?: Worksheet;
  share?: { uuid: string; password_protected: boolean; password: string };
  author: User;
  audiences: Array<string>;
  teams: Array<string>;
  brands: Array<string>;
  channels: Array<string>;
  devices: Array<string>;
  pages: Array<string>;
  readonly created_at: string;
  readonly updated_at: string;
  readonly deleted_at?: string;
}

export interface Worksheet {
  id: string | undefined;
  title: string;
  content: any;
  idea_board_id: string;
  user: User;
  readonly created_at: string;
  readonly updated_at: string;
  readonly deleted_at?: string;
}

export interface Note {
  id?: string;
  value: string;
  user?: User;
  is_illuminate_created: boolean;
  readonly created_at?: string;
  readonly updated_at?: string;
  readonly deleted_at?: string;
}

export interface Insight {
  id: string;
  value: string;
  altitude: string;
  brands: Array<string>;
  teams: Array<string>;
  channels: Array<string>;
  star: boolean;
  key: boolean;
  notes: Array<Note>;
  confidence: string;
  experiments: Array<Experiment>;
  uxrs: Array<Uxr>;
  contributors: Array<User>;
  author: User;
  last_editor: User | null;
  tags: Array<string>;
  share?: { uuid: string; password_protected: boolean; password: string };
  is_illuminate_created: boolean;
  readonly created_at: string;
  readonly updated_at: string;
  readonly deleted_at?: string;
}

export interface InsightRequest {
  value: string;
  altitude?: string;
  key: boolean;
  tags: Array<string>;
  pages: Array<string>;
  devices: Array<string>;
  audiences: Array<string>;
  experiment_ids?: Array<string>;
  uxr_ids?: Array<string>;
}

export interface CaseStudy {
  id: string | undefined;
  experiences: Array<string> | null;
  insights: Array<string> | null;
  reports: Array<string> | null;
  data_vizs: string[] | null;
  uuid: string;
  password_protected: boolean;
  password: string;
}

export interface Experiment {
  id: string | undefined;
  name: string;
  description: any;
  type: string;
  status: string;
  start_date: string;
  startDate: string;
  endDate: string;
  end_date: string;
  program_impact: any;
  programImpact: any;
  audiences: Array<string>;
  devices: Array<string>;
  channels: Array<string>;
  pages: Array<string>;
  tags: Array<string>;
  brands: Array<string>;
  teams: Array<string>;
  experiences: Array<Experience>;
  insights: Array<Insight>;
  // TODO: REMOVE
  program_goal: ProgramGoal;
  program_goals: {
    id: string;
    impact_polarity: string;
    impact_value: number;
    program_goal: ProgramGoal;
  }[];
  programGoals: ProgramGoal[];
  impact_polarity: string;
  impact_value: number;
  impactValue: number;
  impactPolarity: number;
  author: User | null | undefined;
  reports: Array<Report>;
  user: User;
  case_study_uuid: string | null;
  star: boolean;
  custom_data_viz: any;
  is_single_hypothesis: boolean;
  adobe?: {
    id: string;
    type: string;
    name: string;
    reporting_type: string;
    analytics_report_suite: string;
    available_metrics?: any[];
  };
  case_study?: CaseStudy;
  uploads: Array<Upload>;
  contributors: Array<User> | null;
  readonly created_at: string;
  readonly updated_at: string;
  readonly deleted_at?: string;
  readonly createdAt: string;
  readonly updatedAt: string;
  readonly deletedAt?: string;
}

export interface PainGain {
  category: string;
  value: string;
}

export interface Experience {
  readonly id: string;
  name: string;
  type: string;
  traffic: number;
  problem: any;
  difference: any;
  hypothesis: any;
  outcome: any;
  images: Array<Image>;
  overall_winner: boolean;
  tactics: Array<PainGain>;
  readonly created_at: string;
  readonly updated_at: string;
  readonly deleted_at?: string;
}

export interface Report {
  readonly id?: string;
  name: string;
  description: string;
  type: string;
  primary: boolean;
  metrics: Array<Metric>;
  readonly stats_last_updated?: string;
  readonly created_at?: string;
  readonly updated_at?: string;
  readonly deleted_at?: string;
}

export interface Metric {
  readonly id?: string;
  name?: string;
  custom_order: number;
  metric_type?: MetricType;
  symbol?: string;
  primary?: boolean;
  results: Array<Result> | null;
  readonly created_at?: string;
  readonly updated_at?: string;
  readonly deleted_at?: string;
}

export interface Result {
  readonly id?: string;
  experience_id: string;
  description?: string;
  value?: string;
  lift?: number;
  confidence?: number;
  readonly created_at?: string;
  readonly updated_at?: string;
  readonly deleted_at?: string;
}

export interface AccountSummary {
  totals: { [key: string]: number };
}

export interface ProgramGoal {
  id?: string | undefined;
  name: string;
  data_type?: string;
  dataType?: string;
  symbol?: string;
  total_experiments?: number;
  targets?: ProgramGoalTarget[];
  expired_targets?: ProgramGoalTarget[];
}

export interface ProgramGoalStat extends ProgramGoal {
  total: number;
}

export interface TraitStat {
  name: string;
  experiments: number;
  total_wins: number;
  win_rate: number;
}

export interface AccountStats {
  start_date: number;
  end_date: number;
  launched: number;
  completed: number;
  total_wins: number;
  win_rate: number;
  insights: { total: number; per_test: number; linked: number };
  experiences: { total: number; controls: number; variations: number };
  range: Array<{
    date: number;
    winner: number;
    loss: number;
    flat: number;
    undecided: number;
  }>;
  program_goals: { [key: string]: ProgramGoalStat };
  traits: { [key: string]: Array<TraitStat> };
}

export interface Account {
  id: string | undefined;
  name: string;
  slug: string;
  account_type: string;
  enabled: boolean;
  setup_complete: boolean;
  settings: {
    id: string;
    brands: boolean;
    teams: boolean;
    channels: boolean;
    sso: boolean;
    use_bb_methods: boolean;
    password_protected_case_studies: boolean;
    global_case_study_password: boolean;
  };
  profile: {
    id: string | null;
    industry: ProfileSettingsOption | null;
    company_size: ProfileSettingsOption | null;
    program_type: ProfileSettingsOption | null;
    avg_exp_per_month: ProfileSettingsOption | null;
    primary_testing_tool: ProfileSettingsOption | null;
    primary_analytics_tool: ProfileSettingsOption | null;
    wins_per_month: ProfileSettingsOption | null;
    coe: ProfileSettingsOption | null;
    kind_of_tools: ProfileSettingsOption[] | null;
    kind_of_tests: ProfileSettingsOption[] | null;
    testing_challenges: ProfileSettingsOption[] | null;
    testing_program_reaches: ProfileSettingsOption[] | null;
  };
  traits: { [key: string]: Array<Trait> };
  summary: AccountSummary;
  stats?: AccountStats;
  program_goals: Array<ProgramGoal>;
  pages: Array<{ name: string; total: number }>;
  audiences: Array<{ name: string; total: number }>;
  devices: Array<{ name: string; total: number }>;
  apps: Array<App>;
  readonly created_at: string;
  readonly updated_at: string;
  readonly deleted_at?: string;
}

export interface AccountSettings {
  brands?: boolean;
  teams?: boolean;
  channels?: boolean;
  sso?: boolean;
  use_bb_methods?: boolean;
  password_protected_case_studies?: boolean;
  global_case_study_password?: string;
}

export interface AccountRequest {
  name: string;
  slug: string;
  tos: boolean;
  user: {
    notification_settings: {
      newsletter?: boolean;
    };
    first_name: string;
    last_name: string;
    email: string;
    password?: string;
    google_id?: string;
    type: string;
  };
}

export interface Trait {
  name: string;
  total: string;
  category: string;
  id: string;
}

export interface Upload {
  readonly id?: string;
  name?: string;
  file?: string | undefined;
  content_type?: number;
  description?: string;
  url?: string;
  comments?: Array<Comment>;
  readonly created_at?: string;
  readonly updated_at?: string;
}

export interface Image {
  readonly id?: string;
  name?: string;
  description?: string;
  image?: string | undefined;
  index?: number;
  content_type?: number;
  url?: string;
  comments?: Array<Comment>;
  readonly created_at?: string;
  readonly updated_at?: string;
}

export interface Comment {
  readonly id?: string;
  value?: string;
  annotations?: Array<Annotation>;
  user?: User;
  readonly created_at?: string;
  readonly updated_at?: string;
}

export interface Annotation {
  data: any;
  geometry: {
    type: string;
    x: number;
    y: number;
    height: number;
    width: number;
  };
}

export interface App {
  id: string | undefined;
  type: string;
  enabled: boolean;
  api_key: string;
  api_secret: string;
  target_profile: string;
  jwt_payload: string;
  public_key: string;
  global_company_id: string;
  experiments: Array<any>;
  metrics: Array<any>;
  app_id: string;
  client_id: string;
  client_secret: string;
  signing_secret: string;
  channel: string;
  readonly created_at: string;
  readonly updated_at: string;
  readonly deleted_at?: string;
}

export interface Task {
  readonly id?: string;
  type: string;
  action: string;
  message: string;
  data: { type: string; id: string };
  readonly created_at: string;
  readonly updated_at: string;
}

export interface SampleSize {
  duration_type: number;
  adjust: number;
  challengers: number;
  traffic: number;
  minimum_lift: number;
  power: number;
  response_rate: number;
  mean: number;
  standard_deviation: number;
}

export interface SampleSizeResult {
  significance: number;
  sample_per_variation: number;
  total_sample_required: number;
  duration_in_days: number;
}

export interface AdobeMetric {
  readonly client_id: string;
  readonly type?: string;
  readonly name: string;
  readonly description?: string;
  readonly metric_type: string;
  readonly polarity?: string;
  readonly calculated?: boolean;
}

export interface Application {
  name: string;
  readonly id?: string;
  readonly key?: string;
}

export interface Dashboard {
  leaderBoard?: LeaderBoard | null;
  insightsBoard?: InsightBoard | null;
  today?: TodayBoard | null;
  didYouKnow?: DidYouKnow | null;
}

export interface DidYouKnow {
  ideas_avg?: number | null;
  experiments_avg?: number | null;
  insights_avg?: number | null;
}

export interface TodayBoard {
  live: Array<Experiment> | null;
  analysis: Array<Experiment> | null;
  pre_launch: Array<Experiment> | null;
}

export interface LeaderBoard {
  best_experiment: Experiment;
  audience_top_performer: TopPerformer;
  device_top_performer: TopPerformer;
  page_top_performer: TopPerformer;
  top_creators: TopCreator;
}

export interface InsightBoard {
  latest: Insight;
  more_experiments: Insight;
}

export interface TopCreator {
  experiments: User;
  ideas: User;
  insights: User;
}

export interface TopPerformer {
  name: string;
  win_rate: number;
}

export interface Banner {
  id: string;
  name: string;
  information: string;
  image: string;
  banner_placement: string;
  link: string;
}

interface WordCloudWord {
  text: string;
  value: number;
}

export type WordCloud = WordCloudWord[];

export interface InsightProposal {
  id: string;
  industry_category?: string;
  value: string;
  tags: string[];
  comment: string;
  created_at: Date;
  updated_at: Date;
}

export interface InsightProposals {
  general_proposals: InsightProposal[];
  industry_proposals: InsightProposal[];
}

export interface CustomDataViz {
  id: string;
  name: string;
  chart_kind: string;
  primary: boolean;
  reports: CustomDataVizReport[];
}

export interface CustomDataVizReport {
  id: string;
  name: string;
  metrics: CustomDataVizMetric[];
}

export type DataVizChartKind = "horizontal_column" | "vertical_column" | "pie";

export interface CustomDataVizMetric {
  name: string;
  metric_type: string;
  agregation_id: string;
  kind: DataVizMetricKind;
  results: CustomDataVizResult[];
  chart_kind: DataVizChartKind;
  story_telling: any;
}

export interface CustomDataVizResult {
  value: string;
  lift: string;
  confidence: string;
  description: any;
  experience_id: string;
  experience_name: string;
  metric_id: string;
}

export type DataVizMetricKind = "value" | "lift";

export type DataVizMetric = {
  name: string;
  metric_ids: string[];
  kind: DataVizMetricKind;
  chart_kind: DataVizChartKind;
};

export interface ProgramGoalTarget {
  id: string;
  name: string;
  starts_at: Date;
  ends_at: Date;
  value: number;
  current_value?: number;
}

export enum CurrencySymbols {
  DOLLAR = "DOLLAR",
  POUND = "POUND",
  EURO = "EURO",
}
