import React, { Component } from "react";
import * as DataTypes from "store/types";
import Collapsible from "react-collapsible";
import { ArrowDownIcon } from "ui/arrow_down_icon";
import { OrderDirection, OrderIcon } from "ui/order_icon";
import { Helpers } from "utils";
import { InsightConfidenceIcon } from "ui/insight_confidence";

const FilterKeys = ["tags", "brands", "teams", "channels"];

type sortingNamesType = {
  [key: string]: string;
};

const sortingNames: sortingNamesType = {
  customOrder: "Drag and Drop",
  value: "Name",
  updatedAt: "Last Updated",
  createdAt: "Created At",
};

interface IProps {
  useBBMethods: boolean;
  loading: boolean;
  traits: { [key: string]: Array<DataTypes.Trait> } | null;
  selectedFilters: { [key: string]: Array<string> };
  onCheck(
    checked: boolean,
    type: string,
    value: string,
    remove?: boolean
  ): void;
  onSorting: (orderBy: string, currentDirection: OrderDirection) => void;
  sortingValues: { [key: string]: OrderDirection };
  onLimitChange(limit: number): void;
  onClearFilters(): void;
}

class Filters extends Component<IProps> {
  confidenceIndex() {
    const confidenceFilter = this.props.selectedFilters.confidence;

    if (!confidenceFilter) {
      return 0;
    }
    let index = ["low", "medium_low", "medium_high", "high"].lastIndexOf(
      confidenceFilter[0]
    );

    if (index === -1) {
      return 0;
    }

    return index;
  }

  onCheckAuthor = (checked: boolean, type: string, value: string) => {
    const authors = this.props.traits?.authors;
    const author = authors?.filter((author) => author.name === value)[0];
    this.props.onCheck(checked, "author", author?.id || "");
  };

  onCheckContirbutor = (checked: boolean, type: string, value: string) => {
    const contributors = this.props.traits?.contributors;
    const contributor = contributors?.filter(
      (contributor) => contributor.name === value
    )[0];
    this.props.onCheck(checked, "contributor", contributor?.id || "");
  };

  onConfidenceChange = (value: any) => {
    this.setState({ ...this.state, currentConfidence: value });
    this.props.onCheck(
      true,
      "confidence",
      ["low", "medium_low", "medium_high", "high"][value],
      true
    );
  };

  render() {
    const { loading, traits, onCheck, selectedFilters, useBBMethods } =
      this.props;

    const selectedConfidence = this.confidenceIndex();
    const selectedConfidenceSlug = ["low", "medium_low", "medium_high", "high"][
      selectedConfidence
    ];

    if (loading || !traits) {
      return (
        <React.Fragment>
          <div className="Filter placeholder">
            <h4>&nbsp;</h4>
            <ul>
              <li>
                <p></p>
              </li>
              <li>
                <p></p>
              </li>
              <li>
                <p></p>
              </li>
            </ul>
          </div>
          <div className="Filter placeholder">
            <h4>&nbsp;</h4>
            <ul>
              <li>
                <p></p>
              </li>
              <li>
                <p></p>
              </li>
              <li>
                <p></p>
              </li>
            </ul>
          </div>
          <div className="Filter placeholder">
            <h4>&nbsp;</h4>
            <ul>
              <li>
                <p></p>
              </li>
              <li>
                <p></p>
              </li>
              <li>
                <p></p>
              </li>
            </ul>
          </div>
          <div className="Filter placeholder">
            <h4>&nbsp;</h4>
            <ul>
              <li>
                <p></p>
              </li>
              <li>
                <p></p>
              </li>
              <li>
                <p></p>
              </li>
            </ul>
          </div>
        </React.Fragment>
      );
    }

    return (
      <React.Fragment>
        <button
          onClick={this.props.onClearFilters}
          className="button button__link p__zero"
          style={{ paddingLeft: "20px" }}
        >
          Clear Filters
        </button>
        <div className="sortCtrls Filter">
          <Collapsible
            trigger={
              <h4>
                Sorting <ArrowDownIcon height="18" />
              </h4>
            }
            triggerClassName="cursor--pointer"
            triggerOpenedClassName="cursor--pointer"
          >
            <ul>
              {Object.keys(this.props.sortingValues).map(
                (sortingProperty: string) => (
                  <li key={sortingProperty}>
                    <span
                      className="h--sm w--full flex justify-between align-center cursor--pointer"
                      onClick={() => {
                        this.props.onSorting(
                          sortingProperty,
                          this.props.sortingValues[sortingProperty]
                        );
                      }}
                    >
                      {sortingNames[sortingProperty] ||
                        Helpers.camelToCapitalize(sortingProperty)}
                      <span className="icon">
                        <OrderIcon
                          orderDirection={
                            this.props.sortingValues[sortingProperty]
                          }
                        />
                      </span>
                    </span>
                  </li>
                )
              )}
            </ul>
          </Collapsible>
        </div>

        {Object.keys(traits)
          .filter((k) => FilterKeys.includes(k))
          .map((key) => {
            return (
              <FilterGroup
                key={`filter_group_${key}`}
                name={key}
                traits={traits[key]}
                collapsible={true}
                onCheck={onCheck}
                selectedItems={selectedFilters[key] || []}
              />
            );
          })}
        {useBBMethods === true && (
          <Collapsible
            triggerTagName="h4"
            trigger={
              <span>
                Insight Altitude<sup>®</sup> <ArrowDownIcon height="18" />
              </span>
            }
            triggerClassName="cursor--pointer"
            triggerOpenedClassName="cursor--pointer"
            className="Filter"
            openedClassName="Filter"
          >
            <ul>
              <li>
                <label className="CheckBox">
                  <input
                    type="checkbox"
                    defaultChecked={
                      selectedFilters["altitude"] &&
                      selectedFilters["altitude"].indexOf("satellite") >= 0
                        ? true
                        : false
                    }
                    onChange={(e) =>
                      onCheck(e.target.checked, "altitude", "satellite")
                    }
                  />
                  <span className="checkmark"></span>
                </label>
                <p>
                  <img
                    src="/img/altitudes/satellite.png"
                    alt="satellite"
                    style={{
                      height: "16px",
                      width: "16px",
                      marginRight: "4px",
                      top: "-2px",
                      position: "relative",
                    }}
                  />
                  Satellite
                </p>
                <p></p>
              </li>
              <li>
                <label className="CheckBox">
                  <input
                    type="checkbox"
                    defaultChecked={
                      selectedFilters["altitude"] &&
                      selectedFilters["altitude"].indexOf("jetstream") >= 0
                        ? true
                        : false
                    }
                    onChange={(e) =>
                      onCheck(e.target.checked, "altitude", "jetstream")
                    }
                  />
                  <span className="checkmark"></span>
                </label>
                <p>
                  <img
                    src="/img/altitudes/jetstream.png"
                    alt="jetstream"
                    style={{
                      height: "16px",
                      width: "16px",
                      marginRight: "4px",
                      top: "-2px",
                      position: "relative",
                    }}
                  />
                  Jetstream
                </p>
                <p></p>
              </li>
              <li>
                <label className="CheckBox">
                  <input
                    type="checkbox"
                    defaultChecked={
                      selectedFilters["altitude"] &&
                      selectedFilters["altitude"].indexOf("summit") >= 0
                        ? true
                        : false
                    }
                    onChange={(e) =>
                      onCheck(e.target.checked, "altitude", "summit")
                    }
                  />
                  <span className="checkmark"></span>
                </label>
                <p>
                  <img
                    src="/img/altitudes/summit.png"
                    alt="summit"
                    style={{
                      height: "16px",
                      width: "16px",
                      marginRight: "4px",
                      top: "-2px",
                      position: "relative",
                    }}
                  />
                  Summit
                </p>
                <p></p>
              </li>
              <li>
                <label className="CheckBox">
                  <input
                    type="checkbox"
                    defaultChecked={
                      selectedFilters["altitude"] &&
                      selectedFilters["altitude"].indexOf("basecamp") >= 0
                        ? true
                        : false
                    }
                    onChange={(e) =>
                      onCheck(e.target.checked, "altitude", "basecamp")
                    }
                  />
                  <span className="checkmark"></span>
                </label>
                <p>
                  <img
                    src="/img/altitudes/basecamp.png"
                    alt="basecamp"
                    style={{
                      height: "16px",
                      width: "16px",
                      marginRight: "4px",
                      top: "-2px",
                      position: "relative",
                    }}
                  />
                  Basecamp
                </p>
                <p></p>
              </li>
              <li>
                <label className="CheckBox">
                  <input
                    type="checkbox"
                    defaultChecked={
                      selectedFilters["altitude"] &&
                      selectedFilters["altitude"].indexOf("groundlevel") >= 0
                        ? true
                        : false
                    }
                    onChange={(e) =>
                      onCheck(e.target.checked, "altitude", "groundlevel")
                    }
                  />
                  <span className="checkmark"></span>
                </label>
                <p>
                  <img
                    src="/img/altitudes/groundlevel.png"
                    alt="groundlevel"
                    style={{
                      height: "16px",
                      width: "16px",
                      marginRight: "4px",
                      top: "-2px",
                      position: "relative",
                    }}
                  />
                  Groundlevel
                </p>
                <p></p>
              </li>
            </ul>
          </Collapsible>
        )}

        {useBBMethods === true && (
          <Collapsible
            triggerTagName="h4"
            trigger={
              <span>
                Insight Confidence<sup>&reg;</sup> <ArrowDownIcon height="18" />
              </span>
            }
            triggerClassName="cursor--pointer"
            triggerOpenedClassName="cursor--pointer"
            className="Filter"
            openedClassName="Filter"
          >
            <ul>
              <li>
                <div className="w-100">
                  <div className="ScoreSelector">
                    <input
                      type="range"
                      min="0"
                      max="3"
                      step="0"
                      value={selectedConfidence}
                      list="ticks"
                      onChange={(e) => this.onConfidenceChange(e.target.value)}
                    />
                    <datalist id="ticks">
                      <option value="low">Low</option>
                      <option value="medium_high">Medium Low</option>
                      <option value="medium_high">Medium High</option>
                      <option value="medium_high">High</option>
                    </datalist>
                  </div>
                  <div
                    style={{
                      marginTop: "10px",
                      display: "flex",
                      alignItems: "end",
                    }}
                  >
                    <InsightConfidenceIcon
                      height={30}
                      width={30}
                      selected={selectedConfidenceSlug}
                    />
                    <span style={{ marginLeft: "5px" }}>
                      {
                        ["low", "medium-low", "medium-high", "high"][
                          selectedConfidence
                        ]
                      }
                    </span>
                  </div>
                </div>
              </li>
            </ul>
          </Collapsible>
        )}

        <Collapsible
          triggerTagName="h4"
          trigger={
            <span>
              Status <ArrowDownIcon height="18" />
            </span>
          }
          triggerClassName="cursor--pointer"
          triggerOpenedClassName="cursor--pointer"
          className="Filter"
          openedClassName="Filter"
        >
          <ul>
            <li>
              <label className="CheckBox">
                <input
                  type="checkbox"
                  defaultChecked={
                    selectedFilters["key"] &&
                    selectedFilters["key"].indexOf("true") >= 0
                      ? true
                      : false
                  }
                  onChange={(e) => onCheck(e.target.checked, "key", "true")}
                />
                <span className="checkmark"></span>
              </label>
              <p>
                <i className="fas fa-key" />
                key insight
              </p>
              <p></p>
            </li>
            <li>
              <label className="CheckBox">
                <input
                  type="checkbox"
                  defaultChecked={
                    selectedFilters["star"] &&
                    selectedFilters["star"].indexOf("true") >= 0
                      ? true
                      : false
                  }
                  onChange={(e) => onCheck(e.target.checked, "star", "true")}
                />
                <span className="checkmark"></span>
              </label>
              <p>
                <i className="fas fa-star" />
                starred
              </p>
              <p></p>
            </li>
          </ul>
        </Collapsible>

        <FilterGroup
          collapsible={true}
          key={"filter_group_author"}
          name={"Creator"}
          traits={traits.authors}
          onCheck={this.onCheckAuthor}
          selectedItems={selectedFilters["authors"] || []}
        />

        <FilterGroup
          collapsible={true}
          key={"filter_group_contributors"}
          name={"Contributor"}
          traits={traits.contributors}
          onCheck={this.onCheckContirbutor}
          selectedItems={selectedFilters["contributors"] || []}
        />

        <Collapsible
          triggerTagName="h4"
          trigger={
            <span>
              Insights Per Page <ArrowDownIcon height="18" />
            </span>
          }
          triggerClassName="cursor--pointer"
          triggerOpenedClassName="cursor--pointer"
          className="Filter"
          openedClassName="Filter"
        >
          <ul>
            <li>
              <label className="CheckBox">
                <input
                  type="radio"
                  defaultChecked={true}
                  name="per_page"
                  onChange={(e) => this.props.onLimitChange(25)}
                />
                <span className="checkmark"></span>
              </label>
              <p>25</p>
              <p></p>
            </li>

            <li>
              <label className="CheckBox">
                <input
                  name="per_page"
                  type="radio"
                  defaultChecked={false}
                  onChange={(e) => this.props.onLimitChange(50)}
                />
                <span className="checkmark"></span>
              </label>
              <p>50</p>
              <p></p>
            </li>

            <li>
              <label className="CheckBox">
                <input
                  name="per_page"
                  type="radio"
                  defaultChecked={false}
                  onChange={(e) => this.props.onLimitChange(100)}
                />
                <span className="checkmark"></span>
              </label>
              <p>100</p>
              <p></p>
            </li>

            <li>
              <label className="CheckBox">
                <input
                  name="per_page"
                  type="radio"
                  defaultChecked={false}
                  onChange={(e) => this.props.onLimitChange(200)}
                />
                <span className="checkmark"></span>
              </label>
              <p>200</p>
              <p></p>
            </li>
          </ul>
        </Collapsible>
      </React.Fragment>
    );
  }
}

interface IFilterGroupProps {
  name: string;
  traits: Array<DataTypes.Trait>;
  onCheck(checked: boolean, type: string, value: string): void;
  selectedItems: Array<string>;
  collapsible?: boolean;
}

const FilterGroup = ({
  name,
  traits,
  onCheck,
  selectedItems,
  collapsible = false,
}: IFilterGroupProps) => {
  if (traits.length <= 0) {
    return null;
  }

  const options = (
    <ul>
      {traits.map((t, idx) => {
        return (
          <li key={`filter_group_trait_${idx}`}>
            <label className="CheckBox">
              <input
                type="checkbox"
                defaultChecked={
                  selectedItems.indexOf(t.name) >= 0 ? true : false
                }
                onChange={(e) => onCheck(e.target.checked, name, t.name)}
              />
              <span className="checkmark"></span>
            </label>
            <p>{t.name}</p>
            <p>{t.total}</p>
          </li>
        );
      })}
    </ul>
  );

  if (collapsible) {
    return (
      <Collapsible
        triggerTagName="h4"
        trigger={
          <span>
            {name} <ArrowDownIcon height="18" />
          </span>
        }
        triggerClassName="cursor--pointer"
        triggerOpenedClassName="cursor--pointer"
        className="Filter"
        openedClassName="Filter"
      >
        {options}
      </Collapsible>
    );
  }

  return (
    <div className="Filter">
      <h4>{name}</h4>
      {options}
    </div>
  );
};

export { Filters };
