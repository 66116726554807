import { ApiClient } from './client/api_client';

class Slack {
  static async index() {
    return await ApiClient.get('/v1/slack');
  }

  static async auth(code: string, kind: string) {
    return await ApiClient.post(`/v1/slack/auth`, { slack: { code, kind } });
  }

  static async delete(id: string) {
    return await ApiClient.delete(`/v1/slack/${id}`);
  }

  static async saveSettings(domainName: string) {
    return await ApiClient.post('/v1/slack/settings', {
      settings: {
        channel_name: domainName,
      },
    });
  }

  static async getSettings() {
    return await ApiClient.get('/v1/slack/settings');
  }
}

export { Slack };
