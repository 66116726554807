import React, { Component } from 'react';
import { Form, Input, Button, ValidatorTypes, Select } from 'comps/form';
import { Spacer } from 'comps/layout';

interface IProps {
  saving: boolean;
  onSubmit: (invite: { email: string, role: string }) => void;
  inviteNewUserLoading: boolean;
}

const ROLES = ['admin', 'write', 'read-only'];

class InviteNewUser extends Component<IProps> {
  onSubmit = (valid: boolean, dirty: boolean, data: any) => {
    const { email, role } = data;

    if (valid) {
      this.props.onSubmit({ email: email.value, role: role.value.split(' - ')[0] });
    }
  };

  render() {
    const { saving } = this.props;
    return (
      <React.Fragment>
        <div className="header">
          <h3>Invite New User</h3>
          <p>
            Enter the email address below to invite a new user to this illuminate account, <strong>{window.location.host}</strong>. An email will be sent to the
            user so they can create their user profile.{' '}
          </p>
        </div>
        <Form
          formFields={{
            email: { name: 'email', value: '', validator: { type: ValidatorTypes.EMAIL } },
            role: { name: 'role', value: 'write' },
          }}
          onSubmit={this.onSubmit}
          FormComponent={({ fields: { email, role }, onChange }) => {
            return (
              <div className="header">
                <Spacer />
                <div className="form-group">
                  <label>Email</label>
                  <Input field={email} className="form-control" onChange={(e) => onChange(email, e.target.value)} />
                </div>



                <div className="form-group">
                  <label>What is the role of this new user?</label>
                  <ul>
                    <li><p style={{ marginBottom: 0 }}>Admin: Creates content, Manages Users, Manages Settings</p></li>
                    <li><p style={{ marginBottom: 0 }}>Write: Creates content only</p></li>
                    <li><p style={{ marginBottom: 0 }}>Read-Only: Browses content only</p></li>
                  </ul>
                  <Select
                    key="counting_methodology"
                    className="AdobeMetric SelectInput"
                    placeholder="-- Select Counting Methodology --"
                    field={role}
                    options={ROLES}
                    onChange={(value: string) => onChange(role, value)}
                  />
                </div>

                <div className="cntrl-bar">
                  <Button className="btn btn-primary" text="invite user" isLoading={saving} />
                </div>
              </div>
            );
          }}
        />
      </React.Fragment>
    );
  }
}

export { InviteNewUser };
