import React, { useMemo } from "react";
import { UseFormDatePicker } from "../../../../../comps/form/useFormDatePicker";
import { UseFormInput } from "../../../../../comps/form/useFormInput";
import {
  CreateTargetFormContainer,
  DateRangeContainer,
} from "./goal_metric.style";
import { SubmitHandler, useForm } from "react-hook-form";
import * as yup from "yup";
import * as DataTypes from "store/types";
import { useYupValidationResolver } from "../../../../../hooks/yup-validation-resolver";

type Props = {
  programGoal: DataTypes.ProgramGoal;
  onCreate: (
    programGoalId: string,
    payload: Omit<DataTypes.ProgramGoalTarget, "id">
  ) => void;
};

const validationSchema = yup.object({
  name: yup.string().required("Required"),
  startsAt: yup.date().required("Required"),
  endsAt: yup.date().required("Required"),
  value: yup.number().required("Required"),
});

type Input = {
  name: string | null;
  startsAt: Date;
  endsAt: Date;
  value: number | null;
};

export const ProgramGoalTargetForm: React.FC<Props> = ({
  programGoal,
  onCreate,
}: Props) => {
  const resolver = useYupValidationResolver(validationSchema);
  const {
    setValue,
    control,
    handleSubmit,
    register,
    reset,
    formState: { errors },
  } = useForm<Input>({
    defaultValues: {
      endsAt: new Date(),
      startsAt: new Date(),
      name: null,
      value: null,
    },
    resolver,
  });

  const executeCreate: SubmitHandler<Input> = (data: Input) => {
    if (!programGoal || !programGoal.id) {
      return;
    }

    onCreate(programGoal.id, {
      starts_at: data.startsAt,
      ends_at: data.endsAt,
      name: data.name as string,
      value: data.value as number,
    });

    reset();
  };

  const valueClass = useMemo(() => {
    if (!programGoal || programGoal.data_type === "number") {
      return "";
    }

    return ["currency", programGoal.symbol?.toLowerCase() || "dollar"].join(
      " "
    );
  }, [programGoal]);

  return (
    <CreateTargetFormContainer>
      <h4>Create target</h4>

      <form onSubmit={handleSubmit(executeCreate)}>
        <UseFormInput
          className="grow-2 name"
          label="Name"
          fieldName={"name"}
          register={register}
          value={""}
          placeholder={"ie - 2024, Q1 2024, Q2 2024, etc"}
          errors={errors}
        />

        <DateRangeContainer>
          <label className="mbr-05">Dates</label>

          <ul className="DateRangePicker form-group">
            <li>
              <UseFormDatePicker
                control={control}
                fieldName="startsAt"
                setValue={setValue}
                errors={errors}
              />
            </li>
            <li className="seperator">to</li>
            <li>
              <UseFormDatePicker
                control={control}
                fieldName="endsAt"
                setValue={setValue}
                errors={errors}
              />
            </li>
          </ul>
        </DateRangeContainer>

        <UseFormInput
          className="value"
          label="Value"
          fieldName={"value"}
          register={register}
          value={0}
          placeholder={"ie - 500,000"}
          errors={errors}
          inputClassName={valueClass}
        />

        <div className="submit-container">
          <input type="submit" className="btn btn-primary" value="Create" />
        </div>
      </form>
    </CreateTargetFormContainer>
  );
};
