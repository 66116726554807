import React, { Component } from "react";
import { Form, Input, Button, ValidatorTypes } from "comps/form";
import * as DataTypes from "store/types";

interface IProps {
  app: DataTypes.App;
  saving: boolean;
  onCreate(body: any): void;
  onUpdate(app: DataTypes.App, body: any): void;
}

class AdobeModal extends Component<IProps> {
  constructor(props: IProps) {
    super(props);
    const { app } = this.props;
    if (!app) this.props.onCreate({ type: "adobe" });
  }

  onSubmit = (valid: boolean, dirty: boolean, data: any) => {
    const { app } = this.props;
    if (valid) {
      this.props.onUpdate(app, {
        client_id: data.client_id.value,
        client_secret: data.client_secret.value,
        target_profile: data.target_profile.value,
        global_company_id: data.global_company_id.value,
        enabled: true,
      });
    }
  };

  render() {
    const { app, saving } = this.props;
    if (app) {
      return (
        <div className="panels">
          <div className="left">
            <label>integration</label>
            <div className="header">
              <img src="/img/apps/adobe.png" alt="adobe" />
              <h2>Adobe</h2>
            </div>
            <p>illuminate integrates with Adobe accounts that have A4T!</p>
            <p className="mt-2">
              Pull test data into illuminate from Adobe, quickly tying data to
              rich case study content. This paints the full picture of a test -
              from hypothesis and screenshots to the final report and customer
              insights.
            </p>
            <p>Here are the instructions:</p>

            <div style={{ height: "400px", overflowY: "scroll" }}>
              <ol>
                <li>
                  Login to the Adobe I/O Console -{" "}
                  <a
                    target="_blank"
                    href="https://console.adobe.io"
                    rel="noopener noreferrer"
                  >
                    https://console.adobe.io/
                  </a>
                </li>
                <li>
                  Create a new project, select "Add API," choose "Adobe
                  Analytics", and select "OAuth Server-to-Server"
                </li>
                <li>
                  When selecting Product Profiles, select Web Service Access,
                  Reports & Analytics Access, Report Builder, Analysis Workspace
                  Access, and any others specific to your configuration
                </li>
                <li>Then, click "Save configured API."</li>
                <li>
                  Click "+ Add to Project" and then select "API" from the
                  dropdown.
                </li>
                <li>
                  Select Adobe Target and proceed. OAuth Server-to-Server will
                  be automatically selected.
                </li>
                <li>
                  When selecting product profiles, select any profiles relevant
                  to your configuration.
                </li>
                <li>Then, click "Save configured API."</li>
              </ol>

              <p>
                Now, it's time to copy info from Adobe and paste it into
                illuminate to finalize the integration.
              </p>

              <ol>
                <li>
                  Select OAUth Server-to-Server in the left rail under
                  Credentials
                </li>

                <li>
                  Identify your Client ID, copy it, paste it in the Client ID
                  field of this modal
                </li>

                <li>
                  Reveal the Client Secret, copy it, paste it in the Client
                  Secret field of this modal
                </li>

                <li>
                  Provide your Adobe Target{" "}
                  <a
                    target="_blank"
                    href="https://intercom.help/illuminate-v2/en/articles/4580511-finding-your-adobe-target-client-code"
                    rel="noopener noreferrer"
                  >
                    "Client Code"
                  </a>
                </li>

                <li>
                  Provide your Adobe Analytics{" "}
                  <a
                    target="_blank"
                    href="https://intercom.help/illuminate-v2/en/articles/4580513-finding-your-adobe-analytics-global-company-id"
                    rel="noopener noreferrer"
                  >
                    "Global Company ID"
                  </a>
                </li>
              </ol>

              <p>
                For detailed, step-by-step instructions{" "}
                <a
                  target="_blank"
                  href="https://intercom.help/illuminate-v2/en/articles/4579676-in-depth-adobe-integration-instructions"
                  rel="noopener noreferrer"
                >
                  view this article.
                </a>
              </p>
            </div>
          </div>
          <div className="right">
            <Form
              formFields={{
                client_id: {
                  name: "client_id",
                  value: app.client_id || "",
                  validator: {
                    type: ValidatorTypes.REQUIRED,
                    messages: {
                      required: "Please enter a valid client id.",
                    },
                  },
                },
                client_secret: {
                  name: "client_secret",
                  value: app.client_secret || "",
                  validator: {
                    type: ValidatorTypes.REQUIRED,
                    messages: {
                      required: "Please enter a valid client secret.",
                    },
                  },
                },
                target_profile: {
                  name: "target_profile",
                  value: app.target_profile,
                  validator: {
                    type: ValidatorTypes.REQUIRED,
                    messages: {
                      required: "Please enter a valid target profile.",
                    },
                  },
                },
                global_company_id: {
                  name: "global_company_id",
                  value: app.global_company_id,
                  validator: {
                    type: ValidatorTypes.REQUIRED,
                    messages: {
                      required: "Please enter a valid global company id.",
                    },
                  },
                },
              }}
              onSubmit={this.onSubmit}
              FormComponent={({
                fields: {
                  client_id,
                  client_secret,
                  target_profile,
                  global_company_id,
                },
                onChange,
              }) => (
                <div>
                  <div className="form-group">
                    <label>Client Id</label>
                    <Input
                      field={client_id}
                      className="form-control"
                      onChange={(e) => onChange(client_id, e.target.value)}
                    />
                  </div>

                  <div className="form-group">
                    <label>Client Secret</label>
                    <Input
                      field={client_secret}
                      className="form-control"
                      onChange={(e) => onChange(client_secret, e.target.value)}
                    />
                  </div>
                  <div className="form-group">
                    <label>Adobe Target Client Code</label>
                    <Input
                      field={target_profile}
                      className="form-control"
                      onChange={(e) => onChange(target_profile, e.target.value)}
                    />
                  </div>
                  <div className="form-group">
                    <label>Adobe Analytics Global Company ID</label>
                    <Input
                      field={global_company_id}
                      className="form-control"
                      onChange={(e) =>
                        onChange(global_company_id, e.target.value)
                      }
                    />
                  </div>

                  <Button
                    className="btn btn-primary"
                    text="save"
                    isLoading={saving}
                  />
                </div>
              )}
            />
          </div>
        </div>
      );
    }

    return <div>Loader</div>;
  }
}

export { AdobeModal };
