import React, { Component } from "react";
import { createLoadingSelector } from "store/selectors";
import { ApplicationState } from "store/types";
import { connect } from "react-redux";
import * as DataTypes from 'store/types';
import { listSlackRequest, deleteSlackRequest, saveSlackSettingsRequest } from 'store/slack/actions';

const SLACK_NOTIFICATION_KINDS = [
  {
    name: 'When a test starts',
    slug: 'experiment_starting',
  },
  {
    name: 'When a test ends',
    slug: 'experiment_ending',
  },
  {
    name: "With a weekly status update every Monday",
    slug: 'whats_up_this_week',
  },
];

interface IProps {
  saving: boolean;
  listSlackRequest: () => void;
  deleteSlackRequest: (id: string) => void;
  saveSlackSettingsRequest: (domainName: string) => void;
  loading: boolean;
  app: any;
  account: DataTypes.Account | null;
  slack: any;
}

interface IState {
  copied: boolean;
  application: DataTypes.Application | null;
  creating: boolean;
}

class SlackModal extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      copied: false,
      application: null,
      creating: false,
    };
  }

  componentDidMount = () => {
    this.props.listSlackRequest();
  }

  buildSlackIntegration = () => {
    return (
      <div className="panels">
        <div className="left">
          <label>integration</label>
          <div className="header">
            <img src="/img/apps/Slack_RGB.svg" alt="Slack" style={{ width: '143px' }} />
          </div>
          <p>illuminate integrates with Slack to send automatic notifications.</p>
          <p>Decide which notifications you want, and map them to your Slack channels.</p>
          {/* <p>Available commands are listed to the right, and will be available to the Slack user when/where they use the command.</p> */}
          {/* <a href="https://slack.com/oauth/v2/authorize?client_id=18199151862.1742959312867&scope=incoming-webhook,commands&user_scope="><img alt="Add to Slack" height="40" width="139" src="https://platform.slack-edge.com/img/add_to_slack.png" srcSet="https://platform.slack-edge.com/img/add_to_slack.png 1x, https://platform.slack-edge.com/img/add_to_slack@2x.png 2x" /></a> */}
          <p><strong>Note:</strong> If you are not an App Manager of your Slack workspace, a request will be sent to one for approval.</p>
        </div>
        <div className="right">
          {this.buildForm()}
        </div>
      </div>
    );
  }

  private deleteChannel = (item: any) => {
    this.props.deleteSlackRequest(item.id);
  }

  private buildSlackChannelButton = (item: any, kind: any) => {
    if (!item) {
      return <a href={this.slackUrl(kind.slug)}>Select Channel</a>;
    }

    return <React.Fragment>
      <p style={{ marginBottom: '0' }}>{item.channel}</p>
      <i onClick={() => this.deleteChannel(item)} className="fas fa-times" style={{ color: '#757e8e', cursor: 'pointer' }}></i>
    </React.Fragment>;
  }

  // private saveSlackDomain = (domainField: FormField) => {
  //   const filteredDomain = domainField.value?.replace('.slack.com', '');

  //   if (domainField.valid) {
  //     this.props.saveSlackSettingsRequest(filteredDomain);
  //   }
  // }

  // private buildSlackDomain = () => {
  //   return <Form
  //     formFields={{
  //       domain_name: {
  //         name: 'domain_name',
  //         value: this.props.slack?.settings?.channel_name || '',
  //         validator: { type: ValidatorTypes.REQUIRED, messages: { required: 'Please enter a valid slack workspace' } },
  //       },
  //     }}
  //     FormComponent={({ fields: { domain_name }, onChange }) => (
  //       <div>
  //         <div className="form-group">
  //           <label>SLACK WORKSPACE</label>
  //           <Input
  //             onBlur={this.saveSlackDomain}
  //             field={domain_name}
  //             className="form-control"
  //             placeholder="your-hyphen.slack.com"
  //             onChange={(e) => onChange(domain_name, e.target.value)} />
  //         </div>
  //       </div>
  //     )}
  //   />;
  // }

  buildForm = () => {
    if (this.props.loading) {
      return <p>loading</p>;
    }

    return <div style={{ marginTop: '48px' }}>
      {/* {this.buildSlackDomain()} */}

      <span>
        <strong>NOTIFY ME:</strong>
      </span>

      {SLACK_NOTIFICATION_KINDS.map((kind: any) => {
        const item = this.findKind(kind);

        return (<div className="flex justify-between" key={kind.slug}>
          <div className="flex justify-center column" style={{ width: '180px' }}>
            <span style={{ marginBottom: 0, color: '#71798b', fontSize: '14px' }}>{kind.name}</span>
          </div>

          <div style={{ width: '140px', fontSize: '14px' }} className="flex justify-between">
            {this.buildSlackChannelButton(item, kind)}
          </div>
        </div>)
      })}

      {/* <div style={{ marginTop: '24px' }}>
        <span>
          <strong>AVAILABLE COMMANDS:</strong>
          <p style={{ marginBottom: 0, color: '#71798b', fontSize: '14px' }}>
            Display a list of tests when someone types <strong>/tests</strong> into Slack
          </p>

          <p style={{ marginBottom: 0, color: '#71798b', fontSize: '14px' }}>
            Display a list of insights when someone types <strong>/insights</strong> into Slack
          </p>
        </span>
      </div> */}
    </div>
  }

  private findKind = (kind: any) => {
    if (!this.props.slack?.list) {
      return;
    }

    return this.props.slack.list.filter((item: any) => item.kind === kind.slug)[0];
  }

  private slackUrl = (kind: string) => {
    const permissions = [
      'incoming-webhook',
    ];

    const scopes = permissions.map(permission => `scope[]=${permission}`);
    const redirectUri = process.env.REACT_APP_SLACK_REDIRECT_URI;
    const currentAccountSlug = this.props.account?.slug;

    return `https://slack.com/oauth/v2/authorize?redirect_uri=${redirectUri}&client_id=${process.env.REACT_APP_SLACK_CLIENT_ID}&state=${`${currentAccountSlug};${kind}`}&${scopes.join('&')}`
  }

  buildLoading = () => {
    return <p>Loading...</p>;
  }

  render() {
    if (this.props.loading || this.props.saving) {
      return this.buildLoading();
    }

    return this.buildSlackIntegration();
  }
}

const initLoader = createLoadingSelector(['@@slack/LIST_SLACK']);

const mapStateToProps = ({ account, router, app, application, slack }: ApplicationState) => ({
  loading: initLoader(app.requests),
  router,
  applications: application,
  account,
  slack,
});

const mapDispatchToProps = {
  listSlackRequest,
  deleteSlackRequest,
  saveSlackSettingsRequest,
};

const connectedPage = connect(mapStateToProps, mapDispatchToProps)(SlackModal);

export { connectedPage as SlackModal };
