import React, { Component, useCallback } from "react";
import { connect } from "react-redux";
import { Form, Input, Button, ValidatorTypes } from "comps/form";
import { PublicPage } from "comps/pages";
import * as DataTypes from "store/types";
import { signupRequest } from "store/user/actions";
import {
  createLoadingSelector,
  createErrorMessageSelector,
} from "store/selectors";
import { history } from "utils";
import { HTTPCodes } from "config/app";
import * as AppUtils from "utils/app";
import { useKeycloak } from "@react-keycloak/web";

interface IProps {
  signupRequest: (email: string) => void;
  formLoading: boolean;
  formError: any;
  signupStep?: string;
}

type SignupRedirectProps = {};

const GoogleSignupButton: React.FC<SignupRedirectProps> = () => {
  const { keycloak } = useKeycloak();

  const goToLogin = useCallback(() => {
    const subDomain = AppUtils.App.getSubDomain();

    keycloak.login({
      redirectUri: `${process.env.REACT_APP_HTTP_PROTO}://${subDomain}.${process.env.REACT_APP_BASE_URL}/signup/google/activate`,
      idpHint: "google",
    });
  }, []);

  return (
    <button className="btn btn-google" type="button" onClick={goToLogin}>
      use your google account
    </button>
  );
};

class SignUp extends Component<IProps> {
  signupEmail: string = "";

  private onSubmit = (valid: boolean, dirty: boolean, data: any) => {
    if (valid && dirty) {
      const { email } = data;
      this.signupEmail = email.value;
      this.props.signupRequest(email.value);
    }
  };

  render() {
    const { formLoading, signupStep, formError } = this.props;

    if (signupStep === "pending") {
      setTimeout(
        () => history.push(`/signup_success?email=${this.signupEmail}`),
        0
      );
      return null;
    }

    if (formError && formError.statusCode >= HTTPCodes.SYSTEM_ERROR)
      throw formError;

    return (
      <React.Fragment>
        <div className="content">
          <div className="logo">
            <img src="/img/logo.png" alt="illuminate" />
          </div>
          <div className="form">
            <h1>Create your free illuminate account</h1>
            <p className="mb-0">
              Store, share and learn from your A/B tests with illuminate.
            </p>
            <p className="mb-4">
              <i>Now integrates with Adobe Target!</i>
            </p>

            <React.Fragment>
              <GoogleSignupButton />
              <div className="or-splitter"></div>
            </React.Fragment>

            <Form
              formFields={{
                email: {
                  name: "email",
                  value: "",
                  validator: {
                    type: ValidatorTypes.EMAIL,
                    messages: {
                      type: "Whoops! Please enter a valid email address.",
                    },
                  },
                },
              }}
              onSubmit={this.onSubmit}
              FormComponent={({ fields: { email }, onChange }) => (
                <div>
                  <div className="form-group">
                    <label>work email</label>
                    <Input
                      field={email}
                      className="form-control"
                      onChange={(e) => onChange(email, e.target.value)}
                    />
                  </div>
                  <Button
                    className="btn btn-primary"
                    text="continue"
                    isLoading={formLoading}
                  />

                  <div>
                    <p>
                      Already have an account?{" "}
                      <a
                        href={`${process.env.REACT_APP_HTTP_PROTO}://chooseaccount.${process.env.REACT_APP_BASE_DOMAIN}/account/choose`}
                      >
                        Sign in.
                      </a>
                    </p>
                  </div>
                </div>
              )}
            />
          </div>
        </div>
        <div className="aside signup">
          <img src="/img/bg_signup.png" alt="illuminate" />
        </div>
      </React.Fragment>
    );
  }
}

const formLoader = createLoadingSelector(["@@user/SIGNUP"]);
const signUpError = createErrorMessageSelector(["@@user/SIGNUP"]);

const mapStateToProps = ({ app }: DataTypes.ApplicationState) => ({
  formLoading: formLoader(app.requests),
  formError: signUpError(app.errors),
  signupStep: app.signupStep,
});

const mapDispatchToProps = {
  signupRequest,
};

const connectedPage = connect(
  mapStateToProps,
  mapDispatchToProps
)(PublicPage(SignUp));

export { connectedPage as SignUp };
