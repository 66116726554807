import { action } from 'typesafe-actions';
import { Dispatch } from 'redux';
import { ActionTypes } from './types';
import * as DataTypes from '../types';

interface RequestError {
  statusCode?: number | undefined;
  message: any;
}

export const createReportTargetRequest = (experimentId: string, body: any) => (dispatch: Dispatch) =>
  dispatch(action(ActionTypes.CREATE_REPORT_TARGET_REQUEST, { experimentId, body }));
export const createReportTargetSuccess = (response: DataTypes.ApiRequest<DataTypes.Experiment>) =>
  action(ActionTypes.CREATE_REPORT_TARGET_SUCCESS, { response });
export const createReportTargetError = (error: RequestError) =>
  action(ActionTypes.CREATE_REPORT_TARGET_FAILURE, error);