import { action } from "typesafe-actions";
import { Dispatch } from "redux";
import { ActionTypes } from "./types";
import * as DataTypes from "../types";

interface RequestError {
  statusCode?: number | undefined;
  message: any;
}

export const getUxrsTraitRequestSaga = () =>
  action(ActionTypes.GET_UXRS_TRAITS_REQUEST, {});
export const getUxrsTraitsRequest = () => (dispatch: Dispatch) =>
  dispatch(action(ActionTypes.GET_UXRS_TRAITS_REQUEST));
export const getUxrsTraitsSuccess = (
  response: DataTypes.ApiRequest<DataTypes.Trait>
) => action(ActionTypes.GET_UXRS_TRAITS_SUCCESS, { response });
export const getUxrsTraitsError = (error: RequestError) =>
  action(ActionTypes.GET_UXRS_TRAITS_FAILURE, error);

export const createUxrsTraitsRequest =
  (uxrId: string, traitName: string, values: string[]) =>
  (dispatch: Dispatch) =>
    dispatch(
      action(ActionTypes.CREATE_UXRS_TRAITS_REQUEST, {
        uxrId,
        traitName,
        values,
      })
    );
export const createUxrsTraitsSuccess = (
  response: DataTypes.ApiList<DataTypes.Uxr>
) => action(ActionTypes.CREATE_UXRS_TRAITS_SUCCESS, { response });
export const createUxrsTraitsError = (error: RequestError) =>
  action(ActionTypes.CREATE_UXRS_TRAITS_FAILURE, error);
