import React from 'react';

interface IStarControlProps {
  starred: boolean;
  onClick(starred: boolean): void;
}

export const StarControl = ({ starred, onClick }: IStarControlProps) => {
  return (
    <div className="StarControl">
      <button className={starred ? 'btn btn-star active' : 'btn btn-star'} type="button" onClick={() => onClick(!starred)}>
        <i className="fa fa-star" />
      </button>
    </div>
  );
};
