import React, { Component } from "react";
import { FormField } from "./";

interface CheckBoxOption {
  value: string;
  TextComponent?: React.ComponentType<any>;
}

interface Props {
  field: FormField;
  className: string;
  options: CheckBoxOption[];
  onChange(e: any): any;
}

class CheckBoxGroup extends Component<Props> {
  public render() {
    const { className, options, onChange } = this.props;
    const { value, valid, error, name } = this.props.field;
    const cn = valid === false ? className + " error" : className;

    return (
      <>
        <ul>
          {options.map(
            ({ TextComponent, value: optionValue }: CheckBoxOption) => (
              <li key={`radio-checkbox-group-option-${optionValue}`}>
                <label className="CheckBox">
                  <input
                    type="checkbox"
                    name={name}
                    id={`radio-button-option-${optionValue}`}
                    className={cn}
                    onChange={(event) => onChange(event)}
                    value={optionValue}
                    checked={value.lastIndexOf(optionValue) !== -1}
                  />
                  <span className="checkmark"></span>
                </label>
                <p className="d-inline ml-2">
                  {TextComponent ? <TextComponent /> : optionValue}
                </p>
              </li>
            )
          )}
        </ul>
        {valid === false && <div className="error">{error}</div>}
      </>
    );
  }
}

export { CheckBoxGroup };
