import styled from "styled-components";

export const DataVizTable = styled.div`
  margin-top: 3rem;
  display: flex;
`;

export const TableColumn = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid #eaeaea;
  flex-grow: 1;

  &.grow-1 {
    flex-grow: 1;
  }

  &.grow-2 {
    flex-grow: 2;
  }

  &.no-border {
    border: none;
  }
`;

export const TableHeader = styled.div`
  height: 2.5rem;
  border-right: 1px solid #eaeaea;
  display: flex;
  align-items: center;
  justify-content: center;

  p {
    margin: 0;
  }

  &.no-border {
    border-right: none;
  }

  &.fix {
    color: #757e8e;
    border-bottom: 1px solid #eaeaea;
    text-align: center;
  }
`;

export const TableCell = styled.div``;

export const ReportCell = styled.div`
  height: 5rem;
  color: #757e8e;
  border-bottom: 1px solid #eaeaea;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;

  &.pointer {
    cursor: pointer;
  }

  &.active {
    background-color: #f6fafd;
  }
`;

export const TableReportDataCel = styled.div`
  display: flex;
  flex-grow: 1;
`;

export const TableReportMetricCell = styled.div`
  height: 5rem;
  flex-grow: 1;
  border-bottom: 1px solid #eaeaea;
  border-right: 1px solid #eaeaea;

  display: flex;
  align-items: center;
  justify-content: center;

  &.no-border {
    border-right: none;
  }

  p {
    margin: 0;
    color: rgb(47, 71, 96);
  }

  &.active {
    background-color: #f6fafd;
  }
`;

export const TableReportColumnContainer = styled.div`
  display: flex;
`;
