import React, { useEffect, useMemo, useState } from "react";
import { ReportList, TabsContent } from "./style";
import * as DataTypes from "store/types";

type Params = {
  experiment: DataTypes.Experiment;
  active: boolean;
  onSelectReport: (reports: DataTypes.Report[]) => void;
  previousSelected?: DataTypes.Report[];
};

export const ReportTab: React.FC<Params> = ({
  experiment,
  active,
  onSelectReport,
  previousSelected,
}) => {
  const [selectedReports, setSelectedReports] = useState<DataTypes.Report[]>(
    () => (previousSelected ? previousSelected : [])
  );

  const selectedReportsIds = useMemo(
    () => selectedReports.map((report) => report.id),
    [selectedReports]
  );

  useEffect(() => {
    onSelectReport(selectedReports);
  }, [selectedReports]);

  const selectReport = (report: DataTypes.Report) => {
    const currentIndex = selectedReportsIds.lastIndexOf(report.id);

    if (currentIndex === -1) {
      setSelectedReports([...selectedReports, report]);
      return;
    }

    setSelectedReports(
      selectedReports.filter((reportTmp) => report.id !== reportTmp.id)
    );
  };
  return (
    <TabsContent className={`${active ? "active" : ""}`}>
      <p>
        Select which reports you’d like to reference. These will serve as points
        of comparison across reports.
      </p>

      <ReportList>
        {experiment.reports.map((report: DataTypes.Report) => (
          <div key={`report_tab_report_${report.id}`}>
            <input
              id={report.id}
              type="checkbox"
              defaultChecked={selectedReportsIds.lastIndexOf(report.id) !== -1}
              onClick={() => selectReport(report)}
            />
            <label htmlFor={report.id}>{report.name}</label>
          </div>
        ))}
      </ReportList>
    </TabsContent>
  );
};
