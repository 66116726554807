import React, { Component } from "react";
import * as DataTypes from "store/types";
import { ToolTip } from "ui";

interface IProps {
  uxr: DataTypes.Uxr;
  saving: boolean;
  onSubmit(selectedExperiences: Array<string>): void;
}

interface IState {
  selectedQuantitatives: Array<string>;
}

class SelectQuantitatives extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    const { uxr } = this.props;

    this.state = {
      selectedQuantitatives: uxr.share?.quantitatives || [],
    };
  }

  private onCheck = (checked: boolean, data: DataTypes.SupportingData) => {
    const datas = this.state.selectedQuantitatives;

    if (checked === true) {
      datas.push(data.id);
    } else {
      const iIdx = datas.findIndex((i) => i === data.id);
      if (iIdx >= 0) datas.splice(iIdx, 1);
    }

    this.setState({ ...this.state, selectedQuantitatives: datas });
  };

  render() {
    const { uxr, onSubmit, saving } = this.props;
    const { selectedQuantitatives } = this.state;
    return (
      <React.Fragment>
        <div className="header">
          <h3>Select Quantitative Data</h3>
          <p>Select the experiences to show in the case study.</p>
        </div>
        <div className="body">
          {uxr.supporting_datas
            .filter((data) => data.type === "Uxr::SupportingData::Quantitative")
            .map((data, idx) => {
              return (
                <div key={`exp_${data.id}`} className="ExperienceSelector">
                  <div className="selector">
                    <label className="CheckBox">
                      <input
                        type="checkbox"
                        disabled={!data.area_of_focus || !data.area_of_focus.id}
                        defaultChecked={selectedQuantitatives.includes(data.id)}
                        onChange={(e) => this.onCheck(e.target.checked, data)}
                      />
                      <span className="checkmark"></span>
                    </label>
                  </div>
                  <div className="experience">
                    <div className="head">
                      <div className="hexagon">
                        <label
                          style={{
                            paddingLeft: "24px",
                            color:
                              !data.area_of_focus || !data.area_of_focus.id
                                ? "#757e8e"
                                : "#212529",
                          }}
                        >
                          {data.sumarized_data}
                          {(!data.area_of_focus || !data.area_of_focus.id) && (
                            <ToolTip
                              className="align-left"
                              message="To enable data, please provide an area of focus"
                            />
                          )}
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
        </div>
        <div className="cntrl-bar">
          {saving === true ? (
            <button className="btn btn-primary" type="submit" disabled={true}>
              <i className="fas fa-circle-notch fa-spin" />
            </button>
          ) : (
            <button
              className="btn btn-primary"
              onClick={() => onSubmit(selectedQuantitatives)}
            >
              save
            </button>
          )}
        </div>
      </React.Fragment>
    );
  }
}

export { SelectQuantitatives };
