import React, { Component } from "react";
import { FormField, InputTypes } from "./";
import { Validator } from "./validator";

interface Props {
  field: FormField;
  maxLength?: number;
  className: string;
  placeholder?: string;
  options: Array<any>;
  type?: InputTypes | InputTypes.TEXT;
  onOpen?: any;
  onChange(e: any): any;
}

interface State {
  field: FormField;
  edit: boolean;
  hoverIdx: number;
  searchVal: string;
  results: Array<any>;
  exactMatch: boolean;
}

class MultiSelect extends Component<Props, State> {
  validator: Validator;

  constructor(props: Props) {
    super(props);
    this.validator = new Validator();
    this.state = {
      field: this.props.field,
      edit: false,
      hoverIdx: 0,
      searchVal: "",
      results: this.props.options,
      exactMatch: false,
    };
  }

  static getDerivedStateFromProps = (nextProps: Props) => {
    return { field: nextProps.field };
  };

  public validate = () => {
    this.validator.validateField(this.state.field);
    this.setState({ field: this.state.field });
  };

  private setEdit = (edit: boolean) => {
    this.setState({ ...this.state, edit: edit });
    if (edit && this.props.onOpen) {
      this.props.onOpen(edit);
    }
  };

  private setHoverIndex = (idx: number) => {
    this.setState({ ...this.state, hoverIdx: idx });
  };

  private addItem = (item: any) => {
    const { value } = this.props.field;
    let newValue = value;
    newValue.push(item);
    this.validator.validateField(this.state.field);
    this.setState({
      ...this.state,
      field: this.state.field,
      edit: false,
      searchVal: "",
    });
    this.props.onChange(newValue);
  };

  private removeItem = (itemIdx: number) => {
    const { value } = this.props.field;
    let newValue = value;
    newValue = newValue
      .slice(0, itemIdx)
      .concat(newValue.slice(itemIdx + 1, value.length));
    this.props.onChange(newValue);
    this.setState({ ...this.state, edit: false });
  };

  private setSearchValue = (value: string) => {
    const { options } = this.props;
    const results = options.filter((val) =>
      val.toLowerCase().startsWith(value.toLowerCase())
    );
    const exact =
      results.filter((val) => val.toLowerCase() === value.toLowerCase())
        .length > 0;

    this.setState({
      ...this.state,
      searchVal: value,
      exactMatch: exact,
      hoverIdx: exact ? 0 : -1,
      results: results,
    });
  };

  private unFocus = (e: any) => {
    var currentTarget = e.currentTarget;
    setTimeout(() => {
      if (!currentTarget.contains(document.activeElement)) {
        this.setState(
          {
            ...this.state,
            edit: false,
            searchVal: "",
            hoverIdx: 0,
            results: this.props.options,
          },
          () => {
            if (this.props.onOpen) {
              this.props.onOpen(this.state.edit);
            }
          }
        );
      }
    }, 0);
  };

  private preventEnter = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.which === 13 /* Enter */) {
      event.preventDefault();
      const { searchVal } = this.state;
      if (searchVal && searchVal.trim() !== "") this.addItem(searchVal);
    }
  };

  public render() {
    const { className } = this.props;
    const { edit, hoverIdx, field, searchVal, results, exactMatch } =
      this.state;
    const { value, valid, error } = field;
    const cn = valid === false ? className + " error" : className;

    return (
      <div
        tabIndex={1}
        className={edit ? cn + " edit" : cn}
        onBlur={(e) => this.unFocus(e)}
      >
        <ul className="input_box" onClick={() => !edit && this.setEdit(!edit)}>
          {value.map((item: any, idx: number) => (
            <li key={`item_${item}_${idx}`}>
              <span>{item}</span>
              {edit === true && (
                <button
                  type="button"
                  onClick={() => this.removeItem(idx)}
                ></button>
              )}
            </li>
          ))}
        </ul>
        {valid === false && <div className="error">{error}</div>}
        <div className="menu">
          <div className="search">
            <input
              type="text"
              placeholder="Search or add new"
              ref={(input) => input && input.focus()}
              value={searchVal}
              onKeyPress={(e) => this.preventEnter(e)}
              onChange={(e) => this.setSearchValue(e.target.value)}
            />
          </div>
          <div className="results">
            <ul>
              {searchVal !== "" && exactMatch === false && (
                <li
                  className={hoverIdx === -1 ? "over new" : "new"}
                  onMouseOver={(e) => this.setHoverIndex(-1)}
                  onClick={(e) => this.addItem(searchVal)}
                >
                  {searchVal}
                </li>
              )}
              {results.map((item, idx) => (
                <li
                  key={`result_${item}_${idx}`}
                  className={
                    hoverIdx === idx
                      ? value.includes(item)
                        ? "over active"
                        : "over"
                      : ""
                  }
                  onMouseOver={() => this.setHoverIndex(idx)}
                  onClick={() =>
                    value.includes(item)
                      ? this.removeItem(idx)
                      : this.addItem(item)
                  }
                >
                  {item}
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    );
  }
}

export { MultiSelect };
