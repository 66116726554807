import React, { Component } from "react";
import { PublicPage, PublicPageProps } from "comps/pages";
import { appConfig } from "config/app";

interface IProps extends PublicPageProps {}

class Unauthorized extends Component<IProps> {
  render() {
    return (
      <React.Fragment>
        <div className="content">
          <div className="logo">
            <img src="/img/logo.png" alt="illuminate" />
          </div>
          <div className="form">
            <h1>Unauthorized</h1>
            <p className="mb-4">
              Please ask your administrator for admin privileges to access this
              resource.
            </p>
            <p>
              In the meantime, please check out our{" "}
              <a
                href={appConfig.HELP_URL}
                target="_blank"
                rel="noopener noreferrer"
              >
                Help Center.
              </a>
            </p>
          </div>
        </div>
        <div className="aside"></div>
      </React.Fragment>
    );
  }
}

const page = PublicPage(Unauthorized);

export { page as Unauthorized };
