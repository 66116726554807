import React from "react";
import { PublicPage } from "comps/pages";
import { AuthService } from "utils/auth";
import { history } from "utils";

interface IProps {
  location: any;
}

const Loading = (props: IProps) => {
  setTimeout(() => {
    const jwt = getJWT(props.location);
    if (jwt) AuthService.logIn(jwt);
    history.push("/");
  }, 0);
  return (
    <div className="content">
      <div className="row" style={{ width: "100%" }}>
        <div className="col-md-3" />
        <div className="col-md-6" style={{ textAlign: "center" }}>
          <div className="center">
            <img src="/img/logo.png" alt="illuminate" />
          </div>
        </div>
        <div className="col-md-3" />
      </div>
    </div>
  );
};

const getJWT = (location: any) => {
  try {
    const query = location.search.length ? location.search : null;
    if (!query) return "";

    return query.substring(5, query.length);
  } catch (error: any) {
    return "";
  }
};

const _export = PublicPage(Loading);

export { _export as Loading };
