import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import * as API from "../../api";
import * as Actions from "./actions";
import * as AppTypes from "../app/types";
import { ActionTypes } from "./types";
import * as AccountActions from "../account/actions";

function* createProgramGoalTarget(action: any) {
  try {
    // @ts-ignore
    const res = yield call(
      API.ProgramGoalTarget.create,
      action.payload.programGoalId,
      action.payload.payload
    );
    yield put(Actions.createProgramGoalTargetSuccess(res.body.results));

    // @ts-ignore
    const accountRes = yield call(
      API.Account.get,
      window.location.hostname.split(".")[0]
    );

    yield put(AccountActions.getAccountSuccess(accountRes.body));
    yield put({
      type: AppTypes.App.ALERT_SHOW,
      content: "Program goal target created successfully",
    });
  } catch (error: any) {
    yield put(Actions.createProgramGoalTargetError(error));
    yield put({
      type: AppTypes.App.ALERT_SHOW,
      content: "Something went wrong.",
      error: true,
    });
  }
}

function* deleteProgramGoalTarget(action: any) {
  try {
    // @ts-ignore
    const res = yield call(
      API.ProgramGoalTarget.destroy,
      action.payload.programGoalId,
      action.payload.goalId
    );
    yield put(Actions.deleteProgramGoalTargetSuccess(res.body.results));

    // @ts-ignore
    const accountRes = yield call(
      API.Account.get,
      window.location.hostname.split(".")[0]
    );

    yield put(AccountActions.getAccountSuccess(accountRes.body));
    yield put({ type: AppTypes.App.MODAL_HIDE });
    yield put({
      type: AppTypes.App.ALERT_SHOW,
      content: "Program goal target deleted successfully",
    });
  } catch (error: any) {
    yield put(Actions.deleteProgramGoalTargetError(error));
    yield put({
      type: AppTypes.App.ALERT_SHOW,
      content: "Something went wrong.",
      error: true,
    });
  }
}

function* watchCreateProgramGoalTarget() {
  yield takeEvery(
    ActionTypes.CREATE_PROGRAM_GOAL_TARGET_REQUEST,
    createProgramGoalTarget
  );
}

function* watchDeleteProgramGoalTarget() {
  yield takeEvery(
    ActionTypes.DELETE_PROGRAM_GOAL_TARGET_REQUEST,
    deleteProgramGoalTarget
  );
}

function* programGoalTargetSaga() {
  yield all([
    fork(watchCreateProgramGoalTarget),
    fork(watchDeleteProgramGoalTarget),
  ]);
}

export default programGoalTargetSaga;
