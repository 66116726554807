import {
  DataVizTable,
  ReportCell,
  TableColumn,
  TableHeader,
  TableReportColumnContainer,
  TableReportMetricCell,
} from "pages/app/experiments/comps/show/report/custom_data_viz/DataTable/index.style";
import React, { useMemo } from "react";
import * as DataTypes from "store/types";

type Params = {
  dataViz: DataTypes.CustomDataViz;
  onMetricClick?: (index: number) => void;
  currentIndex?: number;
};

const DataTableMetricResult: React.FC<{
  report: DataTypes.CustomDataVizReport;
  metricId: string;
  experienceId: string;
}> = ({ metricId, experienceId, report }) => {
  const metric = useMemo(
    () =>
      report.metrics.filter((metric) => metric.agregation_id === metricId)[0],
    [report.metrics]
  );

  const result = useMemo(() => {
    return metric.results.filter(
      (result: any) => result.experience_id === experienceId
    )[0];
  }, [metric.results]);

  const prefix = useMemo(() => {
    if (metric.metric_type === "currency") {
      return "$ ";
    }

    return "";
  }, [metric.metric_type]);

  const sufix = useMemo(() => {
    if (metric.metric_type === "percent") {
      return "%";
    }

    return "";
  }, [metric.metric_type]);

  if (metric.kind === "lift") {
    return <p>{result.lift}%</p>;
  }

  return (
    <p>
      {prefix}
      {result.value}
      {sufix}
    </p>
  );
};

export const DataTable: React.FC<Params> = ({
  dataViz,
  onMetricClick,
  currentIndex,
}) => {
  const experienceNames = useMemo(() => {
    const names: { [k: string]: string } = {};

    dataViz.reports.forEach((report) => {
      report.metrics.forEach((metric) => {
        metric.results.forEach((result) => {
          names[result.experience_id] = result.experience_name;
        });
      });
    });

    return names;
  }, [dataViz]);

  const metricNames = useMemo(() => {
    const names: { [k: string]: string } = {};

    dataViz.reports.forEach((report) => {
      report.metrics.forEach((metric) => {
        names[metric.agregation_id] = metric.name;
      });
    });

    return names;
  }, [dataViz]);

  return (
    <DataVizTable>
      <TableColumn className="grow-1">
        <TableHeader className="no-border"></TableHeader>
        <TableHeader className="fix no-border">
          <p className="bold">Metric</p>
        </TableHeader>

        {Object.keys(metricNames).map((metricId, index) => (
          <ReportCell
            key={`data_viz_data_table_metric_cell_${metricId}`}
            className={`${onMetricClick ? "pointer" : ""} ${
              onMetricClick && currentIndex === index ? "active" : ""
            }`}
            onClick={() => {
              if (onMetricClick) {
                onMetricClick(index);
              }
            }}
          >
            <p>{metricNames[metricId]}</p>
          </ReportCell>
        ))}
      </TableColumn>

      {Object.keys(experienceNames).map((experienceId) => (
        <TableColumn
          key={`data_viz_data_table_experience_${experienceId}_column`}
          className="grow-2"
        >
          <TableHeader className="fix no-border">
            <p className="bold">{experienceNames[experienceId]}</p>
          </TableHeader>
          <TableReportColumnContainer>
            {dataViz.reports.map((report, reportIndex) => (
              <TableColumn
                className="no-border"
                key={`data_viz_data_table_experience_${experienceId}_column_report_${report.id}`}
              >
                <TableHeader
                  className={`${
                    reportIndex === dataViz.reports.length - 1
                      ? "no-border"
                      : ""
                  } fix`}
                >
                  <p className="semi-bold">{report.name}</p>
                </TableHeader>

                {Object.keys(metricNames).map((metricId, index) => (
                  <TableReportMetricCell
                    className={`${
                      reportIndex === dataViz.reports.length - 1
                        ? "no-border"
                        : ""
                    } ${
                      onMetricClick && currentIndex === index ? "active" : ""
                    }`}
                    key={`data_viz_data_table_experience_${experienceId}_column_report_${report.id}_metric_${metricId}`}
                  >
                    <DataTableMetricResult
                      report={report}
                      metricId={metricId}
                      experienceId={experienceId}
                    />
                  </TableReportMetricCell>
                ))}
              </TableColumn>
            ))}
          </TableReportColumnContainer>
        </TableColumn>
      ))}
    </DataVizTable>
  );
};
