import React, { useRef } from "react";
import { AuthService } from "utils/auth";

interface IProps {
  value?: number;
  onChange(value: string): void;
  disabled?: boolean;
  noCursor?: boolean;
}

const MethodologySelector = ({
  value,
  onChange,
  disabled = false,
  noCursor = false,
}: IProps) => {
  const ref = useRef<any>();

  const getValue = (value: string) => {
    return Number(value);
  };

  const setValue = (value?: number) => {
    if (!value) {
      return "0";
    }
    return value.toString();
  };

  const isReadOnly = () => {
    const user = AuthService.getActiveUser();

    return user.role === "read-only";
  };

  const execChange = (value: any) => {
    if (isReadOnly()) {
      return;
    }

    onChange(value);
  };

  return (
    <div ref={ref} className="ScoreSelector">
      <input
        disabled={disabled}
        style={{
          backgroundColor: "transparent",
          cursor: noCursor ? "default" : "pointer",
        }}
        type="range"
        min="1"
        value={setValue(value)}
        max="5"
        step="1"
        list="ticks"
        onChange={(e) => execChange(getValue(e.target.value))}
      />
      <datalist id="ticks">
        <option>1</option>
        <option>2</option>
        <option>3</option>
        <option>4</option>
        <option>5</option>
      </datalist>

      <ul className="ticks">
        <li style={{ color: "#757e8e", fontWeight: 600 }}>
          Evaluative
          <br />
          <small style={{ fontSize: "80%", textTransform: "none" }}>
            Study measures response to a specific thing
          </small>
        </li>

        <li style={{ color: "#757e8e", fontWeight: 600 }}>
          Generative
          <br />
          <small style={{ fontSize: "80%", textTransform: "none" }}>
            Study measures overall sentiment
          </small>
        </li>
      </ul>
    </div>
  );
};

export { MethodologySelector };
