import React, { Component } from "react";
import { connect } from "react-redux";
import { PublicPage, PublicPageProps } from "comps/pages";
import * as DataTypes from "store/types";
import { useGoogleLogin } from "react-use-googlelogin";
import {
  signupGoogleRequest,
  loginGoogleRequest,
  initInvitedGoogleUserRequest,
} from "store/user/actions";
import { appConfig } from "config/app";
import { Helpers } from "utils";
import { AccountLoader } from "ui/loaders";

enum AuthType {
  SIGNUP = "signup",
  LOGIN = "login",
  INVITED = "invited",
}

interface IGoogleAuthProps {
  init: string | undefined;
  clientId: string;
  onSuccess(guser: any): void;
}

export const GoogleAuth = ({ clientId, init, onSuccess }: IGoogleAuthProps) => {
  const { googleUser, isSignedIn, isInitialized, signIn } = useGoogleLogin({
    clientId: clientId,
    uxMode: "redirect",
  });

  if (isInitialized === true && init === "true") {
    signIn({ prompt: "select_account" });
  }

  if (isInitialized === true && init !== "true" && isSignedIn && googleUser) {
    onSuccess(googleUser);
  }

  if (isInitialized === false) {
    return (
      <AccountLoader
        loaded={false}
        message="Please be patient while we log you in."
      >
        <div></div>
      </AccountLoader>
    );
  }
  return null;
};

interface IProps extends PublicPageProps {
  computedMatch: any;
  location: any;
  initInvitedGoogleUserRequest: (
    firstName: string,
    lastName: string,
    email: string,
    googleId: string,
    account: string,
    invite: string
  ) => void;
  signupGoogleRequest: (
    firstName: string,
    lastName: string,
    email: string,
    googleId: string
  ) => void;
  loginGoogleRequest: (slug: string, tokenId: string) => void;
}

class Auth extends Component<IProps> {
  constructor(props: IProps) {
    super(props);

    const { search } = this.props.location;
    const params = Helpers.getJsonFromUrl(search);

    if (params.slug) localStorage.setItem(appConfig.AUTH_SLUG, params.slug);
    if (params.invite) localStorage.setItem("invite", params.invite);
  }

  private loginUser = (gUser: any) => {
    const { type } = this.props.computedMatch.params;

    if (type === AuthType.INVITED) {
      const slug = localStorage.getItem(appConfig.AUTH_SLUG);
      const invite = localStorage.getItem("invite");
      this.props.initInvitedGoogleUserRequest(
        gUser.profileObj.givenName,
        gUser.profileObj.familyName,
        gUser.profileObj.email,
        gUser.profileObj.googleId,
        slug as string,
        invite as string
      );
    }

    if (type === AuthType.SIGNUP) {
      this.props.signupGoogleRequest(
        gUser.profileObj.givenName,
        gUser.profileObj.familyName,
        gUser.profileObj.email,
        gUser.profileObj.googleId
      );
    }

    if (type === AuthType.LOGIN) {
      const slug = localStorage.getItem(appConfig.AUTH_SLUG);
      if (slug) this.props.loginGoogleRequest(slug, gUser.tokenId);
    }
  };

  render() {
    const { search } = this.props.location;
    const params = Helpers.getJsonFromUrl(search);

    return (
      <div>
        <GoogleAuth
          clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID || ""}
          onSuccess={this.loginUser}
          init={params.init}
        />
      </div>
    );
  }
}

const mapStateToProps = (_: DataTypes.ApplicationState) => ({});

const mapDispatchToProps = {
  initInvitedGoogleUserRequest,
  signupGoogleRequest,
  loginGoogleRequest,
};

const connectedPage = connect(
  mapStateToProps,
  mapDispatchToProps
)(PublicPage(Auth));

export { connectedPage as Auth };
