import React, { Component } from "react";
import * as DataTypes from "store/types";
import { connect } from "react-redux";
import {} from "store/uxr/actions";
import { DropdownControl, TextInput } from "ui";
import { EditorInput } from "ui/editor_input/editor_input";
import {
  updateSupportingDataRequest,
  deleteSupportingDataRequest,
  addSupportingDataImageRequest,
  deleteSupportingDataUploadRequest,
} from "store/uxr/actions";
import { SupportingDataUploader } from "./supporting_data_uploader";
import { Select } from "comps";
import { history } from "utils";
import { showModal } from "../../../../../store/app/actions";
import * as Modals from "./modals";

interface IProps {
  showModal: (component: React.ComponentType<any>, options: any) => void;
  data: any | null;
  uxr: DataTypes.Uxr;
  currentUser: any;
  updateSupportingDataRequest(uxrId: string, dataId: string, body: any): void;
  deleteSupportingDataRequest(uxrId: string, dataId: string): void;
  addSupportingDataImageRequest(
    uxrId: string,
    dataId: string,
    file: File
  ): void;
  deleteSupportingDataUploadRequest(
    uxrId: string,
    dataId: string,
    uploadId: string
  ): void;
  selectData: any;
  type: string;
}

interface IState {
  deleteImage: DataTypes.Image | null;
}

class SupportingDataItem extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      deleteImage: null,
    };
  }

  private onChange = (key: string, value: any) => {
    this.props.updateSupportingDataRequest(
      this.props.uxr.id,
      this.props.data.id,
      { [key]: value }
    );
  };

  private typeCapital() {
    return this.props.type.charAt(0).toUpperCase() + this.props.type.slice(1);
  }

  private onDelete = () => {
    const { uxr, type } = this.props;

    this.props.showModal(
      type === "qualitative"
        ? Modals.DeleteQualitativeData
        : Modals.DeleteQuantitativeData,
      {
        uxr: uxr,
        onSubmit: () => {
          const datas = this.props.uxr.supporting_datas?.filter(
            (data) => data.type === `Uxr::SupportingData::${this.typeCapital()}`
          );

          let next = datas[0];
          if (next?.id === this.props.data.id && datas.length > 1) {
            next = datas[1];
          }

          this.props.selectData(next);
          this.props.deleteSupportingDataRequest(
            this.props.uxr.id,
            this.props.data.id
          );
        },
      }
    );
  };

  private onDeleteImage = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    image: DataTypes.Image
  ) => {
    event.stopPropagation();

    this.props.deleteSupportingDataUploadRequest(
      this.props.uxr.id,
      this.props.data.id,
      image.id || ""
    );

    this.setState({ ...this.state, deleteImage: null });
  };

  private onViewImage = (image: DataTypes.Image) => {
    history.push(
      `/uxr/${this.props.uxr.id}/supporting_data/${this.props.data.id}/images/${image.id}`
    );
  };

  private onImageDrop = (files: any) => {
    if (!this.props.uxr || !files || files.length === 0) {
      return;
    }

    const { id: uxrId } = this.props.uxr;

    files.forEach((file: any) => {
      this.props.addSupportingDataImageRequest(uxrId, this.props.data.id, file);
    });
  };

  private onToggleDelete = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    image: DataTypes.Image | null
  ) => {
    event.stopPropagation();
    this.setState({ ...this.state, deleteImage: image });
  };

  onAreaOfFocusChange = (e: any, idx?: number | undefined) => {
    const areaOfFocus = this.props.uxr.area_of_focus[idx || 0];

    this.props.updateSupportingDataRequest(
      this.props.uxr.id,
      this.props.data.id,
      { uxr_area_of_focus_id: areaOfFocus.id }
    );
  };

  render() {
    const { data } = this.props;

    if (!data) {
      return null;
    }

    return (
      <React.Fragment>
        <div className="Experience">
          <div className="header" style={{ height: "auto" }}>
            <div className="form-group w--full">
              <label style={{ width: "230px" }}>Summarized Data Point:</label>
              <TextInput
                value={data.sumarized_data}
                onChange={(value) => this.onChange("sumarized_data", value)}
              >
                <p style={{ display: "table-cell" }}>{data.sumarized_data}</p>
              </TextInput>
            </div>

            <div className="controls">
              <DropdownControl
                controlItems={[
                  {
                    name: "Delete Data",
                    icon: "far fa-trash-alt",
                    onClick: this.onDelete,
                    splitAbove: true,
                    className: "danger",
                  },
                ]}
              />
            </div>
          </div>

          <div className="form-group">
            <label className="normal">Explanation</label>
            <EditorInput
              content={data.explanation}
              onBlur={(e: any) => this.onChange("explanation", e)}
              area={true}
            />
          </div>

          <div className="form-group">
            <label className="normal">Area of Focus</label>

            <Select
              key="counting_methodology"
              className="AdobeMetric SelectInput"
              placeholder="-- Select Area of Focus --"
              field={{
                name: "area_of_focus",
                value: data.area_of_focus?.theme || "",
              }}
              options={this.props.uxr.area_of_focus.map(
                (area) => area.theme || "New area of focus"
              )}
              onChange={this.onAreaOfFocusChange}
            />
          </div>
          <hr />

          <section className="Targeting">
            <SupportingDataUploader
              currentUser={this.props.currentUser}
              deleteImage={this.state.deleteImage}
              onDeleteImage={this.onDeleteImage}
              onImageDrop={this.onImageDrop}
              onToggleDelete={this.onToggleDelete}
              onView={this.onViewImage}
              data={this.props.data}
            />
          </section>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = ({ router }: DataTypes.ApplicationState) => ({
  router,
});

const mapDispatchToProps = {
  updateSupportingDataRequest,
  deleteSupportingDataRequest,
  addSupportingDataImageRequest,
  deleteSupportingDataUploadRequest,
  showModal,
};

const connectedPage = connect(
  mapStateToProps,
  mapDispatchToProps
)(SupportingDataItem);

export { connectedPage as SupportingDataItem };
