import React from "react";
import { Provider } from "react-redux";
import { Store } from "redux";
import { History } from "history";
import Routes from "./config/routes";
import { ApplicationState } from "./store/types";
import { subDomainRedirect } from "./config/app";
import * as AppUtils from "utils/app";
import { ReactKeycloakProvider } from "@react-keycloak/web";
import "./css/Base.scss";
import keycloak from "./keycloak";

interface IProps {
  store: Store<ApplicationState>;
  history: History;
}

const eventLogger = async (event: unknown, error: unknown) => {};

const tokenLogger = (tokens: any) => {
  if (!tokens) {
    return;
  }

  localStorage.setItem("user_token", tokens.token);
};

export const Main = ({ store, history }: IProps) => {
  const subDomain = AppUtils.App.getSubDomain();

  // redirect existing subdomains
  if (subDomain && subDomainRedirect[subDomain]) {
    const redirectURL =
      window.location.protocol +
      "//" +
      window.location.host.replace(subDomain, subDomainRedirect[subDomain]) +
      window.location.pathname +
      window.location.search;

    document.location.href = redirectURL;
  }

  return (
    <ReactKeycloakProvider
      authClient={keycloak}
      onEvent={eventLogger}
      onTokens={tokenLogger}
    >
      <Provider store={store}>
        <Routes history={history} />
      </Provider>
    </ReactKeycloakProvider>
  );
};
