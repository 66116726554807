import { action } from 'typesafe-actions';
import { Dispatch } from 'redux';
import { ActionTypes } from './types';
import * as DataTypes from '../types';

interface RequestError {
  statusCode?: number | undefined;
  message: any;
}

export const getIdeasTraitRequestSaga = () => action(ActionTypes.GET_IDEAS_TRAITS_REQUEST, {});
export const getIdeasTraitsRequest = () => (dispatch: Dispatch) =>
  dispatch(action(ActionTypes.GET_IDEAS_TRAITS_REQUEST));
export const getIdeasTraitsSuccess = (response: DataTypes.ApiRequest<DataTypes.Trait>) =>
  action(ActionTypes.GET_IDEAS_TRAITS_SUCCESS, { response });
export const getIdeasTraitsError = (error: RequestError) => action(ActionTypes.GET_IDEAS_TRAITS_FAILURE, error);

export const getShareIdeasTraitsRequest = (slug: string) => (dispatch: Dispatch) =>
  dispatch(action(ActionTypes.GET_SHARE_IDEAS_TRAITS_REQUEST, { slug }));
export const getShareIdeasTraitsSuccess = (response: DataTypes.ApiRequest<DataTypes.Trait>) =>
  action(ActionTypes.GET_SHARE_IDEAS_TRAITS_SUCCESS, { response });
export const getShareIdeasTraitsError = (error: RequestError) => action(ActionTypes.GET_SHARE_IDEAS_TRAITS_FAILURE, error);

export const createIdeasTraitsRequest = (insightId: string, traitName: string, values: string[]) => (dispatch: Dispatch) =>
  dispatch(action(ActionTypes.CREATE_IDEAS_TRAITS_REQUEST, { insightId, traitName, values }));
export const createIdeasTraitsSuccess = (response: DataTypes.ApiList<DataTypes.Insight>) =>
  action(ActionTypes.CREATE_IDEAS_TRAITS_SUCCESS, { response });
export const createIdeasTraitsError = (error: RequestError) =>
  action(ActionTypes.CREATE_IDEAS_TRAITS_FAILURE, error);