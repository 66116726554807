import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import * as API from "../../api";
import * as Actions from "./actions";
import { ActionTypes } from "./types";
import * as AccountActions from "../account/actions";
import * as AppTypes from "../app/types";
import { history } from "utils";

function* initIdeaBoardManagementCreate(action: any) {
  try {
    yield put(Actions.initIdeaBoardManagementCreateSuccess());
  } catch (error: any) {
    yield put(Actions.initIdeaBoardManagementCreateError(error));
  }
}

function* showIdeaBoard(action: any) {
  try {
    // @ts-ignore
    const res = yield call(API.IdeaBoards.show, action.payload.board_id);
    yield put(Actions.showIdeaBoardsSuccess(res.body));
  } catch (error: any) {
    yield put(Actions.showIdeaBoardsError(error));
  }
}

function* createIdeaBoardManagement(action: any) {
  try {
    // @ts-ignore
    const res = yield call(API.IdeaBoards.create, action.payload.body);
    yield put(Actions.createIdeaBoardManagementSuccess(res));
    yield put(AccountActions.getAccountSummaryRequest());

    history.push("/ideas/boards");
    yield put({
      type: AppTypes.App.ALERT_SHOW,
      content: "Idea Board created successfully",
    });
  } catch (error: any) {
    yield put(Actions.createIdeaBoardManagementError(error));
  }
}

function* updateIdeaBoardManagement(action: any) {
  try {
    // @ts-ignore
    const res = yield call(
      API.IdeaBoards.update,
      action.payload.id,
      action.payload.body
    );
    yield put(Actions.updateIdeaBoardManagementSuccess(res.body));
    yield put(AccountActions.getAccountTraitsRequestSaga());
    yield put({
      type: AppTypes.App.ALERT_SHOW,
      content: "Idea Board updated successfully",
    });
  } catch (error: any) {
    yield put(Actions.updateIdeaBoardManagementError(error));
  }
}

function* archiveIdeaBoardManagement(action: any) {
  try {
    // @ts-ignore
    const res = yield call(
      API.IdeaBoards.update,
      action.payload.id,
      action.payload.body
    );

    yield put(AccountActions.getAccountTraitsRequestSaga());
    yield put(Actions.archiveIdeaBoardManagementSuccess(res.body));
    yield put({ type: AppTypes.App.MODAL_HIDE });
    yield put({
      type: AppTypes.App.ALERT_SHOW,
      content: "Idea Board updated successfully",
    });
  } catch (error: any) {
    yield put(Actions.archiveIdeaBoardManagementError(error));
  }
}

function* getIdeaBoardsManagement(action: any) {
  try {
    // @ts-ignore
    const res = yield call(API.IdeaBoards.index, action.payload.options);
    const append = action.payload.scrollOpts?.append;
    yield put(Actions.getIdeaBoardsManagementSuccess(res.body, append));
  } catch (error: any) {
    yield put(Actions.getIdeaBoardsManagementError(error));
  }
}

function* deleteIdeaBoardManagement(action: any) {
  try {
    yield put({ type: AppTypes.App.MODAL_SAVING });
    // @ts-ignore
    const res = yield call(API.IdeaBoards.destroy, action.payload.id);

    yield put(Actions.deleteIdeaBoardsManagementSuccess(res.body));
    yield put({ type: AppTypes.App.MODAL_HIDE });
    yield put({
      type: AppTypes.App.ALERT_SHOW,
      content: "Idea board deleted successfully",
    });
    yield put(AccountActions.getAccountTraitsRequestSaga());
    yield put(AccountActions.getAccountSummaryRequest());
  } catch (error: any) {
    yield put(Actions.deleteIdeaBoardsManagementError(error));
  }
}

function* reorderIdeaBoard(action: any) {
  try {
    const { id, position, currentOrder } = action.payload;

    // @ts-ignore
    const res = yield call(API.IdeaBoards.reorder, id, position + 1);
    yield put(Actions.reorderIdeaBoardSuccess(res.body, currentOrder));
  } catch (error: any) {
    yield put(Actions.reorderIdeaBoardError(error));
  }
}

function* watchInitIdeaBoardManagementCreate() {
  yield takeEvery(
    ActionTypes.INIT_IDEA_BOARD_MANAGEMENT_CREATE_REQUEST,
    initIdeaBoardManagementCreate
  );
}

function* watchShowIdeaBoard() {
  yield takeEvery(ActionTypes.SHOW_IDEA_BOARDS_REQUEST, showIdeaBoard);
}

function* watchIdeaBoardManagementCreate() {
  yield takeEvery(
    ActionTypes.CREATE_IDEA_BOARD_MANAGEMENT_REQUEST,
    createIdeaBoardManagement
  );
}

function* watchGetIdeaBoardManagement() {
  yield takeEvery(
    ActionTypes.GET_IDEA_BOARDS_MANAGEMENT_REQUEST,
    getIdeaBoardsManagement
  );
}

function* watchDeleteIdeaBoardManagement() {
  yield takeEvery(
    ActionTypes.DELETE_IDEA_BOARD_MANAGEMENT_REQUEST,
    deleteIdeaBoardManagement
  );
}

function* watchUpdateIdeaBoardManagement() {
  yield takeEvery(
    ActionTypes.UPDATE_IDEA_BOARD_MANAGEMENT_REQUEST,
    updateIdeaBoardManagement
  );
}

function* watchArchiveIdeaBoardManagement() {
  yield takeEvery(
    ActionTypes.ARCHIVE_IDEA_BOARD_MANAGEMENT_REQUEST,
    archiveIdeaBoardManagement
  );
}

function* watchReorderIdeaBoardBoard() {
  yield takeEvery(ActionTypes.REORDER_REQUEST, reorderIdeaBoard);
}

function* ideaBoardManagementSaga() {
  yield all([
    fork(watchIdeaBoardManagementCreate),
    fork(watchShowIdeaBoard),
    fork(watchInitIdeaBoardManagementCreate),
    fork(watchGetIdeaBoardManagement),
    fork(watchDeleteIdeaBoardManagement),
    fork(watchUpdateIdeaBoardManagement),
    fork(watchArchiveIdeaBoardManagement),
    fork(watchReorderIdeaBoardBoard),
  ]);
}

export default ideaBoardManagementSaga;
