import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import * as API from "../../api";
import * as Actions from "./actions";
import { ActionTypes } from "./types";
import * as IdeaActions from "../idea/actions";

function* getIdeasTraits(action: any) {
  try {
    // @ts-ignore
    const res = yield call(API.Ideas.traits);

    yield put(Actions.getIdeasTraitsSuccess(res.body));
  } catch (error: any) {
    yield put(Actions.getIdeasTraitsError(error));
  }
}

function* getShareIdeasTraits(action: any) {
  try {
    // @ts-ignore
    const res = yield call(API.Ideas.shareTraits, action.payload.slug);

    yield put(Actions.getShareIdeasTraitsSuccess(res.body));
  } catch (error: any) {
    yield put(Actions.getShareIdeasTraitsError(error));
  }
}

function* createIdeasTraits(action: any) {
  try {
    const { insightId, traitName, values } = action.payload;

    // @ts-ignore
    const res = yield call(API.Ideas.addTraits, insightId, traitName, values);

    yield put(Actions.createIdeasTraitsSuccess(res.body));
    yield put(Actions.getIdeasTraitRequestSaga());
    yield put(IdeaActions.updateIdeaSuccess(res.body));
  } catch (error: any) {
    yield put(Actions.getIdeasTraitsError(error));
  }
}

function* watchGetShareIdeasTraits() {
  yield takeEvery(
    ActionTypes.GET_SHARE_IDEAS_TRAITS_REQUEST,
    getShareIdeasTraits
  );
}

function* watchGetIdeasTraits() {
  yield takeEvery(ActionTypes.GET_IDEAS_TRAITS_REQUEST, getIdeasTraits);
}

function* watchCreateIdeasTraits() {
  yield takeEvery(ActionTypes.CREATE_IDEAS_TRAITS_REQUEST, createIdeasTraits);
}

function* ideasTraitsSaga() {
  yield all([
    fork(watchGetIdeasTraits),
    fork(watchGetShareIdeasTraits),
    fork(watchCreateIdeasTraits),
  ]);
}

export default ideasTraitsSaga;
