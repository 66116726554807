import React, { Component } from 'react';
import { connect } from 'react-redux';
import Highlighter from 'react-highlight-words';
import { ApplicationState } from 'store/types';
import { createLoadingSelector } from 'store/selectors';
import { getInsightsRequest, clearInsights } from 'store/insight/actions';
import * as DataTypes from 'store/types';
import { SearchBar, Avatar } from 'ui';

interface IProps {
  saving: boolean;
  collections: any;
  insightsLoading: boolean;
  clearInsights: () => void;
  getInsightsRequest: (options: DataTypes.ApiListOptions, scrollOpts: { append: boolean }) => void;
  onSubmit: (insight: Array<DataTypes.Insight>) => void;
  onCreateNewInsight: () => void;
}

interface IState {
  search: string;
  selectedInsights: Array<DataTypes.Insight>;
  linkEnabled: boolean;
}

class LinkInsights extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      search: '',
      selectedInsights: [],
      linkEnabled: false,
    };
  }

  componentWillMount = () => {
    this.props.clearInsights();
  };

  private onSubmit = (value: string) => {
    const values = value.split(',');
    const q = values.map((v) => {
      return 'value=' + v.trim();
    });

    this.props.getInsightsRequest({ q: q }, { append: false });
  };

  private onCheck = (checked: boolean, insight: DataTypes.Insight) => {
    let selectedInsights = this.state.selectedInsights;
    if (checked === true) {
      selectedInsights.push(insight);
      this.setState({ ...this.state, selectedInsights: selectedInsights, linkEnabled: true });
    } else {
      const iIdx = selectedInsights.findIndex((i) => i.id === insight.id);
      selectedInsights.splice(iIdx, 1);
      this.setState({ ...this.state, selectedInsights: selectedInsights, linkEnabled: selectedInsights.length > 0 ? true : false });
    }
  };

  private onLinkInsights = () => {
    this.props.onSubmit(this.state.selectedInsights);
  };

  render() {
    const { saving, insightsLoading, collections } = this.props;
    const { linkEnabled } = this.state;

    return (
      <React.Fragment>
        <div className="LinkInsights">
          <div className="header">
            <h3>Link Insights</h3>
            <p>
              Use the form below to search for existing insights to attach to this Research. Use comma seperated keywords to help you find previous insights.
            </p>
            <div className="form-group">
              <SearchBar
                onChange={(search) => this.setState({ search })}
                value={this.state.search}
                placeholder="Search for insights using comma seperated keywords"
                onSubmit={this.onSubmit}
              />
            </div>
          </div>

          {insightsLoading === true && <div>Loading</div>}
          {insightsLoading === false && collections.insights && collections.insights.data && collections.insights.data.length > 0 && (
            <div className="body">
              {collections.insights.data.map((insight: DataTypes.Insight) => {
                return (
                  <div key={`insight_${insight.id}`} className="InsightSelector">
                    <div className="selector">
                      <label className="CheckBox">
                        <input type="checkbox" defaultChecked={false} onChange={(e) => this.onCheck(e.target.checked, insight)} />
                        <span className="checkmark"></span>
                      </label>
                    </div>
                    <div className="insight">
                      <p>
                        <Highlighter
                          highlightClassName="highlight"
                          searchWords={this.state.search.split(',')}
                          autoEscape={true}
                          textToHighlight={insight.value}
                        />
                      </p>
                    </div>
                    <div className="avatar">
                      <div className="avatar-stack">
                        {insight.contributors.reverse().map((user, idx) => {
                          return (
                            <div style={{ position: 'relative', left: `${Math.abs(8 * idx)}px` }} key={`user_${user.id}`}>
                              <Avatar user={user} />
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          )}
          <div className="cntrl-bar">
            {saving === true ? (
              <button className="btn btn-primary" type="button" disabled={true}>
                <i className="fas fa-circle-notch fa-spin" />
              </button>
            ) : (
              <button className="btn btn-primary" type="button" disabled={!linkEnabled} onClick={this.onLinkInsights}>
                <i className="fa fa-link" />
                Link Insights to Research
              </button>
            )}

            <button className="ml-auto btn btn-link" type="button" onClick={this.props.onCreateNewInsight}>
              Create new insight
            </button>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const insightLoadingSelector = createLoadingSelector(['@@insight/GET_INSIGHTS']);

const mapStateToProps = ({ app, collections }: ApplicationState) => ({
  insightsLoading: insightLoadingSelector(app.requests),
  collections: collections,
});

const mapDispatchToProps = {
  getInsightsRequest,
  clearInsights,
};

const connectedModal = connect(mapStateToProps, mapDispatchToProps)(LinkInsights);

export { connectedModal as LinkInsights };
