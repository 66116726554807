import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import * as API from "../../api";
import * as Actions from "./actions";
import { ActionTypes } from "./types";
import * as AppTypes from "../app/types";
import { history } from "utils";

function* createWorkSheet(action: any) {
  try {
    const { board } = action.payload;
    // @ts-ignore
    const res = yield call(API.Worksheets.create, board.id, {
      worksheet: { title: board.name },
    });
    history.push(`/ideas/board/${board.id}/worksheet`);
    yield put(Actions.createWorksheetSuccess(res.body));
  } catch (error: any) {
    yield put(Actions.createWorksheetError(error));
  }
}

function* updateWorkSheet(action: any) {
  try {
    // @ts-ignore
    const res = yield call(
      API.Worksheets.update,
      action.payload.board_id,
      action.payload.body
    );
    yield put(Actions.updateWorksheetSuccess(res.body));
    yield put({
      type: AppTypes.App.ALERT_SHOW,
      content: "Worksheet updated successfully",
    });
  } catch (error: any) {
    yield put(Actions.updateWorksheetError(error));
  }
}

function* getWorksheet(action: any) {
  try {
    yield put(Actions.resetWorksheet());
    // @ts-ignore
    const res = yield call(API.Worksheets.show, action.payload.board_id);
    yield put(Actions.getWorksheetSuccess(res.body));
  } catch (error: any) {
    yield put(Actions.getWorksheetError(error));
  }
}

function* getShareWorksheet(action: any) {
  try {
    // @ts-ignore
    let res = yield call(
      API.Share.worksheet,
      action.payload.slug,
      action.payload.uuid,
      action.payload.password
    );
    yield put(Actions.getShareWorksheetSuccess(res.body));
  } catch (error: any) {
    yield put(Actions.getShareWorksheetError(error));
  }
}

function* channelUpdateWorksheet(action: any) {
  try {
    const content = action.payload.content;
    yield put(Actions.channelUpdateWorksheetSuccess(content));
  } catch (error: any) {
    yield put(Actions.channelUpdateWorksheetError(error));
  }
}

function* notifyEditing(action: any) {
  try {
    const { ideaBoard } = action.payload;

    yield call(API.Worksheets.notifyEditing, ideaBoard.id);
    yield put(Actions.notifyEditingSuccess());
  } catch (error: any) {
    yield put(Actions.notifyEditingError(error));
  }
}

function* watchCreateWorksheet() {
  yield takeEvery(ActionTypes.CREATE_WORKSHEET_REQUEST, createWorkSheet);
}

function* watchUpdateWorksheet() {
  yield takeEvery(ActionTypes.UPDATE_WORKSHEET_REQUEST, updateWorkSheet);
}

function* watchGetWorksheet() {
  yield takeEvery(ActionTypes.GET_WORKSHEET_REQUEST, getWorksheet);
}

function* watchGetShareWorksheet() {
  yield takeEvery(ActionTypes.GET_SHARE_WORKSHEET_REQUEST, getShareWorksheet);
}

function* watchChannelUpdateWorksheet() {
  yield takeEvery(
    ActionTypes.CHANNEL_UPDATE_WORKSHEET_REQUEST,
    channelUpdateWorksheet
  );
}

function* watchNotifyEditing() {
  yield takeEvery(ActionTypes.NOTIFY_EDITING_REQUEST, notifyEditing);
}

function* worksheetSaga() {
  yield all([
    fork(watchCreateWorksheet),
    fork(watchGetWorksheet),
    fork(watchUpdateWorksheet),
    fork(watchGetShareWorksheet),
    fork(watchChannelUpdateWorksheet),
    fork(watchNotifyEditing),
  ]);
}

export default worksheetSaga;
