import React from "react";
import * as DataTypes from "store/types";
import styled from "styled-components";
import { history } from "utils";

const getIconAndButton = (task: DataTypes.Task) => {
  if (task.action === "invites")
    return {
      icon: "far fa-envelope-open",
      buttonText: "Invite Users",
      onClick: TaskActions.InviteUsers,
    };
  if (task.action === "status")
    return { icon: "fa fa-trophy", buttonText: "Update Win Status" };
  if (task.action === "case_study")
    return { icon: "fa fa-share-alt", buttonText: "Create Case Study" };
  if (task.action === "integrations")
    return {
      icon: "fa fa-wrench",
      buttonText: "Setup Test Tool",
      onClick: TaskActions.Integrations,
    };
  if (task.action === "import")
    return {
      icon: "fab fa-adobe",
      buttonText: "Yes",
      buttonClass: "half",
      onClick: TaskActions.Import,
    };
  return { icon: "fa fa-multiply", buttonText: "Unknown Action" };
};

interface IProps {
  tasks: Array<DataTypes.Task>;
  onDelete(task: DataTypes.Task): void;
}

const TaskList = ({ tasks, onDelete }: IProps) => {
  if (tasks.length === 0) {
    return (
      <StyledTask>
        <div className="message">You currently don't have any tasks.</div>
      </StyledTask>
    );
  }

  return (
    <div>
      {tasks.map((task: DataTypes.Task) => {
        const { icon, buttonText, buttonClass, onClick } =
          getIconAndButton(task);
        return (
          <StyledTask key={task.id}>
            <div className="icon">
              <i className={icon}></i>
            </div>
            <div className="message">{task.message}</div>
            <div className="button">
              {onClick && (
                <button
                  className={`btn btn-default ${buttonClass || ""}`}
                  onClick={() => onClick(task)}
                >
                  {buttonText}
                </button>
              )}
              {task.action === "import" || task.action === "integrations" ? (
                <button
                  className="btn btn-default second half"
                  type="button"
                  onClick={() => onDelete(task)}
                >
                  No
                </button>
              ) : null}
            </div>
          </StyledTask>
        );
      })}
    </div>
  );
};

export { TaskList };

class TaskActions {
  static InviteUsers = (task: DataTypes.Task) =>
    history.push("/settings/invites");
  static Integrations = (task: DataTypes.Task) =>
    history.push("/resources/integrations");
  static Import = (task: DataTypes.Task) =>
    history.push(
      `/tests/create?mode=import&tool=${task.data.type}&tool_id=${task.data.id}`
    );
}

const StyledTask = styled.div`
  background: white;
  border: 2px solid #eaeaea;
  padding: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  border-radius: 4px;

  .icon {
    flex-basis: 28px;
    font-size: 28px;
    text-align: center;
    color: grey;
    .fa-adobe {
      color: #cd252b;
    }
    .fa-trophy {
      color: #e79d00;
    }
    .fa-envelope-open {
      color: #007cef;
    }
  }

  .message {
    flex-basis: 70%;
    font-size: 14px;
    color: #757e8e;
  }

  .button {
    display: flex;
    justify-content: flex-end;
    flex-basis: 22%;
    padding-left: 10px;

    button {
      flex-basis: 100%;
      border: 2px solid #eaeaea;
      color: grey;
      height: 30px;
      margin: 0;
      vertical-align: top;
      display: flex;
      padding: 0px 8px;
      align-items: center;
      justify-content: center;
      font-size: 14px;
      color: #757e8e;
      white-space: nowrap;

      &:hover {
        border: 2px solid #2a88f2;
        color: #2a88f2;
      }
    }
    .second {
      margin-left: 10px;
    }
    .half {
      flex-basis: 40% !important;
    }
  }
`;
