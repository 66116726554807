import React, { Component, useCallback } from "react";
import { connect } from "react-redux";
import {
  Form,
  Input,
  Button,
  Checkbox,
  ValidatorTypes,
  InputTypes,
} from "comps/form";
import { PublicPage } from "comps/pages";
import * as DataTypes from "store/types";
import { createInvitedUserRequest } from "store/user/actions";
import {
  createLoadingSelector,
  createErrorMessageSelector,
} from "store/selectors";
import { AccountLoader } from "ui/loaders";
import { Spacer } from "comps/layout";
import { User } from "api/user";
import { history } from "utils";
import { AuthService } from "utils/auth";
import { useKeycloak } from "@react-keycloak/web";
import * as AppUtils from "utils/app";

interface IProps {
  createInvitedUserRequest: (id: string, body: any) => void;
  waitingOnInvitedUser: boolean;
  createAccountError: string;
  user: any;
  computedMatch: { params: any };
  loading: boolean;
}

interface IState {
  error?: string;
  token?: string;
  email?: string;
  role?: string;
  loading: boolean;
  sender?: DataTypes.User;
  invalidPassword: boolean;
}

type SignupRedirectProps = {
  token: string;
};
const GoogleSignupButton: React.FC<SignupRedirectProps> = ({ token }) => {
  const { keycloak } = useKeycloak();

  const goToLogin = useCallback(() => {
    const subDomain = AppUtils.App.getSubDomain();

    keycloak.login({
      redirectUri: `${process.env.REACT_APP_HTTP_PROTO}://${subDomain}.${process.env.REACT_APP_BASE_URL}/invite/social/success/${token}`,
      idpHint: "google",
    });
  }, []);

  return (
    <button className="btn btn-google" type="button" onClick={goToLogin}>
      use your google account
    </button>
  );
};

class InvitedUserForm extends Component<IProps, IState> {
  state: IState = { loading: true, invalidPassword: false };

  async componentDidMount() {
    const { token } = this.props.computedMatch.params;
    try {
      const response: any = await User.getInvitedUserToken(token);

      if (response.body.email)
        return this.setState(
          {
            email: response.body.email,
            sender: response.body.sender,
            loading: false,
            role: response.body.role,
            token,
          },
          () => {
            AuthService.logOut();
          }
        );
      // in the case of a 404 this never gets called
      throw Error("Not a valid invite link.");
    } catch (error: any) {
      this.setState({ error, loading: false });
    }
  }

  private onSubmit = (valid: boolean, dirty: boolean, data: any) => {
    if (valid && dirty) {
      const values: any = {};
      Object.keys(data).map(
        (field: string) => (values[field] = data[field].value)
      );

      const { token } = this.props.computedMatch.params;
      const { firstName, lastName, password, confirmPassword, newsletter } =
        values;
      if (password !== confirmPassword)
        return this.setState({ invalidPassword: true });
      const transform: any = {
        first_name: firstName,
        last_name: lastName,
        password,
        role: this.state.role,
        type: "standard",
        notifications: {
          newsletter,
        },
      };
      this.props.createInvitedUserRequest(token, transform);
    }
  };

  termsOfService = () => (
    <>
      <>I agree to and accept the </>
      <a href="/terms" target="_blank">
        Terms of Service
      </a>{" "}
      and{" "}
      <a href="/privacy_policy" target="_blank">
        Privacy Policy
      </a>{" "}
      for illuminate.
    </>
  );

  render() {
    if (this.state.error) throw this.state.error;

    if (this.props.user && this.props.user.jwt) history.push("/");

    if (!this.state.sender) return null;

    return (
      <AccountLoader
        loaded={!this.state.loading}
        message="Please wait while we verify your signup link"
      >
        <React.Fragment>
          <div className="content">
            <div className="logo">
              <img src="/img/logo.png" alt="illuminate" />
            </div>
            <div className="form">
              <h1>Welcome to illuminate</h1>
              <p className="mb-1">
                You were invited to join this account by{" "}
                <strong>
                  {this.state.sender.first_name} {this.state.sender.last_name}
                </strong>
                .
              </p>
              <p>
                We'll just need a few more details and you'll be ready to go.
              </p>
              <Spacer size={2} />
              <React.Fragment>
                {this.state.token ? (
                  <GoogleSignupButton token={this.state.token} />
                ) : null}
                <div className="or-splitter"></div>
              </React.Fragment>
              <Form
                formFields={{
                  firstName: {
                    name: "firstName",
                    value: "",
                    validator: {
                      type: ValidatorTypes.REQUIRED,
                      messages: { required: "Please enter your first name" },
                    },
                  },
                  lastName: {
                    name: "lastName",
                    value: "",
                    validator: {
                      type: ValidatorTypes.REQUIRED,
                      messages: { required: "Please enter your last name" },
                    },
                  },
                  password: {
                    name: "password",
                    value: "",
                    validator: {
                      type: ValidatorTypes.PASSWORD,
                      messages: {
                        type: "Please enter a password with at least 8 characters",
                      },
                    },
                  },
                  confirmPassword: {
                    name: "confirmPassword",
                    value: "",
                    validator: {
                      type: ValidatorTypes.PASSWORD,
                      messages: {
                        type: "Please enter a password with at least 8 characters",
                      },
                    },
                  },
                  tos: {
                    name: "tos",
                    value: false,
                    validator: {
                      type: ValidatorTypes.CHECKBOX_REQUIRED,
                      messages: {
                        required:
                          "You must agree to and accept the illuminate Terms of Service and Privacy Policy",
                      },
                    },
                  },
                  newsletter: {
                    name: "newsletter",
                    value: false,
                  },
                }}
                onSubmit={this.onSubmit}
                FormComponent={({
                  fields: {
                    firstName,
                    lastName,
                    password,
                    confirmPassword,
                    newsletter,
                    tos,
                  },
                  onChange,
                }) => (
                  <div>
                    <div className="form-group row">
                      <div className="col-md-6">
                        <label>First Name</label>
                        <Input
                          field={firstName}
                          className="form-control"
                          onChange={(e) => onChange(firstName, e.target.value)}
                        />
                      </div>
                      <div className="col-md-6">
                        <label>Last Name</label>
                        <Input
                          field={lastName}
                          className="form-control"
                          onChange={(e) => onChange(lastName, e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="form-group">
                      <label>Password</label>
                      <Input
                        field={password}
                        className="form-control"
                        type={InputTypes.PASSWORD}
                        onChange={(e) => onChange(password, e.target.value)}
                      />
                      <p className="desc">Must contain at least 8 characters</p>
                    </div>
                    <div className="form-group">
                      <label>Confirm Password</label>
                      <Input
                        field={confirmPassword}
                        className="form-control"
                        type={InputTypes.PASSWORD}
                        onChange={(e) =>
                          onChange(confirmPassword, e.target.value)
                        }
                      />
                      {this.state.invalidPassword ? (
                        <p className="error">Passwords do not match.</p>
                      ) : (
                        <p className="desc">
                          Please ensure your password was entered correctly
                        </p>
                      )}
                    </div>
                    <div className="form-group">
                      <Checkbox
                        TextComponent={this.termsOfService}
                        field={tos}
                        className="form-control"
                        onChange={(e) => onChange(tos, e.target.checked)}
                      />
                    </div>
                    <div className="form-group">
                      <Checkbox
                        text="Yes, send me information about Brooks Bell &amp; illuminate"
                        field={newsletter}
                        className="form-control"
                        onChange={(e) => onChange(newsletter, e.target.checked)}
                      />
                    </div>
                    <Button
                      className="btn btn-primary"
                      text="continue"
                      isLoading={false}
                    />
                    <Spacer size={4} />
                  </div>
                )}
              />
            </div>
          </div>
          <div className="aside"></div>
        </React.Fragment>
      </AccountLoader>
    );
  }
}

const loadingSelector = createLoadingSelector(["@@user/CREATE_INVITED_USER"]);
const errorSelector = createErrorMessageSelector([
  "@@user/CREATE_INVITED_USER",
]);

const mapStateToProps = ({ app, user }: DataTypes.ApplicationState) => ({
  waitingOnInvitedUser: loadingSelector(app.requests),
  createInvitedUserError: errorSelector(app.errors),
  user,
});

const mapDispatchToProps = {
  createInvitedUserRequest,
};

const connectedPage = connect(
  mapStateToProps,
  mapDispatchToProps
)(PublicPage(InvitedUserForm));

export { connectedPage as InvitedUserForm };
