import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Form,
  Input,
  Button,
  ValidatorTypes,
  InputTypes,
  Checkbox,
} from "comps/form";
import { PublicPage, PublicPageProps } from "comps/pages";
import * as DataTypes from "store/types";
import { createAccountRequest } from "store/account/actions";
import {
  createLoadingSelector,
  createErrorMessageSelector,
} from "store/selectors";
import { SignUp } from "../../../api";
import { AccountLoader } from "ui/loaders";
import { AsideImage } from "ui";
import { HTTPCodes } from "config/app";

enum SignUpType {
  EMAIL = "email",
  GOOGLE = "google",
}

enum UserType {
  STANDARD = "standard",
  GOOGLE = "google",
}

interface IProps extends PublicPageProps {
  createAccountRequest: (body: DataTypes.AccountRequest) => void;
  formLoading: boolean;
  formError: any;
  user: any;
  computedMatch: { params: any };
}

interface IState {
  error?: string;
  type?: string;
  firstName?: string;
  lastName?: string;
  googleId?: string;
  email?: string;
  loading: boolean;
  slugError: boolean;
}

class CreateAccount extends Component<IProps, IState> {
  state: IState = { loading: true, slugError: false };

  async componentDidMount() {
    const { token } = this.props.computedMatch.params;
    if (token === "google") {
      this.setState({
        loading: false,
        type: SignUpType.GOOGLE,
      });
      return;
    }

    try {
      const response: any = await SignUp.getSignupToken(token);
      const { type, first_name, last_name, email, google_id } = response.body;
      this.setState({
        type: type,
        email: email,
        firstName: first_name,
        lastName: last_name,
        googleId: google_id,
        loading: false,
      });
    } catch (error: any) {
      this.setState({ error: error.message, loading: false });
    }
  }

  componentDidUpdate = (prevProps: IProps) => {
    const { formError } = this.props;
    if (prevProps.formError !== formError) {
      if (
        formError &&
        formError.statusCode === HTTPCodes.UNPROCESSABLE_ENTITY
      ) {
        const { errors } = formError.body;

        if (
          errors.params &&
          errors.params.lastIndexOf("Slug has already been taken") !== -1
        ) {
          this.setState({ ...this.state, slugError: true });
        }
      }
    }
  };

  termsOfService = () => (
    <>
      <>I agree to and accept the </>
      <a href="/terms" target="_blank">
        Terms of Service
      </a>{" "}
      and{" "}
      <a href="/privacy_policy" target="_blank">
        Privacy Policy
      </a>{" "}
      for illuminate.
    </>
  );

  private onSubmit = (valid: boolean, dirty: boolean, data: any) => {
    const { type, firstName, lastName, email, googleId } = this.state;
    if (!valid || !dirty) {
      return;
    }

    this.setState({ ...this.state, slugError: false });

    if (type === SignUpType.EMAIL) {
      this.props.createAccountRequest({
        name: data.companyName.value,
        slug: data.workspaceURL.value,
        tos: data.tos.value,
        user: {
          notification_settings: {
            newsletter: data.newsletter.value,
          },
          first_name: data.firstName.value,
          last_name: data.lastName.value,
          email: email || "",
          password: data.password.value,
          type: UserType.STANDARD,
        },
      });
    }

    if (type === SignUpType.GOOGLE) {
      this.props.createAccountRequest({
        name: data.companyName.value,
        slug: data.workspaceURL.value,
        tos: data.tos.value,
        user: {
          notification_settings: {
            newsletter: data.newsletter.value,
          },
          first_name: firstName || "",
          last_name: lastName || "",
          email: email || "",
          google_id: googleId,
          type: UserType.GOOGLE,
        },
      });
    }
  };

  render() {
    const { type, error, loading, slugError } = this.state;

    if (error) throw error;

    const { formLoading, formError } = this.props;

    if (formError && formError.statusCode >= HTTPCodes.SYSTEM_ERROR)
      throw formError;

    return (
      <AccountLoader
        loaded={!loading}
        message="Please wait while we verify your signup link"
      >
        <React.Fragment>
          <div className="content">
            <div className="logo">
              <img src="/img/logo.png" alt="illuminate" />
            </div>
            <div className="form">
              <h1>Create your account</h1>
              <p className="mb-4">
                We just need a few more details to set up your new account.
              </p>
              {type === SignUpType.EMAIL && (
                <Form
                  formFields={{
                    firstName: {
                      name: "firstName",
                      value: "",
                      validator: {
                        type: ValidatorTypes.REQUIRED,
                        messages: { required: "Please enter your first name" },
                      },
                    },
                    lastName: {
                      name: "lastName",
                      value: "",
                      validator: {
                        type: ValidatorTypes.REQUIRED,
                        messages: { required: "Please enter your last name" },
                      },
                    },
                    password: {
                      name: "password",
                      value: "",
                      validator: {
                        type: ValidatorTypes.PASSWORD,
                        messages: {
                          type: "Please enter a password with at least 8 characters",
                        },
                      },
                    },
                    companyName: {
                      name: "companyName",
                      value: "",
                      validator: {
                        type: ValidatorTypes.MIN_LENGTH_3,
                        messages: {
                          required:
                            "Please enter the name of your company with at least 3 characters",
                        },
                      },
                    },
                    workspaceURL: {
                      name: "workspaceURL",
                      value: "",
                      validator: { type: ValidatorTypes.URL_SAFE },
                    },
                    tos: {
                      name: "tos",
                      value: false,
                      validator: {
                        type: ValidatorTypes.CHECKBOX_REQUIRED,
                        messages: {
                          required:
                            "You must agree to and accept the illuminate Terms of Service and Privacy Policy",
                        },
                      },
                    },
                    newsletter: {
                      name: "newsletter",
                      value: false,
                    },
                  }}
                  onSubmit={this.onSubmit}
                  FormComponent={({
                    fields: {
                      firstName,
                      lastName,
                      password,
                      companyName,
                      workspaceURL,
                      tos,
                      newsletter,
                    },
                    onChange,
                  }) => (
                    <div>
                      <div className="form-group row">
                        <div className="col-md-6">
                          <label>First Name</label>
                          <Input
                            field={firstName}
                            className="form-control"
                            onChange={(e) =>
                              onChange(firstName, e.target.value)
                            }
                          />
                        </div>
                        <div className="col-md-6">
                          <label>Last Name</label>
                          <Input
                            field={lastName}
                            className="form-control"
                            onChange={(e) => onChange(lastName, e.target.value)}
                          />
                        </div>
                      </div>
                      <div className="form-group">
                        <label>Password</label>
                        <Input
                          field={password}
                          className="form-control"
                          type={InputTypes.PASSWORD}
                          onChange={(e) => onChange(password, e.target.value)}
                        />
                        <p className="desc">
                          Must contain at least 8 characters.
                        </p>
                      </div>
                      <div className="form-group ">
                        <label>Company Name</label>
                        <Input
                          field={companyName}
                          className="form-control"
                          onChange={(e) =>
                            onChange(companyName, e.target.value)
                          }
                        />
                      </div>
                      <div className="form-group">
                        <label>Account URL</label>
                        <div className="workspace-input">
                          <Input
                            field={workspaceURL}
                            className={
                              slugError === false
                                ? "form-control"
                                : "form-control error"
                            }
                            maxLength={20}
                            onChange={(e) => {
                              e.target.value = e.target.value.toLowerCase();
                              onChange(workspaceURL, e.target.value);
                            }}
                          />
                          {slugError === true && (
                            <div className="error">
                              This Workspace URL has already been taken. Please
                              try again.
                            </div>
                          )}
                        </div>
                        <p className="desc">
                          This is the URL that you (and your team) will use to
                          access your account.
                        </p>
                      </div>
                      <div className="form-group">
                        <Checkbox
                          TextComponent={this.termsOfService}
                          field={tos}
                          className="form-control"
                          onChange={(e) => onChange(tos, e.target.checked)}
                        />
                      </div>
                      <div className="form-group">
                        <Checkbox
                          text="Yes, send me information about Brooks Bell &amp; illuminate"
                          field={newsletter}
                          className="form-control"
                          onChange={(e) =>
                            onChange(newsletter, e.target.checked)
                          }
                        />
                      </div>
                      <Button
                        className="btn btn-primary"
                        text="continue"
                        isLoading={formLoading}
                      />
                    </div>
                  )}
                />
              )}
              {type === SignUpType.GOOGLE && (
                <Form
                  formFields={{
                    companyName: { name: "companyName", value: "" },
                    workspaceURL: {
                      name: "workspaceURL",
                      value: "",
                      validator: { type: ValidatorTypes.URL_SAFE },
                    },
                    tos: {
                      name: "tos",
                      value: false,
                      validator: {
                        type: ValidatorTypes.CHECKBOX_REQUIRED,
                        messages: {
                          required: "Terms of Service checkbox is required",
                        },
                      },
                    },
                    newsletter: {
                      name: "newsletter",
                      value: false,
                    },
                  }}
                  onSubmit={this.onSubmit}
                  FormComponent={({
                    fields: { companyName, workspaceURL, tos, newsletter },
                    onChange,
                  }) => (
                    <div>
                      <div className="form-group ">
                        <label>Company Name</label>
                        <Input
                          field={companyName}
                          className="form-control"
                          onChange={(e) =>
                            onChange(companyName, e.target.value)
                          }
                        />
                      </div>
                      <div className="form-group">
                        <label>Account URL</label>
                        <div className="workspace-input">
                          <Input
                            field={workspaceURL}
                            className={
                              slugError === false
                                ? "form-control"
                                : "form-control error"
                            }
                            maxLength={20}
                            onChange={(e) => {
                              e.target.value = e.target.value.toLowerCase();
                              onChange(workspaceURL, e.target.value);
                            }}
                          />
                          {slugError === true && (
                            <div className="error">
                              This name is already taken. Please try again
                            </div>
                          )}
                        </div>
                        <p className="desc">
                          This will help distinguish your project on the web.
                        </p>
                      </div>
                      <div className="form-group">
                        <Checkbox
                          TextComponent={this.termsOfService}
                          field={tos}
                          className="form-control"
                          onChange={(e) => onChange(tos, e.target.checked)}
                        />
                      </div>
                      <div className="form-group">
                        <Checkbox
                          text="Yes, send me information about Brooks Bell &amp; illuminate"
                          field={newsletter}
                          className="form-control"
                          onChange={(e) =>
                            onChange(newsletter, e.target.checked)
                          }
                        />
                      </div>
                      <Button
                        className="btn btn-primary"
                        text="continue"
                        isLoading={formLoading}
                      />
                    </div>
                  )}
                />
              )}
            </div>
          </div>
          <div className="aside">
            <AsideImage />
          </div>
        </React.Fragment>
      </AccountLoader>
    );
  }
}

const formLoader = createLoadingSelector(["@@account/CREATE_ACCOUNT"]);
const createError = createErrorMessageSelector(["@@account/CREATE_ACCOUNT"]);

const mapStateToProps = ({ app, user }: DataTypes.ApplicationState) => ({
  formLoading: formLoader(app.requests),
  formError: createError(app.errors),
  user: user,
});

const mapDispatchToProps = {
  createAccountRequest,
};

const connectedPage = connect(
  mapStateToProps,
  mapDispatchToProps
)(PublicPage(CreateAccount));

export { connectedPage as CreateAccount };
