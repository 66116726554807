import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import * as API from "../../api";
import * as Actions from "./actions";
import { ActionTypes } from "./types";

function* getIdeaBoardsTraits(action: any) {
  try {
    // @ts-ignore
    const res = yield call(API.IdeaBoards.traits);

    yield put(Actions.getIdeaBoardsTraitsSuccess(res.body));
  } catch (error: any) {
    yield put(Actions.getIdeaBoardsTraitsError(error));
  }
}

function* createIdeaBoardsTraits(action: any) {
  try {
    const { insightId, traitName, values } = action.payload;

    // @ts-ignore
    const res = yield call(
      API.IdeaBoards.addTraits,
      insightId,
      traitName,
      values
    );

    yield put(Actions.createIdeaBoardsTraitsSuccess(res.body));
    yield put(Actions.getIdeaBoardsTraitRequestSaga());
  } catch (error: any) {
    yield put(Actions.getIdeaBoardsTraitsError(error));
  }
}

function* watchGetIdeaBoardsTraits() {
  yield takeEvery(
    ActionTypes.GET_IDEA_BOARDS_TRAITS_REQUEST,
    getIdeaBoardsTraits
  );
}

function* watchCreateIdeaBoardsTraits() {
  yield takeEvery(
    ActionTypes.CREATE_IDEA_BOARDS_TRAITS_REQUEST,
    createIdeaBoardsTraits
  );
}

function* ideaBoardsTraitsSaga() {
  yield all([
    fork(watchGetIdeaBoardsTraits),
    fork(watchCreateIdeaBoardsTraits),
  ]);
}

export default ideaBoardsTraitsSaga;
