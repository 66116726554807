import React, { Component } from "react";
import { FormField } from "./";
import * as Dp from "react-date-picker";
import { Validator } from "./validator";
import moment from "moment";

interface Props {
  field: FormField;
  className: string;
  onChange(e: any): any;
  minDate?: Date;
  maxDate?: Date;
}

interface State {
  field: FormField;
}

class DatePicker extends Component<Props, State> {
  validator: Validator;

  constructor(props: Props) {
    super(props);
    this.validator = new Validator();
    this.state = {
      field: this.props.field,
    };
  }

  static getDerivedStateFromProps = (nextProps: Props) => {
    return { field: nextProps.field };
  };

  public validate = () => {
    this.validator.validateField(this.state.field);
    this.setState({ field: this.state.field });
  };

  public render() {
    const { className, onChange, minDate, maxDate } = this.props;
    const { value, valid, error } = this.state.field;
    const cn = valid === false ? className + " error" : className;

    let date = new Date();
    if (value) {
      date = moment(value, "DD-MMM-YYYY").toDate();
    }

    return (
      <div>
        <Dp.default
          className={cn}
          onChange={onChange}
          calendarType={"US"}
          clearIcon={null}
          value={date}
          minDate={minDate}
          maxDate={maxDate}
          calendarIcon={<i className="far fa-calendar" />}
        />
        {valid === false && <div className="error">{error}</div>}
      </div>
    );
  }
}

export { DatePicker };
