import React, { Component } from 'react';
import * as DataTypes from '../../../../store/types';

interface IProps {
  invite: DataTypes.Invite;
  saving: boolean;
  onSubmit(invite: DataTypes.Invite): void;
}

class InviteDeleteModal extends Component<IProps> {
  render() {
    const { invite, onSubmit, saving } = this.props;

    return (
      <React.Fragment>
        <div className="header">
          <h3>Delete Invitation</h3>
          <p>
            Are you sure you want to delete the invitation sent to{' '}
            <strong>
              <span>{invite.email}</span>
            </strong>
            ? This action can not be undone and the invite will no longer be valid.
          </p>
        </div>
        <div className="cntrl-bar">
          {saving === true ? (
            <button className="btn btn-danger" type="submit" disabled={true}>
              <i className="fas fa-circle-notch fa-spin" />
            </button>
          ) : (
            <button className="btn btn-danger" onClick={() => onSubmit(invite)}>
              delete invite
            </button>
          )}
        </div>
      </React.Fragment>
    );
  }
}

export { InviteDeleteModal };
