export enum App {
  APP_ERROR_CLEAR = '@@app/APP_ERROR_CLEAR',

  INIT_APP_REQUEST = '@@app/INIT_APP_REQUEST',
  INIT_APP_SUCCESS = '@@app/INIT_APP_SUCCESS',
  INIT_APP_FAILURE = '@@app/INIT_APP_FAILURE',

  MODAL_SHOW = '@@modal/SHOW',
  MODAL_HIDE = '@@modal/HIDE',
  MODAL_SAVING = '@@modal/SAVING',
  MODAL_ERROR = '@@modal/ERROR',
  MODAL_OPTIONS = '@@modal/OPTIONS',
  MODAL_SAVE_COMPLETE = '@@modal/MODAL_SAVE_COMPLETE',

  ALERT_TOGGLE = '@@alert/TOGGLE',
  ALERT_SHOW = '@@alert/SHOW',
  ALERT_HIDE = '@@alert/HIDE',

  LOGIN_REQUEST = '@@user/LOGIN_REQUEST',
  LOGIN_SUCCESS = '@@user/LOGIN_SUCCESS',
  LOGIN_ERROR = '@@user/LOGIN_ERROR',
}
