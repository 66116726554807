import React from 'react';
import styled from 'styled-components';
import { Spacer } from 'comps/layout';

const InsightAltitude = () => {
  return (
    <StyledModal>
      <div className="header">
        <h1 style={ { fontSize: '26px' } }>What is the Insight Altitude<sup>®</sup> Customer Insights Tool?</h1>
        <p>
          We created Insight Altitude to uncover the deeper meaning behind test results. Use the Insight Altitude taxonomy to consider insights at different levels. From tactical outcomes, to unique customer state of mind (our favorite!), all the way up to general behavioral economics principles - you're on your way to a new way of getting to know your customers.
        </p>
        <p className="mt-2">
          Enable "Brooks Bell Methodologies" in Account Settings to add the Insight Altitude attribute to your insights.
        </p>
      </div>

      <div className="d-flex mt-4">
        <div className="image">
          <img className="w-100" src="/img/altitudes/satellite.png" alt="satellite" />
        </div>
        <div className="desc">
          <h4>Satellite</h4>
          <p>
            These customer theories are formal Behavioral Economics Principles and generally accepted maxims. These describe most human behavior and are helpful in explaining motivation.
          </p>
        </div>
      </div>
      <Spacer size={3} />
      <div className="d-flex">
        <div className="image">
          <img className="w-100" src="/img/altitudes/jetstream.png" alt="jetstream" />
        </div>
        <div className="desc">
          <h4>Jetstream</h4>
          <p>
            This is about WHO your shopper might be as a person, and what this would imply about their behavior while in this test. What are their likes &amp; dislikes, especially specific to your brand?
          </p>
        </div>
      </div>
      <Spacer size={3} />
      <div className="d-flex">
        <div className="image">
          <img className="w-100" src="/img/altitudes/summit.png" alt="summit" />
        </div>
        <div className="desc">
          <h4>Summit</h4>
          <p>
            This is the predicted state of mind of the customer in this particular session. What do they care about? What emotion could be affecting them and why?
          </p>
        </div>
      </div>
      <Spacer size={3} />
      <div className="d-flex">
        <div className="image">
          <img className="w-100" src="/img/altitudes/basecamp.png" alt="basecamp" />
        </div>
        <div className="desc">
          <h4>Basecamp</h4>
          <p>
            This is about the design elements, general usability or speed of the immediate surroundings. Sometimes a test result is simply explained by being faster, less buggy or prettier.
          </p>
        </div>
      </div>
      <Spacer size={3} />
      <div className="d-flex">
        <div className="image">
          <img className="w-100" src="/img/altitudes/groundlevel.png" alt="groundlevel" />
        </div>
        <div className="desc">
          <h4>Groundlevel</h4>
          <p>
            These are the outcomes from the experiment. You can use simple statements like “X works” here (you shouldn’t use the word “works” in any other altitude).
          </p>
        </div>
      </div>
      <Spacer size={2} />
    </StyledModal>
  );
};

export { InsightAltitude };

const StyledModal = styled.div`
  min-width: 600px;
  padding: 10px;

  &&& {
    .header {
      padding: 0;
      width: 100%;
    }
  }
  h4 {
    font-family: Roboto;
  }
  .image {
    width: 75px;
    align-items: center;
    display: flex;
  }

  .w-100 {
    width: 100;
  }
  .desc {
    width: 480px;
  }
  .d-flex {
    justify-content: space-between;
  }
`;
