export enum ActionTypes {
  AUTH_SLACK_REQUEST = '@@slack/AUTH_SLACK_REQUEST',
  AUTH_SLACK_SUCCESS = '@@slack/AUTH_SLACK_SUCCESS',
  AUTH_SLACK_FAILURE = '@@slack/AUTH_SLACK_FAILURE',

  LIST_SLACK_REQUEST = '@@slack/LIST_SLACK_REQUEST',
  LIST_SLACK_SUCCESS = '@@slack/LIST_SLACK_SUCCESS',
  LIST_SLACK_FAILURE = '@@slack/LIST_SLACK_FAILURE',

  DELETE_SLACK_REQUEST = '@@slack/DELETE_SLACK_REQUEST',
  DELETE_SLACK_SUCCESS = '@@slack/DELETE_SLACK_SUCCESS',
  DELETE_SLACK_FAILURE = '@@slack/DELETE_SLACK_FAILURE',

  SAVE_SLACK_SETTINGS_REQUEST = '@@slack/SAVE_SLACK_SETTINGS_REQUEST',
  SAVE_SLACK_SETTINGS_SUCCESS = '@@slack/SAVE_SLACK_SETTINGS_SUCCESS',
  SAVE_SLACK_SETTINGS_FAILURE = '@@slack/SAVE_SLACK_SETTINGS_FAILURE',

  GET_SLACK_SETTINGS_REQUEST = '@@slack/GET_SLACK_SETTINGS_REQUEST',
  GET_SLACK_SETTINGS_SUCCESS = '@@slack/GET_SLACK_SETTINGS_SUCCESS',
  GET_SLACK_SETTINGS_FAILURE = '@@slack/GET_SLACK_SETTINGS_FAILURE'
}
