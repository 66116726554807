import React from "react";
import { TabsContent } from "./style";

type Params = {
  active: boolean;
  name: string;
  onChangeName: (name: string) => void;
};

export const NameTab: React.FC<Params> = ({ active, name, onChangeName }) => {
  return (
    <TabsContent className={`${active ? "active" : ""}`}>
      <div>
        <div className="form-group">
          <label htmlFor="custom_data_viz_name">Name *</label>

          <input
            value={name}
            id="custom_data_viz_name"
            className="form-control"
            onChange={(event) => onChangeName(event.target.value)}
          />

          <p>
            Give this data visualization a name. This will be visible in the
            report section and case study.
          </p>
        </div>
      </div>
    </TabsContent>
  );
};
