export enum ActionTypes {
  UPDATE_EXPERIENCE_SUCCESS = '@@experience/UPDATE_EXPERIENCE_SUCCESS',
  UPDATE_EXPERIENCE_FAILURE = '@@experience/UPDATE_EXPERIENCE_FAILURE',
  UPDATE_EXPERIENCE_REQUEST = '@@experience/UPDATE_EXPERIENCE_REQUEST',

  CREATE_EXPERIENCE_REQUEST = '@@experience/CREATE_EXPERIENCE_REQUEST',
  CREATE_EXPERIENCE_SUCCESS = '@@experience/CREATE_EXPERIENCE_SUCCESS',
  CREATE_EXPERIENCE_FAILURE = '@@experience/CREATE_EXPERIENCE_FAILURE',

  DESTROY_EXPERIENCE_REQUEST = '@@experience/DESTROY_EXPERIENCE_REQUEST',
  DESTROY_EXPERIENCE_SUCCESS = '@@experience/DESTROY_EXPERIENCE_SUCCESS',
  DESTROY_EXPERIENCE_FAILURE = '@@experience/DESTROY_EXPERIENCE_FAILURE',

  ADD_IMAGE_REQUEST = '@@experience/ADD_IMAGE_REQUEST',
  ADD_IMAGE_SUCCESS = '@@experience/ADD_IMAGE_SUCCESS',
  ADD_IMAGE_FAILURE = '@@experience/ADD_IMAGE_FAILURE',

  DELETE_IMAGE_REQUEST = '@@experience/DELETE_IMAGE_REQUEST',
  DELETE_IMAGE_SUCCESS = '@@experience/DELETE_IMAGE_SUCCESS',
  DELETE_IMAGE_FAILURE = '@@experience/DELETE_IMAGE_FAILURE',

  UPDATE_IMAGE_REQUEST = '@@experience/UPDATE_IMAGE_REQUEST',
  UPDATE_IMAGE_SUCCESS = '@@experience/UPDATE_IMAGE_SUCCESS',
  UPDATE_IMAGE_FAILURE = '@@experience/UPDATE_IMAGE_FAILURE',

  ADD_IMAGE_COMMENT_REQUEST = '@@experience/ADD_IMAGE_COMMENT_REQUEST',
  ADD_IMAGE_COMMENT_SUCCESS = '@@experience/ADD_IMAGE_COMMENT_SUCCESS',
  ADD_IMAGE_COMMENT_FAILURE = '@@experience/ADD_IMAGE_COMMENT_FAILURE',

  DELETE_IMAGE_COMMENT_REQUEST = '@@experience/DELETE_IMAGE_COMMENT_REQUEST',
  DELETE_IMAGE_COMMENT_SUCCESS = '@@experience/DELETE_IMAGE_COMMENT_SUCCESS',
  DELETE_IMAGE_COMMENT_FAILURE = '@@experience/DELETE_IMAGE_COMMENT_FAILURE'
}
