import React, { Component } from 'react';
import * as DataTypes from 'store/types';

interface IProps {
  ideaBoard: DataTypes.IdeaBoard;
  saving: boolean;
  onSubmit(): void;
}

class Archive extends Component<IProps> {
  render() {
    const { ideaBoard, onSubmit, saving } = this.props;

    return (
      <React.Fragment>
        <div className="header">
          {
            ideaBoard.archived ?
            <h3>Restore Idea Board</h3> :
            <h3>Archive Idea Board</h3>
          }

          {
            ideaBoard.archived ?
            <p>
              Just making sure you want to restore this idea board. Doing so will bring it back into your active idea board list, and ideas previously tied to this board will automatically be re-added. You can archive it again later if you choose.
            </p> :
            <p>
              Are you sure you want to archive this idea board? You can still access it using the Status filter. You can also restore this idea board from there. Note: this will not archive ideas that are tied to this board.
            </p>
          }

        </div>
        <div className="cntrl-bar">
          {saving === true ? (
            <button className="btn btn-danger" type="submit" disabled={true}>
              <i className="fas fa-circle-notch fa-spin" />
            </button>
          ) : (
            <button className="btn btn-danger" onClick={() => onSubmit()}>
              { ideaBoard.archived ? 'Restore Idea Board' : 'Archive Idea Board' }
            </button>
          )}
        </div>
      </React.Fragment>
    );
  }
}

export { Archive };
