import React, { useState, useRef } from "react";
import { handleOutsideClick } from "./utils";
import * as DataTypes from "store/types";
import { CurrencySymbols } from "store/types";

export interface MetricItem {
  icon: string;
  name: string;
  onClick(): void;
  splitAbove?: boolean;
  className?: string;
}

interface IProps {
  metric: DataTypes.Metric;
  onPrimaryChange(value: any): void;
  onMetricTypeChange?(value: any, symbol?: string): void;
  onDelete(): void;
  currentUser: any;
  metricsCount: number;
  onReorder(metric: any, direction: any, position?: number): void;
}

const CURRENCY_SYMBOL_ICONS = {
  DOLLAR: "fas fa-dollar-sign",
  EURO: "fas fa-euro-sign",
  POUND: "fas fa-pound-sign",
};

const MetricControl = ({
  metric,
  onPrimaryChange,
  onMetricTypeChange,
  onDelete,
  onReorder,
  metricsCount,
  currentUser,
}: IProps) => {
  const ref = useRef<HTMLUListElement>(null);

  const [open, setOpen] = useState(false);
  const [subMenu, subMenuOpen] = useState("");

  const addClassNames = () => (open ? `dropdown-menu show` : `dropdown-menu `);

  handleOutsideClick(ref, () => {
    if (open) setOpen(!open);
  });

  if (currentUser.role === "read-only") {
    return null;
  }

  return (
    <div className="dropdown">
      <button className="dropdown-button" onClick={(e) => setOpen(!open)}>
        <i className="fas fa-ellipsis-h"></i>
      </button>
      <ul ref={ref} className={addClassNames()}>
        <li key="primary" className="dropdown-menu-item">
          <button
            className={"btn dropdown-menu-item-btn"}
            type="button"
            onClick={(e) => onPrimaryChange(!metric.primary)}
          >
            <i className="fa fa-star" />{" "}
            {metric.primary ? "Remove Primary" : "Make Primary"}
          </button>
        </li>

        <li
          key="order"
          className="dropdown-menu-item"
          onMouseOver={(e) => subMenuOpen("order")}
          onMouseLeave={(e) => subMenuOpen("")}
        >
          <button
            className="btn dropdown-menu-item-btn arrow-right"
            type="button"
          >
            <i className="fas fa-arrows-alt-v"></i> Order
          </button>
          {subMenu === "order" && (
            <div className="subMenu">
              <ul>
                <li
                  className={metric.custom_order === 1 ? "disabled" : ""}
                  onClick={() => onReorder(metric, "up", 1)}
                >
                  <i className="arrow-top" />
                  Move to Top
                </li>

                <li
                  className={metric.custom_order === 1 ? "disabled" : ""}
                  onClick={(e) => onReorder(metric, "up")}
                >
                  <i className="arrow-up" />
                  Up
                </li>

                <li
                  className={
                    metric.custom_order >= metricsCount ? "disabled" : ""
                  }
                  onClick={(e) => onReorder(metric, "down")}
                >
                  <i className="arrow-down" />
                  Down
                </li>

                <li
                  className={
                    metric.custom_order >= metricsCount ? "disabled" : ""
                  }
                  onClick={(e) => onReorder(metric, "down", metricsCount)}
                >
                  <i className="arrow-bottom" />
                  Move to Bottom
                </li>
              </ul>
            </div>
          )}
        </li>

        <li
          className={`${
            metric.metric_type !== "currency" &&
            metric.metric_type !== "percent"
              ? "active"
              : ""
          } dropdown-menu-item`}
        >
          <button
            className={"btn dropdown-menu-item-btn"}
            type="button"
            onClick={(e) => onMetricTypeChange && onMetricTypeChange("number")}
          >
            <i className="fab fa-slack-hash" />
            Number
          </button>
        </li>

        <li
          onMouseOver={(e) => subMenuOpen("currency_symbol")}
          onMouseLeave={(e) => subMenuOpen("")}
          className={`${
            metric.metric_type === "currency" ? "active" : ""
          } dropdown-menu-item`}
        >
          <button
            className={"btn dropdown-menu-item-btn arrow-right"}
            type="button"
            onClick={(e) =>
              onMetricTypeChange &&
              onMetricTypeChange("currency", CurrencySymbols.DOLLAR)
            }
          >
            <i className="fas fa-dollar-sign" />
            Currency
          </button>
          {subMenu === "currency_symbol" && (
            <div className="subMenu">
              <ul>
                {Object.keys(CurrencySymbols).map((symbol) => (
                  <li
                    key={`currency-symbol-select-${symbol}`}
                    className={
                      metric.metric_type === "currency" &&
                      metric.symbol === symbol
                        ? "active"
                        : ""
                    }
                    onClick={(e) =>
                      onMetricTypeChange &&
                      onMetricTypeChange("currency", symbol)
                    }
                  >
                    <i className={(CURRENCY_SYMBOL_ICONS as any)[symbol]} />
                    {symbol.toLocaleLowerCase()}
                  </li>
                ))}
              </ul>
            </div>
          )}
        </li>
        <li
          className={`${
            metric.metric_type === "percent" ? "active" : ""
          } dropdown-menu-item`}
        >
          <button
            className={"btn dropdown-menu-item-btn"}
            type="button"
            onClick={(e) => onMetricTypeChange && onMetricTypeChange("percent")}
          >
            <i className="fas fa-percent"></i>
            Percent
          </button>
        </li>

        <li
          key="delete"
          className="dropdown-menu-item split-above"
          onClick={() => onDelete()}
        >
          <button className={"btn dropdown-menu-item-btn danger"} type="button">
            <i className="far fa-trash-alt" /> Delete
          </button>
        </li>
      </ul>
    </div>
  );
};

export { MetricControl };
