import React from 'react';
import * as DataTypes from 'store/types';
import { DropdownControl } from 'ui';
import TimeAgo from 'timeago-react';

interface IProps {
  pendingInvites: Array<DataTypes.User>;
  onDelete(invite: any): void;
  onCopy(invite: any): void;
}

const PendingInvitesList = (props: IProps) => {
  const { pendingInvites, onDelete, onCopy } = props;

  if (!pendingInvites) return null;

  return (
    <ul className="InviteList">
      {pendingInvites.map((pendingInvite: any, idx: number) => {
        return (
          <li key={'user' + pendingInvite.id}>
            <div className="Icon">
              <i className="far fa-envelope-open"></i>
            </div>
            <div className="w-100">
              <h4>
                <span>{pendingInvite.email}</span>
              </h4>
              <p>
                <small>
                  <TimeAgo datetime={pendingInvite.created_at} live={false} />
                </small>
              </p>
            </div>
            <div>
              <div className="control">
                <DropdownControl controlItems={[
                    { name: 'Copy Invite Link', icon: 'far fa-copy', onClick: () => onCopy(pendingInvite) }, 
                    { name: 'Delete', icon: 'far fa-trash-alt', onClick: () => onDelete(pendingInvite), className: 'danger', splitAbove: true }
                  ]}
                />
              </div>
            </div>
          </li>
        );
      })}
    </ul>
  );
};

export { PendingInvitesList };
