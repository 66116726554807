import React from "react";
import * as Nav from "./navs";
import * as DataTypes from "store/types";
import "../css/Pages.scss";

// App
interface IAppProps {
  account: DataTypes.Account | null;
  currentUser: any;
  fullScreen: boolean;
  navIndex: number;
  children: React.ReactNode;
}

export const AppLayout = ({
  fullScreen,
  navIndex,
  account,
  currentUser,
  children,
}: IAppProps) => {
  return (
    <div className="App">
      {fullScreen !== true && (
        <Nav.Main
          key="main_nav"
          account={account}
          currentUser={currentUser}
          navIndex={navIndex}
        />
      )}
      <div className="Page">{children}</div>
    </div>
  );
};

// Public
interface IPubicProps {
  children: React.ReactNode;
}

export const PublicLayout = ({ children }: IPubicProps) => {
  return (
    <div className="Public">
      <div className="Page">{children}</div>
    </div>
  );
};
