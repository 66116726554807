import React, { Component } from "react";
import * as DataTypes from "store/types";

interface IProps {
  idea: DataTypes.Idea;
  saving: boolean;
  onSubmit(idea: DataTypes.Idea): void;
}

class Delete extends Component<IProps> {
  render() {
    const { idea, onSubmit, saving } = this.props;

    return (
      <React.Fragment>
        <div className="header">
          <h3>DELETE IDEA</h3>
          <p>
            Are you sure you want to delete this idea? This action cannot be
            undone and this idea will be <strong>permanently deleted</strong>{" "}
            from illuminate.
          </p>
        </div>
        <div className="cntrl-bar">
          {saving === true ? (
            <button className="btn btn-danger" type="submit" disabled={true}>
              <i className="fas fa-circle-notch fa-spin" />
            </button>
          ) : (
            <button className="btn btn-danger" onClick={() => onSubmit(idea)}>
              Yes, delete this IDEA.
            </button>
          )}
        </div>
      </React.Fragment>
    );
  }
}

export { Delete };
