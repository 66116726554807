import { ApiClient } from "./client/api_client";

class BannerPlacement {
  static async show(bannerPlacementSlug: string) {
    return await ApiClient.get(
      `/v1/marketing/banner_placement/${bannerPlacementSlug}`
    );
  }
}

export { BannerPlacement };
