import React from 'react';
import styled from 'styled-components';

interface IProps {
  value: string;
  onChange: (input: string) => void;
  onSubmit: (input: string) => void;
  placeholder: string;
  enableReset?: boolean;
}
const SearchBar = ({ value, onChange, onSubmit, placeholder, enableReset }: IProps) => (
  <div className="search d-flex">
    <input
      className="form-control"
      type="text"
      placeholder={placeholder}
      value={value}
      onChange={(e) => onChange(e.target.value)}
      onKeyPress={(e) => {
        if (e.which === 13) onSubmit(value);
      }}
    />
    <StyledResetButton>
      {enableReset && value && (
        <button
          onClick={() => {
            onChange('');
            onSubmit('');
          }}
          className="btn-white"
        >
          <i className="far fa-times-circle" />
        </button>
      )}
    </StyledResetButton>
  </div>
);

const StyledResetButton = styled.div`
  width: 0;
  position: relative;
  z-index: 10;

  button {
    position: absolute;
    top: 15%;
    right: 10px;
    width: 30px;
    height: 30px;
    border: 0;
    color: #757e8e;
    font-size: 24px;
    display: flex;
    justify-content: center;
    align-items: center;

    i {
      font-size: 16px;
    }
  }
`;

export { SearchBar };
