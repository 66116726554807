import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { Cell } from './cell';

const DurationBar = (props: {
  name: string;
  start: string;
  dateOffset: number;
  duration: number;
  end: string;
  status?: string;
  total: number;
  link: string;
  blockSize: number;
  star?: boolean;
}) => {
  const { start, duration, status, name, end, dateOffset, total, link, blockSize, star } = props;
  const leftPadding = dateOffset !== 1 ? 3 : 0;
  const isEndOfMonth = dateOffset + duration === total;
  const left = (dateOffset - 1) * blockSize - leftPadding;
  const width = (duration + 1) * blockSize + leftPadding + (isEndOfMonth ? 0 : 3);
  const hoverWidth = (duration + (name.length > 30 ? 6 : 3)) * blockSize;
  const flip = left === 0;
  const getStatus = () => {
    if (status === Status.FLAT) return { color: '#F4B974', symbol: 'F' };
    if (status === Status.WIN) return { color: '#26BE6F', symbol: 'W' };
    if (status === Status.LOSS) return { color: '#FF5A71', symbol: 'L' };
    return { color: '#E4E4E4', symbol: '' };
  };
  const ribbon = getStatus();
  const getName = (name:string, duration:number) => {
    if (duration > 2)
      return name;
    if (duration === 0)
      return `${name.substring(0, 2)}...`;

    return `${name.substring(0, 6)}...`;
  }
  return (
    <Cell style={{ position: 'relative' }}>
      <Link to={link}>
        <StyledDurationBar
          isEndOfMonth={isEndOfMonth}
          flip={flip}
          ribbon={ribbon}
          className={duration < 3 ? 'short-duration-bar' : 'duration-bar'}
          style={{ left, width }}
        >
          {ribbon.symbol && <div className="circle">{ribbon.symbol}</div>}
          <div className="name">
            {getName(name, duration)}
          </div>
          <div className="duration">{duration > 2 && `${start} - ${end}`}&nbsp;</div>
          {star && <div className="star"><i className="fa fa-star"/></div>}
          <StyledDurationBar
            isEndOfMonth={isEndOfMonth}
            flip={false}
            ribbon={ribbon}
            className="hover-state"
            style={{ [flip ? 'right' : 'left']: flip ? 0 : -10, top: -1, width: hoverWidth }}
          >
            {ribbon.symbol && <div className="circle">{ribbon.symbol}</div>}
            <div className="name">{name}</div>
            <div className="duration">
              {start} - {end}
            </div>
            {star && <div className="star"><i className="fa fa-star"/></div>}
          </StyledDurationBar>
        </StyledDurationBar>
      </Link>
    </Cell>
  );
};

const Status = { FLAT: 'flat', COMPLETE: 'complete', LIVE: 'live', LOSS: 'loss', WIN: 'winner' };

const StyledDurationBar: any = styled.div`
  position: absolute;
  top: 20px;
  height: 70px;
  padding: 10px;
  border: 1px lightgrey solid;
  border-left: 10px ${(props: any) => props.ribbon.color} solid;
  border-radius: ${(props: any) => (props.flip || props.isEndOfMonth ? '5px 0px 0px 5px' : '5px 5px 5px 5px')};
  font-size: 12px;
  transform: ${(props: any) => (props.flip ? 'scaleX(-1)' : '')};
  display: none;
  color: #2f4760;
  background: white;
  white-space: nowrap;

  &.duration-bar,
  &.short-duration-bar {
    display: block;

    &:hover {
      box-shadow: 0px 2px 5px lightgrey;
    }
  }
  &.short-duration-bar:hover .hover-state {
    display: block;
    box-shadow: 0px 2px 5px lightgrey;
  }

  & .name {
    font-weight: bold;
    transform: ${(props: any) => (props.flip ? 'scaleX(-1)' : '')};
  }
  & .duration {
    color: grey;
    font-size: 10px;
    transform: ${(props: any) => (props.flip ? 'scaleX(-1)' : '')};
  }
  & .star {
    transform: ${(props: any) => (props.flip ? 'scaleX(-1)' : '')};
    color: #f3be67;
  }

  & .circle {
    position: absolute;
    left: -14px;
    top: 18px;
    border-radius: 50%;
    width: 18px;
    height: 18px;
    background: ${(props: any) => props.ribbon.color};
    color: white;
    font-size: 9px;
    text-align: center;
    padding-top: 3px;
    transform: ${(props: any) => (props.flip ? 'scaleX(-1)' : '')};
  }
`;

export { DurationBar };
