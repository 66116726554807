import React, { useEffect, useState, useRef } from 'react';
import { handleOutsideClick, onEnterPress } from './utils';
import { AdobeMetric } from 'store/types';

interface IProps {
  name: string;
  metrics: Array<AdobeMetric>;
  activeItems: Array<AdobeMetric>;
  onChange?(values: Array<AdobeMetric>): void;
}

const AdobeMetricSelector = ({ name, metrics, activeItems, onChange }: IProps) => {
  const ref = useRef<any>(null);

  const [edit, setEdit] = useState(false);
  const [searchVal, setSearch] = useState('');
  const [hoverIdx, setHoverIndex] = useState(0);
  const [results, setResults] = useState([...new Set(metrics)]);
  const [active, setActiveItems] = useState(activeItems);
  const [exactMatch, setExactMatch] = useState(false);

  onEnterPress(ref, () => {
    setEdit(false);
    if (searchVal !== '' && exactMatch === false) {
      let currentActiveItems = active;
      //currentActiveItems.push(searchVal);
      setSearch('');
      setActiveItems(currentActiveItems);
      if (onChange) onChange(currentActiveItems);
    }
  });

  handleOutsideClick(ref, () => {
    if (edit) {
      setSearch('');
      setHoverIndex(0);
      setEdit(!edit);
    }
  });

  useEffect(() => {
    setResults(metrics);
  }, [metrics]);

  const setSearchValue = (value: string) => {
    setHoverIndex(-1);
    setSearch(value);

    const results = metrics.filter((i) => i.name.toLowerCase().startsWith(value.toLowerCase()));
    const exact = metrics.filter((i) => i.name.toLowerCase() === value.toLowerCase()).length > 0;
    setExactMatch(exact);

    if (exact === true) setHoverIndex(0);

    setResults(results);
  };

  const onAddActiveItems = (value: AdobeMetric) => {
    let currentActiveItems = active;
    const itemIdx = currentActiveItems.findIndex((cai) => cai.client_id === value.client_id);
    if (itemIdx >= 0) {
      currentActiveItems = currentActiveItems.slice(0, itemIdx).concat(activeItems.slice(itemIdx + 1, metrics.length));
    } else {
      currentActiveItems.push({ client_id: value.client_id, name: value.name, metric_type: value.metric_type });
    }

    setActiveItems(currentActiveItems);
    setSearch('');
    if (onChange) onChange(currentActiveItems);
  };

  const onRemoveActiveItems = (itemIdx: number) => {
    let currentActiveItems = active;
    currentActiveItems = currentActiveItems.slice(0, itemIdx).concat(activeItems.slice(itemIdx + 1, metrics.length));
    setActiveItems(currentActiveItems);
    if (onChange) onChange(currentActiveItems);
  };

  return (
    <div ref={ref} className={edit ? 'AdobeMetric multiselect_input edit' : 'AdobeMetric multiselect_input'}>
      <ul className="input_box" onClick={(e) => !edit && setEdit(!edit)}>
        {active.map((item, idx) => (
          <li key={`item_${name}_${item}_${idx}`}>
            <span>{item.name}</span>
            {edit === true && <button type="button" onClick={(e) => onRemoveActiveItems(idx)}></button>}
          </li>
        ))}
      </ul>
      <div className="menu">
        <div className="search">
          <input
            type="text"
            placeholder="Search or add new"
            ref={(input) => input && input.focus()}
            value={searchVal}
            onChange={(e) => setSearchValue(e.target.value)}
          />
        </div>

        <div className="results">
          <ul>
            {results.map((item, idx) => (
              <li
                key={`result_${name}_${item}_${idx}`}
                className={hoverIdx === idx ? (active.findIndex((a) => a.client_id === item.client_id) >= 0 ? 'over active' : 'over') : ''}
                onMouseOver={() => setHoverIndex(idx)}
                onClick={() => onAddActiveItems(item)}
              >
                {item.name}
                <div className="category">{item.calculated === true && <div className="tag">calculated</div>}</div>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export { AdobeMetricSelector };
