import React, { useCallback, useEffect } from "react";

import { history } from "../../utils";

import * as DataTypes from "store/types";

import { PublicPageProps } from "comps/pages";
import { useKeycloak } from "@react-keycloak/web";

interface IProps extends PublicPageProps {
  loginRequest: (slug: string, email: string, password: string) => void;
  formLoading: boolean;
  formError: any;
  account: DataTypes.Account | null;
  location: any;
}

const Login: React.FC<IProps> = () => {
  const { keycloak, initialized } = useKeycloak();

  const login = useCallback(() => {
    const host = window.location.host;
    const subdomain = host.split(".")[0];

    let redirectUri = `${process.env.REACT_APP_HTTP_PROTO}://${subdomain}.${process.env.REACT_APP_KEYCLOAK_REDIRECT_PATH}`;
    const search = new URLSearchParams(window.location.search);
    if (search.get("redirect")) {
      redirectUri = `${redirectUri}/${search.get("redirect")}`;
    }

    keycloak?.login({
      redirectUri,
    });
  }, [keycloak]);

  useEffect(() => {
    if (!keycloak || !initialized) {
      return;
    }

    if (keycloak.authenticated) {
      return history.push("/");
    }

    login();
  }, [keycloak, initialized]);

  return (
    <div>
      <p>Loading...</p>
    </div>
  );
};

export { Login };
