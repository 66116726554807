import { ApiClient } from './client/api_client';

class Tasks {
  static async index() {
    return await ApiClient.get('/v1/account/task');
  }

  static async destroy(id: string) {
    return await ApiClient.delete(`/v1/account/task/${id}`);
  }
}

export { Tasks };
