import React, { Component } from "react";
import { connect } from "react-redux";
import { HTTPCodes, ReportTypes } from "config/app";
import { ApplicationState } from "store/types";
import * as DataTypes from "store/types";
import { clearAppErrors, showModal } from "store/app/actions";

import {
  createLoadingSelector,
  createErrorMessageSelector,
} from "store/selectors";
import { SelectInput } from "ui";
import { AppPage, AppPageProps } from "comps/pages";
import { history } from "utils";
import { MastHead, Details, Insights, ReportSelector } from "./comps/show";
import * as Modals from "./comps/show/modals";
import {
  getAppMetricsRequest,
  getAccountTraitsRequestSaga,
} from "store/account/actions";
import { getExperimentsTraitsRequest } from "store/experiment_trait/actions";

import {
  getExperimentRequest,
  deleteExperimentRequest,
  createReportRequest,
  updateReportRequest,
  deleteExperienceRequest,
  onLinkInsightsRequest,
  onUnLinkInsightsRequest,
  onCreateReportNextStepRequest,
  onDeleteReportRequest,
  onUpdateReportStatsRequest,
  preUploadReportRequest,
  uploadReportRequest,
  updateABExperimentRequest,
  updateAdobeExperimentRequest,
  addUploadRequest,
  deleteUploadRequest,
  uploadInsightsRequest,
  addProgramGoalRequest,
  deleteProgramGoalRequest,
  updateProgramGoalRequest,
} from "store/experiment/actions";
import {
  deleteCustomDataVizRequest,
  updateCustomDataVizMetricAggregationRequest,
  updateCustomDataVizRequest,
} from "store/custom_data_viz/actions";

enum ViewType {
  DETAILS = "detailReports",
  RESULTS = "results",
  INSIGHTS = "insights",
}

enum PageActions {
  NONE = "none",
  DELETE = "delete",
  DELETE_INSIGHT = "delete_insight",
  CLONE = "clone",
  ADD_REPORT = "add_report",
  DELETE_REPORT = "delete_report",
  LINK_INSIGHT = "link_insight",
  VIEW_ATTACHMENT = "view_attachements",
  UPDATE_REPORT_STATS = "update_report_stats",
  IMPORT_CSV = "import_csv",
  UPLOAD_INSIGHTS = "upload_insights",
  DELETE_DATA_VIZ = "delete_data_viz",
  DELETE_PROGRAM_GOAL = "delete_program_goal",
}

interface IProps extends AppPageProps {
  router: any;
  account: DataTypes.Account | null;
  computedMatch: any;
  clearAppErrors: () => void;
  getAppMetricsRequest: (type: string, rsid: string) => void;
  getExperimentRequest: (id: string) => void;
  updateExperimentRequest: (id: string, body: any) => void;
  updateABExperimentRequest: (id: string, body: any) => void;
  updateAdobeExperimentRequest: (id: string, body: any) => void;
  deleteExperienceRequest: (id: string, body: any) => void;
  onDeleteReportRequest: (experiment_id: string, report_id: string) => void;
  onUpdateReportStatsRequest: (
    experiment_id: string,
    report_id: string
  ) => void;
  deleteExperimentRequest: (id: string) => void;
  updateReportRequest: (id: string, body: any) => void;
  updateCustomDataVizRequest: (
    experimentId: string,
    customDataVizId: string,
    body: any
  ) => void;
  deleteCustomDataVizRequest: (
    experimentId: string,
    customDataVizId: string
  ) => void;
  createReportRequest: (id: string, body: any, callback?: any) => void;
  onLinkInsightsRequest: (id: string, insights: any) => void;
  onUnLinkInsightsRequest: (id: string, insightId: string) => void;
  onCreateReportNextStepRequest: (
    experiment: DataTypes.Experiment,
    reportType: string,
    step: number
  ) => void;
  showModal: (component: React.ComponentType<any>, options: any) => void;
  preUploadReportRequest: (
    experiment: DataTypes.Experiment,
    file: File,
    callback: any
  ) => void;
  uploadReportRequest: (
    experiment: DataTypes.Experiment,
    file: File,
    name: string,
    changedMetrics: any
  ) => void;
  getExperimentsTraitsRequest: () => void;
  addUploadRequest: (experimentId: string, file: File) => void;
  deleteUploadRequest: (experimentId: string, uploadId: string) => void;
  getAccountTraitsRequestSaga: () => void;
  uploadInsightsRequest: (experimentId: string, file: any) => void;
  deleteProgramGoalRequest: (
    experimentId: string,
    program_goal_id: string
  ) => void;
  loading: boolean;
  preUploadLoading: boolean;
  insightsLoading: boolean;
  loadError: any;
  experiment: DataTypes.Experiment | null;
  experimentTrait: any;
  createReportLoading: boolean;
  updateCustomDataVizMetricAggregationRequest: (
    experimentId: string,
    aggregationId: string,
    body: any
  ) => void;
  addProgramGoalRequest: (experimentId: string, program_goal: any) => void;
  updateProgramGoalRequest: (
    experimentId: string,
    program_goal: any,
    body: any
  ) => void;
}

interface IState {
  selectedExperience: number;
  loadingReport: boolean;
  uploadReportError: any;
}

class Experiment extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      selectedExperience: 0,
      loadingReport: false,
      uploadReportError: null,
    };
  }

  componentDidMount = () => {
    this.props.getExperimentsTraitsRequest();
    this.props.getAccountTraitsRequestSaga();
    this.props.getExperimentRequest(this.props.computedMatch.params.id);
  };

  private getActiveReport = (
    reports: Array<DataTypes.Report> = [],
    customDataViz: DataTypes.CustomDataViz[] = [],
    id: string
  ) => {
    if (customDataViz) {
      if (id) {
        const dataViz = customDataViz.find((d) => d.id === id);

        if (dataViz) {
          return dataViz;
        }
      }
    }

    if (reports) {
      if (id) {
        const report = reports.find((r) => r.id === id);

        if (report) {
          return report;
        }
      }
    }

    return reports[0] || customDataViz[0] || null;
  };

  private setPageAction = (
    action: PageActions,
    experiment: DataTypes.Experiment | null,
    report?: DataTypes.Report | DataTypes.CustomDataViz,
    insight?: DataTypes.Insight,
    programGoal?: any
  ) => {
    this.props.clearAppErrors();

    // Show delete modal
    if (action === PageActions.DELETE) {
      this.props.showModal(Modals.Delete, {
        experiment: experiment,
        onSubmit: this.onDeleteExperiment,
      });
    }

    // Show delete insight modal
    if (action === PageActions.DELETE_INSIGHT) {
      this.props.showModal(Modals.DeleteInsight, {
        experiment: experiment,
        insight: insight,
        onSubmit: this.unLinkInsight,
      });
    }

    // Show clone modal
    if (action === PageActions.CLONE) {
      this.props.showModal(Modals.Clone, {
        experiment: experiment,
        onSubmit: this.onCloneExperiment,
      });
    }

    // Show create report modal
    if (action === PageActions.ADD_REPORT) {
      this.props.showModal(Modals.AddReportOptions, {
        className: "CreateReport",
        experiment: experiment,
        step: 1,
        onSubmit: this.onCreateReport,
        onNextStep: this.onReportNextStep,
        metrics: experiment?.adobe?.available_metrics,
      });
    }

    // Show link insight
    if (action === PageActions.LINK_INSIGHT) {
      this.props.showModal(Modals.LinkInsights, {
        className: "LinkInsights",
        onSubmit: this.onLinkInsights,
        onCreateNewInsight: this.onCreateNewInsight,
      });
    }

    // Show uploads
    if (action === PageActions.VIEW_ATTACHMENT) {
      this.props.showModal(Modals.Uploads, {
        className: "Uploads",
        experiment: experiment,
        currentUser: this.props.currentUser,
        onUpload: this.onUploadAttachment,
        onDelete: this.onDeleteAttachment,
      });
    }

    // Delete Report
    if (action === PageActions.DELETE_REPORT) {
      this.props.showModal(Modals.DeleteReport, {
        className: "DeleteReport",
        report: report,
        onSubmit: this.onDeleteReport,
      });
    }

    // Delete Data Viz
    if (action === PageActions.DELETE_DATA_VIZ) {
      this.props.showModal(Modals.DeleteCustomDataViz, {
        className: "DeleteReport",
        dataViz: report,
        onSubmit: this.onDeleteDataViz,
      });
    }

    if (action === PageActions.UPDATE_REPORT_STATS) {
      this.props.showModal(Modals.UpdateReportStats, {
        className: "UpdateReportStats",
        experiment: experiment,
        report: report,
        loading: false,
        onUpdate: this.onUpdateReportStats,
      });
    }

    if (action === PageActions.IMPORT_CSV) {
      this.props.showModal(Modals.PreUploadCSV, {
        className: "CreateReport",
        experiment: experiment,
        onSubmit: this.onPreUploadCSV,
        error: this.state.uploadReportError,
      });
    }

    if (action === PageActions.UPLOAD_INSIGHTS) {
      this.props.showModal(Modals.InsightsCsv, {
        account: this.props.account,
        experiment: experiment,
        uploadLoading: false,
        resourceName: "experiment",
        onSubmit: this.onInsightsCsvUpload,
      });
    }

    if (action === PageActions.DELETE_PROGRAM_GOAL) {
      this.props.showModal(Modals.DeleteProgramGoal, {
        goal: programGoal,
        saving: false,
        onSubmit: this.deleteProgramGoal,
      });
    }
  };

  private deleteProgramGoal = (goal: any) => {
    if (!goal) {
      return;
    }

    this.props.deleteProgramGoalRequest(
      this.props.computedMatch.params.id,
      goal.id
    );
  };

  private onInsightsCsvUpload = (file: any) => {
    if (!file || !this.props.experiment?.id) {
      return;
    }

    this.props.uploadInsightsRequest(this.props.experiment.id, file);
  };

  private onPreUploadCSV = (name: string, file: any) => {
    if (!this.props.experiment) {
      return;
    }

    this.props.preUploadReportRequest(
      this.props.experiment,
      file,
      (missingMetrics: any) => this.preUploadSuccess(name, file, missingMetrics)
    );
  };

  private preUploadSuccess = (
    name: string,
    file: File,
    missingMetrics: any
  ) => {
    if (missingMetrics.length === 0) {
      this.onUploadCSV(name, file, {});
      return;
    }

    this.props.showModal(Modals.UploadCSV, {
      className: "",
      onSubmit: this.onUploadCSV,
      existingMetrics: this.props.account?.traits.metrics,
      missingMetrics,
      name,
      file,
    });
  };

  private onUploadCSV = (name: string, file: File, changedMetrics: any) => {
    this.setState({ uploadReportError: "oi" });
    if (!this.props.experiment) {
      return;
    }

    this.props.uploadReportRequest(
      this.props.experiment,
      file,
      name,
      changedMetrics
    );
  };

  private onDeleteReport = (report: DataTypes.Report) => {
    if (report.id) {
      this.props.onDeleteReportRequest(
        this.props.computedMatch.params.id,
        report.id
      );
      this.moveToFirstReport(report.id);
    }
  };

  private onDeleteDataViz = (dataViz: DataTypes.CustomDataViz) => {
    if (dataViz.id) {
      this.props.deleteCustomDataVizRequest(
        this.props.computedMatch.params.id,
        dataViz.id
      );
      this.moveToFirstReport(dataViz.id);
    }
  };

  private moveToFirstReport = (deletedReportId: string) => {
    const reports = this.props.experiment?.reports || [];
    const dataViz = this.props.experiment?.custom_data_viz || [];

    if (reports.length !== 0) {
      if (reports[0].id === deletedReportId) {
        if (reports.length >= 2) {
          this.changeReport(reports[1].id);
          return;
        }
      } else {
        this.changeReport(reports[0].id);
      }
    }

    if (dataViz.length !== 0) {
      if (dataViz[0].id === deletedReportId) {
        if (dataViz.length >= 2) {
          this.changeReport(dataViz[1].id);
          return;
        }
      } else {
        this.changeReport(dataViz[0].id);
      }
    }
  };

  private onUpdateReportStats = (report: DataTypes.Report) => {
    if (report.id) {
      this.props.onUpdateReportStatsRequest(
        this.props.computedMatch.params.id,
        report.id
      );
    }
  };

  private onDeleteExperiment = () => {
    this.props.deleteExperimentRequest(this.props.computedMatch.params.id);
  };

  private onReportNextStep = (
    reportType: string,
    step: number,
    csv: boolean
  ) => {
    const { experiment } = this.props;

    if (experiment) {
      if (csv) {
        this.props.showModal(Modals.PreUploadCSV, {
          className: "CreateReport",
          experiment: experiment,
          onSubmit: this.onPreUploadCSV,
        });

        return;
      }

      if (reportType === ReportTypes.CUSTOM_DATA_VIZ) {
        this.props.showModal(Modals.CreateCustomDataViz, {
          className: "CreateReport",
          experiment: experiment,
        });
      }

      this.props.onCreateReportNextStepRequest(experiment, reportType, step);
    }
  };

  private onCloneExperiment = () => {};

  private onUploadAttachment = (file: any) => {
    const { experiment } = this.props;

    if (!experiment) {
      return;
    }

    const experimentId = experiment.id || "";
    this.props.addUploadRequest(experimentId, file.file);
  };

  private onDeleteAttachment = (upload: DataTypes.Upload) => {
    const { experiment } = this.props;

    if (!experiment) {
      return;
    }

    const experimentId = experiment.id || "";
    const uploadId = upload.id || "";

    this.props.deleteUploadRequest(experimentId, uploadId);
  };

  private onLinkInsights = (insights: Array<DataTypes.Insight>) => {
    const { experiment } = this.props;

    if (!experiment) {
      return;
    }

    this.props.onLinkInsightsRequest(
      this.props.computedMatch.params.id,
      insights
    );
  };

  private unLinkInsight = (insight: DataTypes.Insight) => {
    const { experiment } = this.props;

    if (!experiment) {
      return;
    }

    this.props.onUnLinkInsightsRequest(
      this.props.computedMatch.params.id,
      insight.id
    );
  };

  private onCreateNewInsight = () => {
    const { experiment } = this.props;
    if (experiment) {
      history.push(`/insights/create?experiment=${experiment.id}`);
    }
  };

  private onCreateReport = (name: string, type: string, options: any) => {
    const { experiment } = this.props;
    if (experiment) {
      const { reports } = experiment;
      reports.push({ name: name, type: type, metrics: [], ...options });
      this.props.createReportRequest(this.props.computedMatch.params.id, {
        experiment: { reports },
      });
    }
  };

  private onDetailsChange = (key: string, value: string | Array<string>) => {
    const experimentId = this.props.computedMatch.params.id;

    if (this.props.experiment?.type === "Experiment::Ab") {
      this.props.updateABExperimentRequest(experimentId, {
        experiment: { [key]: value },
      });
      return;
    }

    if (this.props.experiment?.type === "Experiment::Adobe") {
      this.props.updateAdobeExperimentRequest(experimentId, {
        experiment: { [key]: value },
      });
    }
  };

  private onExperienceChange = (experience: DataTypes.Experience) => {
    const { experiment } = this.props;
    if (experiment) {
      const { experiences } = experiment;
      let expIdx = experiences.findIndex((ex) => ex.id === experience.id);
      if (expIdx >= 0) {
        experiences[expIdx] = experience;
      } else {
        experiment.experiences.push(experience);
      }
      this.props.updateExperimentRequest(this.props.computedMatch.params.id, {
        experiment: { experiences: experiences },
      });
    }
  };

  private onOverallWinnerChange = (experience: DataTypes.Experience) => {
    const { experiment } = this.props;
    if (experiment) {
      const { experiences } = experiment;
      experiences.forEach((exp) => {
        exp.overall_winner = false;
      });
      let expIdx = experiences.findIndex((ex) => ex.id === experience.id);
      if (expIdx >= 0) {
        experiences[expIdx].overall_winner = true;
      }
      this.props.updateExperimentRequest(this.props.computedMatch.params.id, {
        experiment: { experiences: experiences },
      });
    }
  };

  private onExperienceDelete = (experience: DataTypes.Experience) => {
    this.props.showModal(Modals.DeleteExperience, {
      experience: experience,
      onSubmit: this.onDeleteExperience,
    });
  };

  private onDeleteExperience = (experience: DataTypes.Experience) => {
    const { experiment } = this.props;
    if (experiment && experiment.experiences) {
      const expIdx = experiment.experiences.findIndex(
        (ex) => ex.id === experience.id
      );
      if (expIdx >= 0) {
        experiment.experiences.splice(expIdx, 1);
        this.props.deleteExperienceRequest(this.props.computedMatch.params.id, {
          experiment: { experiences: experiment.experiences },
        });
      }
    }
  };

  private updateReport = (report: DataTypes.Report) => {
    this.props.updateReportRequest(this.props.computedMatch.params.id, report);
  };

  private updateCustomDataViz = (
    customDataViz: Partial<DataTypes.CustomDataViz>
  ) => {
    this.props.updateCustomDataVizRequest(
      this.props.computedMatch.params.id,
      this.props.computedMatch.params.report_id,
      customDataViz
    );
  };

  private updateCustomDataVizMetric = (metricId: string, body: any) => {
    this.props.updateCustomDataVizMetricAggregationRequest(
      this.props.computedMatch.params.id,
      metricId,
      body
    );
  };

  private changeReport = (value: any) => {
    this.setState({ loadingReport: true }, () => {
      history.push(
        "/tests/" + this.props.computedMatch.params.id + "/results/" + value.id
      );
      this.setState({ loadingReport: false });
    });
  };

  private viewImage = (
    experience: DataTypes.Experience,
    image: DataTypes.Image
  ) => {
    history.push(
      "/tests/" +
        this.props.computedMatch.params.id +
        "/experiences/" +
        experience.id +
        "/images/" +
        image.id
    );
  };

  render() {
    const { loading, loadError, experiment, account, experimentTrait } =
      this.props;
    const { view, report_id } = this.props.computedMatch.params;

    // Handle Load Error (raise not found)
    if (loadError && loadError.statusCode === HTTPCodes.NOT_FOUND)
      throw loadError;
    if (this.props.createReportLoading) {
      return null;
    }

    // Get active report
    const activeReport = this.getActiveReport(
      experiment?.reports,
      experiment?.custom_data_viz,
      report_id
    );

    if (account === null) return null;

    return (
      <div className="Experiment">
        <MastHead
          currentUser={this.props.currentUser}
          loading={loading}
          experiment={experiment}
          navIndex={
            view === ViewType.RESULTS ? 1 : view === ViewType.INSIGHTS ? 2 : 0
          }
          onClone={() => this.setPageAction(PageActions.CLONE, null)}
          onDelete={() => this.setPageAction(PageActions.DELETE, null)}
          onChange={this.onDetailsChange}
          onViewAttachment={() =>
            this.setPageAction(PageActions.VIEW_ATTACHMENT, experiment)
          }
        />

        {loading === false &&
          experiment &&
          view !== ViewType.RESULTS &&
          view !== ViewType.INSIGHTS && (
            <Details
              currentUser={this.props.currentUser}
              account={account}
              traits={experimentTrait?.traits}
              experiment={experiment}
              onChange={this.onDetailsChange}
              onExperienceChange={this.onExperienceChange}
              onExperienceDelete={this.onExperienceDelete}
              onOverallWinnerChange={this.onOverallWinnerChange}
              onViewImage={this.viewImage}
              onAddProgramGoal={this.props.addProgramGoalRequest}
              onUpdateProgramGoal={this.props.updateProgramGoalRequest}
              onDeleteProgramGoal={(goal: any) => {
                this.setPageAction(
                  PageActions.DELETE_PROGRAM_GOAL,
                  null,
                  undefined,
                  undefined,
                  goal
                );
              }}
            />
          )}

        {loading === false &&
          experiment &&
          view === ViewType.RESULTS &&
          experiment.reports.length === 0 &&
          experiment.custom_data_viz.length === 0 && (
            <ReportEmptyState
              currentUser={this.props.currentUser}
              onAddReport={() =>
                this.setPageAction(PageActions.ADD_REPORT, experiment)
              }
              onUploadReport={() =>
                this.setPageAction(PageActions.IMPORT_CSV, experiment)
              }
            />
          )}

        {loading === false &&
          experiment &&
          view === ViewType.RESULTS &&
          activeReport && (
            <div className="Results">
              <section className="Header">
                <div className="left">
                  {this.props.currentUser.role !== "read-only" && (
                    <button
                      type="button"
                      className="btn btn-primary"
                      onClick={() =>
                        this.setPageAction(PageActions.ADD_REPORT, experiment)
                      }
                    >
                      <i className="fa fa-plus" />
                      Add Report
                    </button>
                  )}
                </div>

                <div className="right">
                  <div className="form-group">
                    <label>select report</label>
                    <SelectInput
                      ignoreReadOnly={true}
                      value={activeReport.name}
                      name="report_selector"
                      placeholder="select report"
                      itemKey="name"
                      items={[
                        ...experiment.reports,
                        ...experiment.custom_data_viz,
                      ]}
                      onChange={this.changeReport}
                    />
                  </div>
                </div>
              </section>
              {!this.state.loadingReport && this.props.experiment && (
                <ReportSelector
                  reportId={activeReport.id || report_id}
                  experiment={this.props.experiment}
                  currentUser={this.props.currentUser}
                  onUpdateReport={this.updateReport}
                  onOpenUpdateDataVizModal={(
                    dataViz: DataTypes.CustomDataViz
                  ) => {
                    this.props.showModal(Modals.UpdateCustomDataViz, {
                      className: "CreateReport",
                      experiment: experiment,
                      dataViz,
                    });
                  }}
                  onDeleteReport={() =>
                    this.setPageAction(
                      PageActions.DELETE_REPORT,
                      null,
                      activeReport as DataTypes.Report
                    )
                  }
                  onDeleteDataViz={() => {
                    this.setPageAction(
                      PageActions.DELETE_DATA_VIZ,
                      null,
                      activeReport as DataTypes.CustomDataViz
                    );
                  }}
                  onUpdateReportStats={() =>
                    this.setPageAction(
                      PageActions.UPDATE_REPORT_STATS,
                      experiment,
                      activeReport as DataTypes.Report
                    )
                  }
                  onUpdateDataVizMetric={this.updateCustomDataVizMetric}
                  onUpdateCustomDataViz={this.updateCustomDataViz}
                />
              )}
            </div>
          )}

        {loading === false &&
          experiment &&
          view === ViewType.INSIGHTS &&
          experiment.insights.length === 0 && (
            <InsightsEmptyState
              createOnly={account.summary.totals.insights > 0 ? false : true}
              experiment={experiment}
              onAddInsight={() =>
                this.setPageAction(PageActions.LINK_INSIGHT, null)
              }
              onUploadInsight={() =>
                this.setPageAction(PageActions.UPLOAD_INSIGHTS, null)
              }
            />
          )}

        {loading === false &&
          experiment &&
          view === ViewType.INSIGHTS &&
          experiment.insights.length > 0 && (
            <Insights
              currentUser={this.props.currentUser}
              account={account}
              experiment={experiment}
              onChange={this.changeReport}
              onAddInsight={() =>
                this.setPageAction(PageActions.LINK_INSIGHT, null)
              }
              onDelete={(insight: DataTypes.Insight) =>
                this.setPageAction(
                  PageActions.DELETE_INSIGHT,
                  experiment,
                  undefined,
                  insight
                )
              }
              onUploadInsight={() =>
                this.setPageAction(PageActions.UPLOAD_INSIGHTS, null)
              }
            />
          )}
      </div>
    );
  }
}

const loadingSelector = createLoadingSelector([
  "@@experiment/GET_EXPERIMENT",
  "@@experiment_traits/GET_EXPERIMENTS_TRAITS",
]);
const loadingError = createErrorMessageSelector([
  "@@experiment/GET_EXPERIMENT",
]);
const preUploadSelector = createLoadingSelector([
  "@@experiment/PRE_UPLOAD_REPORT",
]);
const creatingReportSelector = createLoadingSelector([
  "@@report_summary/CREATE_REPORT_SUMMARY",
]);

const mapStateToProps = ({
  router,
  app,
  account,
  experiment,
  experimentTrait,
}: ApplicationState) => ({
  router,
  account,
  experiment,
  experimentTrait,
  loading: loadingSelector(app.requests),
  loadError: loadingError(app.errors),
  preUploadLoading: preUploadSelector(app.requests),
  createReportLoading: creatingReportSelector(app.requests),
});

const mapDispatchToProps = {
  getAccountTraitsRequestSaga,
  clearAppErrors,
  showModal,
  getExperimentRequest,
  updateABExperimentRequest,
  updateAdobeExperimentRequest,
  updateReportRequest,
  deleteExperimentRequest,
  deleteExperienceRequest,
  createReportRequest,
  onLinkInsightsRequest,
  onUnLinkInsightsRequest,
  uploadInsightsRequest,
  getAppMetricsRequest,
  onCreateReportNextStepRequest,
  onDeleteReportRequest,
  onUpdateReportStatsRequest,
  preUploadReportRequest,
  uploadReportRequest,
  getExperimentsTraitsRequest,
  addUploadRequest,
  deleteUploadRequest,
  updateCustomDataVizRequest,
  deleteCustomDataVizRequest,
  updateCustomDataVizMetricAggregationRequest,
  addProgramGoalRequest,
  deleteProgramGoalRequest,
  updateProgramGoalRequest,
};

const connectedPage = connect(
  mapStateToProps,
  mapDispatchToProps
)(AppPage(Experiment));

export { connectedPage as Experiment };

interface IReportEmptyStateProps {
  onAddReport(): void;
  onUploadReport(): void;
  currentUser: any;
}

const ReportEmptyState = ({
  onAddReport,
  onUploadReport,
  currentUser,
}: IReportEmptyStateProps) => {
  return (
    <div className="Results empty-state">
      <div className="wrapper">
        <img src="/img/reports_empty.png" alt="add new report" />
        <h2>Test Results</h2>
        <p>
          Add data analysis and update the results by creating reports for your
          test. These results will be displayed in the case study. Get started
          by clicking the `Add Report` button below.
        </p>

        {currentUser.role !== "read-only" && (
          <button className="btn btn-primary" onClick={onAddReport}>
            Add Report
          </button>
        )}
      </div>
    </div>
  );
};

interface IInsightsEmptyStateProps {
  onAddInsight(): void;
  onUploadInsight(): void;
  createOnly: boolean;
  experiment: DataTypes.Experiment;
}

const InsightsEmptyState = ({
  onAddInsight,
  onUploadInsight,
  createOnly,
  experiment,
}: IInsightsEmptyStateProps) => {
  return (
    <div className="Insights empty-state">
      <div className="wrapper">
        {createOnly === true ? (
          <React.Fragment>
            <img src="/img/bolt_grey.png" alt="insight"></img>
            <h2>Insights</h2>
            <p>
              illuminate helps you uncover and manage customer insights that
              help you build lasting relationships. Create a confidence-building
              feedback loop by attaching your tests to insights
            </p>
            <button
              className="btn btn-primary-light"
              onClick={() =>
                history.push(`/insights/create?experiment=${experiment.id}`)
              }
            >
              <i className="fa fa-plus" />
              Create Insight
            </button>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <img src="/img/bolt_grey.png" alt="insight"></img>
            <h2>Attach Your Insights or Create New Ones</h2>
            <p>
              illuminate helps you uncover and manage customer insights that
              help you build lasting relationships. Create a confidence-building
              feedback loop by attaching your tests to insights
            </p>
            <p>
              By attaching tests to your insights, you’ll be able to track
              supporting evidence that can give you confidence. Use the insights
              in your library, or add something new. Start by using the button
              below.
            </p>

            <button
              style={{ marginRight: "25px" }}
              className="btn btn-primary-light"
              onClick={() =>
                history.push(`/insights/create?experiment=${experiment.id}`)
              }
            >
              <i className="fa fa-plus" />
              New Insight
            </button>

            <button className="btn btn-primary-light" onClick={onAddInsight}>
              <i className="fa fa-link" />
              Existing Insight
            </button>

            <button
              className="btn btn-primary-light"
              type="button"
              style={{ marginLeft: "25px" }}
              onClick={onUploadInsight}
            >
              <i className="fa fa-upload" />
              Batch Upload
            </button>
          </React.Fragment>
        )}
      </div>
    </div>
  );
};
