import React, { Component } from "react";
import * as DataTypes from "store/types";
import { Form, Input, Button, ValidatorTypes } from "comps/form";
import { createReportAnalyticsRequest } from "store/report_analytics/actions";
import { ApplicationState } from "store/types";
import { connect } from "react-redux";
import { createLoadingSelector } from "store/selectors";
import { AdobeMetricSelector } from "ui/adobe_metric_selector";

interface IProps {
  experiment: DataTypes.Experiment;
  saving: boolean;
  metrics?: Array<any>;
  createReportAnalyticsRequest(experimentId: string, body: any): void;
}

interface IState {
  selectedAdobeMetrics: Array<DataTypes.AdobeMetric>;
}

class AddAnalyticstReport extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      selectedAdobeMetrics: [],
    };
  }

  private setAdobeMetrics = (metrics: Array<DataTypes.AdobeMetric>) => {
    this.setState({ ...this.state, selectedAdobeMetrics: metrics });
  };

  private onSubmit = (valid: boolean, dirty: boolean, data: any) => {
    const experimentId = this.props.experiment.id;

    if (!valid) {
      return;
    }

    const { selectedAdobeMetrics } = this.state;
    if (selectedAdobeMetrics.length > 0) {
      this.props.createReportAnalyticsRequest(experimentId || "", {
        metrics: selectedAdobeMetrics.map((m) => ({
          ...m,
          metric_type: DataTypes.MetricType.FLOAT,
        })),
        name: data.name.value,
      });
    }
  };

  render() {
    const { saving, metrics } = this.props;
    const { selectedAdobeMetrics } = this.state;

    return (
      <React.Fragment>
        <div className="header">
          <div className="d-flex">
            <div>
              <img
                src="/img/adobe_analytics.png"
                className="mr-2"
                alt="adobe analytics"
              />
            </div>
            <h3>Adobe Analytics Report</h3>
          </div>
          <p>
            Use the form below to create a new report using Adobe Analytics
            data.
          </p>
        </div>
        <Form
          formFields={{
            name: {
              name: "name",
              value: "",
              validator: {
                type: ValidatorTypes.REQUIRED,
                messages: { required: "Please enter a report name." },
              },
            },
          }}
          onSubmit={this.onSubmit}
          FormComponent={({ fields: { name }, onChange }) => (
            <React.Fragment>
              <div className="body">
                <div className="form-group">
                  <label>Report name</label>
                  <Input
                    field={name}
                    className="form-control"
                    onChange={(e) => onChange(name, e.target.value)}
                  />
                </div>
                <div className="form-group">
                  <label>Select Adobe Analytics Metrics</label>
                  <AdobeMetricSelector
                    name="adobe_selector"
                    metrics={metrics || []}
                    activeItems={selectedAdobeMetrics}
                    onChange={(value) => this.setAdobeMetrics(value)}
                  />
                </div>
              </div>
              <div className="cntrl-bar">
                <Button
                  className="btn btn-primary"
                  text="create report"
                  isLoading={saving}
                />
              </div>
            </React.Fragment>
          )}
        />
      </React.Fragment>
    );
  }
}

const loadingSelector = createLoadingSelector([
  "@@report_summary/CREATE_REPORT_SUMMARY",
]);

const mapStateToProps = ({ app, router }: ApplicationState) => ({
  saving: loadingSelector(app.requests),
  router,
});

const mapDispatchToProps = {
  createReportAnalyticsRequest,
};

const connectedPage = connect(
  mapStateToProps,
  mapDispatchToProps
)(AddAnalyticstReport);

export { connectedPage as AddAnalyticstReport };
