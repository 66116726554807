import React from "react";
import * as DataTypes from "../store/types";

interface IAvatarProps {
  isIlluminate?: boolean;
  user?: DataTypes.User;
  className?: String;
  showHover?: Boolean;
}

const getInitials = (user: DataTypes.User) => {
  if (user.first_name) {
    return (
      (user.first_name[0] || user.firstName[0]) +
      (user.last_name[0] || user.lastName[0])
    );
  }

  return user.firstName[0] + user.lastName[0];
};

export const Avatar = ({
  user,
  className = "",
  showHover = false,
  isIlluminate = false,
}: IAvatarProps) => {
  if (isIlluminate) {
    return (
      <div className="Avatar">
        <div>
          <div className={`user ${className}`}>
            <img src="/img/icon_only.png" className="user" alt="user" />
          </div>
        </div>

        {showHover && <div className="user-name">illuminate</div>}
      </div>
    );
  }
  if (!user) {
    return null;
  }

  return (
    <div className="Avatar">
      <div>
        <div className={`user ${className}`}>
          {user.image_url ? (
            <img
              src={user.image_url || user.imageUrl}
              className="user"
              alt="user"
            />
          ) : (
            getInitials(user)
          )}
        </div>
      </div>

      {showHover && (
        <div className="user-name">
          {user.first_name || user.firstName} {user.last_name || user.lastName}
        </div>
      )}
    </div>
  );
};
