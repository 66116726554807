import React, { Component } from 'react';
import Annotation from 'react-image-annotation';
import * as DataTypes from 'store/types';

enum SelectionType {
  RECTANGLE = 'RECTANGLE'
}

interface IProps {
  image: DataTypes.Image;
  disabled: boolean;
  onDraw?(annotation: any): void;
  annotation?: any;
}

interface IState {
  annotations: Array<any>;
}

class AnnotateImage extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    let annotations: Array<DataTypes.Annotation> = [];

    this.props.image.comments &&
      this.props.image.comments.forEach(comment => {
        comment.annotations &&
          comment.annotations.forEach(anno => {
            if (anno.geometry) annotations.push(anno);
          });
      });

    this.state = {
      annotations: annotations || []
    };
  }

  componentDidUpdate = (prevProps: IProps) => {
    if (prevProps.image.comments && this.props.image.comments) {
      let annotations: Array<DataTypes.Annotation> = [];

      this.props.image.comments &&
        this.props.image.comments.forEach(comment => {
          comment.annotations &&
            comment.annotations.forEach(anno => {
              if (anno.geometry) annotations.push(anno);
            });
        });

      if (annotations.length !== this.state.annotations.length) {
        this.setState({ ...this.state, annotations: annotations || [] });
      }
    }
  };

  private onChange = (annotation: any) => {
    if (this.props.onDraw) {
      this.props.onDraw({ ...annotation, data: { id: Math.random() } });
    }
  };

  private renderSelector = ({ annotation }: any) => {
    const { annotations } = this.state;
    const { geometry } = annotation;
    if (!geometry) return null;

    let annos = annotations.filter(x => x.geometry !== null && x.geometry.height !== null);
    return <Box geometry={geometry} index={annos.length} />;
  };

  private renderHighlight = ({ annotation }: any) => {
    const { annotations } = this.state;

    const { geometry } = annotation;
    if (!geometry || geometry.height === null) return null;
    const annos = annotations.filter(x => x.geometry && x.geometry !== null && x.geometry.height !== null);
    const idx = annos.findIndex(a => a.data && a.data.id === annotation.data.id);
    if (idx >= 0) {
      return <Box key={annotation.data.id} index={idx} geometry={geometry} />;
    }
  };

  private renderEditor = () => {
    const { annotation } = this.props;
    const { geometry } = annotation;
    if (!geometry) return null;
  };

  private renderContent = ({ key, annotation }: any) => {
    const { annotations } = this.state;
    let idx = annotations.findIndex(a => a.data.id === annotation.data.id);

    return (
      <div key={key} className="anno-content">
        <Box key={annotation.data.id} index={idx} geometry={annotation.geometry} />
      </div>
    );
  };

  render() {
    const { disabled, image, annotation } = this.props;
    const { annotations } = this.state;

    return (
      <div className="AnnotateImage">
        <div className="header">
          {disabled === false && (
            <div className="alert">
              <i className="fas fa-crosshairs" />
              Click anywhere on the image to add an annotation
            </div>
          )}
        </div>

        <div className="img-wrapper">
          <div className="image">
            <Annotation
              src={image.url}
              type={SelectionType.RECTANGLE}
              annotations={annotations}
              annotation={annotation || {}}
              value={annotation}
              disableAnnotation={disabled}
              disableOverlay={true}
              renderSelector={this.renderSelector}
              renderHighlight={this.renderHighlight}
              renderContent={this.renderContent}
              renderEditor={this.renderEditor}
              onChange={this.onChange}
            />
          </div>
        </div>
      </div>
    );
  }
}

interface IBoxProps {
  geometry: any;
  index: number;
}

const Box = ({ geometry, index }: IBoxProps) => (
  <div
    className="box"
    style={{
      left: `${geometry.x}%`,
      top: `${geometry.y}%`,
      height: `${geometry.height}%`,
      width: `${geometry.width}%`
    }}
  >
    <div className="marker">{index + 1}</div>
  </div>
);

export { AnnotateImage };
