import React, { Component } from "react";
import { Validator } from "./validator";
import { FormFields } from "./";
import debounce from "debounce";

interface Props {
  formFields: FormFields;
  FormComponent: React.ComponentType<FormComponentProps<FormFields>>;
  onSubmit?(valid: boolean, dirty: boolean, data: FormFields): void;
  onChange?(dirty: boolean, data: FormFields): void;
}

type FormComponentProps<FormFields> = {
  fields: FormFields;
} & Handlers<FormFields>;

export type OnChangeHandler<FormFields> = <K extends keyof FormFields>(
  s: FormFields[K],
  a: any,
  onChangeCallback?: () => FormFields
) => void;

// This just specifies the Handlers. Parameterized over FormFields like everything else.
interface Handlers<FormFields> {
  onChange: OnChangeHandler<FormFields>;
}

interface State {
  fields: FormFields;
}

class Form extends Component<Props, State> {
  validator: Validator;

  constructor(props: Props) {
    super(props);
    this.state = { fields: props.formFields };
    this.validator = new Validator();
    this.submitCallback = debounce(this.submitCallback, 2000, true);
  }

  public onChange: OnChangeHandler<FormFields> = (
    field,
    value,
    multipleFieldsCallback = () => ({})
  ) => {
    const { fields } = this.state;
    let updated = {
      ...this.state,
      fields: {
        ...fields,
        [field.name]: { ...fields[field.name], value: value },
        ...multipleFieldsCallback(),
      },
    };

    this.setState(updated);
    if (this.props.onChange) {
      this.validator.validateForm(updated.fields);

      this.props.onChange(
        this.isDirty(updated.fields),
        this.buildSubmitObject(updated.fields)
      );
    }
  };

  private onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    this.submitCallback();
  };

  private submitCallback = () => {
    const isValid = this.validator.validateForm(this.state.fields);
    this.setState({ fields: { ...this.state.fields } });
    if (this.props.onSubmit)
      this.props.onSubmit(
        isValid,
        this.isDirty(this.state.fields),
        this.buildSubmitObject(this.state.fields)
      );
  };

  private buildSubmitObject = (fields: FormFields): any => {
    let object = {};
    Object.keys(fields).forEach((key) => {
      Object.assign(object, { [key]: { value: fields[key].value } });
    });
    return object;
  };

  private isDirty = (fields: FormFields): boolean => {
    const { formFields } = this.props;
    let dirty = false;
    Object.keys(formFields).forEach((key) => {
      if (formFields[key].value !== fields[key].value) {
        dirty = true;
      }
    });
    return dirty;
  };

  public render() {
    const { FormComponent } = this.props;
    const { fields } = this.state;

    return (
      <form onSubmit={this.onSubmit}>
        <FormComponent fields={fields} onChange={this.onChange} />
      </form>
    );
  }
}

export { Form };
