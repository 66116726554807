import styled from "styled-components";

export const ChartsContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 2rem;

  div {
    div {
      position: relative;
      grid-column: auto / span 1;

      .chart {
        position: relative;
        max-width: 100%;

        canvas {
          max-width: 100%;
          width: 100%;
        }
      }

      div {
        display: flex;
        align-items: flex-end;
        justify-content: flex-end;

        button {
          width: 3rem;
          border-radius: 3rem;
          height: 3rem;
          padding: 0;

          &:hover {
            background-color: #eaeaea;
            // color: #b0bac7;
            box-shadow: 0px 0px transparent;
          }
        }
      }
    }

    &.focus {
      position: fixed;
      top: 0;
      left: 0;
      z-index: 100000;
      height: 100vh;
      width: 100vw;
      animation: scale-in-center 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
      display: flex;
      align-items: center;
      justify-content: center;

      &:after {
        content: " ";
        background: #757e8e;
        opacity: 0.7;
        position: absolute;
        height: 100vh;
        width: 100vw;
        top: 0;
        left: 0;
        z-index: -1;
      }

      .buttonContainer {
        button {
          &:after {
            display: block;
            content: "esc";
            font-size: 8px;
            position: relative;
            top: -8px;
            margin-top: 4px;
            text-transform: uppercase;
          }
        }
      }

      .chartContainer {
        border-radius: 1rem;
        background-color: white;
        padding: 1rem;
        width: 80vw;
        height: 80vh;

        .chart {
          height: 50vh;
        }
      }
    }
  }
`;

export const DataStoryTellingContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start !important;

  .EditorInput {
    justify-content: flex-start;
  }
`;

export const DataStoryTellingArea = styled.div`
  justify-content: flex-start !important;
`;
