import React, { Component } from 'react';
import '../css/Modal.scss';

interface IModalProps {
  show: boolean;
  className?: string;
  component: React.ComponentClass<any> | undefined;
  onClose?(): void;
}

export class Modal extends Component<IModalProps> {
  escFunction = (event: any) => {
    if (this.props.onClose && event.keyCode === 27) {
      this.props.onClose();
    }
  };

  componentDidMount = () => {
    document.addEventListener('keydown', this.escFunction, false);
  };

  componentWillUnmount = () => {
    document.removeEventListener('keydown', this.escFunction, false);
  };

  render() {
    const { className, show, onClose, component } = this.props;
    const Comp = component || null;

    return (
      <React.Fragment>
        {show === true && (
          <div key="modal" className={className ? `Modal ${className}` : 'Modal'}>
            <div className="modal-box">
              <div className="content">
                {onClose && (
                  <button type="button" className="btn btn-close" onClick={onClose}>
                    <i className="fas fa-times" />
                  </button>
                )}
                {Comp && <Comp key="modal_comp" {...this.props} />}
              </div>
            </div>
            <div className="overlay" />
          </div>
        )}
      </React.Fragment>
    );
  }
}
