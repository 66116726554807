import React, { Component } from 'react';
import { DragDropContext, Draggable, Droppable, DropResult, ResponderProvided } from 'react-beautiful-dnd';
import * as DataTypes from 'store/types';
import TimeAgo from 'timeago-react';
import { InsightConfidenceIcon } from 'ui/insight_confidence';

interface IProps {
  useBBMethods: boolean;
  loading: boolean;
  insights: Array<DataTypes.Insight>;
  selectedIdx: number;
  onSelect(itemIndex: number): void;
  onReorder(id: string, position: number): void;
  reorderable: boolean;
}

class InsightsList extends Component<IProps> {
  onDragEnd = (result: DropResult, provided: ResponderProvided) => {
    const { draggableId: id, destination, reason } = result;

    if (reason !== 'DROP' || !destination) {
      return;
    }

    const { index } = destination;

    this.props.onReorder(id, index);
  }

  getDragStyle = (isDragging: boolean, draggableStyle: any) => ({
    userSelect: 'none',
    ...draggableStyle
  });

  render() {

    const { loading, insights, selectedIdx, onSelect, useBBMethods } = this.props;

    if (loading) {
      return (
        <ul className="ListItem placeholder">
          <LoadingListItems count={7} />
        </ul>
      );
    }

    if (insights && insights.length <= 0) {
      return (
        <div className="NoResults">
          <img src="/img/bolt_grey.png" alt="insight"></img>
          <p>Sorry, no results found. Please try again.</p>
        </div>
      );
    }

    return (
      <React.Fragment>
        <ul className="ListItem">
          <DragDropContext onDragEnd={this.onDragEnd}>
            <Droppable droppableId="1">
              {(provided, snapshot) => (
                <div className="column" ref={provided.innerRef}>
                  {provided.placeholder}

                  {insights &&
                    insights.map((insight: DataTypes.Insight, idx: number) =>
                      <Draggable isDragDisabled={!this.props.reorderable} key={`dragabble_${insight.id}`} index={idx} draggableId={insight.id || ''}>
                        {(provided, snapshot) => (
                          <div
                            className={snapshot.isDragging ? 'item dragging' : 'item'}
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            style={this.getDragStyle(snapshot.isDragging, provided.draggableProps.style)}
                          >
                            <ListItem
                              key={'li_' + insight.id}
                              insight={insight}
                              useBBMethods={useBBMethods}
                              selected={idx === selectedIdx}
                              onItemSelect={() => onSelect(idx)}
                            />
                          </div>
                        )}
                      </Draggable>
                    )}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </ul>
      </React.Fragment>
    );
  }
};

export { InsightsList };

interface IListItemProps {
  useBBMethods: boolean;
  insight: DataTypes.Insight;
  selected: boolean;
  onItemSelect(): void;
}

const ListItem = ({ insight, selected, onItemSelect, useBBMethods }: IListItemProps) => {
  const evidenceCount = () => {
    const { experiments, uxrs } = insight;

    const experimentsCount = experiments?.length || 0;
    const uxrsCount = uxrs?.length || 0;

    return experimentsCount + uxrsCount;
  }

  return (
    <li className={selected === true ? 'active' : ''} onClick={onItemSelect} key={'insight_' + insight.id} id={'insight_' + insight.id} tabIndex={1}>
      <div className="body">
        {useBBMethods === true && insight.altitude && (
          <img
            src={`/img/altitudes/${insight.altitude}.png`}
            alt={insight.altitude}
            style={{ height: '24px', width: '24px', top: '-4px', left: '4px', position: 'relative', float: 'right' }}
          />
        )}
        <p className="insight">{insight.value}</p>
        <div className="status">
          <p className="date">
            <TimeAgo datetime={insight.updated_at} live={false} />
          </p>
          <div className="indicators">
            {evidenceCount() > 0 && (
              <div>
                <p>{evidenceCount()}</p> <i className="fas fa-flask" />
              </div>
            )}
            {insight.star === true && (
              <div>
                <i className="fas fa-star" />
              </div>
            )}
            {insight.key === true && (
              <div>
                <i className="fas fa-key" />
              </div>
            )}
            <div style={{ marginRight: '-13px' }}>
              {useBBMethods && insight.confidence && <InsightConfidenceIcon height={40} width={40} selected={insight.confidence} />}
            </div>
          </div>
        </div>
      </div>
    </li>
  );
};

interface ILoadingListItemProps {
  count: number;
}

const LoadingListItems = ({ count }: ILoadingListItemProps): any => {
  let elem = [];

  for (let i = 0; i < count; i++) {
    elem.push(
      <li key={'li_' + i}>
        <div className="body">
          <p className="insight"></p>
          <p className="insight"></p>
          <p className="insight"></p>
        </div>
      </li>
    );
  }

  return elem;
};
