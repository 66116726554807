import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { ApplicationState } from "store/types";
import { AppPage, AppPageProps } from "comps/pages";
import * as DataTypes from "store/types";
import { history, Helpers } from "utils";
import { createLoadingSelector } from "store/selectors";
import {
  showUxrRequest,
  updateUploadRequest,
  addUploadCommentRequest,
  deleteUploadCommentRequest,
} from "store/uxr/actions";

import { AnnotateImage } from "comps";
import { Avatar, TextInput, RichTextInput, CommentInput } from "ui";

enum ViewType {
  FULL = "full",
  SCALED = "scaled",
}

interface IProps extends AppPageProps {
  computedMatch: any;
  account: DataTypes.Account | null;
  uxr: DataTypes.Uxr | null;
  loading: boolean;
  showUxrRequest: (id: string) => void;
  updateUploadRequest: (uxrId: string, uploadId: string, body: any) => void;
  addUploadCommentRequest: (uxrId: string, uploadId: string, body: any) => void;
  deleteUploadCommentRequest: (
    uxrId: string,
    uploadId: string,
    commentId: string
  ) => void;
}

interface IState {
  view: ViewType;
  showAside: boolean | undefined;
  annotateDisabled: boolean;
  annotation: any;
}

class UxrImages extends Component<IProps, IState> {
  navigationListener: any;

  constructor(props: IProps) {
    super(props);
    this.state = {
      view: ViewType.SCALED,
      showAside: undefined,
      annotateDisabled: true,
      annotation: {},
    };
  }

  componentDidMount = () => {
    this.props.showUxrRequest(this.props.computedMatch.params.id);
    this.navigationListener = history.listen((location: any) => {
      if (history.action === "POP" && this.props.uxr)
        history.replace(`/uxr/${this.props.uxr.id}`);
    });
  };

  componentWillUnmount = () => {
    this.navigationListener();
  };

  private close = () => {
    history.goBack();
  };

  private showAside = (show: boolean | undefined) => {
    let toggle = false;
    if (show !== undefined) {
      toggle = !show;
    }

    let annoDisbled = this.state.annotateDisabled;
    if (toggle === false) annoDisbled = true;

    this.setState({
      ...this.state,
      showAside: toggle,
      annotateDisabled: annoDisbled,
    });
  };

  private onChange = (image: DataTypes.Image, key: string, value: string) => {
    const { uxr } = this.props;

    if (!uxr) {
      return;
    }

    const uxrId = uxr.id || "";
    const imageId = image.id || "";

    this.props.updateUploadRequest(uxrId, imageId, {
      [key]: value,
    });
  };

  private onAddComment = (image: DataTypes.Image, value: string) => {
    const { uxr } = this.props;

    if (!value || !value.trim() || !uxr) {
      return;
    }

    const uxrId = uxr.id || "";
    const uploadId = image.id || "";

    this.props.addUploadCommentRequest(uxrId, uploadId, {
      value,
      annotation: this.state.annotation,
    });

    this.setState({ ...this.state, annotateDisabled: true, annotation: {} });
  };

  private onRemoveComment = (comment: DataTypes.Comment) => {
    const { id: uxrId, image_id: uploadId } = this.props.computedMatch.params;

    this.props.deleteUploadCommentRequest(uxrId, uploadId, comment.id || "");
  };

  private annotateImage = (annotation: any) => {
    if (annotation !== this.state.annotation) {
      this.setState({ ...this.state, annotation: annotation });
    }
    return;
  };

  private toggleImageAnnotation = (enabled: boolean) => {
    this.setState({ ...this.state, annotateDisabled: !enabled });
  };

  render() {
    const { loading, uxr } = this.props;
    const { showAside, annotation, annotateDisabled } = this.state;

    if (loading) return null;

    const image =
      uxr &&
      uxr.uploads.find(
        (image) => image.id === this.props.computedMatch.params.image_id
      );

    if (!uxr || !image)
      return (
        <div>
          <h1>No Image</h1>
        </div>
      );

    return (
      <React.Fragment>
        <div className="FullScreen">
          <div className="logo">
            <img src="/img/logo_sm.svg" alt="illuminate" />
          </div>

          <ul className="controls">
            <li>
              <button
                type="button"
                className="btn btn-ctrl"
                onClick={() => this.showAside(showAside)}
              >
                <i
                  className={
                    showAside !== false
                      ? "fas fa-chevron-right"
                      : "fas fa-chevron-left"
                  }
                ></i>
              </button>
            </li>

            <li>
              <button
                type="button"
                className="btn btn-ctrl"
                onClick={this.close}
              >
                <i className="fas fa-times" />
              </button>
            </li>
          </ul>

          <div
            className={
              showAside === false ? "ImageViewer full-size" : "ImageViewer "
            }
          >
            {showAside === false && (
              <div className="title">
                <label>{uxr.name}</label>
                <h2>{image.name}</h2>
              </div>
            )}

            <AnnotateImage
              key={image.id}
              image={image}
              annotation={annotation}
              disabled={annotateDisabled}
              onDraw={this.annotateImage}
            />

            <div
              className={
                showAside === undefined
                  ? "Aside"
                  : showAside
                  ? "Aside opened"
                  : "Aside closed"
              }
            >
              <div className="title-bar">
                <label>{uxr.name}</label>

                <TextInput
                  value={image.name || ""}
                  placeholder="Add a name here"
                  onChange={(value) => this.onChange(image, "name", value)}
                >
                  <h2>{image.name}</h2>
                </TextInput>

                <RichTextInput
                  value={image.description || ""}
                  placeholder="Add description here"
                  onChange={(value) =>
                    this.onChange(image, "description", value)
                  }
                />
              </div>

              <hr />

              <div className="comments">
                <Comments
                  comments={image.comments}
                  onDelete={this.onRemoveComment}
                />
                <div className="comment-input-wrapper">
                  <CommentInput
                    value={""}
                    annotateOn={!annotateDisabled}
                    onAnnotateToggle={() =>
                      this.toggleImageAnnotation(annotateDisabled)
                    }
                    onSubmit={(value) => this.onAddComment(image, value)}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        {uxr.uploads.length > 1 && (
          <div className="ImageCarousel">
            {uxr.uploads.map((img) => {
              return (
                <div
                  key={["img_", img.id].join("_")}
                  className={img.id === image.id ? "thmb active" : "thmb"}
                >
                  <Link to={`/uxr/${uxr.id}/images/${img.id}`}>
                    <img src={img.url} alt={img.name} />
                  </Link>
                </div>
              );
            })}
          </div>
        )}
      </React.Fragment>
    );
  }
}

const loadingSelector = createLoadingSelector(["@@experiment/GET_EXPERIMENT"]);

const mapStateToProps = ({ router, app, account, uxr }: ApplicationState) => ({
  router: router,
  account: account,
  loading: loadingSelector(app.requests),
  uxr,
});

const mapDispatchToProps = {
  showUxrRequest,
  updateUploadRequest,
  addUploadCommentRequest,
  deleteUploadCommentRequest,
};

const connectedPage = connect(
  mapStateToProps,
  mapDispatchToProps
)(AppPage(UxrImages));

export { connectedPage as UxrImages };

interface ICommentsProps {
  comments?: Array<DataTypes.Comment>;
  onDelete(comment: DataTypes.Comment): void;
}
const Comments = ({ comments, onDelete }: ICommentsProps) => {
  let annoCounter = 0;
  const elem =
    comments &&
    comments.map((comment, idx) => {
      if (comment.annotations && comment.annotations.length > 0) {
        annoCounter = annoCounter + 1;
      }

      return (
        <li key={`comment_${comment.id}_${idx}`}>
          <button type="button" className="btn btn-control">
            <i
              className="far fa-trash-alt"
              onClick={() => onDelete(comment)}
            ></i>
          </button>
          {comment.annotations && comment.annotations.length > 0 && (
            <div className="tag">{annoCounter}</div>
          )}
          <Avatar user={comment.user} />
          {comment.user && (
            <p className="name">
              {comment.user.first_name} {comment.user.last_name}
            </p>
          )}
          <p className="value">{comment.value}</p>
          {comment.updated_at && (
            <p className="date">{Helpers.formatDate(comment.updated_at)}</p>
          )}
        </li>
      );
    });

  return <ul>{elem}</ul>;
};
