import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import * as API from "../../api";
import * as Actions from "./actions";
import { ActionTypes } from "./types";

function* getReportTraits(action: any) {
  try {
    // @ts-ignore
    const res = yield call(API.Reports.traits);

    yield put(Actions.getReportTraitsSuccess(res.body));
  } catch (error: any) {
    yield put(Actions.getReportTraitsError(error));
  }
}

function* watchGetReportTraits() {
  yield takeEvery(ActionTypes.GET_REPORTS_TRAITS_REQUEST, getReportTraits);
}

function* reportsTraitsSaga() {
  yield all([fork(watchGetReportTraits)]);
}

export default reportsTraitsSaga;
