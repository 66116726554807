import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import * as API from "../../api";
import * as Actions from "./actions";
import { ActionTypes } from "store/application/types";
import * as AppTypes from "../app/types";

function* getApplication() {
  try {
    // @ts-ignore
    const res = yield call(API.Application.index);
    yield put(Actions.getApplicationSuccess(res.body));
  } catch (error: any) {
    yield put(Actions.getApplicationError(error));
  }
}

function* createApplication(action: any) {
  try {
    yield call(API.Application.create, {
      application: action.payload.body,
    });

    yield put(Actions.getApplicationRequestSaga());
    yield put(Actions.createApplicationSuccess());
    yield put({
      type: AppTypes.App.ALERT_SHOW,
      content: "Application updated successfully",
    });
  } catch (error: any) {
    yield put(Actions.createApplicationError(error));
  }
}

function* deleteApplication(action: any) {
  try {
    yield call(API.Application.delete, action.payload.id);

    yield put(Actions.getApplicationRequestSaga());
    yield put(Actions.deleteApplicationSuccess());
    yield put({
      type: AppTypes.App.ALERT_SHOW,
      content: "Application deleted successfully",
    });
  } catch (error: any) {
    yield put(Actions.deleteApplicationError(error));
  }
}

function* watchGetApplication() {
  yield takeEvery(ActionTypes.GET_APPLICATION_REQUEST, getApplication);
}

function* watchCreateApplication() {
  yield takeEvery(ActionTypes.CREATE_APPLICATION_REQUEST, createApplication);
}

function* watchDeleteApplication() {
  yield takeEvery(ActionTypes.DELETE_APPLICATION_REQUEST, deleteApplication);
}

function* applicationSaga() {
  yield all([
    fork(watchGetApplication),
    fork(watchCreateApplication),
    fork(watchDeleteApplication),
  ]);
}

export default applicationSaga;
