import { Store, createStore, applyMiddleware } from 'redux';
import { routerMiddleware } from 'connected-react-router';
import { composeWithDevTools } from 'redux-devtools-extension';
import createSagaMiddleware from 'redux-saga';
import thunk from 'redux-thunk';
import { History } from 'history';
import { createLogger } from 'redux-logger';
import { rootReducer, rootSaga } from '../store';
import * as DataTypes from '../store/types';

export default function configureStore(history: History, initialState: DataTypes.ApplicationState): Store<DataTypes.ApplicationState> {
  const middlewares: any = [];
  const composeEnhancers = composeWithDevTools({});
  const sagaMiddleware = createSagaMiddleware();

  // Middleware - Log only in development
  middlewares.push(routerMiddleware(history));
  middlewares.push(sagaMiddleware);
  middlewares.push(thunk);
  if (process.env.NODE_ENV === 'development') middlewares.push(createLogger({ collapsed: () => true }));

  const store = createStore(rootReducer(history), initialState, composeEnhancers(applyMiddleware(...middlewares)));

  // Run the root saga
  sagaMiddleware.run(rootSaga);

  return store;
}
