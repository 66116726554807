import React, { Component } from "react";
import {
  FlexibleXYPlot,
  XAxis,
  YAxis,
  HorizontalGridLines,
  VerticalBarSeries,
} from "react-vis";
import * as DataTypes from "store/types";
import "css/Chart.scss";

interface IProps {
  metric?: DataTypes.Metric;
  experiences: Array<DataTypes.Experience>;
  showMissingMetricWarning?: boolean;
}

interface IState {}

const reportData = (
  experiences: Array<DataTypes.Experience>,
  metric?: DataTypes.Metric
) => {
  let data = experiences.map((exp, idx) => {
    if (metric) {
      const result =
        metric.results &&
        metric.results.find((r) => r.experience_id === exp.id);
      if (result) {
        let cleanValue = 0;

        if (result.value) {
          cleanValue = Number(result.value.replace(/,/g, ""));
        }
        return { x: exp.name, y: cleanValue, color: "#C9E1FB" };
      }
    }
    return { x: exp.name, y: 0, color: "#C9E1FB" };
  });

  // Get the max value index to change the color
  let maxValue = 0;
  let maxIndex = 0;
  data.forEach((item, idx) => {
    let itemY = item.y;

    if (itemY > maxValue) {
      maxValue = itemY;
      maxIndex = idx;
    }
  });

  data[maxIndex].color = "#2a88f2";
  return data;
};

class Chart extends Component<IProps, IState> {
  render() {
    const { metric, experiences, showMissingMetricWarning } = this.props;

    if (metric) {
      return (
        <div className="chart-box">
          <div className="header">
            <h5>{metric && metric.name}</h5>
          </div>
          <div className="body">
            <FlexibleXYPlot
              xType="ordinal"
              height={250}
              margin={{ left: 80, right: 40 }}
              className="BarChart"
            >
              <HorizontalGridLines />
              <XAxis
                hideLine
                style={{
                  line: { stroke: "#FFF" },
                  ticks: { stroke: "#FFF" },
                  text: {
                    stroke: "none",
                    fill: "#2F4760",
                    fontSize: "12px",
                    fontWeight: 500,
                  },
                }}
              />
              <YAxis hideLine />
              <VerticalBarSeries
                animation
                barWidth={0.4}
                width={100}
                data={reportData(experiences, metric)}
                colorType="literal"
              />
            </FlexibleXYPlot>
          </div>
        </div>
      );
    }

    return (
      <div className="chart-box">
        <div className="body">
          {showMissingMetricWarning && (
            <p className="placeholder">
              Add a metric and start entering data to begin.
            </p>
          )}
        </div>
      </div>
    );
  }
}

export { Chart };
