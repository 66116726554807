import React, { useRef } from 'react';
import { AuthService } from 'utils/auth';

interface IProps {
  value: string;
  onChange(value: string): void;
}

const ScoreSelector = ({ value, onChange }: IProps) => {
  const ref = useRef<any>();

  const getValue = (value: string) => {
    if (value === '2') return 'medium-low';
    if (value === '3') return 'medium';
    if (value === '4') return 'medium-high';
    if (value === '5') return 'high';

    return 'low';
  };

  const setValue = (value: string) => {
    if (value === 'medium-low') return '2'
    if (value === 'medium') return '3';
    if (value === 'medium-high') return '4';
    if (value === 'high') return '5';

    return '1';
  };

  const isReadOnly = () => {
    const user = AuthService.getActiveUser();

    return user.role === 'read-only';
  }

  const execChange = (value: any) => {
    if (isReadOnly()) {
      return;
    }

    onChange(value);
  }

  return (
    <div ref={ref} className={value === 'none' ? 'ScoreSelector none' : 'ScoreSelector'}>
      <input type="range" min="1" value={setValue(value)} max="5" step="1" list="ticks" onChange={e => execChange(getValue(e.target.value))} />
      <datalist id="ticks">
        <option>1</option>
        <option>2</option>
        <option>3</option>
        <option>4</option>
        <option>5</option>
      </datalist>

      <ul className="ticks">
        <li>low</li>
        <li></li>
        <li>medium</li>
        <li></li>
        <li>high</li>
      </ul>
    </div>
  );
};

export { ScoreSelector };
