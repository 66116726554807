import React from "react";
import { CustomDataVizHorizontalBarChart } from "./horizontal_bar_chart";
import { CustomDataVizVerticalBarChart } from "./vertifcal_bar_chart";

type Params = {
  dataSet: any;
};

export const DataVizChartFactory: React.FC<Params> = ({ dataSet }) => {
  if (!dataSet.chart_kind) {
    return <p>Unkown chart kind</p>;
  }

  if (dataSet.chart_kind === "horizontal_column") {
    return <CustomDataVizHorizontalBarChart dataSet={dataSet} />;
  }

  if (dataSet.chart_kind === "vertical_column") {
    return <CustomDataVizVerticalBarChart dataSet={dataSet} />;
  }

  return <p>Unkown chart kind</p>;
};
