import { action } from 'typesafe-actions';
import { Dispatch } from 'redux';
import { ActionTypes } from './types';
import * as DataTypes from '../types';

interface RequestError {
  statusCode?: number | undefined;
  message: any;
}

export const resetWorksheet = () => action(ActionTypes.RESET_WORKSHEET);

// Create Worksheet
export const createWorksheetRequest = (board: DataTypes.IdeaBoard) => (dispatch: Dispatch) => dispatch(action(ActionTypes.CREATE_WORKSHEET_REQUEST, { board }));
export const createWorksheetSuccess = (response: DataTypes.ApiRequest<DataTypes.Worksheet>) => action(ActionTypes.CREATE_WORKSHEET_SUCCESS, { response });
export const createWorksheetError = (error: RequestError) => action(ActionTypes.CREATE_WORKSHEET_FAILURE, error);

// Update Worksheet
export const updateWorksheetRequest = (board_id: string, body: any) => (dispatch: Dispatch) =>
  dispatch(action(ActionTypes.UPDATE_WORKSHEET_REQUEST, { board_id, body }));
export const updateWorksheetSuccess = (response: DataTypes.ApiRequest<DataTypes.Idea>) => action(ActionTypes.UPDATE_WORKSHEET_SUCCESS, { response });
export const updateWorksheetError = (error: RequestError) => action(ActionTypes.UPDATE_WORKSHEET_FAILURE, error);

// Get Worksheet
export const getWorksheetRequest = (board_id: string) => action(ActionTypes.GET_WORKSHEET_REQUEST, { board_id });
export const getWorksheetSuccess = (response: DataTypes.Worksheet) => action(ActionTypes.GET_WORKSHEET_SUCCESS, { response });
export const getWorksheetError = (error: RequestError) => action(ActionTypes.GET_WORKSHEET_FAILURE, error);

// Get Share Worksheet
export const getShareWorksheetRequest = (slug: string, uuid: string, password?: string) =>
  action(ActionTypes.GET_SHARE_WORKSHEET_REQUEST, { slug, uuid, password });
export const getShareWorksheetSuccess = (response: DataTypes.Worksheet) => action(ActionTypes.GET_SHARE_WORKSHEET_SUCCESS, { response });
export const getShareWorksheetError = (error: RequestError) => action(ActionTypes.GET_SHARE_WORKSHEET_FAILURE, error);

// Update Worksheet
export const channelUpdateWorksheetRequest = (content: any) => (dispatch: Dispatch) =>
  dispatch(action(ActionTypes.CHANNEL_UPDATE_WORKSHEET_REQUEST, { content }));
export const channelUpdateWorksheetSuccess = (content: any) => action(ActionTypes.CHANNEL_UPDATE_WORKSHEET_SUCCESS, { content });
export const channelUpdateWorksheetError = (error: any) => action(ActionTypes.CHANNEL_UPDATE_WORKSHEET_FAILURE, error);

export const notifyEditingRequest = (ideaBoard: any) => (dispatch: Dispatch) =>
  dispatch(action(ActionTypes.NOTIFY_EDITING_REQUEST, { ideaBoard }));
export const notifyEditingSuccess = () => action(ActionTypes.NOTIFY_EDITING_SUCCESS, {});
export const notifyEditingError = (error: RequestError) => action(ActionTypes.NOTIFY_EDITING_FAILURE, error);