import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import _ from "lodash";
import { ApplicationState } from "store/types";
import { history, Helpers } from "utils";
import queryString from "query-string";
import { Aside, Filters, List, Kanban } from "./comps";
import * as DataTypes from "store/types";
import { AppPage, AppPageProps } from "comps/pages";
import { Scrollable } from "comps/base/scrollable";
import { createLoadingSelector } from "store/selectors";
import {
  getIdeasRequest,
  updateIdeaRequest,
  deleteIdeaRequest,
  archiveIdeaRequest,
  reorderIdeaRequest,
  uploadIdeasRequest,
  exportIdeasRequest,
} from "store/idea/actions";
import {
  getIdeaBoardsRequest,
  getIdeaBoardRequest,
  updateIdeaBoardShareRequest,
  deleteIdeaBoardRequest,
} from "store/idea_board/actions";
import {
  setIdeaMultipleSelect,
  selectIdeaRequest,
} from "store/idea_management/actions";
import { getIdeasTraitsRequest } from "store/idea_trait/actions";
import { createWorksheetRequest } from "store/worksheet/actions";
import { clearAppErrors, showModal } from "store/app/actions";
import * as Modals from "./comps/modals";
import { DropdownControl, SearchBar, TotalCountStat } from "ui";

import InfiniteScroll from "comps/infinite_scroll";
import { SwitchButton } from "ui/switch_button";
import { OrderDirection } from "ui/order_icon";
import { CustomLoader } from "ui/loaders";
import { AsideMultiselect } from "./comps/aside_multiselect";
import { AuthService } from "utils/auth";
import { IdeaCsv } from "ui/idea_csv";
import { MarketingBanner } from "comps/banner";

enum ViewType {
  LIST = "list",
  KANBAN = "kanban",
}

enum PageActions {
  NONE = "none",
  DELETE = "delete",
  DELETE_BOARD = "delete_board",
  SHARE_BOARD = "share_board",
  ARCHIVE = "archive",
  UPLOAD_IDEAS = "UPLOAD_IDEAS",
  EXPORT = "EXPORT_IDEAS",
}

interface IProps extends AppPageProps {
  router: any;
  currentUser: any;
  computedMatch: any;
  uploadIdeasRequest: (file: any) => void;
  account: DataTypes.Account | null;
  setIdeaMultipleSelect: (opened: boolean) => void;
  clearAppErrors: () => void;
  getIdeasRequest: (
    options?: DataTypes.ApiListOptions,
    scrollOpts?: { append: boolean }
  ) => void;
  exportIdeasRequest: (queryString: string, fields: string[]) => void;
  getIdeaBoardRequest: (
    board_id: string,
    options?: DataTypes.ApiListOptions,
    scrollOpts?: { append: boolean }
  ) => void;
  getIdeaBoardsRequest: (options?: DataTypes.ApiListOptions) => void;
  createWorksheetRequest: (board: DataTypes.IdeaBoard) => void;
  updateIdeaRequest: (id: string, body: any) => void;
  archiveIdeaRequest: (id: string, body: any) => void;
  updateIdeaBoardShareRequest: (id: string, body: any) => void;
  showModal: (component: React.ComponentType<any>, options: any) => void;
  deleteIdeaRequest: (id: string) => void;
  deleteIdeaBoardRequest: (id: string) => void;
  getIdeasTraitsRequest: () => void;
  reorderIdeaRequest: (
    id: string,
    position: number,
    currentDirection: OrderDirection
  ) => void;
  reorderLoading: boolean;
  loading: boolean;
  accountLoading: boolean;
  exportLoading: boolean;
  collections: any;
  location: any;
  name: string;
  ideaTrait: any;
  ideaManagement: any;
  selectIdeaRequest: (idea: any, clear?: any) => void;
}

interface IState {
  resetingList: boolean;
  selectedIdx: number;
  view: ViewType;
  selectedFilters: { [key: string]: Array<string> };
  group?: string;
  archived?: string;
  board_id?: string;
  search: string;
  limit: number;
  currentOrder: string;
  order: {
    [key: string]: OrderDirection;
  };
}

class Ideas extends Scrollable<IProps, IState> {
  static defaultProps: any;
  listRef: any;

  constructor(props: IProps) {
    super(props);

    const query = queryString.parse(this.props.location.search, {
      arrayFormat: "bracket",
    });

    const { orderBy, orderDirection } = query;

    let order = {
      customOrder: OrderDirection.NONE,
      name: OrderDirection.NONE,
      valueOfLearning: OrderDirection.NONE,
      winLikelyhood: OrderDirection.NONE,
      levelOfEffort: OrderDirection.NONE,
      score: OrderDirection.NONE,
      businessImpact: OrderDirection.NONE,
      createdAt: OrderDirection.NONE,
      updatedAt: OrderDirection.NONE,
    };

    if (orderBy) {
      order = { ...order, [orderBy as any]: orderDirection };
    }

    this.state = {
      resetingList: false,
      selectedIdx: 0,
      search: "",
      limit: 25,
      view: this.props.router.location.query.view || ViewType.LIST,
      selectedFilters: {},
      currentOrder: (orderBy as string) || "",
      order,
    };

    this.fetchMore = _.debounce(this.fetchMore, 1000, {
      leading: true,
      trailing: false,
    });
  }

  private setPageAction = (
    action: PageActions,
    idea?: DataTypes.Idea | null,
    board?: DataTypes.IdeaBoard | null
  ) => {
    this.props.clearAppErrors();

    // Show delete modal
    if (action === PageActions.DELETE) {
      this.props.showModal(Modals.Delete, {
        idea: idea,
        onSubmit: this.onDeleteIdea,
      });
    }

    if (action === PageActions.ARCHIVE) {
      this.props.showModal(Modals.Archive, {
        idea,
        onSubmit: this.onArchiveIdea,
      });
    }

    // Show delete board modal
    if (action === PageActions.DELETE_BOARD) {
      this.props.showModal(Modals.DeleteBoard, {
        board: board,
        onSubmit: this.onDeleteBoard,
      });
    }

    // Show share board modal
    if (action === PageActions.SHARE_BOARD) {
      this.props.showModal(Modals.ShareBoard, {
        className: "Share",
        board: board,
        onSubmit: this.onUpdateShare,
      });
    }

    if (action === PageActions.UPLOAD_IDEAS) {
      this.props.showModal(IdeaCsv, {
        onSubmit: this.onIdeasCsvUpload,
      });
    }

    if (action === PageActions.EXPORT) {
      this.props.showModal(Modals.ExportIdeas, {
        onSubmit: this.onExportIdeas,
        saving: this.props.exportLoading,
        bbMethodEnable: this.props.account?.settings.use_bb_methods,
      });
    }
  };

  private onExportIdeas = (fields: string[]) => {
    if (!fields || fields.length === 0) {
      return;
    }

    this.props.exportIdeasRequest(this.props.location.search, fields);
  };

  private onIdeasCsvUpload = (file: any) => {
    if (!file) {
      return;
    }

    this.props.uploadIdeasRequest(file);
  };

  private getSortString() {
    if (!this.state.currentOrder) {
      return "updated_at";
    }

    const direction =
      this.state.order[this.state.currentOrder] === OrderDirection.ASC
        ? "-"
        : "";

    return `${direction}${Helpers.camelToSnake(this.state.currentOrder)}`;
  }

  private resetetOrders() {
    return Object.keys(this.state.order).reduce(
      (acc, current) => ({ ...acc, [current]: OrderDirection.NONE }),
      {}
    );
  }

  private changeOrder = (orderBy: string, currentDirection: OrderDirection) => {
    let newDirection: OrderDirection;

    if (orderBy !== "customOrder") {
      if (currentDirection === OrderDirection.DESC) {
        newDirection = OrderDirection.ASC;
      } else {
        newDirection = OrderDirection.DESC;
      }
    } else {
      newDirection = OrderDirection.ASC;
    }

    const order = { ...this.resetetOrders(), [orderBy]: newDirection };
    this.setState(
      {
        order,
        currentOrder: orderBy,
      },
      () => {
        const query = queryString.parse(this.props.location.search, {
          arrayFormat: "bracket",
        });
        query.orderBy = orderBy;
        query.orderDirection = newDirection;

        const qStr = queryString.stringify(query, {
          encode: true,
          arrayFormat: "bracket",
        });
        history.push(`?${qStr}`);

        document.body.dispatchEvent(new Event("resetPage"));
        const q = queryString.parse(this.props.location.search, {
          arrayFormat: "bracket",
        });
        this.props.getIdeasRequest(
          {
            limit: 25,
            sort: this.getSortString(),
            q: q.q,
          },
          { append: false }
        );
      }
    );
  };

  itemSelected = (itemIndex: number) => {
    this.setState({ ...this.state, selectedIdx: -1 }, () => {
      this.setState({ ...this.state, selectedIdx: itemIndex });

      const idea = this.props.collections.ideas.data[itemIndex];

      if (this.props.ideaManagement.multiSelect) {
        this.props.selectIdeaRequest(idea);
      }
    });
  };

  componentDidMount = () => {
    let q = queryString.parse(this.props.location.search, {
      arrayFormat: "bracket",
    });

    let selectedFilters: { [key: string]: Array<any> } = {};
    if (Array.isArray(q.q))
      selectedFilters = Helpers.getFiltersFromQueryString(q.q);
    if (
      selectedFilters["idea_board_id"] &&
      selectedFilters["idea_board_id"].length > 0
    ) {
      this.setState({
        ...this.state,
        board_id: selectedFilters["idea_board_id"][0],
        selectedFilters: selectedFilters,
      });
    } else {
      this.setState({
        ...this.state,
        group: q.group?.toString() || "all",
        selectedFilters: selectedFilters,
      });
    }

    this.props.getIdeasTraitsRequest();
    this.props.getIdeaBoardsRequest({ limit: 200, sort: "updated_at" });
    this.props.getIdeasRequest({
      sort: "updated_at",
      q: q.q,
      group: q.group?.toString() || undefined,
    });
    super.componentDidMount();
  };

  private changeView = (view: ViewType) => {
    let q = queryString.parse(this.props.location.search, {
      arrayFormat: "bracket",
    });
    q["view"] = view;
    const qStr = queryString.stringify(q, {
      encode: true,
      arrayFormat: "bracket",
    });
    history.push(`?${qStr}`);

    if (view === ViewType.KANBAN) {
      this.fetchMore(1, false, true);
    }

    this.setState({ ...this.state, view: view });
  };

  private onChange = (
    id: string,
    key: string,
    value: string | Array<string> | boolean | Array<DataTypes.PainGain>
  ) => {
    if (this.isReadOnly()) {
      return;
    }

    this.props.updateIdeaRequest(id, { idea: { [key]: value } });
  };

  private isReadOnly = () => {
    const user = AuthService.getActiveUser();

    return user.role === "read-only";
  };

  private onUpdateShare = (
    id: string,
    passwordProtected: boolean,
    password?: string
  ) => {
    if (passwordProtected === true && password) {
      this.props.updateIdeaBoardShareRequest(id, {
        idea_board: {
          password_protected: passwordProtected,
          password: password,
        },
      });
    } else {
      this.props.updateIdeaBoardShareRequest(id, {
        idea_board: { password_protected: passwordProtected },
      });
    }
  };

  private onDeleteIdea = (idea: DataTypes.Idea) => {
    if (idea?.id) {
      this.props.deleteIdeaRequest(idea.id);
    }
  };

  private onArchiveIdea = (idea: DataTypes.Idea) => {
    if (!idea?.id) {
      return;
    }

    this.props.archiveIdeaRequest(idea.id, {
      idea: { archived: !idea.archived },
    });
  };

  private fetchMore = (page?: number, append = true, all = false) => {
    const { collections } = this.props;

    if (
      (collections.ideas &&
        collections.ideas.data.length > 0 &&
        collections.ideas.has_more) ||
      !append
    ) {
      let q = queryString.parse(this.props.location.search, {
        arrayFormat: "bracket",
      });
      this.props.getIdeasRequest(
        {
          sort: this.getSortString(),
          q: q.q,
          page,
          limit: all ? 1000000 : this.state.limit,
        },
        { append }
      );
    }
  };

  private onFilterCheck = (checked: boolean, type: string, value: string) => {
    let q = queryString.parse(this.props.location.search, {
      arrayFormat: "bracket",
    });

    if (type !== `score`) {
      if (checked) {
        if (q.q && Array.isArray(q.q)) {
          q.q.push(`${type}=${value}`);
        } else {
          q.q = [`${type}=${value}`];
        }
      } else {
        if (q.q && Array.isArray(q.q)) {
          q.q = q.q.filter((item: string) => item !== `${type}=${value}`);
        }
      }
    }

    // we want to only have one score so lets remove any that exists and replace with new value.
    if (q) {
      if (type === `score`) {
        if (q.q && Array.isArray(q.q)) {
          q.q = q.q.filter((item: string) => !item.startsWith(`score=<`));
          q.q.push(`${type}=<${value}`);
        } else {
          q.q = [`${type}=<${value}`];
        }
      }
    }

    const qStr = queryString.stringify(q, {
      encode: true,
      arrayFormat: "bracket",
    });

    if (Array.isArray(q.q)) {
      this.setState({
        ...this.state,
        selectedFilters: Helpers.getFiltersFromQueryString(q.q),
      });
    }

    history.push(`/ideas?${qStr}`);
    this.props.getIdeasRequest({
      sort: "updated_at",
      q: q.q,
      group: q.group?.toString() || undefined,
    });
  };

  private setGroup = (value: string) => {
    let q = queryString.parse(this.props.location.search, {
      arrayFormat: "bracket",
    });
    q["group"] = value;
    if (Array.isArray(q.q)) {
      q.q = q.q.filter((item: string) => !item.startsWith(`idea_board_id=`));
    }
    const qStr = queryString.stringify(q, {
      encode: true,
      arrayFormat: "bracket",
    });
    this.setState({ ...this.state, group: value, board_id: undefined });
    history.push(`/ideas?${qStr}`);
    this.props.getIdeasRequest({
      sort: "updated_at",
      q: q.q,
      group: q.group?.toString() || undefined,
    });
  };

  private setBoard = (board_id: string) => {
    let q = queryString.parse(this.props.location.search, {
      arrayFormat: "bracket",
    });
    q.q = [`idea_board_id=${board_id}`];
    if (q) delete q["group"];

    const qStr = queryString.stringify(q, {
      encode: true,
      arrayFormat: "bracket",
    });
    this.setState({ ...this.state, board_id: board_id, group: undefined });
    history.push(`/ideas?${qStr}`);
    this.props.getIdeasRequest({
      sort: "updated_at",
      q: q.q,
      group: undefined,
    });
  };

  private onViewWorksheet = (board: DataTypes.IdeaBoard) => {
    if (board.worksheet) {
      history.push(`/ideas/board/${board.id}/worksheet`);
    } else {
      this.props.createWorksheetRequest(board);
    }
  };

  private onDeleteBoard = (board: DataTypes.IdeaBoard) => {
    if (board.id) {
      this.props.deleteIdeaBoardRequest(board.id);
      this.setState({ ...this.state, board_id: undefined, group: "all" });
    }
  };

  private onReorder = (id: string, index: number) => {
    this.props.reorderIdeaRequest(
      id,
      index,
      this.state.order[this.state.currentOrder]
    );
  };

  private onSearchSubmit = (search: string) => {
    let q = queryString.parse(this.props.location.search, {
      arrayFormat: "bracket",
    });

    if (search) {
      if (q.q && Array.isArray(q.q)) {
        if (q.q.filter((item: string) => /name=/.test(item)).length)
          // there can only be on search key
          q.q = q.q.map((item: string) =>
            /name=/.test(item) ? `name=${search}` : item
          );
        else q.q.push(`name=${search}`);
      } else {
        q.q = [`name=${search}`];
      }
    } else {
      if (q.q && Array.isArray(q.q)) {
        q.q = q.q.filter((item: string) => !/name=/.test(item));
      }
    }

    if (Array.isArray(q.q))
      this.setState({
        ...this.state,
        search,
        selectedFilters: Helpers.getFiltersFromQueryString(q.q),
      });

    const qStr = queryString.stringify(q, {
      encode: true,
      arrayFormat: "bracket",
    });
    history.push(`/ideas?${qStr}`);
    this.props.getIdeasRequest(
      { sort: this.getSortString(), q: q.q },
      { append: false }
    );
  };

  onLimitChange = (limit = 25) => {
    setTimeout(() => {
      if (this.props.loading) {
        this.onLimitChange(limit);
        return;
      }

      this.setState({ limit: limit }, () => {
        this.fetchMore(1, false);
      });
    }, 500);
  };

  private clearFilters = () => {
    const qStr = queryString.stringify(
      {},
      {
        encode: true,
        arrayFormat: "bracket",
      }
    );

    history.push(`/ideas?${qStr}`);
    this.props.getIdeasRequest({
      q: null,
      sort: this.getSortString(),
    });
  };

  render() {
    const { loading, accountLoading, account, collections, ideaTrait } =
      this.props;
    const { view, selectedIdx, selectedFilters, group, board_id } = this.state;

    if (account?.summary?.totals.ideas === 0) {
      return <EmptyState />;
    }

    let selectedBoard: DataTypes.IdeaBoard | undefined = undefined;
    if (board_id && collections.idea_boards && collections.idea_boards.data) {
      selectedBoard = collections.idea_boards.data.find(
        (ib: DataTypes.IdeaBoard) => ib.id === board_id
      );
    }

    return (
      <React.Fragment>
        <section className="FilterBar">
          <SwitchButton
            buttons={[
              {
                text: "ideas",
                onClick: () => history.push("/ideas"),
                selected: () => history.location.pathname === "/ideas",
              },
              {
                text: "boards",
                onClick: () => history.push("/ideas/boards"),
                selected: () => history.location.pathname === "/ideas/boards",
              },
            ]}
          />
          <div className="viewCtrls">
            <button
              title="Change View Type to List"
              className={
                view !== ViewType.KANBAN
                  ? "btn btn-icon active"
                  : "btn btn-icon"
              }
              onClick={(e) => this.changeView(ViewType.LIST)}
            >
              <i className="fas fa-bars" />
            </button>
            <button
              title="Change View Type to Kanban"
              className={
                view === ViewType.KANBAN
                  ? "btn btn-icon active"
                  : "btn btn-icon"
              }
              onClick={(e) => this.changeView(ViewType.KANBAN)}
            >
              <i className="fas fa-bars nine-deg" />
            </button>
          </div>
          <div className="pad20">
            <hr />
          </div>
          <TotalCountStat
            name="ideas"
            total={account?.summary?.totals.ideas || 0}
          />
          {this.props.currentUser.role === "read-only" ? null : (
            <div className="addCtrls">
              <Link to="/ideas/create" className="btn btn-primary">
                Add Idea
              </Link>
            </div>
          )}

          {this.props.currentUser.role === "read-only" ? null : (
            <div className="addCtrls">
              <button
                className="btn btn-primary"
                onClick={() =>
                  this.setPageAction(PageActions.UPLOAD_IDEAS, null)
                }
              >
                Batch Upload
              </button>
            </div>
          )}
          <div className="pad20">
            <hr />
          </div>

          {/* <MarketingBanner
            containerStyle={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              marginTop: "20px",
              marginLeft: "3px",
            }}
            bannerPlacementSlug={"filter_bar_banners"}
            imageStyle={{ width: "200px", height: "200px" }}
          /> */}

          <Filters
            onLimitChange={this.onLimitChange}
            onGroupClick={this.setGroup}
            onSorting={this.changeOrder}
            sortingValues={this.state.order}
            onArchivedClick={this.onFilterCheck}
            loading={accountLoading}
            traits={ideaTrait?.traits}
            currentUser={this.props.currentUser}
            onCheck={this.onFilterCheck}
            selectedFilters={selectedFilters}
            boardId={(selectedBoard && selectedBoard.id) || undefined}
            onBoardClick={this.setBoard}
            onCreateBoard={() => history.push("/ideas/boards/create")}
            boards={
              (collections.idea_boards && collections.idea_boards.data) ||
              undefined
            }
            onClearFilters={this.clearFilters}
          />
        </section>

        {view !== ViewType.KANBAN && (
          <React.Fragment>
            <section className="List Ideas">
              <ListHeader
                onExport={() => this.setPageAction(PageActions.EXPORT)}
                type={board_id ? "board" : group ? group : "all"}
                board={selectedBoard}
                onViewWorksheet={this.onViewWorksheet}
                onDeleteBoard={() =>
                  this.setPageAction(
                    PageActions.DELETE_BOARD,
                    null,
                    selectedBoard
                  )
                }
                onShare={(board) =>
                  this.setPageAction(PageActions.SHARE_BOARD, null, board)
                }
              ></ListHeader>
              <div className="SearchBar">
                <SearchBar
                  onChange={(search) =>
                    this.setState({ ...this.state, search })
                  }
                  value={this.state.search}
                  placeholder="Search for ideas"
                  onSubmit={this.onSearchSubmit}
                  enableReset
                />
              </div>
              <div className="flex frow justify-end">
                {/* <button
                  type="button"
                  onClick={() => this.setPageAction(PageActions.EXPORT)}
                  className="btn btn-link"
                  style={{
                    textAlign: "end",
                    fontSize: "14px",
                    paddingBottom: 0,
                    marginBottom: 0,
                    width: "fit-content",
                  }}
                >
                  Export
                </button> */}
                {this.props.currentUser.role === "ready-only" ? null : this
                    .props.ideaManagement.multiSelect ? (
                  <button
                    type="button"
                    onClick={() => this.props.setIdeaMultipleSelect(false)}
                    className="btn btn-link"
                    style={{
                      textAlign: "end",
                      fontSize: "14px",
                      paddingBottom: 0,
                      marginBottom: 0,
                      width: "fit-content",
                    }}
                  >
                    Close Multiple Select Menu
                  </button>
                ) : (
                  <button
                    type="button"
                    onClick={() => this.props.setIdeaMultipleSelect(true)}
                    className="btn btn-link"
                    style={{
                      textAlign: "end",
                      fontSize: "14px",
                      paddingBottom: 0,
                      marginBottom: 0,
                      width: "fit-content",
                    }}
                  >
                    Select Multiple
                  </button>
                )}
              </div>
              <InfiniteScroll
                pageStart={1}
                loadMore={this.fetchMore}
                hasMore={
                  (collections.ideas && collections.ideas.has_more) || false
                }
                initialLoad={true}
                ref={(ref) => (this.listRef = ref)}
                loader={
                  <CustomLoader
                    key={"loader"}
                    text="Wait while we load more ideas for you"
                  />
                }
                useWindow={false}
              >
                <List
                  reorderable={
                    !this.props.reorderLoading &&
                    this.state.currentOrder === "customOrder" &&
                    this.props.currentUser.role !== "read-only"
                  }
                  onReorder={this.onReorder}
                  loading={loading && collections.ideas === undefined}
                  ideas={collections.ideas && collections.ideas.data}
                  selectedIdx={selectedIdx}
                  ideaManagement={this.props.ideaManagement}
                  onSelect={this.itemSelected}
                />
              </InfiniteScroll>
            </section>
            <section className="Aside">
              {selectedIdx !== -1 ? (
                this.props.ideaManagement.multiSelect ? (
                  <AsideMultiselect />
                ) : (
                  <Aside
                    currentUser={this.props.currentUser}
                    account={account}
                    idea={
                      collections.ideas && collections.ideas.data
                        ? collections.ideas.data[selectedIdx]
                        : null
                    }
                    traits={ideaTrait?.traits}
                    boards={
                      collections.idea_boards && collections.idea_boards.data
                    }
                    onChange={this.onChange}
                    onDelete={() =>
                      this.setPageAction(
                        PageActions.DELETE,
                        collections.ideas.data[selectedIdx]
                      )
                    }
                    onArchive={() =>
                      this.setPageAction(
                        PageActions.ARCHIVE,
                        collections.ideas.data[selectedIdx]
                      )
                    }
                  ></Aside>
                )
              ) : null}
            </section>
          </React.Fragment>
        )}
        {view === ViewType.KANBAN && (
          <React.Fragment>
            <section className="Kanban">
              <div className="board">
                <ListHeader
                  onExport={() => this.setPageAction(PageActions.EXPORT)}
                  type={board_id ? "board" : group ? group : "all"}
                  board={selectedBoard}
                  onViewWorksheet={this.onViewWorksheet}
                  onDeleteBoard={() =>
                    this.setPageAction(
                      PageActions.DELETE_BOARD,
                      null,
                      selectedBoard
                    )
                  }
                  onShare={(board) =>
                    this.setPageAction(PageActions.SHARE_BOARD, null, board)
                  }
                ></ListHeader>
                <Kanban
                  currentUser={this.props.currentUser}
                  onSelect={this.itemSelected}
                  loading={loading}
                  ideas={collections.ideas && collections.ideas.data}
                  onChange={this.onChange}
                  selectedIdea={
                    collections.ideas && collections.ideas.data
                      ? collections.ideas.data[selectedIdx]
                      : null
                  }
                />
              </div>
            </section>
            <section className="Aside KanbanAside">
              <Aside
                currentUser={this.props.currentUser}
                account={account}
                idea={
                  collections.ideas && collections.ideas.data
                    ? collections.ideas.data[selectedIdx]
                    : null
                }
                traits={account && account.traits}
                boards={collections.idea_boards && collections.idea_boards.data}
                onChange={this.onChange}
                onDelete={() =>
                  this.setPageAction(
                    PageActions.DELETE,
                    collections.ideas.data[selectedIdx]
                  )
                }
                onArchive={() =>
                  this.setPageAction(
                    PageActions.ARCHIVE,
                    collections.ideas.data[selectedIdx]
                  )
                }
              ></Aside>
            </section>
          </React.Fragment>
        )}
      </React.Fragment>
    );
  }
}

Ideas.defaultProps = {
  name: "idea",
};

const loadingSelector = createLoadingSelector(["@@idea/GET_IDEAS"]);
const accountLoadingSelector = createLoadingSelector([
  "@@account/GET_ACCOUNT",
  "@@account/GET_IDEAS_TRAITS",
  "@@idea_board/GET_IDEA_BOARDS",
]);
const reorderSelector = createLoadingSelector(["@@idea/REORDER"]);
const exportLoadingSelector = createLoadingSelector(["@@idea/EXPORT_IDEAS"]);

const mapStateToProps = ({
  router,
  app,
  account,
  collections,
  ideaTrait,
  ideaManagement,
}: ApplicationState) => ({
  loading: loadingSelector(app.requests),
  accountLoading: accountLoadingSelector(app.requests),
  reorderLoading: reorderSelector(app.requests),
  exportLoadingSelector: exportLoadingSelector(app.requests),
  ideaManagement: ideaManagement,
  router,
  collections,
  account,
  ideaTrait,
});

const mapDispatchToProps = {
  clearAppErrors,
  getIdeaBoardsRequest,
  createWorksheetRequest,
  getIdeaBoardRequest,
  getIdeasRequest,
  updateIdeaRequest,
  uploadIdeasRequest,
  archiveIdeaRequest,
  updateIdeaBoardShareRequest,
  deleteIdeaRequest,
  deleteIdeaBoardRequest,
  selectIdeaRequest,
  showModal,
  getIdeasTraitsRequest,
  reorderIdeaRequest,
  setIdeaMultipleSelect,
  exportIdeasRequest,
};

const connectedPage = connect(
  mapStateToProps,
  mapDispatchToProps
)(AppPage(Ideas));

export { connectedPage as Ideas };

const EmptyState = () => {
  return (
    <div className="Ideas empty-state">
      <div className="wrapper">
        <img src="/img/ideas_empty.png" alt="add new idea" />
        <h1>Ideas</h1>
        <h4>Your tests are only as good as the strategy behind them!</h4>
        <p>
          Create winning test ideas and manage them here. This is your hub for
          idea generating, editing, organizing, and prioritizing them to become
          winning tests. Get started by adding an idea.
        </p>
        <button
          className="btn btn-primary"
          type="button"
          onClick={() => history.push("/ideas/create")}
        >
          Add Idea
        </button>
      </div>
    </div>
  );
};

interface IListHeaderProps {
  type: any;
  board?: DataTypes.IdeaBoard;
  onViewWorksheet(board: DataTypes.IdeaBoard): void;
  onDeleteBoard(board: DataTypes.IdeaBoard): void;
  onShare(board: DataTypes.IdeaBoard): void;
  onExport(): void;
}

const ListHeader = ({
  type,
  board,
  onViewWorksheet,
  onDeleteBoard,
  onShare,
  onExport,
}: IListHeaderProps) => {
  if (type === "board" && board) {
    return (
      <div className="header">
        <div className="controls">
          <DropdownControl
            controlItems={[
              {
                name: "Share & Request Ideas",
                icon: "fas fa-share-alt",
                onClick: () => onShare(board),
              },
              {
                name: board.worksheet ? "View Worksheet" : "Create Worksheet",
                icon: "far fa-file-alt",
                onClick: () => onViewWorksheet(board),
              },
              {
                name: "Delete Idea Board",
                icon: "far fa-trash-alt",
                onClick: () => onDeleteBoard(board),
                splitAbove: true,
                className: "danger",
              },
            ]}
          />
        </div>
        <h3>{board.name}</h3>
        <p>
          {board.description.blocks.map((block: any) => block.text).join(" ")}
        </p>

        <p>
          <button className="button button__link" onClick={onExport}>
            Export
          </button>{" "}
          your filtered set of ideas into an Excel file so you can use them in a
          workshopping tool like Miro. Just copy and paste directly from the
          file - quick and easy!
        </p>
      </div>
    );
  }

  if (type === "all") {
    return (
      <div className="header">
        <h3>all ideas</h3>
        <p>
          Manage all ideas by organizing them into boards, scoring them based on
          prioritization criteria, and quickly making note of which ones you
          love vs. which ones should go on the backburner. When you are ready to
          test an idea, promote it from here and we’ll get your case study
          started.
        </p>

        <p>
          <button
            className="button button__link"
            style={{
              padding: 0,
              fontSize: "12px",
            }}
            onClick={onExport}
          >
            Export
          </button>{" "}
          your filtered set of ideas into an Excel file so you can use them in a
          workshopping tool like Miro. Just copy and paste directly from the
          file - quick and easy!
        </p>
      </div>
    );
  }

  if (type === "starred") {
    return (
      <div className="header">
        <h3>Starred ideas</h3>
        <p>
          Manage all ideas by organizing them into boards, scoring them based on
          prioritization criteria, and quickly making note of which ones you
          love vs. which ones should go on the backburner. When you are ready to
          test an idea, promote it from here and we’ll get your case study
          started.
        </p>

        <p>
          <button
            style={{
              padding: 0,
              fontSize: "12px",
            }}
            className="button button__link"
            onClick={onExport}
          >
            Export
          </button>{" "}
          your filtered set of ideas into an Excel file so you can use them in a
          workshopping tool like Miro. Just copy and paste directly from the
          file - quick and easy!
        </p>
      </div>
    );
  }

  if (type === "my") {
    return (
      <div className="header">
        <h3>My ideas</h3>
        <p>
          Manage all ideas by organizing them into boards, scoring them based on
          prioritization criteria, and quickly making note of which ones you
          love vs. which ones should go on the backburner. When you are ready to
          test an idea, promote it from here and we’ll get your case study
          started.
        </p>

        <p>
          <button
            style={{
              padding: 0,
              fontSize: "12px",
            }}
            className="button button__link"
            onClick={onExport}
          >
            Export
          </button>{" "}
          your filtered set of ideas into an Excel file so you can use them in a
          workshopping tool like Miro. Just copy and paste directly from the
          file - quick and easy!
        </p>
      </div>
    );
  }

  if (type === "archive") {
    return (
      <div className="header">
        <h3>Archived ideas</h3>
        <p>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
          minim veniam, quis nostrud exercitation ullamco laboris nisi ut
          aliquip ex ea commodo consequat. Duis aute irure dolor in
          reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
          pariatur.
        </p>

        <p>
          <button
            style={{
              padding: 0,
              fontSize: "12px",
            }}
            className="button button__link"
            onClick={onExport}
          >
            Export
          </button>{" "}
          your filtered set of ideas into an Excel file so you can use them in a
          workshopping tool like Miro. Just copy and paste directly from the
          file - quick and easy!
        </p>
      </div>
    );
  }
  return null;
};
