import React, { Component } from 'react';
import { connect } from 'react-redux';
import { ApplicationState } from 'store/types';

interface IProps {
  router: any;
}

interface IState {}

class InsightBoardLoading extends Component<IProps, IState> {
  render() {
    return <div className="flex fcol-3 column">
      <div className="text_placeholder"></div>

      <div className="insight_placeholder">
        <div className="text_placeholder"></div>
      </div>

      <div className="text_placeholder"></div>

      <div className="insight_placeholder">
        <div className="text_placeholder"></div>
      </div>
    </div>;
  }
}

const mapStateToProps = ({ router }: ApplicationState) => ({
  router,
});

const mapDispatchToProps = {
};

const connectedPage = connect(mapStateToProps, mapDispatchToProps)(InsightBoardLoading);

export { connectedPage as InsightBoardLoading };