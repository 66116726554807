import { ApiClient } from "./client/api_client";

class UserToken {
  static async show(accountSlug: string, token: string) {
    const path = `/v1/user/token/${accountSlug}`;
    try {
      const response = await fetch(
        ApiClient.buildPath(path),
        this.getProps(token)
      );
      return ApiClient.handleResponse(response);
    } catch (err) {
      console.error(err);
      throw err;
    }
  }

  static getProps(token: string): RequestInit {
    return {
      method: "GET",
      headers: this.defaultHeaders(token),
      mode: "cors",
      cache: "default",
    };
  }

  static defaultHeaders(token: string): Headers {
    return new Headers({
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
      "Service-Name": "simpatic.io",
      "Content-Type": "application/json",
      "x-timestamp": new Date().toISOString(),
    });
  }
}

export { UserToken };
