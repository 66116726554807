import { action } from 'typesafe-actions';
import { Dispatch } from 'redux';
import { ActionTypes } from './types';
import * as DataTypes from '../types';

interface RequestError {
  statusCode?: number | undefined;
  message: any;
}

export const createMetricRequest = (experimentId: string, reportId: string, body: any) => (dispatch: Dispatch) =>
  dispatch(action(ActionTypes.CREATE_METRIC_REQUEST, { experimentId, reportId, body }));
export const createMetricSuccess = (response: DataTypes.ApiRequest<DataTypes.Experiment>) =>
  action(ActionTypes.CREATE_METRIC_SUCCESS, { response });
export const createMetricError = (error: RequestError) =>
  action(ActionTypes.CREATE_METRIC_FAILURE, error);

export const destroyMetricRequest = (experimentId: string, reportId: string, metricId: string) =>
  (dispatch: Dispatch) => dispatch(action(ActionTypes.DESTROY_METRIC_REQUEST, { experimentId, reportId, metricId }));
export const destroyMetricSuccess = (response: DataTypes.ApiRequest<DataTypes.Experiment>) =>
  action(ActionTypes.DESTROY_METRIC_SUCCESS, { response });
export const destroyMetricError = (error: RequestError) =>
  action(ActionTypes.DESTROY_METRIC_FAILURE, error);

export const createResultRequest = (experimentId: string, reportId: string, experienceId: string, metricId: string, body: any) =>
  (dispatch: Dispatch) => dispatch(action(ActionTypes.ADD_RESULT_REQUEST, { experimentId, reportId, experienceId, metricId, body }));
export const createResultSuccess = (response: DataTypes.ApiRequest<DataTypes.Experiment>) =>
  action(ActionTypes.ADD_RESULT_SUCCESS, { response });
export const createResultError = (error: RequestError) =>
  action(ActionTypes.ADD_RESULT_FAILURE, error);

export const updateMetricRequest = (metric: DataTypes.ApiRequest<DataTypes.Metric>, report: any, experimentId: any) => (dispatch: Dispatch) =>
  dispatch(action(ActionTypes.UPDATE_METRIC_REQUEST, { metric, report, experimentId }));
export const updateMetricSuccess = (response: DataTypes.ApiRequest<DataTypes.Experiment>) =>
  action(ActionTypes.UPDATE_METRIC_SUCCESS, { response });
export const updateMetricError = (error: RequestError) =>
  action(ActionTypes.UPDATE_METRIC_ERROR, error);

export const updateMetricOrderRequest = (
  experimentId: string,
  reportId: string,
  metric: DataTypes.ApiRequest<DataTypes.Metric>,
  position: number
) => (dispatch: Dispatch) =>
    dispatch(action(ActionTypes.UPDATE_METRIC_ORDER_REQUEST, {
      metric,
      position,
      experimentId,
      reportId,
    }));
export const updateMetricOrderSuccess = (response: DataTypes.ApiRequest<DataTypes.Experiment>) =>
  action(ActionTypes.UPDATE_METRIC_ORDER_SUCCESS, { response });
export const updateMetricOrderError = (error: RequestError) =>
  action(ActionTypes.UPDATE_METRIC_ORDER_ERROR, error);