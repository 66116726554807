import React, { Component } from "react";
import * as DataTypes from "store/types";
import Collapsible from "react-collapsible";
import { ArrowDownIcon } from "ui/arrow_down_icon";
import { OrderDirection, OrderIcon } from "ui/order_icon";
import { Helpers } from "utils";
import queryString from "query-string";
import Slider from "rc-slider";
import "rc-slider/assets/index.css";

const FilterKeys = [
  "audiences",
  "devices",
  "pages",
  "brands",
  "teams",
  "channels",
  "themes",
];

type sortingNamesType = {
  [key: string]: string;
};

const sortingNames: sortingNamesType = {
  valueOfLearning: "Value of Learning",
  winLikelyhood: "Win Likelihood",
  updatedAt: "Last Updated",
  createdAt: "Created At",
  customOrder: "Drag and Drop",
  score: "Score",
};

const buildFilterSettings = () => {
  let config = {};

  FilterKeys.forEach((key: string) => {
    config = { ...config, [key]: { showMore: false } };
  });

  return config;
};

interface IProps {
  currentUser: any;
  loading: boolean;
  traits: { [key: string]: Array<DataTypes.Trait> } | null;
  selectedFilters: { [key: string]: Array<string> };
  boards?: Array<DataTypes.IdeaBoard>;
  boardId?: string;
  onCheck(checked: boolean, type: string, value: any): void;
  onBoardClick(board_id: string): void;
  onCreateBoard(): void;
  onArchivedClick(checked: boolean, type: string, value: string): void;
  onSorting: (orderBy: string, currentDirection: OrderDirection) => void;
  sortingValues: { [key: string]: OrderDirection };
  onGroupClick(value: string): void;
  onLimitChange(limit: number): void;
  onClearFilters(): void;
}

interface IState {
  filterSettings: { [key: string]: { showMore: boolean } };
  maxScore: number;
  minScore: number;
}

class Filters extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      maxScore: 5,
      minScore: 0,
      filterSettings: buildFilterSettings(),
    };
  }

  private onScoreChange = (value: any) => {
    this.setState({
      ...this.state,
      maxScore: Number(value[1]) / 2,
      minScore: Number(value[0]) / 2,
    });
    this.props.onCheck(true, "score", [
      Number(value[0]) / 2,
      Number(value[1]) / 2,
    ]);
  };

  private onUpdateFilterSettings = (
    key: string,
    prop: string,
    value: string | boolean
  ) => {
    this.setState({
      ...this.state,
      filterSettings: {
        ...this.state.filterSettings,
        [key]: { ...this.state.filterSettings[key], [prop]: value },
      },
    });
  };

  onCheckAuthor = (checked: boolean, type: string, value: string) => {
    const authors = this.props.traits?.authors;
    const author = authors?.filter((author) => author.name === value)[0];
    this.props.onCheck(checked, "author", author?.id || "");
  };

  onCheckContributors = (checked: boolean, type: string, value: string) => {
    const contributors = this.props.traits?.contributors;
    const contributor = contributors?.filter(
      (contributor) => contributor.name === value
    )[0];
    this.props.onCheck(checked, "contributor_ids", contributor?.id || "");
  };

  buildBoards = () => {
    const { onCreateBoard, onBoardClick, boards, boardId } = this.props;

    return (
      <Collapsible
        triggerTagName="h4"
        trigger={
          <span className="flex justify-between">
            <span>
              Boards
              <ArrowDownIcon height="18" />
            </span>

            {this.props.currentUser.role === "read-only" ? null : (
              <button
                style={{ padding: "0px" }}
                className="button button__secondary flex"
                type="button"
                onClick={onCreateBoard}
              >
                Create
              </button>
            )}
          </span>
        }
        triggerClassName="cursor--pointer"
        triggerOpenedClassName="cursor--pointer"
        className="Filter"
        openedClassName="Filter"
      >
        <section>
          <ul>
            {boards &&
              boards.map((board, idx) => {
                return (
                  <li
                    key={["board", board.id].join("_")}
                    className={board.id === boardId ? "active" : ""}
                  >
                    <p
                      className="cursor--pointer"
                      onClick={() => onBoardClick(board.id || "")}
                    >
                      {board.name}
                    </p>

                    <p></p>
                  </li>
                );
              })}
          </ul>
        </section>
      </Collapsible>
    );
  };

  starred = () => {
    const query = queryString.parse(window.location.search, {
      arrayFormat: "bracket",
    });
    const { group } = query;

    if (!group || group !== "starred") {
      this.props.onGroupClick("starred");
      return;
    }

    this.props.onGroupClick("all");
  };

  render() {
    const { loading, traits, onCheck, selectedFilters } = this.props;
    const { filterSettings } = this.state;
    const query = queryString.parse(window.location.search, {
      arrayFormat: "bracket",
    });
    const { group } = query;

    if (loading || !traits) {
      return (
        <React.Fragment>
          <div className="Filter placeholder">
            <h4>&nbsp;</h4>
            <ul>
              <li>
                <p></p>
              </li>
              <li>
                <p></p>
              </li>
              <li>
                <p></p>
              </li>
            </ul>
          </div>
          <div className="Filter placeholder">
            <h4>&nbsp;</h4>
            <ul>
              <li>
                <p></p>
              </li>
              <li>
                <p></p>
              </li>
              <li>
                <p></p>
              </li>
            </ul>
          </div>
          <div className="Filter placeholder">
            <h4>&nbsp;</h4>
            <ul>
              <li>
                <p></p>
              </li>
              <li>
                <p></p>
              </li>
              <li>
                <p></p>
              </li>
            </ul>
          </div>
          <div className="Filter placeholder">
            <h4>&nbsp;</h4>
            <ul>
              <li>
                <p></p>
              </li>
              <li>
                <p></p>
              </li>
              <li>
                <p></p>
              </li>
            </ul>
          </div>
        </React.Fragment>
      );
    }

    return (
      <React.Fragment>
        <button
          onClick={this.props.onClearFilters}
          className="button button__link p__zero"
          style={{ paddingLeft: "20px" }}
        >
          Clear Filters
        </button>
        <div className="sortCtrls Filter">
          <Collapsible
            trigger={
              <h4>
                Sorting <ArrowDownIcon height="18" />
              </h4>
            }
            triggerClassName="cursor--pointer"
            triggerOpenedClassName="cursor--pointer"
          >
            <ul>
              {Object.keys(this.props.sortingValues).map(
                (sortingProperty: string) => (
                  <li key={sortingProperty}>
                    <span
                      className="h--sm w--full flex justify-between align-center cursor--pointer"
                      onClick={() => {
                        this.props.onSorting(
                          sortingProperty,
                          this.props.sortingValues[sortingProperty]
                        );
                      }}
                    >
                      {sortingNames[sortingProperty] ||
                        Helpers.camelToCapitalize(sortingProperty)}
                      <span className="icon">
                        <OrderIcon
                          orderDirection={
                            this.props.sortingValues[sortingProperty]
                          }
                        />
                      </span>
                    </span>
                  </li>
                )
              )}
            </ul>
          </Collapsible>
        </div>

        {this.buildBoards()}

        {Object.keys(traits)
          .filter((k) => FilterKeys.includes(k))
          .map((key) => {
            return (
              <FilterGroup
                key={`filter_group_${key}`}
                collapsible={true}
                name={key}
                traits={traits[key]}
                onCheck={onCheck}
                selectedItems={selectedFilters[key] || []}
                filterSettings={filterSettings[key] || undefined}
                onUpdateFilterSettings={this.onUpdateFilterSettings}
              />
            );
          })}

        <Collapsible
          triggerTagName="h4"
          trigger={
            <span>
              Status <ArrowDownIcon height="18" />
            </span>
          }
          triggerClassName="cursor--pointer"
          triggerOpenedClassName="cursor--pointer"
          className="Filter"
          openedClassName="Filter"
        >
          <ul>
            <li>
              <label className="CheckBox">
                <input
                  type="checkbox"
                  defaultChecked={
                    selectedFilters["status"] &&
                    selectedFilters["status"].indexOf("love") >= 0
                      ? true
                      : false
                  }
                  onChange={(e) => onCheck(e.target.checked, "status", "love")}
                />
                <span className="checkmark"></span>
              </label>
              <p>
                <i className="fas fa-heart" />
                love
              </p>
              <p></p>
            </li>
            <li>
              <label className="CheckBox">
                <input
                  type="checkbox"
                  defaultChecked={
                    selectedFilters["status"] &&
                    selectedFilters["status"].indexOf("back_burner") >= 0
                      ? true
                      : false
                  }
                  onChange={(e) =>
                    onCheck(e.target.checked, "status", "back_burner")
                  }
                />
                <span className="checkmark"></span>
              </label>

              <p>
                <i className="fas fa-fire-alt" />
                back burner
              </p>
              <p></p>
            </li>
            <li key={`filter_group_trait_starred`}>
              <label className="CheckBox">
                <input
                  type="checkbox"
                  defaultChecked={group && group === "starred"}
                  onChange={this.starred}
                />
                <span className="checkmark"></span>
              </label>
              <p>
                <i className="fas fa-star" />
                Starred
              </p>
              <p></p>
            </li>
            <li key={`filter_group_trait_archived`}>
              <label className="CheckBox">
                <input
                  type="checkbox"
                  defaultChecked={
                    selectedFilters["archived"]?.indexOf("true") >= 0
                      ? true
                      : false
                  }
                  onChange={(e) =>
                    onCheck(e.target.checked, "archived", "true")
                  }
                />
                <span className="checkmark"></span>
              </label>
              <p>
                <i className="fas fa-archive" style={{ color: "#757e8e" }}></i>
                archived
              </p>
              <p></p>
            </li>
          </ul>
        </Collapsible>

        <Collapsible
          triggerTagName="h4"
          trigger={
            <span>
              Score <ArrowDownIcon height="18" />
            </span>
          }
          triggerClassName="cursor--pointer"
          triggerOpenedClassName="cursor--pointer"
          className="Filter"
          openedClassName="Filter"
        >
          <div style={{ padding: "8px" }}>
            <Slider
              range
              allowCross={false}
              min={0}
              max={10}
              defaultValue={[0, 10]}
              onChange={this.onScoreChange}
            />
            <small>
              {this.state.minScore} - {this.state.maxScore}
            </small>
          </div>
        </Collapsible>

        <FilterGroup
          collapsible={true}
          key={"filter_group_author"}
          name={"Creator"}
          traits={traits.authors}
          onCheck={this.onCheckAuthor}
          selectedItems={selectedFilters["authors"] || []}
          filterSettings={filterSettings["authors"] || undefined}
          onUpdateFilterSettings={this.onUpdateFilterSettings}
        />

        <FilterGroup
          collapsible={true}
          key={"filter_group_contributors"}
          name={"contributors"}
          traits={traits.contributors}
          onCheck={this.onCheckContributors}
          selectedItems={selectedFilters["contributors"] || []}
          filterSettings={filterSettings["contributors"] || undefined}
          onUpdateFilterSettings={this.onUpdateFilterSettings}
        />

        <Collapsible
          triggerTagName="h4"
          trigger={
            <span>
              Ideas Per Page <ArrowDownIcon height="18" />
            </span>
          }
          triggerClassName="cursor--pointer"
          triggerOpenedClassName="cursor--pointer"
          className="Filter"
          openedClassName="Filter"
        >
          <ul>
            <li>
              <label className="CheckBox">
                <input
                  type="radio"
                  defaultChecked={true}
                  name="per_page"
                  onChange={(e) => this.props.onLimitChange(25)}
                />
                <span className="checkmark"></span>
              </label>
              <p>25</p>
              <p></p>
            </li>

            <li>
              <label className="CheckBox">
                <input
                  name="per_page"
                  type="radio"
                  defaultChecked={false}
                  onChange={(e) => this.props.onLimitChange(50)}
                />
                <span className="checkmark"></span>
              </label>
              <p>50</p>
              <p></p>
            </li>

            <li>
              <label className="CheckBox">
                <input
                  name="per_page"
                  type="radio"
                  defaultChecked={false}
                  onChange={(e) => this.props.onLimitChange(100)}
                />
                <span className="checkmark"></span>
              </label>
              <p>100</p>
              <p></p>
            </li>

            <li>
              <label className="CheckBox">
                <input
                  name="per_page"
                  type="radio"
                  defaultChecked={false}
                  onChange={(e) => this.props.onLimitChange(200)}
                />
                <span className="checkmark"></span>
              </label>
              <p>200</p>
              <p></p>
            </li>
          </ul>
        </Collapsible>
      </React.Fragment>
    );
  }
}

interface IFilterGroupProps {
  name: string;
  traits: Array<DataTypes.Trait>;
  onCheck(checked: boolean, type: string, value: string): void;
  selectedItems: Array<string>;
  filterSettings?: { showMore: boolean };
  onUpdateFilterSettings(
    key: string,
    prop: string,
    value: string | boolean
  ): void;
  collapsible?: boolean;
}

const FilterGroup = ({
  name,
  traits,
  onCheck,
  selectedItems,
  filterSettings,
  onUpdateFilterSettings,
  collapsible = false,
}: IFilterGroupProps) => {
  if (traits.length <= 0) {
    return null;
  }

  const options = (
    <ul>
      {traits.map((t, idx) => {
        if (filterSettings && filterSettings.showMore === false && idx > 4) {
          return null;
        }
        return (
          <li key={`filter_group_trait_${idx}`}>
            <label className="CheckBox">
              <input
                type="checkbox"
                defaultChecked={
                  selectedItems.indexOf(t.name) >= 0 ? true : false
                }
                onChange={(e) => onCheck(e.target.checked, name, t.name)}
              />
              <span className="checkmark"></span>
            </label>
            <p>{t.name}</p>
            <p>{t.total}</p>
          </li>
        );
      })}
      {traits.length > 5 && (
        <li>
          {filterSettings && filterSettings.showMore === false ? (
            <button
              className="btn btn-link"
              type="button"
              onClick={() => onUpdateFilterSettings(name, "showMore", true)}
            >
              show more
            </button>
          ) : (
            <button
              className="btn btn-link"
              type="button"
              onClick={() => onUpdateFilterSettings(name, "showMore", false)}
            >
              show less
            </button>
          )}
        </li>
      )}
    </ul>
  );

  if (collapsible) {
    return (
      <Collapsible
        triggerTagName="h4"
        trigger={
          <span>
            {name} <ArrowDownIcon height="18" />
          </span>
        }
        triggerClassName="cursor--pointer"
        triggerOpenedClassName="cursor--pointer"
        className="Filter"
        openedClassName="Filter"
      >
        {options}
      </Collapsible>
    );
  }

  return (
    <div className="Filter">
      <h4>{name}</h4>
      {options}
    </div>
  );
};

export { Filters };
