import { action } from 'typesafe-actions';
import { ActionTypes } from './types';
import * as DataTypes from '../types';

interface RequestError {
  statusCode?: number | undefined;
  message: any;
}

// Get Experiments
export const postSampleSizeRequest = (request: DataTypes.SampleSize )  => action(ActionTypes.POST_SAMPLE_SIZE_REQUEST, { request });
export const postSampleSizeSuccess = (response: Array<DataTypes.SampleSizeResult>) => action(ActionTypes.POST_SAMPLE_SIZE_SUCCESS, { response });
export const postSampleSizeError = (error: RequestError) => action(ActionTypes.POST_SAMPLE_SIZE_FAILURE, error);