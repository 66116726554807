import React, { Component } from "react";
import * as DataTypes from "store/types";

interface IProps {
  report: DataTypes.Report;
  experiences: Array<DataTypes.Experience>;
  onSelectedRow(rowIdx: number): void;
  selectedRowIdx: number;
}

interface IState {
  showNewMetricColumn: boolean;
}

class Table extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      showNewMetricColumn: false,
    };
  }

  private onFocus = (rowIdx: number) => {
    this.props.onSelectedRow(rowIdx);
  };

  render() {
    const { report, experiences, selectedRowIdx } = this.props;

    return (
      <React.Fragment>
        <div className="ResultsTable">
          <div className="ResultsTableWrapper">
            <ul className="ControlColumn">
              <li className="Column">
                <div className="Header"></div>
                <div className="Ledger">
                  <div>metric</div>
                </div>
                <MetricSelectors
                  key="metric_selector"
                  report={report}
                  selectedRow={selectedRowIdx}
                  onFocus={(rowIdx) => this.onFocus(rowIdx)}
                />
              </li>
            </ul>
            <ul
              className="ResultsMetrics"
              style={{ overflowX: "scroll", overflowY: "hidden" }}
            >
              {experiences.map((experience: DataTypes.Experience) => {
                return (
                  <ExperienceResult
                    key={`exp_${experience.id}`}
                    experience={experience}
                    metrics={report.metrics}
                    onFocus={(rowIdx) => this.onFocus(rowIdx)}
                    selectedRow={selectedRowIdx}
                  />
                );
              })}
            </ul>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
export { Table };

interface IMetricSelectorProps {
  report: DataTypes.Report;
  onFocus(row: number): void;
  selectedRow: number;
}

const MetricSelectors = ({
  report,
  onFocus,
  selectedRow,
}: IMetricSelectorProps) => {
  return (
    <React.Fragment>
      {report.metrics.map((metric: DataTypes.Metric, idx: number) => {
        return (
          <div
            key={`m_ctrl_${idx}`}
            className={
              selectedRow === idx ? "Metric Control active" : "Metric Control"
            }
            onClick={() => onFocus(idx)}
          >
            {metric.primary === true ? (
              <i className="primary fa fa-star" />
            ) : (
              <i className="primary fa " />
            )}
            <label>{metric.name}</label>
          </div>
        );
      })}
    </React.Fragment>
  );
};

interface IExperienceResultProps {
  experience: DataTypes.Experience;
  metrics: Array<DataTypes.Metric>;
  onFocus(row: number): void;
  selectedRow: number;
}

const ExperienceResult = ({
  experience,
  metrics,
  onFocus,
  selectedRow,
}: IExperienceResultProps) => {
  if (experience.type === "Experiment::Experience::Control") {
    return (
      <li className="Column Control">
        <div className="Header">{experience.name}</div>
        <div className="Ledger">
          <div>value</div>
        </div>
        {metrics.map((metric, idx) => {
          const result = metric.results
            ? metric.results.find((r) => r.experience_id === experience.id)
            : undefined;
          return (
            <div
              className="MetricWrapper"
              key={`exp_ctrl_${experience.id}_metric_${metric.id}`}
            >
              <div
                className={selectedRow === idx ? "Metric active" : "Metric"}
                style={{ borderBottom: "none" }}
              >
                <ResultInput
                  value={result && result.value}
                  tabIndex={idx + 1}
                  inputType={metric.metric_type || DataTypes.MetricType.FLOAT}
                  currencySymbol={metric.symbol}
                  onFocus={() => onFocus(idx)}
                />
              </div>

              <div
                className={
                  selectedRow === idx
                    ? "ResultDescription active"
                    : "ResultDescription"
                }
              >
                <input
                  style={{ color: "#2f4760" }}
                  type="text"
                  value={result?.description}
                  readOnly={true}
                />
              </div>
            </div>
          );
        })}
      </li>
    );
  } else {
    return (
      <li key={`exp_${experience.id}`} className="Column">
        <div className="Header">{experience.name}</div>
        <div className="Ledger">
          <div>value</div>
          <div>lift</div>
          <div>confidence</div>
        </div>
        {metrics.map((metric: DataTypes.Metric, idx: number) => {
          const result = metric.results
            ? metric.results.find((r) => r.experience_id === experience.id)
            : undefined;
          return (
            <div className="MetricWrapper">
              <div
                key={`exp_${experience.id}_metric_${metric.id}`}
                className={selectedRow === idx ? "Metric active" : "Metric"}
                style={{ borderBottom: "none" }}
              >
                <ResultInput
                  value={result && result.value}
                  tabIndex={idx + 1}
                  inputType={metric.metric_type || DataTypes.MetricType.FLOAT}
                  onFocus={() => onFocus(idx)}
                  currencySymbol={metric.symbol}
                />
                <ResultInput
                  value={result && result.lift}
                  tabIndex={idx + 1}
                  inputType={DataTypes.MetricType.PERCENT}
                  onFocus={() => onFocus(idx)}
                />
                <ResultInput
                  value={result && result.confidence}
                  tabIndex={idx + 1}
                  inputType={DataTypes.MetricType.PERCENT}
                  onFocus={() => onFocus(idx)}
                />
              </div>

              <div
                className={
                  selectedRow === idx
                    ? "ResultDescription active"
                    : "ResultDescription"
                }
              >
                <input
                  type="text"
                  style={{ color: "#2f4760" }}
                  value={result?.description}
                  readOnly={true}
                />
              </div>
            </div>
          );
        })}
      </li>
    );
  }
};

interface IResultInputProps {
  inputType: DataTypes.MetricType;
  tabIndex: number;
  value: number | undefined | string;
  onFocus(): void;
  currencySymbol?: string;
}

interface IResultInputState {
  inputValue: string;
}

class ResultInput extends Component<IResultInputProps, IResultInputState> {
  constructor(props: IResultInputProps) {
    super(props);
    this.state = {
      inputValue:
        this.props.value !== null && this.props.value !== undefined
          ? this.props.value.toString()
          : "",
    };
  }

  render() {
    const { tabIndex, onFocus, inputType, currencySymbol } = this.props;
    const { inputValue } = this.state;

    let inputSymbol = "";

    if (inputType === "currency") {
      if (
        !currencySymbol ||
        currencySymbol === DataTypes.CurrencySymbols.DOLLAR
      ) {
        inputSymbol = "dollar";
      }
      if (currencySymbol === DataTypes.CurrencySymbols.EURO) {
        inputSymbol = "euro";
      }

      if (currencySymbol === DataTypes.CurrencySymbols.POUND) {
        inputSymbol = "pound";
      }
    }

    return (
      <div className={`ResultInput ${inputType} ${inputSymbol}`}>
        <input
          type="text"
          style={{ color: "#2f4760" }}
          readOnly={true}
          tabIndex={tabIndex}
          value={inputValue}
          maxLength={inputType === DataTypes.MetricType.PERCENT ? 8 : 10}
          onFocus={(e) => onFocus()}
        />
      </div>
    );
  }
}
