import { ApiListOptions } from "store/types";
import { ApiClient } from "./client/api_client";

class Uxrs {
  static async index(options: ApiListOptions) {
    return await ApiClient.post("/v1/graphql", {
      query: `query uxrs($sort: String, $traits: [String!], $ids: [String!], $first: Int, $after: String, $search: String, $star: Boolean) {
        uxrs(sort: $sort, traits: $traits, ids: $ids, first: $first, after: $after, search :$search, star: $star) {
          nodes {
            id
            name
            description
            customOrder
            audienceCriteria
            participants
            methodology
            startDate
            endDate
            createdAt
            updatedAt
            brands
            audiences
            devices
            pages
            channels
            tags
            star
            teams
            methodKinds            
            user {
              id
              firstName
              lastName
              imageUrl
            }
          }
          pageInfo {
            endCursor
            startCursor
            hasPreviousPage
            hasNextPage
          }
        }
      }`,
      variables: {
        traits: options.q,
        sort: options.sort,
        search: options.search,
        ids: options.ids,
        first: options.limit,
        after: options.starting_after,
      },
    });
  }

  static async create(body: any) {
    return await ApiClient.post("/v1/uxr", body);
  }

  static async update(id: string, body: any) {
    return await ApiClient.put(`/v1/uxr/${id}`, body);
  }

  static async destroy(id: string) {
    return await ApiClient.delete(`/v1/uxr/${id}`);
  }

  static async show(id: string) {
    return await ApiClient.get(`/v1/uxr/${id}`);
  }

  static async traits() {
    return await ApiClient.get("/v1/uxr/traits");
  }

  static async shareTraits(slug: string) {
    return await ApiClient.get(`/v1/share/${slug}/idea_board/traits`);
  }

  static async addTraits(uxrId: string, trait: string, values: string[]) {
    return await ApiClient.post(`/v1/uxr/${uxrId}/traits`, {
      trait,
      values,
    });
  }

  static async reorder(id: string, position: number) {
    return await ApiClient.put(`/v1/uxr/${id}/order/${position}`, {});
  }

  static async addImage(uxrId: string, file: File) {
    const formData = new FormData();
    formData.append("upload", file);

    return await ApiClient.postMultipart(`/v1/uxr/${uxrId}/upload`, formData);
  }

  static async addDocument(uxrId: string, file: File) {
    const formData = new FormData();
    formData.append("upload", file);

    return await ApiClient.postMultipart(`/v1/uxr/${uxrId}/document`, formData);
  }

  static async deleteUpload(uxrId: string, uploadId: string) {
    return await ApiClient.delete(`/v1/uxr/${uxrId}/upload/${uploadId}`);
  }

  static async deleteDocument(uxrId: string, uploadId: string) {
    return await ApiClient.delete(`/v1/uxr/${uxrId}/document/${uploadId}`);
  }

  static async createAreaOfFocus(uxrId: string) {
    return await ApiClient.post(`/v1/uxr/${uxrId}/area_of_focus`, {});
  }

  static async updateAreaOfFocus(uxrId: string, areaId: string, body: any) {
    return await ApiClient.put(`/v1/uxr/${uxrId}/area_of_focus/${areaId}`, {
      area_of_focus: body,
    });
  }

  static async deleteAreaOfFocus(uxrId: string, areaId: string) {
    return await ApiClient.delete(`/v1/uxr/${uxrId}/area_of_focus/${areaId}`);
  }

  static async addSupportingData(uxrId: string, type: string) {
    return await ApiClient.post(`/v1/supporting_data/uxr/${uxrId}/${type}`, {});
  }

  static async updateSupportingData(uxrId: string, dataId: string, body: any) {
    return await ApiClient.put(`/v1/uxr/${uxrId}/supporting_data/${dataId}`, {
      supporting_data: body,
    });
  }

  static async deleteSupportingData(uxrId: string, dataId: string) {
    return await ApiClient.delete(`/v1/uxr/${uxrId}/supporting_data/${dataId}`);
  }

  static async addSupportingDataImage(
    uxrId: string,
    dataId: string,
    file: File
  ) {
    const formData = new FormData();
    formData.append("upload", file);

    return await ApiClient.postMultipart(
      `/v1/uxr/${uxrId}/supporting_data/${dataId}/upload`,
      formData
    );
  }

  static async deleteSupportingDataUpload(
    uxrId: any,
    dataId: any,
    uploadId: any
  ) {
    return await ApiClient.delete(
      `/v1/uxr/${uxrId}/supporting_data/${dataId}/upload/${uploadId}`
    );
  }

  static async addInsight(uxrId: string, insightId: string) {
    return await ApiClient.put(`/v1/uxr/${uxrId}/insight/${insightId}`, {});
  }

  static async removeInsight(uxrId: string, insightId: string) {
    return await ApiClient.delete(`/v1/uxr/${uxrId}/insight/${insightId}`);
  }

  static async createShare(uxrId: string) {
    return await ApiClient.post(`/v1/uxr/${uxrId}/share`, {});
  }

  static async updateShare(uxrId: any, shareId: any, body: any) {
    return await ApiClient.put(`/v1/uxr/${uxrId}/share/${shareId}`, {
      share: body,
    });
  }

  static async updateUpload(uxrId: string, uploadId: string, body: any) {
    return await ApiClient.put(`/v1/uxr/${uxrId}/upload/${uploadId}`, {
      upload: body,
    });
  }

  static async updateSupportingDataUpload(
    uxrId: string,
    supportingDataId: string,
    uploadId: string,
    body: any
  ) {
    return await ApiClient.put(
      `/v1/uxr/${uxrId}/supporting_data/${supportingDataId}/upload/${uploadId}`,
      {
        upload: body,
      }
    );
  }

  static async addUploadComment(uxrId: string, uploadId: string, body: any) {
    return await ApiClient.post(`/v1/uxr/${uxrId}/upload/${uploadId}/comment`, {
      comment: body,
    });
  }

  static async deleteUploadComment(
    uxrId: string,
    uploadId: string,
    commentId: string
  ) {
    return await ApiClient.delete(
      `/v1/uxr/${uxrId}/upload/${uploadId}/comment/${commentId}`
    );
  }

  static async deleteSupportingDataUploadComment(
    uxrId: any,
    supportingDataId: any,
    uploadId: any,
    commentId: any
  ) {
    return await ApiClient.delete(
      `/v1/uxr/${uxrId}/supporting_data/${supportingDataId}/upload/${uploadId}/comment/${commentId}`
    );
  }

  static async addSupportingDataUploadComment(
    uxrId: string,
    supportingDataId: string,
    uploadId: string,
    body: any
  ) {
    return await ApiClient.post(
      `/v1/uxr/${uxrId}/supporting_data/${supportingDataId}/upload/${uploadId}/comment`,
      {
        comment: body,
      }
    );
  }

  static async uploadInsights(uxrId: any, file: any) {
    const formData = new FormData();
    formData.append("file", file);

    return await ApiClient.postMultipart(
      `/v1/uxr/${uxrId}/insight/csv`,
      formData
    );
  }
}

export { Uxrs };
