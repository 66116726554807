import React, { Component } from "react";
import { connect } from "react-redux";
import { ApplicationState } from "store/types";
import * as DataTypes from "store/types";
import {
  Form,
  Input,
  Button,
  ValidatorTypes,
  FormField,
  FormFields,
} from "comps/form";
import { inviteNewUsersRequest } from "store/account/actions";
import { Spacer } from "comps/layout";
import { history } from "utils";

import "css/Setup.scss";
import { SetupStepsEnum } from "./types";

interface IProps {
  account: DataTypes.Account | null;
  inviteNewUsersRequest: (invites: any, redirectUrl: string) => void;
}

interface IState {
  invites: number;
  inviteFields: FormFields;
  addingInvite: boolean;
}

class InviteYourTeam extends Component<IProps, IState> {
  validator = {
    type: ValidatorTypes.EMAIL,
    messages: { type: "Please enter a valid email address." },
  };

  constructor(props: IProps) {
    super(props);

    this.state = {
      invites: 1,
      inviteFields: {
        invite0: { name: "invite0", value: "", validator: this.validator },
      },
      addingInvite: false,
    };
  }

  private onSubmit = (valid: boolean, dirty: boolean, data: any) => {
    let _valid = true;
    const transform: Array<string> = [];

    const validateEmail = (value: string) => {
      const re = /([^@\s]+)@((?:[-a-z0-9]+\.)+[a-z]{2,})/i;
      return re.test(value);
    };

    Object.values(data).forEach((item: any, index: number) => {
      if (index + 1 > this.state.invites) return;

      validateEmail(item.value);

      if (_valid && validateEmail(item.value)) {
        transform.push(item.value);
      } else {
        _valid = false;
      }
    });

    if (_valid) {
      this.props.inviteNewUsersRequest(
        transform,
        `?step=${SetupStepsEnum.CONGRATULATIONS}`
      );
    }
  };

  private skipInvites = () => {
    history.push(`?step=${SetupStepsEnum.CONGRATULATIONS}`);
  };

  private addInvite = (fields: FormFields) => {
    this.setState(
      {
        addingInvite: true,
      },
      () => {
        const newInviteName = `invite${this.state.invites}`;
        const inviteFields = { ...fields };
        inviteFields[newInviteName] = {
          name: newInviteName,
          value: "",
          validator: this.validator,
        };

        this.setState({
          invites: this.state.invites + 1,
          inviteFields: { ...inviteFields },
          addingInvite: false,
        });
      }
    );
  };

  private deleteInvite = (fields: FormFields) => {
    const newFields = { ...fields };
    delete newFields[`invite${this.state.invites - 1}`];

    this.setState(
      {
        addingInvite: true,
      },
      () => {
        this.setState({
          invites: this.state.invites - 1,
          inviteFields: newFields,
          addingInvite: false,
        });
      }
    );
  };

  render() {
    const { account } = this.props;
    const { addingInvite } = this.state;

    if (!account || addingInvite) return null;

    return (
      <React.Fragment>
        <h1>Invite Your Team {this.state.invites}</h1>
        <h4 className="subtitle friends">
          It's always better to work with friends.
        </h4>
        <Spacer />
        <p>
          Use the form below to invite a new user to this illuminate account,{" "}
          <strong>{window.location.host}</strong>. An email will be sent to each
          recipient so they can create their user profile.
        </p>
        <div className="AccountSettings">
          <Form
            formFields={this.state.inviteFields}
            onSubmit={this.onSubmit}
            FormComponent={({ fields, onChange }) => {
              const formComponent: any = Object.values(fields).map(
                (formField: FormField, index: number) =>
                  this.state.invites > index && (
                    <div key={formField.name}>
                      <div className="d-flex">
                        <div
                          className={
                            this.state.invites === 1 ? "full" : "invite-email"
                          }
                        >
                          <Input
                            field={formField}
                            onChange={(e) =>
                              onChange(formField, e.target.value)
                            }
                            className="form-control"
                          />
                        </div>
                        <div
                          className={
                            this.state.invites === 1 ? "" : "delete-invite"
                          }
                        >
                          {this.state.invites === index + 1 && index !== 0 && (
                            <button
                              title="remove"
                              onClick={() => this.deleteInvite(fields)}
                              className="btn btn-icon"
                            >
                              <i className="fas fa-trash-alt" />
                            </button>
                          )}
                        </div>
                      </div>

                      {index !== this.state.invites - 1 && <Spacer />}
                    </div>
                  )
              );
              return (
                <div>
                  {formComponent}
                  <div className="add-invite">
                    {this.state.invites < 10 ? (
                      <button
                        type="button"
                        className="btn btn-icon link-basic"
                        onClick={() => this.addInvite(fields)}
                      >
                        + Add Another Invite
                      </button>
                    ) : (
                      <Spacer />
                    )}
                  </div>
                  <div className="d-flex">
                    <Button className="btn btn-primary" text="next" />
                    <button
                      type="button"
                      className="btn btn-icon link-basic"
                      onClick={this.skipInvites}
                    >
                      Skip <i className="ml-2 fas fa-chevron-right"></i>
                    </button>
                  </div>
                </div>
              );
            }}
          />
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = ({ app, account }: ApplicationState) => ({
  account: account,
});

const mapDispatchToProps = {
  inviteNewUsersRequest,
};

const connectedPage = connect(
  mapStateToProps,
  mapDispatchToProps
)(InviteYourTeam);

export { connectedPage as InviteYourTeam };
