import React, { Component } from "react";
import * as DataTypes from "store/types";
import { Experience } from "./experience";
import { createExperienceRequest } from "store/experience/actions";
import { connect } from "react-redux";

interface IProps {
  useBBMethods: boolean;
  experiences: Array<DataTypes.Experience>;
  experimentType: string;
  currentUser: any;
  experiment: DataTypes.Experiment | null;
  onUpdate(experience: DataTypes.Experience | any): void;
  onDelete(experience: DataTypes.Experience | any): void;
  onViewImage(experience: DataTypes.Experience, image: DataTypes.Image): void;
  createExperienceRequest(experimentId: string, body: any): void;
  onExperimentChange(
    key: string,
    value: string | Array<string> | boolean
  ): void;
}

interface IState {
  selectedExperienceId: string | undefined;
  is_single_hypothesis: boolean;
}

class Experiences extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      selectedExperienceId: this.props.experiences[0]
        ? this.props.experiences[0].id
        : undefined,
      is_single_hypothesis:
        this.props.experiment?.is_single_hypothesis || false,
    };
  }

  private selectExperience = (experience: DataTypes.Experience) => {
    /**
     * FIXME: This was made to avoid saving data on the wrong experience.
     * The user would type something on any experience field an click on the link to the next experience
     * The saved data would be included on the seccond visited experience instead of the first.
     *
     * The cause:
     *    The setState function is beeing called before the on change for the child component, this makes the request
     *    to be sent with the wrong id.
     **/
    this.setState({ ...this.state, selectedExperienceId: "" });

    setTimeout(() => {
      this.setState({ ...this.state, selectedExperienceId: experience.id });
    }, 100);
  };

  private addExperience = () => {
    const { experiences } = this.props;

    const experienceBody = {
      name: `Experience #${experiences.length}`,
      traffic: 0,
    };

    this.props.createExperienceRequest(
      this.props.experiment?.id || "",
      experienceBody
    );
  };

  private isReadOnly = () => {
    return this.props.currentUser.role === "read-only";
  };

  render() {
    const { experiences, experimentType } = this.props;
    if (!experiences) return null;

    if (experiences.length === 0 && experimentType !== "adobe") {
      return (
        <section className="Experiences empty-state">
          <h4>Experiences</h4>
          <p>
            Provide important details and visuals. This increases the
            effectiveness of your repository and is used to populate your
            sharable Case Study.
          </p>
          <button
            className="btn btn-primary-light"
            onClick={this.addExperience}
          >
            <i className="fa fa-plus" />
            add experience
          </button>
        </section>
      );
    }

    const { selectedExperienceId } = this.state;

    let selectedExperience = experiences.find(
      (e) => e.id === selectedExperienceId
    );
    if (!selectedExperience) selectedExperience = experiences[0];

    return (
      <section className="Experiences">
        <div className="left">
          <h4>Experiences</h4>
          <p>
            Provide important details and visuals. This increases the
            effectiveness of your repository and is used to populate your
            sharable Case Study.
          </p>
          {experimentType !== "adobe" && !this.isReadOnly() ? (
            <button
              className="btn btn-primary-light"
              onClick={this.addExperience}
            >
              <i className="fa fa-plus" />
              add experience
            </button>
          ) : (
            <div className="mt-4"></div>
          )}

          <ul className="Experiences">
            {experiences.map((experience, idx) => {
              return (
                <li
                  key={`ex_${experience.id}`}
                  className={
                    selectedExperience &&
                    selectedExperience.id === experience.id
                      ? "active"
                      : ""
                  }
                >
                  {experience.type === "control" ? (
                    <i className="fa fa-star" />
                  ) : (
                    <i className="fa" />
                  )}
                  <button
                    type="button"
                    onClick={(e) => this.selectExperience(experience)}
                  >
                    {experience.name}
                  </button>
                </li>
              );
            })}
          </ul>

          <div className="form-group">
            <label className="CheckBox">
              <input
                type="checkbox"
                defaultChecked={
                  this.props.experiment?.is_single_hypothesis || false
                }
                onChange={(e) => {
                  this.props.onExperimentChange(
                    "is_single_hypothesis",
                    e.target.checked
                  );
                }}
              />
              <span className="checkmark" style={{ marginTop: "3px" }}></span>
              Enable test-level{" "}
              {this.props.useBBMethods ? "Whypothesis®" : "hypothesis"}
            </label>
            <p>
              Check here for test-level{" "}
              {this.props.useBBMethods ? "Whypothesis®" : "hypothesis"}.
              Otherwise, you will create a{" "}
              {this.props.useBBMethods ? "Whypothesis®" : "hypothesis"} per
              experience.
            </p>
          </div>
        </div>
        <div className="right">
          <div className="grey_box">
            {this.state.selectedExperienceId !== "" ? (
              <Experience
                currentUser={this.props.currentUser}
                experiment={this.props.experiment}
                experimentType={experimentType}
                useBBMethods={this.props.useBBMethods}
                experience={selectedExperience}
                onDelete={
                  experimentType !== "adobe" ? this.props.onDelete : undefined
                }
                onUpdate={this.props.onUpdate}
                onViewImage={this.props.onViewImage}
              />
            ) : null}
          </div>
        </div>
      </section>
    );
  }
}

const mapStateToProps = ({ router }: DataTypes.ApplicationState) => ({
  router,
});

const mapDispatchToProps = {
  createExperienceRequest,
};

const connectedPage = connect(mapStateToProps, mapDispatchToProps)(Experiences);

export { connectedPage as Experiences };
