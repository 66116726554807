import React, { Component } from 'react';
import * as DataTypes from 'store/types';

interface IProps {
  experiment: DataTypes.Experiment | null;
  saving: boolean;
  onSubmit(): void;
}

class BestInClass extends Component<IProps> {
  render() {
    return (
      <React.Fragment>
        <div className="header flex">
          <div className='mr__sm'>
            <i className='fas fa-trophy' style={{ color: "#f5a623", fontSize: '80px' }}></i>
          </div>

          <div>
            <h4 style={{ fontWeight: 300 }}>Best in Class</h4>
            <h3>Tests</h3>
          </div>
        </div>
        <div style={{ maxWidth: '600px' }} className="mt__sm">
          <p>With a win status selected, illuminate creates a Sharable Case Study for your test with one click. No more decks! To ensure your case study shines, here are some tips:</p>

          <ul style={{ listStyle: 'disc' }}>
            <li><p>Use the Summary to convey the inspiration, execution, results and next steps.</p></li>
            <li><p>Use rich text editor to <strong>bullet, bold and more.</strong></p></li>
            <li><p>Use the target icon in the image comment box to <strong>draw a box around notable elements in your screenshots.</strong></p></li>
            <li><p>Create as many reports as you need and don't underestimate the power of the <strong>Data Storytelling section</strong> below the data tables.</p></li>
            <li><p><strong>Link your insights</strong> - new and existing - to your tests to build a feedback loop. We bring it all together in the Insights section.</p></li>
            <li><p><strong>Use the share icon</strong> in the case study to share it with anyone. They don’t even need an account.</p></li>
          </ul>

          <div className="flex justify-center align-center mt__sm">
            <a href="https://sandbox.illuminate.app/cs/78a7634" target="_blank">
              <img src="/img/best_in_class_case_study.png" alt="Best in Class image" className='shadow__full' height={'106px'} />
            </a>
            <a href="https://sandbox.illuminate.app/cs/78a7634" target='_blank' style={{ width: '30%', marginLeft: '24px' }}>Check out this live, best in class example to see it in action!</a>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export { BestInClass };
