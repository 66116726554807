import React, { Component } from "react";
import { connect } from "react-redux";
import { history, Helpers } from "utils";
import { ApplicationState } from "store/types";
import {
  createLoadingSelector,
  createErrorMessageSelector,
} from "store/selectors";
import { initIdeaCreateRequest, createIdeaRequest } from "store/idea/actions";
import * as DataTypes from "store/types";
import { AppPage, AppPageProps } from "comps/pages";
import { Form, Input, ValidatorTypes, MultiSelect, Button } from "comps/form";
import { showIdeaBoardsRequest } from "store/idea_board_management/actions";
import { getIdeasTraitsRequest } from "store/idea_trait/actions";
import { convertFromRaw, EditorState, genKey } from "draft-js";

import "../../../css/Screens.scss";
import { Link } from "react-router-dom";
import { EditorInput } from "ui/editor_input/editor_input";
import Collapsible from "react-collapsible";
import { PainsGainsSelectInput } from "ui";
import { ArrowDownIcon } from "ui/arrow_down_icon";

interface IProps extends AppPageProps {
  account: DataTypes.Account | null;
  ideaTrait: any;
  saving: boolean;
  loading: boolean;
  ideaManagement: any;
  ideaBoardLoading: boolean;
  computedMatch: any;
  ideaBoard: DataTypes.IdeaBoard | null;
  showIdeaBoardsRequest: (boardId: string) => void;
  initIdeaCreateRequest: () => void;
  createIdeaRequest: (body: any) => void;
  getIdeasTraitsRequest: () => void;
}

interface IState {
  tactics: Array<DataTypes.PainGain>;
  isTacticsOpen: boolean;
  submitted: boolean;
}

class IdeaCreate extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      tactics: [],
      isTacticsOpen: false,
      submitted: false,
    };
  }

  componentDidMount = () => {
    this.props.initIdeaCreateRequest();
    this.props.getIdeasTraitsRequest();

    if (this.props.computedMatch?.params?.board_id) {
      this.props.showIdeaBoardsRequest(
        this.props.computedMatch?.params?.board_id
      );
    }

    document.addEventListener("keydown", this.escFunction, false);

    if (
      history.location.pathname === "/ideas/merge" &&
      this.props.ideaManagement.selected.length <= 1
    ) {
      history.goBack();
    }
  };

  componentWillUnmount = () => {
    document.removeEventListener("keydown", this.escFunction, false);
  };

  escFunction = (event: any) => {
    if (event.keyCode === 27) {
      history.goBack();
    }
  };

  private close = () => {
    history.goBack();
  };

  private onSubmit = (valid: boolean, dirty: boolean, data: any) => {
    let problemStateValid = false;
    if (data.problem.value) {
      problemStateValid = EditorState.createWithContent(convertFromRaw(data.problem.value)).getCurrentContent().hasText();
    }

    let proposedStateValid = false;
    if (data.proposed.value) {
      proposedStateValid = EditorState.createWithContent(convertFromRaw(data.proposed.value)).getCurrentContent().hasText();
    }

    if (valid && problemStateValid && proposedStateValid) {
      const idea = {
        name: data.name.value,
        problem: data.problem.value,
        proposed: data.proposed.value,
        audiences: data.audiences.value,
        devices: data.devices.value,
        pages: data.pages.value,
        tactics: this.state.tactics,
        idea_board_id: this.props.computedMatch?.params?.board_id,
        hypothesis: data.hypothesis.value,
        themes: data.themes.value,
        ideas_ids: this.props.ideaManagement.selected?.map(
          (idea: any) => idea.id
        ),
      };
      this.props.createIdeaRequest(idea);
    } else {
      this.setState({ submitted: true })
    }
  };

  private buildTitle = () => {
    if (this.isMerge()) {
      return (
        <div className="title">
          <h2>Merge Multiple Ideas into One New Idea</h2>
          <p>
            You are about to merge {this.props.ideaManagement.selected.length}{" "}
            ideas into one. Please review the merged attributes and edit if
            necessary.
          </p>
        </div>
      );
    }

    if (!this.props.computedMatch?.params?.board_id) {
      return (
        <div className="title">
          <h2>Create Idea</h2>
          <p>Use the form below to create a new test idea!</p>
        </div>
      );
    }

    const { ideaBoard } = this.props;

    if (!ideaBoard) {
      return null;
    }

    return (
      <React.Fragment>
        <div className="title">
          <h2>{ideaBoard.name}</h2>
          <EditorInput
            content={ideaBoard.description}
            onBlur={() => { }}
            readOnly={true}
          />

          <div className="mt-2">
            {ideaBoard.worksheet && (
              <Link to={`/ib/${ideaBoard.share?.uuid}/worksheet`}>
                View Worksheet
              </Link>
            )}
          </div>
        </div>
        <hr />
        <div className="title">
          <h2>Create Idea</h2>
          <p>Use the form below to create a new test idea!</p>
        </div>
      </React.Fragment>
    );
  };

  private devices = () => {
    if (this.isMerge()) {
      return [
        ...new Set(
          this.props.ideaManagement.selected
            .map((idea: any) => idea.devices)
            .flat()
        ),
      ];
    }

    if (this.props.ideaBoard?.devices) {
      return this.props.ideaBoard.devices;
    }

    return [];
  };

  private audiences = () => {
    if (this.isMerge()) {
      return [
        ...new Set(
          this.props.ideaManagement.selected
            .map((idea: any) => idea.audiences)
            .flat()
        ),
      ];
    }

    if (this.props.ideaBoard?.audiences) {
      return this.props.ideaBoard.audiences;
    }

    return [];
  };

  private pages = () => {
    if (this.isMerge()) {
      return [
        ...new Set(
          this.props.ideaManagement.selected
            .map((idea: any) => idea.pages)
            .flat()
        ),
      ];
    }

    if (this.props.ideaBoard?.pages) {
      return this.props.ideaBoard.pages;
    }

    return [];
  };

  private themes = () => {
    if (this.isMerge()) {
      return [
        ...new Set(
          this.props.ideaManagement.selected
            .map((idea: any) => idea.themes)
            .flat()
        ),
      ];
    }

    if (this.props.ideaBoard?.pages) {
      return this.props.ideaBoard.pages;
    }

    return [];
  };


  private isMerge = () => {
    return history.location.pathname === "/ideas/merge";
  };

  private problem = () => {
    if (!this.isMerge()) {
      return null;
    }

    return {
      blocks: this.props.ideaManagement.selected
        .map((idea: any) => idea.problem.blocks)
        .flat()
        .map((i: any) => ({ ...i, key: genKey() })),
      entityMap: {},
    };
  };

  private proposed = () => {
    if (!this.isMerge()) {
      return null;
    }

    return {
      blocks: this.props.ideaManagement.selected
        .map((idea: any) => idea.proposed.blocks)
        .flat()
        .map((i: any) => ({ ...i, key: genKey() })),
      entityMap: {},
    };
  };

  private setTactic = (tactics: any) => {
    this.setState({
      tactics: tactics,
    });
  };

  private tacticsCategory = () => {
    return this.state.tactics
      .map((tactic) => tactic.category)
      .filter((category, index, self) => self.indexOf(category) === index);
  }

  private getTacticsMessage = () => {
    const categories = this.tacticsCategory();
    if (categories.length === 0) {
      return null;
    }

    if (categories.length === 1) {
      switch (categories[0]) {
        case 'anxiety':
          return (<span>
            "Anxiety" strategies can teach you so much about what motivates and/or stresses your customers. Spend some extra time to detail the “why” behind this strategy.
          </span>);
        case 'mental effort':
          return (<span>
            "Mental Effort" strategies are the most frequently used Pains &amp; Gains<sup>&trade;</sup>, supporting the notion that making it simple for your customers is key.
          </span>);
        case 'time':
          return (<span>
            "Time" strategies are more nuanced, but they have the highest win rate of all Pains &amp; Gains<sup>&trade;</sup>. If you think time is a concern for your customers, you definitely want to try these out.

          </span>)
        case 'money':
          return (<span>
            There's more to resolving "Money" concerns than just offering discounts. Use these Pains &amp; Gains<sup>&trade;</sup> to alleviate money-related concerns without getting your finance department involved.
          </span>);
        case 'value':
          return (<span>
            Communicating the "benefit to the user" is the most used "Value" based Pain &amp; Gain<sup>&trade;</sup>. Why should your customer say yes? Tell them!
          </span>)
        default:
          return null;
      }
    }

    return <span>Pains &amp; Gains<sup>&trade;</sup> were designed to help you leverage knowledge from thousands of tests in our database. Use them to help you build your ideas.</span>
  }

  render() {
    const { saving, account, loading, ideaBoardLoading, ideaTrait } =
      this.props;

    const useBBMethods = account?.settings.use_bb_methods;

    if (loading === true || ideaBoardLoading) return null;
    if (!account) return null;

    return (
      <div className="FullScreen">
        <div className="header">
          <img src="/img/logo_sm.svg" alt="illuminate" className="logo" />
          <button type="button" className="btn btn-close" onClick={this.close}>
            <i className="fas fa-times" />
          </button>
        </div>
        <div className="form-body">
          <div className="content simple">
            {this.buildTitle()}
            <Form
              formFields={{
                name: {
                  name: "name",
                  value: "",
                  validator: {
                    type: ValidatorTypes.REQUIRED,
                    messages: { required: "Please enter a valid idea name" },
                  },
                },
                problem: {
                  name: "problem",
                  value: this.problem(),
                },
                proposed: {
                  name: "proposed",
                  value: this.proposed(),
                },
                audiences: {
                  name: "audiences",
                  value: this.audiences(),
                },
                devices: {
                  name: "devices",
                  value: this.devices(),
                },
                pages: {
                  name: "pages",
                  value: this.pages(),
                },
                tactics: {
                  name: "tactics",
                  value: [],
                },
                themes: {
                  name: "themes",
                  value: this.themes(),
                },
                hypothesis: {
                  name: 'hypothesis',
                  value: null,
                },
              }}
              onSubmit={this.onSubmit}
              FormComponent={({
                fields: {
                  name,
                  problem,
                  proposed,
                  audiences,
                  devices,
                  pages,
                  tactics,
                  themes,
                  hypothesis
                },
                onChange,
              }) => (
                <div>
                  <section>
                    <div className="title">
                      <div className="step-circle">1</div>
                      <h4>overview</h4>
                    </div>
                    <div className="body">
                      <div className="form-group">
                        <label>name</label>
                        <Input
                          field={name}
                          className="form-control"
                          onChange={(e) => onChange(name, e.target.value)}
                        />
                        <p>
                          This is the name of the idea that will be displayed
                          across illuminate, so use something unique, yet
                          intuitive.
                        </p>
                      </div>
                      <div className="form-group">
                        <label>
                          What problem/opportunity do you want to address?
                        </label>
                        <EditorInput
                          content={problem.value}
                          required={true}
                          submitted={this.state.submitted}
                          errorMessage="Please tell us what problem or opportunity led you to this idea"
                          onBlur={(e) => onChange(problem, e)}
                          area={true}
                        />
                        <p>
                          What did you uncover that makes you think this test
                          idea will win? Whether it is a data point, an insight,
                          an observation, or just a hunch - let us know.
                        </p>
                      </div>
                      <div className="form-group">
                        <label>
                          What is the proposed variation that would address the
                          problem/opportunity you identified?
                        </label>
                        <EditorInput
                          required={true}
                          errorMessage="Please describe the variation you’d like to test"
                          content={proposed.value}
                          onBlur={(e) => onChange(proposed, e)}
                          area={true}
                          submitted={this.state.submitted}
                        />
                        <p>
                          Describe the variation you think could beat the
                          control. Please be as specific as possible.
                        </p>
                      </div>
                    </div>
                  </section>
                  <section>
                    <div className="title">
                      <div className="step-circle">2</div>
                      <h4>targeting</h4>
                    </div>
                    <div className="body">
                      <div className="form-group">
                        <label>audiences</label>
                        <MultiSelect
                          field={audiences}
                          options={
                            ideaTrait?.traits && ideaTrait?.traits["audiences"]
                              ? Helpers.uniqueArray(
                                ideaTrait.traits["audiences"].map(
                                  (t: any) => {
                                    return t.name;
                                  }
                                )
                              )
                              : []
                          }
                          className="multiselect_input create"
                          onChange={(value) => onChange(audiences, value)}
                        />
                        <p>Specify what audience(s) your test should target.</p>
                      </div>
                      <div className="form-group">
                        <label>devices</label>
                        <MultiSelect
                          field={devices}
                          options={
                            ideaTrait?.traits && ideaTrait.traits["devices"]
                              ? Helpers.uniqueArray(
                                ideaTrait?.traits["devices"].map((t: any) => {
                                  return t.name;
                                })
                              )
                              : []
                          }
                          className="multiselect_input create"
                          onChange={(value) => onChange(devices, value)}
                        />
                        <p>
                          Track which device(s) should be included in your test.
                        </p>
                      </div>
                      <div className="form-group">
                        <label>pages</label>
                        <MultiSelect
                          field={pages}
                          options={
                            ideaTrait?.traits && ideaTrait.traits["pages"]
                              ? Helpers.uniqueArray(
                                ideaTrait.traits["pages"].map((t: any) => {
                                  return t.name;
                                })
                              )
                              : []
                          }
                          className="multiselect_input create"
                          onChange={(value) => onChange(pages, value)}
                        />
                        <p>
                          Specify the page(s) that should display your test.
                        </p>
                      </div>
                    </div>
                  </section>

                  <section className="Advanced">
                    <Collapsible
                      triggerTagName="label"
                      onOpen={() => {
                        this.setState({ isTacticsOpen: true })
                      }}
                      onClose={() => {
                        this.setState({ isTacticsOpen: false })
                      }}
                      open={this.state.isTacticsOpen}
                      triggerWhenOpen={
                        <div className="title">
                          <div className="step-circle">3</div>
                          <h4 style={{ cursor: 'pointer' }}>Advanced Options <ArrowDownIcon className="rotate--upsidedown" height="18" /></h4>
                        </div>
                      }
                      trigger={
                        <div className="title">
                          <div className="step-circle">3</div>
                          <h4 style={{ cursor: 'pointer' }}>Advanced Options <ArrowDownIcon height="18" /></h4>
                        </div>
                      }
                    >
                      <div className="body">
                        <div className="form-group">
                          <label>Brooks Bell Pains &amp; Gains<sup>&trade;</sup></label>
                          <PainsGainsSelectInput
                            activeItems={this.state.tactics}
                            name="pains_gains"
                            onChange={(value) => this.setTactic(value)}
                          />
                          <p>
                            Customers holding back due to Anxiety, Mental Effort, Money, Time or Value? We've got you covered.
                          </p>
                          {this.state.tactics.length > 0 ?
                            <p style={{ color: '#25be6f' }}>
                              <i className="fas fa-lightbulb" /> {' '}
                              {this.getTacticsMessage()}
                            </p>
                            :
                            null
                          }

                        </div>

                        <div className="form-group">

                          {useBBMethods ? <label>Whypothesis<sup>&reg;</sup> Customer Insights Tool</label> : <label>Hypothesis</label>}

                          <EditorInput
                            content={hypothesis.value}
                            onBlur={(e) => onChange(hypothesis, e)}
                            area={true}
                          />
                          {useBBMethods ?
                            <p>
                              Consider structuring your Whypothesis to say “If this wins, we may learn that customers…:
                            </p>
                            :
                            <p>
                              Consider structuring your Whypothesis to say “If this wins, we may learn that customers…:
                            </p>
                          }
                        </div>

                        <div className="form-group">
                          <label>Theme</label>
                          <MultiSelect
                            field={themes}
                            options={
                              ideaTrait?.traits && ideaTrait.traits["themes"]
                                ? Helpers.uniqueArray(
                                  ideaTrait.traits["themes"].map((t: any) => {
                                    return t.name;
                                  })
                                )
                                : []
                            }
                            className="multiselect_input create"
                            onChange={(value) => onChange(themes, value)}
                          />
                          <p>
                            Categorize your idea with a theme like a project reference or the overarching concept of the idea.
                          </p>
                        </div>
                      </div>
                    </Collapsible>
                  </section>

                  <hr />

                  <Button
                    className="btn btn-primary"
                    text="save idea"
                    isLoading={saving}
                  />
                </div>
              )}
            />
          </div>
        </div>
      </div >
    );
  }
}

const initLoader = createLoadingSelector(["@@idea/INIT_IDEA_CREATE"]);
const initError = createErrorMessageSelector(["@@idea/INIT_IDEA_CREATE"]);
const savingSelector = createLoadingSelector(["@@idea/CREATE_IDEA"]);
const ideaBoardLoading = createLoadingSelector([
  "@@idea_board_management/SHOW_IDEA_BOARDS",
]);

const mapStateToProps = ({
  router,
  app,
  account,
  ideaBoardManagement,
  ideaTrait,
  ideaManagement,
}: ApplicationState) => ({
  router: router,
  loading: initLoader(app.requests),
  initError: initError(app.errors),
  saving: savingSelector(app.requests),
  ideaBoardLoading: ideaBoardLoading(app.requests),
  account: account,
  ideaBoard: ideaBoardManagement.active,
  ideaTrait,
  ideaManagement,
});

const mapDispatchToProps = {
  createIdeaRequest,
  initIdeaCreateRequest,
  showIdeaBoardsRequest,
  getIdeasTraitsRequest,
};

const connectedPage = connect(
  mapStateToProps,
  mapDispatchToProps
)(AppPage(IdeaCreate));

export { connectedPage as IdeaCreate };
