import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import * as API from "../../api";
import * as Actions from "./actions";
import { ActionTypes } from "./types";

function* showBannerPlacement(action: any) {
  try {
    const { bannerPlacementSlug } = action.payload;

    // @ts-ignore
    const res = yield call(API.BannerPlacement.show, bannerPlacementSlug);

    yield put(Actions.showBannerPlacementSuccess(res.body));
  } catch (error: any) {
    yield put(Actions.showBannerPlacementError(error));
  }
}

function* watchShowBannerPlacement() {
  yield takeEvery(
    ActionTypes.SHOW_BANNER_PLACEMENT_REQUEST,
    showBannerPlacement
  );
}

function* experienceSaga() {
  yield all([fork(watchShowBannerPlacement)]);
}

export default experienceSaga;
