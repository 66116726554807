import React, { useCallback, useEffect, useState } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import { ConnectedRouter } from "connected-react-router";
import { History } from "history";
import * as AppUtils from "../utils/app";
import { AuthService } from "../utils/auth";
import * as Pages from "../pages";
import { App } from "comps/pages/app";
import { Boundry as ErrorBoundry } from "../comps/errors/boundry";
import { Unauthorized } from "pages/public";
import GA from "utils/google_analytics";
import { useKeycloak } from "@react-keycloak/web";
import { UserToken } from "api/user_token";
import { appConfig } from "./app";
import { history } from "utils";

enum PageTypes {
  PRIVATE = "private",
  PUBLIC = "public",
}

interface RouteProps {
  history: History;
}

const Routes = ({ history }: RouteProps) => {
  return (
    <ConnectedRouter history={history}>
      {GA.init() && <GA.RouteTracker />}
      <ErrorBoundry>
        <Switch>
          <Route path="/health" render={Pages.Health} />

          <PublicRoute exact path="/health" component={Pages.Health} />

          <AuthRoute exact path="/auth/:type" component={Pages.Auth} />
          <AuthRoute
            exact
            path="/account/choose"
            component={Pages.ChooseAccount}
          />
          <PublicRoute exact path="/login" component={Pages.Login} />
          <Route exact path="/terms" component={Pages.TermsOfService} />
          <Route exact path="/privacy_policy" component={Pages.PrivacyPolicy} />

          <PublicRoute
            exact
            path="/forgot_password"
            component={Pages.ForgotPwd}
          />
          <PublicRoute
            exact
            path="/google/:user/invite/:invite"
            component={Pages.InvitedGoogleUserForm}
          />
          <PublicRoute
            exatc
            path="/click_academy_redirect"
            component={Pages.ClickAcademyRedirect}
          />
          <PublicRoute
            exact
            path="/invite/:token"
            component={Pages.InvitedUserForm}
          />
          <PublicRoute
            exact
            path="/pwd/:token/reset"
            component={Pages.ResetPwd}
          />
          <PublicRoute
            exact
            path="/loading"
            component={Pages.Loading}
            className="signup"
          />

          <PublicRoute
            exatc
            path="/invite/social/success/:inviteToken"
            component={Pages.InviteSocialSuccess}
          />

          <SignUpRoute
            exact
            path="/signup/:token/activate"
            component={Pages.CreateAccount}
            className="signup"
          />
          <SignUpRoute
            exact
            path="/signup"
            component={Pages.SignUp}
            className="signup"
          />
          <SignUpRoute
            exact
            path="/signup_success"
            component={Pages.SignUpSuccess}
            className="signup"
          />

          <AppRoute exact path="/" component={Pages.Home} navIndex={-1} />
          <AppRoute
            exact
            path="/tests"
            component={Pages.Experiments}
            navIndex={0}
          />
          <AppRoute
            exact
            path="/tests/create"
            editPage={true}
            component={Pages.CreateExperiment}
            fullScreen={true}
          />
          <AppRoute
            exact
            path="/tests/create/idea"
            editPage={true}
            component={Pages.CreateExperiment}
            fullScreen={true}
          />
          <ShareRoute
            exact
            path="/tests/:id/experiences/:experience_id/images/:image_id"
            component={Pages.ExperimentImages}
            fullScreen={true}
          />
          <AppRoute
            exact
            path="/tests/:id/:view?/:report_id?"
            component={Pages.Experiment}
            navIndex={0}
          />

          <AppRoute
            exact
            path="/case_study/:id"
            component={Pages.CaseStudy}
            fullScreen={true}
          />

          <AppRoute exact path="/ideas" component={Pages.Ideas} navIndex={2} />
          <AppRoute
            exact
            path="/ideas/boards"
            component={Pages.IdeaBoards}
            navIndex={2}
          />
          <AppRoute
            exact
            path="/ideas/boards/create"
            editPage={true}
            component={Pages.IdeaBoardCreate}
            fullScreen={true}
          />
          <AppRoute
            exact
            path="/ideas/board/:board_id"
            component={Pages.Ideas}
            navIndex={1}
          />
          <AppRoute
            exact
            path="/ideas/board/:board_id/worksheet"
            component={Pages.IdeaWorkSheet}
            fullScreen={true}
          />
          <AppRoute
            exact
            path="/ideas/create"
            editPage={true}
            component={Pages.IdeaCreate}
            fullScreen={true}
          />
          <AppRoute
            exact
            path="/ideas/merge"
            editPage={true}
            component={Pages.IdeaCreate}
            fullScreen={true}
          />
          <AppRoute
            exact
            path="/ideas/create/:board_id"
            editPage={true}
            component={Pages.IdeaCreate}
            fullScreen={true}
          />
          <AppRoute
            exact
            path="/insights"
            component={Pages.Insights}
            navIndex={3}
          />
          <AppRoute
            exact
            path="/insights/create"
            editPage={true}
            component={Pages.InsightCreate}
            fullScreen={true}
          />

          <AppRoute
            exact
            path="/resources"
            component={Pages.Resources}
            navIndex={4}
          />

          <AppRoute
            exact
            path="/resources/integrations"
            adminOnly
            component={Pages.Integrations}
            navIndex={-1}
          />
          <AppRoute
            exact
            path="/resources/program_goals"
            adminOnly
            component={Pages.ProgramGoals}
            navIndex={-1}
          />

          <AppRoute
            exact
            path="/settings"
            adminOnly
            component={Pages.Settings}
            navIndex={-1}
          />
          <AppRoute
            exact
            path="/settings/users"
            adminOnly
            component={Pages.Users}
            navIndex={-1}
          />
          <AppRoute
            exact
            path="/settings/invites"
            adminOnly
            component={Pages.Invites}
            navIndex={-1}
          />
          <AppRoute
            exact
            path="/settings/subscription"
            adminOnly
            component={Pages.Subscription}
            navIndex={-1}
          />

          <AppRoute
            exact
            path="/profile"
            component={Pages.Profile}
            fullScreen={true}
          />
          <AppRoute
            exact
            path="/setup"
            component={Pages.Setup}
            fullScreen={true}
          />
          <AppRoute exact path="/tasks" component={Pages.Tasks} />
          <AppRoute
            exact
            path="/tools/calculator"
            fullScreen={true}
            component={Pages.Calculator}
          />

          {/* UXR SECTION */}
          <AppRoute exact path="/uxr" component={Pages.Uxrs} navIndex={1} />
          <AppRoute
            exact
            path="/uxr/create"
            editPage={true}
            component={Pages.CreateUxr}
            fullScreen={true}
          />

          <AppRoute
            exact
            path="/uxr/:id/:view?"
            component={Pages.Uxr}
            navIndex={1}
          />

          <AppRoute
            exact
            path="/uxr_share/:id"
            component={Pages.UxrShare}
            fullScreen={true}
          />

          <ShareRoute
            exact
            path="/uxr/:id/images/:image_id"
            component={Pages.UxrImages}
            fullScreen={true}
          />

          <ShareRoute
            exact
            path="/uxr/:id/supporting_data/:supporting_data_id/images/:image_id"
            component={Pages.UxrSupportingDataImages}
            fullScreen={true}
          />

          <ShareRoute
            exact
            path="/cs/:uuid"
            fullScreen={true}
            component={Pages.ShareCaseStudy}
          />
          <ShareRoute
            exact
            path="/uxrs/:uuid"
            fullScreen={true}
            component={Pages.ShareUxrShare}
          />
          <ShareRoute
            exact
            path="/cs/:uuid/experiences/:experience_id/images/:image_id"
            component={Pages.SharedExperimentImages}
            fullScreen={true}
          />
          <ShareRoute
            exact
            path="/ib/:uuid"
            fullScreen={true}
            component={Pages.ShareIdeaBoard}
          />
          <ShareRoute
            exact
            path="/ib/:uuid/worksheet"
            fullScreen={true}
            component={Pages.ShareIdeaBoardWorksheet}
          />
          <ShareRoute
            exatc
            path="/in/:uuid"
            fullScreen={true}
            component={Pages.ShareInsight}
          />

          <LogOutRoute exact path="/logout" />

          <Route exact path="/403" component={Pages.Forbidden} />
          <Route component={Pages.PageNotFound} />
        </Switch>
      </ErrorBoundry>
    </ConnectedRouter>
  );
};

const AppRoute = ({ component, ...props }: any) => {
  const { keycloak, initialized } = useKeycloak();
  const [tokenFetched, setTokenFetched] = useState(false);

  const fetchToken = useCallback(async () => {
    const host = window.location.host;
    const subdomain = host.split(".")[0];

    try {
      const userToken = await UserToken.show(subdomain, keycloak.token || "");

      localStorage.setItem(appConfig.ILLUMINATE_TOKEN, userToken.body.jwt);
      setTokenFetched(true);
      history.push(AppUtils.App.getRedirectPath());
    } catch (error: any) {
      if ((error as any).statusCode === 403) {
        history.push("/403");
      }
    }
  }, []);

  useEffect(() => {
    if (window.location.pathname === "/403") {
      return;
    }

    if (subdomain === "chooseaccount") {
      console.info("skiping fetch token:");
      return;
    }

    if (!initialized) {
      return;
    }

    fetchToken();
  }, [initialized]);

  const rootSubdomains = ["", "www"];
  // Set the redirect path for re-login if the user is not

  let redirectPath = "/";

  if (props.location.pathname) {
    redirectPath = props.location.pathname;

    if (props.location.search) {
      redirectPath = `${redirectPath}${props.location.search}`;
    }
  }

  AppUtils.App.setRedirectPath(redirectPath);
  // redirect to marketing page if the url is root and the subdomain is www or blank
  const subdomain = AppUtils.App.getSubDomain().toLowerCase();
  if (props.location.pathname === "/") {
    if (subdomain === "slack") {
      const urlParams = new URLSearchParams(window.location.search);
      const code = urlParams.get("code");
      const state = urlParams.get("state");

      if (state) {
        const [slug, kind] = state?.split(";");
        const url = `${process.env.REACT_APP_HTTP_PROTO}://${slug}.${process.env.REACT_APP_BASE_URL}/resources/integrations?slackCode=${code}&kind=${kind}`;

        return (
          <Route
            {...props}
            render={() => {
              window.location.replace(url);
              return;
            }}
          />
        );
      }
    }

    if (rootSubdomains.includes(subdomain)) {
      return (
        <Route
          {...props}
          render={() => {
            window.location.replace("https://www.brooksbell.com/illuminate");
            return;
          }}
        />
      );
    }
  }

  if (keycloak.authenticated) {
    const currentUser = AuthService.getActiveUser();

    if (props.editPage && currentUser?.role === "read-only") {
      return <Redirect to="/403" />;
    }

    if (props.adminOnly && currentUser?.role !== "admin") {
      return <Unauthorized />;
    }

    if (!tokenFetched) {
      return null;
    }

    return (
      <Route
        {...props}
        render={() => (
          <App
            Page={component}
            routerProps={{ ...props, currentUser, pageType: PageTypes.PRIVATE }}
          ></App>
        )}
      />
    );
  }

  if (!initialized) {
    return null;
  }

  if (subdomain === "chooseaccount" || subdomain === "brooks-bell") {
    return (
      <Redirect
        to={{ pathname: "/login", search: "redirect=account/choose" }}
      />
    );
  }

  return <Redirect to={{ pathname: "/login" }} />;
};

const PublicRoute = ({ component, ...props }: any) => {
  if (props.path === "/click_academy_redirect") {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; click-academy-redirect-account=`);
    const redirectAccount = parts.pop()?.split(";").shift();

    if (redirectAccount) {
      window.location.assign(
        `${process.env.REACT_APP_HTTP_PROTO}://${redirectAccount}.${process.env.REACT_APP_BASE_URL}`
      );
    }
  }

  return (
    <Route
      {...props}
      render={() => (
        <App
          Page={component}
          routerProps={{ ...props, pageType: PageTypes.PUBLIC }}
        ></App>
      )}
    />
  );
};

const ShareRoute = ({ component, ...props }: any) => {
  return (
    <Route
      {...props}
      render={() => (
        <App
          Page={component}
          routerProps={{ ...props, pageType: PageTypes.PRIVATE }}
        ></App>
      )}
    />
  );
};

const SignUpRoute = ({ component: Component, ...props }: any) => {
  const validSubdomains = ["", "www", "brooks-bell"];
  const subdomain = AppUtils.App.getSubDomain().toLowerCase();

  if (validSubdomains.includes(subdomain))
    return <Route {...props} render={() => <Component {...props} />} />;
  return <Redirect to="/404" />;
};

const AuthRoute = ({ component: Component, ...props }: any) => {
  const validSubdomains = ["auth", "chooseaccount"];
  const subdomain = AppUtils.App.getSubDomain().toLowerCase();
  if (validSubdomains.includes(subdomain))
    return <Route {...props} render={() => <Component {...props} />} />;
  return <Redirect to="/404" />;
};

const LogOutRoute = (_: any) => {
  const { keycloak, initialized } = useKeycloak();

  AuthService.logOut();

  useEffect(() => {
    if (!initialized) {
      return;
    }

    const host = window.location.host;
    const subdomain = host.split(".")[0];

    keycloak.logout({
      redirectUri: `${process.env.REACT_APP_HTTP_PROTO}://${subdomain}.${process.env.REACT_APP_KEYCLOAK_REDIRECT_PATH}`,
    });
  }, [initialized, keycloak]);

  return null;
};

export default Routes;
