import React from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Pie } from "react-chartjs-2";

ChartJS.register(ArcElement, Tooltip, Legend);

type Params = {
  dataSet: any;
};

const chartOptions = {
  plugins: {
    title: {
      display: true,
      text: "Chart.js Bar Chart - Stacked",
    },
  },
  responsive: true,
  maintainAspectRatio: true,
  interaction: {
    mode: "index" as const,
    intersect: false,
  },
};

export const CustomDataVizPieChart: React.FC<Params> = ({ dataSet }) => {
  return (
    <Pie
      options={{
        ...chartOptions,
        plugins: {
          title: {
            display: true,
            text: dataSet.title,
            position: "top",
            color: "#2f4760",
            font: {
              weight: "400",
              size: 24,
              family: "Roboto, sans-serif",
            },
          },
        },
      }}
      data={{ labels: dataSet.labels, datasets: dataSet.dataSets }}
    />
  );
};
