import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import * as API from "../../api";
import * as Actions from "./actions";
import { ActionTypes } from "./types";

function* getLeaderBoard(action: any) {
  try {
    // @ts-ignore
    const res = yield call(API.Dashboard.leaderBoard);

    yield put(Actions.getLeaderBoardSuccess(res.body));
  } catch (error: any) {
    yield put(Actions.getLeaderBoardError(error));
  }
}

function* getInsightsBoard(action: any) {
  try {
    // @ts-ignore
    const res = yield call(API.Dashboard.insights);

    yield put(Actions.getInsightsBoardSuccess(res.body));
  } catch (error: any) {
    yield put(Actions.getInsightsBoardError(error));
  }
}

function* getTodayBoard(action: any) {
  try {
    // @ts-ignore
    const res = yield call(API.Dashboard.today);

    yield put(Actions.getTodayBoardSuccess(res.body));
  } catch (error: any) {
    yield put(Actions.getTodayBoardError(error));
  }
}

function* getDidYouKnow(action: any) {
  try {
    // @ts-ignore
    const res = yield call(API.Dashboard.didYouKnow);

    yield put(Actions.getDidYouKnowSuccess(res.body.did_you_know));
  } catch (error: any) {
    yield put(Actions.getDidYouKnowError(error));
  }
}

function* watchGetLeaderBoard() {
  yield takeEvery(ActionTypes.GET_LEADER_BOARD_REQUEST, getLeaderBoard);
}

function* watchGetInsightsBoard() {
  yield takeEvery(ActionTypes.GET_INSIGHT_BOARD_REQUEST, getInsightsBoard);
}

function* watchGetTodayBoard() {
  yield takeEvery(ActionTypes.GET_TODAY_BOARD_REQUEST, getTodayBoard);
}

function* watchGetDidYouKnow() {
  yield takeEvery(ActionTypes.GET_DID_YOU_KNOW_REQUEST, getDidYouKnow);
}

function* dashboardSaga() {
  yield all([
    fork(watchGetDidYouKnow),
    fork(watchGetLeaderBoard),
    fork(watchGetInsightsBoard),
    fork(watchGetTodayBoard),
  ]);
}

export default dashboardSaga;
