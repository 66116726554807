import React, { Component } from "react";
import * as DataTypes from "store/types";

interface IProps {
  experiment: DataTypes.Experiment;
  saving: boolean;
  onSubmit(selectedReports: string[], selectedDataVizs: string[]): void;
}

interface IState {
  selectedReports: string[];
  selectedDataVizs: string[];
}

class SelectReports extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    const { experiment } = this.props;

    let setSelected: string[] = [];
    let setSelectedDataViz: string[] = [];

    if (experiment.case_study?.reports) {
      experiment.reports.forEach((report, idx) => {
        if (report.id) {
          if (experiment.case_study?.reports?.includes(report.id)) {
            setSelected.push(report.id);
          }
        }
      });
    }

    if (experiment.case_study?.data_vizs) {
      experiment.custom_data_viz.forEach((dataViz: any) => {
        if (dataViz.id) {
          if (experiment.case_study?.data_vizs?.includes(dataViz.id)) {
            setSelectedDataViz.push(dataViz.id);
          }
        }
      });
    }

    this.state = {
      selectedReports: setSelected || [],
      selectedDataVizs: setSelectedDataViz || [],
    };
  }

  private onCheck = (checked: boolean, report: DataTypes.Report) => {
    const sReport = this.state.selectedReports;
    if (report.id) {
      if (checked === true) {
        sReport.push(report.id);
      } else {
        const iIdx = sReport.findIndex((i) => i === report.id);
        if (iIdx >= 0) sReport.splice(iIdx, 1);
      }

      this.setState({ ...this.state, selectedReports: sReport });
    }
  };

  private onCheckDataViz = (checked: boolean, dataViz: any) => {
    const sDataViz = this.state.selectedDataVizs;
    if (dataViz.id) {
      if (checked === true) {
        sDataViz.push(dataViz.id);
      } else {
        const iIdx = sDataViz.findIndex((i) => i === dataViz.id);
        if (iIdx >= 0) sDataViz.splice(iIdx, 1);
      }

      this.setState({ ...this.state, selectedDataVizs: sDataViz });
    }
  };

  render() {
    const { experiment, onSubmit, saving } = this.props;
    const { selectedReports, selectedDataVizs } = this.state;

    return (
      <React.Fragment>
        <div className="header">
          <h3>Select Reports</h3>
          <p>
            Use the checkboxes to select the reports you would like to show in
            this case study
          </p>
        </div>

        <div className="body">
          {experiment.reports.map((report) => {
            return (
              <div key={`report_${report.id}`} className="ReportSelector">
                <div className="selector">
                  <label className="CheckBox">
                    <input
                      title={report.name}
                      type="checkbox"
                      defaultChecked={selectedReports.includes(
                        report.id ? report.id : "0"
                      )}
                      onChange={(e) => this.onCheck(e.target.checked, report)}
                    />
                    <span className="checkmark"></span>
                  </label>
                </div>
                <div className="report">
                  <h4>{report.name}</h4>
                </div>
              </div>
            );
          })}
          {experiment.custom_data_viz.map((dataViz: any) => {
            return (
              <div key={`report_${dataViz.id}`} className="ReportSelector">
                <div className="selector">
                  <label className="CheckBox">
                    <input
                      title={dataViz.name}
                      type="checkbox"
                      defaultChecked={selectedDataVizs.includes(
                        dataViz.id ? dataViz.id : "0"
                      )}
                      onChange={(e) =>
                        this.onCheckDataViz(e.target.checked, dataViz)
                      }
                    />
                    <span className="checkmark"></span>
                  </label>
                </div>
                <div className="report">
                  <h4>{dataViz.name}</h4>
                </div>
              </div>
            );
          })}
        </div>
        <div className="cntrl-bar">
          {saving === true ? (
            <button
              title="Loading"
              className="btn btn-primary"
              type="submit"
              disabled={true}
            >
              <i className="fas fa-circle-notch fa-spin" />
            </button>
          ) : (
            <button
              title="save"
              className="btn btn-primary"
              onClick={() => onSubmit(selectedReports, selectedDataVizs)}
            >
              save
            </button>
          )}
        </div>
      </React.Fragment>
    );
  }
}

export { SelectReports };
