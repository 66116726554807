import React, { Component } from "react";
import moment from "moment";
import * as DataTypes from "store/types";
import { SelectInput, MultiSelectInput, DatePicker } from "ui";
import { Experiences } from "./";
import { Helpers, history } from "utils";
import { EditorInput } from "ui/editor_input/editor_input";
import { createExperimentsTraitsRequest } from "store/experiment_trait/actions";
import { updateExperienceRequest } from "store/experience/actions";
import { connect } from "react-redux";
import { AuthService } from "utils/auth";

interface IProps {
  account: DataTypes.Account;
  experiment: DataTypes.Experiment;
  currentUser: any;
  traits: { [key: string]: Array<DataTypes.Trait> } | null;
  onChange(key: string, value: string | Array<string>): void;
  onExperienceChange(experience: DataTypes.Experience): void;
  onOverallWinnerChange(experience: DataTypes.Experience): void;
  onExperienceDelete(experience: DataTypes.Experience): void;
  onViewImage(experience: DataTypes.Experience, image: DataTypes.Image): void;
  onAddProgramGoal(experimentId: string, programGoal: any): void;
  onDeleteProgramGoal(goal: any): void;
  createExperimentsTraitsRequest: (
    experimentId: string,
    traitName: string,
    values: string[]
  ) => void;
  updateExperienceRequest: (
    experimentId: string,
    experienceId: string,
    body: any
  ) => void;
  onUpdateProgramGoal: (
    experimentId: string,
    programGoalId: string,
    body: any
  ) => void;
}

interface IState {
  minimumEndDate?: Date;
  maximumStartDate?: Date;
  editingProgramGoal: {
    program_goal?: DataTypes.ProgramGoal;
    impact_value?: number;
  } | null;
}

class Details extends Component<IProps, IState> {
  state: IState = {
    editingProgramGoal: null,
  };

  private setOverallWinner = (experience: DataTypes.Experience) => {
    const experimentId = this.props.experiment.id || "";
    const experienceId = experience.id;

    this.props.updateExperienceRequest(experimentId, experienceId, {
      ...experience,
      overall_winner: true,
    });
  };

  private onDateChange = (name: string) => (value: string) => {
    const { onChange } = this.props;
    if (name === "start_date") {
      const minimumEndDate = moment(value).toDate();
      this.setState({ minimumEndDate });
    }
    if (name === "end_date") {
      const maximumStartDate = moment(value).toDate();
      this.setState({ maximumStartDate });
    }
    return onChange(name, value.toString());
  };

  componentDidMount() {
    const { experiment } = this.props;

    if (experiment.start_date && experiment.end_date)
      this.setState({
        minimumEndDate: moment(experiment.start_date).toDate(),
        maximumStartDate: moment(experiment.end_date).toDate(),
      });
  }

  experimentStatus(status: string) {
    if (status === "undecided") {
      return null;
    }

    if (status === "winner") {
      return "win";
    }

    return Helpers.snakeToTitle(status);
  }

  availableGoals() {
    const { account, experiment } = this.props;
    if (
      !experiment ||
      !account ||
      !account.program_goals ||
      !experiment.program_goals
    ) {
      return null;
    }

    const existingGoalIds = experiment.program_goals.map(
      (pg) => pg.program_goal.id
    );
    const accountGoals = account.program_goals;

    const availableGoals = accountGoals.filter(
      (pg) => !existingGoalIds.includes(pg.id!)
    );

    return availableGoals;
  }

  nextProgramGoal() {
    const availableGoals = this.availableGoals();
    if (!availableGoals) {
      return null;
    }

    return availableGoals[0];
  }

  render() {
    const { account, experiment, traits, onChange } = this.props;

    const overall_winner = experiment.experiences.find(
      (e) => e.overall_winner === true
    );

    const availableGoals = this.availableGoals();
    const nextGoal = this.nextProgramGoal();

    return (
      <React.Fragment>
        <div className="Details">
          <section className="General">
            <div className="left">
              <div className="form-group">
                <ul className="DateRangePicker">
                  <li>
                    <label>start date</label>
                    <DatePicker
                      key="start_date"
                      className="form-control"
                      onChange={this.onDateChange("start_date")}
                      date={
                        experiment.start_date
                          ? moment(experiment.start_date, "YYYY-MM-DD").toDate()
                          : moment().toDate()
                      }
                      maxDate={this.state.maximumStartDate}
                    />
                  </li>
                  <li>
                    <label>end date</label>
                    <DatePicker
                      key="end_date"
                      className="form-control"
                      onChange={this.onDateChange("end_date")}
                      date={
                        experiment.end_date
                          ? moment(experiment.end_date, "YYYY-MM-DD").toDate()
                          : moment().toDate()
                      }
                      minDate={this.state.minimumEndDate}
                    />
                  </li>
                </ul>
              </div>
              <div className="form-group">
                <label>summary</label>
                <EditorInput
                  alwaysShowTollbar={true}
                  alwaysFocused={true}
                  content={experiment.description}
                  onBlur={(value: any) => onChange("description", value)}
                />
              </div>

              <div className="d-flex flex-row">
                {account && account.settings.brands && (
                  <div className="form-group w-50">
                    <label>Brands</label>
                    <MultiSelectInput
                      activeItems={experiment.brands}
                      name="brands"
                      items={
                        traits && traits["brands"]
                          ? Helpers.uniqueArray(
                              traits["brands"].map((t) => {
                                return t.name;
                              })
                            )
                          : []
                      }
                      onChange={(values) =>
                        this.props.createExperimentsTraitsRequest(
                          experiment.id || "",
                          "brands",
                          values
                        )
                      }
                    />
                  </div>
                )}
                {account && account.settings.teams && (
                  <div className="form-group w-50">
                    <label>Teams</label>
                    <MultiSelectInput
                      activeItems={experiment.teams}
                      name="teams"
                      items={
                        traits && traits["teams"]
                          ? Helpers.uniqueArray(
                              traits["teams"].map((t) => {
                                return t.name;
                              })
                            )
                          : []
                      }
                      onChange={(values) =>
                        this.props.createExperimentsTraitsRequest(
                          experiment.id || "",
                          "teams",
                          values
                        )
                      }
                    />
                  </div>
                )}
              </div>

              <div className="d-flex flex-row">
                {account?.settings?.channels && (
                  <div className="form-group w-50">
                    <label>Channels</label>
                    <MultiSelectInput
                      activeItems={experiment.channels}
                      name="tags"
                      items={
                        traits && traits["channels"]
                          ? Helpers.uniqueArray(
                              traits["channels"].map((t) => {
                                return t.name;
                              })
                            )
                          : []
                      }
                      onChange={(values) =>
                        this.props.createExperimentsTraitsRequest(
                          experiment.id || "",
                          "channels",
                          values
                        )
                      }
                    />
                  </div>
                )}

                <div
                  className={`form-group ${
                    account?.settings?.channels ? "w-50" : "w-100"
                  }`}
                >
                  <label>Tags</label>
                  <MultiSelectInput
                    activeItems={experiment.tags}
                    name="tags"
                    items={
                      traits && traits["tags"]
                        ? Helpers.uniqueArray(
                            traits["tags"].map((t) => {
                              return t.name;
                            })
                          )
                        : []
                    }
                    onChange={(values) =>
                      this.props.createExperimentsTraitsRequest(
                        experiment.id || "",
                        "tags",
                        values
                      )
                    }
                  />
                </div>
              </div>
            </div>
            <div className="right">
              <div className="grey_box">
                <div className="form-group">
                  <label>win status</label>
                  <p>What was the overall win status of this test?</p>
                  <SelectInput
                    value={this.experimentStatus(experiment.status)}
                    name="win_status"
                    placeholder="select"
                    items={["win", "flat", "loss", "data not available"]}
                    onChange={(value) => {
                      let status = value === "win" ? "winner" : value;
                      if (status === "data not available") {
                        status = "data_not_available";
                      }

                      onChange("status", status);
                    }}
                  />
                </div>
                {experiment.status === "winner" && (
                  <div className="form-group">
                    <label>overall winner</label>
                    <p>
                      This should be your suggestion on which experience to
                      implement.
                    </p>
                    <SelectInput
                      value={overall_winner ? overall_winner.name : ""}
                      name="overall_winner"
                      placeholder="select overall winner"
                      searchEnabled={false}
                      disableAddNew={true}
                      itemKey="name"
                      items={
                        experiment
                          ? experiment.experiences.map((t) => {
                              return t;
                            })
                          : []
                      }
                      onChange={(value) => this.setOverallWinner(value as any)}
                    />
                  </div>
                )}

                <div className="form-group" style={{ marginBottom: "4px" }}>
                  <label>program impact</label>
                  <p>How did this test impact your program goal?</p>
                  {experiment?.program_goals
                    ? experiment.program_goals.map((goal, index) => (
                        <div
                          style={{
                            marginBottom:
                              index < experiment.program_goals.length - 1
                                ? "10px"
                                : 0,
                          }}
                          key={`experiment-existing-program-goals-${goal.id}`}
                        >
                          <div className="flex">
                            <SelectInput
                              className="fgrow-1"
                              value={goal.program_goal.name}
                              name="program_impact"
                              placeholder="select"
                              searchEnabled={false}
                              disableAddNew={true}
                              itemKey="name"
                              items={
                                availableGoals
                                  ? availableGoals.map((t: any) => {
                                      return t;
                                    })
                                  : []
                              }
                              onChange={(value: any) => {
                                this.props.onUpdateProgramGoal(
                                  experiment.id!,
                                  goal.id,
                                  { account_program_goal_id: value.id }
                                );
                              }}
                            />

                            <button
                              className="btn btn-ctrl"
                              style={{
                                width: "48px",
                                color: "#757e8e",
                                marginBottom: 0,
                              }}
                              onClick={() =>
                                this.props.onDeleteProgramGoal(goal)
                              }
                            >
                              <i className="fas fa-trash"></i>
                            </button>
                          </div>

                          <div className="d-flex">
                            <ImpactValueInput
                              programGoal={goal.program_goal}
                              value={goal.impact_value}
                              onUpdate={(value) =>
                                this.props.onUpdateProgramGoal(
                                  experiment.id!,
                                  goal.id,
                                  {
                                    impact_value: value,
                                    impact_polarity:
                                      Number(value) > 0
                                        ? "increased"
                                        : "decreased",
                                  }
                                )
                              }
                            />
                            <div style={{ width: "51px" }}></div>
                          </div>
                        </div>
                      ))
                    : null}
                </div>

                <button
                  className={`button button__secondary ${
                    !nextGoal ? "disabled" : ""
                  }`}
                  style={{ paddingTop: 0, paddingBottom: 0, paddingLeft: 0 }}
                  disabled={!nextGoal}
                  onClick={() => {
                    if (!nextGoal) {
                      return null;
                    }

                    this.props.onAddProgramGoal(this.props.experiment.id!, {
                      impact_value: 0,
                      impact_polarity: "increased",
                      account_program_goal_id: nextGoal.id,
                    });
                  }}
                >
                  Add a program goal
                </button>

                <div className="form-group">
                  <label>Insights</label>
                  <p>
                    Next, be sure to document the{" "}
                    <strong
                      style={{ color: "#2A88F2", cursor: "pointer" }}
                      onClick={() =>
                        history.push(`/tests/${experiment.id}/insights`)
                      }
                    >
                      insights
                    </strong>{" "}
                    you learned from this test. We'll link them to this test so
                    you can build your{" "}
                    <strong
                      style={{ color: "#2A88F2", cursor: "pointer" }}
                      onClick={() => history.push(`/insights`)}
                    >
                      insights library
                    </strong>
                    .
                  </p>
                </div>
              </div>
            </div>
          </section>
        </div>
        <hr />
        <div>
          <section className="Targeting">
            <div className="left">
              <h4>Targeting</h4>
              <p>
                Select the page(s), device(s), and audience(s) that are part of
                this test.
              </p>
            </div>
            <div className="right">
              <div className="body">
                <div className="form-group">
                  <label>Audience(s)</label>
                  <p>Specify what audience(s) are targeted in this test.</p>
                  <MultiSelectInput
                    activeItems={experiment.audiences}
                    name="audiences"
                    items={
                      traits && traits["audiences"]
                        ? Helpers.uniqueArray(
                            traits["audiences"].map((t) => {
                              return t.name;
                            })
                          )
                        : []
                    }
                    onChange={(values) =>
                      this.props.createExperimentsTraitsRequest(
                        experiment.id || "",
                        "audiences",
                        values
                      )
                    }
                  />
                </div>
                <div className="form-group">
                  <label>Device(s)</label>
                  <p>Specify which device(s) are targeted in this test.</p>
                  <MultiSelectInput
                    activeItems={experiment.devices}
                    name="devices"
                    items={
                      traits && traits["devices"]
                        ? Helpers.uniqueArray(
                            traits["devices"].map((t) => {
                              return t.name;
                            })
                          )
                        : []
                    }
                    onChange={(values) =>
                      this.props.createExperimentsTraitsRequest(
                        experiment.id || "",
                        "devices",
                        values
                      )
                    }
                  />
                </div>
                <div className="form-group">
                  <label>Page(s)</label>
                  <p>Specify the page(s) that are targeted in this test.</p>
                  <MultiSelectInput
                    activeItems={experiment.pages}
                    name="pages"
                    items={
                      traits && traits["pages"]
                        ? Helpers.uniqueArray(
                            traits["pages"].map((t) => {
                              return t.name;
                            })
                          )
                        : []
                    }
                    onChange={(values) =>
                      this.props.createExperimentsTraitsRequest(
                        experiment.id || "",
                        "pages",
                        values
                      )
                    }
                  />
                </div>
              </div>
            </div>
          </section>
        </div>
        <hr />
        <Experiences
          currentUser={this.props.currentUser}
          experiment={experiment}
          useBBMethods={account.settings.use_bb_methods}
          experiences={experiment.experiences}
          onUpdate={this.props.onExperienceChange}
          onDelete={this.props.onExperienceDelete}
          onViewImage={this.props.onViewImage}
          experimentType={experiment.type}
          onExperimentChange={this.props.onChange}
        />
      </React.Fragment>
    );
  }
}

const mapStateToProps = ({ experimentTrait }: DataTypes.ApplicationState) => ({
  experimentTrait,
});

const mapDispatchToProps = {
  updateExperienceRequest,
  createExperimentsTraitsRequest,
};

const connectedPage = connect(mapStateToProps, mapDispatchToProps)(Details);

export { connectedPage as Details };

interface IImpactValueInputProps {
  value: number | undefined;
  programGoal?: DataTypes.ProgramGoal;
  onUpdate(value: string): void;
}

interface IImpactValueInputState {
  inputValue: string;
}

class ImpactValueInput extends Component<
  IImpactValueInputProps,
  IImpactValueInputState
> {
  constructor(props: IImpactValueInputProps) {
    super(props);
    this.state = {
      inputValue:
        this.props.value && this.props.value !== null
          ? this.props.value.toLocaleString()
          : "",
    };
  }

  private onChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value.toString().trim();
    const numberRegex = /^[-]?\d*[.|\d]?[\d]*?$/;
    if (numberRegex.test(value) || value === "") {
      this.setState({ ...this.state, inputValue: value });
    } else {
      e.preventDefault();
    }
  };

  private isReadOnly = () => {
    const user = AuthService.getActiveUser();

    return user.role === "read-only";
  };

  render() {
    const { onUpdate, programGoal } = this.props;
    const { inputValue } = this.state;
    if (!programGoal) return null;

    let symbol = "";

    if (programGoal.data_type === "currency") {
      if (!programGoal.symbol || programGoal.symbol === "DOLLAR") {
        symbol = "dollar";
      } else if (programGoal.symbol === "EURO") {
        symbol = "euro";
      } else if (programGoal.symbol === "POUND") {
        symbol = "pound";
      }
    }

    return (
      <div
        className={`ImpactValue nomargin ${programGoal?.data_type} ${symbol}`}
      >
        <input
          type="text"
          tabIndex={1}
          disabled={!programGoal}
          className="form-control"
          value={inputValue}
          maxLength={12}
          onChange={this.onChangeHandler}
          onBlur={(e) => onUpdate(e.target.value)}
          placeholder="Please enter the annualized impact to your program goal"
          readOnly={this.isReadOnly()}
        />
      </div>
    );
  }
}
