import React, { Component } from "react";
import { Dispatch } from "redux";
import { connect } from "react-redux";
import { ApplicationState } from "store/types";

import { AppPage, AppPageProps } from "comps/pages";
import { SubNav } from "ui/navs";

interface IProps extends AppPageProps {}

class Subscription extends Component<IProps> {
  render() {
    return (
      <div className="Settings">
        <div className="container">
          <SubNav
            activeIdx={4}
            navItems={[
              { name: "account", link: "/settings" },
              { name: "users", link: "/settings/users" },
              { name: "invites", link: "/settings/invites" },
              { name: "subscription", link: "/settings/subscription" },
            ]}
          />
          <div className="header">
            <h4>Subscription</h4>
            <p>Use this section to manage your subscription.</p>
          </div>
          <section></section>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (_: ApplicationState) => ({});

const mapDispatchToProps = (_: Dispatch) => ({});

const connectedPage = connect(
  mapStateToProps,
  mapDispatchToProps
)(AppPage(Subscription));

export { connectedPage as Subscription };
