import { action } from "typesafe-actions";
import { Dispatch } from "redux";
import { ActionTypes } from "./types";
import * as DataTypes from "../types";

interface RequestError {
  statusCode?: number | undefined;
  message: any;
}
export const getInsightWordCloudRequest = () => (dispatch: Dispatch) =>
  dispatch(action(ActionTypes.GET_INSIGHTS_WORD_CLOUD_REQUEST, {}));
export const getInsightWordCloudSuccess = (response: DataTypes.WordCloud) =>
  action(ActionTypes.GET_INSIGHTS_WORD_CLOUD_SUCCESS, { response });
export const getInsightWordCloudError = (error: RequestError) =>
  action(ActionTypes.GET_INSIGHTS_WORD_CLOUD_FAILURE, error);
