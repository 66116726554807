import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import * as API from "../../api";
import * as Actions from "./actions";
import { ActionTypes } from "./types";

function* getExperimentsTraits(action: any) {
  try {
    // @ts-ignore
    const res = yield call(API.Experiments.traits);

    yield put(Actions.getExperimentsTraitsSuccess(res.body));
  } catch (error: any) {
    yield put(Actions.getExperimentsTraitsError(error));
  }
}

function* createExperimentsTraits(action: any) {
  try {
    const { insightId, traitName, values } = action.payload;

    // @ts-ignore
    const res = yield call(
      API.Experiments.addTraits,
      insightId,
      traitName,
      values
    );

    yield put(Actions.createExperimentsTraitsSuccess(res.body));
    yield put(Actions.getExperimentsTraitRequestSaga());
  } catch (error: any) {
    yield put(Actions.getExperimentsTraitsError(error));
  }
}

function* watchGetExperimentsTraits() {
  yield takeEvery(
    ActionTypes.GET_EXPERIMENTS_TRAITS_REQUEST,
    getExperimentsTraits
  );
}

function* watchCreateExperimentsTraits() {
  yield takeEvery(
    ActionTypes.CREATE_EXPERIMENTS_TRAITS_REQUEST,
    createExperimentsTraits
  );
}

function* ideasTraitsSaga() {
  yield all([
    fork(watchGetExperimentsTraits),
    fork(watchCreateExperimentsTraits),
  ]);
}

export default ideasTraitsSaga;
