import { action } from "typesafe-actions";
import { ActionTypes } from "./types";
import * as DataTypes from "../types";

interface RequestError {
  statusCode?: number | undefined;
  message: any;
}

export const createProgramGoalTargetRequest = (
  programGoalId: string,
  payload: Omit<DataTypes.ProgramGoalTarget, "id">
) =>
  action(ActionTypes.CREATE_PROGRAM_GOAL_TARGET_REQUEST, {
    programGoalId,
    payload,
  });
export const createProgramGoalTargetSuccess = (
  response: Array<DataTypes.SampleSizeResult>
) => action(ActionTypes.CREATE_PROGRAM_GOAL_TARGET_SUCCESS, { response });
export const createProgramGoalTargetError = (error: RequestError) =>
  action(ActionTypes.CREATE_PROGRAM_GOAL_TARGET_FAILURE, error);

export const deleteProgramGoalTargetRequest = (
  programGoalId: string,
  goalId: string
) =>
  action(ActionTypes.DELETE_PROGRAM_GOAL_TARGET_REQUEST, {
    programGoalId,
    goalId,
  });
export const deleteProgramGoalTargetSuccess = (
  response: Array<DataTypes.SampleSizeResult>
) => action(ActionTypes.DELETE_PROGRAM_GOAL_TARGET_SUCCESS, { response });
export const deleteProgramGoalTargetError = (error: RequestError) =>
  action(ActionTypes.DELETE_PROGRAM_GOAL_TARGET_FAILURE, error);
