import React, { Component } from "react";
import { connect } from "react-redux";
import * as DataTypes from "store/types";
import { ApplicationState } from "store/types";
import { TabsContainer } from "../style";
import { ReportTab } from "../report_tab";
import { MetricTab } from "../metric_tab";
import { NameTab } from "../name_tab";
import { ExperiencesTab } from "../experience_tab";
import { VisualsTab } from "../visuals_tab";
import { Helpers } from "utils";
import { updateCustomDataVizRequest } from "store/custom_data_viz/actions";
import { createLoadingSelector } from "store/selectors";

interface IProps {
  experiment: DataTypes.Experiment | null;
  dataViz: DataTypes.CustomDataViz | null;
  updatingLoading: boolean;
  updateCustomDataVizRequest(
    experimentId: string,
    customDataVizId: string,
    body: any
  ): void;
}

type TabsDefnition = {
  REPORTS: string;
  METRICS: string;
  NAME: string;
  EXPERIENCES: string;
  VISUALS: string;
};
const Tabs: TabsDefnition = {
  NAME: "Name",
  REPORTS: "Reports",
  EXPERIENCES: "Experiences",
  METRICS: "Metrics",
  VISUALS: "Visuals",
};

interface IState {
  name: string;
  activeTab: keyof TabsDefnition;
  reports: DataTypes.Report[];
  experiences: DataTypes.Experience[];
  metrics: DataTypes.DataVizMetric[];
  errors: any;
}

const metricWithAggregationId = (
  dataViz: DataTypes.CustomDataViz,
  aggregationId: string
) => {
  const reports = dataViz.reports;

  const metrics = Helpers.flatten(reports.map((report) => report.metrics));
  const filteredMetrics = metrics.filter(
    (metric) =>
      (metric as DataTypes.CustomDataVizMetric).agregation_id === aggregationId
  );

  const results = Helpers.flatten(
    filteredMetrics.map(
      (metric) => (metric as DataTypes.CustomDataVizMetric).results
    )
  );

  const metricIds = results.map(
    (result) => (result as DataTypes.CustomDataVizResult).metric_id
  );

  return Helpers.uniqueArray(metricIds);
};

class UpdateCustomDataViz extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    const dataVizReportIds = props.dataViz?.reports.map((report) => report.id);
    const firstReport = props.dataViz?.reports[0];
    const firstMetric = firstReport?.metrics[0];
    const experienceIds = firstMetric?.results.map(
      (result) => result.experience_id
    );

    const metrics = firstReport?.metrics.map((metric) => {
      return {
        name: metric.name,
        kind: metric.kind,
        chart_kind: metric.chart_kind,
        metric_ids: metricWithAggregationId(
          props.dataViz!,
          metric.agregation_id
        ),
      };
    });

    this.state = {
      name: this.props.dataViz?.name || "",
      activeTab: "NAME",
      reports:
        props.experiment?.reports.filter(
          (report) => dataVizReportIds?.lastIndexOf(report.id!) !== -1
        ) || [],
      experiences:
        props.experiment?.experiences.filter(
          (experience) => experienceIds?.lastIndexOf(experience.id) !== -1
        ) || [],
      metrics: metrics || [],
      errors: {},
    };
  }

  private submit = () => {
    if (!this.props.experiment?.id) {
      return;
    }

    const body = {
      name: this.state.name,
      report_ids: this.state.reports.map((report) => report.id),
      experience_ids: this.state.experiences.map((experience) => experience.id),
      metrics: this.state.metrics,
    };

    this.props.updateCustomDataVizRequest(
      this.props.experiment.id,
      this.props.dataViz?.id!,
      body
    );
  };

  private isValid = () => {
    if (this.state.activeTab === "NAME") {
      if (!this.state.name || this.state.name === "") {
        return false;
      }
    }

    if (this.state.activeTab === "REPORTS") {
      if (!this.state.reports || this.state.reports.length === 0) {
        return false;
      }
    }

    if (this.state.activeTab === "EXPERIENCES") {
      if (!this.state.experiences || this.state.experiences.length === 0) {
        return false;
      }
    }

    if (this.state.activeTab === "METRICS") {
      if (!this.state.metrics || this.state.metrics.length === 0) {
        return false;
      }

      let hasError = false;

      this.state.metrics.forEach((metric) => {
        if (metric.metric_ids.length !== this.state.reports.length) {
          hasError = true;
        }
      });

      if (hasError) {
        return false;
      }
    }

    return true;
  };

  render() {
    if (!this.props.experiment) {
      return null;
    }

    return (
      <React.Fragment>
        <div className="header">
          <h3>Update Custom Data Visualization {this.props.dataViz?.name} </h3>
        </div>
        <div className="body">
          <div className="header">
            <p>
              You decide what visual best represents the data! Create a "report"
              that uses existing report data with the visualization of your
              choice. This is a great way to compare metrics across reports.
            </p>
          </div>
        </div>

        <TabsContainer>
          <ul className="tab_list">
            {Object.keys(Tabs).map((tab, index) => (
              <li
                key={`custom_data_viz_tab_${tab}`}
                className={this.state.activeTab === tab ? "active" : ""}
              >
                <div className="step-circle">{index + 1}</div>
                <span>{Tabs[tab as keyof TabsDefnition]}</span>
              </li>
            ))}
          </ul>

          <div className="tab-content">
            <NameTab
              active={this.state.activeTab === "NAME"}
              name={this.state.name}
              onChangeName={(name: string) => this.setState({ name })}
            />

            <ReportTab
              active={this.state.activeTab === "REPORTS"}
              experiment={this.props.experiment}
              onSelectReport={(reports) => {
                this.setState({ reports });
                const reportMetrics = Helpers.flatten(
                  reports.map((report) => report.metrics)
                );
                const metricIds = reportMetrics.map(
                  (metric: DataTypes.Metric) => metric.id
                );

                const metrics = this.state.metrics.map((metric) => ({
                  ...metric,
                  metric_ids: metric.metric_ids.filter(
                    (id) => metricIds.lastIndexOf(id) !== -1
                  ),
                }));

                this.setState({ metrics });
              }}
              previousSelected={this.state.reports}
            />

            <ExperiencesTab
              active={this.state.activeTab === "EXPERIENCES"}
              experiment={this.props.experiment}
              onSelectExperience={(experiences) =>
                this.setState({ experiences })
              }
              previousSelected={this.state.experiences}
            />

            <MetricTab
              active={this.state.activeTab === "METRICS"}
              selectedReports={this.state.reports}
              onSelectMetric={(metrics) => this.setState({ metrics })}
              previousSelected={this.state.metrics}
            />

            <VisualsTab
              active={this.state.activeTab === "VISUALS"}
              metrics={this.state.metrics}
              onChangeChartKind={(metrics) => this.setState({ metrics })}
            />
          </div>
        </TabsContainer>

        <div className="cntrl-bar">
          {this.state.activeTab !== "NAME" ? (
            <button
              type="button"
              className="btn btn-link"
              onClick={() => {
                const tabKeys = Object.keys(Tabs);
                const currentIndex = tabKeys.lastIndexOf(this.state.activeTab);

                this.setState({
                  activeTab: tabKeys[currentIndex - 1] as keyof TabsDefnition,
                });
              }}
            >
              Back
            </button>
          ) : null}

          {this.state.activeTab === "VISUALS" ? (
            <button
              type="button"
              className="btn btn-primary"
              disabled={this.props.updatingLoading}
              onClick={() => this.submit()}
            >
              Update
            </button>
          ) : (
            <button
              type="button"
              className="btn btn-primary"
              disabled={!this.isValid()}
              onClick={() => {
                const tabKeys = Object.keys(Tabs);
                const currentIndex = tabKeys.lastIndexOf(this.state.activeTab);

                this.setState({
                  activeTab: tabKeys[currentIndex + 1] as keyof TabsDefnition,
                });
              }}
            >
              Next
            </button>
          )}
        </div>
      </React.Fragment>
    );
  }
}

const updatingSelector = createLoadingSelector([
  "@@customDataViz/UPDATE_CUSTOM_DATA_VIZ",
]);

const mapStateToProps = ({ app }: ApplicationState) => ({
  updatingLoading: updatingSelector(app.requests),
});

const mapDispatchToProps = {
  updateCustomDataVizRequest,
};

const connectedPage = connect(
  mapStateToProps,
  mapDispatchToProps
)(UpdateCustomDataViz);

export { connectedPage as UpdateCustomDataViz };
