import { useEffect } from 'react';

const OutsideClick = (ref: any, callback: any) => {
  const handlClick = (e: any) => {
    if (ref.current && !ref.current.contains(e.target)) {
      callback(e);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handlClick);

    return () => {
      document.removeEventListener('click', handlClick);
    };
  });
};

export { OutsideClick as handleOutsideClick };

const OnEnterPress = (ref: any, callback: any) => {
  const handleKeyUp = (e: any) => {
    if (e.key === 'Enter') {
      if (ref.current) {
        callback();
      }
    }
  };

  useEffect(() => {
    const currentRef = ref.current;
    if (currentRef) {
      currentRef.addEventListener('keyup', handleKeyUp);

      return () => {
        currentRef.removeEventListener('keyup', handleKeyUp);
      };
    }
  });
};

export { OnEnterPress as onEnterPress };
