import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { ApplicationState } from 'store/types';
import * as DataTypes from 'store/types';
import { HTTPCodes } from 'config/app';
import { clearAppErrors } from 'store/app/actions';
import { createLoadingSelector, createErrorMessageSelector } from 'store/selectors';
import { getShareWorksheetRequest } from 'store/worksheet/actions';
import { AppPage, AppPageProps } from 'comps/pages';
import { Form, Input, InputTypes, ValidatorTypes, Button } from 'comps/form';
import { AuthService } from 'utils/auth';
import { WorkSheet } from 'ui/worksheet';
import * as Nav from 'ui/navs';
import '../../css/Screens.scss';

interface IProps extends AppPageProps {
  router: any;
  computedMatch: any;
  loading: boolean;
  error: any;
  clearAppErrors: () => void;
  getShareWorksheetRequest: (slug: string, uuid: string, password?: string) => void;
  worksheet: DataTypes.Worksheet | null;
  account: DataTypes.Account | null;
}

interface IState {}

class ShareIdeaBoardWorksheet extends Component<IProps, IState> {
  componentDidMount = () => {
    this.props.clearAppErrors();

    let pwd;
    const sharedPwd = AuthService.getSharePwd();

    if (sharedPwd) {
      const json = JSON.parse(sharedPwd);
      if (json.key === this.props.computedMatch.params.uuid) {
        pwd = json.pwd;
      } else {
        AuthService.clearSharePwd();
      }
    }

    const { account } = this.props;
    if (account) {
      this.props.getShareWorksheetRequest(account.slug, this.props.computedMatch.params.uuid, pwd || undefined);
    }
  };

  private onSubmit = (valid: boolean, dirty: boolean, data: any) => {
    const { account } = this.props;
    this.props.clearAppErrors();

    if (account && valid) {
      AuthService.setSharePwd(JSON.stringify({ key: this.props.computedMatch.params.uuid, pwd: data.password.value }));
      this.props.getShareWorksheetRequest(account.slug, this.props.computedMatch.params.uuid, data.password.value);
    }
  };

  private onChange = () => {};

  private onSignOut = () => {
    AuthService.shareLogOut();
    this.setState({ ...this.state });
  };

  render() {
    const { loading, worksheet, account, error } = this.props;

    const currentUser = AuthService.getActiveShareUser();

    if (error) {
      if (error.statusCode === HTTPCodes.UNAUTHORIZED) {
        return (
          <div className="ShareLogin">
            <div className="body">
              <img src="/img/logo.png" alt="illuminate" />
              <h3>This link is password protected</h3>
              <p>Enter the password below to view</p>
              <Form
                formFields={{
                  password: {
                    name: 'password',
                    value: '',
                    validator: { type: ValidatorTypes.REQUIRED, messages: { required: 'Please enter a password to view the case study.' } },
                  },
                }}
                onSubmit={this.onSubmit}
                FormComponent={({ fields: { password }, onChange }) => (
                  <div>
                    <div className="form-group">
                      <Input
                        field={password}
                        placeholder="Enter password"
                        type={InputTypes.PASSWORD}
                        className="form-control"
                        onChange={(e) => onChange(password, e.target.value)}
                      />
                    </div>
                    <Button className="btn btn-primary" text="view case study" isLoading={loading} />
                  </div>
                )}
              />
            </div>
          </div>
        );
      }

      if (error.statusCode === HTTPCodes.NOT_FOUND) {
        return (
          <div className="ShareLogin">
            <div className="body">
              <img src="/img/logo.png" alt="illuminate" />
              <h3>The link you have requested is invalid</h3>
              <p>Please check the link and try again</p>
            </div>
          </div>
        );
      }
      return null;
    }

    if (loading) return null;
    if (!worksheet || !account) return null;

    return (
      <div className="FullScreen">
        <div className="header mt-4">
          <img src="/img/logo_sm.svg" alt="illuminate" className="logo" />
          <div className="ctrls">
            <Link to={`/ib/${this.props.computedMatch.params.uuid}`} className="btn-back">
              <i className="fas fa-arrow-left"></i>Back
            </Link>
            <Nav.ShareNav currentUser={currentUser} onSignOut={this.onSignOut} />
          </div>
        </div>
        <div className="form-body">
          <WorkSheet setPristine={() => {}} readOnly={true} title={worksheet?.title || ''} content={worksheet?.content} onChange={this.onChange} />
        </div>
      </div>
    );
  }
}

const loadingSelector = createLoadingSelector(['@@worksheet/GET_SHARE_WORKSHEET']);
const errorSelector = createErrorMessageSelector(['@@worksheet/GET_SHARE_WORKSHEET']);

const mapStateToProps = ({ router, app, account, worksheet }: ApplicationState) => ({
  router: router,
  account: account,
  loading: loadingSelector(app.requests),
  error: errorSelector(app.errors),
  worksheet: worksheet,
});

const mapDispatchToProps = {
  clearAppErrors,
  getShareWorksheetRequest,
};

const connectedPage = connect(mapStateToProps, mapDispatchToProps)(AppPage(ShareIdeaBoardWorksheet));

export { connectedPage as ShareIdeaBoardWorksheet };
