import {
  all,
  call,
  fork,
  put,
  takeEvery,
  takeLatest,
} from "redux-saga/effects";
import * as API from "../../api";
import { ActionTypes } from "./types";
import * as Actions from "./actions";
import * as AppTypes from "../app/types";
import * as AccountActions from "../account/actions";
import { history } from "utils";

function* getInsightsProposals(action: any) {
  try {
    // @ts-ignore
    const res = yield call(API.InsightProposals.index);
    // yield put(Actions.getInsightProposalsSuccess(res.body));
    // if (
    //   res.body.general_proposals.length === 0 &&
    //   res.body.industry_proposals.length === 0
    // ) {
    //   return;
    // }
    // if (window.localStorage.getItem("INSIGHTS_PROPOSAL_DONT_SHOW_AGAIN")) {
    //   return;
    // }
    // action.payload.callback();
  } catch (error: any) {
    yield put(Actions.getInsightProposalsError(error));
  }
}

function* createInsightsProposals(action: any) {
  try {
    const unselected = action.payload.unselected;

    // @ts-ignore
    const res = yield call(API.InsightProposals.create, unselected);

    yield put(Actions.createInsightProposalsSuccess(res.body));
    yield put(AccountActions.getAccountSummaryRequest());

    history.push(`/insights`);

    yield put({ type: AppTypes.App.MODAL_HIDE });
  } catch (error: any) {
    yield put(Actions.createInsightProposalsError(error));
  }
}

function* watchGetInsightsProposals() {
  yield takeLatest(
    ActionTypes.GET_INSIGHTS_PROPOSALS_REQUEST,
    getInsightsProposals
  );
}

function* watchCreateInsightsProposals() {
  yield takeEvery(
    ActionTypes.CREATE_INSIGHTS_PROPOSALS_REQUEST,
    createInsightsProposals
  );
}

function* insightsProposalsSaga() {
  yield all([
    fork(watchGetInsightsProposals),
    fork(watchCreateInsightsProposals),
  ]);
}

export default insightsProposalsSaga;
