import React, { Component } from 'react';
import * as DataTypes from 'store/types';

interface IProps {
  invite:DataTypes.Invite
};

interface IState {
  copied: boolean
}

class InviteCopyLink extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      copied: false,
    };
  }
  
  private copyUrl = () => {
    var copyText = document.getElementById('linkUrl') as HTMLInputElement;
    copyText.select();
    document.execCommand('Copy');
    this.setState({ ...this.state, copied: true });
  };

  render() {
    const { invite } = this.props;
    const { copied } = this.state;

    return (
      <React.Fragment>
        <div className="header">
          <h3>Copy Invite Link</h3>
          <p>Use the link below to accept your invite.</p>
        </div>
        <div className="body">
          <div className="form-group mt-2 mb-2">
            <label>copy the share link below</label>
            <div className="input-group mb-3">
              <input
                id="linkUrl"
                type="text"
                className="form-control"
                readOnly={true}
                value={`${window.location.protocol}//${window.location.host}/invite/${invite.id}`}
              />
              <div className="input-group-append">
                <button className={copied ? 'btn btn-success' : 'btn btn-primary'} type="button" onClick={this.copyUrl}>
                  {copied ? 'copied' : 'copy'}
                </button>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export { InviteCopyLink };
