import styled from "styled-components";

export const StyledGoal = styled.div`
  padding: 10px;
  font-size: 18px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 2px solid #eaeaea;
  border-radius: 4px;
  margin-top: 8px;
  margin-bottom: 8px;
  background-color: #fff;
  width: 100%;

  .Collapsible {
    width: 100%;

    .Collapsible__trigger {
      cursor: pointer;

      .space {
        padding: 10px;
      }

      .icon {
        color: lightgrey;
      }

      .name {
        font-weight: 600;
        font-size: 14px;
        text-transform: capitalize;
        color: #2f4760;
        flex-grow: 1;

        .nameText {
          width: fit-content;

          &:hover {
            text-decoration: underline;
          }
        }
      }

      .d-flex {
        align-items: center;
        padding-left: 20px;
        padding-right: 20px;
      }

      .menu {
        display: flex;
        gap: 0.5rem;

        button {
          position: relative;
          top: -2px;
          background-color: transparent;
          color: #eaeaea;
          border: 2px solid #eaeaea;
          border-radius: 4px;
          background-color: #fff;
          padding: 2px 8px;

          transition: all 0.5s;

          &.open {
            &:hover {
              color: #2a88f2;
              border-color: #2a88f2;
            }
          }

          &.delete {
            &:hover {
              color: #ff5a71;
              border-color: #ff5a71;
            }
          }
        }
      }
    }
  }
`;

export const ProgramGoalTargetEmptyStateContainer = styled("div")`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 20px 20px 20px 26px;

  h1 {
    font-size: 1.5rem;
  }

  p {
    text-align: center;
  }
`;

export const ProgramGoalTargets = styled("div")`
  min-height: 415px;
`;

export const ExpiredTargetsContainer = styled("div")`
  display: flex;
  width: 100%;
  gap: 1rem;
  align-items: center;

  .line {
    height: 1px;
    flex-grow: 1;
    border: 1px dashed #eaeaea;
  }

  div {
    p {
      margin-bottom: 0;
    }
  }
`;

export const TargetListContainer = styled("div")`
  padding: 0rem 2rem 2rem 2rem;

  h4 {
    display: inline-block;
    font-size: 18px;
    text-transform: capitalize;
    color: #2f4760;
  }
`;

export const CreateTargetFormContainer = styled("div")`
  padding: 2rem;

  h4 {
    display: inline-block;
    font-size: 18px;
    text-transform: capitalize;
    color: #2f4760;
  }

  form {
    display: flex;
    margin-top: 1rem;

    gap: 1rem;

    .grow-1 {
      flex-grow: 1;
    }

    .grow-2 {
      flex-grow: 2;
    }

    .name,
    .value {
      margin-top: 0.29rem;
    }

    label {
      color: #71798b;
      font-size: 14px;
      letter-spacing: 0.2px;
      margin-bottom: 6px;
      text-transform: none;
    }

    .submit-container {
      display: flex;
      align-items: center;

      input[type="submit"] {
        height: 40px;
        margin-top: 14px;
      }
    }
  }
`;

export const DateRangeContainer = styled("div")`
  label {
    font-size: 16px;
    text-transform: capitalize;
    color: #71798b;
    font-weight: bold;
    margin-bottom: 1px;
    text-indent: 4px;
  }

  .form-control {
    width: 160px !important;
  }
`;
