import React, { Component } from 'react';
import * as DataTypes from 'store/types';

interface IProps {
  experiment: DataTypes.Experiment | null;
  saving: boolean;
  onSubmit(): void;
}

class BestInClass extends Component<IProps> {
  render() {
    return (
      <React.Fragment>
        <div className="header flex">
          <div className='mr__sm'>
            <i className='fas fa-trophy' style={{ color: "#f5a623", fontSize: '80px' }}></i>
          </div>

          <div>
            <h4 style={{ fontWeight: 300 }}>Best in Class</h4>
            <h3>Insights</h3>
          </div>
        </div>
        <div style={{ maxWidth: '600px' }} className="mt__sm">
          <p>Research and Testing provide amazing customer insights. Insights that transcend the online experience and teach you what makes your customers tick. Here are some tips for making your Insights shine.</p>

          <ul style={{ listStyle: 'disc' }}>
            <li><p>Insights evolve, so document what you have now and edit it as you iterate and refine. <strong>Embrace the change.</strong></p></li>
            <li><p>Don't be nervous to document it because you "aren't sure yet." <strong>Use Insight Confidence<sup>&reg;</sup></strong> to be transparent if it's a budding idea. Increase the levels as you gain confidence.</p></li>
            <li><p>Is this about the page? Our customer? People in general? <strong>Insight Altitude<sup>&reg;</sup> tags</strong> help you track what types of insights are in your library. </p></li>
            <li><p><strong>Link your research and tests to insights</strong>, and watch the Supporting Evidence counter grow.</p></li>
            <li><p><strong>Don't be shy!</strong> Share your insights using the "Share Insight" option. They'll be able to access the insight, details you provided, and linked tests and research.</p></li>
          </ul>

          <div className="flex justify-center align-center mt__sm">
            <a href="https://sandbox.illuminate.app/in/b868d47" target="_blank">
              <img src="/img/best_in_class_insight.png" alt="Best in Class image" className='shadow__full' height={'106px'} />
            </a>
            <a href="https://sandbox.illuminate.app/in/b868d47" target='_blank' style={{ width: '30%', marginLeft: '24px' }}>Check out this live, best in class example to see it in action!</a>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export { BestInClass };
