import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import * as API from "../../api";
import * as Actions from "./actions";
import { ActionTypes } from "./types";

function* getTasks(action: any) {
  try {
    // @ts-ignore
    const res = yield call(API.Tasks.index);
    yield put(Actions.getTasksSuccess(res.body));
  } catch (error: any) {
    yield put(Actions.getTasksFailure(error));
  }
}

function* deleteTask(action: any) {
  try {
    // @ts-ignore
    const res = yield call(API.Tasks.destroy, action.payload.id);
    yield all([put(Actions.getTasksRequest())]);
    yield put(Actions.deleteTaskSuccess(res.body));
  } catch (error: any) {
    yield put(Actions.deleteTaskError(error));
  }
}

function* watchGetTasks() {
  yield takeEvery(ActionTypes.GET_TASKS_REQUEST, getTasks);
}

function* watchDeleteTask() {
  yield takeEvery(ActionTypes.DELETE_TASK_REQUEST, deleteTask);
}

function* taskSaga() {
  yield all([fork(watchGetTasks), fork(watchDeleteTask)]);
}

export default taskSaga;
