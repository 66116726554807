import React, { Component } from "react";
import { connect } from "react-redux";
import { HTTPCodes } from "config/app";
import { ApplicationState } from "store/types";
import * as DataTypes from "store/types";
import { clearAppErrors, showModal } from "store/app/actions";

import {
  createLoadingSelector,
  createErrorMessageSelector,
} from "store/selectors";
import { AppPage, AppPageProps } from "comps/pages";
import { history } from "utils";
import { Design, Insights, MastHead } from "./comps/show";
import * as Modals from "./comps/show/modals";
import { getAccountTraitsRequestSaga } from "store/account/actions";
import { getUxrsTraitRequestSaga } from "store/uxr_trait/actions";
import {
  showUxrRequest,
  updateUxrRequest,
  addImageRequest,
  deleteUploadRequest,
  onLinkInsightsRequest,
  onUnLinkInsightsRequest,
  deleteUxrRequest,
  deleteDocumentRequest,
  addDocumentRequest,
  uploadInsightsRequest,
} from "store/uxr/actions";
import { AreasOfFocus } from "./comps/show/area_of_focus";
import { SupportingData } from "./comps/show/supporting_data";

enum ViewType {
  design = "design",
  insights = "insights",
  areasoffocus = "areasoffocus",
  supportingdata = "supportingdata",
}

enum PageActions {
  NONE = "none",
  DELETE = "delete",
  CLONE = "clone",
  ADD_REPORT = "add_report",
  DELETE_REPORT = "delete_report",
  LINK_INSIGHT = "link_insight",
  VIEW_ATTACHMENT = "view_attachements",
  UPDATE_REPORT_STATS = "update_report_stats",
  IMPORT_CSV = "import_csv",
  UPLOAD_INSIGHTS = "upload_insights",
  DELETE_INSIGHT = "delete_insights",
}

interface IProps extends AppPageProps {
  router: any;
  account: DataTypes.Account | null;
  computedMatch: any;
  clearAppErrors: () => void;
  showUxrRequest: (id: string) => void;
  updateUxrRequest: (id: string, body: any) => void;
  deleteUxrRequest: (id: string) => void;
  onLinkInsightsRequest: (id: string, insights: any) => void;
  onUnLinkInsightsRequest: (id: string, insightId: string) => void;
  showModal: (component: React.ComponentType<any>, options: any) => void;
  getUxrsTraitRequestSaga: () => void;
  addUploadRequest: (uxrId: string, file: File) => void;
  addDocumentRequest: (uxrId: string, file: File) => void;
  deleteUploadRequest: (uxrId: string, uploadId: string) => void;
  deleteDocumentRequest: (uxrId: string, uploadId: string) => void;
  getAccountTraitsRequestSaga: () => void;
  uploadInsightsRequest: (uxrId: string, file: any) => void;
  addImageRequest: (uxrId: string, file: File) => void;
  loading: boolean;
  preUploadLoading: boolean;
  insightsLoading: boolean;
  loadError: any;
  uxr: DataTypes.Uxr | null;
  uxrTrait: any;
  createReportLoading: boolean;
}

interface IState {
  selectedExperience: number;
  loadingReport: boolean;
  deleteImage: DataTypes.Image | null;
}

class Uxr extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      selectedExperience: 0,
      loadingReport: false,
      deleteImage: null,
    };
  }

  private onUploadAttachment = (file: any) => {
    const { uxr } = this.props;

    if (!uxr) {
      return;
    }

    const uxrId = uxr.id || "";
    this.props.addDocumentRequest(uxrId, file.file);
  };

  private onDeleteAttachment = (upload: DataTypes.Upload) => {
    const { uxr } = this.props;

    if (!uxr) {
      return;
    }

    const uxrId = uxr.id || "";
    const uploadId = upload.id || "";

    this.props.deleteDocumentRequest(uxrId, uploadId);
  };

  componentDidMount = () => {
    this.props.getUxrsTraitRequestSaga();
    this.props.getAccountTraitsRequestSaga();
    this.props.showUxrRequest(this.props.computedMatch.params.id);
  };

  private setPageAction = (
    action: PageActions,
    uxr: DataTypes.Uxr | null,
    { insight }: { insight?: DataTypes.Insight } = {}
  ) => {
    this.props.clearAppErrors();

    // Show delete modal
    if (action === PageActions.DELETE) {
      this.props.showModal(Modals.Delete, {
        experiment: uxr,
        onSubmit: this.onDeleteUxr,
      });
    }

    // Show link insight
    if (action === PageActions.LINK_INSIGHT) {
      this.props.showModal(Modals.LinkInsights, {
        className: "LinkInsights",
        onSubmit: this.onLinkInsights,
        onCreateNewInsight: this.onCreateNewInsight,
      });
    }

    if (action === PageActions.VIEW_ATTACHMENT) {
      this.props.showModal(Modals.Uploads, {
        className: "Uploads",
        uxr: uxr,
        currentUser: this.props.currentUser,
        onUpload: this.onUploadAttachment,
        onDelete: this.onDeleteAttachment,
      });
    }

    if (action === PageActions.UPLOAD_INSIGHTS) {
      this.props.showModal(Modals.InsightsCsv, {
        account: this.props.account,
        uxr: uxr,
        uploadLoading: false,
        resourceName: "experiment",
        onSubmit: this.onInsightsCsvUpload,
      });
    }

    if (action === PageActions.DELETE_INSIGHT) {
      this.props.showModal(Modals.DeleteInsight, {
        account: this.props.account,
        uxr: uxr,
        insight: insight,
        onSubmit: () => this.unLinkInsight(insight!),
      });
    }
  };

  private onInsightsCsvUpload = (file: any) => {
    if (!file || !this.props.uxr?.id) {
      return;
    }

    this.props.uploadInsightsRequest(this.props.uxr.id, file);
  };

  private onDeleteUxr = () => {
    this.props.deleteUxrRequest(this.props.computedMatch.params.id);
  };

  private onLinkInsights = (insights: Array<DataTypes.Insight>) => {
    const { uxr } = this.props;

    if (!uxr) {
      return;
    }

    this.props.onLinkInsightsRequest(
      this.props.computedMatch.params.id,
      insights
    );
  };

  private unLinkInsight = (insight: DataTypes.Insight) => {
    const { uxr } = this.props;

    if (!uxr) {
      return;
    }

    this.props.onUnLinkInsightsRequest(
      this.props.computedMatch.params.id,
      insight.id
    );
  };

  private onCreateNewInsight = () => {
    const { uxr } = this.props;
    if (uxr) {
      history.push(`/insights/create?uxr=${uxr.id}`);
    }
  };

  private onDetailsChange = (key: string, value: string | Array<string>) => {
    const uxrId = this.props.computedMatch.params.id;

    this.props.updateUxrRequest(uxrId, {
      uxr: { [key]: value },
    });
  };

  private viewImage = (image: DataTypes.Image) => {
    history.push(
      "/uxr/" + this.props.computedMatch.params.id + "/images/" + image.id
    );
  };

  private toggleDeleteImage = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    image: DataTypes.Image | null
  ) => {
    event.stopPropagation();
    this.setState({ ...this.state, deleteImage: image });
  };

  private onDeleteImage = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    image: DataTypes.Image
  ) => {
    event.stopPropagation();

    this.props.deleteUploadRequest(this.props.uxr?.id || "", image.id || "");

    this.setState({ ...this.state, deleteImage: null });
  };

  private uploadImage = (files: any) => {
    if (!this.props.uxr || !files || files.length === 0) {
      return;
    }

    const { id: uxrId } = this.props.uxr;

    files.forEach((file: any) => {
      this.props.addImageRequest(uxrId || "", file);
    });
  };

  subnavView() {
    const { view } = this.props.computedMatch.params;

    if (!view) {
      return 0;
    }

    return Object.keys(ViewType).indexOf(view);
  }

  render() {
    const { loading, loadError, uxr, account, uxrTrait } = this.props;

    // Handle Load Error (raise not found)
    if (loadError && loadError.statusCode === HTTPCodes.NOT_FOUND) {
      throw loadError;
    }

    if (account === null) return null;

    return (
      <div className="Experiment">
        <MastHead
          currentUser={this.props.currentUser}
          loading={loading}
          uxr={uxr}
          navIndex={this.subnavView()}
          onClone={() => this.setPageAction(PageActions.CLONE, null)}
          onDelete={() => this.setPageAction(PageActions.DELETE, null)}
          onChange={this.onDetailsChange}
          onViewAttachment={() =>
            this.setPageAction(PageActions.VIEW_ATTACHMENT, uxr)
          }
        />

        {loading === false && uxr && this.subnavView() === 0 && (
          <Design
            currentUser={this.props.currentUser}
            account={account}
            traits={uxrTrait?.traits}
            uxr={uxr}
            onChange={this.onDetailsChange}
            onViewImage={this.viewImage}
            deleteImage={this.state.deleteImage}
            onImageDrop={this.uploadImage}
            onToggleDelete={this.toggleDeleteImage}
            onDeleteImage={this.onDeleteImage}
          />
        )}

        {loading === false &&
          uxr &&
          this.subnavView() === 1 &&
          uxr.insights.length === 0 && (
            <InsightsEmptyState
              createOnly={account.summary.totals.insights > 0 ? false : true}
              uxr={uxr}
              onAddInsight={() =>
                this.setPageAction(PageActions.LINK_INSIGHT, null)
              }
              onUploadInsight={() =>
                this.setPageAction(PageActions.UPLOAD_INSIGHTS, null)
              }
            />
          )}

        {loading === false &&
          uxr &&
          this.subnavView() === 1 &&
          uxr.insights.length > 0 && (
            <Insights
              account={account}
              currentUser={this.props.currentUser}
              onAddInsight={() =>
                this.setPageAction(PageActions.LINK_INSIGHT, null)
              }
              onUploadInsight={() =>
                this.setPageAction(PageActions.UPLOAD_INSIGHTS, null)
              }
              onDelete={(insight: DataTypes.Insight) =>
                this.setPageAction(PageActions.DELETE_INSIGHT, uxr, { insight })
              }
              uxr={uxr}
            />
          )}

        {loading === false && uxr && this.subnavView() === 2 && (
          <AreasOfFocus
            account={account}
            currentUser={this.props.currentUser}
            uxr={uxr}
          />
        )}

        {loading === false && uxr && this.subnavView() === 3 && (
          <SupportingData
            account={account}
            currentUser={this.props.currentUser}
            uxr={uxr}
          />
        )}
      </div>
    );
  }
}

const loadingSelector = createLoadingSelector([
  "@@uxr/SHOW_UXR",
  "@@uxr_traits/GET_UXRS_TRAITS",
]);
const loadingError = createErrorMessageSelector(["@@uxr/SHOW_UXR"]);

const mapStateToProps = ({
  router,
  app,
  account,
  uxr,
  uxrTrait,
}: ApplicationState) => ({
  router,
  account,
  uxr,
  uxrTrait,
  loading: loadingSelector(app.requests),
  loadError: loadingError(app.errors),
});

const mapDispatchToProps = {
  getAccountTraitsRequestSaga,
  clearAppErrors,
  uploadInsightsRequest,
  showModal,
  showUxrRequest,
  deleteUxrRequest,
  onLinkInsightsRequest,
  addDocumentRequest,
  onUnLinkInsightsRequest,
  addImageRequest,
  deleteDocumentRequest,
  getUxrsTraitRequestSaga,
  deleteUploadRequest,
  updateUxrRequest,
};

const connectedPage = connect(
  mapStateToProps,
  mapDispatchToProps
)(AppPage(Uxr));

export { connectedPage as Uxr };

interface IInsightsEmptyStateProps {
  onAddInsight(): void;
  onUploadInsight(): void;
  createOnly: boolean;
  uxr: DataTypes.Uxr;
}

const InsightsEmptyState = ({
  onAddInsight,
  onUploadInsight,
  createOnly,
  uxr,
}: IInsightsEmptyStateProps) => {
  return (
    <div className="Insights empty-state">
      <div className="wrapper">
        {createOnly === true ? (
          <React.Fragment>
            <img src="/img/bolt_grey.png" alt="insight"></img>
            <h2>Insights</h2>
            <p>
              illuminate helps you uncover and manage customer insights that
              help you build lasting relationships. Create a confidence-building
              feedback loop by attaching your research to insights
            </p>
            <button
              className="btn btn-primary-light"
              onClick={() => history.push(`/insights/create?uxr=${uxr.id}`)}
            >
              <i className="fa fa-plus" />
              Create Insight
            </button>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <img src="/img/bolt_grey.png" alt="insight"></img>
            <h2>Attach Your Insights or Create New Ones</h2>
            <p>
              illuminate helps you uncover and manage customer insights that
              help you build lasting relationships. Create a confidence-building
              feedback loop by attaching your researches to insights
            </p>
            <p>
              By attaching your research studies to your insights, you’ll be
              able to track supporting evidence that can give you confidence.
              Use the insights in your library, or add something new. Start by
              using the button below.
            </p>

            <button
              style={{ marginRight: "25px" }}
              className="btn btn-primary-light"
              onClick={() => history.push(`/insights/create?uxr=${uxr.id}`)}
            >
              <i className="fa fa-plus" />
              New Insight
            </button>

            <button className="btn btn-primary-light" onClick={onAddInsight}>
              <i className="fa fa-link" />
              Existing Insight
            </button>

            <button
              className="btn btn-primary-light"
              type="button"
              style={{ marginLeft: "25px" }}
              onClick={onUploadInsight}
            >
              <i className="fa fa-upload" />
              Batch Upload
            </button>
          </React.Fragment>
        )}
      </div>
    </div>
  );
};
