import React from "react";

interface IPageLoaderProps {
  loaded: boolean;
  children: React.ReactNode;
}

interface ICustomLoaderProps {
  text?: string;
}

export const CustomLoader = ({ text }: ICustomLoaderProps) => {
  return (
    <div className="flex column align-center">
      <div className="lds-roller">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>

      {text ? <p className="mt__xxsm">{text}</p> : null}
    </div>
  );
};

export const PageLoader = ({ loaded, children }: IPageLoaderProps) => {
  return loaded ? <div>Page Loader</div> : <div>{children}</div>;
};

export const SectionLoader = ({ loaded, children }: IPageLoaderProps) => {
  return loaded ? <section></section> : <section>{children}</section>;
};

interface IAccountLoaderProps extends IPageLoaderProps {
  message?: string;
}

export const AccountLoader = ({
  loaded,
  children,
  message,
}: IAccountLoaderProps) => {
  if (!loaded) {
    return (
      <div className="PageLoader">
        <div className="content">
          <div>
            <div>
              <div className="load-logo">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
              </div>
            </div>
            <p className="mt-4">
              {message || "Please wait while we load your account"}
            </p>
          </div>
        </div>
      </div>
    );
  }

  return <React.Fragment>{children}</React.Fragment>;
};
