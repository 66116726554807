import { ApiClient } from './client/api_client';

class CaseStudies {
  static async create(experimentId: string) {
    return await ApiClient.post(`/v1/experiment/${experimentId}/case_study`, {});
  }

  static async update(experimentId: string, caseStudyId: string, body: any) {
    return await ApiClient.put(`/v1/experiment/${experimentId}/case_study/${caseStudyId}`, { case_study: body });
  }
}

export { CaseStudies };
