import { ApiClient } from "./client/api_client";
import { ApiGetOptions } from "./types";

class Insights {
  static async index(options: ApiGetOptions) {
    return await ApiClient.get("/v1/insight", options);
  }

  static async create(body: any) {
    return await ApiClient.post("/v1/insight", { insight: body });
  }

  static async update(body: any) {
    return await ApiClient.put(`/v1/insight/${body.id}`, { insight: body });
  }

  static async delete(id: string) {
    return await ApiClient.delete(`/v1/insight/${id}`);
  }

  static async traits() {
    return await ApiClient.get("/v1/insight/traits");
  }

  static async addTraits(insightId: string, trait: string, values: string[]) {
    return await ApiClient.post(`/v1/insight/${insightId}/traits`, {
      trait,
      values,
    });
  }

  static async addNotes(insightId: string, value: string) {
    return await ApiClient.post(`/v1/insight/${insightId}/notes`, {
      note: {
        value,
      },
    });
  }

  static async deleteNote(noteId: string) {
    return await ApiClient.delete(`/v1/insight/notes/${noteId}`);
  }

  static async reorder(id: string, position: number) {
    return await ApiClient.put(`/v1/insight/${id}/order/${position}`, {});
  }

  static async uploadInsights(file: any) {
    const formData = new FormData();
    formData.append("file", file);

    return await ApiClient.postMultipart("/v1/insight/csv", formData);
  }

  static async wordCloud() {
    return ApiClient.get("/v1/insight/word_cloud");
  }
}

export { Insights };
