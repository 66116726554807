import React, { Component } from "react";
import { connect } from "react-redux";
import { ApplicationState } from "store/types";
import * as DataTypes from "store/types";
import { getAccountRequest } from "store/account/actions";
import { history } from "utils";

import "css/Setup.scss";

interface IProps {
  account: DataTypes.Account | null;
  getAccountRequest: (slug: string, callback: any) => void;
}

class Congratulations extends Component<IProps> {
  private onFinish = () => {
    this.props.getAccountRequest(window.location.hostname.split(".")[0], () => {
      history.push("/");
    });
  };

  render() {
    const { account } = this.props;

    if (!account) return null;

    return (
      <React.Fragment>
        <div className="Finish">
          <h2>Congratulations! You’re all set up.</h2>
          <p>
            Modify your program goal and account customizations any time by
            clicking your profile in the navigation.{" "}
          </p>
          <p className="mt-2">
            Now, get out there and learn something new about your customers!
          </p>
          <button
            type="button"
            className="btn btn-primary"
            onClick={this.onFinish}
          >
            Finish
          </button>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = ({ app, account }: ApplicationState) => ({
  account: account,
});

const mapDispatchToProps = {
  getAccountRequest,
};

const connectedPage = connect(
  mapStateToProps,
  mapDispatchToProps
)(Congratulations);

export { connectedPage as Congratulations };
