import React from "react";
import { connect } from "react-redux";
import { history, Helpers } from "utils";
import queryString from "query-string";
import { Link } from "react-router-dom";
import _ from "lodash";
import * as DataTypes from "store/types";
import { clearAppErrors, showModal } from "store/app/actions";
import { clearExperiments } from "store/experiment/actions";
import {
  getUxrsRequest,
  reorderUxrRequest,
  updateUxrRequest,
} from "store/uxr/actions";
import { getUxrsTraitsRequest } from "store/uxr_trait/actions";
import { createLoadingSelector } from "store/selectors";
import { ApplicationState } from "store/types";
import { AppPage, AppPageProps } from "comps/pages";
import { Scrollable } from "comps/base/scrollable";
import { Filters, List, Aside } from "./comps";
import { SearchBar, TotalCountStat } from "ui";
import "css/Experiments.scss";

import { CustomLoader } from "ui/loaders";
import { AuthService } from "utils/auth";
import GraphQLInfiniteScroll from "comps/infinite_scroll/graphql_scroll";
import { OrderDirection } from "ui/order_icon";
import * as Modals from "./comps/modals";
import { BestInClass } from "./comps/modals/best_in_class";
import { MarketingBanner } from "comps/banner";

const DEFAULT_LIMIT = 25;

interface IProps extends AppPageProps {
  router: any;
  account: DataTypes.Account | null;
  currentUser: any;
  clearAppErrors: () => void;
  getUxrsTraitsRequest: () => void;
  getUxrsRequest: (
    options: DataTypes.ApiListOptions,
    scrollOpts?: { append: boolean }
  ) => void;
  updateUxrRequest: (id: string, body: any) => void;
  clearExperiments: () => void;
  showModal: (component: React.ComponentType<any>, options: any) => void;
  reorderUxrRequest: (
    id: string,
    position: number,
    currentOrder: OrderDirection
  ) => void;
  reorderLoading: boolean;
  loading: boolean;
  accountLoading: boolean;
  collections: any;
  name: string;
  uxrTrait: any;
}

interface IState {
  selectedIdx: number;
  selectedFilters: { [key: string]: Array<string> };
  ids: any;
  search: string;
  currentOrder: string;
  limit: number;
  resetingFilters: boolean;
  order: {
    [key: string]: OrderDirection;
  };
}

class Uxr extends Scrollable<IProps, IState> {
  static defaultProps: any;
  listRef: any;

  constructor(props: IProps) {
    super(props);
    const q = queryString.parse(this.props.location.search, {
      arrayFormat: "bracket",
    });
    let selectedFilters: { [key: string]: Array<any> } = {};
    if (Array.isArray(q.q))
      selectedFilters = Helpers.getFiltersFromQueryString(q.q);

    const { orderBy, orderDirection } = q;
    let order = {
      customOrder: OrderDirection.NONE,
      name: OrderDirection.NONE,
      methodology: OrderDirection.NONE,
      startDate: OrderDirection.NONE,
      endDate: OrderDirection.NONE,
      updatedAt: OrderDirection.NONE,
      createdAt: OrderDirection.NONE,
    };

    if (orderBy) {
      order = { ...order, [orderBy as any]: orderDirection };
    }

    this.state = {
      ids: q.ids || null,
      selectedIdx: 0,
      selectedFilters: selectedFilters,
      search: q.search,
      currentOrder: (orderBy as string) || "",
      order,
      resetingFilters: false,
      limit: DEFAULT_LIMIT,
    };

    this.fetchMore = _.debounce(this.fetchMore, 500, {
      leading: true,
      trailing: false,
    });
  }

  private clearFilters = () => {
    const qStr = queryString.stringify(
      {},
      {
        encode: true,
        arrayFormat: "bracket",
      }
    );

    history.push(`/uxr?${qStr}`);
    this.setState(
      {
        selectedFilters: {},
        resetingFilters: true,
      },
      () => {
        this.setState({
          resetingFilters: false,
        });
      }
    );
    this.props.getUxrsRequest({
      q: null,
      sort: this.getSortString(),
    });
  };

  private getSortString() {
    if (!this.state.currentOrder) {
      return "updated_at";
    }

    const direction =
      this.state.order[this.state.currentOrder] === OrderDirection.ASC
        ? "-"
        : "";

    return `${direction}${Helpers.camelToSnake(this.state.currentOrder)}`;
  }

  private fetchMore = (endCursor: string, append = true) => {
    const {
      collections: { experiments },
    } = this.props;
    if (
      (experiments && experiments.data.length > 0 && experiments.has_more) ||
      !append
    ) {
      let q = queryString.parse(this.props.location.search, {
        arrayFormat: "bracket",
      });
      const options = {
        q: q.q,
        sort: this.getSortString(),
        search: q.search,
        starting_after: endCursor,
        limit: this.state.limit,
      };

      this.props.getUxrsRequest(options, { append });
    }
  };

  private resetetOrders() {
    return Object.keys(this.state.order).reduce(
      (acc, current) => ({ ...acc, [current]: OrderDirection.NONE }),
      {}
    );
  }

  private changeOrder(orderBy: string, currentDirection: OrderDirection) {
    let newDirection: OrderDirection;

    if (orderBy !== "customOrder") {
      if (currentDirection === OrderDirection.DESC) {
        newDirection = OrderDirection.ASC;
      } else {
        newDirection = OrderDirection.DESC;
      }
    } else {
      newDirection = OrderDirection.ASC;
    }

    const order = { ...this.resetetOrders(), [orderBy]: newDirection };
    this.setState(
      {
        order,
        currentOrder: orderBy,
      },
      () => {
        const query = queryString.parse(this.props.location.search, {
          arrayFormat: "bracket",
        });
        query.orderBy = orderBy;
        query.orderDirection = newDirection;

        const qStr = queryString.stringify(query, {
          encode: true,
          arrayFormat: "bracket",
        });
        history.push(`?${qStr}`);

        const q = queryString.parse(this.props.location.search, {
          arrayFormat: "bracket",
        });
        this.props.clearExperiments();
        this.props.getUxrsRequest({
          limit: DEFAULT_LIMIT,
          sort: this.getSortString(),
          q: q.q,
        });
      }
    );
  }

  itemSelected = (itemIndex: number) => {
    this.setState(
      {
        ...this.state,
        selectedIdx: -1,
      },
      () => {
        this.setState({ ...this.state, selectedIdx: itemIndex });
      }
    );
  };

  componentDidMount = () => {
    const q = queryString.parse(this.props.location.search, {
      arrayFormat: "bracket",
    });

    let selectedFilters: { [key: string]: Array<any> } = {};
    if (Array.isArray(q.q))
      selectedFilters = Helpers.getFiltersFromQueryString(q.q);
    this.setState({ ...this.state, selectedFilters });

    this.props.clearAppErrors();
    this.props.getUxrsTraitsRequest();
    this.props.getUxrsRequest({
      limit: DEFAULT_LIMIT,
      sort: this.getSortString(),
      ids: q.ids,
      q: q.q,
      search: q.search,
      month: q.month as string,
    });
    super.componentDidMount();
  };

  private onFilterCheck = (checked: boolean, type: string, value: string) => {
    let q = queryString.parse(this.props.location.search, {
      arrayFormat: "bracket",
    });

    if (checked) {
      if (q.q && Array.isArray(q.q)) {
        q.q.push(`${type}=${value}`);
      } else {
        q.q = [`${type}=${value}`];
      }
    } else {
      if (q.q && Array.isArray(q.q)) {
        q.q = q.q.filter((item: string) => item !== `${type}=${value}`);
      }
    }

    // we want to only have one methodology so lets remove any that exists and replace with new value.
    if (q) {
      if (type === `methodology`) {
        if (q.q && Array.isArray(q.q)) {
          q.q = q.q.filter((item: string) => !item.startsWith(`methodology=`));
          q.q.push(`${type}=${value}`);
        } else {
          q.q = [`${type}=${value}`];
        }
      }
    }

    const qStr = queryString.stringify(q, {
      encode: true,
      arrayFormat: "bracket",
    });

    if (Array.isArray(q.q)) {
      this.setState({
        ...this.state,
        selectedFilters: Helpers.getFiltersFromQueryString(q.q),
      });
    }

    history.push(`/uxr?${qStr}`);
    this.props.getUxrsRequest({
      q: q.q,
      month: q.month as string,
      sort: this.getSortString(),
    });
  };

  private onChange = (
    uxr: DataTypes.Uxr,
    key: string,
    value: string | Array<string> | boolean
  ) => {
    if (this.isReadOnly()) {
      return;
    }

    this.props.updateUxrRequest(uxr.id || "", {
      uxr: { [key]: value },
    });
  };

  private isReadOnly = () => {
    const user = AuthService.getActiveUser();

    return user?.role === "read-only";
  };

  private onSearchSubmit = (search: string) => {
    let q = queryString.parse(this.props.location.search, {
      arrayFormat: "bracket",
    });

    if (search) {
      q = { ...q, search };
    } else {
      delete q.search;
    }

    const qStr = queryString.stringify(q, {
      encode: true,
      arrayFormat: "bracket",
    });
    history.push(`/uxrs?${qStr}`);
    this.props.getUxrsRequest(
      { sort: this.getSortString(), q: q.q, search },
      { append: false }
    );
  };

  onLimitChange = (limit = DEFAULT_LIMIT) => {
    setTimeout(() => {
      if (this.props.loading) {
        this.onLimitChange(limit);
        return;
      }

      this.setState({ limit: limit }, () => {
        this.fetchMore("", false);
      });
    }, 500);
  };

  private onReorder = (id: string, index: number) => {
    this.props.reorderUxrRequest(
      id,
      index,
      this.state.order[this.state.currentOrder]
    );
  };

  private openExplanation = () => {
    this.props.showModal(Modals.Explanation, {
      className: "Explanation",
    });
  };

  render() {
    const { accountLoading, loading, collections, account, uxrTrait } =
      this.props;
    const { selectedIdx, selectedFilters } = this.state;

    if (account?.summary?.totals.uxrs === 0) {
      return <EmptyState />;
    }

    return (
      <React.Fragment>
        <section className="FilterBar">
          <div className="viewCtrls">
            <button className={"btn btn-icon active"} onClick={(e) => {}}>
              <i className="fas fa-bars" />
            </button>
          </div>
          <div className="pad20">
            <hr />
          </div>
          <TotalCountStat
            name="UXR Studies"
            total={account?.summary?.totals.uxrs || 0}
          />
          {this.props.currentUser.role === "read-only" ? null : (
            <div className="addCtrls">
              <Link to="/uxr/create" className="btn btn-primary">
                Add UX Research
              </Link>
            </div>
          )}
          <div className="w--full flex justify-center">
            <button
              className="button button__link p__zero"
              style={{ padding: 0, textDecoration: "none" }}
              onClick={() => this.props.showModal(BestInClass, {})}
            >
              <i className="fas fa-lightbulb"></i> Best in Class Example
            </button>
          </div>
          <div className="pad20">
            <hr />
          </div>

          {/* <MarketingBanner
            containerStyle={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              marginTop: "20px",
              marginLeft: "3px",
            }}
            bannerPlacementSlug={"filter_bar_banners"}
            imageStyle={{ width: "200px", height: "200px" }}
          /> */}

          {this.state.resetingFilters ? null : (
            <Filters
              onSorting={this.changeOrder.bind(this)}
              sortingValues={this.state.order}
              loading={accountLoading}
              traits={uxrTrait?.traits}
              onCheck={this.onFilterCheck}
              onLimitChange={this.onLimitChange}
              selectedFilters={selectedFilters}
              onClearFilters={this.clearFilters}
            />
          )}
          <div className="cta">
            <button
              onClick={this.openExplanation}
              className="btn btn-info-link"
            >
              Learn about our UX Research structure to document and share your
              study findings
            </button>
          </div>
        </section>

        <React.Fragment>
          <div className="Experiments list-view">
            <div className="SearchBar">
              <SearchBar
                onChange={(search) => this.setState({ ...this.state, search })}
                value={this.state.search}
                placeholder="Search for Research"
                onSubmit={this.onSearchSubmit}
                enableReset
              />
            </div>
            <section className="Experiments List">
              {collections.uxrs && collections.uxrs.metadata ? (
                <GraphQLInfiniteScroll
                  pageStart={1}
                  startcursor={collections.uxrs.metadata.startCursor}
                  endcursor={collections.uxrs.metadata.endCursor}
                  loadMore={this.fetchMore}
                  hasMore={
                    (collections.uxrs && collections.uxrs.has_more) || false
                  }
                  initialLoad={true}
                  loader={
                    <CustomLoader
                      key={"loader"}
                      text="Wait while we load more researchs for you"
                    />
                  }
                  ref={(ref) => (this.listRef = ref)}
                  useWindow={false}
                >
                  <List
                    key="list-experiments"
                    reorderable={
                      !this.props.reorderLoading &&
                      this.state.currentOrder === "customOrder" &&
                      this.props.currentUser.role !== "read-only"
                    }
                    onReorder={this.onReorder}
                    loading={loading && collections.uxrs === undefined}
                    uxrs={collections.uxrs && collections.uxrs.data}
                    selectedIdx={selectedIdx}
                    onSelect={this.itemSelected}
                  />
                </GraphQLInfiniteScroll>
              ) : null}
            </section>
          </div>
          <section className="Aside exp">
            {this.state.selectedIdx !== -1 && (
              <Aside
                currentUser={this.props.currentUser}
                loading={loading}
                account={account}
                onChange={this.onChange}
                traits={uxrTrait?.traits}
                uxr={
                  collections.uxrs && collections.uxrs.data
                    ? collections.uxrs.data[selectedIdx]
                    : null
                }
              />
            )}
          </section>
        </React.Fragment>
      </React.Fragment>
    );
  }
}

Uxr.defaultProps = {
  name: "uxr",
};

const loadingSelector = createLoadingSelector(["@@uxr/GET_UXR"]);
const accountLoadingSelector = createLoadingSelector([
  "@@account/GET_ACCOUNT",
  "@@uxr_traits/GET_UXRS_TRAITS",
]);
const reorderLoading = createLoadingSelector(["@@uxr/REORDER"]);

const mapStateToProps = ({
  router,
  app,
  account,
  collections,
  uxrTrait,
}: ApplicationState) => ({
  router,
  collections,
  account,
  uxrTrait,
  loading: loadingSelector(app.requests),
  accountLoading: accountLoadingSelector(app.requests),
  reorderLoading: reorderLoading(app.requests),
});

const mapDispatchToProps = {
  clearAppErrors,
  getUxrsTraitsRequest,
  getUxrsRequest,
  updateUxrRequest,
  clearExperiments,
  reorderUxrRequest,
  showModal,
};

const connectedPage = connect(
  mapStateToProps,
  mapDispatchToProps
)(AppPage(Uxr));

export { connectedPage as Uxrs };

const EmptyState = () => {
  return (
    <div className="Experiments empty-state">
      <div className="wrapper">
        <img src="/img/experiments_empty.png" alt="add new experiment" />
        <h1>UX Research Studies</h1>

        <h4>
          UX Research Studies provide a ton of customer insights and they are a
          great complement to experimentation.
        </h4>

        <p>
          Use illuminate to generate professional, easy-to-read sharable study
          reports detailing your study design, insights, overarching themes and
          supporting data. Share your findings with just a click, and easily
          reference them later using the searchable/filterable repository. Get
          started by adding a study.
        </p>
        <button
          className="btn btn-primary"
          type="button"
          onClick={() => history.push("/uxr/create")}
        >
          Create New Study
        </button>
        <p className="mt-2">
          <i>
            Pro Tip: Check out this{" "}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://sandbox.illuminate.app/uxrs/50e527d"
            >
              Best Practice Example
            </a>{" "}
            for inspiration on how to get the most out of this feature.
          </i>
        </p>
      </div>
    </div>
  );
};
