import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import * as API from "../../api";
import * as AppTypes from "../app/types";
import * as Actions from "./actions";
import * as AccountActions from "../account/actions";
import * as InsightTraitActions from "../insight_trait/actions";
import { ActionTypes } from "./types";

function* getInsights(action: any) {
  try {
    // @ts-ignore
    const res = yield call(API.Insights.index, action.payload.options);

    const append =
      action.payload.scrollOpts && action.payload.scrollOpts.append;
    yield put(Actions.getInsightsSuccess(res.body, append));
  } catch (error: any) {
    yield put(Actions.getInsightsError(error));
  }
}

function* createInsight(action: any) {
  try {
    // @ts-ignore
    const res = yield call(API.Insights.create, action.payload.insight);
    yield put(AccountActions.getAccountSummaryRequest());
    yield put(Actions.createInsightSuccess(res.body));
    if (action.payload.cb) {
      yield put({
        type: AppTypes.App.ALERT_SHOW,
        content: "Insight created successfully",
      });
      return action.payload.cb(res.body);
    }
    yield put({
      type: AppTypes.App.ALERT_SHOW,
      content: "Insight created successfully",
    });
    yield put({
      type: ActionTypes.GET_INSIGHTS_REQUEST,
      payload: { options: { sort: "updated_at" } },
    });
  } catch (error: any) {
    yield put(Actions.createInsightError(error));
  }
}

function* updateInsight(action: any) {
  try {
    // @ts-ignore
    const res = yield call(API.Insights.update, action.payload.insight);
    yield put(AccountActions.getAccountTraitsRequestSaga());
    yield put({
      type: AppTypes.App.ALERT_SHOW,
      content: "Insight updated successfully",
    });

    yield put(Actions.updateInsightSuccess(res.body));
  } catch (error: any) {
    yield put(Actions.updateInsightError(error));
  }
}

function* deleteInsight(action: any) {
  try {
    // @ts-ignore
    const res = yield call(API.Insights.delete, action.payload.id);
    yield put(AccountActions.getAccountSummaryRequest());
    yield put(InsightTraitActions.getInsightsTraitRequestSaga());
    yield put(Actions.deleteInsightSuccess(res.body));
    yield put({ type: AppTypes.App.MODAL_HIDE });
    yield put({
      type: AppTypes.App.ALERT_SHOW,
      content: "Insight deleted successfully",
    });
    yield put({
      type: ActionTypes.GET_INSIGHTS_REQUEST,
      payload: { options: { sort: "updated_at" } },
    });
  } catch (error: any) {
    yield put(Actions.deleteInsightError(error));
  }
}

function* createInsightNote(action: any) {
  try {
    const { insightId, value } = action.payload;

    // @ts-ignore
    const res = yield call(API.Insights.addNotes, insightId, value);
    yield put(Actions.createInsightNoteSuccess(res.body));
    yield put({
      type: AppTypes.App.ALERT_SHOW,
      content: "Insight updated successfully",
    });
  } catch (error: any) {
    yield put(Actions.createInsightError(error));
  }
}

function* deleteNote(action: any) {
  try {
    const { noteId } = action.payload;

    // @ts-ignore
    const res = yield call(API.Insights.deleteNote, noteId);

    yield put(Actions.deleteNoteSuccess(res.body));
    yield put({
      type: AppTypes.App.ALERT_SHOW,
      content: "Note deleted successfully",
    });
  } catch (error: any) {
    yield put(Actions.deleteNoteError(error));
  }
}

function* getInsightShare(action: any) {
  try {
    const { uuid, password, slug } = action.payload;
    // @ts-ignore
    const response = yield call(API.Share.insight, slug, uuid, password);

    yield put(Actions.getInsightShareSuccess(response.body));
  } catch (error: any) {
    yield put(Actions.getInsightShareError(error));
  }
}

function* reorderInsight(action: any) {
  try {
    const { id, position, currentOrder } = action.payload;

    // @ts-ignore
    const res = yield call(API.Insights.reorder, id, position + 1);
    yield put(Actions.reorderInsightSuccess(res.body, currentOrder));
  } catch (error: any) {
    yield put(Actions.reorderInsightError(error));
  }
}

function* uploadInsights(action: any) {
  try {
    const { file } = action.payload;

    yield call(API.Insights.uploadInsights, file);

    yield put({ type: AppTypes.App.MODAL_HIDE });
    yield put({
      type: ActionTypes.GET_INSIGHTS_REQUEST,
      payload: { options: { sort: "updated_at" } },
    });
    yield put({
      type: AppTypes.App.ALERT_SHOW,
      content: "Batch upload complete.",
    });
  } catch (error: any) {
    if (error.body?.errors?.message) {
      yield put({
        type: AppTypes.App.ALERT_SHOW,
        content: error.body?.errors?.message,
        error: true,
      });
    } else {
      yield put({
        type: AppTypes.App.ALERT_SHOW,
        content: "Something went wrong, review your csv and try again.",
        error: true,
      });
    }

    yield put(Actions.uploadInsightsError(error));
  }
}

function* watchDeleteNote() {
  yield takeEvery(ActionTypes.DELETE_NOTE_REQUEST, deleteNote);
}

function* watchGetInsights() {
  yield takeEvery(ActionTypes.GET_INSIGHTS_REQUEST, getInsights);
}

function* watchCreateInsightNote() {
  yield takeEvery(ActionTypes.CREATE_INSIGHT_NOTE_REQUEST, createInsightNote);
}

function* watchCreateInsight() {
  yield takeEvery(ActionTypes.CREATE_INSIGHT_REQUEST, createInsight);
}

function* watchUpdateInsight() {
  yield takeEvery(ActionTypes.UPDATE_INSIGHT_REQUEST, updateInsight);
}

function* watchDeleteInsight() {
  yield takeEvery(ActionTypes.DELETE_INSIGHT_REQUEST, deleteInsight);
}

function* watchGetInsightShare() {
  yield takeEvery(ActionTypes.GET_INSIGHT_SHARE_REQUEST, getInsightShare);
}

function* watchReorderInsight() {
  yield takeEvery(ActionTypes.REORDER_REQUEST, reorderInsight);
}

function* watchUploadInsights() {
  yield takeEvery(ActionTypes.UPLOAD_INSIGHTS_REQUEST, uploadInsights);
}

function* insightsSaga() {
  yield all([
    fork(watchGetInsights),
    fork(watchCreateInsight),
    fork(watchUpdateInsight),
    fork(watchDeleteInsight),
    fork(watchCreateInsightNote),
    fork(watchDeleteNote),
    fork(watchGetInsightShare),
    fork(watchReorderInsight),
    fork(watchUploadInsights),
  ]);
}

export default insightsSaga;
