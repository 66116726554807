import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import { AuthService } from 'utils/auth';
import { handleOutsideClick, onEnterPress } from './utils';

interface IProps {
  name: string;
  activeItems: Array<string>;
  items: Array<any>;
  onChange?(values: Array<string>): void;
}

const MultiSelectInput = ({ name, activeItems, items, onChange }: IProps) => {
  const ref = useRef<any>(null);

  const [edit, setEdit] = useState(false);
  const [searchVal, setSearch] = useState('');
  const [hoverIdx, setHoverIndex] = useState(0);
  const [results, setResults] = useState([...new Set(items)]);
  const [active, setActiveItems] = useState(activeItems);
  const [exactMatch, setExactMatch] = useState(false);

  onEnterPress(ref, () => {
    open(false);
    if (searchVal !== '' && exactMatch === false) {
      let currentActiveItems = active;
      currentActiveItems.push(searchVal);
      setSearch('');
      setActiveItems(currentActiveItems);
      if (onChange) onChange(currentActiveItems);
    }
  });

  handleOutsideClick(ref, () => {
    if (edit) {
      setSearch('');
      setHoverIndex(0);
      open(!edit);
    }
  });

  useEffect(() => {
    setResults(items);
  }, [items]);

  const setSearchValue = (value: string) => {
    setHoverIndex(-1);
    setSearch(value);

    const results = items.filter((val) => val.toLowerCase().startsWith(value.toLowerCase()));
    const exact = results.filter((val) => val.toLowerCase() === value.toLowerCase()).length > 0;
    setExactMatch(exact);

    if (exact === true) setHoverIndex(0);

    setResults(results);
  };

  const onAddActiveItems = (value: string) => {
    let currentActiveItems = active;
    if (currentActiveItems.findIndex((a) => a.toLowerCase() === value.toLowerCase()) >= 0) {
      const itemIdx = currentActiveItems.indexOf(value);
      currentActiveItems = currentActiveItems.slice(0, itemIdx).concat(activeItems.slice(itemIdx + 1, items.length));
    } else {
      currentActiveItems.push(value);
    }

    setActiveItems(currentActiveItems);
    setSearch('');
    if (onChange) onChange(currentActiveItems);
  };

  const onRemoveActiveItems = (itemIdx: number) => {
    let currentActiveItems = active;
    currentActiveItems = currentActiveItems.slice(0, itemIdx).concat(activeItems.slice(itemIdx + 1, items.length));
    setActiveItems(currentActiveItems);
    if (onChange) onChange(currentActiveItems);
  };

  const open = (value: any) => {
    if (isReadOnly()) {
      return;
    }

    setEdit(value);
  }

  const isReadOnly = () => {
    const user = AuthService.getActiveUser();

    return user.role === 'read-only';
  }

  return (
    <div ref={ref} className={edit && !isReadOnly() ? 'multiselect_input edit' : 'multiselect_input'}>
      <ul className="input_box" onClick={(e) => !edit && open(!edit)}>
        {active && active.map((item, idx) => (
          <li key={`item_${name}_${item}_${idx}`}>
            <span>{item}</span>
            {edit === true && <button type="button" onClick={(e) => onRemoveActiveItems(idx)}></button>}
          </li>
        ))}
      </ul>
      <div className="menu">
        <div className="search">
          <input
            type="text"
            placeholder="Search or add new"
            ref={(input) => input && input.focus()}
            value={searchVal}
            onChange={(e) => setSearchValue(e.target.value)}
          />
        </div>
        <div className="results">
          <ul>
            {searchVal !== '' && exactMatch === false && (
              <li className={hoverIdx === -1 ? 'over new' : 'new'} onMouseOver={(e) => setHoverIndex(-1)} onClick={(e) => onAddActiveItems(searchVal)}>
                {searchVal}
              </li>
            )}
            {results.map((item, idx) => (
              <li
                key={`result_${name}_${item}_${idx}`}
                className={hoverIdx === idx ? (active.findIndex((a) => a.toLowerCase() === item.toLowerCase()) >= 0 ? 'over active' : 'over') : ''}
                onMouseOver={() => setHoverIndex(idx)}
                onClick={() => onAddActiveItems(item)}
              >
                {item}
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = () => ({});

const mapDispatchToProps = {};

const connectedComponent = connect(mapStateToProps, mapDispatchToProps)(MultiSelectInput);

export { connectedComponent as MultiSelectInput };