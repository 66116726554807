import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import * as API from "../../api";
import * as Actions from "./actions";
import * as ExperimentActions from "../experiment/actions";
import * as AppTypes from "../app/types";
import { ActionTypes } from "./types";

function* createReportAnalytics(action: any) {
  try {
    const { experimentId, body } = action.payload;

    // @ts-ignore
    const res = yield call(API.ReportAnalytics.create, experimentId, body);

    yield put(Actions.createReportAnalyticsSuccess(res.body));
    yield put(ExperimentActions.updateExperimentSuccess(res.body));
    yield put({ type: AppTypes.App.MODAL_HIDE });
  } catch (error: any) {
    yield put(Actions.createReportAnalyticsError(error));
  }
}

function* watchCreateReportAnalytics() {
  yield takeEvery(
    ActionTypes.CREATE_REPORT_ANALYTICS_REQUEST,
    createReportAnalytics
  );
}

function* reportAnalyticsSaga() {
  yield all([fork(watchCreateReportAnalytics)]);
}

export default reportAnalyticsSaga;
