import React, { Component } from 'react';
import * as DataTypes from '../../../../store/types';

interface IProps {
  user: DataTypes.User;
  onConfirm(user: DataTypes.User): void;
}

class UserChangeRoleModal extends Component<IProps> {
  render() {
    const { user, onConfirm } = this.props;

    return (
      <React.Fragment>
        <div className="header">
          <h3>Remove Admin Role</h3>
          <p>
            Are you sure you want to remove admin privileges for&nbsp;
            <strong>
              <span className="text-capitalize">
                {user.first_name} {user.last_name}
              </span>
            </strong>
            ?
          </p>
          <p className="mt-2">
            By doing so, they will still have Add/Edit/View functionality but will no longer be able to access account settings or add/remove users on this
            account.
          </p>
        </div>
        <div className="cntrl-bar">
          <button className="btn btn-primary" onClick={() => onConfirm(user)}>
            Confirm
          </button>
        </div>
      </React.Fragment>
    );
  }
}

export { UserChangeRoleModal };
