import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import * as API from "../../api";
import * as Actions from "./actions";
import * as ExperimentActions from "../experiment/actions";
import { ActionTypes } from "./types";
import * as DataTypes from "store/types";
import * as AppTypes from "../app/types";
import { history } from "utils";

function* createCustomDataViz(action: any) {
  try {
    const { experimentId, body } = action.payload;

    // @ts-ignore
    const res = yield call(API.CustomDataViz.create, experimentId, {
      custom_data_viz: body,
    });

    yield put(Actions.createCustomDataVizSuccess(res.body));
    yield put(ExperimentActions.getExperimentRequestAction(experimentId));

    history.push(`/tests/${experimentId}/results/${res.body.id}`);

    yield put({ type: AppTypes.App.MODAL_HIDE });
    yield put({
      type: AppTypes.App.ALERT_SHOW,
      content: "Custom Data Visualization added successfully",
    });
  } catch (error: any) {
    yield put(
      Actions.createCustomDataVizError(error as DataTypes.RequestError)
    );
  }
}

function* updateCustomDataViz(action: any) {
  try {
    const { experimentId, customDataVizId, body } = action.payload;

    // @ts-ignore
    const res = yield call(
      API.CustomDataViz.update,
      experimentId,
      customDataVizId,
      { custom_data_viz: body }
    );

    yield put(ExperimentActions.updateExperimentSuccess(res.body));
    yield put(Actions.updateCustomDataVizSuccess(res.body));
    yield put({ type: AppTypes.App.MODAL_HIDE });
    yield put({
      type: AppTypes.App.ALERT_SHOW,
      content: "Custom Data Visualization updated successfully",
    });
  } catch (error: any) {
    yield put(Actions.updateCustomDataVizError(error));
  }
}

function* deleteCustomDataViz(action: any) {
  try {
    const { experimentId, customDataVizId } = action.payload;

    // @ts-ignore
    const res = yield call(
      API.CustomDataViz.destroy,
      experimentId,
      customDataVizId
    );

    yield put(ExperimentActions.getExperimentRequestAction(experimentId));
    yield put({ type: AppTypes.App.MODAL_HIDE });

    yield put(Actions.deleteCustomDataVizSuccess(res.body));
    yield put({
      type: AppTypes.App.ALERT_SHOW,
      content: "Custom Data Visualization deleted successfully",
    });
  } catch (error: any) {
    yield put(Actions.deleteCustomDataVizError(error));
  }
}

function* updateCustomDataVizMetricAggregation(action: any) {
  try {
    const { experimentId, aggregationId, body } = action.payload;

    // @ts-ignore
    const res = yield call(
      API.CustomDataViz.updateMetric,
      experimentId,
      aggregationId,
      { metric: body }
    );

    yield put(ExperimentActions.updateExperimentSuccess(res.body));
    yield put(Actions.updateCustomDataVizMetricAggregationSuccess(res.body));
    yield put({
      type: AppTypes.App.ALERT_SHOW,
      content: "Custom Data Visualization updated successfully",
    });
  } catch (error: any) {
    yield put(Actions.updateCustomDataVizMetricAggregationError(error));
  }
}

function* watchCreateCustomDataViz() {
  yield takeEvery(
    ActionTypes.CREATE_CUSTOM_DATA_VIZ_REQUEST,
    createCustomDataViz
  );
}

function* watchUpdateCustomDataViz() {
  yield takeEvery(
    ActionTypes.UPDATE_CUSTOM_DATA_VIZ_REQUEST,
    updateCustomDataViz
  );
}

function* watchDeleteCustomDataViz() {
  yield takeEvery(
    ActionTypes.DELETE_CUSTOM_DATA_VIZ_REQUEST,
    deleteCustomDataViz
  );
}

function* watchUpdateCustomDataVizMetricAggregation() {
  yield takeEvery(
    ActionTypes.UPDATE_CUSTOM_DATA_VIZ_METRIC_AGGREGATION_REQUEST,
    updateCustomDataVizMetricAggregation
  );
}

function* customDataVizSaga() {
  yield all([
    fork(watchCreateCustomDataViz),
    fork(watchUpdateCustomDataViz),
    fork(watchDeleteCustomDataViz),
    fork(watchUpdateCustomDataVizMetricAggregation),
  ]);
}

export default customDataVizSaga;
