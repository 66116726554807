import { ApiClient } from "./client/api_client";
import { AccountRequest } from "../store/types";

class Account {
  static async create(body: AccountRequest): Promise<any> {
    return await ApiClient.post("/v1/account", { account: body });
  }

  static async update(body: any) {
    return await ApiClient.put("/v1/account", body);
  }

  static async get(slug: string) {
    return await ApiClient.get(`/v1/account/${slug}`);
  }

  static async traits() {
    return await ApiClient.get("/v1/account/traits");
  }

  static async stats(
    startDate: string,
    endDate: string,
    brand?: string,
    team?: string,
    channel?: string
  ) {
    let queryList: any = [];

    if (startDate) {
      queryList = [...queryList, `start_date=${encodeURIComponent(startDate)}`];
    }

    if (endDate) {
      queryList = [...queryList, `end_date=${encodeURIComponent(endDate)}`];
    }

    if (brand) {
      queryList = [...queryList, `brand=${encodeURIComponent(brand)}`];
    }

    if (team) {
      queryList = [...queryList, `team=${encodeURIComponent(team)}`];
    }

    if (channel) {
      queryList = [...queryList, `channel=${encodeURIComponent(channel)}`];
    }

    let query = "";

    if (queryList.length > 0) {
      query = `?${queryList.join("&")}`;
    }

    return await ApiClient.get(`/v1/account/stats${query}`);
  }

  static async summary() {
    return await ApiClient.get("/v1/account/summary");
  }

  static async users(_: number, __: string, page = 1) {
    return await ApiClient.get(`/v1/user?page=${page}`);
  }

  static async getPendingInvites() {
    return await ApiClient.get("/v1/user/invite");
  }

  static async deletePendingInvite(id: string) {
    return await ApiClient.delete(`/v1/user/invite/${id}`);
  }
}

export { Account };
