import React, { Component } from "react";
import { connect } from "react-redux";
import { ApplicationState } from "store/types";
import * as DataTypes from "store/types";
import TimeAgo from "timeago-react";
import { Avatar, DropdownControl, MultiSelectInput, TextInput } from "ui";
import { Helpers, history } from "utils";
import { createWorksheetRequest } from "store/worksheet/actions";
import { showModal } from "store/app/actions";
import { updateIdeaBoardShareRequest } from "store/idea_board/actions";
import { ShareBoard } from "./modals/share_board";
import { DeleteBoard } from "pages/app/ideas/comps/modals";
import { Archive } from "./modals/archive";
import {
  deleteIdeaBoardsManagementRequest,
  updateIdeaBoardManagementRequest,
  archiveIdeaBoardManagementRequest,
} from "store/idea_board_management/actions";
import { createIdeaBoardsTraitsRequest } from "store/idea_board_trait/actions";

import "css/Ideas.scss";
import { EditorInput } from "ui/editor_input/editor_input";
import { Link } from "react-router-dom";

interface IProps {
  router: any;
  account: DataTypes.Account | null;
  currentUser: any;
  ideaBoard: DataTypes.IdeaBoard | null;
  createWorksheetRequest: (board: DataTypes.IdeaBoard) => void;
  showModal: (component: React.ComponentType<any>, options: any) => void;
  updateIdeaBoardShareRequest: (id: string, body: any) => void;
  deleteIdeaBoardsManagementRequest: (id: string) => void;
  updateIdeaBoardManagementRequest: (
    id: string,
    body: any,
    onUpdate?: any
  ) => void;
  archiveIdeaBoardManagementRequest: (
    id: string,
    body: any,
    onUpdate?: any
  ) => void;
  createIdeaBoardsTraitsRequest: (
    ideaBoardId: string,
    traitName: string,
    values: string[]
  ) => void;
  ideaBoardTrait: any;
}

interface IState {}

class Sidebar extends Component<IProps, IState> {
  onChange = (prop: string, value: any) => {
    const { ideaBoard } = this.props;

    if (!ideaBoard || !ideaBoard.id) {
      return;
    }

    this.props.updateIdeaBoardManagementRequest(ideaBoard.id, {
      ...ideaBoard,
      [prop]: value,
    });
  };

  onDelete = () => {
    const { ideaBoard } = this.props;

    if (!ideaBoard) {
      return;
    }

    this.props.showModal(DeleteBoard, {
      board: ideaBoard,
      onSubmit: this.onDeleteBoard,
    });
  };

  onArchive = () => {
    const { ideaBoard } = this.props;

    if (!ideaBoard) {
      return;
    }

    this.props.showModal(Archive, { ideaBoard, onSubmit: this.onArchiveBoard });
  };

  private onDeleteBoard = () => {
    const { ideaBoard } = this.props;

    if (!ideaBoard || !ideaBoard.id) {
      return;
    }

    this.props.deleteIdeaBoardsManagementRequest(ideaBoard.id);
    this.setState({ ...this.state, board_id: undefined, group: "all" });
  };

  private onArchiveBoard = () => {
    const { ideaBoard } = this.props;

    if (!ideaBoard || !ideaBoard.id) {
      return;
    }

    this.props.archiveIdeaBoardManagementRequest(ideaBoard.id, {
      ...ideaBoard,
      archived: !ideaBoard.archived,
    });
  };

  private onViewWorksheet = () => {
    const { ideaBoard } = this.props;

    if (!ideaBoard) {
      return;
    }

    if (ideaBoard?.worksheet) {
      history.push(`/ideas/board/${ideaBoard.id}/worksheet`);
    } else {
      if (this.isReadOnly()) {
        return;
      }

      this.props.createWorksheetRequest(ideaBoard);
    }
  };

  private onUpdateShare = (
    id: string,
    passwordProtected: boolean,
    password?: string
  ) => {
    if (passwordProtected === true && password) {
      this.props.updateIdeaBoardShareRequest(id, {
        idea_board: {
          password_protected: passwordProtected,
          password: password,
        },
      });
    } else {
      this.props.updateIdeaBoardShareRequest(id, {
        idea_board: { password_protected: passwordProtected },
      });
    }
  };

  shareBoard = () => {
    const { ideaBoard } = this.props;

    if (!ideaBoard) {
      return;
    }

    this.props.showModal(ShareBoard, {
      className: "Share",
      board: ideaBoard,
      onSubmit: this.onUpdateShare,
    });
  };

  buildHeader = () => {
    const { ideaBoard } = this.props;

    if (!ideaBoard) {
      return;
    }

    let controlItems = [
      {
        name: "Share & Request Ideas",
        icon: "fas fa-share-alt",
        onClick: () => this.shareBoard(),
      },
      {
        name: ideaBoard?.worksheet ? "View Worksheet" : "Create Worksheet",
        icon: "far fa-file-alt",
        onClick: () => this.onViewWorksheet(),
      },
      {
        name: ideaBoard.archived ? "Restore" : "Archive",
        icon: ideaBoard.archived ? "fas fa-undo" : "fas fa-archive",
        onClick: () => this.onArchive(),
        splitAbove: true,
        className: "",
      },
      {
        name: "Delete Idea Board",
        icon: "far fa-trash-alt",
        onClick: () => this.onDelete(),
        className: "danger",
      },
    ];

    return (
      <section className="header">
        <div className="left">
          <div className="status-btns">
            <button
              key={["status_love_", ideaBoard.id].join("_")}
              className={`btn btn-status`}
              type="button"
              title={
                ideaBoard?.worksheet ? "View Worksheet" : "Create Worksheet"
              }
              onClick={() => this.onViewWorksheet()}
            >
              <i
                className={`far fa-file-alt ${
                  ideaBoard.worksheet ? "active" : ""
                }`}
              />
            </button>
          </div>
        </div>
        <div className="right">
          <div className="controls">
            {this.isReadOnly() ? null : (
              <DropdownControl controlItems={controlItems} />
            )}
          </div>
        </div>
      </section>
    );
  };

  buildTitle = () => {
    const { ideaBoard } = this.props;

    if (!ideaBoard) {
      return null;
    }

    return (
      <section className="title-bar">
        <div>
          <p className="date">
            Updated <TimeAgo datetime={ideaBoard.updated_at} live={false} />
          </p>
          <TextInput
            readonly={this.isReadOnly()}
            value={ideaBoard.name || ""}
            onChange={(value) => this.onChange("name", value)}
          >
            <h2>{ideaBoard.name}</h2>
          </TextInput>
        </div>
        <div>
          <Avatar user={ideaBoard.author} />
        </div>
      </section>
    );
  };

  isReadOnly = () => {
    return this.props.currentUser.role === "read-only";
  };

  buildQuestions = () => {
    const { ideaBoard, account, ideaBoardTrait } = this.props;

    if (!ideaBoard || !account || !ideaBoardTrait) {
      return null;
    }

    const { traits } = ideaBoardTrait;

    return (
      <section className="questions">
        <div className="question">
          <label>Description</label>
          <EditorInput
            content={ideaBoard.description}
            onBlur={(e: any) => this.onChange("description", e)}
            area={true}
          />
        </div>
        <div className="question">
          <label className="m-0">Ideas</label>
          <p
            className="cursor--pointer"
            onClick={() =>
              history.push(`/ideas?q[]=idea_board_id=${ideaBoard.id}`)
            }
          >
            <span
              className="badge badge-secondary"
              style={{ fontSize: "12px" }}
            >
              {ideaBoard.total_ideas}
            </span>
          </p>
        </div>
        <div className="question">
          <label className="m-0">Audiences</label>
          <p className="m-0">
            <small>Specify what audience(s) your test should target.</small>
          </p>
          <MultiSelectInput
            key={["audiences", ideaBoard.id].join("_")}
            activeItems={ideaBoard.audiences}
            name="audiences"
            items={
              traits && traits["audiences"]
                ? Helpers.uniqueArray(
                    traits["audiences"].map((t: any) => {
                      return t.name;
                    })
                  )
                : []
            }
            onChange={(values) =>
              this.props.createIdeaBoardsTraitsRequest(
                ideaBoard.id || "",
                "audiences",
                values
              )
            }
          />
        </div>
        <div className="question">
          <label className="m-0">Devices</label>
          <p className="m-0">
            <small>
              Track which device(s) should be included in your test.
            </small>
          </p>
          <MultiSelectInput
            key={["devices_", ideaBoard.id].join("_")}
            activeItems={ideaBoard.devices}
            name="devices"
            items={
              traits && traits["devices"]
                ? Helpers.uniqueArray(
                    traits["devices"].map((t: any) => {
                      return t.name;
                    })
                  )
                : []
            }
            onChange={(values) => this.onChange("devices", values)}
          />
        </div>
        <div className="question">
          <label className="m-0">Pages</label>
          <p className="m-0">
            <small>Specify the page(s) that should display your test.</small>
          </p>
          <MultiSelectInput
            key={["pages_", ideaBoard.id].join("_")}
            activeItems={ideaBoard.pages}
            name="pages"
            items={
              traits && traits["pages"]
                ? Helpers.uniqueArray(
                    traits["pages"].map((t: any) => {
                      return t.name;
                    })
                  )
                : []
            }
            onChange={(values) =>
              this.props.createIdeaBoardsTraitsRequest(
                ideaBoard.id || "",
                "pages",
                values
              )
            }
          />
        </div>
        {account && account.settings.brands && (
          <div className="question">
            <label className="m-0">Brands</label>
            <MultiSelectInput
              key={["brands", ideaBoard.id].join("_")}
              activeItems={ideaBoard.brands || []}
              name="brands"
              items={
                traits && traits["brands"]
                  ? Helpers.uniqueArray(
                      traits["brands"].map((t: any) => {
                        return t.name;
                      })
                    )
                  : []
              }
              onChange={(values) =>
                this.props.createIdeaBoardsTraitsRequest(
                  ideaBoard.id || "",
                  "brands",
                  values
                )
              }
            />
          </div>
        )}
        {account && account.settings.teams && (
          <div className="question">
            <label className="m-0">Teams</label>
            <MultiSelectInput
              key={["teams", ideaBoard.id].join("_")}
              activeItems={ideaBoard.teams || []}
              name="teams"
              items={
                traits && traits["teams"]
                  ? Helpers.uniqueArray(
                      traits["teams"].map((t: any) => {
                        return t.name;
                      })
                    )
                  : []
              }
              onChange={(values) =>
                this.props.createIdeaBoardsTraitsRequest(
                  ideaBoard.id || "",
                  "teams",
                  values
                )
              }
            />
          </div>
        )}
        {account && account.settings.channels && (
          <div className="question">
            <label className="m-0">Channels</label>
            <MultiSelectInput
              key={["channels", ideaBoard.id].join("_")}
              activeItems={ideaBoard.channels || []}
              name="channels"
              items={
                traits && traits["channels"]
                  ? Helpers.uniqueArray(
                      traits["channels"].map((t: any) => {
                        return t.name;
                      })
                    )
                  : []
              }
              onChange={(values) =>
                this.props.createIdeaBoardsTraitsRequest(
                  ideaBoard.id || "",
                  "channels",
                  values
                )
              }
            />
          </div>
        )}
      </section>
    );
  };

  buildCTAs = () => {
    const { ideaBoard } = this.props;

    if (!ideaBoard) {
      return null;
    }

    return (
      <section>
        {!this.isReadOnly() && (
          <Link
            to={`/ideas/create/${ideaBoard.id}`}
            className="button button__primary"
          >
            {" "}
            Create Idea
          </Link>
        )}

        <Link
          to={`/ideas?q[]=idea_board_id=${ideaBoard.id}`}
          className={`button button__primary-reverse ${
            this.isReadOnly() ? "" : "ml__sm"
          }`}
        >
          View Ideas
        </Link>
      </section>
    );
  };

  render() {
    const { ideaBoard } = this.props;

    if (!ideaBoard) {
      return null;
    }

    return (
      <section className="Aside pl__sm pr__sm pt__sm">
        <div className="Idea">
          {this.buildHeader()}
          {this.buildTitle()}
          {this.buildCTAs()}
          {this.buildQuestions()}
        </div>
      </section>
    );
  }
}

const mapStateToProps = ({
  router,
  account,
  ideaBoardTrait,
}: ApplicationState) => ({
  router,
  account,
  ideaBoardTrait,
});

const mapDispatchToProps = {
  createWorksheetRequest,
  showModal,
  updateIdeaBoardShareRequest,
  deleteIdeaBoardsManagementRequest,
  updateIdeaBoardManagementRequest,
  archiveIdeaBoardManagementRequest,
  createIdeaBoardsTraitsRequest,
};

const connectedPage = connect(mapStateToProps, mapDispatchToProps)(Sidebar);

export { connectedPage as Sidebar };
