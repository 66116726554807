import React, { useMemo } from "react";

import * as DataTypes from "store/types";

import { Report } from "./report";
import { CustomDataViz } from "./custom_data_viz";

interface IProps {
  reportId: string;
  experiment: DataTypes.Experiment;
  currentUser: any;
  onUpdateReport(report: DataTypes.Report): void;
  onUpdateCustomDataViz(customDataViz: Partial<DataTypes.CustomDataViz>): void;
  onAddAnalyticsMetrics?(): void;
  onDeleteReport(): void;
  onDeleteDataViz(): void;
  onUpdateReportStats(): void;
  onUpdateDataVizMetric(metricId: string, body: any): void;
  onOpenUpdateDataVizModal(customDataViz: DataTypes.CustomDataViz): void;
}

export const ReportSelector: React.FC<IProps> = ({
  experiment,
  reportId,
  currentUser,
  onUpdateReport,
  onDeleteReport,
  onUpdateReportStats,
  onUpdateCustomDataViz,
  onDeleteDataViz,
  onUpdateDataVizMetric,
  onOpenUpdateDataVizModal,
}) => {
  const isReport = useMemo(() => {
    const foundReport = experiment.reports.filter((report) => {
      return report.id === reportId;
    });

    return foundReport.length > 0;
  }, [experiment, reportId]);

  const isCustomDataViz = useMemo(() => {
    const foundDataViz = experiment.custom_data_viz.filter((dataViz: any) => {
      return dataViz.id === reportId;
    });

    return foundDataViz.length > 0;
  }, [experiment, reportId]);

  const report = useMemo(() => {
    if (!isReport) {
      return null;
    }

    return experiment.reports.filter((report) => report.id === reportId)[0];
  }, [reportId, isReport, experiment]);

  const customDataViz = useMemo(() => {
    if (!isCustomDataViz) {
      return null;
    }

    return experiment.custom_data_viz.filter(
      (dataViz: any) => dataViz.id === reportId
    )[0];
  }, [reportId, isCustomDataViz, experiment]);

  if (isReport && report) {
    return (
      <Report
        currentUser={currentUser}
        experiment={experiment}
        experiences={experiment.experiences}
        onUpdateReport={onUpdateReport}
        report={report}
        onDelete={onDeleteReport}
        onUpdateReportStats={onUpdateReportStats}
      />
    );
  }

  if (isCustomDataViz && customDataViz) {
    return (
      <CustomDataViz
        onUpdateDataVizMetric={onUpdateDataVizMetric}
        dataViz={customDataViz}
        onUpdate={onUpdateCustomDataViz}
        currentUser={currentUser}
        onDelete={onDeleteDataViz}
        onOpenUpdateModal={onOpenUpdateDataVizModal}
      />
    );
  }

  return null;
};
