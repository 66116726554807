import React, { Component, RefObject } from "react";
import { connect } from "react-redux";
import { createLoadingSelector } from "store/selectors";
import * as DataTypes from "store/types";
import { ApplicationState } from "store/types";
import { SelectInput } from "ui";

interface IProps {
  experiment: DataTypes.Experiment | null;
  uploadLoading: boolean;
  onSubmit(name: string, file: File, changedMetrics: any): void;
  existingMetrics: Array<DataTypes.Metric>;
  missingMetrics: Array<string>;
  name: string;
  file: File;
}

interface IState {
  existingMetrics: Array<string>;
  changedMetrics: any;
  error: string | null;
}

class UploadCSV extends Component<IProps, IState> {
  uploadFileButton: RefObject<any>;

  constructor(props: IProps) {
    super(props);

    this.uploadFileButton = React.createRef();

    this.state = {
      existingMetrics: this.props.existingMetrics.map(
        (metric) => metric.name || ""
      ),
      changedMetrics: {},
      error: null,
    };
  }

  componentDidMount(): void {
    document.addEventListener("reportUploadError", this.handleError);
  }

  componentWillUnmount(): void {
    document.removeEventListener("reportUploadError", this.handleError);
  }

  handleError = (error: any) => {
    this.setState({ error: error.detail });
  };

  onMetricUpdate = (metric: string, value: string) => {
    this.setState({
      changedMetrics: {
        ...this.state.changedMetrics,
        [metric]: value,
      },
    });
  };

  buildMetrics = () => {
    return this.props.missingMetrics.map((metric) => (
      <li
        key={metric}
        className="flex justify-between align-center border_b__separator pb__xsm"
      >
        <span className="fcol-1">{metric}</span>
        <SelectInput
          value={this.state.changedMetrics[metric] || metric}
          name="name"
          placeholder="select"
          searchEnabled={true}
          className="fcol-1"
          items={
            this.props.existingMetrics
              ? this.props.existingMetrics.map((metric) => {
                  return metric.name;
                })
              : []
          }
          onChange={(value) => this.onMetricUpdate(metric, value)}
        />
      </li>
    ));
  };

  private onSubmitForm = () => {
    this.props.onSubmit(
      this.props.name,
      this.props.file,
      this.state.changedMetrics
    );
  };

  render() {
    const { uploadLoading } = this.props;

    return (
      <React.Fragment>
        <div className="header">
          <h3>Import from CSV - {this.props.name}</h3>
          <p>Keep your metrics clean!</p>
          <p>
            To prevent duplicates (like Revenue per Visit as well as RPV),
            illuminate detects metrics that haven't been used yet and provides
            you with the opportunity to use an existing metric or to create a
            new one to add to your library.
          </p>
          <p>
            Use this as an opportunity to see if the new metrics you've added
            are necessary or if they already exist in another format.
          </p>
        </div>
        <div className="body" style={{ minHeight: "290px" }}>
          <ul>{this.buildMetrics()}</ul>
        </div>

        {this.state.error && (
          <ul
            style={{
              paddingLeft: "20px",
              display: "flex",
              flexWrap: "wrap",
              maxWidth: "800px",
            }}
          >
            <li style={{ width: "100%" }}>
              <p style={{ color: "#ff5a71" }}>
                Something went wrong with your upload, see the reasons below:
              </p>
            </li>
            <li>
              <p style={{ color: "#ff5a71" }}>{this.state.error}</p>
            </li>
          </ul>
        )}
        <div className="cntrl-bar">
          {uploadLoading === true ? (
            <button className="btn btn-primary" type="submit" disabled={true}>
              <i className="fas fa-circle-notch fa-spin" />
            </button>
          ) : (
            <button className="btn btn-primary" onClick={this.onSubmitForm}>
              import CSV data
            </button>
          )}
        </div>
      </React.Fragment>
    );
  }
}

const uploadSelector = createLoadingSelector(["@@experiment/UPLOAD_REPORT"]);

const mapStateToProps = ({ app }: ApplicationState) => ({
  uploadLoading: uploadSelector(app.requests),
});

const mapDispatchToProps = {};

const connectedPage = connect(mapStateToProps, mapDispatchToProps)(UploadCSV);

export { connectedPage as UploadCSV };
