import React, { useMemo, useState } from "react";
import * as DataTypes from "store/types";
import {
  ExpiredTargetsContainer,
  ProgramGoalTargetEmptyStateContainer,
  ProgramGoalTargets,
  StyledGoal,
} from "./goal_metric.style";
import Collapsible from "react-collapsible";
import { ProgramGoalTargetForm } from "./target_form";
import { ProgramGoalTargetList } from "./target_list";

interface IProps {
  programGoal: DataTypes.ProgramGoal;
  onDelete: (programGoal: DataTypes.ProgramGoal) => void;
  onCreate: (
    programGoalId: string,
    payload: Omit<DataTypes.ProgramGoalTarget, "id">
  ) => void;
  onDeleteTarget: (targetId: string) => void;
  openUpdateGoalModal: (programGoal: DataTypes.ProgramGoal) => void;
}

const EmptyState: React.FC = () => {
  return (
    <ProgramGoalTargetEmptyStateContainer>
      <img src="/img/insights_empty.png" alt="Set Program Goal Targets" />
      <h1>Set Program Goal Targets</h1>
      <p>
        You've set the metric(s) your program is designed to impact. Here, you{" "}
        can set targets with timelines and we'll show you how you're trending.{" "}
        To start, consider adding one overall goal as well as individual{" "}
        quarterly goals.
      </p>
    </ProgramGoalTargetEmptyStateContainer>
  );
};

export const GoalMetric = ({
  programGoal,
  onDelete,
  onCreate,
  onDeleteTarget,
  openUpdateGoalModal,
}: IProps) => {
  const [isExpiredOpen, setIsExpiredOpen] = useState<boolean>(false);

  const icon = useMemo(() => {
    if (programGoal.data_type === "number") {
      return "hashtag";
    }

    if (!programGoal.symbol || programGoal.symbol === "DOLLAR") {
      return "dollar-sign";
    }

    if (programGoal.symbol === "EURO") {
      return "euro-sign";
    }

    if (programGoal.symbol === "POUND") {
      return "pound-sign";
    }

    return "hashtag";
  }, [programGoal]);

  return (
    <StyledGoal>
      <Collapsible
        triggerTagName="div"
        trigger={
          <div className="d-flex">
            <div className="icon">
              <i className={`fas fa-${icon}`} />
            </div>
            <div className="space"></div>
            <div className="name">
              <div
                className="nameText"
                onClick={() => openUpdateGoalModal(programGoal)}
              >
                {programGoal.name}
              </div>
            </div>

            <div className="menu">
              <button className="dropdown-button open" onClick={(e) => {}}>
                <i className="far fa-eye"></i>
              </button>

              <button
                className="dropdown-button delete"
                onClick={(e) => onDelete(programGoal)}
              >
                <i className="far fa-trash-alt"></i>
              </button>
            </div>
          </div>
        }
      >
        <ProgramGoalTargets>
          <ProgramGoalTargetForm
            programGoal={programGoal}
            onCreate={onCreate}
          />

          {programGoal.targets?.length ? (
            <ProgramGoalTargetList
              programGoal={programGoal}
              onDelete={onDeleteTarget}
            />
          ) : (
            <EmptyState />
          )}

          {programGoal.expired_targets &&
            programGoal.expired_targets.length > 0 && (
              <Collapsible
                triggerTagName="div"
                onOpening={() => setIsExpiredOpen(true)}
                onClosing={() => setIsExpiredOpen(false)}
                trigger={
                  <ExpiredTargetsContainer>
                    <div className="line"></div>
                    <div>
                      <p>{isExpiredOpen ? "Hide" : "Show"} expired</p>
                    </div>
                    <div className="line"></div>
                  </ExpiredTargetsContainer>
                }
              >
                <ProgramGoalTargetList
                  programGoal={programGoal}
                  onDelete={onDeleteTarget}
                  expired={true}
                />
              </Collapsible>
            )}
        </ProgramGoalTargets>
      </Collapsible>
    </StyledGoal>
  );
};
