import React, { Component } from "react";
import * as DataTypes from "store/types";

interface IProps {
  insight: DataTypes.Insight;
  saving: boolean;
  onSubmit(insight: DataTypes.Insight): void;
}

class DeleteInsight extends Component<IProps> {
  render() {
    const { onSubmit, saving } = this.props;

    return (
      <React.Fragment>
        <div className="header">
          <h3>REMOVE INSIGHT</h3>
          <p>
            Are you sure you want to remove this insight from this test? This
            insight will remain in the Insights section, but will{" "}
            <strong>no longer be linked</strong> to this test.
          </p>
        </div>
        <div className="cntrl-bar">
          {saving === true ? (
            <button className="btn btn-danger" type="submit" disabled={true}>
              <i className="fas fa-circle-notch fa-spin" />
            </button>
          ) : (
            <button
              className="btn btn-danger btn-no-transform"
              onClick={() => onSubmit(this.props.insight)}
            >
              Yes, remove this INSIGHT.
            </button>
          )}
        </div>
      </React.Fragment>
    );
  }
}

export { DeleteInsight };
