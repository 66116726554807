import React, { Component } from 'react';
import * as DataTypes from 'store/types';
import { ReportTypes } from 'config/app';

interface IProps {
  experiment: DataTypes.Experiment;
  report: DataTypes.Report;
  loading: boolean;
  onUpdate(report: DataTypes.Report): void;
}

class UpdateReportStats extends Component<IProps> {
  constructor(props: IProps) {
    super(props);
    const { report } = this.props;
    this.props.onUpdate(report);
  }

  render() {
    const { report } = this.props;
    return (
      <React.Fragment>
        <div className="header">
          {report.type === ReportTypes.ANALYTICS && <img src="/img/adobe_analytics.png" alt="adobe analytics" />}
          {report.type === ReportTypes.TARGET && <img src="/img/adobe_target.png" alt="adobe target" />}
          <div>
            <h4>Updating Report Data</h4>
            <p>Please wait while we update your report data.</p>
          </div>
        </div>
        <div className="body">
          {this.props.loading === true && (
            <div className="mt-4">
              <div className="progress">
                <div className="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" style={{ width: '100%' }}></div>
              </div>
            </div>
          )}
        </div>
      </React.Fragment>
    );
  }
}

export { UpdateReportStats };
