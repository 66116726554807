import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import * as API from "../../api";
import * as Actions from "./actions";
import { ActionTypes } from "./types";
import * as ExperimentActionTypes from "store/experiment/actions";
import * as AppTypes from "../app/types";

function* updateExperience(action: any) {
  try {
    const { experimentId, experienceId, body } = action.payload;

    // @ts-ignore
    const res = yield call(API.Experiences.update, experimentId, experienceId, {
      experience: body,
    });

    yield put(Actions.updateExperienceSuccess(res.body));
    yield put(ExperimentActionTypes.updateExperimentSuccess(res.body));
    yield put({
      type: AppTypes.App.ALERT_SHOW,
      content: "Experience updated successfully",
    });
  } catch (error: any) {
    yield put(Actions.updateExperienceError(error));
  }
}

function* createExperience(action: any) {
  try {
    const { experimentId, body } = action.payload;

    // @ts-ignore
    const res = yield call(API.Experiences.create, experimentId, {
      experience: body,
    });
    yield put(Actions.createExperienceSuccess(res.body));
    yield put(ExperimentActionTypes.updateExperimentSuccess(res.body));
    yield put({
      type: AppTypes.App.ALERT_SHOW,
      content: "Experience created successfully",
    });
  } catch (error: any) {
    yield put(Actions.createExperienceError(error));
  }
}

function* destroyExperience(action: any) {
  try {
    const { experimentId, experienceId } = action.payload;

    // @ts-ignore
    const res = yield call(API.Experiences.destroy, experimentId, experienceId);
    yield put(Actions.destroyExperienceSuccess(res.body));
    yield put(ExperimentActionTypes.updateExperimentSuccess(res.body));
  } catch (error: any) {
    yield put(Actions.destroyExperienceError(error));
  }
}

function* addImage(action: any) {
  try {
    const { experimentId, experienceId, file } = action.payload;

    // @ts-ignore
    const res = yield call(
      API.Experiences.addImage,
      experimentId,
      experienceId,
      file
    );

    yield put(Actions.addImageSuccess(res.body));
    yield put(ExperimentActionTypes.updateExperimentSuccess(res.body));
    yield put({
      type: AppTypes.App.ALERT_SHOW,
      content: "Image added successfully",
    });
  } catch (error: any) {
    yield put(Actions.addImageError(error));
  }
}

function* deleteImage(action: any) {
  try {
    const { experimentId, experienceId, imageId } = action.payload;

    // @ts-ignore
    const res = yield call(
      API.Experiences.deleteImage,
      experimentId,
      experienceId,
      imageId
    );

    yield put(Actions.deleteImageSuccess(res.body));
    yield put(ExperimentActionTypes.updateExperimentSuccess(res.body));
  } catch (error: any) {
    yield put(Actions.deleteImageError(error));
  }
}

function* updateImage(action: any) {
  try {
    const { experimentId, experienceId, imageId, body } = action.payload;

    // @ts-ignore
    const res = yield call(
      API.Experiences.updateImage,
      experimentId,
      experienceId,
      imageId,
      body
    );

    yield put(Actions.updateImageSuccess(res.body));
    yield put(ExperimentActionTypes.updateExperimentSuccess(res.body));
  } catch (error: any) {
    yield put(Actions.updateImageError(error));
  }
}

function* addImageComment(action: any) {
  try {
    const { experimentId, experienceId, imageId, body } = action.payload;

    // @ts-ignore
    const res = yield call(
      API.Experiences.addImageComment,
      experimentId,
      experienceId,
      imageId,
      body
    );

    yield put(Actions.addImageCommentSuccess(res.body));
    yield put(ExperimentActionTypes.updateExperimentSuccess(res.body));
    yield put({
      type: AppTypes.App.ALERT_SHOW,
      content: "Comment added successfully",
    });
  } catch (error: any) {
    yield put(Actions.addImageCommentError(error));
  }
}

function* deleteImageComment(action: any) {
  try {
    const { experimentId, experienceId, imageId, commentId } = action.payload;

    // @ts-ignore
    const res = yield call(
      API.Experiences.deleteImageComment,
      experimentId,
      experienceId,
      imageId,
      commentId
    );

    yield put(Actions.deleteImageCommentSuccess(res.body));
    yield put(ExperimentActionTypes.updateExperimentSuccess(res.body));
  } catch (error: any) {
    yield put(Actions.deleteImageCommentError(error));
  }
}

function* watchUpdateExperience() {
  yield takeEvery(ActionTypes.UPDATE_EXPERIENCE_REQUEST, updateExperience);
}

function* watchCreateExperience() {
  yield takeEvery(ActionTypes.CREATE_EXPERIENCE_REQUEST, createExperience);
}

function* watchDestroyExperience() {
  yield takeEvery(ActionTypes.DESTROY_EXPERIENCE_REQUEST, destroyExperience);
}

function* watchAddImage() {
  yield takeEvery(ActionTypes.ADD_IMAGE_REQUEST, addImage);
}

function* watchDeleteImage() {
  yield takeEvery(ActionTypes.DELETE_IMAGE_REQUEST, deleteImage);
}

function* watchUpdateImage() {
  yield takeEvery(ActionTypes.UPDATE_IMAGE_REQUEST, updateImage);
}

function* watchAddImageComment() {
  yield takeEvery(ActionTypes.ADD_IMAGE_COMMENT_REQUEST, addImageComment);
}

function* watchDeleteImageComment() {
  yield takeEvery(ActionTypes.DELETE_IMAGE_COMMENT_REQUEST, deleteImageComment);
}

function* experienceSaga() {
  yield all([
    fork(watchUpdateExperience),
    fork(watchCreateExperience),
    fork(watchDestroyExperience),
    fork(watchAddImage),
    fork(watchDeleteImage),
    fork(watchUpdateImage),
    fork(watchAddImageComment),
    fork(watchDeleteImageComment),
  ]);
}

export default experienceSaga;
