import React from 'react';

interface IProps {
  className?: string;
  height?: string;
}

export const ArrowDownIcon = ({ className = '', height = '24' }: IProps) => {
  return <svg className={className} height={height} viewBox="0 0 24 24" width="24">
    <path d="M0 0h24v24H0V0z" fill="none"/>
    <path d="M7.41 8.59L12 13.17l4.59-4.58L18 10l-6 6-6-6 1.41-1.41z"/>
  </svg>
};
