import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Helpers, history } from "utils";
import * as DataTypes from "store/types";
import { DropdownControl, TextInput, ExperimentIcon, ToolTip } from "ui";
import { SubNav } from "ui/navs";

interface IProps {
  experiment: DataTypes.Experiment | null;
  loading: boolean;
  navIndex: number;
  currentUser: any;
  onClone(): void;
  onDelete(): void;
  onViewAttachment(): void;
  onChange(key: string, value: string): void;
}

class MastHead extends Component<IProps> {
  render() {
    const { loading, experiment, onChange } = this.props;

    if (loading) {
      return (
        <div className="MastHead placeholder">
          <ul className="breadCrumbs">
            <li>
              <p></p>
            </li>
          </ul>
          <div className="body">
            <div>
              <h2>&nbsp;</h2>
              <p className="small"></p>
            </div>
            <div className="controls">
              <p></p>
              <p></p>
            </div>
          </div>
          <div className="subNav">
            <ul>
              <li>
                <p></p>
              </li>
              <li>
                <p></p>
              </li>
            </ul>
          </div>
        </div>
      );
    }

    if (experiment === null) return null;

    return (
      <div className="MastHead">
        <ul className="breadCrumbs">
          <li>
            <Link to="/tests">Tests</Link>
          </li>
          <li>{experiment.name}</li>
        </ul>
        <div className="body">
          <section>
            <ExperimentIcon
              experimentType={experiment.type}
              adobe={experiment.adobe?.type}
            />
          </section>
          <section>
            <TextInput
              className="TitleGrow"
              value={experiment.name}
              onChange={(value) => onChange("name", value)}
            >
              <h2>{experiment.name}</h2>
            </TextInput>
            <p className="dates">
              {Helpers.formatDate(experiment.start_date)} -{" "}
              {Helpers.formatDate(experiment.end_date)}
            </p>
          </section>
          <section className="disabled-tooltip">
            {!experiment.case_study && experiment.status === "undecided" && (
              <React.Fragment>
                <ToolTip
                  className="align-left"
                  message="To enable a case study, please provide <br> a win status"
                />
              </React.Fragment>
            )}
          </section>
          <section>
            <div className="controls">
              <div>
                {experiment.case_study && (
                  <button
                    onClick={() => history.push(`/case_study/${experiment.id}`)}
                    type="button"
                    className="btn btn-primary"
                  >
                    view case study
                  </button>
                )}
                {!experiment.case_study &&
                  this.props.currentUser.role !== "read-only" && (
                    <button
                      onClick={() =>
                        history.push(`/case_study/${experiment.id}`)
                      }
                      type="button"
                      className="btn btn-primary"
                      disabled={
                        experiment.status === "undecided" ? true : false
                      }
                    >
                      create case study
                    </button>
                  )}
              </div>
              <div>
                <button
                  className="btn btn-ctrl uploads"
                  onClick={this.props.onViewAttachment}
                >
                  {experiment.uploads && experiment.uploads.length > 0 && (
                    <div className="circle notifications">
                      {experiment.uploads.length}
                    </div>
                  )}
                  <i className="fas fa-paperclip"></i>
                </button>
              </div>
              <div>
                {this.props.currentUser.role === "read-only" ? null : (
                  <DropdownControl
                    controlItems={[
                      {
                        name: "Delete Test",
                        icon: "far fa-trash-alt",
                        onClick: () => this.props.onDelete(),
                        splitAbove: true,
                        className: "danger",
                      },
                    ]}
                  />
                )}
              </div>
            </div>
          </section>
        </div>
        <SubNav
          activeIdx={this.props.navIndex}
          navItems={[
            { name: "details", link: `/tests/${experiment.id}` },
            { name: "reports", link: `/tests/${experiment.id}/results` },
            { name: "insights", link: `/tests/${experiment.id}/insights` },
          ]}
        />
      </div>
    );
  }
}

export { MastHead };
