import { Reducer } from 'redux';
import * as DataTypes from '../types';
import { ActionTypes } from './types';

const initialState: DataTypes.Experiment | null = null;

const reducer: Reducer<DataTypes.Experiment | null> = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.GET_EXPERIMENT_SUCCESS:
    case ActionTypes.GET_CASE_STUDY_SUCCESS:
    case ActionTypes.GET_SHARE_CASE_STUDY_SUCCESS:
    case ActionTypes.UPDATE_CASE_STUDY_SUCCESS:
    case ActionTypes.CREATE_EXPERIMENT_SUCCESS:
    case ActionTypes.UPDATE_EXPERIMENT_SUCCESS:
    case ActionTypes.UPDATE_AB_EXPERIMENT_SUCCESS:
    case ActionTypes.UPDATE_ADOBE_EXPERIMENT_SUCCESS:
    case ActionTypes.DELETE_EXPERIMENT_SUCCESS:
    case ActionTypes.CREATE_REPORT_SUCCESS:
    case ActionTypes.LINK_INSIGHTS_SUCCESS:
    case ActionTypes.UNLINK_INSIGHTS_SUCCESS:
    case ActionTypes.DELETE_EXPERIENCE_SUCCESS:
    case ActionTypes.UPDATE_REPORT_SUCCESS: {
      return action.payload.response;
    }
    case ActionTypes.UPDATE_EXPERIMENT_REQUEST: {
      return {
        ...state,
        ...action.payload.body.experiment,
      };
    }
    case ActionTypes.UPDATE_REPORT_REQUEST: {
      let reports: Array<DataTypes.Report> = [];

      if (state) {
        reports = state.reports;
        const rIdx = reports.findIndex((r) => r.id === action.payload.report.id);
        reports[rIdx] = { ...reports[rIdx] };
      }
      return {
        ...state,
        reports: reports,
      };
    }
    case ActionTypes.UPDATE_REPORT_STATS_SUCCESS: {
      let reports: Array<DataTypes.Report> = [];
      if (state) {
        reports = state.reports;
        const rIdx = reports.findIndex((r) => r.id === action.payload.response.id);
        reports[rIdx] = action.payload.response;
      }
      return {
        ...state,
        reports: reports,
      };
    }
    default: {
      return state;
    }
  }
};

export { reducer as experimentReducer };
