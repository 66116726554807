import React, { ChangeEvent, Component, RefObject } from "react";
import Dropzone, { DropEvent } from "react-dropzone";
import { connect } from "react-redux";
import { createLoadingSelector } from "store/selectors";
import * as DataTypes from "store/types";
import { ApplicationState } from "store/types";

interface IProps {
  experiment: DataTypes.Experiment | null;
  preUploadLoading: boolean;
  onSubmit(name: string, file: any): void;
}

interface IState {
  name: string;
  file: any;
  pristine: boolean;
  error: string | null;
}

class PreUploadCSV extends Component<IProps, IState> {
  downloadTemplateLink: RefObject<any>;

  constructor(props: IProps) {
    super(props);

    this.downloadTemplateLink = React.createRef();

    this.state = {
      name: "",
      file: null,
      pristine: true,
      error: null,
    };
  }

  componentDidMount(): void {
    document.addEventListener("reportUploadError", this.handleError);
  }

  componentWillUnmount(): void {
    document.removeEventListener("reportUploadError", this.handleError);
  }

  handleError = (error: any) => {
    this.setState({ error: error.detail });
  };

  private onFileUpload = (
    acceptedFiles: File[],
    rejectedFiles: File[],
    event: DropEvent
  ) => {
    if (!acceptedFiles || acceptedFiles.length === 0) {
      return;
    }

    this.setState({
      file: acceptedFiles[0],
    });
  };

  private onNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    this.setState({
      name: event.target.value,
    });
  };

  private onSubmitForm = () => {
    const valid = this.state.name && this.state.file;

    if (!valid) {
      this.setState({
        pristine: false,
      });
      return;
    }

    this.props.onSubmit(this.state.name, this.state.file);
  };

  private exportCsvTemplate = () => {
    const experiences = this.props.experiment?.experiences
      .filter((experience) => experience.name.toLowerCase() !== "control")
      .map(
        (experience) =>
          `${experience.name},Lift - ${experience.name},Confidence - ${experience.name}`
      )
      .join(",");

    const rows = [
      [
        "Please delete these instructions before upload so that your file begins with the row of headers.",
      ],
      [
        "Do not change the headers. Doing so will prevent your data from being imported.",
      ],
      [
        "Simply add the values below each header. You can use $ or % if desired.",
      ],
      ["Specify the primary metric by typing true in the Primary column."],
      [""],
      [`Metric,Control,${experiences},Primary`],
    ];

    const csvContent = "data:text/csv;charset=utf-8,";

    const file =
      csvContent +
      encodeURIComponent(rows.map((row) => row.join(",")).join("\r\n"));

    this.downloadTemplateLink.current.href = file;
    this.downloadTemplateLink.current.download = `illuminate - ${this.props.experiment?.name} - report template.csv`;

    this.downloadTemplateLink.current.click();
  };

  render() {
    const { preUploadLoading } = this.props;
    const nameInvalid = !this.state.name && !this.state.pristine;
    const fileInvalid = !this.state.file && !this.state.pristine;

    return (
      <React.Fragment>
        <div className="header">
          <h3>Import from CSV</h3>
        </div>
        <div className="body">
          <div className="panels" style={{ marginTop: "0" }}>
            <div className="left">
              <div className="header">
                <p>
                  Import your data using our CSV template. Follow the format
                  provided to ensure that your data is imported properly.
                </p>
                <p>
                  We've taken care of including your experience names, so you'll
                  only need to provide the data and remove the instructions.
                </p>
                <button
                  className="button button__link"
                  onClick={this.exportCsvTemplate}
                >
                  Download CSV template
                </button>
                <a
                  href="/"
                  ref={this.downloadTemplateLink}
                  style={{ display: "none" }}
                >
                  download
                </a>
              </div>
            </div>

            <div className="right">
              <div className="form-group">
                <label>Report Name *</label>
                <input className="form-control" onChange={this.onNameChange} />
                {nameInvalid && (
                  <div className="error">Please enter a report name.</div>
                )}
              </div>

              <Dropzone onDrop={this.onFileUpload} accept=".csv">
                {({ getRootProps, getInputProps }) => (
                  <div className="">
                    <div
                      {...getRootProps()}
                      className={`file_uploader ${
                        this.state.file ? "active" : ""
                      } ${fileInvalid || this.state.error ? "error" : ""}`}
                    >
                      <div className="item-dropbox">
                        <input {...getInputProps()} />

                        <i className="fas fa-cloud-upload-alt"></i>

                        {fileInvalid ? (
                          <p>Please select a valid file</p>
                        ) : this.state.file?.name ? (
                          <p>{this.state.file.name}</p>
                        ) : (
                          <p>
                            Drag 'n drop your CSV file here, or click to select
                            file
                          </p>
                        )}
                        <p></p>
                      </div>
                    </div>
                  </div>
                )}
              </Dropzone>
            </div>
          </div>
        </div>
        {this.state.error && (
          <ul
            style={{
              paddingLeft: "20px",
              display: "flex",
              flexWrap: "wrap",
              maxWidth: "800px",
            }}
          >
            <li style={{ width: "100%" }}>
              <p style={{ color: "#ff5a71" }}>
                Something went wrong with your upload, see the reasons below:
              </p>
            </li>
            <li>
              <p style={{ color: "#ff5a71" }}>{this.state.error}</p>
            </li>
          </ul>
        )}
        <div className="cntrl-bar">
          {preUploadLoading === true ? (
            <button className="btn btn-primary" type="submit" disabled={true}>
              <i className="fas fa-circle-notch fa-spin" />
            </button>
          ) : (
            <button className="btn btn-primary" onClick={this.onSubmitForm}>
              import CSV data
            </button>
          )}
        </div>
      </React.Fragment>
    );
  }
}

const preUploadSelector = createLoadingSelector([
  "@@experiment/PRE_UPLOAD_REPORT",
]);

const mapStateToProps = ({ app }: ApplicationState) => ({
  preUploadLoading: preUploadSelector(app.requests),
});

const mapDispatchToProps = {};

const connectedPage = connect(
  mapStateToProps,
  mapDispatchToProps
)(PreUploadCSV);

export { connectedPage as PreUploadCSV };
