import { ApiClient } from './client/api_client';

class SignUp {
  static async signup(type: string, email: string, first_name?: string, last_name?: string, google_id?: string) {
    return await ApiClient.post('/v1/sign_up', { user: { kind: type, email, first_name, last_name, google_id } });
  }

  static async getSignupToken(token: string) {
    return await ApiClient.get(`/v1/sign_up/${token}`);
  }
}

export { SignUp };
