import React, { Component } from "react";
import * as DataTypes from "store/types";
import { connect } from "react-redux";
import { addAreaOfFocusRequest } from "store/uxr/actions";
import { AreasOfFocusItem } from "./area_of_focus_item";

interface IProps {
  account: DataTypes.Account;
  uxr: DataTypes.Uxr;
  currentUser: any;
  addAreaOfFocusRequest(uxrId: string, callback: any): void;
}

interface IState {
  selectedAreaId: string | null;
}

class AreasOfFocus extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      selectedAreaId: this.props.uxr.area_of_focus
        ? this.props.uxr.area_of_focus[0]?.id
        : null,
    };
  }

  private isReadOnly = () => {
    return this.props.currentUser.role === "read-only";
  };

  private addArea = () => {
    this.props.addAreaOfFocusRequest(this.props.uxr.id, this.selectArea);
  };

  private selectedArea = () => {
    if (
      !this.state.selectedAreaId &&
      this.props.uxr.area_of_focus.length === 0
    ) {
      return null;
    }

    if (!this.state.selectedAreaId && this.props.uxr.area_of_focus.length > 0) {
      return this.props.uxr.area_of_focus[0];
    }

    return this.props.uxr.area_of_focus.filter(
      (area) => area.id === this.state.selectedAreaId
    )[0];
  };

  private selectArea = (area: any) => {
    if (!area) {
      return;
    }
    /**
     * FIXME: This was made to avoid saving data on the wrong experience.
     * The user would type something on any experience field an click on the link to the next experience
     * The saved data would be included on the seccond visited experience instead of the first.
     *
     * The cause:
     *    The setState function is beeing called before the on change for the child component, this makes the request
     *    to be sent with the wrong id.
     **/
    this.setState({ ...this.state, selectedAreaId: "" });

    setTimeout(() => {
      this.setState({ ...this.state, selectedAreaId: area.id });
    }, 100);
  };

  render() {
    const { uxr } = this.props;
    const { selectedAreaId } = this.state;

    const areasOfFocus = uxr.area_of_focus;

    return (
      <React.Fragment>
        <section className="Experiences">
          <div className="left">
            <h4>Areas of Focus</h4>
            <p>
              Use this section to summarize the themes of your findings and
              support them with bulleted explanations and/or recommendations.
            </p>
            {!this.isReadOnly() ? (
              <button className="btn btn-primary-light" onClick={this.addArea}>
                <i className="fa fa-plus" />
                Add Area of Focus
              </button>
            ) : (
              <div className="mt-4"></div>
            )}

            <ul className="Experiences">
              {areasOfFocus.map((area, idx) => {
                return (
                  <li
                    key={`ex_${area.id}`}
                    className={
                      selectedAreaId && selectedAreaId === area.id
                        ? "active"
                        : ""
                    }
                  >
                    <button
                      type="button"
                      onClick={(e) => this.selectArea(area)}
                    >
                      {area.theme || "New area of focus"}
                    </button>
                  </li>
                );
              })}
            </ul>
          </div>
          <div className="right">
            <div className="grey_box">
              {this.selectedArea() ? (
                <AreasOfFocusItem
                  selectedAreaId={this.state.selectedAreaId}
                  selectArea={this.selectArea}
                  uxr={uxr}
                  area={this.selectedArea()}
                />
              ) : (
                <EmptyState onAdd={this.addArea} />
              )}
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}

const mapStateToProps = ({ router }: DataTypes.ApplicationState) => ({
  router,
});

const mapDispatchToProps = {
  addAreaOfFocusRequest,
};

const connectedPage = connect(
  mapStateToProps,
  mapDispatchToProps
)(AreasOfFocus);

export { connectedPage as AreasOfFocus };

const EmptyState = ({ onAdd }: { onAdd(): void }) => {
  return (
    <div className="Experiments empty-state">
      <div className="wrapper">
        <p>
          Document Areas of Focus (aka themes) resulting from your study. Think
          of this as a way to break down your insights into smaller themes that
          you’ll take action on. Add as many Areas of Focus as you’d like!
        </p>
        <button
          className="btn btn-primary"
          type="button"
          onClick={() => onAdd()}
        >
          Add Area of Focus
        </button>
        <p className="mt-2">
          <i>
            Pro Tip: You’ll attach data points in the “Supporting Data” tab, so
            this section should be more conversational.
          </i>
        </p>
      </div>
    </div>
  );
};
