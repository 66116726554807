import React, { Component } from 'react';
import { FormField, InputTypes } from './';
import { Validator } from './validator';

interface Props {
  field: FormField;
  maxLength?: number;
  className: string;
  placeholder?: string;
  type?: InputTypes | InputTypes.TEXT;
  onChange(e: any): any;
  name?: string;
  value?: string;
  controlled?: boolean
  disabled?: boolean
  autoFocus?: boolean;
  onBlur?: any;
}

interface State {
  field: FormField;
}

class Input extends Component<Props, State> {
  validator: Validator;

  constructor(props: Props) {
    super(props);
    this.validator = new Validator();
    this.state = {
      field: this.props.field
    };
  }

  static getDerivedStateFromProps = (nextProps: Props) => {
    return { field: nextProps.field };
  };

  public validate = () => {
    this.validator.validateField(this.state.field);
    this.setState({ field: this.state.field });

    if (this.props.onBlur) {
      this.props.onBlur(this.state.field);
    }
  };

  public render() {
    const { autoFocus, className, maxLength, type, onChange, placeholder, name, controlled, disabled } = this.props;
    const { value, valid, error } = this.state.field;
    const cn = valid === false ? className + ' error' : className;

    return (
      <>
        <input
          type={type || 'text'}
          className={cn}
          defaultValue={controlled ? undefined : value.toString()}
          onChange={onChange}
          onBlur={this.validate}
          maxLength={maxLength}
          placeholder={placeholder}
          autoFocus={autoFocus}
          autoComplete="none"
          id={name || ''}
          name={name || ''}
          value={controlled ? value : undefined}
          disabled={disabled}
        />
        {valid === false && <div className="error">{error}</div>}
      </>
    )
  }
}

export { Input };
