import React from 'react';
import { PublicLayout, AppLayout } from 'ui/layouts';
import * as DataTypes from 'store/types';
import { history } from 'utils';
import { appConfig } from 'config/app';

export interface AppPageProps {
  currentUser: any;
  account: DataTypes.Account | null;
  navIndex: number;
  fullScreen: boolean;
  location: any;
}

export function AppPage<T extends AppPageProps = AppPageProps>(WrappedComponent: React.ComponentType<T>) {
  const displayName = WrappedComponent.displayName || WrappedComponent.name || 'Private Component';

  return class PageComponent extends React.Component<T, AppPageProps> {
    public static displayName = `App Layout - (${displayName})`;

    componentDidMount = () => {
      // listen for local storage changes to (user,account)
      // if not set, redirect to login page
      window.addEventListener('storage', (e) => {
        if (e.key === appConfig.USER_TOKEN && e.newValue === null) {
          history.push('/login');
        }
      });
    };

    public render() {
      return (
        <AppLayout {...(this.props as T)}>
          <WrappedComponent {...(this.props as T)} />
        </AppLayout>
      );
    }
  };
}

export interface PublicPageProps {}

export function PublicPage<T extends PublicPageProps = PublicPageProps>(WrappedComponent: React.ComponentType<T>) {
  const displayName = WrappedComponent.displayName || WrappedComponent.name || 'Public Component';

  return class PageComponent extends React.Component<T, PublicPageProps> {
    public static displayName = `Pubilic Layout - (${displayName})`;

    public render() {
      return (
        <PublicLayout>
          <WrappedComponent {...(this.props as T)} />
        </PublicLayout>
      );
    }
  };
}
