import { action } from 'typesafe-actions';
import { Dispatch } from 'redux';
import * as ActionTypes from './types';
import * as DataTypes from '../types';

interface RequestError {
  statusCode?: number | undefined;
  message: any;
}

// Fetch Application
export const getApplicationRequestSaga = () =>
  action(ActionTypes.ActionTypes.GET_APPLICATION_REQUEST);
export const getApplicationRequest = () => (dispatch: Dispatch) =>
  dispatch(action(ActionTypes.ActionTypes.GET_APPLICATION_REQUEST));
export const getApplicationSuccess = (response: DataTypes.ApiRequest<DataTypes.Application>) =>
  action(ActionTypes.ActionTypes.GET_APPLICATION_SUCCESS, { response });
export const getApplicationError = (error: RequestError) =>
  action(ActionTypes.ActionTypes.GET_APPLICATION_FAILURE, error);

// Create Application
export const createApplicationRequest = (body: DataTypes.Application) =>
  (dispatch: Dispatch) => dispatch(action(ActionTypes.ActionTypes.CREATE_APPLICATION_REQUEST, { body }));
export const createApplicationSuccess = () =>
  action(ActionTypes.ActionTypes.CREATE_APPLICATION_SUCCESS);
export const createApplicationError = (error: RequestError) =>
  action(ActionTypes.ActionTypes.CREATE_APPLICATION_FAILURE, error);


// Delete Application
export const deleteApplicationRequest = (id: string) =>
  (dispatch: Dispatch) => dispatch(action(ActionTypes.ActionTypes.DELETE_APPLICATION_REQUEST, { id }));
export const deleteApplicationSuccess = () =>
  action(ActionTypes.ActionTypes.DELETE_APPLICATION_SUCCESS);
export const deleteApplicationError = (error: RequestError) =>
  action(ActionTypes.ActionTypes.DELETE_APPLICATION_FAILURE, error);