import { Reducer } from 'redux';
import { ActionTypes } from './types';

const initialState: any = {
  list: null,
  settigngs: null,
};

const reducer: Reducer<any> = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.LIST_SLACK_SUCCESS: {
      return { ...state, list: action.payload.response };
    }
    case ActionTypes.GET_SLACK_SETTINGS_SUCCESS: {
      return { ...state, settings: action.payload.response };
    }
    default: {
      return state;
    }
  }
};

export { reducer as slackReducer };
