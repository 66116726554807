export enum ActionTypes {
  INIT_IDEA_CREATE_REQUEST = '@@idea/INIT_IDEA_CREATE_REQUEST',
  INIT_IDEA_CREATE_SUCCESS = '@@idea/INIT_IDEA_CREATE_SUCCESS',
  INIT_IDEA_CREATE_FAILURE = '@@idea/INIT_IDEA_CREATE_FAILURE',

  CREATE_IDEA_REQUEST = '@@idea/CREATE_IDEA_REQUEST',
  CREATE_IDEA_SUCCESS = '@@idea/CREATE_IDEA_SUCCESS',
  CREATE_IDEA_FAILURE = '@@idea/CREATE_IDEA_FAILURE',

  UPDATE_IDEA_REQUEST = '@@idea/UPDATE_IDEA_REQUEST',
  UPDATE_IDEA_SUCCESS = '@@idea/UPDATE_IDEA_SUCCESS',
  UPDATE_IDEA_FAILURE = '@@idea/UPDATE_IDEA_FAILURE',

  DELETE_IDEA_REQUEST = '@@idea/DELETE_IDEA_REQUEST',
  DELETE_IDEA_SUCCESS = '@@idea/DELETE_IDEA_SUCCESS',
  DELETE_IDEA_FAILURE = '@@idea/DELETE_IDEA_FAILURE',

  GET_IDEAS_REQUEST = '@@idea/GET_IDEAS_REQUEST',
  GET_IDEAS_SUCCESS = '@@idea/GET_IDEAS_SUCCESS',
  GET_IDEAS_FAILURE = '@@idea/GET_IDEAS_FAILURE',

  SHOW_IDEA_REQUEST = '@@idea/SHOW_IDEA_REQUEST',
  SHOW_IDEA_SUCCESS = '@@idea/SHOW_IDEA_SUCCESS',
  SHOW_IDEA_FAILURE = '@@idea/SHOW_IDEA_ERROR',

  CREATE_SHARE_IDEA_REQUEST = '@@idea/CREATE_SHARE_IDEA_REQUEST',
  CREATE_SHARE_IDEA_SUCCESS = '@@idea/CREATE_SHARE_IDEA_SUCCESS',
  CREATE_SHARE_IDEA_FAILURE = '@@idea/CREATE_SHARE_IDEA_FAILURE',

  ARCHIVE_IDEA_REQUEST = '@@idea/ARCHIVE_IDEA_REQUEST',
  ARCHIVE_IDEA_SUCCESS = '@@idea/ARCHIVE_IDEA_SUCCESS',
  ARCHIVE_IDEA_FAILURE = '@@idea/ARCHIVE_IDEA_FAILURE',

  CLEAR_IDEA_REQUEST = '@@idea/CLEAR_IDEA_REQUEST',
  CLEAR_IDEA_SUCCESS = '@@idea/CLEAR_IDEA_SUCCESS',
  CLEAR_IDEA_FAILURE = '@@idea/CLEAR_IDEA_FAILURE',

  REORDER_REQUEST = '@@idea/REORDER_REQUEST',
  REORDER_SUCCESS = '@@idea/REORDER_SUCCESS',
  REORDER_FAILURE = '@@idea/REORDER_FAILURE',

  UPLOAD_IDEAS_REQUEST = "@@idea/UPLOAD_IDEAS_REQUEST",
  UPLOAD_IDEAS_SUCCESS = "@@idea/UPLOAD_IDEAS_SUCCESS",
  UPLOAD_IDEAS_FAILURE = "@@idea/UPLOAD_IDEAS_FAILURE",

  EXPORT_IDEAS_REQUEST = "@@idea/EXPORT_IDEAS_REQUEST",
  EXPORT_IDEAS_SUCCESS = "@@idea/EXPORT_IDEAS_SUCCESS",
  EXPORT_IDEAS_FAILURE = "@@idea/EXPORT_IDEAS_FAILURE"
}
