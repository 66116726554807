import React, { Component } from "react";
import { connect } from "react-redux";
import { Form, Input, Button, Checkbox, ValidatorTypes } from "comps/form";
import { PublicPage } from "comps/pages";
import * as DataTypes from "store/types";
import { createInvitedUserRequest } from "store/user/actions";
import {
  createLoadingSelector,
  createErrorMessageSelector,
} from "store/selectors";
import { AccountLoader } from "ui/loaders";
import { Spacer } from "comps/layout";
import { User, SignUp } from "api";
import { history } from "utils/history";
import { AuthService } from "utils/auth";

interface IProps {
  createInvitedUserRequest: (id: string, body: any) => void;
  waitingOnInvitedUser: boolean;
  createAccountError: string;
  computedMatch: { params: any };
  loading: boolean;
  user: any;
}

interface IState {
  error?: string;
  email?: string;
  role?: string;
  loading: boolean;
  first_name?: string;
  last_name?: string;
  google_id?: string;
  invalidEmail: boolean;
  expected?: string;
  actual?: string;
}

class InvitedGoogleUserForm extends Component<IProps, IState> {
  state: IState = { loading: true, invalidEmail: false };

  async componentDidMount() {
    const { user, invite } = this.props.computedMatch.params;
    try {
      const responses = await Promise.all([
        SignUp.getSignupToken(user),
        User.getInvitedUserToken(invite),
      ]);
      const [userResponse, inviteResponse] = responses;
      const { role } = inviteResponse.body;
      const { first_name, last_name, email, google_id } = userResponse.body;
      if (userResponse.body.email !== inviteResponse.body.email)
        return this.setState({
          invalidEmail: true,
          expected: inviteResponse.body.email,
          actual: userResponse.body.email,
          google_id,
          role,
        });

      if (userResponse.body.email)
        return this.setState(
          { email, first_name, last_name, google_id, loading: false, role },
          () => {
            AuthService.logOut();
          }
        );
    } catch (error: any) {
      this.setState({ error, loading: false });
    }
  }

  private onSubmit = (valid: boolean, dirty: boolean, data: any) => {
    if (valid && dirty) {
      const values: any = {};
      Object.keys(data).map(
        (field: string) => (values[field] = data[field].value)
      );
      const { invite } = this.props.computedMatch.params;
      const { firstName, lastName, newsletter, email } = values;
      const transform: any = {
        email,
        first_name: firstName,
        last_name: lastName,
        type: "google",
        google_id: this.state.google_id,
        role: this.state.role,
        notifications: {
          newsletter,
        },
      };
      this.props.createInvitedUserRequest(invite, transform);
    }
  };

  termsOfService = () => (
    <>
      <>I agree to and accept the </>
      <a href="/terms" target="_blank">
        Terms of Service
      </a>{" "}
      and{" "}
      <a href="/privacy_policy" target="_blank">
        Privacy Policy
      </a>{" "}
      for illuminate.
    </>
  );

  render() {
    if (this.state.error) throw this.state.error;

    if (this.props.user && this.props.user.jwt) history.push("/");

    return (
      <AccountLoader
        loaded={!!this.state.google_id}
        message="Please wait while we verify your signup link"
      >
        <React.Fragment>
          <div className="content">
            <div className="logo">
              <img src="/img/logo.png" alt="illuminate" />
            </div>
            <div className="form">
              {this.state.invalidEmail ? (
                <React.Fragment>
                  <h1>Invalid Login</h1>
                  <div className="error">
                    The email address you used to log in is invalid. Please sign
                    up using the invited email:{" "}
                    <strong>{this.state.expected}</strong>
                  </div>
                  <button
                    className="btn btn-primary mt-2"
                    onClick={() =>
                      history.push(
                        `/invite/${this.props.computedMatch.params.invite}`
                      )
                    }
                  >
                    Try Again
                  </button>
                </React.Fragment>
              ) : (
                <React.Fragment>
                  <h1>Welcome to illuminate</h1>
                  <p>Almost done!</p>
                  <Spacer size={2} />
                  <Form
                    formFields={{
                      email: {
                        name: "email",
                        value: this.state.email,
                        validator: {
                          type: ValidatorTypes.REQUIRED,
                          messages: {
                            required: "Please enter your first name",
                          },
                        },
                      },
                      firstName: {
                        name: "firstName",
                        value: this.state.first_name,
                        validator: {
                          type: ValidatorTypes.REQUIRED,
                          messages: {
                            required: "Please enter your first name",
                          },
                        },
                      },
                      lastName: {
                        name: "lastName",
                        value: this.state.last_name,
                        validator: {
                          type: ValidatorTypes.REQUIRED,
                          messages: { required: "Please enter your last name" },
                        },
                      },
                      tos: {
                        name: "tos",
                        value: false,
                        validator: {
                          type: ValidatorTypes.CHECKBOX_REQUIRED,
                          messages: {
                            required:
                              "You must agree to and accept the illuminate Terms of Service and Privacy Policy",
                          },
                        },
                      },
                      newsletter: {
                        name: "newsletter",
                        value: false,
                      },
                    }}
                    onSubmit={this.onSubmit}
                    FormComponent={({
                      fields: { email, firstName, lastName, newsletter, tos },
                      onChange,
                    }) => (
                      <div>
                        <div className="form-group row">
                          <div className="col-md-12">
                            <label>Email</label>
                            <Input
                              field={email}
                              disabled
                              className="form-control"
                              onChange={(e) => onChange(email, e.target.value)}
                            />
                          </div>
                        </div>
                        <div className="form-group row">
                          <div className="col-md-6">
                            <label>First Name</label>
                            <Input
                              field={firstName}
                              disabled
                              className="form-control"
                              onChange={(e) =>
                                onChange(firstName, e.target.value)
                              }
                            />
                          </div>
                          <div className="col-md-6">
                            <label>Last Name</label>
                            <Input
                              field={lastName}
                              disabled
                              className="form-control"
                              onChange={(e) =>
                                onChange(lastName, e.target.value)
                              }
                            />
                          </div>
                        </div>
                        <div className="form-group">
                          <Checkbox
                            TextComponent={this.termsOfService}
                            field={tos}
                            className="form-control"
                            onChange={(e) => onChange(tos, e.target.checked)}
                          />
                        </div>
                        <div className="form-group">
                          <Checkbox
                            text="Yes, send me information about Brooks Bell &amp; illuminate"
                            field={newsletter}
                            className="form-control"
                            onChange={(e) =>
                              onChange(newsletter, e.target.checked)
                            }
                          />
                        </div>
                        <Button
                          className="btn btn-primary"
                          text="continue"
                          isLoading={false}
                        />
                        <Spacer size={4} />
                      </div>
                    )}
                  />
                </React.Fragment>
              )}
            </div>
          </div>
          <div className="aside"></div>
        </React.Fragment>
      </AccountLoader>
    );
  }
}

const loadingSelector = createLoadingSelector([
  "@@user/CREATE_INVITED_USER",
  "@@app/INIT_APP",
  "@@account/GET_ACCOUNT",
]);
const errorSelector = createErrorMessageSelector([
  "@@user/CREATE_INVITED_USER",
]);

const mapStateToProps = ({ app, user }: DataTypes.ApplicationState) => ({
  waitingOnInvitedUser: loadingSelector(app.requests),
  createInvitedUserError: errorSelector(app.errors),
  user,
});

const mapDispatchToProps = {
  createInvitedUserRequest,
};

const connectedPage = connect(
  mapStateToProps,
  mapDispatchToProps
)(PublicPage(InvitedGoogleUserForm));

export { connectedPage as InvitedGoogleUserForm };
