import { ApiClient } from "./client/api_client";

class InsightProposals {
  static async create(unselected: string[]) {
    return await ApiClient.post("/v1/insight/proposals", { unselected });
  }

  static async index() {
    return await ApiClient.get("/v1/insight/proposals");
  }
}

export { InsightProposals };
