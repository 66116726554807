import React, { Component } from "react";
import { connect } from "react-redux";
import { ApplicationState } from "store/types";
import { AppPage, AppPageProps } from "comps/pages";
import queryString from "query-string";

import {
  getAccountAppsRequest,
  createAppRequest,
  updateAppRequest,
} from "store/account/actions";
import { getApplicationRequest } from "store/application/actions";
import { authSlackRequest } from "store/slack/actions";
import * as DataTypes from "store/types";
import { createLoadingSelector } from "store/selectors";
import { clearAppErrors, showModal } from "store/app/actions";
import { AdobeModal } from "../../settings/modals";
import { ApiKeyModal } from "../../settings/modals/api_key";
import { SlackModal } from "../../settings/modals/slack";
import { listSlackRequest, getSlackSettingsRequest } from "store/slack/actions";

export enum AppTypes {
  ADOBE = "App::Adobe",
  OPTIMIZELY = "optimizely",
  API_KEY = "apikey",
  SLACK = "App::Slack",
}

interface IProps extends AppPageProps {
  loading: boolean;
  creating: boolean;
  updating: boolean;
  slack: any;
  clearAppErrors: () => void;
  getAccountAppsRequest: () => void;
  createAppRequest: (body: any) => void;
  getApplicationRequest: () => void;
  updateAppRequest: (id: string, body: any) => void;
  showModal: (component: React.ComponentType<any>, options: any) => void;
  authSlackRequest: (code: string, kind: string) => void;
  listSlackRequest: () => void;
  getSlackSettingsRequest: () => void;
  account: DataTypes.Account | null;
  applications: DataTypes.ApiList<DataTypes.Application> | null;
}

class Integrations extends Component<IProps> {
  constructor(props: IProps) {
    super(props);

    this.props.getAccountAppsRequest();
    this.props.getApplicationRequest();
  }

  private setSlack = () => {
    const query = queryString.parse(this.props.location.search, {
      arrayFormat: "bracket",
    });
    const { slackCode, kind } = query;

    if (!slackCode || !kind) {
      return;
    }

    this.props.authSlackRequest(slackCode, kind);
  };

  componentDidMount() {
    this.setSlack();
    this.props.listSlackRequest();
    this.props.getSlackSettingsRequest();
  }

  private showAppSetup = (type: string, app: any) => {
    if (type === AppTypes.ADOBE) {
      this.props.showModal(AdobeModal, {
        app: app,
        onCreate: this.createApp,
        onUpdate: this.updateApp,
        saving: this.props.updating,
      });
      return;
    }

    if (type === AppTypes.API_KEY) {
      this.props.showModal(ApiKeyModal, {});
    }

    if (type === AppTypes.SLACK) {
      this.props.showModal(SlackModal, { app: app });
    }
  };

  private createApp = (body: any) => {
    this.props.createAppRequest(body);
  };

  private updateApp = (app: DataTypes.App, body: any) => {
    if (app && app.id) {
      this.props.updateAppRequest(app.id, body);
    }
  };

  render() {
    const { account, loading, applications } = this.props;
    if (loading) return null;

    const adobeApp =
      account &&
      account.apps &&
      account.apps.find((app) => app.type === "adobe");
    const slackApp = this.props.slack?.list && this.props.slack.list.length > 0;

    return (
      <div className="Settings">
        <div className="container">
          <div className="header">
            <h4>Integrations</h4>
            <p>Use this section to manage integrations.</p>

            <div className="Integrations">
              <div
                className={
                  adobeApp && adobeApp.enabled
                    ? "box adobe enabled"
                    : "box adobe"
                }
                onClick={() => this.showAppSetup(AppTypes.ADOBE, adobeApp)}
              >
                <img src="/img/apps/adobe.png" alt="adobe" />
                <h2 className="title">Adobe</h2>
              </div>

              <div
                className={`box adobe ${slackApp ? "enabled" : ""}`}
                onClick={() => this.showAppSetup(AppTypes.SLACK, {})}
              >
                <img src="/img/apps/Slack_RGB.svg" alt="Slack" />
                <h2 className="title">Slack</h2>
              </div>

              <div
                className={`box adobe ${
                  applications &&
                  applications.data &&
                  applications.data.length > 0
                    ? "enabled"
                    : ""
                }`}
                onClick={() => this.showAppSetup(AppTypes.API_KEY, {})}
              >
                <img src="/img/apps/illuminate.png" alt="illuminate API" />
                <h2 className="title">illuminate API</h2>
              </div>
            </div>
          </div>
          <section></section>
        </div>
      </div>
    );
  }
}

const createLoaderelector = createLoadingSelector(["@@account/CREATE_APP"]);
const updateLoaderSelected = createLoadingSelector(["@@account/UPDATE_APP"]);
const loadingSelector = createLoadingSelector([
  "@@account/GET_ACCOUNT_APPS",
  "@@@application/GET_APPLICATION",
]);

const mapStateToProps = ({
  app,
  account,
  application,
  slack,
}: ApplicationState) => ({
  loading: loadingSelector(app.requests),
  creating: createLoaderelector(app.requests),
  updating: updateLoaderSelected(app.requests),
  applications: application,
  account,
  slack,
});

const mapDispatchToProps = {
  clearAppErrors,
  showModal,
  getAccountAppsRequest,
  createAppRequest,
  updateAppRequest,
  getApplicationRequest,
  authSlackRequest,
  listSlackRequest,
  getSlackSettingsRequest,
};

const connectedPage = connect(
  mapStateToProps,
  mapDispatchToProps
)(AppPage(Integrations));

export { connectedPage as Integrations };
