import React, { Component } from 'react';
import { FormField } from './';
import { Validator } from './validator';

interface Props {
  field: FormField;
  maxLength?: number;
  className: string;
  placeholder?: string;
  onChange(e: any): any;
}

interface State {
  field: FormField;
}

class TextArea extends Component<Props, State> {
  validator: Validator;

  constructor(props: Props) {
    super(props);
    this.validator = new Validator();
    this.state = {
      field: this.props.field
    };
  }

  static getDerivedStateFromProps = (nextProps: Props) => {
    return { field: nextProps.field };
  };

  public validate = () => {
    this.validator.validateField(this.state.field);
    this.setState({ field: this.state.field });
  };

  public render() {
    const { className, maxLength, onChange, placeholder } = this.props;
    const { name, value, valid, error } = this.state.field;
    const cn = valid === false ? className + ' error' : className;
    return (
      <div>
        <textarea
          className={cn}
          name={name}
          defaultValue={value.toString()}
          onChange={onChange}
          onBlur={this.validate}
          maxLength={maxLength}
          placeholder={placeholder}
          autoComplete="none"
        />
        {valid === false && <div className="error">{error}</div>}
      </div>
    );
  }
}

export { TextArea };
