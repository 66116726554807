import _ from 'lodash';

export const createLoadingSelector = (actions: Array<string>) => (state: any) => {
  const selector = _(actions)
    .map(action => _.get(state, action))
    .compact()
    .first();
  return (selector && selector.loading) || false;
};

export const createErrorMessageSelector = (actions: Array<string>) => (state: any) => {
  return (
    _(actions)
      .map(action => _.get(state, action))
      .compact()
      .first() || ''
  );
};
