import React, { Component } from "react";
import { connect } from "react-redux";
import { history, Helpers } from "utils";
import { Link } from "react-router-dom";
import { ApplicationState } from "store/types";
import * as DataTypes from "store/types";
import { showModal } from "store/app/actions";
import { createLoadingSelector } from "store/selectors";
import {
  getCaseStudyRequest,
  updateCaseStudyRequest,
} from "store/experiment/actions";
import { AppPage, AppPageProps } from "comps/pages";
import { ExperimentStatusIcon, DropdownControl, SelectInput } from "ui";
import { AnnotateImage } from "comps";
import * as Modals from "./comps/modals";
import { Report } from "./comps/report";
import "css/CaseStudy.scss";
import { EditorInput } from "ui/editor_input/editor_input";
import { CustomDataViz } from "./comps/custom_data_viz";
import { ProgramGoalsList } from "./comps/program_goal";

enum PageActions {
  NONE = "none",
  SELECT_EXPERIENCES = "select_experiences",
  SELECT_INSIGHTS = "select_insights",
  TOGGLE_RESULTS = "toggle_results",
  SHARE = "share",
}

interface IProps extends AppPageProps {
  router: any;
  computedMatch: any;
  loading: boolean;
  getCaseStudyRequest: (id: string) => void;
  updateCaseStudyRequest: (
    id: string,
    body: any,
    disableHideModal?: boolean
  ) => void;
  showModal: (component: React.ComponentType<any>, options: any) => void;
  experiment: DataTypes.Experiment | null;
  account: DataTypes.Account | null;
  updating: boolean;
}

interface IState {
  activeReport?: DataTypes.Report;
  loadingReport?: boolean;
  selectedImages: { [k: string]: number };
}

class CaseStudy extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      loadingReport: false,
      selectedImages: {},
    };
  }

  componentDidMount = () => {
    this.props.getCaseStudyRequest(this.props.computedMatch.params.id);
  };

  private close = () => {
    history.push(`/tests/${this.props.experiment?.id}`);
  };

  private setPageAction = (action: PageActions) => {
    const { experiment, account } = this.props;
    // Show Select Experiences
    if (action === PageActions.SELECT_EXPERIENCES) {
      if (experiment) {
        this.props.showModal(Modals.SelectExperiences, {
          className: "SelectExperiences",
          experiment: experiment,
          onSubmit: this.updateExperiences,
        });
      }
    }

    // Show Select Insights
    if (action === PageActions.SELECT_INSIGHTS) {
      if (experiment) {
        this.props.showModal(Modals.SelectInsights, {
          className: "SelectInsights",
          experiment: experiment,
          onSubmit: this.updateInsights,
        });
      }
    }

    // Show Report Config
    if (action === PageActions.TOGGLE_RESULTS) {
      if (experiment) {
        this.props.showModal(Modals.SelectReports, {
          className: "SelectReports",
          experiment: experiment,
          onSubmit: this.updateReports,
        });
      }
    }

    // Share Case Study Config
    if (action === PageActions.SHARE) {
      if (experiment && account) {
        this.props.showModal(Modals.Share, {
          className: "Share",
          experiment: experiment,
          account: account,
          onSubmit: this.updateShare,
        });
      }
    }
  };

  private updateInsights = (selectedInsights: Array<string>) => {
    const { experiment } = this.props;
    if (experiment && experiment?.case_study) {
      this.props.updateCaseStudyRequest(this.props.computedMatch.params.id, {
        case_study: {
          ...experiment.case_study,
          insights: selectedInsights,
        },
      });
    }
  };

  private updateExperiences = (selectedExperiences: Array<string>) => {
    const { experiment } = this.props;

    if (experiment && experiment?.case_study) {
      this.props.updateCaseStudyRequest(this.props.computedMatch.params.id, {
        case_study: {
          ...experiment.case_study,
          experiences: selectedExperiences,
        },
      });
    }
  };

  private updateReports = (
    selectedReports: string[],
    selectedDataVizs: string[]
  ) => {
    const { experiment } = this.props;

    if (experiment && experiment?.case_study) {
      this.props.updateCaseStudyRequest(this.props.computedMatch.params.id, {
        case_study: {
          ...experiment.case_study,
          reports: selectedReports,
          data_viz: selectedDataVizs,
        },
      });
    }
  };

  private updateShare = (password_protected: boolean, password?: string) => {
    const { experiment } = this.props;

    if (!experiment) {
      return;
    }

    if (password_protected) {
      this.props.updateCaseStudyRequest(this.props.computedMatch.params.id, {
        case_study: {
          ...experiment.case_study,
          password_protected: password_protected,
          password: password,
        },
      });
      return;
    }

    this.props.updateCaseStudyRequest(
      this.props.computedMatch.params.id,
      {
        case_study: {
          ...experiment.case_study,
          password_protected: password_protected,
        },
      },
      true
    );
  };

  private changeReport = (value: any) => {
    this.setState({ loadingReport: true }, () => {
      this.setState({
        ...this.state,
        activeReport: value,
        loadingReport: false,
      });
    });
  };

  render() {
    const { loading, experiment, account, updating } = this.props;

    if (loading || updating) return null;
    if (!experiment) return null;
    if (!experiment.case_study)
      return <div>Please wait while we create your case study</div>;

    const selectedInsights = experiment.case_study.insights || [];
    const selectedExperiences = experiment.case_study.experiences || [];
    const selectedReports = [
      ...(experiment.case_study.reports || []),
      ...(experiment.case_study.data_vizs || []),
    ];

    const controlExperience = experiment.experiences.filter(
      (exp) => exp.type === "Experiment::Experience::Control"
    )[0];

    const overall_winner = experiment.experiences.find(
      (ex) => ex.overall_winner === true
    );

    let activeReport = this.state?.activeReport;

    const availableReports = selectedReports.map(
      (sr, idx) =>
        experiment.reports.find((r) => r.id === sr) ||
        experiment.custom_data_viz.find((r: any) => (r.id = sr))
    );

    if (!activeReport && selectedReports.length > 0) {
      activeReport = availableReports.find((report) => report?.primary);

      selectedReports.forEach((sr, idx) => {
        if (!activeReport) {
          activeReport = experiment.reports.find(
            (r) => r.id === selectedReports[idx]
          );
        }
      });
    }

    const isActiveReportDataViz =
      experiment.custom_data_viz
        .map((dv: any) => dv.id)
        .lastIndexOf(activeReport?.id) !== -1;

    // set control items
    let controlItems = [
      {
        name: "Experiences",
        icon: "fas fa-flask",
        onClick: () => this.setPageAction(PageActions.SELECT_EXPERIENCES),
      },
    ];

    if (experiment.insights.length > 0) {
      controlItems.push({
        name: "Insights",
        icon: "fas fa-bolt",
        onClick: () => this.setPageAction(PageActions.SELECT_INSIGHTS),
      });
    }
    if (experiment.reports.length > 0) {
      controlItems.push({
        name: "Reports",
        icon: "fas fa-chart-line",
        onClick: () => this.setPageAction(PageActions.TOGGLE_RESULTS),
      });
    }

    return (
      <div className="CaseStudy">
        <div className="header">
          <div className="left">
            <img src="/img/logo_sm.svg" alt="illuminate" className="center" />
          </div>
          {this.props.currentUser?.role === "read-only" ? null : (
            <div className="right">
              <DropdownControl
                component={<i className="fas fa-cog" />}
                controlItems={controlItems}
              />

              <button
                title="share"
                type="button"
                className="btn btn-ctrl"
                onClick={() => this.setPageAction(PageActions.SHARE)}
              >
                <i className="fas fa-share-alt"></i>
              </button>

              <button
                title="close"
                type="button"
                className="btn btn-ctrl"
                onClick={this.close}
              >
                <i className="fas fa-times" />
              </button>
            </div>
          )}
        </div>
        <div className="wrapper">
          <section>
            <div className="content Details">
              <div className="left">
                <ExperimentStatusIcon
                  status={experiment.status}
                  device="desktop"
                />
                <h1>{experiment.name}</h1>
                <p>
                  <small>
                    {Helpers.formatDate(experiment.start_date)} -{" "}
                    {Helpers.formatDate(experiment.end_date)}
                  </small>
                </p>
                <ExperimentStatusIcon
                  status={experiment.status}
                  device="mobile"
                />
              </div>
              <div className="right">
                <div className="targeting">
                  <div className="target">
                    <label>pages</label>
                    {experiment.pages.map((t, idx) => {
                      return <p key={["page_", idx].join("_")}>{t}</p>;
                    })}
                  </div>
                  <div className="target">
                    <label>audiences</label>
                    {experiment.audiences.map((t, idx) => {
                      return <p key={["aud_", idx].join("_")}>{t}</p>;
                    })}
                  </div>
                  <div className="target">
                    <label>devices</label>
                    {experiment.devices.map((t, idx) => {
                      return <p key={["aud_", idx].join("_")}>{t}</p>;
                    })}
                  </div>
                </div>
                <div className="targeting">
                  {account?.settings.teams &&
                    experiment.teams &&
                    experiment.teams.length > 0 && (
                      <div className="target">
                        <label>Teams</label>
                        {experiment.teams.map((t, idx) => {
                          return <p key={["aud_", idx].join("_")}>{t}</p>;
                        })}
                      </div>
                    )}
                  {account?.settings.brands &&
                    experiment.brands &&
                    experiment.brands.length > 0 && (
                      <div className="target">
                        <label>Brands</label>
                        {experiment.brands.map((t, idx) => {
                          return <p key={["aud_", idx].join("_")}>{t}</p>;
                        })}
                      </div>
                    )}
                  {account?.settings.channels &&
                    experiment.channels &&
                    experiment.channels.length > 0 && (
                      <div className="target">
                        <label>Channels</label>
                        {experiment.channels.map((t, idx) => {
                          return <p key={["aud_", idx].join("_")}>{t}</p>;
                        })}
                      </div>
                    )}
                </div>
              </div>
            </div>
          </section>

          <section>
            <div
              className="content Results"
              style={{
                flexDirection: experiment.status === "flat" ? "column" : "row",
              }}
            >
              {experiment.status === "flat" ||
              experiment.status === "data_not_available" ? (
                <React.Fragment>
                  <div style={{ paddingRight: "50px", width: "100%" }}>
                    <EditorInput
                      content={experiment.description}
                      readOnly={true}
                      onBlur={() => {}}
                    />
                  </div>
                  <ProgramGoalsList
                    className="right"
                    experiment={experiment}
                    style={{ width: "100%", paddingTop: "10px" }}
                  />
                </React.Fragment>
              ) : (
                <React.Fragment>
                  <div className="left">
                    <label>Summary</label>

                    <EditorInput
                      content={experiment.description}
                      readOnly={true}
                      onBlur={() => {}}
                    />
                  </div>
                  <div className="right">
                    <ProgramGoalsList experiment={experiment} />

                    {overall_winner && overall_winner.images.length > 0 && (
                      <React.Fragment>
                        <div>
                          <Link
                            to={`/tests/${experiment.id}/experiences/${overall_winner.id}/images/${overall_winner.images[0].id}?backTo=case_study`}
                          >
                            <AnnotateImage
                              image={
                                this.state.selectedImages["winner"]
                                  ? overall_winner.images[
                                      this.state.selectedImages["winner"]
                                    ]
                                  : overall_winner.images[0]
                              }
                              disabled={true}
                              annotation={{}}
                            />
                          </Link>
                          {overall_winner.images.length > 1 && (
                            <ul className="ImageIndicator">
                              {overall_winner.images.map((img, index) => {
                                return (
                                  <li
                                    style={{
                                      cursor: "pointer",
                                      backgroundColor:
                                        (!this.state.selectedImages["winner"] &&
                                          index === 0) ||
                                        this.state.selectedImages["winner"] ===
                                          index
                                          ? "#0056b3"
                                          : "#eaeaea",
                                    }}
                                    onClick={() => {
                                      this.setState({
                                        selectedImages: {
                                          ...this.state.selectedImages,
                                          winner: index,
                                        },
                                      });
                                    }}
                                    key={["ow_img_", img.id].join("_")}
                                  ></li>
                                );
                              })}
                            </ul>
                          )}
                        </div>
                      </React.Fragment>
                    )}
                  </div>
                </React.Fragment>
              )}
            </div>
          </section>

          {selectedInsights.length > 0 &&
            experiment.insights &&
            experiment.insights.length > 0 && (
              <section className="bg-blue">
                <div className="content Insights">
                  <h2>Key Insights</h2>
                  <ul>
                    {experiment.insights.map((insight) => {
                      if (!selectedInsights.includes(insight.id)) {
                        return null;
                      }

                      return (
                        <li key={["insight_", insight.id].join("_")}>
                          <div className="insight">
                            <p>{insight.value}</p>
                          </div>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              </section>
            )}

          {selectedExperiences.length > 0 && (
            <section>
              <div className="content Experiences">
                <h2>Deep Dive</h2>
                {experiment &&
                  experiment.experiences.map((experience, idx) => {
                    if (!selectedExperiences.includes(experience.id)) {
                      return null;
                    }

                    return (
                      <React.Fragment>
                        <div
                          key={["exp_", idx].join("_")}
                          className="experience"
                        >
                          {experience.images && experience.images.length > 0 ? (
                            <div>
                              <Link
                                to={`/tests/${experiment.id}/experiences/${experience.id}/images/${experience.images[0].id}?backTo=case_study`}
                              >
                                <AnnotateImage
                                  image={
                                    this.state.selectedImages[experience.id]
                                      ? experience.images[
                                          this.state.selectedImages[
                                            experience.id
                                          ]
                                        ]
                                      : experience.images[0]
                                  }
                                  disabled={true}
                                  annotation={{}}
                                />
                              </Link>

                              {experience.images.length > 1 && (
                                <ul className="ImageIndicator">
                                  {experience.images.map((img, index) => {
                                    return (
                                      <li
                                        style={{
                                          cursor: "pointer",
                                          backgroundColor:
                                            (!this.state.selectedImages[
                                              experience.id
                                            ] &&
                                              index === 0) ||
                                            this.state.selectedImages[
                                              experience.id
                                            ] === index
                                              ? "#0056b3"
                                              : "#eaeaea",
                                        }}
                                        onClick={() => {
                                          this.setState({
                                            selectedImages: {
                                              ...this.state.selectedImages,
                                              [experience.id]: index,
                                            },
                                          });
                                        }}
                                        key={["exp_img_", img.id].join("_")}
                                      ></li>
                                    );
                                  })}
                                </ul>
                              )}
                            </div>
                          ) : (
                            <div style={{ width: "50%" }}></div>
                          )}

                          <div className="content">
                            {experience.type ===
                            "Experiment::Experience::Control" ? (
                              <React.Fragment>
                                <div className="head">
                                  <div className="hexagon control">
                                    <label>ctl</label>
                                  </div>
                                  <h4>{experience.name}</h4>
                                </div>
                                {experience.problem ? (
                                  <div className="questions">
                                    {experience.problem &&
                                    experience.problem !== null &&
                                    experience.problem.blocks
                                      .map((block: any) => block.text)
                                      .join("") === "" ? null : (
                                      <div className="question">
                                        <label>
                                          What problem or opportunity was
                                          identified on the Control?
                                        </label>
                                        {experience.problem && (
                                          <EditorInput
                                            content={experience.problem}
                                            onBlur={() => {}}
                                            readOnly={true}
                                          />
                                        )}
                                      </div>
                                    )}
                                  </div>
                                ) : null}

                                {experiment.is_single_hypothesis &&
                                controlExperience &&
                                controlExperience.hypothesis ? (
                                  controlExperience.hypothesis &&
                                  controlExperience.hypothesis.blocks
                                    .map((block: any) => block.text)
                                    .join("") === "" ? null : (
                                    <div className="questions">
                                      {account &&
                                      account.settings.use_bb_methods ===
                                        true ? (
                                        <label
                                          style={{
                                            textTransform: "uppercase",
                                            color: "#2f4760",
                                            fontSize: "12px",
                                            fontWeight: 500,
                                          }}
                                        >
                                          Whypothesis<sup>&reg;</sup> Customer
                                          Insights Tool
                                        </label>
                                      ) : (
                                        <label
                                          style={{
                                            textTransform: "uppercase",
                                            color: "#2f4760",
                                            fontSize: "12px",
                                            fontWeight: 500,
                                          }}
                                        >
                                          hypothesis
                                        </label>
                                      )}
                                      {controlExperience.hypothesis && (
                                        <EditorInput
                                          content={controlExperience.hypothesis}
                                          onBlur={() => {}}
                                          readOnly={true}
                                        />
                                      )}
                                    </div>
                                  )
                                ) : null}
                              </React.Fragment>
                            ) : (
                              <React.Fragment>
                                <div className="head">
                                  <div className="hexagon">
                                    <label>V{idx}</label>
                                  </div>
                                  <h4>{experience.name}</h4>
                                </div>
                                <div className="questions">
                                  {experience.difference ? (
                                    experience.difference &&
                                    experience.difference.blocks
                                      .map((block: any) => block.text)
                                      .join("") === "" ? null : (
                                      <div className="question">
                                        <label>
                                          How is this experience different from
                                          the Control?
                                        </label>
                                        {experience.difference && (
                                          <EditorInput
                                            content={experience.difference}
                                            onBlur={() => {}}
                                            readOnly={true}
                                          />
                                        )}
                                      </div>
                                    )
                                  ) : null}
                                  {!experiment.is_single_hypothesis &&
                                  experience.hypothesis ? (
                                    experience.hypothesis &&
                                    experience.hypothesis.blocks
                                      .map((block: any) => block.text)
                                      .join("") === "" ? null : (
                                      <div className="question">
                                        {account &&
                                        account.settings.use_bb_methods ===
                                          true ? (
                                          <label
                                            style={{
                                              textTransform: "uppercase",
                                              color: "#2f4760",
                                              fontSize: "12px",
                                              fontWeight: 500,
                                            }}
                                          >
                                            Whypothesis<sup>&reg;</sup> Customer
                                            Insights Tool
                                          </label>
                                        ) : (
                                          <label
                                            style={{
                                              textTransform: "uppercase",
                                              color: "#2f4760",
                                              fontSize: "12px",
                                              fontWeight: 500,
                                            }}
                                          >
                                            hypothesis
                                          </label>
                                        )}
                                        {experience.hypothesis && (
                                          <EditorInput
                                            content={experience.hypothesis}
                                            onBlur={() => {}}
                                            readOnly={true}
                                          />
                                        )}
                                      </div>
                                    )
                                  ) : null}
                                  {experience.tactics.length > 0 && (
                                    <div className="question">
                                      <label>Pains & Gains™</label>
                                      <ul>
                                        {experience.tactics.map(
                                          (tactic, idx) => {
                                            return (
                                              <li
                                                key={`t_${experience.id}_${idx}`}
                                              >
                                                <p>
                                                  {tactic.category}::
                                                  {tactic.value}
                                                </p>
                                              </li>
                                            );
                                          }
                                        )}
                                      </ul>
                                    </div>
                                  )}
                                  {experience.outcome ? (
                                    experience.outcome &&
                                    experience.outcome.blocks
                                      .map((block: any) => block.text)
                                      .join("") === "" ? null : (
                                      <div className="question">
                                        <label>
                                          outcomes & possible explanations
                                        </label>
                                        {experience.outcome && (
                                          <EditorInput
                                            content={experience.outcome}
                                            onBlur={() => {}}
                                            readOnly={true}
                                          />
                                        )}
                                      </div>
                                    )
                                  ) : null}
                                </div>
                              </React.Fragment>
                            )}
                          </div>
                        </div>
                      </React.Fragment>
                    );
                  })}
              </div>
            </section>
          )}
          {selectedReports.length > 0 && activeReport && (
            <section
              style={{
                paddingBottom: experiment.uploads?.length > 0 ? "0" : "60px",
              }}
            >
              <div className="content Analysis">
                <h2>Analysis</h2>
                <div className="flex">
                  {selectedReports.length === 1 && (
                    <div className="ReportSelector flex column fcol-1 pb__md">
                      <label>Report Name: {activeReport.name} </label>
                    </div>
                  )}
                  {selectedReports.length > 1 && (
                    <div className="ReportSelector flex column fcol-1 pb__md">
                      <label>
                        Select Report
                        <i
                          className="fa fa-star"
                          style={{
                            color: activeReport.primary ? "#f4b974" : "",
                            marginLeft: "22px",
                          }}
                        ></i>
                      </label>
                      <SelectInput
                        ignoreReadOnly={true}
                        value={activeReport.name}
                        name="report_selector"
                        placeholder="select report"
                        itemKey="name"
                        items={[
                          ...experiment.reports.filter((r) =>
                            selectedReports.includes(r.id ? r.id : "")
                          ),
                          ...experiment.custom_data_viz.filter((r: any) =>
                            selectedReports.includes(r.id ? r.id : "")
                          ),
                        ]}
                        onChange={this.changeReport}
                      />
                    </div>
                  )}
                </div>

                <div>
                  {isActiveReportDataViz ? (
                    <CustomDataViz
                      dataViz={
                        activeReport as unknown as DataTypes.CustomDataViz
                      }
                    />
                  ) : (
                    <>
                      {!this.state.loadingReport && (
                        <Report
                          experiences={experiment.experiences}
                          report={activeReport}
                        />
                      )}
                    </>
                  )}
                </div>
              </div>
            </section>
          )}

          {experiment.uploads?.length > 0 && (
            <section style={{ paddingBottom: "60px" }}>
              <div className="content Analysis">
                <h2 style={{ fontWeight: 200 }}>Attachments</h2>

                <ul>
                  {experiment.uploads.map((upload: any) => (
                    <li key={upload.id}>
                      <a
                        href={upload.url}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {upload.name}
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
            </section>
          )}
        </div>
      </div>
    );
  }
}

const loadingSelector = createLoadingSelector(["@@experiment/GET_CASE_STUDY"]);
const updatingSelector = createLoadingSelector([
  "@@experiment/UPDATE_CASE_STUDY",
]);

const mapStateToProps = ({
  router,
  app,
  account,
  experiment,
}: ApplicationState) => ({
  router: router,
  account: account,
  loading: loadingSelector(app.requests),
  experiment: experiment,
  updating: updatingSelector(app.requests),
});

const mapDispatchToProps = {
  showModal,
  getCaseStudyRequest,
  updateCaseStudyRequest,
};

const connectedPage = connect(
  mapStateToProps,
  mapDispatchToProps
)(AppPage(CaseStudy));

export { connectedPage as CaseStudy };
