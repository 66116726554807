import React, { Component } from "react";
import { connect } from "react-redux";
import moment from "moment";
import {
  createLoadingSelector,
  createErrorMessageSelector,
} from "store/selectors";
import * as DataTypes from "store/types";
import { AppPage, AppPageProps } from "comps/pages";
import { ApplicationState } from "store/types";
import { initUxrCreateRequest, createUxrRequest } from "store/uxr/actions";
import { showIdeaRequest, clearIdeaRequest } from "store/idea/actions";
import { getUxrsTraitsRequest } from "store/uxr_trait/actions";
import {
  Form,
  Input,
  ValidatorTypes,
  MultiSelect,
  DatePicker,
  Button,
} from "comps/form";
import { Helpers, history } from "utils";
import "css/Screens.scss";
import { EditorInput } from "ui/editor_input/editor_input";
import { MethodologySelector } from "ui/methodology_selector";
import { convertFromRaw, EditorState } from "draft-js";

interface IProps extends AppPageProps {
  router: any;
  account: DataTypes.Account | null;
  idea: DataTypes.Idea | null;
  loading: boolean;
  saving: boolean;
  appExpLoading: boolean;
  computedMatch: any;
  uxrTrait: any;
  ideaManagement: any;
  initUxrCreateRequest: () => void;
  createUxrRequest: (body: any) => void;
  getAppExpsRequest: (type: string) => void;
  showIdeaRequest: (id: string, callback: Function) => void;
  clearIdeaRequest: () => void;
  getUxrsTraitsRequest: () => void;
}

interface IState {
  selectedApp?: DataTypes.App;
  selectedAppExperiment?: any;
  minimumEndDate?: Date;
  maximumStartDate?: Date;
  loading: Boolean;
  submited: boolean;
}

class CreateUxr extends Component<IProps, IState> {

  constructor(props: IProps) {
    super(props);

    this.state = {
      minimumEndDate: moment().toDate(),
      maximumStartDate: moment().add(1, "year").toDate(),
      loading: true,
      submited: false,
    };
  }

  escFunction = (event: any) => {
    if (event.keyCode === 27) {
      history.goBack();
    }
  };

  componentDidMount = () => {
    this.props.initUxrCreateRequest();
    this.props.getUxrsTraitsRequest();

    document.addEventListener("keydown", this.escFunction, false);
  };

  componentWillMount = () => {
    this.props.clearIdeaRequest();

    this.setState({
      loading: false,
    });
  };

  componentWillUnmount = () => {
    document.removeEventListener("keydown", this.escFunction, false);
  };

  private close = () => {
    history.goBack();
  };

  private onSubmit = (valid: boolean, dirty: boolean, data: any) => {
    let descriptionStateValid = false;
    if (data.summary.value) {
      descriptionStateValid = EditorState.createWithContent(convertFromRaw(data.summary.value)).getCurrentContent().hasText();
    }

    if (valid && descriptionStateValid) {
      const uxr = {
        name: data.name.value,
        description: data.summary.value,
        start_date: data.start_date.value,
        end_date: data.end_date.value,
        methodology: data.methodology.value,
        method_kinds: data.method_kinds.value,
      };
      this.props.createUxrRequest(uxr);
    } else {
      this.setState({ submited: true })
    }
  };

  render() {
    const { saving, loading, account, uxrTrait } = this.props;

    if (loading === true || !account) {
      return null;
    }

    return (
      <div className="FullScreen">
        <div className="header">
          <img src="/img/logo_sm.svg" alt="illuminate" className="logo" />
          <button type="button" className="btn btn-close" onClick={this.close}>
            <i className="fas fa-times" />
          </button>
        </div>
        <div className="form-body">
          <div className="content simple">
            <div className="title">
              <h2>Add Research Study</h2>
            </div>
            <p>
              <span>
                Enter the research study details below. You’ll add rest of the
                information next.
              </span>
            </p>

            <Form
              formFields={{
                name: {
                  name: "name",
                  value: "",
                  validator: {
                    type: ValidatorTypes.REQUIRED,
                    messages: { required: "Please enter a valid study name" },
                  },
                },
                summary: {
                  name: "summary",
                  value: null,
                  validator: {
                    type: ValidatorTypes.TEXT_BOX_REQUIRED,
                    messages: {
                      required: "Please provide a summary of this study",
                    },
                  },
                },
                start_date: {
                  name: "start_date",
                  value: new Date(),
                  validator: {
                    type: ValidatorTypes.REQUIRED,
                    messages: {
                      required: "Please provide a start date for this study",
                    },
                  },
                },
                end_date: {
                  name: "end_date",
                  value: new Date(),
                  validator: {
                    type: ValidatorTypes.REQUIRED,
                    messages: {
                      required: "Please provide an end date for this study",
                    },
                  },
                },
                methodology: {
                  name: "methodology",
                  value: 0,
                  validator: {
                    type: ValidatorTypes.REQUIRED,
                    messages: {
                      required: "Please provide a methodology for this study",
                    },
                  },
                },
                method_kinds: {
                  name: "method_kinds",
                  value: [],
                  validator: {
                    type: ValidatorTypes.REQUIRED,
                    messages: {
                      required: "Please provide valid methods for this study",
                    },
                  },
                },
              }}
              onSubmit={this.onSubmit}
              FormComponent={({
                fields: {
                  name,
                  summary,
                  start_date,
                  end_date,
                  methodology,
                  method_kinds,
                },
                onChange,
              }) => (
                <div>
                  <section>
                    <div className="title">
                      <div className="step-circle">1</div>
                      <h4>overview</h4>
                    </div>
                    <div className="body">
                      <div className="form-group">
                        <label className="required">Study Name</label>
                        <Input
                          field={name}
                          className="form-control"
                          onChange={(e) => onChange(name, e.target.value)}
                        />
                        <p>
                          This is the name of the study that will be displayed
                          across illuminate.
                        </p>
                      </div>
                      <div className="form-group">
                        <label className="required">summary</label>
                        <EditorInput
                          submitted={this.state.submited}
                          content={summary.value}
                          onBlur={(e) => onChange(summary, e)}
                          errorMessage="Please provide a summary of this study"
                          required={true}
                          area={true}
                        />
                        <p>
                          Use the summary to share the goals of this study and
                          to preview what the reader can expect in terms of
                          outputs and limitations.
                        </p>
                      </div>
                      <div className="form-group">
                        <label className="required">Study Dates</label>
                        <ul className="DateRangePicker">
                          <li>
                            <DatePicker
                              field={start_date}
                              className="form-control"
                              onChange={(value) => {
                                const minimumEndDate = moment(value).toDate();
                                this.setState({ minimumEndDate });

                                onChange(start_date, value, () => {
                                  if (end_date.value < value) {
                                    return {
                                      [end_date.name]: { ...end_date, value },
                                    };
                                  }

                                  return {};
                                });
                              }}
                              maxDate={this.state.maximumStartDate}
                            />
                          </li>
                          <li className="seperator">to</li>
                          <li>
                            <DatePicker
                              field={end_date}
                              className="form-control"
                              onChange={(value) => {
                                const maximumStartDate = moment(value).toDate();
                                this.setState({ maximumStartDate });
                                onChange(end_date, value);
                              }}
                              minDate={this.state.minimumEndDate}
                            />
                          </li>
                        </ul>
                        <p>
                          Add the start date and end date of this research. They
                          can be in the past, present or future.
                        </p>
                      </div>
                    </div>
                  </section>
                  <section>
                    <div className="title">
                      <div className="step-circle">2</div>
                      <h4>Study Design</h4>
                    </div>

                    <div className="body">
                      <div
                        className="form-group"
                        style={{ marginBottom: "75px" }}
                      >
                        <div
                          className="control"
                          style={{ marginBottom: "40px" }}
                        >
                          <label className="required">Methodology</label>
                          <MethodologySelector
                            value={methodology.value}
                            onChange={(value) => onChange(methodology, value)}
                          />
                        </div>
                      </div>

                      <div className="form-group">
                        <label className="required">Methods</label>
                        <MultiSelect
                          field={method_kinds}
                          options={
                            uxrTrait?.traits && uxrTrait.traits["method_kinds"]
                              ? Helpers.uniqueArray([
                                ...uxrTrait?.traits["method_kinds"].map(
                                  (t: any) => {
                                    return t.name;
                                  }
                                ),
                                "Unmoderated",
                                "Moderated",
                                "Survey",
                                "Field Study",
                                "Tree Testing",
                                "Journey Map",
                                "Card Sort",
                                "Info Architecture",
                                "Wevo®",
                              ])
                              : []
                          }
                          className="multiselect_input create"
                          onChange={(value) => onChange(method_kinds, value)}
                        />
                        <p>
                          Select the method(s) that contributed to this research
                          study
                        </p>
                      </div>
                    </div>
                  </section>

                  <hr />
                  <Button
                    className="btn btn-primary"
                    text="save study"
                    isLoading={saving}
                  />
                </div>
              )}
            />
          </div>
        </div>
      </div>
    );
  }
}

const initLoader = createLoadingSelector(["@@uxr/INIT_UXR_CREATE"]);
const initError = createErrorMessageSelector(["@@uxr/INIT_UXR_CREATE"]);
const savingSelector = createLoadingSelector(["@@uxr/CREATE_UXR_REQUEST"]);

const mapStateToProps = ({
  router,
  app,
  account,
  uxrTrait,
}: ApplicationState) => ({
  loading: initLoader(app.requests),
  initError: initError(app.errors),
  saving: savingSelector(app.requests),
  router,
  account,
  uxrTrait,
});

const mapDispatchToProps = {
  createUxrRequest,
  initUxrCreateRequest,
  showIdeaRequest,
  clearIdeaRequest,
  getUxrsTraitsRequest,
};

const connectedPage = connect(
  mapStateToProps,
  mapDispatchToProps
)(AppPage(CreateUxr));

export { connectedPage as CreateUxr };
