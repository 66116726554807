import { action } from 'typesafe-actions';
import { Dispatch } from 'redux';
import { ActionTypes } from './types';
import * as DataTypes from '../types';

interface RequestError {
  statusCode?: number | undefined;
  message: any;
}

// Create Idea Board
export const createIdeaBoardRequest = (body: any) => (dispatch: Dispatch) => dispatch(action(ActionTypes.CREATE_IDEA_BOARD_REQUEST, { body }));
export const createIdeaBoardSuccess = (response: DataTypes.ApiRequest<DataTypes.IdeaBoard>) => action(ActionTypes.CREATE_IDEA_BOARD_SUCCESS, { response });
export const createIdeaBoardError = (error: RequestError) => action(ActionTypes.CREATE_IDEA_BOARD_FAILURE, error);

// Delete Idea Board by ID
export const deleteIdeaBoardRequest = (id: string) => (dispatch: Dispatch) => dispatch(action(ActionTypes.DELETE_IDEA_BOARD_REQUEST, { id }));
export const deleteIdeaBoardSuccess = (response: DataTypes.ApiRequest<DataTypes.IdeaBoard>) => action(ActionTypes.DELETE_IDEA_BOARD_SUCCESS, { response });
export const deleteIdeaBoardError = (error: RequestError) => action(ActionTypes.DELETE_IDEA_BOARD_FAILURE, error);

// Get Idea Boards
export const getIdeaBoardsRequest = (options?: DataTypes.ApiListOptions) => action(ActionTypes.GET_IDEA_BOARDS_REQUEST, { options });
export const getIdeaBoardsSuccess = (response: DataTypes.ApiList<DataTypes.IdeaBoard>) => action(ActionTypes.GET_IDEA_BOARDS_SUCCESS, { response });
export const getIdeaBoardsError = (error: RequestError) => action(ActionTypes.GET_IDEA_BOARDS_FAILURE, error);

// Get Idea Board
export const getIdeaBoardRequest = (board_id: string, options?: DataTypes.ApiListOptions, scrollOpts?: { append: boolean }) =>
  action(ActionTypes.GET_IDEA_BOARD_REQUEST, { board_id, options, scrollOpts });
export const getIdeaBoardSuccess = (response: DataTypes.ApiList<DataTypes.IdeaBoard>, append?: boolean) =>
  action(ActionTypes.GET_IDEA_BOARD_SUCCESS, { response, append });
export const getIdeaBoardError = (error: RequestError) => action(ActionTypes.GET_IDEA_BOARD_FAILURE, error);

// Update Idea Board Share by id
export const updateIdeaBoardShareRequest = (id: string, body: any) => (dispatch: Dispatch) =>
  dispatch(action(ActionTypes.UPDATE_IDEA_BOARD_SHARE_REQUEST, { id, body }));
export const updateIdeaBoardShareSuccess = (response: DataTypes.ApiRequest<DataTypes.IdeaBoard>) =>
  action(ActionTypes.UPDATE_IDEA_BOARD_SHARE_SUCCESS, { response });
export const updateIdeaBoardShareError = (error: RequestError) => action(ActionTypes.UPDATE_IDEA_BOARD_SHARE_FAILURE, error);

// Get Share Idea Board
export const getShareIdeaBoardRequest = (slug: string, uuid: string, password?: string) => (dispatch: Dispatch) =>
  dispatch(action(ActionTypes.GET_SHARE_IDEA_BOARD_REQUEST, { slug, uuid, password }));
export const getShareIdeaBoardSuccess = (response: DataTypes.ApiList<DataTypes.IdeaBoard>, append?: boolean) =>
  action(ActionTypes.GET_SHARE_IDEA_BOARD_SUCCESS, { response, append });
export const getShareIdeaBoardError = (error: RequestError) => action(ActionTypes.GET_SHARE_IDEA_BOARD_FAILURE, error);

export const exportIdeasRequest = (queryString: string, fields: string[], boardId: string) =>
  (dispatch: Dispatch) => dispatch(action(ActionTypes.EXPORT_IDEAS_REQUEST, { queryString, fields, boardId }));
export const exportIdeasSuccess = (response: DataTypes.ApiRequest<void>) =>
  action(ActionTypes.EXPORT_IDEAS_SUCCESS, { response });
export const exportIdeasError = (error: RequestError) =>
  action(ActionTypes.EXPORT_IDEAS_FAILURE, error);