import React, { Component } from "react";
import { connect } from "react-redux";
import { ApplicationState } from "store/types";
import * as DataTypes from "store/types";
import { createLoadingSelector } from "store/selectors";

import { AppPage, AppPageProps } from "comps/pages";
import { SubNav } from "ui/navs";
import { SectionLoader } from "ui/loaders";
import { updateAccountSettingsRequest } from "store/account/actions";
import { Form, Button, ValidatorTypes } from "comps/form";
import { VisiblePasswordInput } from "./comps";

interface IProps extends AppPageProps {
  account: DataTypes.Account | null;
  loading: boolean;
  updateAccountSettingsRequest: (settings: DataTypes.AccountSettings) => void;
}

class Settings extends Component<IProps> {
  private toggleBrands = (checked: boolean) => {
    this.props.updateAccountSettingsRequest({ brands: checked });
  };

  private toggleTeams = (checked: boolean) => {
    this.props.updateAccountSettingsRequest({ teams: checked });
  };

  private toggleBBMethods = (checked: boolean) => {
    this.props.updateAccountSettingsRequest({ use_bb_methods: checked });
  };

  private togglePasswordProtected = (checked: boolean) => {
    this.props.updateAccountSettingsRequest({
      password_protected_case_studies: checked,
    });
  };

  private toggleChannels = (checked: boolean) => {
    this.props.updateAccountSettingsRequest({ channels: checked });
  };

  private onPasswordSubmit = (valid: boolean, dirty: boolean, data: any) => {
    if (valid)
      this.props.updateAccountSettingsRequest({
        global_case_study_password: data.password.value,
      });
  };

  render() {
    const { account, loading } = this.props;

    return (
      <div className="Settings">
        <div className="container">
          <SubNav
            activeIdx={0}
            navItems={[
              { name: "account", link: "/settings" },
              { name: "users", link: "/settings/users" },
              { name: "invites", link: "/settings/invites" },
            ]}
          />
          <div className="header">
            <h4>Account</h4>
            <p>Use this section to manage your account settings.</p>
          </div>
          <SectionLoader loaded={loading}>
            {account && (
              <React.Fragment>
                <section>
                  <ul className="h-list">
                    <li>
                      <div>
                        <label>Company</label>
                        <p className="text-lrg">
                          <strong>{account.name}</strong>
                        </p>
                      </div>
                    </li>
                    <li>
                      <div>
                        <label>Account URL</label>
                        <p>{account.slug}</p>
                      </div>
                    </li>
                  </ul>
                </section>
                <hr className="dashed" />
                <section className="AppSettings">
                  <div className="desc">
                    <h4>Settings</h4>
                    <p>Manage global settings for your illuminate account.</p>
                  </div>
                  <div className="controls">
                    <div className="control">
                      <div className="desc">
                        <label>Use Brooks Bell Methodology</label>
                        <p>
                          Enable the proven methodology that testing experts at
                          Brooks Bell use, including the Insight Altitude
                          <sup>&reg;</sup>, Whypothesis<sup>&reg;</sup>, and
                          Insight Confidence<sup>&reg;</sup> Customer Insight
                          Tools.
                          <br />
                          <br />
                          When enabled, you’ll be able to tag your insights with
                          the Insight Altitude taxonomy, you’ll replace a
                          traditional hypothesis with our more customer-centric
                          Whypothesis structure, and you’ll be able to set your
                          Insight Confidence level so that you know which
                          insights are most reliable versus ones still gathering
                          supporting evidence.
                        </p>
                      </div>
                      <div className="toggle">
                        <label className="switch">
                          <input
                            type="checkbox"
                            defaultChecked={account.settings.use_bb_methods}
                            onChange={(e) =>
                              this.toggleBBMethods(e.target.checked)
                            }
                          />
                          <span className="slider round"></span>
                        </label>
                      </div>
                    </div>
                    <div className="control">
                      <div className="desc">
                        <label>Brands</label>
                        <p>
                          Enabling this feature will add a field called "Brands"
                          to tests, ideas, and insights. This will allow you to
                          filter by brand, making it easier to organize and
                          review your data if you are testing across multiple
                          brands or lines of business.
                        </p>
                      </div>
                      <div className="toggle">
                        <label className="switch">
                          <input
                            type="checkbox"
                            defaultChecked={account.settings.brands}
                            onChange={(e) =>
                              this.toggleBrands(e.target.checked)
                            }
                          />
                          <span className="slider round"></span>
                        </label>
                      </div>
                    </div>

                    <div className="control">
                      <div className="desc">
                        <label>Teams</label>
                        <p>
                          Enabling this feature will add a field called "Teams"
                          to tests, ideas, and insights. This will allow you to
                          filter by team, making it easier to organize and
                          review your data based upon the team involved.
                        </p>
                      </div>
                      <div className="toggle">
                        <label className="switch">
                          <input
                            type="checkbox"
                            defaultChecked={account.settings.teams}
                            onChange={(e) => this.toggleTeams(e.target.checked)}
                          />
                          <span className="slider round"></span>
                        </label>
                      </div>
                    </div>

                    <div className="control">
                      <div className="desc">
                        <label>Channels</label>
                        <p>
                          Enabling this feature will add a field called
                          "Channels" to tests, ideas, and insights. This will
                          allow you to filter by channel, making it easier to
                          organize and review your data if you are testing
                          across multiple channels.
                        </p>
                      </div>
                      <div className="toggle">
                        <label className="switch">
                          <input
                            type="checkbox"
                            defaultChecked={account.settings.channels}
                            onChange={(e) =>
                              this.toggleChannels(e.target.checked)
                            }
                          />
                          <span className="slider round"></span>
                        </label>
                      </div>
                    </div>

                    <div className="control">
                      <div className="desc">
                        <label>Password protect all case studies</label>
                        <p>
                          Enabling this feature will password protect all case
                          studies using one global password that you will
                          define.
                        </p>
                      </div>
                      <div className="toggle">
                        <label className="switch">
                          <input
                            type="checkbox"
                            defaultChecked={
                              account.settings.password_protected_case_studies
                            }
                            onChange={(e) =>
                              this.togglePasswordProtected(e.target.checked)
                            }
                          />
                          <span className="slider round"></span>
                        </label>
                      </div>
                    </div>
                    {account.settings.password_protected_case_studies && (
                      <Form
                        formFields={{
                          password: {
                            name: "password",
                            value:
                              account.settings.global_case_study_password || "",
                            validator: {
                              type: ValidatorTypes.REQUIRED,
                              messages: {
                                required: "Please provide a password",
                              },
                            },
                          },
                        }}
                        onSubmit={this.onPasswordSubmit}
                        FormComponent={({ fields: { password }, onChange }) => (
                          <div className="d-flex password-form">
                            <VisiblePasswordInput
                              field={password}
                              onChange={onChange}
                            />
                            <Button
                              disabled={
                                account.settings.global_case_study_password &&
                                account.settings.global_case_study_password ===
                                  password.value
                              }
                              text={
                                account.settings.global_case_study_password
                                  ? "update password"
                                  : "save password"
                              }
                              className="btn btn-primary"
                            />
                          </div>
                        )}
                      />
                    )}
                  </div>
                </section>
              </React.Fragment>
            )}
          </SectionLoader>
        </div>
      </div>
    );
  }
}

const loadingSelector = createLoadingSelector(["@@account/GET_ACCOUNT"]);

const mapStateToProps = ({ app, account }: ApplicationState) => ({
  loading: loadingSelector(app.requests),
  account: account,
});

const mapDispatchToProps = {
  updateAccountSettingsRequest,
};

const connectedPage = connect(
  mapStateToProps,
  mapDispatchToProps
)(AppPage(Settings));

export { connectedPage as Settings };
