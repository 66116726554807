import React, { Component } from 'react';

const TIME_OUT = 3000;

interface IProps {
  show: boolean;
  error?: boolean;
  text: string | undefined;
  onTimeOut(): void;
}

interface IState {}

class Alert extends Component<IProps, IState> {
  timeouts: Array<any> | undefined;

  componentDidMount() {
    this.timeouts = [];
  }

  componentDidUpdate = () => {
    const { show, onTimeOut } = this.props;

    if (this.timeouts && show === true) {
      this.timeouts.push(
        setTimeout(() => {
          if (onTimeOut) onTimeOut();
          this.clearTimeouts();
        }, TIME_OUT)
      );
    }
  };

  private clearTimeouts = () => {
    if (this.timeouts) {
      this.timeouts.forEach(clearTimeout);
      this.timeouts = [];
    }
  };

  render() {
    const { text, show, error } = this.props;
    return (
      <React.Fragment>
        {show === true && (
          <div className={`Alert${error ? ' danger' : ''}`} key="alert">
            {text}
          </div>
        )}
      </React.Fragment>
    );
  }
}

export { Alert };
