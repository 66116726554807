import React, { useEffect, useState } from "react";
import { DropdownControl, TextInput } from "ui";
import * as DataTypes from "store/types";
import { DataTable } from "./DataTable";
import { CustomDataVizChart } from "./chart";

type Params = {
  dataViz: DataTypes.CustomDataViz;
  onUpdate(customDataViz: Partial<DataTypes.CustomDataViz>): void;
  onDelete(): void;
  currentUser: any;
  onUpdateDataVizMetric(metricId: string, body: any): void;
  onOpenUpdateModal(customDataViz: DataTypes.CustomDataViz): void;
};

export const CustomDataViz: React.FC<Params> = ({
  dataViz,
  onUpdate,
  currentUser,
  onDelete,
  onOpenUpdateModal,
  onUpdateDataVizMetric,
}) => {
  const [resizing, setResizing] = useState(false);

  const onResize = (event: any) => {
    setResizing(true);
  };

  useEffect(() => {
    if (resizing) {
      setResizing(false);
    }
  }, [resizing]);

  useEffect(() => {
    window.addEventListener("resize", onResize);

    return () => {
      window.removeEventListener("resize", onResize);
    };
  }, []);

  return (
    <React.Fragment>
      <section className="Report">
        <div className="header">
          <div className="left">
            <TextInput
              key={["name", dataViz.id].join("_")}
              value={dataViz.name || ""}
              onChange={(name) => {
                onUpdate({ name });
              }}
            >
              <h4 className="text-capitalize">
                {dataViz.name}
                <i
                  className="fa fa-star"
                  style={{
                    color: dataViz.primary ? "#f4b974" : "",
                    marginLeft: "22px",
                  }}
                ></i>
              </h4>
            </TextInput>

            <label>EXPERIMENT::REPORT::Custom Data Visualization</label>
          </div>

          <div className="right">
            {currentUser.role !== "read-only" && (
              <DropdownControl
                controlItems={[
                  {
                    name: "edit",
                    icon: "far fa-edit",
                    onClick: () => onOpenUpdateModal(dataViz),
                  },
                  {
                    name: dataViz.primary ? "Remove Primary" : "Make Primary",
                    icon: "fa fa-star",
                    onClick: () => onUpdate({ primary: !dataViz.primary }),
                  },
                  {
                    name: "Delete",
                    icon: "far fa-trash-alt",
                    onClick: () => onDelete(),
                    splitAbove: true,
                    className: "danger",
                  },
                ]}
              />
            )}
          </div>
        </div>
      </section>

      {!resizing && (
        <CustomDataVizChart
          dataViz={dataViz}
          onUpdateDataVizMetric={onUpdateDataVizMetric}
        />
      )}

      <DataTable dataViz={dataViz} />
    </React.Fragment>
  );
};
