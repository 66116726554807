export enum ActionTypes {
  CREATE_WORKSHEET_REQUEST = '@@worksheet/CREATE_WORKSHEET_REQUEST',
  CREATE_WORKSHEET_SUCCESS = '@@worksheet/CREATE_WORKSHEET_SUCCESS',
  CREATE_WORKSHEET_FAILURE = '@@worksheet/CREATE_WORKSHEET_FAILURE',

  UPDATE_WORKSHEET_REQUEST = '@@worksheet/UPDATE_WORKSHEET_REQUEST',
  UPDATE_WORKSHEET_SUCCESS = '@@worksheet/UPDATE_WORKSHEET_SUCCESS',
  UPDATE_WORKSHEET_FAILURE = '@@worksheet/UPDATE_WORKSHEET_FAILURE',

  GET_WORKSHEET_REQUEST = '@@worksheet/GET_WORKSHEET_REQUEST',
  GET_WORKSHEET_SUCCESS = '@@worksheet/GET_WORKSHEET_SUCCESS',
  GET_WORKSHEET_FAILURE = '@@worksheet/GET_WORKSHEET_FAILURE',

  GET_SHARE_WORKSHEET_REQUEST = '@@worksheet/GET_SHARE_WORKSHEET_REQUEST',
  GET_SHARE_WORKSHEET_SUCCESS = '@@worksheet/GET_SHARE_WORKSHEET_SUCCESS',
  GET_SHARE_WORKSHEET_FAILURE = '@@worksheet/GET_SHARE_WORKSHEET_FAILURE',

  CHANNEL_UPDATE_WORKSHEET_REQUEST = '@@worksheet/CHANNEL_UPDATE_WORKSHEET_REQUEST',
  CHANNEL_UPDATE_WORKSHEET_SUCCESS = '@@worksheet/CHANNEL_UPDATE_WORKSHEET_SUCCESS',
  CHANNEL_UPDATE_WORKSHEET_FAILURE = '@@worksheet/CHANNEL_UPDATE_WORKSHEET_FAILURE',

  RESET_WORKSHEET = "@@worksheet/RESET_WORKSHEET",

  NOTIFY_EDITING_REQUEST = "@@worksheet/NOTIFY_EDITING_REQUEST",
  NOTIFY_EDITING_SUCCESS = "@@worksheet/NOTIFY_EDITING_SUCCESS",
  NOTIFY_EDITING_FAILURE = "@@worksheet/NOTIFY_EDITING_FAILURE"
}
