import React from "react";
import { InsightProposal } from "store/types";
import { Container } from "./index.style";

type Props = {
  proposal: InsightProposal;
  onSelect: (id: string) => void;
  unselected: string[];
};

export const InsightCard: React.FC<Props> = ({
  proposal,
  onSelect,
  unselected,
}) => {
  return (
    <Container>
      <input
        id={proposal.id}
        type="checkbox"
        checked={!unselected.includes(proposal.id)}
        onClick={() => onSelect(proposal.id)}
      />
      <label htmlFor={proposal.id}>{proposal.value}</label>
    </Container>
  );
};
