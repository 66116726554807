import { action } from 'typesafe-actions';
import { Dispatch } from 'redux';
import { ActionTypes } from './types';
import * as DataTypes from '../types';

interface RequestError {
  statusCode?: number | undefined;
  message: any;
}

export const getIdeaBoardsTraitRequestSaga = () => action(ActionTypes.GET_IDEA_BOARDS_TRAITS_REQUEST, {});
export const getIdeaBoardsTraitsRequest = () => (dispatch: Dispatch) =>
  dispatch(action(ActionTypes.GET_IDEA_BOARDS_TRAITS_REQUEST));
export const getIdeaBoardsTraitsSuccess = (response: DataTypes.ApiRequest<DataTypes.Trait>) =>
  action(ActionTypes.GET_IDEA_BOARDS_TRAITS_SUCCESS, { response });
export const getIdeaBoardsTraitsError = (error: RequestError) => action(ActionTypes.GET_IDEA_BOARDS_TRAITS_FAILURE, error);

export const createIdeaBoardsTraitsRequest = (insightId: string, traitName: string, values: string[]) => (dispatch: Dispatch) =>
  dispatch(action(ActionTypes.CREATE_IDEA_BOARDS_TRAITS_REQUEST, { insightId, traitName, values }));
export const createIdeaBoardsTraitsSuccess = (response: DataTypes.ApiList<DataTypes.Insight>) =>
  action(ActionTypes.CREATE_IDEA_BOARDS_TRAITS_SUCCESS, { response });
export const createIdeaBoardsTraitsError = (error: RequestError) =>
  action(ActionTypes.CREATE_IDEA_BOARDS_TRAITS_FAILURE, error);