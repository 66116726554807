import React, { Component } from "react";
import * as DataTypes from "store/types";
import { Avatar } from "ui";
import {
  DragDropContext,
  Draggable,
  Droppable,
  DropResult,
  ResponderProvided,
} from "react-beautiful-dnd";

interface IProps {
  loading: boolean;
  ideas: Array<DataTypes.Idea> | null;
  selectedIdx: number;
  onReorder(id: string, position: number): void;
  onSelect(itemIndex: number): void;
  reorderable: boolean;
  ideaManagement: any;
}

class List extends Component<IProps> {
  loadMore = () => {};

  onDragEnd = (result: DropResult, provided: ResponderProvided) => {
    const { draggableId: id, destination, reason } = result;

    if (reason !== "DROP" || !destination) {
      return;
    }

    const { index } = destination;

    this.props.onReorder(id, index);
  };

  getDragStyle = (isDragging: boolean, draggableStyle: any) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: "none",

    // change background colour if dragging
    //background: isDragging ? 'lightgreen' : 'grey',

    // styles we need to apply on draggables

    ...draggableStyle,
  });

  render() {
    const { loading, ideas, selectedIdx } = this.props;
    if (loading) {
      return (
        <ul className="ListItem placeholder">
          <LoadingListItems count={8} />
        </ul>
      );
    }

    return (
      <React.Fragment>
        <ul className="ListItem">
          <DragDropContext onDragEnd={this.onDragEnd}>
            <Droppable droppableId="1">
              {(provided, snapshot) => (
                <div className="column" ref={provided.innerRef}>
                  {provided.placeholder}

                  {ideas &&
                    ideas.map((idea: DataTypes.Idea, idx: number) => (
                      <Draggable
                        isDragDisabled={!this.props.reorderable}
                        key={`dragabble_${idea.id}`}
                        index={idx}
                        draggableId={idea.id || ""}
                      >
                        {(provided, snapshot) => (
                          <div
                            className={
                              snapshot.isDragging ? "item dragging" : "item"
                            }
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            style={this.getDragStyle(
                              snapshot.isDragging,
                              provided.draggableProps.style
                            )}
                          >
                            <ListItem
                              ideaManagement={this.props.ideaManagement}
                              key={"li_" + idea.id}
                              idea={idea}
                              selected={idx === selectedIdx}
                              onItemSelect={() => this.props.onSelect(idx)}
                            />
                          </div>
                        )}
                      </Draggable>
                    ))}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </ul>
      </React.Fragment>
    );
  }
}

export { List };

interface IListItemProps {
  idea: DataTypes.Idea;
  selected: boolean;
  onItemSelect(): void;
  ideaManagement: any;
}

const ListItem = ({
  idea,
  selected,
  onItemSelect,
  ideaManagement,
}: IListItemProps) => {
  const isChecked =
    ideaManagement.selected.find(
      (currentIdea: any) => idea.id === currentIdea.id
    ) || false;

  const problem =
    idea.problem?.blocks?.map((block: any) => block.text).join(" ") || "";

  return (
    <li
      className={
        selected === true || (isChecked && ideaManagement.multiSelect)
          ? "active"
          : ""
      }
      onClick={onItemSelect}
      key={"idea_" + idea.id}
      id={"idea_" + idea.id}
      style={{ tableLayout: "fixed" }}
      tabIndex={1}
    >
      <div className="left" style={{ width: "80px" }}>
        <div className="flex align-center">
          {ideaManagement.multiSelect ? (
            <input
              readOnly={true}
              type="checkbox"
              style={{ marginRight: "18px" }}
              checked={isChecked}
            />
          ) : null}
          <div>
            <Avatar user={idea.author} showHover={true} />
            <div className={`tag ${idea.status}`}>
              {idea.status === "love" && <i className="fas fa-heart" />}
              {idea.status === "back_burner" && (
                <i className="fas fa-fire-alt" />
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="body" style={{ width: "auto" }}>
        <p className="title">{idea.name}</p>

        <p className="description">{problem}</p>
        <ul className="bullet-list">
          {idea.themes.map((theme: any) => (
            <li key={`idea-${idea.id}-theme-${theme}`}>{theme}</li>
          ))}
        </ul>
      </div>
      <div className="right" style={{ width: "80px" }}>
        <div className="score">
          {idea.star === true && <i className="fa fa-star active" />}
          <React.Fragment>
            <h3>{idea.score || "0.0"}</h3>
            <p>score</p>
          </React.Fragment>
        </div>
      </div>
    </li>
  );
};

interface ILoadingListItemProps {
  count: number;
}

const LoadingListItems = ({ count }: ILoadingListItemProps): any => {
  let elem = [];

  for (let i = 0; i < count; i++) {
    elem.push(
      <li key={"li_" + i}>
        <div className="body">
          <h4 className="title">&nbsp;</h4>
          <p></p>
          <p></p>
          <p></p>
        </div>
        <div className="right">
          <div className="status"></div>
          <p></p>
          <p></p>
        </div>
      </li>
    );
  }

  return elem;
};
