import React, { Component } from 'react';
import { ApiError, ApiSystemError } from '../../api/types';
import { HTTPCodes, appConfig } from 'config/app';

interface IProps {
  children: React.ReactNode;
}

interface IState {
  hasError: boolean;
  error?: any;
}

class Boundry extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error: any) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true, error: error };
  }

  componentDidCatch(error: any, info: any) {
    console.info('Error boundry logging error', error.body);
  }

  render() {
    if (this.state.hasError === true) {
      if (this.state.error instanceof ApiSystemError) {
        return (
          <div className="Public">
            <div className="Page">
              <div className="content">
                <div className="logo">
                  <img src="/img/logo.png" alt="illuminate" />
                </div>
                <div className="form">
                  <h1>Oops! Looks like we are experiencing a system error.</h1>
                  <p className="mb-4">Rest assured - we’re looking into the issue and hope to have this resolved shortly.</p>
                </div>
              </div>
              <div className="aside"></div>
            </div>
          </div>
        );
      }

      if (this.state.error instanceof ApiError) {
        if (this.state.error.statusCode === HTTPCodes.NOT_FOUND) {
          return (
            <div className="Public">
              <div className="Page">
                <div className="content">
                  <div className="logo">
                    <img src="/img/logo.png" alt="illuminate" />
                  </div>
                  <div className="form">
                    <h1>Hmmm, something went wrong.</h1>
                    <p className="mb-4">Please be sure the url was typed correctly. If it was, either this page doesn’t exist or is having an issue. </p>
                    <p>
                      In the meantime, please check out our{' '}
                      <a href={appConfig.HELP_URL} target="_blank" rel="noopener noreferrer">
                        Help Center.
                      </a>
                    </p>
                  </div>
                </div>
                <div className="aside"></div>
              </div>
            </div>
          );
        }
        return (
          <div className="PageError">
            <div className="content">
              <div className="logo">
                <img src="/img/logo.png" alt="illuminate" />
              </div>
              <h2>API Error</h2>
              <p>{this.state.error.message}</p>
            </div>
          </div>
        );
      }

      if (this.state.error instanceof Error) {
        return (
          <div className="PageError">
            <div className="content">
              <div className="logo">
                <img src="/img/logo.png" alt="illuminate" />
              </div>
              <h2>Javascript Error</h2>
              <p>{this.state.error.message}</p>
            </div>
          </div>
        );
      }
    }

    return this.props.children;
  }
}

export { Boundry };
