import React, { Component } from 'react';
import * as DataTypes from 'store/types';
import { Form, Input, InputTypes, Button, ValidatorTypes } from 'comps/form';

interface IProps {
  saving: boolean;
  account: DataTypes.Account;
  uxr: DataTypes.Uxr;
  onSubmit(passwordProtected: boolean, password?: string): void;
}

interface IState {
  passwordProtect: boolean;
  copied: boolean;
}

class Share extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    const { uxr } = this.props;

    this.state = {
      copied: false,
      passwordProtect: uxr.share && uxr.share.password_protected ? uxr.share.password_protected : false,
    };
  }

  private copyUrl = () => {
    var copyText = document.getElementById('shareUrl') as HTMLInputElement;
    copyText.select();
    document.execCommand('Copy');
    this.setState({ ...this.state, copied: true });
  };

  private togglePasswordProtect = (checked: boolean) => {
    const { uxr } = this.props;
    if (checked === false && uxr.share?.password_protected !== false) {
      this.props.onSubmit(false);
    }
    this.setState({ ...this.state, passwordProtect: checked });
  };

  private updateCaseStudy = (valid: boolean, dirty: boolean, data: any) => {
    if (valid) {
      this.props.onSubmit(true, data.password.value);
    }
  };

  render() {
    const { uxr, account, saving } = this.props;
    const { passwordProtect, copied } = this.state;

    return (
      <React.Fragment>
        <div className="header">
          <h3>Share Case Study</h3>
          <p>Use the link below to share your case study.</p>
        </div>
        <div className="body">
          <div className="form-group mt-2 mb-2">
            <label>copy the share link below</label>
            <div className="input-group mb-3">
              <input
                id="shareUrl"
                type="text"
                className="form-control"
                readOnly={true}
                value={`${window.location.protocol}//${window.location.host}/uxrs/${uxr.share?.uuid}`}
              />
              <div className="input-group-append">
                <button className={copied ? 'btn btn-success' : 'btn btn-primary'} type="button" onClick={this.copyUrl}>
                  {copied ? 'copied' : 'copy'}
                </button>
              </div>
            </div>
          </div>

          {account.settings.password_protected_case_studies === false ? (
            <React.Fragment>
              <div className="control">
                <div className="toggle">
                  <label className="switch">
                    <input type="checkbox" defaultChecked={passwordProtect} onChange={(e) => this.togglePasswordProtect(e.target.checked)} />
                    <span className="slider round"></span>
                  </label>
                </div>
                <div className="desc">
                  <p>Password protect this case study</p>
                </div>
              </div>
              {passwordProtect === true && (
                <Form
                  formFields={{
                    password: {
                      name: 'password',
                      value: uxr.share?.password || '',
                      validator: { type: ValidatorTypes.REQUIRED, messages: { required: 'Please enter a valid password.' } },
                    },
                  }}
                  onSubmit={this.updateCaseStudy}
                  FormComponent={({ fields: { password }, onChange }) => (
                    <React.Fragment>
                      <div className="form-group mt-2">
                        <label>password</label>
                        <Input field={password} type={InputTypes.PASSWORD} className="form-control" onChange={(e) => onChange(password, e.target.value)} />
                      </div>
                      <div className="cntrl-bar">
                        <Button className="btn btn-primary" text="save" isLoading={saving} />
                      </div>
                    </React.Fragment>
                  )}
                />
              )}
            </React.Fragment>
          ) : (
            <div>
              <p>
                <i>
                  This case study will be password protected using a global password when shared. Please contact your illuminate administrator to obtain this
                  password.
                </i>
              </p>
            </div>
          )}
        </div>
      </React.Fragment>
    );
  }
}

export { Share };
