import React, { useState, useRef } from "react";
import Collapsible from "react-collapsible";
import { Link } from "react-router-dom";
import { ArrowDownIcon } from "./arrow_down_icon";
import { handleOutsideClick } from "./utils";

export interface MenuItem {
  icon: string;
  name: string;
  link: string;
  splitAbove?: boolean;
  external?: boolean;
  children?: Array<MenuItem>;
}

interface IProps {
  name: string;
  menuItems: Array<MenuItem>;
  value: string;
  component: React.ReactNode;
}

const DropdownMenu = ({ name, value, menuItems, component }: IProps) => {
  const ref = useRef<HTMLUListElement>(null);

  const [open, setOpen] = useState(false);

  handleOutsideClick(ref, () => {
    if (open) setOpen(!open);
  });

  return (
    <div className="dropdown">
      <button className="dropdown-toggler" onClick={(e) => setOpen(!open)}>
        {component ? component : <span>{value}</span>}
      </button>

      <ul ref={ref} className={open ? "dropdown-menu show" : "dropdown-menu"}>
        {menuItems.map((item) =>
          item.children ? (
            <Collapsible
              key={item.name}
              triggerTagName="li"
              trigger={
                <span style={{ color: "#757e8e" }}>
                  {item.name} <ArrowDownIcon height="18" />
                </span>
              }
              triggerClassName="cursor--pointer"
              triggerOpenedClassName="cursor--pointer"
              className={`dropdown-menu-item ${
                item.splitAbove ? "split-above" : ""
              }`}
              openedClassName={`dropdown-menu-item ${
                item.splitAbove ? "split-above" : ""
              }`}
            >
              {item.children.map((child: any) => (
                <li key={child + "_" + child.name}>
                  {child.external ? (
                    <a href={child.link}>
                      <i className={child.icon} /> {child.name}
                    </a>
                  ) : (
                    <Link to={child.link}>
                      <i className={child.icon} /> {child.name}
                    </Link>
                  )}
                </li>
              ))}
            </Collapsible>
          ) : (
            <li
              key={name + "_" + item.name}
              className={`dropdown-menu-item ${
                item.splitAbove ? "split-above" : ""
              }`}
            >
              {item.external ? (
                <a href={item.link} target="_blank" rel="noopener noreferrer">
                  <i className={item.icon} /> {item.name}
                </a>
              ) : (
                <Link to={item.link}>
                  <i className={item.icon} /> {item.name}
                </Link>
              )}
            </li>
          )
        )}
      </ul>
    </div>
  );
};

export { DropdownMenu };
