import { Button, Form, MultiSelect, ValidatorTypes } from 'comps';
import React, { Component } from 'react';
import { Helpers } from 'utils';

interface IProps {
  onSubmit(themes: any): void;
  ideaTrait: any;
}

class AddTheme extends Component<IProps> {
  private onSubmit = (valid: boolean, dirty: boolean, data: any) => {
    const themes = data.theme.value;

    this.props.onSubmit(themes);
  }

  render() {
    return (
      <React.Fragment>
        <div className="header">
          <h3>Add a theme to the ideas you selected to help you better organize them.</h3>
        </div>

        <Form
          formFields={{
            theme: {
              name: 'theme',
              value: [],
              validator: {
                type: ValidatorTypes.REQUIRED,
                messages: {
                  required: 'Please enter a valid theme',
                },
              },
            },
          }}
          onSubmit={this.onSubmit}
          FormComponent={({ fields: { theme }, onChange }) => (
            <React.Fragment>
              <div className="form-group">
                <MultiSelect
                  field={theme}

                  options={
                    this.props.ideaTrait && this.props.ideaTrait['themes']
                      ? Helpers.uniqueArray(
                        this.props.ideaTrait['themes'].map((t: any) => {
                          return t.name;
                        })
                      )
                      : []
                  }
                  className="multiselect_input create"
                  onChange={(value) => onChange(theme, value)}
                />

                <p>Examples: Streamlined Checkout, CTA Updates, Incorporating Social Proof, etc.</p>
              </div>

              <Button className="btn btn-primary" text="add themes" isLoading={false} />
            </React.Fragment>
          )} />
      </React.Fragment>
    );
  }
}

export { AddTheme };
