import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { ApplicationState } from 'store/types';
import { AppPage, AppPageProps } from 'comps/pages';
import * as DataTypes from 'store/types';
import { history, Helpers } from 'utils';
import { createLoadingSelector } from 'store/selectors';
import { getShareCaseStudyRequest } from 'store/experiment/actions';
import { AuthService } from 'utils/auth';
import { AnnotateImage } from 'comps';
import { Avatar } from 'ui';

enum ViewType {
  FULL = 'full',
  SCALED = 'scaled',
}

interface IProps extends AppPageProps {
  computedMatch: any;
  account: DataTypes.Account | null;
  experiment: DataTypes.Experiment | null;
  loading: boolean;
  getShareCaseStudyRequest: (slug: string, uuid: string, password?: string) => void;
}

interface IState {
  view: ViewType;
  showAside: boolean | undefined;
  annotation: any;
}

class SharedExperimentImages extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      view: ViewType.SCALED,
      showAside: undefined,
      annotation: {},
    };
  }

  componentDidMount = () => {
    let pwd;
    const sharedPwd = AuthService.getSharePwd();

    if (sharedPwd) {
      const json = JSON.parse(sharedPwd);
      if (json.key === this.props.computedMatch.params.uuid) {
        pwd = json.pwd;
      } else {
        AuthService.clearSharePwd();
      }
    }

    const { account } = this.props;
    if (account) {
      this.props.getShareCaseStudyRequest(account.slug, this.props.computedMatch.params.uuid, pwd || undefined);
    }
  };

  private close = () => {
    const { experiment } = this.props;

    if (experiment) {
      history.push(`/cs/${experiment.case_study?.uuid}`);
    }
  };

  private showAside = (show: boolean | undefined) => {
    let toggle = false;
    if (show !== undefined) {
      toggle = !show;
    }

    this.setState({ ...this.state, showAside: toggle });
  };

  render() {
    const { loading, experiment } = this.props;
    const { showAside, annotation } = this.state;

    if (loading) return null;

    const experience = experiment && experiment.experiences.find((ex) => ex.id === this.props.computedMatch.params.experience_id);
    const image = experience && experience.images.find((image) => image.id === this.props.computedMatch.params.image_id);

    if (!experiment || !experience || !image)
      return (
        <div>
          <h1>No Image</h1>
        </div>
      );

    return (
      <React.Fragment>
        <div className="FullScreen">
          <div className="logo">
            <img src="/img/logo_sm.svg" alt="illuminate" />
          </div>
          <ul className="controls">
            <li>
              <button type="button" className="btn btn-ctrl" onClick={() => this.showAside(showAside)}>
                <i className={showAside !== false ? 'fas fa-chevron-right' : 'fas fa-chevron-left'}></i>
              </button>
            </li>
            <li>
              <button type="button" className="btn btn-ctrl" onClick={this.close}>
                <i className="fas fa-times" />
              </button>
            </li>
          </ul>
          <div className={showAside === false ? 'ImageViewer full-size' : 'ImageViewer '}>
            {showAside === false && (
              <div className="title">
                <label>{experience.name}</label>
                <h2>{image.name}</h2>
              </div>
            )}

            <AnnotateImage key={image.id} image={image} annotation={annotation} disabled={true} />

            <div className={showAside === undefined ? 'Aside' : showAside ? 'Aside opened' : 'Aside closed'}>
              <div className="title-bar share">
                <label>{experience.name}</label>
                <h2>{image.name}</h2>
                <p>{image.description}</p>
              </div>
              <hr />
              <div className="comments">
                <Comments comments={image.comments} />
              </div>
            </div>
          </div>
        </div>
        {experience.images.length > 1 && (
          <div className="ImageCarousel">
            {experience.images.map((img) => {
              return (
                <div key={['img_', img.id].join('_')} className={img.id === image.id ? 'thmb active' : 'thmb'}>
                  <Link to={`/cs/${experiment.case_study?.uuid}/experiences/${experience.id}/images/${img.id}`}>
                    <img src={img.url} alt={img.name} />
                  </Link>
                </div>
              );
            })}
          </div>
        )}
      </React.Fragment>
    );
  }
}

const loadingSelector = createLoadingSelector(['@@experiment/GET_SHARE_CASE_STUDY']);

const mapStateToProps = ({ router, app, account, experiment }: ApplicationState) => ({
  router: router,
  account: account,
  loading: loadingSelector(app.requests),
  experiment: experiment,
});

const mapDispatchToProps = {
  getShareCaseStudyRequest,
};

const connectedPage = connect(mapStateToProps, mapDispatchToProps)(AppPage(SharedExperimentImages));

export { connectedPage as SharedExperimentImages };

interface ICommentsProps {
  comments?: Array<DataTypes.Comment>;
}

const Comments = ({ comments }: ICommentsProps) => {
  let annoCounter = 0;
  const elem =
    comments &&
    comments.map((comment, idx) => {
      if (comment.annotations && comment.annotations.length > 0) {
        annoCounter = annoCounter + 1;
      }

      return (
        <li key={`comment_${comment.id}_${idx}`}>
          {comment.annotations && comment.annotations.length > 0 && <div className="tag">{annoCounter}</div>}
          <Avatar user={comment.user} />
          {comment.user && (
            <p className="name">
              {comment.user.first_name} {comment.user.last_name}
            </p>
          )}
          <p className="value">{comment.value}</p>
          {comment.updated_at && <p className="date">{Helpers.formatDate(comment.updated_at)}</p>}
        </li>
      );
    });

  return <ul>{elem}</ul>;
};
