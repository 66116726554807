import React from "react";
import { InsightProposal } from "store/types";
import { InsightCard } from "./insight_card";
import { Container } from "./insight_list.style";

type Props = {
  name: string;
  items: InsightProposal[];
  onSelect: (id: string) => void;
  unselected: string[];
};

export const InsightList: React.FC<Props> = ({
  name,
  items,
  onSelect,
  unselected,
}) => {
  if (items.length === 0) {
    return null;
  }

  return (
    <Container>
      <h2>{name}</h2>

      <ul>
        {items.map((proposal: InsightProposal) => (
          <InsightCard
            unselected={unselected}
            onSelect={onSelect}
            proposal={proposal}
            key={proposal.id}
          />
        ))}
      </ul>
    </Container>
  );
};
