import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import * as API from "../../api";
import * as Actions from "./actions";
import { ActionTypes } from "./types";

function* getUxrsTraits(action: any) {
  try {
    // @ts-ignore
    const res = yield call(API.Uxrs.traits);

    yield put(Actions.getUxrsTraitsSuccess(res.body));
  } catch (error: any) {
    yield put(Actions.getUxrsTraitsError(error));
  }
}

function* createUxrsTraits(action: any) {
  try {
    const { uxrId, traitName, values } = action.payload;

    // @ts-ignore
    const res = yield call(API.Uxrs.addTraits, uxrId, traitName, values);

    yield put(Actions.createUxrsTraitsSuccess(res.body));
    yield put(Actions.getUxrsTraitRequestSaga());
  } catch (error: any) {
    yield put(Actions.getUxrsTraitsError(error));
  }
}

function* watchGetUxrsTraits() {
  yield takeEvery(ActionTypes.GET_UXRS_TRAITS_REQUEST, getUxrsTraits);
}

function* watchCreateUxrsTraits() {
  yield takeEvery(ActionTypes.CREATE_UXRS_TRAITS_REQUEST, createUxrsTraits);
}

function* uxrsTraitsSaga() {
  yield all([fork(watchGetUxrsTraits), fork(watchCreateUxrsTraits)]);
}

export default uxrsTraitsSaga;
