import React from "react";
import { Form, Input, Select, Button, ValidatorTypes } from "comps/form";
import { Account, CurrencySymbols } from "store/types";

interface IProps {
  updateAccountProgramGoalsRequest: (body: any, redirecturl?: string) => void;
  account: Account;
  saving: boolean;
}

const DATA_TYPES = ["number", "currency"];

export const AddGoal = (props: IProps) => {
  const { account, saving, updateAccountProgramGoalsRequest } = props;

  const onSubmit = (valid: boolean, dirty: boolean, data: any) => {
    if (valid) {
      updateAccountProgramGoalsRequest({
        program_goals: [
          {
            name: data.name.value,
            data_type: data.type.value,
            symbol: data.symbol.value,
          },
          ...account.program_goals,
        ],
      });
    }
  };

  return (
    <React.Fragment>
      <div className="header">
        <h3>Create Program Goal</h3>
        <p>Use the form below to create a new program goal.</p>
      </div>
      <Form
        onSubmit={onSubmit}
        formFields={{
          name: {
            name: "name",
            value: "",
            validator: {
              type: ValidatorTypes.REQUIRED,
              messages: { required: "Enter a valid program goal name" },
            },
          },
          type: { name: "type", value: "" },
          symbol: {
            name: "symbol",
            value: "DOLLAR",
          },
        }}
        FormComponent={({ fields: { name, type, symbol }, onChange }) => (
          <div className="header">
            <div className="form-group">
              <label>Name</label>
              <Input
                field={name}
                className="form-control"
                onChange={(e) => onChange(name, e.target.value)}
              />
            </div>
            <div className="form-group">
              <label>Type</label>
              <Select
                className="SelectInput"
                placeholder="-- Select --"
                field={type}
                options={DATA_TYPES}
                onChange={(value: string) => onChange(type, value)}
              />
            </div>

            {type.value === "currency" ? (
              <div className="form-group">
                <label>Currency symbol</label>
                <Select
                  className="SelectInput currency-select"
                  placeholder="-- Select --"
                  field={symbol}
                  options={Object.keys(CurrencySymbols)}
                  onChange={(value: string) => onChange(symbol, value)}
                  showSymbols={true}
                />
              </div>
            ) : null}

            <div className="cntrl-bar">
              <Button
                className="btn btn-primary"
                text="create program goal"
                isLoading={saving}
              />
            </div>
          </div>
        )}
      />
    </React.Fragment>
  );
};
