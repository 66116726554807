import React, { Component } from 'react';
import * as DataTypes from 'store/types';

interface IProps {
  experiment: DataTypes.Experiment | null;
  saving: boolean;
  onSubmit(): void;
}

class BestInClass extends Component<IProps> {
  render() {
    return (
      <React.Fragment>
        <div className="header flex">
          <div className='mr__sm'>
            <i className='fas fa-trophy' style={{ color: "#f5a623", fontSize: '80px' }}></i>
          </div>

          <div>
            <h4 style={{ fontWeight: 300 }}>Best in Class</h4>
            <h3>Idea Boards</h3>
          </div>
        </div>
        <div style={{ maxWidth: '600px' }} className="mt__sm">
          <p>Crowdsourcing ideas is a culture-booster, but you've got to share data and context in order to make it productive. illuminate makes it easy! To help set you up for a queue of winning ideas, here are some tips</p>

          <ul style={{ listStyle: 'disc' }}>
            <li><p>Set up an Idea Board to manage ideas surrounding a <strong>common theme</strong>. Not one per test, but one per theme. You may end up with dozens of ideas for a theme that can last you many tests.</p></li>
            <li><p>Create the Idea Board Worksheet to <strong>share context, goals, quantitative data, qualitative data and more</strong>.</p></li>
            <li><p>Call competitors to mind and restate your unique value proposition to help your participants get in a <strong>customer mindset</strong>.</p></li>
            <li><p>Use the <strong>"Share and Request Ideas"</strong> option to get a link you can provide to anyone - they don't need an account. They'll get access to the worksheet and an intake form.</p></li>
            <li><p>Update your worksheet and <strong>share the board link again</strong> whenever your idea queue is running low. No need to reinvent the wheel.</p></li>
          </ul>

          <div className="flex justify-center align-center mt__sm">
            <a href="https://sandbox.illuminate.app/ib/38950c1" target="_blank">
              <img src="/img/best_in_class_idea_board.png" alt="Best in Class image" className='shadow__full' height={'106px'} />
            </a>
            <a href="https://sandbox.illuminate.app/ib/38950c1" target='_blank' style={{ width: '30%', marginLeft: '24px' }}>Check out this live, best in class example to see it in action!</a>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export { BestInClass };
