import React, { Component } from "react";
import { connect } from "react-redux";
import { history, Helpers } from "utils";
import { ApplicationState } from "store/types";
import * as DataTypes from "store/types";
import { showModal } from "store/app/actions";
import { createLoadingSelector } from "store/selectors";
import { getShareRequest, updateShareRequest } from "store/uxr/actions";
import { AppPage, AppPageProps } from "comps/pages";
import { DropdownControl } from "ui";
import { AnnotateImage } from "comps";
import * as Modals from "./comps/modals";
import "css/CaseStudy.scss";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { EditorInput } from "ui/editor_input/editor_input";
import { Link } from "react-router-dom";
import Collapsible from "react-collapsible";
import { Carousel } from "react-responsive-carousel";
import { MethodologySelector } from "ui/methodology_selector";

enum PageActions {
  NONE = "none",
  SELECT_QUANTITATIVE_DATA = "select_quantitative_data",
  SELECT_QUALITATIVE_DATA = "select_qualitative_data",
  SELECT_AREA_OF_FOCUS = "select_area_of_focus",
  SELECT_INSIGHTS = "select_insights",
  TOGGLE_RESULTS = "toggle_results",
  SHARE = "share",
}

interface IProps extends AppPageProps {
  router: any;
  computedMatch: any;
  loading: boolean;
  getShareRequest: (id: string) => void;
  updateShareRequest: (
    id: string,
    body: any,
    disableHideModal?: boolean
  ) => void;
  showModal: (component: React.ComponentType<any>, options: any) => void;
  uxr: DataTypes.Uxr | null;
  account: DataTypes.Account | null;
  updating: boolean;
}

interface IState {
  activeReport?: DataTypes.Report;
  loadingReport?: boolean;
  selectedAreaId: string | null;
  selectedQualitativeId: string | null;
  selectedQuantitativeId: string | null;
}

class UxrShare extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      loadingReport: false,
      selectedAreaId: null,
      selectedQualitativeId: null,
      selectedQuantitativeId: null,
    };
  }

  componentDidMount = () => {
    this.props.getShareRequest(this.props.computedMatch.params.id);
  };

  private close = () => {
    history.goBack();
  };

  private setPageAction = (action: PageActions) => {
    const { uxr, account } = this.props;

    // Show Select Insights
    if (action === PageActions.SELECT_INSIGHTS) {
      if (uxr) {
        this.props.showModal(Modals.SelectInsights, {
          className: "SelectInsights",
          uxr: uxr,
          onSubmit: this.updateInsights,
        });
      }
    }

    // Share Case Study Config
    if (action === PageActions.SHARE) {
      if (uxr && account) {
        this.props.showModal(Modals.Share, {
          className: "Share",
          uxr: uxr,
          account: account,
          onSubmit: this.updateShare,
        });
      }
    }

    if (action === PageActions.SELECT_QUANTITATIVE_DATA) {
      if (uxr && account) {
        this.props.showModal(Modals.SelectQuantitatives, {
          className: "SelectQuantitatives",
          uxr: uxr,
          account: account,
          onSubmit: this.updateQuantitative,
        });
      }
    }

    if (action === PageActions.SELECT_QUALITATIVE_DATA) {
      if (uxr && account) {
        this.props.showModal(Modals.SelectQualitative, {
          className: "SelectQualitative",
          uxr: uxr,
          account: account,
          onSubmit: this.updateQualitative,
        });
      }
    }

    if (action === PageActions.SELECT_AREA_OF_FOCUS) {
      if (uxr && account) {
        this.props.showModal(Modals.SelectAreaOfFocus, {
          className: "SelectAreaOfFocus",
          uxr: uxr,
          account: account,
          onSubmit: this.updateAreaOfFocus,
        });
      }
    }
  };

  private updateInsights = (selectedInsights: Array<string>) => {
    const { uxr } = this.props;
    if (uxr && uxr?.share) {
      this.props.updateShareRequest(this.props.computedMatch.params.id, {
        share: {
          ...uxr.share,
          insights: selectedInsights,
        },
      });
    }
  };

  private updateQuantitative = (selected: Array<string>) => {
    const { uxr } = this.props;
    if (uxr && uxr?.share) {
      this.props.updateShareRequest(this.props.computedMatch.params.id, {
        share: {
          ...uxr.share,
          quantitatives: selected,
        },
      });
    }
  };

  private updateQualitative = (selected: Array<string>) => {
    const { uxr } = this.props;
    if (uxr && uxr?.share) {
      this.props.updateShareRequest(this.props.computedMatch.params.id, {
        share: {
          ...uxr.share,
          qualitatives: selected,
        },
      });
    }
  };

  private updateAreaOfFocus = (selected: Array<string>) => {
    const { uxr } = this.props;
    if (uxr && uxr?.share) {
      this.props.updateShareRequest(this.props.computedMatch.params.id, {
        share: {
          ...uxr.share,
          area_of_focus: selected,
        },
      });
    }
  };

  private updateShare = (password_protected: boolean, password?: string) => {
    const { uxr } = this.props;

    if (!uxr) {
      return;
    }

    if (password_protected) {
      this.props.updateShareRequest(this.props.computedMatch.params.id, {
        share: {
          ...uxr.share,
          password_protected: password_protected,
          password: password,
        },
      });
      return;
    }

    this.props.updateShareRequest(
      this.props.computedMatch.params.id,
      {
        share: {
          ...uxr.share,
          password_protected: password_protected,
        },
      },
      true
    );
  };

  private selectedArea = () => {
    const { selectedAreaId } = this.state;

    if (selectedAreaId === "pending") {
      return null;
    }

    if (!this.props.uxr?.area_of_focus) {
      return null;
    }

    if (!selectedAreaId) {
      return this.props.uxr?.area_of_focus[0];
    }

    return this.props.uxr?.area_of_focus.filter(
      (area) => area.id === selectedAreaId
    )[0];
  };

  render() {
    const { loading, uxr, account, updating } = this.props;

    if (loading || updating) return null;
    if (!uxr) return null;
    if (!uxr.share)
      return <div>Please wait while we create your case study</div>;

    const selectedInsights = uxr.share.insights || [];
    const selectedAreasOfFocus = uxr.share.area_of_focus || [];

    // set control items
    let controlItems = [];

    if (uxr.insights.length > 0) {
      controlItems.push({
        name: "Insights",
        icon: "fas fa-bolt",
        onClick: () => this.setPageAction(PageActions.SELECT_INSIGHTS),
      });
    }

    if (uxr.area_of_focus.length > 0) {
      controlItems.push({
        name: "Area of focus",
        icon: "fas fa-bookmark",
        onClick: () => this.setPageAction(PageActions.SELECT_AREA_OF_FOCUS),
      });
    }

    return (
      <div className="CaseStudy">
        <div className="header">
          <div className="left">
            <img src="/img/logo_sm.svg" alt="illuminate" className="center" />
          </div>
          {this.props.currentUser?.role === "read-only" ? null : (
            <div className="right">
              <DropdownControl
                component={<i className="fas fa-cog" />}
                controlItems={controlItems}
              />

              <button
                type="button"
                className="btn btn-ctrl"
                onClick={() => this.setPageAction(PageActions.SHARE)}
              >
                <i className="fas fa-share-alt"></i>
              </button>

              <button
                type="button"
                className="btn btn-ctrl"
                onClick={this.close}
              >
                <i className="fas fa-times" />
              </button>
            </div>
          )}
        </div>
        <div className="wrapper">
          <section>
            <div className="content Details">
              <div className="left" style={{ borderRight: "none" }}>
                <h1>{uxr.name}</h1>
                <p>
                  <small>
                    {Helpers.formatDate(uxr.start_date)} -{" "}
                    {Helpers.formatDate(uxr.end_date)}
                  </small>
                </p>
              </div>
            </div>
          </section>

          <section>
            <div className="content Results" style={{ marginBottom: "120px" }}>
              <React.Fragment>
                <div style={{ paddingRight: "50px", width: "100%" }}>
                  <EditorInput
                    content={uxr.description}
                    readOnly={true}
                    onBlur={() => {}}
                  />

                  <hr />

                  <p>Number of Participants: {uxr.participants}</p>

                  {uxr.audiences.length > 0 && (
                    <div
                      className="target"
                      style={{
                        display: "flex",
                        alignItems: "center",
                        marginTop: "10px",
                      }}
                    >
                      <p style={{ marginBottom: 0 }}>Audiences: </p>
                      {uxr.audiences.map((t, idx) => {
                        return (
                          <p
                            key={["aud_", idx].join("_")}
                            style={{
                              marginBottom: 0,
                              marginLeft: "5px",
                              backgroundColor: "#757e8e",
                              padding: "2px 7px",
                              borderRadius: "19px",
                              color: "#fff",
                              fontWeight: 500,
                            }}
                          >
                            {t}
                          </p>
                        );
                      })}
                    </div>
                  )}

                  <div
                    className="target"
                    style={{
                      marginTop: "10px",
                    }}
                  >
                    <p style={{ marginBottom: 0 }}>Audience Criteria: </p>

                    <EditorInput
                      content={uxr.audience_criteria}
                      readOnly={true}
                      onBlur={() => {}}
                    />
                  </div>

                  {uxr.method_kinds.length > 0 && (
                    <div
                      className="target"
                      style={{
                        display: "flex",
                        alignItems: "center",
                        marginTop: "10px",
                      }}
                    >
                      <p style={{ marginBottom: 0 }}>Methods: </p>
                      {uxr.method_kinds.map((t, idx) => {
                        return (
                          <p
                            key={["aud_", idx].join("_")}
                            style={{
                              marginBottom: 0,
                              marginLeft: "5px",
                              backgroundColor: "#757e8e",
                              padding: "2px 7px",
                              borderRadius: "19px",
                              color: "#fff",
                              fontWeight: 500,
                            }}
                          >
                            {t}
                          </p>
                        );
                      })}
                    </div>
                  )}

                  <div style={{ marginTop: "12px" }}>
                    <p>Methodology: </p>

                    <MethodologySelector
                      value={uxr.methodology}
                      onChange={() => {}}
                      disabled={true}
                      noCursor={true}
                    />
                  </div>
                </div>

                {uxr.uploads.length > 0 ? (
                  <div>
                    <Link to={`/uxr/${uxr.id}/images/${uxr.uploads[0].id}`}>
                      <AnnotateImage
                        image={uxr.uploads[0]}
                        disabled={true}
                        annotation={{}}
                      />
                    </Link>
                    {uxr.uploads.length > 1 && (
                      <ul className="ImageIndicator">
                        {uxr.uploads.map((img) => {
                          return <li key={["ow_img_", img.id].join("_")}></li>;
                        })}
                      </ul>
                    )}
                  </div>
                ) : null}
              </React.Fragment>
            </div>
          </section>

          {selectedInsights.length > 0 &&
            uxr.insights &&
            uxr.insights.length > 0 && (
              <section className="bg-blue">
                <div className="content Insights">
                  <h2>Key Insights</h2>
                  <ul>
                    {uxr.insights.map((insight) => {
                      if (!selectedInsights.includes(insight.id)) {
                        return null;
                      }

                      return (
                        <li key={["insight_", insight.id].join("_")}>
                          <div className="insight">
                            <p>{insight.value}</p>
                          </div>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              </section>
            )}

          {selectedAreasOfFocus.length > 0 &&
          uxr.area_of_focus &&
          uxr.area_of_focus.length > 0 ? (
            <section style={{ display: "flex", justifyContent: "center" }}>
              <div className="content">
                <h2
                  style={{
                    paddingBottom: "10px",
                    fontSize: "30px",
                    color: "#2f4760",
                  }}
                >
                  Areas of Focus
                </h2>

                {uxr.area_of_focus
                  .filter((area) => selectedAreasOfFocus.includes(area.id))
                  .map((area, idx) => {
                    return (
                      <div
                        key={area.id}
                        style={{
                          boxShadow: "6px 6px 6px #eaeaea",
                          backgroundColor: "#fff",
                          border: "1px solid #eaeaea",
                          padding: "16px",
                          marginTop: "12px",
                          borderRadius: "4px",
                        }}
                      >
                        <Collapsible
                          triggerDisabled={
                            uxr.supporting_datas.filter(
                              (data) => data.area_of_focus?.id === area.id
                            ).length === 0
                          }
                          trigger={
                            <div>
                              <h4 style={{ fontSize: "20px", fontWeight: 400 }}>
                                {area.theme}
                              </h4>
                              <EditorInput
                                content={area?.explanation}
                                readOnly={true}
                                onBlur={() => {}}
                              />

                              {uxr.supporting_datas.filter(
                                (data) => data.area_of_focus?.id === area.id
                              ).length > 0 ? (
                                <p
                                  style={{
                                    textAlign: "center",
                                    marginTop: "16px",
                                  }}
                                >
                                  View Data
                                </p>
                              ) : null}
                            </div>
                          }
                          triggerWhenOpen={
                            <div>
                              <h4 style={{ fontSize: "20px", fontWeight: 400 }}>
                                {area.theme}
                              </h4>
                              <EditorInput
                                content={area?.explanation}
                                readOnly={true}
                                onBlur={() => {}}
                              />
                              <p
                                style={{
                                  textAlign: "center",
                                  marginTop: "16px",
                                }}
                              >
                                Hide Data
                              </p>
                            </div>
                          }
                          triggerClassName="cursor--pointer"
                          triggerOpenedClassName="cursor--pointer"
                        >
                          <React.Fragment>
                            <hr />

                            <Carousel showIndicators={false} showThumbs={false}>
                              {uxr.supporting_datas &&
                                uxr.supporting_datas
                                  .filter(
                                    (data) => data.area_of_focus?.id === area.id
                                  )
                                  .map((data) => (
                                    <React.Fragment key={data.id}>
                                      <div
                                        style={{
                                          display: "flex",
                                          minHeight: "200px",
                                        }}
                                      >
                                        {data.uploads.length > 0 && (
                                          <div
                                            style={{
                                              width: "50%",
                                              minWidth: "50%",
                                            }}
                                          >
                                            <Link
                                              to={`/uxr/${area.id}/supporting_data/${data.id}/images/${data.uploads[0].id}`}
                                            >
                                              <AnnotateImage
                                                image={data.uploads[0]}
                                                disabled={true}
                                                annotation={{}}
                                              />
                                            </Link>
                                            {data.uploads.length > 1 && (
                                              <ul className="ImageIndicator">
                                                {data.uploads.map((img) => {
                                                  return (
                                                    <li
                                                      key={[
                                                        "ow_img_",
                                                        img.id,
                                                      ].join("_")}
                                                    ></li>
                                                  );
                                                })}
                                              </ul>
                                            )}
                                          </div>
                                        )}

                                        <div
                                          style={{
                                            marginLeft: "24px",
                                            display: "flex",
                                            flexDirection: "column",
                                            justifyContent: "space-around",
                                            alignItems: "flex-start",
                                            paddingRight: "12px",
                                          }}
                                        >
                                          <h4
                                            style={{
                                              fontSize: "20px",
                                              fontWeight: 400,
                                            }}
                                          >
                                            Supporting Data
                                          </h4>

                                          <label
                                            style={{
                                              fontSize: "12px",
                                              fontWeight: 500,
                                              color: "#2f4760",
                                            }}
                                          >
                                            {data.sumarized_data}
                                          </label>

                                          <EditorInput
                                            content={data?.explanation}
                                            readOnly={true}
                                            onBlur={() => {}}
                                          />

                                          <p>
                                            Data Type:{" "}
                                            {data.type.replace(
                                              "Uxr::SupportingData::",
                                              ""
                                            )}
                                          </p>
                                        </div>
                                      </div>
                                      <div
                                        style={{
                                          width: "100%",
                                          height: "24px",
                                        }}
                                      ></div>
                                    </React.Fragment>
                                  ))}
                            </Carousel>
                          </React.Fragment>
                        </Collapsible>
                      </div>
                    );
                  })}
              </div>
            </section>
          ) : null}

          <section
            style={{
              display: "flex",
              justifyContent: "center",
              paddingBottom: "52px",
            }}
          >
            <div className="content">
              <h2 style={{ paddingBottom: "10px" }}>For Reference</h2>

              {account?.settings.teams && uxr.teams && uxr.teams.length > 0 && (
                <div
                  className="target"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginTop: "10px",
                  }}
                >
                  <label style={{ marginBottom: 0 }}>Teams: </label>
                  {uxr.teams.map((t, idx) => {
                    return (
                      <p
                        key={["aud_", idx].join("_")}
                        style={{
                          marginBottom: 0,
                          marginLeft: "5px",
                          backgroundColor: "#757e8e",
                          padding: "2px 7px",
                          borderRadius: "19px",
                          color: "#fff",
                        }}
                      >
                        {t}
                      </p>
                    );
                  })}
                </div>
              )}

              {account?.settings.brands && uxr.brands && uxr.brands.length > 0 && (
                <div
                  className="target"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginTop: "10px",
                  }}
                >
                  <label style={{ marginBottom: 0 }}>Brands:</label>
                  {uxr.brands.map((t, idx) => {
                    return (
                      <p
                        key={["aud_", idx].join("_")}
                        style={{
                          marginBottom: 0,
                          marginLeft: "5px",
                          backgroundColor: "#757e8e",
                          padding: "2px 7px",
                          borderRadius: "19px",
                          color: "#fff",
                        }}
                      >
                        {t}
                      </p>
                    );
                  })}
                </div>
              )}

              {account?.settings.channels &&
                uxr.channels &&
                uxr.channels.length > 0 && (
                  <div
                    className="target"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginTop: "10px",
                    }}
                  >
                    <label style={{ marginBottom: 0 }}>Channels:</label>
                    {uxr.channels.map((t, idx) => {
                      return (
                        <p
                          key={["aud_", idx].join("_")}
                          style={{
                            marginBottom: 0,
                            marginLeft: "5px",
                            backgroundColor: "#757e8e",
                            padding: "2px 7px",
                            borderRadius: "19px",
                            color: "#fff",
                          }}
                        >
                          {t}
                        </p>
                      );
                    })}
                  </div>
                )}

              {uxr.pages.length > 0 && (
                <div
                  className="target"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginTop: "10px",
                  }}
                >
                  <label style={{ marginBottom: 0 }}>Pages:</label>
                  {uxr.pages.map((t, idx) => {
                    return (
                      <p
                        key={["aud_", idx].join("_")}
                        style={{
                          marginBottom: 0,
                          marginLeft: "5px",
                          backgroundColor: "#757e8e",
                          padding: "2px 7px",
                          borderRadius: "19px",
                          color: "#fff",
                        }}
                      >
                        {t}
                      </p>
                    );
                  })}
                </div>
              )}

              {uxr.devices.length > 0 && (
                <div
                  className="target"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginTop: "10px",
                  }}
                >
                  <label style={{ marginBottom: 0 }}>Devices</label>
                  {uxr.devices.map((t, idx) => {
                    return (
                      <p
                        key={["aud_", idx].join("_")}
                        style={{
                          marginBottom: 0,
                          marginLeft: "5px",
                          backgroundColor: "#757e8e",
                          padding: "2px 7px",
                          borderRadius: "19px",
                          color: "#fff",
                        }}
                      >
                        {t}
                      </p>
                    );
                  })}
                </div>
              )}

              {uxr.tags.length > 0 && (
                <div
                  className="target"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginTop: "10px",
                  }}
                >
                  <label style={{ marginBottom: 0 }}>Tags:</label>
                  {uxr.tags.map((t, idx) => {
                    return (
                      <p
                        key={["aud_", idx].join("_")}
                        style={{
                          marginBottom: 0,
                          marginLeft: "5px",
                          backgroundColor: "#757e8e",
                          padding: "2px 7px",
                          borderRadius: "19px",
                          color: "#fff",
                        }}
                      >
                        {t}
                      </p>
                    );
                  })}
                </div>
              )}
            </div>
          </section>

          {uxr.documents?.length > 0 && (
            <section style={{ paddingBottom: "60px" }}>
              <div className="content Analysis">
                <h2 style={{ fontWeight: 200 }}>Attachments</h2>

                <ul>
                  {uxr.documents.map((upload: any) => (
                    <li key={upload.id}>
                      <a
                        href={upload.url}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {upload.name}
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
            </section>
          )}
        </div>
      </div>
    );
  }
}

const loadingSelector = createLoadingSelector(["@@uxr/GET_SHARE"]);
const updatingSelector = createLoadingSelector(["@@uxr/UPDATE_SHARE"]);

const mapStateToProps = ({ router, app, account, uxr }: ApplicationState) => ({
  router: router,
  account: account,
  loading: loadingSelector(app.requests),
  uxr,
  updating: updatingSelector(app.requests),
});

const mapDispatchToProps = {
  showModal,
  getShareRequest,
  updateShareRequest,
};

const connectedPage = connect(
  mapStateToProps,
  mapDispatchToProps
)(AppPage(UxrShare));

export { connectedPage as UxrShare };
