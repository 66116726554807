export interface ApiGetOptions {
  starting_after?: string;
  ending_before?: string;
  limit?: number;
  sort?: string;
  query?: string | string[] | null | undefined;
}

export class ApiResponse {
  statusCode: number;
  body: any;

  constructor(statusCode: number, body: any) {
    this.statusCode = statusCode;
    this.body = body;
  }
}

export class ApiError extends Error {
  statusCode: number;
  message: any;
  body: any;

  constructor(message: any, statusCode: number, body: any) {
    super(message);
    this.name = 'ApiError';
    this.statusCode = statusCode;
    this.message = message;
    this.body = body;
  }
}

export class ApiSystemError extends Error {
  statusCode: number;
  message: any;
  body: any;

  constructor(message: any, statusCode: number, body: any) {
    super(message);
    this.name = 'ApiSystemError';
    this.statusCode = statusCode;
    this.message = message;
    this.body = body;
  }
}
