export enum ActionTypes {
  INIT_UXR_CREATE_REQUEST = "@@uxr/INIT_UXR_CREATE_REQUEST",
  INIT_UXR_CREATE_SUCCESS = "@@uxr/INIT_UXR_CREATE_SUCCESS",
  INIT_UXR_CREATE_FAILURE = "@@uxr/INIT_UXR_CREATE_FAILURE",

  CREATE_UXR_REQUEST = "@@uxr/CREATE_UXR_REQUEST",
  CREATE_UXR_SUCCESS = "@@uxr/CREATE_UXR_SUCCESS",
  CREATE_UXR_FAILURE = "@@uxr/CREATE_UXR_FAILURE",

  UPDATE_UXR_REQUEST = "@@uxr/UPDATE_UXR_REQUEST",
  UPDATE_UXR_SUCCESS = "@@uxr/UPDATE_UXR_SUCCESS",
  UPDATE_UXR_FAILURE = "@@uxr/UPDATE_UXR_FAILURE",

  DELETE_UXR_REQUEST = "@@uxr/DELETE_UXR_REQUEST",
  DELETE_UXR_SUCCESS = "@@uxr/DELETE_UXR_SUCCESS",
  DELETE_UXR_FAILURE = "@@uxr/DELETE_UXR_FAILURE",

  GET_UXRS_REQUEST = "@@uxr/GET_UXRS_REQUEST",
  GET_UXRS_SUCCESS = "@@uxr/GET_UXRS_SUCCESS",
  GET_UXRS_FAILURE = "@@uxr/GET_UXRS_FAILURE",

  SHOW_UXR_REQUEST = "@@uxr/SHOW_UXR_REQUEST",
  SHOW_UXR_SUCCESS = "@@uxr/SHOW_UXR_SUCCESS",
  SHOW_UXR_FAILURE = "@@uxr/SHOW_UXR_ERROR",

  ARCHIVE_UXR_REQUEST = "@@uxr/ARCHIVE_UXR_REQUEST",
  ARCHIVE_UXR_SUCCESS = "@@uxr/ARCHIVE_UXR_SUCCESS",
  ARCHIVE_UXR_FAILURE = "@@uxr/ARCHIVE_UXR_FAILURE",

  CLEAR_UXR_REQUEST = "@@uxr/CLEAR_UXR_REQUEST",
  CLEAR_UXR_SUCCESS = "@@uxr/CLEAR_UXR_SUCCESS",
  CLEAR_UXR_FAILURE = "@@uxr/CLEAR_UXR_FAILURE",

  REORDER_REQUEST = "@@uxr/REORDER_REQUEST",
  REORDER_SUCCESS = "@@uxr/REORDER_SUCCESS",
  REORDER_FAILURE = "@@uxr/REORDER_FAILURE",

  ADD_IMAGE_REQUEST = "@@uxr/ADD_IMAGE_REQUEST",
  ADD_IMAGE_SUCCESS = "@@uxr/ADD_IMAGE_SUCCESS",
  ADD_IMAGE_FAILURE = "@@uxruADD_IMAGE_FAILURE",

  DELETE_UPLOAD_REQUEST = "@@uxr/DELETE_UPLOAD_REQUEST",
  DELETE_UPLOAD_SUCCESS = "@@uxr/DELETE_UPLOAD_SUCCESS",
  DELETE_UPLOAD_FAILURE = "@@uxr/DELETE_UPLOAD_FAILURE",

  ADD_AREA_OF_FOCUS_REQUEST = "@@uxr/ADD_AREA_OF_FOCUS_REQUEST",
  ADD_AREA_OF_FOCUS_SUCCESS = "@@uxr/ADD_AREA_OF_FOCUS_SUCCESS",
  ADD_AREA_OF_FOCUS_FAILURE = "@@uxr/ADD_AREA_OF_FOCUS_FAILURE",

  UPDATE_AREA_OF_FOCUS_REQUEST = "@@uxr/UPDATE_AREA_OF_FOCUS_REQUEST",
  UPDATE_AREA_OF_FOCUS_SUCCESS = "@@uxr/UPDATE_AREA_OF_FOCUS_SUCCESS",
  UPDATE_AREA_OF_FOCUS_FAILURE = "@@uxr/UPDATE_AREA_OF_FOCUS_FAILURE",

  DELETE_AREA_OF_FOCUS_REQUEST = "@@uxr/DELETE_AREA_OF_FOCUS_REQUEST",
  DELETE_AREA_OF_FOCUS_SUCCESS = "@@uxr/DELETE_AREA_OF_FOCUS_SUCCESS",
  DELETE_AREA_OF_FOCUS_FAILURE = "@@uxr/DELETE_AREA_OF_FOCUS_FAILURE",

  ADD_SUPPORTING_DATA_REQUEST = "@@uxr/ADD_SUPPORTING_DATA_REQUEST",
  ADD_SUPPORTING_DATA_SUCCESS = "@@uxr/ADD_SUPPORTING_DATA_SUCCESS",
  ADD_SUPPORTING_DATA_FAILURE = "@@uxr/ADD_SUPPORTING_DATA_FAILURE",

  UPDATE_SUPPORTING_DATA_REQUEST = "@@uxr/UPDATE_SUPPORTING_DATA_REQUEST",
  UPDATE_SUPPORTING_DATA_SUCCESS = "@@uxr/UPDATE_SUPPORTING_DATA_SUCCESS",
  UPDATE_SUPPORTING_DATA_FAILURE = "@@uxr/UPDATE_SUPPORTING_DATA_FAILURE",

  DELETE_SUPPORTING_DATA_REQUEST = "@@uxr/DELETE_SUPPORTING_DATA_REQUEST",
  DELETE_SUPPORTING_DATA_SUCCESS = "@@uxr/DELETE_SUPPORTING_DATA_SUCCESS",
  DELETE_SUPPORTING_DATA_FAILURE = "@@uxr/DELETE_SUPPORTING_DATA_FAILURE",

  ADD_SUPPORTING_DATA_IMAGE_SUCCESS = "@@uxr/ADD_SUPPORTING_DATA_IMAGE_SUCCESS",
  ADD_SUPPORTING_DATA_IMAGE_FAILURE = "@@uxr/ADD_SUPPORTING_DATA_IMAGE_FAILURE",
  ADD_SUPPORTING_DATA_IMAGE_REQUEST = "@@uxr/ADD_SUPPORTING_DATA_IMAGE_REQUEST",

  DELETE_SUPPORTING_DATA_UPLOAD_REQUEST = "@@uxr/DELETE_SUPPORTING_DATA_UPLOAD_REQUEST",
  DELETE_SUPPORTING_DATA_UPLOAD_SUCCESS = "@@uxr/DELETE_SUPPORTING_DATA_UPLOAD_SUCCESS",
  DELETE_SUPPORTING_DATA_UPLOAD_FAILURE = "@@uxr/DELETE_SUPPORTING_DATA_UPLOAD_FAILURE",

  LINK_INSIGHTS_REQUEST = "@@uxr/LINK_INSIGHTS_REQUEST",
  LINK_INSIGHTS_SUCCESS = "@@uxr/LINK_INSIGHTS_SUCCESS",
  LINK_INSIGHTS_FAILURE = "@@uxr/LINK_INSIGHTS_FAILURE",

  UNLINK_INSIGHTS_REQUEST = "@@uxr/UNLINK_INSIGHTS_REQUEST",
  UNLINK_INSIGHTS_SUCCESS = "@@uxr/UNLINK_INSIGHTS_SUCCESS",
  UNLINK_INSIGHTS_FAILURE = "@@uxr/UNLINK_INSIGHTS_FAILURE",

  GET_SHARE_REQUEST = "@@uxr/GET_SHARE_REQUEST",
  GET_SHARE_SUCCESS = "@@uxr/GET_SHARE_SUCCESS",
  GET_SHARE_FAILURE = "@@uxr/GET_SHARE_FAILURE",

  UPDATE_SHARE_REQUEST = "@@uxr/UPDATE_SHARE_REQUEST",
  UPDATE_SHARE_SUCCESS = "@@uxr/UPDATE_SHARE_SUCCESS",
  UPDATE_SHARE_FAILURE = "@@uxr/UPDATE_SHARE_FAILURE",

  GET_SHARE_SHARE_REQUEST = "@@uxr/GET_SHARE_SHARE_REQUEST",
  GET_SHARE_SHARE_SUCCESS = "@@uxr/GET_SHARE_SHARE_SUCCESS",
  GET_SHARE_SHARE_FAILURE = "@@uxr/GET_SHARE_SHARE_FAILURE",

  UPDATE_UPLOAD_REQUEST = "@@uxr/UPDATE_UPLOAD_REQUEST",
  UPDATE_UPLOAD_SUCCESS = "@@uxr/UPDATE_UPLOAD_SUCCESS",
  UPDATE_UPLOAD_FAILURE = "@@uxr/UPDATE_UPLOAD_FAILURE",

  ADD_UPLOAD_COMMENT_REQUEST = "@@uxr/ADD_UPLOAD_COMMENT_REQUEST",
  ADD_UPLOAD_COMMENT_SUCCESS = "@@uxr/ADD_UPLOAD_COMMENT_SUCCESS",
  ADD_UPLOAD_COMMENT_FAILURE = "@@uxr/ADD_UPLOAD_COMMENT_FAILURE",

  DELETE_UPLOAD_COMMENT_REQUEST = "@@uxr/DELETE_UPLOAD_COMMENT_REQUEST",
  DELETE_UPLOAD_COMMENT_SUCCESS = "@@uxr/DELETE_UPLOAD_COMMENT_SUCCESS",
  DELETE_UPLOAD_COMMENT_FAILURE = "@@uxr/DELETE_UPLOAD_COMMENT_FAILURE",

  UPDATE_SUPPORTING_DATA_UPLOAD_REQUEST = "@@uxr/UPDATE_SUPPORTING_DATA_UPLOAD_REQUEST",
  UPDATE_SUPPORTING_DATA_UPLOAD_SUCCESS = "@@uxr/UPDATE_SUPPORTING_DATA_UPLOAD_SUCCESS",
  UPDATE_SUPPORTING_DATA_UPLOAD_FAILURE = "@@uxr/UPDATE_SUPPORTING_DATA_UPLOAD_FAILURE",

  DELETE_SUPPORTING_DATA_UPLOAD_COMMENT_REQUEST = "@@uxr/DELETE_SUPPORTING_DATA_UPLOAD_COMMENT_REQUEST",
  DELETE_SUPPORTING_DATA_UPLOAD_COMMENT_SUCCESS = "@@uxr/DELETE_SUPPORTING_DATA_UPLOAD_COMMENT_SUCCESS",
  DELETE_SUPPORTING_DATA_UPLOAD_COMMENT_FAILURE = "@@uxr/DELETE_SUPPORTING_DATA_UPLOAD_COMMENT_FAILURE",

  ADD_SUPPORTING_DATA_UPLOAD_COMMENT_REQUEST = "@@uxr/ADD_SUPPORTING_DATA_UPLOAD_COMMENT_REQUEST",
  ADD_SUPPORTING_DATA_UPLOAD_COMMENT_SUCCESS = "@@uxr/ADD_SUPPORTING_DATA_UPLOAD_COMMENT_SUCCESS",
  ADD_SUPPORTING_DATA_UPLOAD_COMMENT_FAILURE = "@@uxr/ADD_SUPPORTING_DATA_UPLOAD_COMMENT_FAILURE",

  ADD_DOCUMENT_SUCCESS = "@@uxr/ADD_DOCUMENT_SUCCESS",
  ADD_DOCUMENT_FAILURE = "@@uxr/ADD_DOCUMENT_FAILURE",
  ADD_DOCUMENT_REQUEST = "@@uxr/ADD_DOCUMENT_REQUEST",

  DELETE_DOCUMENT_REQUEST = "@@uxr/DELETE_DOCUMENT_REQUEST",
  DELETE_DOCUMENT_SUCCESS = "@@uxr/DELETE_DOCUMENT_SUCCESS",
  DELETE_DOCUMENT_FAILURE = "@@uxr/DELETE_DOCUMENT_FAILURE",

  UPLOAD_INSIGHTS_REQUEST = "@@uxr/UPLOAD_INSIGHTS_REQUEST",
  UPLOAD_INSIGHTS_SUCCESS = "@@uxr/UPLOAD_INSIGHTS_SUCCESS",
  UPLOAD_INSIGHTS_FAILURE = "@@uxr/UPLOAD_INSIGHTS_FAILURE"
}
