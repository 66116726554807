import { ApiClient } from "./client/api_client";

export type ProgramGoalPayload = {
  startsAt: Date;
  endsAt: Date;
};

class ProgramGoalTarget {
  static async create(programGoalId: string, body: ProgramGoalPayload) {
    return await ApiClient.post(
      `/v1/account/program_goal/${programGoalId}/program_goal_target`,
      body
    );
  }

  static async destroy(programGoalId: string, targetId: string) {
    return await ApiClient.delete(
      `/v1/account/program_goal/${programGoalId}/program_goal_target/${targetId}`
    );
  }
}

export { ProgramGoalTarget };
