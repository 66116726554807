import React, { Component } from 'react';
import { connect } from 'react-redux';
import { ApplicationState } from 'store/types';

interface IProps {
  router: any;
}

interface IState {}

class TodayBoardLoading extends Component<IProps, IState> {
  render() {
    return <div className="flex fcol-3 column">
      <div className="text_placeholder"></div>

      <ul className="list_placeholder">
        <li></li>
        <li></li>
        <li></li>
      </ul>

      <ul className="list_placeholder">
        <li></li>
        <li></li>
        <li></li>
      </ul>

      <ul className="list_placeholder">
        <li></li>
        <li></li>
        <li></li>
      </ul>
    </div>;
  }
}

const mapStateToProps = ({ router }: ApplicationState) => ({
  router,
});

const mapDispatchToProps = {
};

const connectedPage = connect(mapStateToProps, mapDispatchToProps)(TodayBoardLoading);

export { connectedPage as TodayBoardLoading };