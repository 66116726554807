import React, { Component } from 'react';
import { PublicPage, PublicPageProps } from 'comps/pages';
import { appConfig } from 'config/app';

interface IProps extends PublicPageProps {}

class PageNotFound extends Component<IProps> {
  render() {
    return (
      <React.Fragment>
        <div className="content">
          <div className="logo">
            <img src="/img/logo.png" alt="illuminate" />
          </div>
          <div className="form">
            <h1>Hmmm, something went wrong.</h1>
            <p className="mb-4">Please be sure the url was typed correctly. If it was, either this page doesn’t exist or is having an issue. </p>
            <p>
              In the meantime, please check out our{' '}
              <a href={appConfig.HELP_URL} target="_blank" rel="noopener noreferrer">
                Help Center.
              </a>
            </p>
          </div>
        </div>
        <div className="aside"></div>
      </React.Fragment>
    );
  }
}

const page = PublicPage(PageNotFound);

export { page as PageNotFound };
