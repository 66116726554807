import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import * as API from "../../api";
import * as Actions from "./actions";
import { ActionTypes } from "./types";

function* getInsightsTraits(action: any) {
  try {
    // @ts-ignore
    const res = yield call(API.Insights.traits);

    yield put(Actions.getInsightsTraitsSuccess(res.body));
  } catch (error: any) {
    yield put(Actions.getInsightsTraitsError(error));
  }
}

function* createInsightsTraits(action: any) {
  try {
    const { insightId, traitName, values } = action.payload;

    // @ts-ignore
    const res = yield call(
      API.Insights.addTraits,
      insightId,
      traitName,
      values
    );

    yield put(Actions.createInsightsTraitsSuccess(res.body));
    yield put(Actions.getInsightsTraitRequestSaga());
  } catch (error: any) {
    yield put(Actions.getInsightsTraitsError(error));
  }
}

function* watchGetInsightsTraits() {
  yield takeEvery(ActionTypes.GET_INSIGHTS_TRAITS_REQUEST, getInsightsTraits);
}

function* watchCreateInsightsTraits() {
  yield takeEvery(
    ActionTypes.CREATE_INSIGHTS_TRAITS_REQUEST,
    createInsightsTraits
  );
}

function* insightsTraitsSaga() {
  yield all([fork(watchGetInsightsTraits), fork(watchCreateInsightsTraits)]);
}

export default insightsTraitsSaga;
