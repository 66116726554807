import React from 'react';
import { PublicPage } from 'comps/pages';
import { Redirect } from 'react-router-dom';
import { Helpers } from 'utils';
import { AsideImage } from 'ui';
interface IProps {
  location: { search: any };
}

const SignUpSuccess = (props: IProps) => {
  const url = Helpers.getJsonFromUrl(props.location.search);
  if (!url.email) return <Redirect to="/404" />;

  return (
    <React.Fragment>
      <div className="content">
        <div className="logo">
          <img src="/img/logo.png" alt="illuminate" />
        </div>
        <div className="form">
          <h1>Got it! Please go check your inbox.</h1>
          <p>
            We just sent a verification message to <strong>{url.email}</strong>. Click the link in your email to activate your new account.
          </p>
          <p className="mt-4">
            Didn't receive an email? We can <a href="/resend_request">send it again.</a>
          </p>
        </div>
      </div>
      <div className="aside">
        <AsideImage />
      </div>
    </React.Fragment>
  );
};

const page = PublicPage(SignUpSuccess);

export { page as SignUpSuccess };
