import React from "react";
import { Form, Input, Select, Button, ValidatorTypes } from "comps/form";
import { CurrencySymbols, ProgramGoal } from "store/types";

interface IProps {
  updateProgramGoalRequest: (id: string, body: any) => void;
  programGoal: ProgramGoal;
  saving: boolean;
}

const DATA_TYPES = ["number", "currency"];

export const UpdateGoal = (props: IProps) => {
  const { programGoal, saving, updateProgramGoalRequest } = props;

  const onSubmit = (valid: boolean, dirty: boolean, data: any) => {
    if (valid) {
      updateProgramGoalRequest(programGoal.id || "", {
        name: data.name.value.toLowerCase(),
        data_type: data.type.value,
        symbol: data.symbol.value,
      });
    }
  };

  return (
    <React.Fragment>
      <div className="header">
        <h3>Update Program Goal</h3>
        <p>Use the form below to update your program goal.</p>
      </div>
      <Form
        onSubmit={onSubmit}
        formFields={{
          name: {
            name: "name",
            value: programGoal.name,
            validator: {
              type: ValidatorTypes.REQUIRED,
              messages: { required: "Enter a valid program goal name" },
            },
          },
          type: { name: "type", value: programGoal.data_type },
          symbol: {
            name: "symbol",
            value: programGoal.symbol || "DOLLAR",
          },
        }}
        FormComponent={({ fields: { name, type, symbol }, onChange }) => (
          <div className="header">
            <div className="form-group">
              <label>Name</label>
              <Input
                field={name}
                className="form-control"
                onChange={(e) => onChange(name, e.target.value)}
              />
            </div>
            <div className="form-group">
              <label>Type</label>
              <Select
                className="SelectInput"
                placeholder="-- Select --"
                field={type}
                options={DATA_TYPES}
                onChange={(value: string) => onChange(type, value)}
              />
            </div>

            {type.value === "currency" ? (
              <div className="form-group">
                <label>Currency symbol</label>
                <Select
                  className="SelectInput currency-select"
                  placeholder="-- Select --"
                  field={symbol}
                  options={Object.keys(CurrencySymbols)}
                  onChange={(value: string) => onChange(symbol, value)}
                  showSymbols={true}
                />
              </div>
            ) : null}

            <div className="cntrl-bar">
              <Button
                className="btn btn-primary"
                text="update program goal"
                isLoading={saving}
              />
            </div>
          </div>
        )}
      />
    </React.Fragment>
  );
};
