export enum ActionTypes {
  INIT_IDEA_BOARD_MANAGEMENT_CREATE_REQUEST = '@@idea_board_management/INIT_IDEA_BOARD_MANAGEMENT_CREATE_REQUEST',
  INIT_IDEA_BOARD_MANAGEMENT_CREATE_SUCCESS = '@@idea_board_management/INIT_IDEA_BOARD_MANAGEMENT_CREATE_SUCCESS',
  INIT_IDEA_BOARD_MANAGEMENT_CREATE_FAILURE = '@@idea_board_management/INIT_IDEA_BOARD_MANAGEMENT_CREATE_FAILURE',

  CREATE_IDEA_BOARD_MANAGEMENT_REQUEST = '@@idea_board_management/CREATE_IDEA_BOARD_MANAGEMENT_REQUEST',
  CREATE_IDEA_BOARD_MANAGEMENT_SUCCESS = '@@idea_board_management/CREATE_IDEA_BOARD_MANAGEMENT_SUCCESS',
  CREATE_IDEA_BOARD_MANAGEMENT_FAILURE = '@@idea_board_management/CREATE_IDEA_BOARD_MANAGEMENT_FAILURE',

  GET_IDEA_BOARDS_MANAGEMENT_REQUEST = '@@idea_board_management/GET_IDEA_BOARDS_MANAGEMENT_REQUEST',
  GET_IDEA_BOARDS_MANAGEMENT_SUCCESS = '@@idea_board_management/GET_IDEA_BOARDS_MANAGEMENT_SUCCESS',
  GET_IDEA_BOARDS_MANAGEMENT_FAILURE = '@@idea_board_management/GET_IDEA_BOARDS_MANAGEMENT_FAILURE',

  CLEAR_IDEA_BOARDS_MANAGEMENT = '@@idea_board_management/CLEAR_IDEA_BOARDS_MANAGEMENT',

  DELETE_IDEA_BOARD_MANAGEMENT_REQUEST = "@@idea_board_management/DELETE_IDEA_BOARD_MANAGEMENT_REQUEST",
  DELETE_IDEA_BOARD_MANAGEMENT_SUCCESS = "@@idea_board_management/DELETE_IDEA_BOARD_MANAGEMENT_SUCCESS",
  DELETE_IDEA_BOARD_MANAGEMENT_FAILURE = "@@idea_board_management/DELETE_IDEA_BOARD_MANAGEMENT_FAILURE",

  UPDATE_IDEA_BOARD_MANAGEMENT_REQUEST = "@@idea_board_management/UPDATE_IDEA_BOARD_MANAGEMENT_REQUEST",
  UPDATE_IDEA_BOARD_MANAGEMENT_SUCCESS = "@@idea_board_management/UPDATE_IDEA_BOARD_MANAGEMENT_SUCCESS",
  UPDATE_IDEA_BOARD_MANAGEMENT_FAILURE = "@@idea_board_management/UPDATE_IDEA_BOARD_MANAGEMENT_FAILURE",

  SHOW_IDEA_BOARDS_REQUEST = "@@idea_board_management/SHOW_IDEA_BOARDS_REQUEST",
  SHOW_IDEA_BOARDS_SUCCESS = "@@idea_board_management/SHOW_IDEA_BOARDS_SUCCESS",
  SHOW_IDEA_BOARDS_FAILURE = "@@idea_board_management/SHOW_IDEA_BOARDS_FAILURE",

  ARCHIVE_IDEA_BOARD_MANAGEMENT_REQUEST = "@@idea_board_management/ARCHIVE_IDEA_BOARD_MANAGEMENT_REQUEST",
  ARCHIVE_IDEA_BOARD_MANAGEMENT_SUCCESS = "@@idea_board_management/ARCHIVE_IDEA_BOARD_MANAGEMENT_SUCCESS",
  ARCHIVE_IDEA_BOARD_MANAGEMENT_FAILURE = "@@idea_board_management/ARCHIVE_IDEA_BOARD_MANAGEMENT_FAILURE",

  REORDER_REQUEST = "@@idea_board/REORDER_REQUEST",
  REORDER_SUCCESS = "@@idea_board/REORDER_SUCCESS",
  REORDER_FAILURE = "@@idea_board/REORDER_FAILURE",
}
