import { ApiClient } from './client/api_client';

class Application {
  static async index() {
    return await ApiClient.get('/v1/oauth/application');
  }

  static async create(body: any) {
    return await ApiClient.post('/v1/oauth/application', body);
  }

  static async delete(id: string) {
    return await ApiClient.delete(`/v1/oauth/application/${id}`);
  }
}

export { Application };
