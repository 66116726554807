import styled from "styled-components";

export const TargetTable = styled("div")`
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
`;

export const TargetRow = styled("div")`
  display: grid;
  grid-template-columns: 1fr 2fr 3fr 1fr;
  gap: 1rem 1rem;

  p {
    text-align: center;
  }

  .actions {
    display: flex;
    justify-content: flex-end;

    button {
      position: relative;
      top: -2px;
      background-color: transparent;
      color: #eaeaea;
      border: 2px solid #eaeaea;
      border-radius: 4px;
      background-color: #fff;
      padding: 2px 8px;

      transition: all 0.5s;

      &:hover {
        color: #ff5a71;
        border-color: #ff5a71;
      }
    }
  }
`;

export const TargetCurrentProgressContainer = styled("div")<{
  percentage: number;
  expired: boolean;
}>`
  position: relative;
  height: 24px;
  width: 100%;
  background-color: #eaeaea;

  div {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    height: 100%;

    p {
      z-index: 1000;
      position: relative;
      margin-bottom: 0;

      font-weight: bold;
      color: ${(props) =>
        props.expired && props.percentage < 100 ? "#ff5a71" : "#2f4760"};
      font-size: 1rem;
    }
  }
`;

export const TargetCurrentProgressBar = styled("div")<{
  percentage: number;
  expired: boolean;
}>`
  height: 40px;
  width: ${(props) => props.percentage}% !important;
  background-color: ${(props) =>
    props.expired && props.percentage < 100 ? "#ffbdc6" : "#25be6f"};
  position: absolute;
  top: 0;
  left: 0;
`;
