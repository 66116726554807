export enum ActionTypes {
  GET_SETTINGS_OPTIONS_REQUEST = "@@account/GET_SETTINGS_OPTIONS_REQUEST",
  GET_SETTINGS_OPTIONS_SUCCESS = "@@account/GET_SETTINGS_OPTIONS_SUCCESS",
  GET_SETTINGS_OPTIONS_FAILURE = "@@account/GET_SETTINGS_OPTIONS_FAILURE",

  GET_SETTINGS_OPTIONS_AVG_EXPERIMENTS_PER_MONTH_REQUEST = "@@account/GET_SETTINGS_OPTIONS_AVG_EXPERIMENTS_PER_MONTH_REQUEST",
  GET_SETTINGS_OPTIONS_AVG_EXPERIMENTS_PER_MONTH_SUCCESS = "@@account/GET_SETTINGS_OPTIONS_AVG_EXPERIMENTS_PER_MONTH_SUCCESS",
  GET_SETTINGS_OPTIONS_AVG_EXPERIMENTS_PER_MONTH_FAILURE = "@@account/GET_SETTINGS_OPTIONS_AVG_EXPERIMENTS_PER_MONTH_FAILURE",

  GET_SETTINGS_OPTIONS_COE_REQUEST = "@@account/GET_SETTINGS_OPTIONS_COE_REQUEST",
  GET_SETTINGS_OPTIONS_COE_SUCCESS = "@@account/GET_SETTINGS_OPTIONS_COE_SUCCESS",
  GET_SETTINGS_OPTIONS_COE_FAILURE = "@@account/GET_SETTINGS_OPTIONS_COE_FAILURE",

  GET_SETTINGS_OPTIONS_COMPANY_SIZE_REQUEST = "@@account/GET_SETTINGS_OPTIONS_COMPANY_SIZE_REQUEST",
  GET_SETTINGS_OPTIONS_COMPANY_SIZE_SUCCESS = "@@account/GET_SETTINGS_OPTIONS_COMPANY_SIZE_SUCCESS",
  GET_SETTINGS_OPTIONS_COMPANY_SIZE_FAILURE = "@@account/GET_SETTINGS_OPTIONS_COMPANY_SIZE_FAILURE",

  GET_SETTINGS_OPTIONS_COMPANY_EXPERIMENTS_WIN_PER_MONTH_REQUEST = "@@account/GET_SETTINGS_OPTIONS_COMPANY_EXPERIMENTS_WIN_PER_MONTH_REQUEST",
  GET_SETTINGS_OPTIONS_COMPANY_EXPERIMENTS_WIN_PER_MONTH_SUCCESS = "@@account/GET_SETTINGS_OPTIONS_COMPANY_EXPERIMENTS_WIN_PER_MONTH_SUCCESS",
  GET_SETTINGS_OPTIONS_COMPANY_EXPERIMENTS_WIN_PER_MONTH_FAILURE = "@@account/GET_SETTINGS_OPTIONS_COMPANY_EXPERIMENTS_WIN_PER_MONTH_FAILURE",

  GET_SETTINGS_OPTIONS_INDUSTRY_REQUEST = "@@account/GET_SETTINGS_OPTIONS_INDUSTRY_REQUEST",
  GET_SETTINGS_OPTIONS_INDUSTRY_SUCCESS = "@@account/GET_SETTINGS_OPTIONS_INDUSTRY_SUCCESS",
  GET_SETTINGS_OPTIONS_INDUSTRY_FAILURE = "@@account/GET_SETTINGS_OPTIONS_INDUSTRY_FAILURE",

  GET_SETTINGS_OPTIONS_KIND_OF_TEST_REQUEST = "@@account/GET_SETTINGS_OPTIONS_KIND_OF_TEST_REQUEST",
  GET_SETTINGS_OPTIONS_KIND_OF_TEST_SUCCESS = "@@account/GET_SETTINGS_OPTIONS_KIND_OF_TEST_SUCCESS",
  GET_SETTINGS_OPTIONS_KIND_OF_TEST_FAILURE = "@@account/GET_SETTINGS_OPTIONS_KIND_OF_TEST_FAILURE",

  GET_SETTINGS_OPTIONS_KIND_OF_TOOL_REQUEST = "@@account/GET_SETTINGS_OPTIONS_KIND_OF_TOOL_REQUEST",
  GET_SETTINGS_OPTIONS_KIND_OF_TOOL_SUCCESS = "@@account/GET_SETTINGS_OPTIONS_KIND_OF_TOOL_SUCCESS",
  GET_SETTINGS_OPTIONS_KIND_OF_TOOL_FAILURE = "@@account/GET_SETTINGS_OPTIONS_KIND_OF_TOOL_FAILURE",

  GET_SETTINGS_OPTIONS_PRIMARY_ANALYTICS_TOOL_REQUEST = "@@account/GET_SETTINGS_OPTIONS_PRIMARY_ANALYTICS_TOOL_REQUEST",
  GET_SETTINGS_OPTIONS_PRIMARY_ANALYTICS_TOOL_SUCCESS = "@@account/GET_SETTINGS_OPTIONS_PRIMARY_ANALYTICS_TOOL_SUCCESS",
  GET_SETTINGS_OPTIONS_PRIMARY_ANALYTICS_TOOL_FAILURE = "@@account/GET_SETTINGS_OPTIONS_PRIMARY_ANALYTICS_TOOL_FAILURE",

  GET_SETTINGS_OPTIONS_PRIMARY_TESTING_TOOL_REQUEST = "@@account/GET_SETTINGS_OPTIONS_PRIMARY_TESTING_TOOL_REQUEST",
  GET_SETTINGS_OPTIONS_PRIMARY_TESTING_TOOL_SUCCESS = "@@account/GET_SETTINGS_OPTIONS_PRIMARY_TESTING_TOOL_SUCCESS",
  GET_SETTINGS_OPTIONS_PRIMARY_TESTING_TOOL_FAILURE = "@@account/GET_SETTINGS_OPTIONS_PRIMARY_TESTING_TOOL_FAILURE",

  GET_SETTINGS_OPTIONS_PROGRAM_TYPE_REQUEST = "@@account/GET_SETTINGS_OPTIONS_PROGRAM_TYPE_REQUEST",
  GET_SETTINGS_OPTIONS_PROGRAM_TYPE_SUCCESS = "@@account/GET_SETTINGS_OPTIONS_PROGRAM_TYPE_SUCCESS",
  GET_SETTINGS_OPTIONS_PROGRAM_TYPE_FAILURE = "@@account/GET_SETTINGS_OPTIONS_PROGRAM_TYPE_FAILURE",

  GET_SETTINGS_OPTIONS_TESTING_CHALLENGE_REQUEST = "@@account/GET_SETTINGS_OPTIONS_TESTING_CHALLENGE_REQUEST",
  GET_SETTINGS_OPTIONS_TESTING_CHALLENGE_SUCCESS = "@@account/GET_SETTINGS_OPTIONS_TESTING_CHALLENGE_SUCCESS",
  GET_SETTINGS_OPTIONS_TESTING_CHALLENGE_FAILURE = "@@account/GET_SETTINGS_OPTIONS_TESTING_CHALLENGE_FAILURE",

  GET_SETTINGS_OPTIONS_TESTING_PROGRAM_REACH_REQUEST = "@@account/GET_SETTINGS_OPTIONS_TESTING_PROGRAM_REACH_REQUEST",
  GET_SETTINGS_OPTIONS_TESTING_PROGRAM_REACH_SUCCESS = "@@account/GET_SETTINGS_OPTIONS_TESTING_PROGRAM_REACH_SUCCESS",
  GET_SETTINGS_OPTIONS_TESTING_PROGRAM_REACH_FAILURE = "@@account/GET_SETTINGS_OPTIONS_TESTING_PROGRAM_REACH_FAILURE",
}
