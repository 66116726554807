import { Reducer } from 'redux';
import { ActionTypes } from './types';

const initialState: any = { multiSelect: false, selected: [] };

const reducer: Reducer<any> = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.SET_IDEA_MULTIPLE_SELECT:
      return { ...state, multiSelect: action.payload.opened, selected: [] };
    case ActionTypes.SELECT_IDEA_SUCCESS:
      const selectedIdea = action.payload.idea;
      const clear = action.payload.clear || false;
      const index = state.selected.findIndex((idea: any) => idea.id === selectedIdea.id);

      let resultingIdeas = clear ? [] : state.selected;

      if (index === -1) {
        return { ...state, selected: [...resultingIdeas, selectedIdea] };
      }

      return {...state, selected: resultingIdeas.filter((idea: any, currentIndex: any) => index !== currentIndex )};
    default: {
      return state;
    }
  }
};

export { reducer as ideaManagementReducer };
