import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import * as API from "../../api";
import * as Actions from "./actions";
import { ActionTypes } from "./types";
import { ActionTypes as AccountActionTypes } from "../account/types";
import { AuthService } from "utils/auth";
import * as AppTypes from "../app/types";

enum SignUpType {
  EMAIL = "email",
  GOOGLE = "google",
}

function* loginUser(action: any) {
  try {
    // @ts-ignore
    const res = yield call(
      API.User.login,
      action.payload.slug,
      action.payload.email,
      action.payload.password
    );
    AuthService.logIn(res.body.jwt);
    yield put(Actions.loginSuccess(res.body));
  } catch (error: any) {
    yield put({
      type: AppTypes.App.ALERT_SHOW,
      content: "Email/password incorrect",
      error: true,
    });
    yield put(Actions.loginFailure(error));
  }
}

function* forgotPwd(action: any) {
  try {
    // @ts-ignore
    const res = yield call(
      API.User.forgotPwd,
      action.payload.slug,
      action.payload.email
    );
    yield put(Actions.forgotPwdSuccess(res.body));
  } catch (error: any) {
    yield put(Actions.forgotPwdFailure(error));
  }
}

function* resetPwd(action: any) {
  try {
    // @ts-ignore
    const res = yield call(
      API.User.resetPwd,
      action.payload.slug,
      action.payload.token,
      action.payload.password
    );
    AuthService.logIn(res.body.jwt);
    yield put(Actions.resetPwdSuccess(res.body));
  } catch (error: any) {
    yield put(Actions.resetPwdFailure(error));
  }
}

function* signupUser(action: any) {
  try {
    // @ts-ignore
    const res = yield call(
      API.SignUp.signup,
      SignUpType.EMAIL,
      action.payload.email
    );
    yield put(Actions.signupSuccess(res.body));
  } catch (error: any) {
    yield put(Actions.signupFailure(error));
  }
}

function* initInvitedGoogleUser(action: any) {
  try {
    // @ts-ignore
    const res = yield call(
      API.SignUp.signup,
      SignUpType.GOOGLE,
      action.payload.email,
      action.payload.firstName,
      action.payload.lastName,
      action.payload.googleId
    );
    yield put(Actions.initInvitedGoogleUserSuccess(res.body));
    window.location.href = `${process.env.REACT_APP_HTTP_PROTO}://${action.payload.account}.${process.env.REACT_APP_BASE_URL}/google/${res.body.token}/invite/${action.payload.invite}`;
  } catch (error: any) {
    yield put(Actions.initInvitedGoogleUserFailure(error));
  }
}
function* signupGoogleUser(action: any) {
  try {
    // @ts-ignore
    const res = yield call(
      API.SignUp.signup,
      SignUpType.GOOGLE,
      action.payload.email,
      action.payload.firstName,
      action.payload.lastName,
      action.payload.googleId
    );
    yield put(Actions.signupGoogleSuccess(res.body));
    window.location.href = `${process.env.REACT_APP_HTTP_PROTO}://www.${process.env.REACT_APP_BASE_URL}/signup/${res.body.token}/activate`;
  } catch (error: any) {
    yield put(Actions.signupGoogleFailure(error));
  }
}

function* loginGoogleUser(action: any) {
  try {
    const { slug, tokenId } = action.payload;
    // @ts-ignore
    const res = yield call(API.User.loginGoogle, slug, tokenId);
    window.location.href = `${process.env.REACT_APP_HTTP_PROTO}://${slug}.${process.env.REACT_APP_BASE_URL}/loading?jwt=${res.body.jwt}`;
    yield put(Actions.loginGoogleSuccess(res.body));
  } catch (error: any) {
    window.location.href = `${process.env.REACT_APP_HTTP_PROTO}://${action.payload.slug}.${process.env.REACT_APP_BASE_URL}/login?failure=true&type=google`;
    yield put(Actions.loginGoogleFailure(error));
  }
}

function* updateUserProfile(action: any) {
  try {
    // @ts-ignore
    const res = yield call(
      API.User.update,
      action.payload.id,
      action.payload.body
    );
    const { jwt } = res.body;
    if (jwt) AuthService.logIn(jwt);
    yield put(Actions.updateProfileSuccess(res.body));
    if (action.payload.body.role) {
      yield put({ type: AppTypes.App.MODAL_HIDE });
      yield put({ type: AccountActionTypes.GET_USERS_REQUEST, payload: {} });
    }
    yield put({
      type: AppTypes.App.ALERT_SHOW,
      content: "User profile updated successfully",
    });
  } catch (error: any) {
    yield put(Actions.updateProfileFailure(error));
  }
}

function* updateUserProfileImage(action: any) {
  try {
    const { file } = action.payload;

    // @ts-ignore
    const res = yield call(API.User.updateImage, file);

    const { jwt } = res.body;
    if (jwt) AuthService.logIn(jwt);
    yield put(Actions.updateProfileImageSuccess(res.body));
    yield put(Actions.updateProfileSuccess(res.body));
    if (action.payload.body.role) {
      yield put({ type: AppTypes.App.MODAL_HIDE });
      yield put({ type: AccountActionTypes.GET_USERS_REQUEST, payload: {} });
    }
    yield put({
      type: AppTypes.App.ALERT_SHOW,
      content: "User profile updated successfully",
    });
  } catch (error: any) {
    yield put(Actions.updateProfileImageFailure(error));
    yield put(Actions.updateProfileFailure(error));
  }
}

function* updateUserPassword(action: any) {
  try {
    // @ts-ignore
    const res = yield call(
      API.User.update,
      action.payload.id,
      action.payload.body
    );
    const { jwt } = res.body;
    if (jwt) AuthService.logIn(jwt);
    yield put(Actions.updatePasswordSuccess(res.body));
    yield put({
      type: AppTypes.App.ALERT_SHOW,
      content: "Password updated successfully",
    });
  } catch (error: any) {
    yield put(Actions.updatePasswordFailure(error));
  }
}

function* updateUserNotifications(action: any) {
  try {
    // @ts-ignore
    const res = yield call(
      API.User.updateNotification,
      action.payload.id,
      action.payload.body
    );
    yield put(Actions.updateNotificationsSuccess(res.body));
    yield put({
      type: AppTypes.App.ALERT_SHOW,
      content: "Notifications updated successfully",
    });
  } catch (error: any) {
    yield put(Actions.updateNotificationsFailure(error));
  }
}

function* createInvitedUser(action: any) {
  try {
    // @ts-ignore
    const res = yield call(
      API.User.createInvitedUser,
      action.payload.id,
      action.payload.user
    );
    AuthService.logIn(res.body.jwt);
    yield put(Actions.createInvitedUserSuccess(res.body));
  } catch (error: any) {
    yield put(Actions.createInvitedUserError(error));
  }
}

function* watchLoginUser() {
  yield takeEvery(ActionTypes.LOGIN_REQUEST, loginUser);
}

function* watchForgotPwd() {
  yield takeEvery(ActionTypes.FORGOT_PWD_REQUEST, forgotPwd);
}

function* watchResetPwd() {
  yield takeEvery(ActionTypes.RESET_PWD_REQUEST, resetPwd);
}

function* watchSignupUser() {
  yield takeEvery(ActionTypes.SIGNUP_REQUEST, signupUser);
}

function* watchInitInvitedGoogleUser() {
  yield takeEvery(
    ActionTypes.INIT_INVITED_GOOGLE_REQUEST,
    initInvitedGoogleUser
  );
}

function* watchSignupGoogleUser() {
  yield takeEvery(ActionTypes.GOOGLE_SIGNUP_REQUEST, signupGoogleUser);
}
function* watchLoginGoogleUser() {
  yield takeEvery(ActionTypes.LOGIN_GOOGLE_REQUEST, loginGoogleUser);
}

function* watchUpdateUserProfile() {
  yield takeEvery(ActionTypes.UPDATE_PROFILE_REQUEST, updateUserProfile);
}

function* watchUpdateUserPassword() {
  yield takeEvery(ActionTypes.UPDATE_PASSWORD_REQUEST, updateUserPassword);
}

function* watchUpdateUserNotifications() {
  yield takeEvery(
    ActionTypes.UPDATE_NOTIFICATIONS_REQUEST,
    updateUserNotifications
  );
}

function* watchCreateInvitedUser() {
  yield takeEvery(ActionTypes.CREATE_INVITED_USER_REQUEST, createInvitedUser);
}

function* watchUpdateUserProfileImage() {
  yield takeEvery(
    ActionTypes.UPDATE_PROFILE_IMAGE_REQUEST,
    updateUserProfileImage
  );
}

function* userSaga() {
  yield all([
    fork(watchLoginUser),
    fork(watchForgotPwd),
    fork(watchResetPwd),
    fork(watchSignupUser),
    fork(watchSignupGoogleUser),
    fork(watchInitInvitedGoogleUser),
    fork(watchLoginGoogleUser),
    fork(watchUpdateUserProfile),
    fork(watchUpdateUserPassword),
    fork(watchUpdateUserNotifications),
    fork(watchCreateInvitedUser),
    fork(watchUpdateUserProfileImage),
  ]);
}

export default userSaga;
