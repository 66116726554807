import React, { Component } from "react";
import { connect } from "react-redux";
import { Helpers } from "utils";
import { Link } from "react-router-dom";
import { ApplicationState } from "store/types";
import * as DataTypes from "store/types";
import { HTTPCodes } from "config/app";
import { clearAppErrors } from "store/app/actions";
import {
  createLoadingSelector,
  createErrorMessageSelector,
} from "store/selectors";
import {
  getShareCaseStudyRequest,
  updateCaseStudyRequest,
} from "store/experiment/actions";
import { AppPage, AppPageProps } from "comps/pages";
import { ExperimentStatusIcon, SelectInput } from "ui";
import { AnnotateImage } from "comps";
import { Report } from "../../app/case_study/comps/report";
import { Form, Input, Button, ValidatorTypes, InputTypes } from "comps/form";
import { AuthService } from "utils/auth";
import "css/CaseStudy.scss";
import { EditorInput } from "ui/editor_input/editor_input";
import { CustomDataViz } from "./custom_data_viz";
import { ProgramGoalsList } from "./program_goal";

interface IProps extends AppPageProps {
  router: any;
  computedMatch: any;
  loading: boolean;
  error: any;
  clearAppErrors: () => void;
  getShareCaseStudyRequest: (
    slug: string,
    uuid: string,
    password?: string
  ) => void;
  updateCaseStudyRequest: (
    id: string,
    body: any,
    disableHideModal?: boolean
  ) => void;
  experiment: DataTypes.Experiment | null;
  account: DataTypes.Account | null;
}

interface IState {
  activeReport?: DataTypes.Report;
  loadingReport?: boolean;
  selectedImages: { [k: string]: number };
}

class ShareCaseStudy extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      loadingReport: false,
      selectedImages: {},
    };
  }

  componentDidMount = () => {
    this.props.clearAppErrors();

    let pwd;
    const sharedPwd = AuthService.getSharePwd();

    if (sharedPwd) {
      const json = JSON.parse(sharedPwd);
      if (json.key === this.props.computedMatch.params.uuid) {
        pwd = json.pwd;
      } else {
        AuthService.clearSharePwd();
      }
    }

    const { account } = this.props;
    if (account) {
      this.props.getShareCaseStudyRequest(
        account.slug,
        this.props.computedMatch.params.uuid,
        pwd || undefined
      );
    }
  };

  private changeReport = (value: any) => {
    this.setState({ loadingReport: true }, () => {
      this.setState({
        ...this.state,
        activeReport: value,
        loadingReport: false,
      });
    });
  };

  private onSubmit = (valid: boolean, dirty: boolean, data: any) => {
    const { account } = this.props;
    this.props.clearAppErrors();

    if (account && valid) {
      AuthService.setSharePwd(
        JSON.stringify({
          key: this.props.computedMatch.params.uuid,
          pwd: data.password.value,
        })
      );
      this.props.getShareCaseStudyRequest(
        account.slug,
        this.props.computedMatch.params.uuid,
        data.password.value
      );
    }
  };

  render() {
    const { loading, experiment, account, error } = this.props;

    if (error) {
      if (error.statusCode === HTTPCodes.FORBIDDEN) {
        return (
          <div className="ShareLogin">
            <div className="body">
              <img src="/img/logo.png" alt="illuminate" />
              <h3>This link is password protected</h3>
              <p>Enter the password below to view</p>
              <Form
                formFields={{
                  password: {
                    name: "password",
                    value: "",
                    validator: {
                      type: ValidatorTypes.REQUIRED,
                      messages: {
                        required:
                          "Please enter a password to view the case study.",
                      },
                    },
                  },
                }}
                onSubmit={this.onSubmit}
                FormComponent={({ fields: { password }, onChange }) => (
                  <div>
                    <div className="form-group">
                      <Input
                        field={password}
                        placeholder="Enter password"
                        type={InputTypes.PASSWORD}
                        className="form-control"
                        onChange={(e) => onChange(password, e.target.value)}
                      />
                    </div>
                    <Button
                      className="btn btn-primary"
                      text="view case study"
                      isLoading={loading}
                    />
                  </div>
                )}
              />
            </div>
          </div>
        );
      }

      if (error.statusCode === HTTPCodes.NOT_FOUND) {
        return (
          <div className="ShareLogin">
            <div className="body">
              <img src="/img/logo.png" alt="illuminate" />
              <h3>The link you have requested is invalid</h3>
              <p>Please check the link and try again</p>
            </div>
          </div>
        );
      }
      return null;
    }

    if (loading) return null;
    if (!experiment) return null;
    if (!experiment.case_study) {
      return <div>Please wait while we create your case study</div>;
    }
    const controlExperience = experiment.experiences.filter(
      (exp) => exp.type === "Experiment::Experience::Control"
    )[0];

    const selectedInsights = experiment.case_study.insights || [];
    const selectedExperiences = experiment.case_study.experiences || [];
    const selectedReports = [
      ...(experiment.case_study.reports || []),
      ...(experiment.case_study.data_vizs || []),
    ];
    const overall_winner = experiment.experiences.find(
      (ex) => ex.overall_winner === true
    );

    let activeReport = this.state?.activeReport;

    const availableReports = selectedReports.map(
      (sr, idx) =>
        experiment.reports.find((r) => r.id === sr) ||
        experiment.custom_data_viz.find((r: any) => (r.id = sr))
    );

    if (!activeReport && selectedReports.length > 0) {
      activeReport = availableReports.find((report) => report?.primary);

      selectedReports.forEach((sr, idx) => {
        if (!activeReport) {
          activeReport = experiment.reports.find(
            (r) => r.id === selectedReports[idx]
          );
        }
      });
    }

    const isActiveReportDataViz =
      experiment?.custom_data_viz
        .map((dv: any) => dv.id)
        .lastIndexOf(activeReport?.id) !== -1;

    return (
      <div className="CaseStudy">
        <div className="header">
          <div className="left">
            <img
              src="/img/logo_sm.svg"
              alt="illuminate"
              className="center mt-4"
            />
          </div>
          <div className="right"></div>
        </div>
        <div className="wrapper">
          <section>
            <div className="content Details">
              <div className="left">
                <ExperimentStatusIcon
                  status={experiment.status}
                  device="desktop"
                />
                <h1>{experiment.name}</h1>
                <p>
                  <small>
                    {Helpers.formatDate(experiment.start_date)} -{" "}
                    {Helpers.formatDate(experiment.end_date)}
                  </small>
                </p>
                <ExperimentStatusIcon
                  status={experiment.status}
                  device="mobile"
                />
              </div>
              <div className="right">
                <div className="targeting">
                  <div className="target">
                    <label>pages</label>
                    {experiment.pages.map((t, idx) => {
                      return <p key={["page_", idx].join("_")}>{t}</p>;
                    })}
                  </div>
                  <div className="target">
                    <label>audiences</label>
                    {experiment.audiences.map((t, idx) => {
                      return <p key={["aud_", idx].join("_")}>{t}</p>;
                    })}
                  </div>
                  <div className="target">
                    <label>devices</label>
                    {experiment.devices.map((t, idx) => {
                      return <p key={["aud_", idx].join("_")}>{t}</p>;
                    })}
                  </div>
                </div>
                <div className="targeting">
                  {account?.settings.teams &&
                    experiment.teams &&
                    experiment.teams.length > 0 && (
                      <div className="target">
                        <label>Teams</label>
                        {experiment.teams.map((t, idx) => {
                          return <p key={["aud_", idx].join("_")}>{t}</p>;
                        })}
                      </div>
                    )}
                  {account?.settings.brands &&
                    experiment.brands &&
                    experiment.brands.length > 0 && (
                      <div className="target">
                        <label>Brands</label>
                        {experiment.brands.map((t, idx) => {
                          return <p key={["aud_", idx].join("_")}>{t}</p>;
                        })}
                      </div>
                    )}
                  {account?.settings.channels &&
                    experiment.channels &&
                    experiment.channels.length > 0 && (
                      <div className="target">
                        <label>Channels</label>
                        {experiment.channels.map((t, idx) => {
                          return <p key={["aud_", idx].join("_")}>{t}</p>;
                        })}
                      </div>
                    )}
                </div>
              </div>
            </div>
          </section>

          <section>
            <div
              className="content Results"
              style={{
                flexDirection: experiment.status === "flat" ? "column" : "row",
              }}
            >
              {experiment.status === "flat" ||
              experiment.status === "data_not_available" ? (
                <React.Fragment>
                  <div style={{ paddingRight: "50px", width: "100%" }}>
                    <EditorInput
                      content={experiment.description}
                      readOnly={true}
                      onBlur={() => {}}
                    />
                  </div>
                  <ProgramGoalsList
                    className="right"
                    experiment={experiment}
                    style={{ width: "100%", paddingTop: "10px" }}
                  />
                </React.Fragment>
              ) : (
                <React.Fragment>
                  <div className="left">
                    <EditorInput
                      content={experiment.description}
                      readOnly={true}
                      onBlur={() => {}}
                    />
                  </div>
                  <div className="right">
                    <ProgramGoalsList experiment={experiment} />

                    {overall_winner && overall_winner.images.length > 0 && (
                      <div>
                        <Link
                          to={`/tests/${experiment.id}/experiences/${overall_winner.id}/images/${overall_winner.images[0].id}?backTo=case_study`}
                        >
                          <AnnotateImage
                            image={
                              this.state.selectedImages["winner"]
                                ? overall_winner.images[
                                    this.state.selectedImages["winner"]
                                  ]
                                : overall_winner.images[0]
                            }
                            disabled={true}
                            annotation={{}}
                          />
                        </Link>
                        {overall_winner.images.length > 1 && (
                          <ul className="ImageIndicator">
                            {overall_winner.images.map((img, index) => {
                              return (
                                <li
                                  style={{
                                    cursor: "pointer",
                                    backgroundColor:
                                      (!this.state.selectedImages["winner"] &&
                                        index === 0) ||
                                      this.state.selectedImages["winner"] ===
                                        index
                                        ? "#0056b3"
                                        : "#eaeaea",
                                  }}
                                  onClick={() => {
                                    this.setState({
                                      selectedImages: {
                                        ...this.state.selectedImages,
                                        winner: index,
                                      },
                                    });
                                  }}
                                  key={["ow_img_", img.id].join("_")}
                                ></li>
                              );
                            })}
                          </ul>
                        )}
                      </div>
                    )}
                  </div>
                </React.Fragment>
              )}
            </div>
          </section>

          {selectedInsights.length > 0 &&
            experiment.insights &&
            experiment.insights.length > 0 && (
              <section className="bg-blue">
                <div className="content Insights">
                  <h2>Key Insights</h2>
                  <ul>
                    {experiment.insights.map((insight) => {
                      if (!selectedInsights.includes(insight.id)) {
                        return null;
                      }

                      return (
                        <li key={["insight_", insight.id].join("_")}>
                          <div className="insight">
                            <p>{insight.value}</p>
                          </div>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              </section>
            )}

          {selectedExperiences.length > 0 && (
            <section>
              <div className="content Experiences">
                <h2>Deep Dive</h2>
                {experiment &&
                  experiment.experiences.map((experience, idx) => {
                    if (!selectedExperiences.includes(experience.id)) {
                      return null;
                    }

                    return (
                      <div key={["exp_", idx].join("_")} className="experience">
                        {experience.images && experience.images.length > 0 ? (
                          <div>
                            <Link
                              to={`/tests/${experiment.id}/experiences/${experience.id}/images/${experience.images[0].id}?backTo=case_study`}
                            >
                              <AnnotateImage
                                image={
                                  this.state.selectedImages[experience.id]
                                    ? experience.images[
                                        this.state.selectedImages[experience.id]
                                      ]
                                    : experience.images[0]
                                }
                                disabled={true}
                                annotation={{}}
                              />
                            </Link>

                            {experience.images.length > 1 && (
                              <ul className="ImageIndicator">
                                {experience.images.map((img, index) => {
                                  return (
                                    <li
                                      style={{
                                        cursor: "pointer",
                                        backgroundColor:
                                          (!this.state.selectedImages[
                                            experience.id
                                          ] &&
                                            index === 0) ||
                                          this.state.selectedImages[
                                            experience.id
                                          ] === index
                                            ? "#0056b3"
                                            : "#eaeaea",
                                      }}
                                      onClick={() => {
                                        this.setState({
                                          selectedImages: {
                                            ...this.state.selectedImages,
                                            [experience.id]: index,
                                          },
                                        });
                                      }}
                                      key={["exp_img_", img.id].join("_")}
                                    ></li>
                                  );
                                })}
                              </ul>
                            )}
                          </div>
                        ) : (
                          <div style={{ width: "50%" }}></div>
                        )}

                        <div className="content">
                          {experience.type ===
                          "Experiment::Experience::Control" ? (
                            <React.Fragment>
                              <div className="head">
                                <div className="hexagon control">
                                  <label>ctl</label>
                                </div>
                                <h4>{experience.name}</h4>
                              </div>
                              {experience.problem ? (
                                <div className="questions">
                                  {experience.problem &&
                                  experience.problem !== null &&
                                  experience.problem.blocks
                                    .map((block: any) => block.text)
                                    .join("") === "" ? null : (
                                    <div className="question">
                                      <label>
                                        What problem or opportunity was
                                        identified on the Control?
                                      </label>
                                      {experience.problem && (
                                        <EditorInput
                                          content={experience.problem}
                                          onBlur={() => {}}
                                          readOnly={true}
                                        />
                                      )}
                                    </div>
                                  )}
                                </div>
                              ) : null}

                              {experiment.is_single_hypothesis &&
                              controlExperience &&
                              controlExperience.hypothesis ? (
                                controlExperience.hypothesis &&
                                controlExperience.hypothesis.blocks
                                  .map((block: any) => block.text)
                                  .join("") === "" ? null : (
                                  <div className="questions">
                                    {account &&
                                    account.settings.use_bb_methods === true ? (
                                      <label
                                        style={{
                                          textTransform: "uppercase",
                                          color: "#2f4760",
                                          fontSize: "12px",
                                          fontWeight: 500,
                                        }}
                                      >
                                        Whypothesis<sup>&reg;</sup> Customer
                                        Insights Tool
                                      </label>
                                    ) : (
                                      <label
                                        style={{
                                          textTransform: "uppercase",
                                          color: "#2f4760",
                                          fontSize: "12px",
                                          fontWeight: 500,
                                        }}
                                      >
                                        hypothesis
                                      </label>
                                    )}
                                    {controlExperience.hypothesis && (
                                      <EditorInput
                                        content={controlExperience.hypothesis}
                                        onBlur={() => {}}
                                        readOnly={true}
                                      />
                                    )}
                                  </div>
                                )
                              ) : null}
                            </React.Fragment>
                          ) : (
                            <React.Fragment>
                              <div className="head">
                                <div className="hexagon">
                                  <label>V{idx}</label>
                                </div>
                                <h4>{experience.name}</h4>
                              </div>
                              <div className="questions">
                                {experience.difference ? (
                                  experience.difference &&
                                  experience.difference.blocks
                                    .map((block: any) => block.text)
                                    .join("") === "" ? null : (
                                    <div className="question">
                                      <label>
                                        How is this experience different from
                                        the Control?
                                      </label>
                                      {experience.difference && (
                                        <EditorInput
                                          content={experience.difference}
                                          onBlur={() => {}}
                                          readOnly={true}
                                        />
                                      )}
                                    </div>
                                  )
                                ) : null}
                                {!experiment.is_single_hypothesis &&
                                experience.hypothesis ? (
                                  experience.hypothesis &&
                                  experience.hypothesis.blocks
                                    .map((block: any) => block.text)
                                    .join("") === "" ? null : (
                                    <div className="question">
                                      {account &&
                                      account.settings.use_bb_methods ===
                                        true ? (
                                        <label
                                          style={{
                                            textTransform: "uppercase",
                                            color: "#2f4760",
                                            fontSize: "12px",
                                            fontWeight: 500,
                                          }}
                                        >
                                          Whypothesis<sup>&reg;</sup> Customer
                                          Insights Tool
                                        </label>
                                      ) : (
                                        <label
                                          style={{
                                            textTransform: "uppercase",
                                            color: "#2f4760",
                                            fontSize: "12px",
                                            fontWeight: 500,
                                          }}
                                        >
                                          hypothesis
                                        </label>
                                      )}
                                      {experience.hypothesis && (
                                        <EditorInput
                                          content={experience.hypothesis}
                                          onBlur={() => {}}
                                          readOnly={true}
                                        />
                                      )}
                                    </div>
                                  )
                                ) : null}
                                {experience.tactics.length > 0 && (
                                  <div className="question">
                                    <label>Pains & Gains™</label>
                                    <ul>
                                      {experience.tactics.map((tactic, idx) => {
                                        return (
                                          <li key={`t_${experience.id}_${idx}`}>
                                            <p>
                                              {tactic.category}::{tactic.value}
                                            </p>
                                          </li>
                                        );
                                      })}
                                    </ul>
                                  </div>
                                )}
                                {experience.outcome ? (
                                  experience.outcome &&
                                  experience.outcome.blocks
                                    .map((block: any) => block.text)
                                    .join("") === "" ? null : (
                                    <div className="question">
                                      <label>
                                        outcomes & possible explanations
                                      </label>
                                      {experience.outcome && (
                                        <EditorInput
                                          content={experience.outcome}
                                          onBlur={() => {}}
                                          readOnly={true}
                                        />
                                      )}
                                    </div>
                                  )
                                ) : null}
                              </div>
                            </React.Fragment>
                          )}
                        </div>
                      </div>
                    );
                  })}
              </div>
            </section>
          )}
          {selectedReports.length > 0 && activeReport && (
            <section
              style={{
                paddingBottom: experiment.uploads?.length > 0 ? "0" : "60px",
              }}
            >
              <div className="content Analysis">
                <h2>Analysis</h2>
                <div className="flex">
                  {selectedReports.length === 1 && (
                    <div className="ReportSelector flex column fcol-1 pb__md">
                      <label>Report Name: {activeReport.name} </label>
                    </div>
                  )}
                  {selectedReports.length > 1 && (
                    <div className="ReportSelector flex column fcol-1 pb__md">
                      <label>
                        Select Report
                        <i
                          className="fa fa-star"
                          style={{
                            color: activeReport.primary ? "#f4b974" : "",
                            marginLeft: "22px",
                          }}
                        ></i>
                      </label>
                      <SelectInput
                        value={activeReport.name}
                        name="report_selector"
                        placeholder="select report"
                        itemKey="name"
                        items={[
                          ...experiment.reports.filter((r) =>
                            selectedReports.includes(r.id ? r.id : "")
                          ),
                          ...experiment.custom_data_viz.filter((r: any) =>
                            selectedReports.includes(r.id ? r.id : "")
                          ),
                        ]}
                        onChange={this.changeReport}
                      />
                    </div>
                  )}
                </div>

                <div>
                  {isActiveReportDataViz ? (
                    <CustomDataViz
                      dataViz={
                        activeReport as unknown as DataTypes.CustomDataViz
                      }
                    />
                  ) : (
                    <>
                      {!this.state.loadingReport && (
                        <Report
                          experiences={experiment.experiences}
                          report={activeReport}
                        />
                      )}
                    </>
                  )}
                </div>
              </div>
            </section>
          )}

          {experiment.uploads?.length > 0 && (
            <section style={{ paddingBottom: "60px" }}>
              <div className="content Analysis">
                <h2 style={{ fontWeight: 200 }}>Attachments</h2>

                <ul>
                  {experiment.uploads.map((upload: any) => (
                    <li key={upload.id}>
                      <a
                        href={upload.url}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {upload.name}
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
            </section>
          )}
        </div>
      </div>
    );
  }
}

const loadingSelector = createLoadingSelector([
  "@@experiment/GET_SHARE_CASE_STUDY",
]);
const errorSelector = createErrorMessageSelector([
  "@@experiment/GET_SHARE_CASE_STUDY",
]);

const mapStateToProps = ({
  router,
  app,
  account,
  experiment,
}: ApplicationState) => ({
  router: router,
  account: account,
  loading: loadingSelector(app.requests),
  error: errorSelector(app.errors),
  experiment: experiment,
});

const mapDispatchToProps = {
  clearAppErrors,
  getShareCaseStudyRequest,
  updateCaseStudyRequest,
};

const connectedPage = connect(
  mapStateToProps,
  mapDispatchToProps
)(AppPage(ShareCaseStudy));

export { connectedPage as ShareCaseStudy };
