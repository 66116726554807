import React from "react";
import * as Dp from "react-date-picker";
import {
  Control,
  Controller,
  FieldErrors,
  UseFormSetValue,
} from "react-hook-form";
import { Container } from "./index.style";

interface Props {
  fieldName: string;
  control: Control<any>;
  setValue: UseFormSetValue<any>;
  className?: string;
  minDate?: Date;
  maxDate?: Date;
  errors: FieldErrors<any>;
}

export const UseFormDatePicker: React.FC<Props> = ({
  className,
  minDate,
  maxDate,
  control,
  fieldName,
  setValue,
  errors,
}) => {
  return (
    <Container>
      <Controller
        control={control}
        name={fieldName}
        render={({ field }) => (
          <Dp.default
            className={[errors[fieldName] ? "error" : "", "form-control"].join(
              " "
            )}
            onChange={(value) => setValue(fieldName, value)}
            calendarType={"US"}
            clearIcon={null}
            value={field.value}
            minDate={minDate}
            maxDate={maxDate}
            calendarIcon={<i className="far fa-calendar" />}
          />
        )}
      ></Controller>
    </Container>
  );
};
