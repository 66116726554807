import moment from "moment";
import { AdobeExperimentTypes } from "config/app";

class Helpers {
  static formatDate(date: string) {
    if (!date) return;
    return moment(date, "YYYY-MM-DD").format("LL");
  }

  static getExperimentType(type: string, adobe?: string) {
    if (type === "Experiment::Ab") return "A/B";
    if (type === "Experiment::Adobe") {
      if (adobe === AdobeExperimentTypes.AB) return "A/B";
      if (adobe === AdobeExperimentTypes.XT) return "XT";
    }
  }

  static relativeTime(date: Date | string) {
    return moment(date).fromNow();
  }

  static getJsonFromUrl(url: string) {
    if (!url) return {};
    const query = url.substr(1);
    const result: any = {};
    query.split("&").forEach((part: string) => {
      var item = part.split("=");
      result[item[0]] = decodeURIComponent(item[1]);
    });
    return result;
  }

  static getUrlParamsFromHashStr(hashStr: string) {
    const hashes = hashStr.slice(hashStr.indexOf("?") + 1).split("&");
    const params: any = {};
    hashes.forEach((hash) => {
      const [key, val] = hash.split("=");
      params[key] = decodeURIComponent(val);
    });
    return params;
  }

  static getFiltersFromQueryString(qStr: Array<string>): {
    [key: string]: Array<any>;
  } {
    const params: any = {};
    qStr.forEach((item) => {
      const [key, val] = item.split("=");
      if (params[key]) {
        params[key].push(decodeURIComponent(val));
      } else {
        params[key] = [decodeURIComponent(val)];
      }
    });
    return params;
  }

  static formatValue(value: number, dataType?: string, symbol?: string) {
    if (dataType === "number" && value !== null) return value.toLocaleString();
    if (dataType === "currency") {
      if (!symbol || symbol === "DOLLAR") {
        return new Intl.NumberFormat("en-US", {
          style: "currency",
          currency: "USD",
        }).format(value);
      }

      if (symbol === "EURO") {
        return new Intl.NumberFormat("en-US", {
          style: "currency",
          currency: "EUR",
        }).format(value);
      }

      if (symbol === "POUND") {
        return new Intl.NumberFormat("en-US", {
          style: "currency",
          currency: "GBP",
        }).format(value);
      }
    }

    return value;
  }

  static uniqueArray(array: Array<string>) {
    let ua = new Map(array.map((s) => [s.toLowerCase(), s]));
    return [...ua.values()];
  }

  static isIOS() {
    const userAgent = window.navigator.userAgent;
    if (userAgent.match(/iPad/i) || userAgent.match(/iPhone/i) || window.safari)
      return true;
    return false;
  }

  static valueWithCommas(value: string) {
    return value.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  static sleep = (t: number) =>
    new Promise((resolve) => setTimeout(() => resolve({}), t));

  static snakeToTitle(value: string) {
    return value.replace(/_/g, " ");
  }

  static camelToSnake(value: string) {
    return value
      .replace(
        /[\w]([A-Z])/g,
        (cammelLetter) => cammelLetter[0] + "_" + cammelLetter[1]
      )
      .toLowerCase();
  }

  static camelToCapitalize(value: string) {
    return value.replace(
      /[\w]([A-Z])/g,
      (cammelLetter) => cammelLetter[0] + " " + cammelLetter[1]
    );
  }

  static flatten(arr: any[]) {
    return [].concat(...arr);
  }
}

export { Helpers };
