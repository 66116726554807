import React, { Component } from "react";
import { connect } from "react-redux";
import * as DataTypes from "store/types";
import { ApplicationState } from "store/types";
import "css/Base.scss";
import "css/Screens.scss";
import { getDidYouKnowRequest } from "store/dashboard/actions";
import "css/Insights.scss";
import { createLoadingSelector } from "store/selectors";
import { TodayBoardLoading } from "./loading/todayboard_loading";
import { Link } from "react-router-dom";
import { DidYouLikeIt } from "ui/did_you_like_it";

interface IProps {
  router: any;
  loading: boolean;
  dashboard: DataTypes.Dashboard | null;
  getDidYouKnowRequest: () => void;
}

interface IState {
  whatToRender: number;
}

class DidYouKnow extends Component<IProps, IState> {
  componentDidMount() {
    this.props.getDidYouKnowRequest();

    const orderInStorage = window.localStorage.getItem("ad-banner-order");
    let currentOrder = window.localStorage.getItem("ad-banner-order") || 0;

    try {
      currentOrder = Number(orderInStorage);

      if (currentOrder >= 4) {
        currentOrder = 0;
      }
    } catch (e) {
      currentOrder = 0;
    }

    window.localStorage.setItem(
      "ad-banner-order",
      (currentOrder + 1).toString()
    );

    this.setState({ whatToRender: currentOrder });
  }

  private buildExperiments = (didYouKnow: DataTypes.DidYouKnow) => {
    if (
      didYouKnow?.experiments_avg !== null &&
      didYouKnow.experiments_avg !== undefined &&
      didYouKnow?.experiments_avg < 25
    ) {
      return (
        <p className="text-did-you-know">
          Based on our data, brands have an average of 58 tests in their
          illuminate account.{" "}
          <Link to="/tests">Add your tests to catch up today!</Link>
        </p>
      );
    }

    return (
      <p className="text-did-you-know">
        Brands in your industry have {didYouKnow.experiments_avg} tests in
        illuminate. <Link to="/tests">How do you stack up?</Link>
      </p>
    );
  };

  private buildIdeas = (didYouKnow: DataTypes.DidYouKnow) => {
    if (
      didYouKnow?.ideas_avg !== null &&
      didYouKnow?.ideas_avg !== undefined &&
      didYouKnow?.ideas_avg < 25
    ) {
      return (
        <p className="text-did-you-know">
          Based on our data, brands have an average of 91 test ideas in
          illuminate! <Link to="/ideas">Add some ideas today!</Link>
        </p>
      );
    }

    return (
      <p className="text-did-you-know">
        Brands in your industry have {didYouKnow.ideas_avg} test ideas in
        illuminate. <Link to="/ideas">Are you outpacing them?</Link>
      </p>
    );
  };

  private buildInsights = (didYouKnow: DataTypes.DidYouKnow) => {
    if (
      didYouKnow?.insights_avg !== null &&
      didYouKnow.insights_avg !== undefined &&
      didYouKnow?.insights_avg < 25
    ) {
      return (
        <p className="text-did-you-know">
          Based on our data, brands have an average of 44 insights in their
          illuminate account.{" "}
          <Link to="/insights">Catch up by adding insights!</Link>
        </p>
      );
    }

    return (
      <p className="text-did-you-know">
        Brands in your industry have {didYouKnow.insights_avg} insights in
        illuminate. <Link to="/insights">Keep going!</Link>
      </p>
    );
  };

  private buildBBBaner = (didYouKnow: DataTypes.DidYouKnow) => {
    return (
      <ul>
        <li>
          <p className="text-did-you-know">
            Want to know how your program compares?{" "}
          </p>
        </li>
        <li>
          <p className="text-did-you-know">
            Better yet, want an action plan to unlock more success?{" "}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="mailto:hello@brooksbell.com"
            >
              Contact us!
            </a>
          </p>
        </li>
      </ul>
    );
  };

  private decideRender = (didYouKnow: DataTypes.DidYouKnow) => {
    if (!this.state) {
      return null;
    }
    const { whatToRender } = this.state;

    return [
      this.buildExperiments,
      this.buildInsights,
      this.buildIdeas,
      this.buildBBBaner,
    ][whatToRender](didYouKnow);
  };

  private featureNames = () => {
    if (!this.state) {
      return null;
    }

    const { whatToRender } = this.state;

    return ["experiments", "insights", "ideas", "baner"][whatToRender];
  };

  render() {
    if (this.props.loading) {
      return <TodayBoardLoading />;
    }

    const { dashboard } = this.props;

    if (!dashboard || !dashboard.didYouKnow) {
      return null;
    }

    const { didYouKnow } = dashboard;

    return (
      <div className="trait" style={{ marginBottom: "28px" }}>
        <label style={{ fontSize: "18px" }}>Did You Know?</label>
        <div className="shadow body">
          {this.decideRender(didYouKnow)}
          <DidYouLikeIt
            featureName={`doYouKnow-${this.featureNames()}`}
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-end",
            }}
          />
        </div>
      </div>
    );
  }
}

const loadingSelector = createLoadingSelector(["@@dashboard/DID_YOU_KNOW"]);

const mapStateToProps = ({ router, dashboard, app }: ApplicationState) => ({
  router,
  loading: loadingSelector(app.requests),
  dashboard,
});

const mapDispatchToProps = {
  getDidYouKnowRequest,
};

const connectedPage = connect(mapStateToProps, mapDispatchToProps)(DidYouKnow);

export { connectedPage as DidYouNow };
