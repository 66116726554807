import React, { Component, useCallback } from "react";
import { useDropzone } from "react-dropzone";
import * as DataTypes from "store/types";
import TimeAgo from "timeago-react";

interface IProps {
  uxr: DataTypes.Uxr;
  saving: boolean;
  currentUser: any;
  onUpload(body: any): void;
  onDelete(upload: DataTypes.Upload): void;
}

class Uploads extends Component<IProps> {
  private uploadFile = (files: any) => {
    if (files && files.length > 0) {
      files.forEach((file: any) => {
        this.props.onUpload({ name: file.name.split(".")[0], file: file });
      });
    }
  };

  render() {
    const { uxr, saving, currentUser } = this.props;

    return (
      <React.Fragment>
        <div className="header">
          <h3>Attachments</h3>
          <p>
            Add attachments to include other information about this Research.
          </p>
        </div>
        <div className="body">
          {uxr.documents?.length > 0 && (
            <ul className="FileList">
              {uxr.documents.map((upload) => {
                return (
                  <li key={["uploads_", upload.id].join("_")}>
                    <i className="fas fa-paperclip" />
                    <div className="title">
                      <a
                        href={upload.url}
                        target="_blank"
                        rel="noopener noreferrer"
                        download={true}
                      >
                        {upload.name}
                      </a>
                      <p>
                        <small>
                          {upload.updated_at && (
                            <TimeAgo
                              datetime={upload.updated_at}
                              live={false}
                            />
                          )}
                        </small>
                      </p>
                    </div>
                    <div className="ctrls">
                      <button
                        className="btn btn-link"
                        type="button"
                        onClick={() => this.props.onDelete(upload)}
                      >
                        <i className="fas fa-trash-alt" />
                      </button>
                    </div>
                  </li>
                );
              })}
            </ul>
          )}
          {currentUser.role === "read-only" ? null : saving === false ? (
            <UploadBox onFileDrop={this.uploadFile} />
          ) : (
            <div>
              <p className="mb-2">Please wait while we upload your file.</p>
              <div className="progress">
                <div
                  className="progress-bar progress-bar-striped progress-bar-animated"
                  role="progressbar"
                  style={{ width: "100%" }}
                ></div>
              </div>
            </div>
          )}
        </div>
      </React.Fragment>
    );
  }
}

export { Uploads };

interface IUploadBoxProps {
  onFileDrop(files: any): void;
}

const UploadBox = ({ onFileDrop }: IUploadBoxProps) => {
  const onDrop = useCallback(
    (acceptedFiles) => {
      onFileDrop(acceptedFiles);
    },
    [onFileDrop]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
  });

  return (
    <div>
      <div {...getRootProps()}>
        <div>
          <input {...getInputProps()} />
          <div className={isDragActive ? "UploadBox active" : "UploadBox"}>
            <i className="fas fa-cloud-upload-alt"></i>
            <h4>Drag & Drop to upload</h4>
            <p>
              or <span className="link">browse</span> to choose a file
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
