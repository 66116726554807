import React, { Component } from "react";
import { connect } from "react-redux";
import * as DataTypes from "store/types";
import { ApplicationState } from "store/types";
import "css/Base.scss";
import "css/Screens.scss";
import "css/Experiments.scss";
import { getLeaderBoardRequest } from "store/dashboard/actions";
import { Avatar, ExperimentAvatar, ExperimentStatusIcon } from "ui";
import { Link } from "react-router-dom";
import { Helpers } from "utils";
import { createLoadingSelector } from "store/selectors";
import { LeaderBoardLoading } from "./loading/leaderboard_loading";

interface IProps {
  router: any;
  loading: boolean;
  dashboard: DataTypes.Dashboard | null;
  getLeaderBoardRequest: () => void;
}

interface IState {}

class LeaderBoard extends Component<IProps, IState> {
  componentDidMount() {
    this.props.getLeaderBoardRequest();
  }

  buildExperiment = () => {
    const { dashboard } = this.props;

    if (!dashboard) {
      return null;
    }

    const { leaderBoard } = dashboard;

    if (!leaderBoard || !leaderBoard.best_experiment) {
      return null;
    }

    const experiment = leaderBoard.best_experiment;

    return (
      <React.Fragment>
        <label className="text__sm">Most recent Winner</label>

        <li
          className="flex frow align-center"
          id={`experiment_${experiment.id}`}
          onClick={() => {}}
          style={{ paddingLeft: "19px" }}
        >
          <div className="left">
            <ExperimentAvatar experiment={experiment} className="sm" />
          </div>

          <div className="body ml__sm">
            <h6 className="title">
              <Link to={`/tests/${experiment.id}`}>{experiment.name}</Link>
            </h6>
          </div>

          <div className="flex column align-center fcol-2 align-end">
            <div className="flex column align-center">
              <i
                className={
                  experiment.star === true ? "fa fa-star active" : "fa"
                }
              />

              <ExperimentStatusIcon status={experiment.status} />

              {experiment.impact_value !== 0 && experiment.program_goal && (
                <React.Fragment>
                  <p style={{ marginBottom: 0 }}>
                    {Helpers.formatValue(
                      experiment.impact_value,
                      experiment.program_goal.data_type,
                      experiment.program_goal.symbol
                    )}
                  </p>
                  <p className="text-capitalize" style={{ textAlign: "right" }}>
                    {experiment.program_goal.name}
                  </p>
                </React.Fragment>
              )}
            </div>
          </div>
        </li>
      </React.Fragment>
    );
  };

  buildTopCreators = () => {
    const { dashboard } = this.props;

    if (!dashboard) {
      return null;
    }

    const { leaderBoard } = dashboard;

    if (!leaderBoard || !leaderBoard.top_creators) {
      return null;
    }

    const { top_creators: topCreators } = leaderBoard;

    return (
      <li>
        {topCreators.experiments && (
          <React.Fragment>
            <label className="text__sm">Top Test Creator</label>
            <div className="flex align-center mv__sm">
              <div className="ml__sm">
                <Avatar className="sm" user={topCreators.experiments} />
              </div>
              <p className="m0">
                {topCreators.experiments.first_name}{" "}
                {topCreators.experiments.last_name}
              </p>
            </div>
          </React.Fragment>
        )}

        {topCreators.ideas && (
          <React.Fragment>
            <label className="text__sm">Top Idea Creator</label>
            <div className="flex align-center mv__sm">
              <div className="ml__sm">
                <Avatar className="sm" user={topCreators.ideas} />
              </div>
              <p className="m0">
                {topCreators.ideas.first_name} {topCreators.ideas.last_name}
              </p>
            </div>
          </React.Fragment>
        )}

        {topCreators.insights && (
          <React.Fragment>
            <label className="text__sm">Top Insight Creator</label>
            <div className="flex align-center mv__sm">
              <div className="ml__sm">
                <Avatar className="sm" user={topCreators.insights} />
              </div>
              <p className="m0">
                {topCreators.insights.first_name}{" "}
                {topCreators.insights.last_name}
              </p>
            </div>
          </React.Fragment>
        )}
      </li>
    );
  };

  render() {
    if (this.props.loading) {
      return <LeaderBoardLoading />;
    }

    const { dashboard } = this.props;

    if (!dashboard || !dashboard.leaderBoard) {
      return null;
    }

    if (
      !dashboard.leaderBoard.audience_top_performer &&
      !dashboard.leaderBoard.best_experiment &&
      !dashboard.leaderBoard.device_top_performer &&
      !dashboard.leaderBoard.page_top_performer &&
      !dashboard.leaderBoard.top_creators
    ) {
      return null;
    }

    return (
      <div className="trait">
        <label style={{ fontSize: "18px" }}>Leaderboard</label>
        <div className="body shadow">
          <ul>
            {this.buildExperiment()}
            {this.buildTopCreators()}
          </ul>
        </div>
      </div>
    );
  }
}

const loadingSelector = createLoadingSelector(["@@dashboard/GET_LEADER_BOARD"]);

const mapStateToProps = ({ router, dashboard, app }: ApplicationState) => ({
  router,
  loading: loadingSelector(app.requests),
  dashboard,
});

const mapDispatchToProps = {
  getLeaderBoardRequest,
};

const connectedPage = connect(mapStateToProps, mapDispatchToProps)(LeaderBoard);

export { connectedPage as LeaderBoard };
