import { ApiClient } from './client/api_client';

class Reports {
  static async update(experimentId: string, reportId: string, body: any) {
    return await ApiClient.put(`/v1/experiment/${experimentId}/report/${reportId}`, body);
  }

  static async destroy(experimentId: string, reportId: string) {
    return await ApiClient.delete(`/v1/experiment/${experimentId}/report/${reportId}`);
  }

  static async preUpload(experimentId: string, file: File) {
    const formData = new FormData();
    formData.append('file', file);

    return await ApiClient.postMultipart(`/v1/experiment/${experimentId}/report/csv/pre_upload`, formData);
  }

  static async upload(experimentId: string, file: File, name: string, changedMetrics: any) {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('name', name);
    formData.append('changed_metrics', JSON.stringify(changedMetrics));

    return await ApiClient.postMultipart(`/v1/experiment/${experimentId}/report/csv/upload`, formData);
  }

  static async traits() {
    return await ApiClient.get('/v1/experiment/report/traits');
  }
}

export { Reports };
