export enum ActionTypes {
  CREATE_ACCOUNT_REQUEST = "@@account/CREATE_ACCOUNT_REQUEST",
  CREATE_ACCOUNT_SUCCESS = "@@account/CREATE_ACCOUNT_SUCCESS",
  CREATE_ACCOUNT_FAILURE = "@@account/CREATE_ACCOUNT_FAILURE",

  UPDATE_ACCOUNT_PROFILE_REQUEST = "@@account/UPDATE_ACCOUNT_PROFILE_REQUEST",
  UPDATE_ACCOUNT_PROFILE_SUCCESS = "@@account/UPDATE_ACCOUNT_PROFILE_SUCCESS",
  UPDATE_ACCOUNT_PROFILE_FAILURE = "@@account/UPDATE_ACCOUNT_PROFILE_FAILURE",

  UPDATE_ACCOUNT_PGS_REQUEST = "@@account/UPDATE_ACCOUNT_PGS_REQUEST",
  UPDATE_ACCOUNT_PGS_SUCCESS = "@@account/UPDATE_ACCOUNT_PGS_SUCCESS",
  UPDATE_ACCOUNT_PGS_FAILURE = "@@account/UPDATE_ACCOUNT_PGS_FAILURE",

  GET_ACCOUNT_REQUEST = "@@account/GET_ACCOUNT_REQUEST",
  GET_ACCOUNT_SUCCESS = "@@account/GET_ACCOUNT_SUCCESS",
  GET_ACCOUNT_FAILURE = "@@account/GET_ACCOUNT_FAILURE",

  GET_ACCOUNT_SUMMARY_REQUEST = "@@account/GET_ACCOUNT_SUMMARY_REQUEST",
  GET_ACCOUNT_SUMMARY_SUCCESS = "@@account/GET_ACCOUNT_SUMMARY_SUCCESS",
  GET_ACCOUNT_SUMMARY_FAILURE = "@@account/GET_ACCOUNT_SUMMARY_FAILURE",

  GET_ACCOUNT_STATS_REQUEST = "@@account/GET_ACCOUNT_STATS_REQUEST",
  GET_ACCOUNT_STATS_SUCCESS = "@@account/GET_ACCOUNT_STATS_SUCCESS",
  GET_ACCOUNT_STATS_FAILURE = "@@account/GET_ACCOUNT_STATS_FAILURE",

  GET_ACCOUNT_TRAITS_REQUEST = "@@account/GET_ACCOUNT_TRAITS_REQUEST",
  GET_ACCOUNT_TRAITS_SUCCESS = "@@account/GET_ACCOUNT_TRAITS_SUCCESS",
  GET_ACCOUNT_TRAITS_FAILURE = "@@account/GET_ACCOUNT_TRAITS_FAILURE",

  GET_SHARE_ACCOUNT_TRAITS_REQUEST = "@@account/GET_SHARE_ACCOUNT_TRAITS_REQUEST",
  GET_SHARE_ACCOUNT_TRAITS_SUCCESS = "@@account/GET_SHARE_ACCOUNT_TRAITS_SUCCESS",
  GET_SHARE_ACCOUNT_TRAITS_FAILURE = "@@account/GET_SHARE_ACCOUNT_TRAITS_FAILURE",

  GET_ACCOUNT_APPS_REQUEST = "@@account/GET_ACCOUNT_APPS_REQUEST",
  GET_ACCOUNT_APPS_SUCCESS = "@@account/GET_ACCOUNT_APPS_SUCCESS",
  GET_ACCOUNT_APPS_FAILURE = "@@account/GET_ACCOUNT_APPS_FAILURE",

  CREATE_APP_REQUEST = "@@account/CREATE_APP_REQUEST",
  CREATE_APP_SUCCESS = "@@account/CREATE_APP_SUCCESS",
  CREATE_APP_FAILURE = "@@account/CREATE_APP_FAILURE",

  UPDATE_APP_REQUEST = "@@account/UPDATE_APP_REQUEST",
  UPDATE_APP_SUCCESS = "@@account/UPDATE_APP_SUCCESS",
  UPDATE_APP_FAILURE = "@@account/UPDATE_APP_FAILURE",

  GET_APP_EXPS_REQUEST = "@@account/GET_APP_EXPS_REQUEST",
  GET_APP_EXPS_SUCCESS = "@@account/GET_APP_EXPS_SUCCESS",
  GET_APP_EXPS_FAILURE = "@@account/GET_APP_EXPS_FAILURE",

  GET_APP_METRICS_REQUEST = "@@account/GET_APP_METRICS_REQUEST",
  GET_APP_METRICS_SUCCESS = "@@account/GET_APP_METRICS_SUCCESS",
  GET_APP_METRICS_FAILURE = "@@account/GET_APP_METRICS_FAILURE",

  GET_USERS_REQUEST = "@@account/GET_USERS_REQUEST",
  GET_USERS_SUCCESS = "@@account/GET_USERS_SUCCESS",
  GET_USERS_FAILURE = "@@account/GET_USERS_FAILURE",

  DELETE_USER_REQUEST = "@@account/DELETE_USER_REQUEST",
  DELETE_USER_SUCCESS = "@@account/DELETE_USER_SUCCESS",
  DELETE_USER_FAILURE = "@@account/DELETE_USER_FAILURE",

  UPDATE_ACCOUNT_SETTINGS_REQUEST = "@@account/UPDATE_ACCOUNT_SETTINGS_REQUEST",
  UPDATE_ACCOUNT_SETTINGS_SUCCESS = "@@account/UPDATE_ACCOUNT_SETTINGS_SUCCESS",
  UPDATE_ACCOUNT_SETTINGS_FAILURE = "@@account/UPDATE_ACCOUNT_SETTINGS_FAILURE",

  INVITE_NEW_USER_REQUEST = "@@account/INVITE_NEW_USER_REQUEST",
  INVITE_NEW_USER_SUCCESS = "@@account/INVITE_NEW_USER_SUCCESS",
  INVITE_NEW_USER_FAILURE = "@@account/INVITE_NEW_USER_FAILURE",

  INVITE_NEW_USERS_REQUEST = "@@account/INVITE_NEW_USERS_REQUEST",
  INVITE_NEW_USERS_SUCCESS = "@@account/INVITE_NEW_USERS_SUCCESS",
  INVITE_NEW_USERS_FAILURE = "@@account/INVITE_NEW_USERS_FAILURE",

  GET_PENDING_INVITES_REQUEST = "@@account/GET_PENDING_INVITES_REQUEST",
  GET_PENDING_INVITES_SUCCESS = "@@account/GET_PENDING_INVITES_SUCCESS",
  GET_PENDING_INVITES_FAILURE = "@@account/GET_PENDING_INVITES_FAILURE",

  DELETE_PENDING_INVITE_REQUEST = "@@account/DELETE_PENDING_INVITE_REQUEST",
  DELETE_PENDING_INVITE_SUCCESS = "@@account/DELETE_PENDING_INVITE_SUCCESS",
  DELETE_PENDING_INVITE_FAILURE = "@@account/DELETE_PENDING_INVITE_FAILURE",

  UPDATE_PROGRAM_GOAL_REQUEST = "@@account/UPDATE_PROGRAM_GOAL_REQUEST",
  UPDATE_PROGRAM_GOAL_SUCCESS = "@@account/UPDATE_PROGRAM_GOAL_SUCCESS",
  UPDATE_PROGRAM_GOAL_FAILURE = "@@account/UPDATE_PROGRAM_GOAL_FAILURE",
}
