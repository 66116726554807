import React, { Component } from 'react';
import * as DataTypes from 'store/types';
import { Form, Input, Button, ValidatorTypes } from 'comps/form';
import { createReportSummaryRequest } from 'store/report_summary/actions';
import { ApplicationState } from 'store/types';
import { connect } from 'react-redux';
import { createLoadingSelector } from 'store/selectors';

interface IProps {
  experiment: DataTypes.Experiment;
  saving: boolean;
  onSubmit(name: string, type: string): void;
  createReportSummaryRequest(experimentId: string, body: any): void;
}

class AddReport extends Component<IProps> {
  private onSubmit = (valid: boolean, dirty: boolean, data: any) => {
    const experimentId = this.props.experiment.id;

    if (valid && experimentId) {
      this.props.createReportSummaryRequest(experimentId, { name: data.name.value });
    }
  };

  render() {
    const { saving } = this.props;

    return (
      <React.Fragment>
        <div className="header">
          <h3>Create New Report</h3>
          <p>Use the form below to create a new report for this test.</p>
        </div>
        <Form
          formFields={{
            name: { name: 'name', value: '', validator: { type: ValidatorTypes.REQUIRED, messages: { required: 'Please enter a report name.' } } },
          }}
          onSubmit={this.onSubmit}
          FormComponent={({ fields: { name }, onChange }) => (
            <React.Fragment>
              <div className="body">
                <div className="form-group">
                  <label>Report name</label>
                  <Input field={name} className="form-control" onChange={(e) => onChange(name, e.target.value)} />
                </div>
              </div>
              <div className="cntrl-bar">
                <Button className="btn btn-primary" text="create report" isLoading={saving} />
              </div>
            </React.Fragment>
          )}
        />
      </React.Fragment>
    );
  }
}

const loadingSelector = createLoadingSelector(['@@report_summary/CREATE_REPORT_SUMMARY']);

const mapStateToProps = ({ app, router }: ApplicationState) => ({
  saving: loadingSelector(app.requests),
  router,
});

const mapDispatchToProps = {
  createReportSummaryRequest,
};

const connectedPage = connect(mapStateToProps, mapDispatchToProps)(AddReport);

export { connectedPage as AddReport };