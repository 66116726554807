import React from 'react';

const findLinkEntities = (contentBlock: any, callback: any, contentState: any) => {
  contentBlock.findEntityRanges(
    (character: any) => {
      const entityKey = character.getEntity();
      return (
        entityKey !== null &&
        contentState.getEntity(entityKey).getType() === 'LINK'
      );
    },
    callback
  );
}

const Link = (props: any) => {
  const {url} = props.contentState.getEntity(props.entityKey).getData();
  return (
    <a target="_blank" rel="noopener noreferrer" href={url} className="link">
      {props.children}
    </a>
  );
};

export default {
  strategy: findLinkEntities,
  component: Link,
};