import React, { Component, useCallback } from "react";
import { Dispatch } from "redux";
import { connect } from "react-redux";
import { ApplicationState } from "store/types";
import * as DataTypes from "store/types";
import {
  updateProfileRequest,
  updatePasswordRequest,
  updateNotificationsRequest,
  updateProfileImageRequest,
} from "store/user/actions";
import {
  createLoadingSelector,
  createErrorMessageSelector,
} from "store/selectors";
import { history } from "utils";
import { Form, Input, InputTypes, Button, ValidatorTypes } from "comps/form";
import { Avatar } from "ui";
import { Spacer } from "comps/layout";
import { useDropzone } from "react-dropzone";
import "css/Screens.scss";

interface IProps {
  formProfileLoading: boolean;
  formProfileError: any;
  formPasswordLoading: boolean;
  formPasswordError: any;
  updateProfileRequest: typeof updateProfileRequest;
  updatePasswordRequest: typeof updatePasswordRequest;
  updateNotificationsRequest: typeof updateNotificationsRequest;
  updateProfileImageRequest: typeof updateProfileImageRequest;
  user: DataTypes.User | null;
}

interface IState {
  passwordMatch: boolean;
}

class Profile extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      passwordMatch: true,
    };
  }

  escFunction = (event: any) => {
    if (event.keyCode === 27) {
      history.goBack();
    }
  };

  componentDidMount = () => {
    document.addEventListener("keydown", this.escFunction, false);
  };

  componentWillUnmount = () => {
    document.removeEventListener("keydown", this.escFunction, false);
  };

  private close = () => {
    history.goBack();
  };

  private onProfileSubmit = (valid: boolean, dirty: boolean, data: any) => {
    const { user } = this.props;
    if (valid && user) {
      this.props.updateProfileRequest(user.id, {
        first_name: data.first_name.value,
        last_name: data.last_name.value,
        email: data.email.value,
      });
    }
  };

  private onPasswordSubmit = (valid: boolean, dirty: boolean, data: any) => {
    const { user } = this.props;
    if (valid && user) {
      if (data.password.value.toString() === data.confirm.value.toString()) {
        this.props.updatePasswordRequest(user.id, {
          password: data.password.value.toString(),
        });
        this.setState({ ...this.state, passwordMatch: true });
      } else {
        this.setState({ ...this.state, passwordMatch: false });
      }
    }
  };

  private toggleNotification = (key: string, value: boolean) => {
    const { user } = this.props;
    if (!user) {
      return;
    }

    this.props.updateNotificationsRequest(user.id, {
      notification: { [key]: value },
    });
  };

  private uploadImage = (files: any) => {
    const { user } = this.props;
    if (user) {
      if (files && files.length > 0) {
        files.forEach((file: any) => {
          this.props.updateProfileImageRequest(user.id, file);
        });
      }
    }
  };

  render() {
    const { user, formProfileLoading, formPasswordLoading } = this.props;
    if (!user) return null;

    return (
      <div className="FullScreen">
        <div className="header">
          <img src="/img/logo_sm.svg" alt="illuminate" className="logo" />
          <button
            title="close"
            type="button"
            className="btn btn-close"
            onClick={this.close}
          >
            <i className="fas fa-times" />
          </button>
        </div>
        <div className="form-body">
          <div className="content simple">
            <h2>Update Profile</h2>
            <p>Use the form below to update your user profile.</p>
            <Form
              formFields={{
                first_name: {
                  name: "first_name",
                  value: user.first_name,
                  validator: {
                    type: ValidatorTypes.REQUIRED,
                    messages: { required: "Please enter a valid first name." },
                  },
                },
                last_name: {
                  name: "last_name",
                  value: user.last_name,
                  validator: {
                    type: ValidatorTypes.REQUIRED,
                    messages: { required: "Please enter a valid last name." },
                  },
                },
                email: {
                  name: "email",
                  value: user.email,
                  validator: {
                    type: ValidatorTypes.EMAIL,
                    messages: { type: "Please enter a valid email address." },
                  },
                },
              }}
              onSubmit={this.onProfileSubmit}
              FormComponent={({
                fields: { first_name, last_name, email },
                onChange,
              }) => (
                <div className="UpdateProfile">
                  <div className="body">
                    <div className="left">
                      <div className="profileName">
                        <div className="form-group">
                          <label>first name</label>
                          <Input
                            field={first_name}
                            className="form-control"
                            onChange={(e) =>
                              onChange(first_name, e.target.value)
                            }
                          />
                        </div>
                        <div className="form-group">
                          <label>last name</label>
                          <Input
                            field={last_name}
                            className="form-control"
                            onChange={(e) =>
                              onChange(last_name, e.target.value)
                            }
                          />
                        </div>
                      </div>
                      <div className="form-group">
                        <label>email</label>
                        <Input
                          field={email}
                          className="form-control"
                          onChange={(e) => onChange(email, e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="right">
                      <div className="profile-avatar">
                        <ProfileAvatar
                          user={user}
                          onImageDrop={this.uploadImage}
                        />
                      </div>
                    </div>
                  </div>
                  <Button
                    className="btn btn-primary"
                    text="update profile"
                    isLoading={formProfileLoading}
                  />
                </div>
              )}
            />
            <hr className="dash" />
          </div>
          <div className="content simple">
            <h4>Change Password</h4>
            <p>Use the form below to update your password</p>
            <Form
              formFields={{
                password: {
                  name: "password",
                  value: "",
                  validator: { type: ValidatorTypes.PASSWORD },
                },
                confirm: {
                  name: "confirm",
                  value: "",
                  validator: { type: ValidatorTypes.PASSWORD },
                },
              }}
              onSubmit={this.onPasswordSubmit}
              FormComponent={({ fields: { password, confirm }, onChange }) => (
                <div className="UpdateProfile">
                  <div className="body">
                    <div className="left">
                      <div className="form-group">
                        <label>password</label>
                        <Input
                          field={password}
                          type={InputTypes.PASSWORD}
                          className="form-control"
                          onChange={(e) => onChange(password, e.target.value)}
                        />
                      </div>
                      <div className="form-group">
                        <label>confirm password</label>
                        <Input
                          field={confirm}
                          type={InputTypes.PASSWORD}
                          className="form-control"
                          onChange={(e) => onChange(confirm, e.target.value)}
                        />
                        {this.state.passwordMatch === false && (
                          <div className="error">Passwords do not match</div>
                        )}
                      </div>
                    </div>
                  </div>
                  <Button
                    className="btn btn-primary"
                    text="change password"
                    isLoading={formPasswordLoading}
                  />
                </div>
              )}
            />
            <hr className="dash" />
          </div>
          <div className="content simple">
            <h4>Notifications</h4>
            <p>
              illuminate can notify you via email when certain actions take
              place. Select the items below that you would like to be notified
              about via email:
            </p>
            <div className="Notifications">
              <div className="controls">
                {/* <div className="control">
                  <div className="desc">
                    <p>On the start and end dates of my tests.</p>
                  </div>
                  <div className="toggle">
                    <label className="switch">
                      <input
                        type="checkbox"
                        defaultChecked={user.notifications.experiment_completed}
                        onChange={(e) => this.toggleNotification('experiment_completed', e.target.checked)}
                      />
                      <span className="slider round"></span>
                    </label>
                  </div>
                </div> */}
                {/* <div className="control">
                  <div className="desc">
                    <p>
                      When new tests are created in my testing tool. <i>(Integration required)</i>
                    </p>
                  </div>
                  <div className="toggle">
                    <label className="switch">
                      <input
                        type="checkbox"
                        defaultChecked={user.notifications.experiment_created}
                        onChange={(e) => this.toggleNotification('experiment_created', e.target.checked)}
                      />
                      <span className="slider round"></span>
                    </label>
                  </div>
                </div> */}
                <div className="control">
                  <div className="desc">
                    <p>
                      Send me Brooks Bell's tips and strategic insights for
                      testing and personalization.
                    </p>
                  </div>
                  <div className="toggle">
                    <label className="switch">
                      <input
                        title="newsLetter"
                        type="checkbox"
                        defaultChecked={user.notification_settings.newsletter}
                        onChange={(e) =>
                          this.toggleNotification(
                            "newsletter",
                            e.target.checked
                          )
                        }
                      />
                      <span className="slider round"></span>
                    </label>
                  </div>
                </div>
              </div>
              <Spacer size={4} />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const updateProfileLoader = createLoadingSelector(["@@user/UPDATE_PROFILE"]);
const updateProfileError = createErrorMessageSelector([
  "@@user/UPDATE_PROFILE",
]);

const updatePasswordLoader = createLoadingSelector(["@@user/UPDATE_PASSWORD"]);
const updatePasswordError = createErrorMessageSelector([
  "@@user/UPDATE_PASSWORD",
]);

const mapStateToProps = ({ app, user }: ApplicationState) => ({
  formProfileLoading: updateProfileLoader(app.requests),
  formProfileError: updateProfileError(app.errors),
  formPasswordLoading: updatePasswordLoader(app.requests),
  formPasswordError: updatePasswordError(app.errors),
  user: user,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  updateProfileRequest: (id: string, body: any) =>
    dispatch(updateProfileRequest(id, body)),
  updatePasswordRequest: (id: string, body: any) =>
    dispatch(updatePasswordRequest(id, body)),
  updateNotificationsRequest: (id: string, body: any) =>
    dispatch(updateNotificationsRequest(id, body)),
  updateProfileImageRequest: (id: string, file: File) =>
    dispatch(updateProfileImageRequest(id, file)),
});

const connectedPage = connect(mapStateToProps, mapDispatchToProps)(Profile);

export { connectedPage as Profile };

interface IProfileAvatarProps {
  user: DataTypes.User;
  onImageDrop(files: any): void;
}

const ProfileAvatar = ({ user, onImageDrop }: IProfileAvatarProps) => {
  const onDrop = useCallback(
    (acceptedFiles) => {
      onImageDrop(acceptedFiles);
    },
    [onImageDrop]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: ".jpg,.gif,.jpeg,.png",
    onDrop,
  });

  return (
    <div>
      <div {...getRootProps()}>
        <div>
          <input {...getInputProps()} />
          {!isDragActive ? (
            <Avatar user={user} />
          ) : (
            <div className="Avatar">
              <div className="upload active">
                Drop your profile
                <br />
                pic here
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
