export enum ActionTypes {
  GET_INSIGHTS_TRAITS_REQUEST = '@@insight_traits/GET_INSIGHTS_TRAITS_REQUEST',
  GET_INSIGHTS_TRAITS_SUCCESS = '@@insight_traits/GET_INSIGHTS_TRAITS_SUCCESS',
  GET_INSIGHTS_TRAITS_FAILURE = '@@insight_traits/GET_INSIGHTS_TRAITS_FAILURE',

  CREATE_INSIGHTS_TRAITS_REQUEST = '@@insight_traits/CREATE_INSIGHTS_TRAITS_REQUEST',
  CREATE_INSIGHTS_TRAITS_SUCCESS = '@@insight_traits/CREATE_INSIGHTS_TRAITS_SUCCESS',
  CREATE_INSIGHTS_TRAITS_FAILURE = '@@insight_traits/CREATE_INSIGHTS_TRAITS_FAILURE',

  DELETE_INSIGHTS_TRAITS_REQUEST = '@@insight_traits/DELETE_INSIGHTS_TRAITS_REQUEST',
  DELETE_INSIGHTS_TRAITS_SUCCESS = '@@insight_traits/DELETE_INSIGHTS_TRAITS_SUCCESS',
  DELETE_INSIGHTS_TRAITS_FAILURE = '@@insight_traits/DELETE_INSIGHTS_TRAITS_FAILURE',
}
