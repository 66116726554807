import React, { useEffect, useMemo, useState } from "react";
import { ReportList, TabsContent } from "./style";
import * as DataTypes from "store/types";

type Params = {
  experiment: DataTypes.Experiment;
  active: boolean;
  onSelectExperience: (experiences: DataTypes.Experience[]) => void;
  previousSelected?: DataTypes.Experience[];
};

export const ExperiencesTab: React.FC<Params> = ({
  experiment,
  active,
  onSelectExperience,
  previousSelected,
}) => {
  const [selectedExperiences, setSelectedExperiences] = useState<
    DataTypes.Experience[]
  >(() => (previousSelected ? previousSelected : []));

  const selectedExperiencesIds = useMemo(
    () => selectedExperiences.map((experience) => experience.id),
    [selectedExperiences]
  );

  useEffect(() => {
    onSelectExperience(selectedExperiences);
  }, [selectedExperiences]);

  const selectExperience = (experience: DataTypes.Experience) => {
    const currentIndex = selectedExperiencesIds.lastIndexOf(experience.id);

    if (currentIndex === -1) {
      setSelectedExperiences([...selectedExperiences, experience]);
      return;
    }

    setSelectedExperiences(
      selectedExperiences.filter(
        (experienceTmp) => experience.id !== experienceTmp.id
      )
    );
  };

  return (
    <TabsContent className={`${active ? "active" : ""}`}>
      <p>
        Select which experiences you’d like to reference in this data
        visualization.
      </p>

      <ReportList>
        {experiment.experiences.map((experience: DataTypes.Experience) => (
          <div key={`report_tab_experience_${experience.id}`}>
            <input
              id={experience.id}
              type="checkbox"
              defaultChecked={
                selectedExperiencesIds.lastIndexOf(experience.id) !== -1
              }
              onClick={() => selectExperience(experience)}
            />
            <label htmlFor={experience.id}>{experience.name}</label>
          </div>
        ))}
      </ReportList>
    </TabsContent>
  );
};
