import { ApiClient } from './client/api_client';

class MetricResults {
  static async update(experimentId: any, reportId: any, metricId: any, resultId: any, body: any) {
    return await ApiClient.put(
      `/v1/experiment/${experimentId}/report/${reportId}/metric/${metricId}/result/${resultId}`,
      { result: body });
  }

  static async create(experimentId: string, reportId: string, metricId: string, body: any) {
    return await ApiClient.post(
      `/v1/experiment/${experimentId}/report/${reportId}/metric/${metricId}/result`,
      { result: body });
  }
}

export { MetricResults };
