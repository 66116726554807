import React, { Component } from "react";

import * as DataTypes from "store/types";
import { DropdownControl, TextInput } from "ui";
import { ReportTypes } from "config/app";
import TimeAgo from "timeago-react";
import { EditorInput } from "ui/editor_input/editor_input";
import { updateReportRequest } from "store/report/actions";
import { getReportTraitsRequest } from "store/report_trait/actions";
import { updateMetricRequest } from "store/report_metric/actions";
import { connect } from "react-redux";
import { ReportChart } from "../../report_chart";
import { ResultsTable } from "../../results_table";

interface IProps {
  experiment: DataTypes.Experiment;
  report: DataTypes.Report;
  currentUser: any;
  experiences: Array<DataTypes.Experience>;
  reportTrait: any;
  onUpdateReport(report: DataTypes.Report): void;
  onAddAnalyticsMetrics?(): void;
  onDelete(): void;
  onUpdateReportStats(): void;
  updateReportRequest(experimentId: string, reportId: string, body: any): void;
  getReportTraitsRequest(): void;
  updateMetricRequest: (metric: any, report: any, experimentId: any) => void;
}

interface IState {
  metricIdx: number;
}

class Report extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      metricIdx: 0,
    };
  }

  componentDidMount() {
    this.props.getReportTraitsRequest();
  }

  private onUpdateMetrics = (
    metrics: Array<DataTypes.Metric>,
    metricIdx: any
  ) => {
    const { report } = this.props;
    this.props.updateMetricRequest(
      metrics[metricIdx],
      report,
      this.props.experiment.id
    );
  };

  private onUpdatePrimary = (primary: boolean) => {
    const { id: experimentId } = this.props.experiment;
    const { report } = this.props;

    this.props.updateReportRequest(experimentId || "", report.id || "", {
      primary,
    });
  };

  private onUpdateName = (name: string) => {
    const { id: experimentId } = this.props.experiment;
    const { report } = this.props;

    this.props.updateReportRequest(experimentId || "", report.id || "", {
      name,
    });
  };

  private onUpdateDescription = (description: string) => {
    const { id: experimentId } = this.props.experiment;
    const { report } = this.props;
    this.props.updateReportRequest(experimentId || "", report.id || "", {
      description,
    });
  };

  private setMetricIndex = (metricIdx: number) => {
    this.setState({ ...this.state, metricIdx: metricIdx });
  };

  render() {
    const { report, reportTrait, experiences, onAddAnalyticsMetrics } =
      this.props;
    const { metricIdx } = this.state;

    if (!reportTrait) {
      return null;
    }

    return (
      <React.Fragment>
        <section className="Report">
          <div className="header">
            <div className="left">
              <TextInput
                key={["name", report.id].join("_")}
                value={report.name || ""}
                onChange={this.onUpdateName}
              >
                <h4 className="text-capitalize">
                  {report.name}
                  <i
                    className="fa fa-star"
                    style={{
                      color: report.primary ? "#f4b974" : "",
                      marginLeft: "22px",
                    }}
                  ></i>
                </h4>
              </TextInput>

              <label>{report.type} report</label>
            </div>
            <div className="right">
              {report.type === ReportTypes.ANALYTICS && (
                <React.Fragment>
                  {report.stats_last_updated && (
                    <div className="date">
                      <label>Last Updated:</label>
                      <p>
                        <TimeAgo
                          datetime={report.stats_last_updated}
                          live={false}
                        />
                      </p>
                    </div>
                  )}
                  {this.props.currentUser.role !== "read-only" && (
                    <DropdownControl
                      controlItems={[
                        {
                          name: report.primary
                            ? "Remove Primary"
                            : "Make Primary",
                          icon: "fa fa-star",
                          onClick: () => this.onUpdatePrimary(!report.primary),
                        },
                        {
                          name: "Update Adobe Results",
                          icon: "fab fa-adobe",
                          onClick: () => this.props.onUpdateReportStats(),
                        },
                        {
                          name: "Delete Report",
                          icon: "far fa-trash-alt",
                          onClick: () => this.props.onDelete(),
                          splitAbove: true,
                          className: "danger",
                        },
                      ]}
                    />
                  )}
                </React.Fragment>
              )}
              {report.type === ReportTypes.TARGET && (
                <React.Fragment>
                  {report.stats_last_updated && (
                    <div className="date">
                      <label>Last Updated:</label>
                      <p>
                        <TimeAgo
                          datetime={report.stats_last_updated}
                          live={false}
                        />
                      </p>
                    </div>
                  )}
                  {this.props.currentUser.role !== "read-only" && (
                    <DropdownControl
                      controlItems={[
                        {
                          name: report.primary
                            ? "Remove Primary"
                            : "Make Primary",
                          icon: "fa fa-star",
                          onClick: () => this.onUpdatePrimary(!report.primary),
                        },
                        {
                          name: "Update Adobe Results",
                          icon: "fab fa-adobe",
                          onClick: () => this.props.onUpdateReportStats(),
                        },
                        {
                          name: "Delete Report",
                          icon: "far fa-trash-alt",
                          onClick: () => this.props.onDelete(),
                          splitAbove: true,
                          className: "danger",
                        },
                      ]}
                    />
                  )}
                </React.Fragment>
              )}
              {this.props.currentUser.role !== "read-only" &&
                report.type === ReportTypes.SUMMARY && (
                  <DropdownControl
                    controlItems={[
                      {
                        name: report.primary
                          ? "Remove Primary"
                          : "Make Primary",
                        icon: "fa fa-star",
                        onClick: () => this.onUpdatePrimary(!report.primary),
                      },
                      {
                        name: "Delete Report",
                        icon: "far fa-trash-alt",
                        onClick: () => this.props.onDelete(),
                        splitAbove: true,
                        className: "danger",
                      },
                    ]}
                  />
                )}
            </div>
          </div>

          <ReportChart
            metric={report.metrics[metricIdx]}
            experiences={experiences}
          />

          <div className="form-group mt__sm">
            <label>Data Storytelling</label>

            <EditorInput
              className="z-0"
              content={report.description}
              onBlur={(value: any) => this.onUpdateDescription(value)}
            />
          </div>

          <ResultsTable
            currentUser={this.props.currentUser}
            metrics={reportTrait?.traits.metrics}
            experiment={this.props.experiment}
            report={report}
            experiences={experiences}
            onMetricUpdate={this.onUpdateMetrics}
            selectedRowIdx={metricIdx}
            onSelectedRow={this.setMetricIndex}
            onAddMetrics={
              report.type === "analytics"
                ? () => onAddAnalyticsMetrics
                : undefined
            }
          />
        </section>
      </React.Fragment>
    );
  }
}

const mapStateToProps = ({
  router,
  reportTrait,
}: DataTypes.ApplicationState) => ({
  router,
  reportTrait,
});

const mapDispatchToProps = {
  updateReportRequest,
  getReportTraitsRequest,
  updateMetricRequest,
};

const connectedPage = connect(mapStateToProps, mapDispatchToProps)(Report);

export { connectedPage as Report };
