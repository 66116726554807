import React from 'react';
import styled from 'styled-components';

interface Result {
  specified_significance: number;
  duration_in_days: number;
  sample_per_variation: number;
}
interface IProps {
  results: Array<Result>;
}
const SampleSizeResults = (props: IProps) => {
  const { results } = props;
  if (!results) return null;
  return (
    <StyledResults>
      {results.map((result: Result, index: number) => {
        const { specified_significance, duration_in_days, sample_per_variation } = result;
        return (
          <div key={index} className="flex-row">
            <div className="center">
              <div className="label">Significance</div>
              <div className="significance">{(100 * specified_significance).toFixed(0)}%</div>
            </div>
            <div>
              <div className="label">Duration in days</div>
              <div className="secondary-stat">{insertCommas(duration_in_days)}</div>
              <div className="label">Sample Size Per Experience</div>
              <div className="secondary-stat">{insertCommas(sample_per_variation)}</div>
            </div>
          </div>
        );
      })}
    </StyledResults>
  );
};

const insertCommas = (largeNumber: number) => {
  return largeNumber.toLocaleString();
};

const StyledResults = styled.div`
  background: #f0f3f4;
  padding: 10px 0px;
  margin-top: 30px;

  .flex-row {
    display: flex;
    justify-content: space-between;
    padding: 20px 0;
    margin: 0 15px;
    &:not(:last-child) {
      border-bottom: 1px lightgrey dotted;
    }

    .center {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
    .significance {
      font-weight: bold;
      font-size: 28px;
      color: #223e5a;
    }
    .label {
      font-size: 12px;
    }
    .secondary-stat {
      font-size: 24px;
    }
  }
`;

export { SampleSizeResults };
