import { Reducer } from 'redux';
import * as DataTypes from '../types';
import { ActionTypes } from './types';

const initialState: null = null;

const reducer: Reducer<Array<DataTypes.SampleSizeResult> | null> = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.POST_SAMPLE_SIZE_SUCCESS:
      return action.payload.response
    default: {
      return state;
    }
  }
};

export { reducer as calculatorReducer };
