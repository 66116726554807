import { Reducer } from 'redux';
import * as DataTypes from '../types';
import { ActionTypes } from './types';

const initialState: DataTypes.Account | null = null;

const reducer: Reducer<DataTypes.Account | null> = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.CREATE_ACCOUNT_SUCCESS: {
      return action.payload;
    }
    case ActionTypes.UPDATE_ACCOUNT_PROFILE_SUCCESS: {
      return { ...state, profile: action.payload.response };
    }
    case ActionTypes.UPDATE_ACCOUNT_PGS_SUCCESS: {
      return { ...state, program_goals: action.payload.response };
    }
    case ActionTypes.GET_ACCOUNT_SUCCESS: {
      return action.payload;
    }
    case ActionTypes.GET_ACCOUNT_SUMMARY_SUCCESS: {
      return {
        ...state,
        summary: action.payload.summary,
      };
    }
    case ActionTypes.GET_ACCOUNT_STATS_SUCCESS: {
      return {
        ...state,
        stats: action.payload.stats,
      };
    }
    case ActionTypes.GET_ACCOUNT_TRAITS_SUCCESS:
    case ActionTypes.GET_SHARE_ACCOUNT_TRAITS_SUCCESS: {
      return {
        ...state,
        traits: action.payload.traits,
      };
    }
    case ActionTypes.GET_ACCOUNT_APPS_SUCCESS: {
      return {
        ...state,
        apps: action.payload.apps,
      };
    }
    case ActionTypes.GET_APP_EXPS_SUCCESS: {
      let apps: Array<DataTypes.App> = [];
      if (state) {
        apps = state.apps;
        const aIdx = apps.findIndex((a) => a.type === action.payload.type);
        apps[aIdx] = { ...apps[aIdx], experiments: action.payload.response.experiments };
      }
      return {
        ...state,
        apps: apps,
      };
    }
    case ActionTypes.GET_APP_METRICS_SUCCESS: {
      let apps: Array<DataTypes.App> = [];
      if (state) {
        apps = state.apps;
        const aIdx = apps.findIndex((a) => a.type === action.payload.type);
        apps[aIdx] = { ...apps[aIdx], metrics: action.payload.response.metrics };
      }
      return {
        ...state,
        apps: apps,
      };
    }
    case ActionTypes.UPDATE_ACCOUNT_SETTINGS_SUCCESS: {
      return {
        ...state,
        settings: action.payload.settings,
      };
    }
    default: {
      return state;
    }
  }
};

export { reducer as accountReducer };
