import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import * as API from "../../api";
import * as Actions from "./actions";
import * as ExperimentActions from "../experiment/actions";
import * as AppTypes from "../app/types";
import { ActionTypes } from "./types";

function* createReportTarget(action: any) {
  try {
    const { experimentId, body } = action.payload;

    // @ts-ignore
    const res = yield call(API.ReportTarget.create, experimentId, body);

    yield put(Actions.createReportTargetSuccess(res.body));
    yield put(ExperimentActions.updateExperimentSuccess(res.body));
    yield put({ type: AppTypes.App.MODAL_HIDE });
  } catch (error: any) {
    yield put(Actions.createReportTargetError(error));
  }
}

function* watchCreateReportTarget() {
  yield takeEvery(ActionTypes.CREATE_REPORT_TARGET_REQUEST, createReportTarget);
}

function* reportTargetSaga() {
  yield all([fork(watchCreateReportTarget)]);
}

export default reportTargetSaga;
