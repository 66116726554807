import { Reducer } from "redux";
import * as DataTypes from "../types";
import { ActionTypes } from "./types";

const initialState: DataTypes.InsightProposals | null = null;

const reducer: Reducer<DataTypes.InsightProposals | null> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case ActionTypes.GET_INSIGHTS_PROPOSALS_SUCCESS:
      return action.payload.response;
    default: {
      return state;
    }
  }
};

export { reducer as insightsProposalsReducer };
