import { action } from 'typesafe-actions';
import { Dispatch } from 'redux';
import { ActionTypes } from './types';
import * as DataTypes from '../types';

interface RequestError {
  statusCode?: number | undefined;
  message: any;
}

export const getReportTraitRequestSaga = () => action(ActionTypes.GET_REPORTS_TRAITS_REQUEST, {});
export const getReportTraitsRequest = () => (dispatch: Dispatch) =>
  dispatch(action(ActionTypes.GET_REPORTS_TRAITS_REQUEST));
export const getReportTraitsSuccess = (response: DataTypes.ApiRequest<DataTypes.Trait>) =>
  action(ActionTypes.GET_REPORTS_TRAITS_SUCCESS, { response });
export const getReportTraitsError = (error: RequestError) => action(ActionTypes.GET_REPORTS_TRAITS_FAILURE, error);