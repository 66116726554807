import { all, call, fork, put, takeLatest } from "redux-saga/effects";
import * as API from "../../api";
import { ActionTypes } from "./types";
import * as Actions from "./actions";

function* getInsightsWordCloud(action: any) {
  try {
    // @ts-ignore
    const res = yield call(API.Insights.wordCloud);

    yield put(Actions.getInsightWordCloudSuccess(res.body));
  } catch (error: any) {
    yield put(Actions.getInsightWordCloudError(error));
  }
}

function* watchGetInsightsWordCloud() {
  yield takeLatest(
    ActionTypes.GET_INSIGHTS_WORD_CLOUD_REQUEST,
    getInsightsWordCloud
  );
}

function* insightsWordCloudSaga() {
  yield all([fork(watchGetInsightsWordCloud)]);
}

export default insightsWordCloudSaga;
