import React, { Component } from 'react';
import * as DataTypes from 'store/types';

interface IProps {
  experiment: DataTypes.Experiment;
  saving: boolean;
  onSubmit(selectedExperiences: Array<string>): void;
}

interface IState {
  selectedExperiences: Array<string>;
}

class SelectExperiences extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    const { experiment } = this.props;

    this.state = {
      selectedExperiences: experiment.case_study?.experiences || [],
    };
  }

  private onCheck = (checked: boolean, experience: DataTypes.Experience) => {
    const sExperiences = this.state.selectedExperiences;
    if (checked === true) {
      sExperiences.push(experience.id);
    } else {
      const iIdx = sExperiences.findIndex((i) => i === experience.id);
      if (iIdx >= 0) sExperiences.splice(iIdx, 1);
    }

    this.setState({ ...this.state, selectedExperiences: sExperiences });
  };

  render() {
    const { experiment, onSubmit, saving } = this.props;
    const { selectedExperiences } = this.state;
    return (
      <React.Fragment>
        <div className="header">
          <h3>Select Experiences</h3>
          <p>Select the experiences to show in the case study.</p>
        </div>
        <div className="body">
          {experiment.experiences.map((exp, idx) => {
            return (
              <div key={`exp_${exp.id}`} className="ExperienceSelector">
                <div className="selector">
                  <label className="CheckBox">
                    <input type="checkbox" defaultChecked={selectedExperiences.includes(exp.id)} onChange={(e) => this.onCheck(e.target.checked, exp)} />
                    <span className="checkmark"></span>
                  </label>
                </div>
                <div className="experience">
                  {exp.type === 'Experiment::Experience::Control' && (
                    <div className="head">
                      <div className="hexagon control">
                        <label>ctl</label>
                      </div>
                      <h4>{exp.name}</h4>
                    </div>
                  )}
                  {exp.type === 'Experiment::Experience::Variation' && (
                    <div className="head">
                      <div className="hexagon">
                        <label>V{idx}</label>
                      </div>
                      <h4>{exp.name}</h4>
                    </div>
                  )}
                </div>
              </div>
            );
          })}
        </div>
        <div className="cntrl-bar">
          {saving === true ? (
            <button className="btn btn-primary" type="submit" disabled={true}>
              <i className="fas fa-circle-notch fa-spin" />
            </button>
          ) : (
            <button className="btn btn-primary" onClick={() => onSubmit(selectedExperiences)}>
              save
            </button>
          )}
        </div>
      </React.Fragment>
    );
  }
}

export { SelectExperiences };
