import React, { useEffect, useState, useRef } from "react";
import { AuthService } from "utils/auth";
import { handleOutsideClick, onEnterPress } from "./utils";

interface IProps {
  name?: string;
  placeholder: string;
  value: any;
  items: Array<any>;
  itemKey?: string;
  disableAddNew?: boolean;
  searchEnabled?: boolean;
  disabled?: boolean;
  removeable?: boolean;
  onChange?(value: any): void;
  className?: string;
  ignoreReadOnly?: boolean;
  buttonProps?: any;
}

const SelectInput = ({
  buttonProps = {},
  name,
  value,
  placeholder,
  items,
  itemKey,
  disabled,
  disableAddNew,
  searchEnabled,
  onChange,
  className,
  removeable,
  ignoreReadOnly,
}: IProps) => {
  const ref = useRef<any>();

  const [edit, setEdit] = useState(false);
  const [searchVal, setSearch] = useState("");
  const [hoverIdx, setHoverIndex] = useState(0);
  const [results, setResults] = useState(items);

  const [exactMatch, setExactMatch] = useState(false);

  useEffect(() => {
    setResults(items);
  }, [items]);

  onEnterPress(ref, () => {
    if (searchVal !== "" && exactMatch === false) {
      if (onChange) onChange(searchVal);
    } else {
      if (results[hoverIdx]) {
        if (onChange) onChange(results[hoverIdx]);
      }
    }
    open(false);
  });

  handleOutsideClick(ref, () => {
    if (edit) {
      setSearch("");
      setHoverIndex(0);
      open(!edit);
    }
  });

  const open = (value: any) => {
    if (ignoreReadOnly) {
      setEdit(value);
      return;
    }

    if (isReadOnly()) {
      return;
    }

    setEdit(value);
  };

  const isReadOnly = () => {
    const user = AuthService.getActiveUser();

    return user?.role === "read-only";
  };

  const setSearchValue = (value: string) => {
    setHoverIndex(-1);
    setSearch(value);

    let exact;
    let results;
    if (itemKey) {
      results = items.filter((val) =>
        val[itemKey].toLowerCase().startsWith(value.toLowerCase())
      );
      exact =
        results.filter(
          (val) => val[itemKey].toLowerCase() === value.toLowerCase()
        ).length > 0;
    } else {
      results = items.filter((val) =>
        val.toLowerCase().startsWith(value.toLowerCase())
      );
      exact =
        results.filter((val) => val.toLowerCase() === value.toLowerCase())
          .length > 0;
    }

    setExactMatch(exact);

    if (exact === true) setHoverIndex(0);

    setResults(results);
  };

  const setValue = (value: any) => {
    if (onChange) onChange(value);
    open(!edit);
  };

  return (
    <div
      ref={ref}
      className={`${
        edit
          ? "SelectInput edit"
          : disabled === true
          ? "SelectInput disabled"
          : "SelectInput"
      } ${className || ""}`}
    >
      <button
        {...buttonProps}
        type="button"
        onClick={(e) => disabled !== true && open(!edit)}
      >
        {value ? value : <span className="placeholder">{placeholder}</span>}
      </button>
      <div className="menu">
        {searchEnabled === true && (
          <div className="search">
            <input
              type="text"
              placeholder={
                disableAddNew === true ? "Search " : "Search or add new"
              }
              value={searchVal}
              onChange={(e) => setSearchValue(e.target.value)}
            />
          </div>
        )}
        <div className="results">
          <ul>
            {disableAddNew !== true &&
              searchVal !== "" &&
              exactMatch === false && (
                <li
                  className={hoverIdx === -1 ? "over new" : "new"}
                  onMouseOver={(e) => setHoverIndex(-1)}
                  onClick={(e) => setValue(searchVal)}
                >
                  {searchVal}
                </li>
              )}
            {results.map((item, idx) => (
              <li
                key={`${name}_${item}_${idx}`}
                className={
                  hoverIdx === idx
                    ? item === value || (itemKey && item[itemKey] === value)
                      ? removeable === true
                        ? "over active"
                        : "over"
                      : "over"
                    : ""
                }
                onMouseOver={(e) => setHoverIndex(idx)}
                onClick={(e) => setValue(item)}
              >
                {itemKey ? item[itemKey] : item}
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export { SelectInput };
