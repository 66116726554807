import React, { Component } from 'react';
import * as DataTypes from 'store/types';
import { ExperimentIcon, ExperimentStatusIcon, TextInput, StarControl, MultiSelectInput, Avatar } from 'ui';
import { Helpers } from 'utils';
import TimeAgo from 'timeago-react';
import { createExperimentsTraitsRequest } from 'store/experiment_trait/actions';

import 'css/Experiments.scss';
import { ExperimentLifts } from './experiment_lifts';
import { EditorInput } from 'ui/editor_input/editor_input';
import { connect } from 'react-redux';

interface IProps {
  loading: boolean;
  currentUser: any;
  account: DataTypes.Account | null;
  experiment: DataTypes.Experiment | null;
  traits: { [key: string]: Array<DataTypes.Trait> } | null;
  onChange(experiment: DataTypes.Experiment, key: string, value: string | Array<string> | boolean): void;
  createExperimentsTraitsRequest: (experimentId: string, traitName: string, values: string[]) => void;
}

class Aside extends Component<IProps> {
  render() {
    const { account, loading, experiment, traits } = this.props;

    if (loading) {
      return (
        <div className="Experiment placeholder">
          <section className="header"></section>
          <section className="details">
            <h4>&nbsp;</h4>
            <div className="status"></div>
            <p></p>
            <p></p>
            <p></p>
          </section>
          <hr />
          <section></section>
        </div>
      );
    }

    if (!experiment) return null;

    return (
      <div className="Experiment">
        <section className="header">
          <div className="left">
            <ExperimentStatusIcon key={['status', experiment.id].join('_')} status={experiment.status} />
          </div>
          <div className="right">
            <div className="controls">
              <StarControl starred={experiment.star} onClick={(value) => this.props.onChange(experiment, 'star', value)} />
            </div>
          </div>
        </section>
        <section className="title-bar">
          <div>
            <p className="date">
              Updated <TimeAgo datetime={experiment.updatedAt} live={false} />
            </p>
            <TextInput
              readonly={this.props.currentUser.role === 'read-only'}
              key={['name', experiment.id].join('_')}
              value={experiment.name || ''}
              onChange={(value) => this.props.onChange(experiment, 'name', value)}
            >
              <h2>{experiment.name}</h2>
            </TextInput>
            <p className="date">
              {Helpers.formatDate(experiment.startDate)} - {Helpers.formatDate(experiment.endDate)}
            </p>
          </div>
          <div>
            <ExperimentIcon key={['type', experiment.id].join('_')} experimentType={experiment.type} adobe={experiment.adobe?.type} />
          </div>
        </section>

        <section>
          <div className="userInfo">
            <div className="img">
              <div className="avatar-stack">
                {experiment.contributors && experiment.contributors.reverse().map((contributor: any, idx) => {
                  if (contributor.user === null) {
                    return null;
                  }

                  if (contributor.user) {
                    return (<div style={{ position: 'relative', left: `${8 * idx - 8}px` }} key={`user_${contributor.user.id}`}>
                      <Avatar user={contributor.user} showHover={true} />
                    </div>)
                  }
                  return (
                    <div style={{ position: 'relative', left: `${8 * idx - 8}px` }} key={`user_${contributor.id}`}>
                      <Avatar user={contributor} showHover={true} />
                    </div>
                  );
                })}
                {experiment.author && (
                  <div style={{ position: 'relative' }}>
                    <Avatar user={experiment.author} showHover={true} className="focus author" />
                  </div>
                )}
              </div>
            </div>
          </div>
        </section>

        <ExperimentLifts experiment={this.props.experiment} />

        <section className="questions">
          <div className="question">
            <label>Summary</label>
            <EditorInput
              onBlur={(value) => this.props.onChange(experiment, 'description', value)}
              content={experiment.description} />
          </div>
          <div className="question">
            <label>Audiences</label>
            <MultiSelectInput
              key={['audiences', experiment.id].join('_')}
              activeItems={experiment.audiences}
              name="audiences"
              items={
                traits && traits['audiences']
                  ? Helpers.uniqueArray(
                    traits['audiences'].map((t) => {
                      return t.name;
                    })
                  )
                  : []
              }
              onChange={(values) => this.props.createExperimentsTraitsRequest(experiment.id || '', 'audiences', values)}
            />
          </div>
          <div className="question">
            <label>Devices</label>
            <MultiSelectInput
              key={['devices_', experiment.id].join('_')}
              activeItems={experiment.devices}
              name="devices"
              items={
                traits && traits['devices']
                  ? Helpers.uniqueArray(
                    traits['devices'].map((t) => {
                      return t.name;
                    })
                  )
                  : []
              }
              onChange={(values) => this.props.createExperimentsTraitsRequest(experiment.id || '', 'devices', values)}
            />
          </div>
          <div className="question">
            <label>Pages</label>
            <MultiSelectInput
              key={['pages_', experiment.id].join('_')}
              activeItems={experiment.pages}
              name="pages"
              items={
                traits && traits['pages']
                  ? Helpers.uniqueArray(
                    traits['pages'].map((t) => {
                      return t.name;
                    })
                  )
                  : []
              }
              onChange={(values) => this.props.createExperimentsTraitsRequest(experiment.id || '', 'pages', values)}
            />
          </div>

          {experiment.author && (
            <div className="question">
              <label>Creator</label>
              <p>{experiment.author.first_name} {experiment.author.last_name}</p>
            </div>
          )}

          {account && account.settings.brands && (
            <div className="question">
              <label>Brands</label>
              <MultiSelectInput
                key={['brands_', experiment.id].join('_')}
                activeItems={experiment.brands || []}
                name="brands"
                items={
                  traits && traits['brands']
                    ? Helpers.uniqueArray(
                      traits['brands'].map((t) => {
                        return t.name;
                      })
                    )
                    : []
                }
                onChange={(values) => this.props.createExperimentsTraitsRequest(experiment.id || '', 'brands', values)}
              />
            </div>
          )}
          {account && account.settings.teams && (
            <div className="question">
              <label>Teams</label>
              <MultiSelectInput
                key={['teams_', experiment.id].join('_')}
                activeItems={experiment.teams || []}
                name="teams"
                items={
                  traits && traits['teams']
                    ? Helpers.uniqueArray(
                      traits['teams'].map((t) => {
                        return t.name;
                      })
                    )
                    : []
                }
                onChange={(values) => this.props.createExperimentsTraitsRequest(experiment.id || '', 'teams', values)}
              />
            </div>
          )}
          {account && account.settings.channels && (
            <div className="question">
              <label>Channels</label>
              <MultiSelectInput
                key={['channels_', experiment.id].join('_')}
                activeItems={experiment.channels || []}
                name="channels"
                items={
                  traits && traits['channels']
                    ? Helpers.uniqueArray(
                      traits['channels'].map((t) => {
                        return t.name;
                      })
                    )
                    : []
                }
                onChange={(values) => this.props.createExperimentsTraitsRequest(experiment.id || '', 'channels', values)}
              />
            </div>
          )}
        </section>
        <hr className="dashed" />
        <section className="experiences">
          <label>Experiences</label>
          <ul>
            {experiment.experiences.map((exp) => {
              return (
                <li key={['exp_', exp.id].join('_')}>
                  <div>
                    {exp.type === 'Experiment::Experience::Control' && <i className="fa fa-star" />}
                    <p>{exp.name}</p>
                  </div>
                  <div>
                    <p>{exp.traffic}%</p>
                  </div>
                </li>
              );
            })}
          </ul>
        </section>
      </div>
    );
  }
}

const mapStateToProps = ({ router }: DataTypes.ApplicationState) => ({
  router,
});

const mapDispatchToProps = {
  createExperimentsTraitsRequest,
};

const connectedPage = connect(mapStateToProps, mapDispatchToProps)(Aside);

export { connectedPage as Aside };
