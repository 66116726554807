import React from "react";
import {
  Editor,
  EditorState,
  RichUtils,
  convertFromRaw,
  convertToRaw,
} from "draft-js";
import { v4 as uuidv4 } from "uuid";
import { EditorToolbar } from "./editor_toolbar";
import "draft-js/dist/Draft.css";
import plugins from "./plugins";
import { connect } from "react-redux";
import { showModal, hideModal } from "store/app/actions";
import { AuthService } from "utils/auth";

const styleMap = {
  STRIKETHROUGH: {
    textDecoration: "line-through",
  },
};

interface IProps {
  content?: any;
  readOnly?: boolean;
  onBlur(value: any): any;
  area?: boolean;
  errorMessage?: string;
  required?: boolean;
  submitted?: boolean;
  className?: string;
  showModal: (component: React.ComponentType<any>, options: any) => void;
  hideModal: () => void;
  alwaysFocused?: boolean;
  alwaysShowTollbar?: boolean;
}

interface IState {
  editorState: EditorState;
  inlineToolbar: any;
  id: string;
  pristine: boolean;
}

class EditorInput extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      editorState: props.content
        ? EditorState.createWithContent(convertFromRaw(props.content), plugins)
        : EditorState.createEmpty(plugins),
      pristine: true,
      id: uuidv4(),
      inlineToolbar: {
        show: false,
        position: {
          top: 0,
          left: 0,
        },
      },
    };
  }

  getSelectionRange = () => {
    const selection = window.getSelection();
    if (!selection || selection.rangeCount === 0) return null;
    return selection.getRangeAt(0);
  };

  getSelectionCoords = (selectionRange: any) => {
    const worksheetElem = document.getElementById(this.state.id);
    if (worksheetElem && worksheetElem !== null) {
      const editorBounds = worksheetElem.getBoundingClientRect();
      const rangeBounds = selectionRange.getBoundingClientRect();
      const rangeWidth = rangeBounds.right - rangeBounds.left;

      // 240 is width of inline toolbar
      let offsetLeft =
        rangeBounds.left - editorBounds.left + rangeWidth / 2 - 240 / 2;
      if (offsetLeft <= 0) {
        offsetLeft = 0;
      }

      // 42px is height of inline toolbar
      const offsetTop = rangeBounds.top - editorBounds.top - 30;

      return { offsetLeft, offsetTop };
    }
  };

  setInlineToolbar = (inlineToolbar: any) => {
    this.setState({
      inlineToolbar,
    });
  };

  setEditorState = (editorState: EditorState) => {
    this.setState({
      editorState,
    });
  };

  onEditorChange = (editorState: EditorState) => {
    if (this.isReadOnly()) {
      return;
    }

    if (!editorState.getSelection().isCollapsed()) {
      const selectionRange = this.getSelectionRange();

      if (!selectionRange) {
        this.setInlineToolbar({ show: false, position: { top: 0, left: 0 } });
        return;
      }

      const selectionCoords = this.getSelectionCoords(selectionRange);

      this.setInlineToolbar({
        show: true,
        position: {
          top: selectionCoords?.offsetTop || 0,
          left: selectionCoords?.offsetLeft || 0,
        },
      });
    } else {
      this.setInlineToolbar({ show: false, position: { top: 0, left: 0 } });
    }

    this.setEditorState(editorState);
  };

  editorActions = (style: string): any => {
    return {
      inline: () =>
        this.onEditorChange(
          RichUtils.toggleInlineStyle(this.state.editorState, style)
        ),
      block: () =>
        this.onEditorChange(
          RichUtils.toggleBlockType(this.state.editorState, style)
        ),
      link: () => this.insertLink(style),
      removeLink: () => this.removeLink(),
    };
  };

  toggleInlineStyle = (type: string, style: string) => {
    this.editorActions(style)[type]();
  };

  insertLink = (link: string) => {
    this.onEditorChange(
      RichUtils.toggleInlineStyle(this.state.editorState, "link")
    );
    const selection = this.state.editorState.getSelection();
    const content = this.state.editorState.getCurrentContent();
    const contentWithEntity = content.createEntity("LINK", "MUTABLE", {
      url: link,
    });

    const newEditorState = EditorState.push(
      this.state.editorState,
      contentWithEntity,
      "apply-entity"
    );
    const entityKey = contentWithEntity.getLastCreatedEntityKey();
    this.onEditorChange(
      RichUtils.toggleLink(newEditorState, selection, entityKey)
    );
  };

  hasError = () => {
    if (this.state.pristine && !this.props.submitted) {
      return false;
    }

    if (
      this.props.submitted &&
      this.props.required &&
      this.props.errorMessage
    ) {
      return !this.state.editorState.getCurrentContent().hasText();
    }

    return false;
  };

  removeLink = () => {
    const { editorState } = this.state;
    const selection = editorState.getSelection();
    if (!selection.isCollapsed()) {
      this.setState({
        editorState: RichUtils.toggleLink(editorState, selection, null),
      });
    }
  };

  onChange = (editorState: EditorState) => {
    this.setState({ editorState });
  };

  onBlur = () => {
    const content = convertToRaw(this.state.editorState.getCurrentContent());
    this.setState({ pristine: false });
    this.props.onBlur(content);
  };

  styleClass = () => {
    if (this.props.readOnly) {
      return "";
    }

    return "writeable";
  };

  private isReadOnly = () => {
    const user = AuthService.getActiveUser();

    if (this.props.readOnly === false) {
      return false;
    }

    if (!user || user.role === "read-only") {
      return true;
    }

    if (!this.props.readOnly) {
      return false;
    }

    return this.props.readOnly;
  };

  render() {
    const { inlineToolbar, editorState } = this.state;

    const selectionState = editorState.getSelection();
    const anchorKey = selectionState.getAnchorKey();
    const currentContent = editorState.getCurrentContent();
    const currentContentBlock = currentContent.getBlockForKey(anchorKey);
    const start = selectionState.getStartOffset();
    const end = selectionState.getEndOffset();
    const selectedText = currentContentBlock.getText().slice(start, end);

    return (
      <React.Fragment>
        <div
          className={`EditorInput ${
            this.props.alwaysShowTollbar ? "visible-toolbar" : ""
          }
          ${
            this.props.alwaysShowTollbar && selectedText !== ""
              ? "has-selection"
              : ""
          }
           ${
             this.props.alwaysFocused ? "always-focused" : ""
           } ${this.styleClass()} ${this.props.area ? "textarea" : ""} ${
            this.props.className || ""
          } ${this.hasError() ? "error" : ""}`}
          id={this.state.id}
        >
          <EditorToolbar
            show={this.props.alwaysShowTollbar ? true : inlineToolbar.show}
            position={
              this.props.alwaysShowTollbar
                ? { top: 8, left: 12 }
                : inlineToolbar.position
            }
            editorState={editorState}
            onToggle={this.toggleInlineStyle}
            showModal={this.props.showModal}
            hideModal={this.props.hideModal}
          />

          <Editor
            customStyleMap={styleMap}
            editorState={this.state.editorState}
            onChange={this.onEditorChange}
            readOnly={this.isReadOnly()}
            onBlur={this.onBlur}
            spellCheck={true}
          />
        </div>

        {this.hasError() && (
          <div className="error">{this.props.errorMessage}</div>
        )}
      </React.Fragment>
    );
  }
}

const mapStateToProps = () => ({});

const mapDispatchToProps = {
  showModal,
  hideModal,
};

const connectedPage = connect(mapStateToProps, mapDispatchToProps)(EditorInput);

export { connectedPage as EditorInput };
