import React, { Component } from "react";
import { connect } from "react-redux";
import moment from "moment";
import {
  createLoadingSelector,
  createErrorMessageSelector,
} from "store/selectors";
import * as DataTypes from "store/types";
import { AppPage, AppPageProps } from "comps/pages";
import { ApplicationState } from "store/types";
import {
  initExpCreateRequest,
  createABExperimentRequest,
  createAdobeExperimentRequest,
} from "store/experiment/actions";
import { showIdeaRequest, clearIdeaRequest } from "store/idea/actions";
import { getAppExpsRequest } from "store/account/actions";
import { getExperimentsTraitsRequest } from "store/experiment_trait/actions";
import {
  Form,
  Input,
  ValidatorTypes,
  MultiSelect,
  DatePicker,
  Button,
} from "comps/form";
import { SelectInput } from "ui";
import { AccountLoader } from "ui/loaders";
import { Helpers, history } from "utils";
import { convertFromRaw, EditorState, genKey } from "draft-js";
import "css/Screens.scss";
import { EditorInput } from "ui/editor_input/editor_input";

enum ExperimentTypes {
  AB = "ab",
  ADOBE = "adobe",
}

export enum CreateMode {
  MANUAL = "manual",
  IMPORT = "import",
}

interface IProps extends AppPageProps {
  router: any;
  account: DataTypes.Account | null;
  idea: DataTypes.Idea | null;
  loading: boolean;
  saving: boolean;
  appExpLoading: boolean;
  computedMatch: any;
  experimentTrait: any;
  ideaManagement: any;
  initExpCreateRequest: () => void;
  getAppExpsRequest: (type: string) => void;
  showIdeaRequest: (id: string, callback: Function) => void;
  clearIdeaRequest: () => void;
  createABExperimentRequest: (body: any) => void;
  createAdobeExperimentRequest: (body: any) => void;
  getExperimentsTraitsRequest: () => void;
}

interface IState {
  experiences: Array<any>;
  mode: CreateMode;
  selectedApp?: DataTypes.App;
  selectedAppExperiment?: any;
  ideas?: any;
  selectedAppError: boolean;
  selectedAppExperimentError: boolean;
  minimumEndDate?: Date;
  maximumStartDate?: Date;
  loading: Boolean;
  submitted: boolean;
}

class CreateExperiment extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      mode: this.props.router.location.query.mode || CreateMode.MANUAL,
      experiences: [
        { name: "Control", traffic: "50", type: "control" },
        { name: "Experience #1", traffic: "50", type: "variation" },
      ],
      selectedAppError: false,
      selectedAppExperimentError: false,
      minimumEndDate: moment().toDate(),
      maximumStartDate: moment().add(1, "year").toDate(),
      loading: true,
      submitted: false,
    };
  }

  escFunction = (event: any) => {
    if (event.keyCode === 27) {
      history.goBack();
    }
  };

  componentDidMount = () => {
    this.props.initExpCreateRequest();
    this.props.getExperimentsTraitsRequest();

    if (this.props.router.location.query.tool) {
      this.props.getAppExpsRequest(this.props.router.location.query.tool);
    }
    document.addEventListener("keydown", this.escFunction, false);
  };

  componentWillMount = () => {
    this.props.clearIdeaRequest();

    if (this.isPromote()) {
      const numberOfIdeas = this.props.ideaManagement.selected.length;

      if (numberOfIdeas === 0) {
        history.goBack();
      }

      const trafficPercentage = 100 / (numberOfIdeas + 1);

      this.setState({
        ...this.state,
        loading: false,
        ideas: this.props.ideaManagement.selected,
        experiences: [
          {
            name: "Control",
            traffic: `${trafficPercentage.toFixed(0)}`,
            type: "control",
          },
          ...this.props.ideaManagement.selected.map((idea: any) => ({
            name: idea.name,
            type: "variation",
            traffic: `${trafficPercentage.toFixed(0)}`,
            difference: idea.proposed,
            hypothesis: idea.hypothesis,
            idea,
          })),
        ],
      });
    } else {
      this.setState({
        loading: false,
      });
    }
  };

  componentWillUnmount = () => {
    document.removeEventListener("keydown", this.escFunction, false);
  };

  private close = () => {
    history.goBack();
  };

  private onSubmit = (valid: boolean, dirty: boolean, data: any) => {
    const { experiences } = this.state;

    let summaryStateValid = false;
    if (data.summary.value) {
      summaryStateValid = EditorState.createWithContent(
        convertFromRaw(data.summary.value)
      )
        .getCurrentContent()
        .hasText();
    }

    if (valid && summaryStateValid) {
      const experiment = {
        type: ExperimentTypes.AB,
        name: data.name.value,
        description: data.summary.value,
        start_date: data.start_date.value,
        end_date: data.end_date.value,
        audiences: data.audiences.value,
        devices: data.devices.value,
        pages: data.pages.value,
        experiences: experiences,
        is_single_hypothesis: data.is_single_hypothesis.value,
        ideas:
          history.location.pathname === "/tests/create/idea"
            ? this.props.ideaManagement.selected
            : [],
      };
      this.props.createABExperimentRequest(experiment);
    } else {
      this.setState({ submitted: true });
    }
  };

  private onAppSubmit = (valid: boolean, dirty: boolean, data: any) => {
    const { selectedApp, selectedAppExperiment } = this.state;
    let errors = false;

    if (selectedApp === undefined || selectedAppExperiment === undefined) {
      errors = true;
      this.setState({
        ...this.state,
        selectedAppError: selectedApp === undefined ? true : false,
        selectedAppExperimentError:
          selectedAppExperiment === undefined ? true : false,
      });
    }

    if (valid && errors === false && selectedApp && selectedAppExperiment) {
      // Submit Adobe Target Experiment
      const experiment = {
        type: selectedApp.type,
        name: selectedAppExperiment.name,
        description: data.summary.value,
        audiences: data.audiences.value,
        devices: data.devices.value,
        pages: data.pages.value,
        start_date: selectedAppExperiment.start_date,
        end_date: selectedAppExperiment.end_date,
        adobe: {
          id: selectedAppExperiment.id,
          name: selectedAppExperiment.name,
          type: selectedAppExperiment.type,
        },
      };

      this.props.createAdobeExperimentRequest(experiment);
    }
  };

  private addExperience = () => {
    const { experiences } = this.state;
    experiences.push({
      name: `Experience #${experiences.length}`,
      traffic: "50",
      type: "variation",
    });
    this.setState({ ...this.state, experiences: experiences });
  };

  private removeExperience = (idx: number) => {
    const { experiences } = this.state;
    experiences.splice(idx, 1);
    this.setState({ ...this.state, experiences: experiences });
  };

  private experienceNameChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    idx: number
  ) => {
    let { experiences } = this.state;
    experiences[idx].name = e.target.value;
  };

  private experienceTrafficChange = (value: string, idx: number) => {
    let { experiences } = this.state;
    experiences[idx].traffic = value;
  };

  private toggleCreate = (mode: CreateMode) => {
    this.setState({ ...this.state, mode: mode });
  };

  private setApp = (app: any) => {
    this.setState({
      ...this.state,
      selectedApp: app,
      selectedAppError: false,
      selectedAppExperimentError: false,
      selectedAppExperiment: undefined,
    });

    this.props.getAppExpsRequest(app.type);
  };

  private onExperimentSelect = (experiment: any) => {
    this.setState({
      ...this.state,
      selectedAppExperiment: experiment,
      selectedAppExperimentError: false,
    });
  };

  componentDidUpdate = () => {
    const { account, loading, appExpLoading } = this.props;
    const { selectedApp } = this.state;
    if (account && account.apps) {
      if (
        loading === false &&
        appExpLoading === false &&
        this.props.router.location.query.tool &&
        selectedApp === undefined
      ) {
        const app = account.apps.find(
          (a) => a.type === this.props.router.location.query.tool
        );
        if (app) {
          const exp = app.experiments.find(
            (ex) => ex.id === this.props.router.location.query.tool_id
          );
          this.setState({
            ...this.state,
            selectedApp: app,
            selectedAppExperiment: exp,
          });
        }
      }
    }
  };

  private problem = () => {
    if (!this.isPromote()) {
      return "";
    }

    return {
      blocks: this.props.ideaManagement.selected
        .map((idea: any) => idea.problem.blocks)
        .flat()
        .map((i: any) => ({ ...i, key: genKey() })),
      entityMap: {},
    };
  };

  private isPromote = () => {
    return history.location.pathname === "/tests/create/idea";
  };

  private devices = () => {
    if (this.isPromote()) {
      return [
        ...new Set(
          this.props.ideaManagement.selected
            .map((idea: any) => idea.devices)
            .flat()
        ),
      ];
    }

    return [];
  };

  private audiences = () => {
    if (this.isPromote()) {
      return [
        ...new Set(
          this.props.ideaManagement.selected
            .map((idea: any) => idea.audiences)
            .flat()
        ),
      ];
    }
    return [];
  };

  private pages = () => {
    if (this.isPromote()) {
      return [
        ...new Set(
          this.props.ideaManagement.selected
            .map((idea: any) => idea.pages)
            .flat()
        ),
      ];
    }

    return [];
  };

  private ideaName = () => {
    if (!this.isPromote()) {
      return "";
    }

    if (this.props.ideaManagement.selected.length === 1) {
      return this.props.ideaManagement.selected[0].name;
    }

    return "";
  };

  render() {
    const { saving, loading, account, experimentTrait } = this.props;
    const {
      experiences,
      mode,
      selectedApp,
      selectedAppExperiment,
      selectedAppError,
      selectedAppExperimentError,
    } = this.state;

    if (loading === true) return null;
    const { query } = this.props.router.location;

    // Loader while getting experiment from testing tool
    if (query.tool && query.tool_id && query.mode) {
      if (!selectedApp) {
        return (
          <AccountLoader
            loaded={false}
            message="Loading experiment from testing tool"
          >
            <div></div>
          </AccountLoader>
        );
      }
    }

    if (!account) return null;

    let appExperiments: Array<any> | undefined;
    if (selectedApp && account.apps) {
      let app = account.apps.find((a) => a.id === selectedApp.id);
      appExperiments = app?.experiments;
    }

    return (
      <div className="FullScreen">
        <div className="header">
          <img src="/img/logo_sm.svg" alt="illuminate" className="logo" />
          <button type="button" className="btn btn-close" onClick={this.close}>
            <i className="fas fa-times" />
          </button>
        </div>
        <div className="form-body">
          <div className="content simple">
            <div className="title">
              {account.apps &&
                account.apps.length > 0 &&
                account.apps[0].enabled === true && (
                  <div className="controls">
                    {mode === CreateMode.MANUAL && !this.state.ideas && (
                      <button
                        className="btn btn-link"
                        type="button"
                        onClick={() => this.toggleCreate(CreateMode.IMPORT)}
                      >
                        <i className="fas fa-cloud-download-alt" /> import from
                        testing tool
                      </button>
                    )}

                    {mode === CreateMode.IMPORT && (
                      <button
                        className="btn btn-link"
                        type="button"
                        onClick={() => this.toggleCreate(CreateMode.MANUAL)}
                      >
                        <i className="fas fa-pen" /> create manual
                      </button>
                    )}
                  </div>
                )}

              {this.ideaName() === "" ? (
                <h2>Add Test</h2>
              ) : (
                <h2>
                  Promote <i>{this.ideaName()}</i> to a Test
                </h2>
              )}

              <p>
                {account.apps &&
                  account.apps.length > 0 &&
                  account.apps[0].enabled === true &&
                  !this.props.idea && (
                    <span>
                      Import an existing test from your testing tool, or type
                      your responses below.
                    </span>
                  )}
              </p>
            </div>

            {mode === CreateMode.MANUAL && !this.state.loading && (
              <Form
                formFields={{
                  name: {
                    name: "name",
                    value: this.ideaName(),
                    validator: {
                      type: ValidatorTypes.REQUIRED,
                      messages: { required: "Please enter a valid test name" },
                    },
                  },
                  summary: {
                    name: "summary",
                    value: this.problem(),
                    validator: {
                      type: ValidatorTypes.REQUIRED,
                      messages: {
                        required: "Please provide a summary of this test",
                      },
                    },
                  },
                  start_date: {
                    name: "start_date",
                    value: new Date(),
                    validator: {
                      type: ValidatorTypes.REQUIRED,
                      messages: {
                        required: "Please select a valid start date",
                      },
                    },
                  },
                  end_date: {
                    name: "end_date",
                    value: new Date(),
                    validator: {
                      type: ValidatorTypes.REQUIRED,
                      messages: { required: "Please select a valid end date" },
                    },
                  },
                  audiences: {
                    name: "audiences",
                    value: this.audiences(),
                    validator: {
                      type: ValidatorTypes.REQUIRED,
                      messages: {
                        required: "Please provide the audience(s) targeted",
                      },
                    },
                  },
                  devices: {
                    name: "devices",
                    value: this.devices(),
                    validator: {
                      type: ValidatorTypes.REQUIRED,
                      messages: {
                        required: "Please provide the device(s) targeted",
                      },
                    },
                  },
                  pages: {
                    name: "pages",
                    value: this.pages(),
                    validator: {
                      type: ValidatorTypes.REQUIRED,
                      messages: {
                        required: "Please provide the page(s) targeted",
                      },
                    },
                  },
                  is_single_hypothesis: {
                    name: "is_single_hypothesis",
                    value: false,
                  },
                }}
                onSubmit={this.onSubmit}
                FormComponent={({
                  fields: {
                    name,
                    summary,
                    start_date,
                    end_date,
                    audiences,
                    devices,
                    pages,
                    is_single_hypothesis,
                  },
                  onChange,
                }) => (
                  <div>
                    <section>
                      <div className="title">
                        <div className="step-circle">1</div>
                        <h4>overview</h4>
                      </div>
                      <div className="body">
                        <div className="form-group">
                          <label className="required">Test Name</label>
                          <Input
                            controlled={!!this.isPromote()}
                            field={name}
                            className="form-control"
                            onChange={(e) => onChange(name, e.target.value)}
                          />
                          <p>
                            This is the name of the test that will be displayed
                            across illuminate.
                          </p>
                        </div>
                        <div className="form-group">
                          <label className="required">summary</label>
                          <EditorInput
                            submitted={this.state.submitted}
                            content={summary.value}
                            onBlur={(e) => onChange(summary, e)}
                            errorMessage={
                              "Please provide a summary of this test"
                            }
                            required={true}
                            area={true}
                          />
                          <p>
                            If you had to tell your friend from another
                            department about this test, how would you summarize
                            it? What inspired it, what was your strategy, what
                            happened, what’s next? Tell a story, be casual, and
                            have fun!
                          </p>
                        </div>
                        <div className="form-group">
                          <label className="required">Test Dates</label>
                          <ul className="DateRangePicker">
                            <li>
                              <DatePicker
                                field={start_date}
                                className="form-control"
                                onChange={(value) => {
                                  const minimumEndDate = moment(value).toDate();
                                  this.setState({ minimumEndDate });

                                  onChange(start_date, value, () => {
                                    if (end_date.value < value) {
                                      return {
                                        [end_date.name]: { ...end_date, value },
                                      };
                                    }

                                    return {};
                                  });
                                }}
                                maxDate={this.state.maximumStartDate}
                              />
                            </li>
                            <li className="seperator">to</li>
                            <li>
                              <DatePicker
                                field={end_date}
                                className="form-control"
                                onChange={(value) => {
                                  const maximumStartDate =
                                    moment(value).toDate();
                                  this.setState({ maximumStartDate });
                                  onChange(end_date, value);
                                }}
                                minDate={this.state.minimumEndDate}
                              />
                            </li>
                          </ul>
                          <p>
                            Add the start date and end date of this test. They
                            can be in the past, present or future.
                          </p>
                        </div>
                      </div>
                    </section>
                    <section>
                      <div className="title">
                        <div className="step-circle">2</div>
                        <h4>targeting</h4>
                      </div>
                      <div className="body">
                        <div className="form-group">
                          <label className="required">audiences</label>
                          <MultiSelect
                            field={audiences}
                            options={
                              experimentTrait?.traits["audiences"]
                                ? Helpers.uniqueArray(
                                    experimentTrait?.traits["audiences"].map(
                                      (t: any) => {
                                        return t.name;
                                      }
                                    )
                                  )
                                : []
                            }
                            className="multiselect_input create"
                            onChange={(value) => onChange(audiences, value)}
                          />
                          <p>
                            Specify what audience(s) are targeted in this test.
                          </p>
                        </div>
                        <div className="form-group">
                          <label className="required">devices</label>
                          <MultiSelect
                            field={devices}
                            options={
                              experimentTrait?.traits["devices"]
                                ? Helpers.uniqueArray(
                                    experimentTrait?.traits["devices"].map(
                                      (t: any) => {
                                        return t.name;
                                      }
                                    )
                                  )
                                : []
                            }
                            className="multiselect_input create"
                            onChange={(value) => onChange(devices, value)}
                          />
                          <p>
                            Specify which device(s) are targeted in this test.
                          </p>
                        </div>
                        <div className="form-group">
                          <label className="required">pages</label>
                          <MultiSelect
                            field={pages}
                            options={
                              experimentTrait?.traits["pages"]
                                ? Helpers.uniqueArray(
                                    experimentTrait?.traits["pages"].map(
                                      (t: any) => {
                                        return t.name;
                                      }
                                    )
                                  )
                                : []
                            }
                            className="multiselect_input create"
                            onChange={(value) => onChange(pages, value)}
                          />
                          <p>
                            Specify the page(s) that are targeted in this test.
                          </p>
                        </div>
                      </div>
                    </section>
                    <section>
                      <div className="title">
                        <div className="step-circle">3</div>
                        <h4>experiences</h4>
                        <p>
                          Tests are made up of experiences, usually a control
                          and challenger(s). You can add/modify your experiences
                          later.
                        </p>
                      </div>
                      <div className="body">
                        <table className="experiences">
                          <thead>
                            <tr>
                              <th>
                                <label>experience name</label>
                              </th>
                              <th>
                                <label>traffic %</label>
                              </th>
                              <th></th>
                            </tr>
                          </thead>
                          <tbody>
                            {experiences.map((exp: any, idx: number) => {
                              return (
                                <tr key={`experience_${idx}`}>
                                  <td>
                                    <div className="input">
                                      <input
                                        type="text"
                                        className="form-control"
                                        maxLength={150}
                                        defaultValue={exp.name}
                                        onChange={(e) =>
                                          this.experienceNameChange(e, idx)
                                        }
                                      />
                                    </div>
                                  </td>
                                  <td>
                                    <TrafficInput
                                      value={exp.traffic}
                                      onUpdate={(value) =>
                                        this.experienceTrafficChange(value, idx)
                                      }
                                    />
                                  </td>
                                  <td>
                                    {exp.type === "variation" && (
                                      <button
                                        type="button"
                                        className="btn btn-close"
                                        onClick={() =>
                                          this.removeExperience(idx)
                                        }
                                      >
                                        <i className="fas fa-times" />
                                      </button>
                                    )}
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>

                        <div className="form-group">
                          <label className="CheckBox">
                            <input
                              type="checkbox"
                              defaultChecked={is_single_hypothesis.value}
                              onChange={(e) =>
                                onChange(is_single_hypothesis, e.target.checked)
                              }
                            />
                            <span
                              className="checkmark"
                              style={{ marginTop: "3px" }}
                            ></span>
                            Enable test-level{" "}
                            {this.props.account?.settings.use_bb_methods
                              ? "Whypothesis®"
                              : "hypothesis"}
                          </label>
                          <p
                            style={{
                              fontSize: "13px",
                              color: "#757e8e",
                              lineHeight: "18px",
                            }}
                          >
                            Check here for test-level{" "}
                            {this.props.account?.settings.use_bb_methods
                              ? "Whypothesis®"
                              : "hypothesis"}
                            . Otherwise, you will create a{" "}
                            {this.props.account?.settings.use_bb_methods
                              ? "Whypothesis®"
                              : "hypothesis"}{" "}
                            per experience.
                          </p>
                        </div>
                        <button
                          className="btn btn-link"
                          type="button"
                          onClick={this.addExperience}
                        >
                          <i className="fa fa-plus" />
                          Add experience
                        </button>
                      </div>
                    </section>
                    <hr />

                    <div
                      className="action-create"
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        gap: "1rem",
                        alignItems: "center",
                      }}
                    >
                      <Button
                        className="btn btn-primary"
                        text="save test"
                        isLoading={saving}
                      />
                      <p
                        style={{
                          fontSize: "13px",
                          color: "#757e8e",
                          lineHeight: "18px",
                        }}
                      >
                        Tip: There's more to do! Add additional details,
                        screenshots, reporting, and more in the next section
                      </p>
                    </div>
                  </div>
                )}
              />
            )}
            {mode === CreateMode.IMPORT && (
              <Form
                formFields={{
                  summary: {
                    name: "summary",
                    value: "",
                    validator: {
                      type: ValidatorTypes.REQUIRED,
                      messages: { required: "Enter a valid test summary" },
                    },
                  },
                  audiences: {
                    name: "audiences",
                    value: [],
                    validator: {
                      type: ValidatorTypes.REQUIRED,
                      messages: {
                        required: "Please provide the audience(s) targeted",
                      },
                    },
                  },
                  devices: {
                    name: "devices",
                    value: [],
                    validator: {
                      type: ValidatorTypes.REQUIRED,
                      messages: {
                        required: "Please provide the device(s) targeted",
                      },
                    },
                  },
                  pages: {
                    name: "pages",
                    value: [],
                    validator: {
                      type: ValidatorTypes.REQUIRED,
                      messages: {
                        required: "Please provide the page(s) targeted",
                      },
                    },
                  },
                }}
                onSubmit={this.onAppSubmit}
                FormComponent={({
                  fields: { summary, audiences, devices, pages },
                  onChange,
                }) => (
                  <div>
                    <section>
                      <div className="title">
                        <div className="step-circle">1</div>
                        <h4>Select Test</h4>
                      </div>
                      <div className="body">
                        <div className="form-group">
                          <label>Testing Tool</label>
                          <SelectInput
                            value={selectedApp ? selectedApp.type : ""}
                            name="app"
                            placeholder="select testing tool"
                            searchEnabled={false}
                            itemKey="type"
                            disableAddNew={true}
                            className={selectedAppError === true ? "error" : ""}
                            items={
                              account.apps
                                ? account.apps.map((t: any) => {
                                    return t;
                                  })
                                : []
                            }
                            onChange={(value) => this.setApp(value)}
                          />
                          {selectedAppError === true && (
                            <div className="error">
                              Please select the app you would like to use.
                            </div>
                          )}
                          <p>
                            Select the testing tool you would like to import
                            your test from.
                          </p>
                        </div>
                        <div className="form-group">
                          <label>Select Test</label>
                          <SelectInput
                            disabled={appExperiments ? false : true}
                            value={
                              selectedAppExperiment
                                ? selectedAppExperiment.name
                                : ""
                            }
                            name="experiment"
                            placeholder="select test"
                            searchEnabled={true}
                            itemKey="name"
                            disableAddNew={true}
                            className={
                              appExperiments &&
                              selectedAppExperimentError === true
                                ? "error"
                                : ""
                            }
                            items={
                              selectedApp && appExperiments
                                ? appExperiments.map((t) => {
                                    return t;
                                  })
                                : []
                            }
                            onChange={(value) => this.onExperimentSelect(value)}
                          />
                          {appExperiments &&
                            selectedAppExperimentError === true && (
                              <div className="error">
                                Please select the test you would like to import.
                              </div>
                            )}
                          <p>Select the experiment you would like to import.</p>
                        </div>
                        <div
                          className="form-group"
                          style={{ position: "relative", zIndex: 0 }}
                        >
                          <label>summary</label>
                          <EditorInput
                            submitted={this.state.submitted}
                            onBlur={(e) => onChange(summary, e)}
                            errorMessage={summary.error}
                            area={true}
                          />
                          <p>
                            If you had to tell your friend from another
                            department about this test, how would you summarize
                            what the test is about? What are some interesting
                            tidbits? Tell a story, be casual, and have fun!
                          </p>
                        </div>
                      </div>
                    </section>
                    <section>
                      <div className="title">
                        <div className="step-circle">2</div>
                        <h4>targeting</h4>
                        <p>
                          You can configure up to 10 experiences for an test.
                          Remember you can always add new experiences to an test
                          at any time.
                        </p>
                      </div>
                      <div className="body">
                        <div className="form-group">
                          <label>audiences</label>
                          <MultiSelect
                            field={audiences}
                            options={
                              experimentTrait?.traits["audiences"]
                                ? experimentTrait?.traits["audiences"].map(
                                    (t: any) => {
                                      return t.name;
                                    }
                                  )
                                : []
                            }
                            className="multiselect_input create"
                            onChange={(value) => onChange(audiences, value)}
                          />
                          <p>
                            Track which audiences your are test is targeting.
                          </p>
                        </div>
                        <div className="form-group">
                          <label>devices</label>
                          <MultiSelect
                            field={devices}
                            options={
                              experimentTrait?.traits["devices"]
                                ? experimentTrait?.traits["devices"].map(
                                    (t: any) => {
                                      return t.name;
                                    }
                                  )
                                : []
                            }
                            className="multiselect_input create"
                            onChange={(value) => onChange(devices, value)}
                          />
                          <p>Track which devices your are test is targeting.</p>
                        </div>
                        <div className="form-group">
                          <label>pages</label>
                          <MultiSelect
                            field={pages}
                            options={
                              experimentTrait?.traits["pages"]
                                ? experimentTrait?.traits["pages"].map(
                                    (t: any) => {
                                      return t.name;
                                    }
                                  )
                                : []
                            }
                            className="multiselect_input create"
                            onChange={(value) => onChange(pages, value)}
                          />
                          <p>Track which pages your are test is targeting.</p>
                        </div>
                      </div>
                    </section>
                    <hr />
                    <Button
                      className="btn btn-primary"
                      text="import test"
                      isLoading={saving}
                    />
                  </div>
                )}
              />
            )}
          </div>
        </div>
      </div>
    );
  }
}

const initLoader = createLoadingSelector([
  "@@experiment/INIT_EXP_CREATE",
  "@@experiment_traits/GET_EXPERIMENTS_TRAITS",
]);
const initError = createErrorMessageSelector(["@@experiment/INIT_EXP_CREATE"]);
const savingSelector = createLoadingSelector([
  "@@experiment/CREATE_EXPERIMENT",
]);

const appExpLoader = createLoadingSelector(["@@account/GET_APP_EXPS"]);

const mapStateToProps = ({
  router,
  app,
  account,
  idea,
  experimentTrait,
  ideaManagement,
}: ApplicationState) => ({
  loading: initLoader(app.requests),
  initError: initError(app.errors),
  appExpLoading: appExpLoader(app.requests),
  saving: savingSelector(app.requests),
  router,
  account,
  idea,
  experimentTrait,
  ideaManagement,
});

const mapDispatchToProps = {
  createABExperimentRequest,
  initExpCreateRequest,
  getAppExpsRequest,
  showIdeaRequest,
  clearIdeaRequest,
  getExperimentsTraitsRequest,
  createAdobeExperimentRequest,
};

const connectedPage = connect(
  mapStateToProps,
  mapDispatchToProps
)(AppPage(CreateExperiment));

export { connectedPage as CreateExperiment };

interface ITrafficInputProps {
  value: number | undefined;
  onUpdate(value: string): void;
}

interface ITrafficInputState {
  inputValue: string;
}

class TrafficInput extends Component<ITrafficInputProps, ITrafficInputState> {
  constructor(props: ITrafficInputProps) {
    super(props);
    this.state = {
      inputValue: this.props.value ? this.props.value.toString() : "",
    };
  }

  private onChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value.toString().trim();
    const numberRegex = /^\d*[\d]?[\d]*?$/;
    if (numberRegex.test(value) || value === "") {
      this.setState({ ...this.state, inputValue: value });
    } else {
      e.preventDefault();
    }
  };

  render() {
    const { onUpdate } = this.props;
    const { inputValue } = this.state;

    return (
      <div className="input percent">
        <input
          type="text"
          className="form-control"
          value={inputValue}
          maxLength={3}
          onChange={this.onChangeHandler}
          onBlur={(e) => onUpdate(e.target.value)}
        />
      </div>
    );
  }
}
