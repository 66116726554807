import React, { Component } from 'react';
import * as DataTypes from 'store/types';

interface IProps {
  experiment: DataTypes.Experiment;
  saving: boolean;
  onSubmit(selectedReports: Array<string>): void;
}

interface IState {
  selectedReports: Array<string>;
}

class SelectReports extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    const { experiment } = this.props;

    let setSelected: Array<string> = [];

    if (experiment.case_study?.reports) {
      experiment.reports.forEach((report, idx) => {
        if (report.id) {
          if (experiment.case_study?.reports?.includes(report.id)) {
            setSelected.push(report.id);
          }
        }
      });
    }

    this.state = {
      selectedReports: setSelected || [],
    };
  }

  private onCheck = (checked: boolean, report: DataTypes.Report) => {
    const sReport = this.state.selectedReports;
    if (report.id) {
      if (checked === true) {
        sReport.push(report.id);
      } else {
        const iIdx = sReport.findIndex((i) => i === report.id);
        if (iIdx >= 0) sReport.splice(iIdx, 1);
      }

      this.setState({ ...this.state, selectedReports: sReport });
    }
  };

  render() {
    const { experiment, onSubmit, saving } = this.props;
    const { selectedReports } = this.state;

    return (
      <React.Fragment>
        <div className="header">
          <h3>Select Reports</h3>
          <p>Use the checkboxes to select the reports you would like to show in this case study</p>
        </div>

        <div className="body">
          {experiment.reports.map((report) => {
            return (
              <div key={`report_${report.id}`} className="ReportSelector">
                <div className="selector">
                  <label className="CheckBox">
                    <input
                      type="checkbox"
                      defaultChecked={selectedReports.includes(report.id ? report.id : '0')}
                      onChange={(e) => this.onCheck(e.target.checked, report)}
                    />
                    <span className="checkmark"></span>
                  </label>
                </div>
                <div className="report">
                  <h4>{report.name}</h4>
                </div>
              </div>
            );
          })}
        </div>
        <div className="cntrl-bar">
          {saving === true ? (
            <button className="btn btn-primary" type="submit" disabled={true}>
              <i className="fas fa-circle-notch fa-spin" />
            </button>
          ) : (
            <button className="btn btn-primary" onClick={() => onSubmit(selectedReports)}>
              save
            </button>
          )}
        </div>
      </React.Fragment>
    );
  }
}

export { SelectReports };
