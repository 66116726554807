import React from "react";
import styled from "styled-components";
import { Spacer } from "comps/layout";

const Explanation = () => {
  return (
    <StyledModal>
      <div className="header">
        <h1 style={{ fontSize: "26px" }}>UX Research Studies in illuminate</h1>
        <p>
          Classic combinations: Peanut butter and jelly. Mario and Luigi. Tests
          and UX Research.
        </p>
        <p className="mt-2">
          We know that great insights come from multiple sources. Now, you can
          store your UX Research Studies alongside your tests in illuminate.
          Plus, we automatically generate a sharable case study to help you
          socialize your findings!
        </p>
      </div>

      <div className="d-flex mt-4">
        <div className="desc">
          <h4>Study Design</h4>
          <p>First, you’ll describe the study itself.</p>
        </div>
      </div>

      <Spacer size={3} />

      <div className="d-flex">
        <div className="desc">
          <h4>Insights</h4>
          <p>
            Next, summarize the top level insights. These are the primary things
            you want to remember, build upon, and share. They will automatically
            be stored in your insight library and liked to this study.
          </p>
        </div>
      </div>

      <Spacer size={3} />

      <div className="d-flex">
        <div className="desc">
          <h4>Areas of Focus</h4>
          <p>
            Let’s dig a little deeper and break those insights into more
            specific themes or areas of focus. What stood out to you and why?
          </p>
        </div>
      </div>

      <Spacer size={3} />

      <div className="d-flex">
        <div className="desc">
          <h4>Supporting Data</h4>
          <p>
            Document the data from your study, and tie those data points to your
            areas of focus and/or pages/journey for easier tracking.
          </p>
        </div>
      </div>

      <Spacer size={3} />

      <div className="d-flex">
        <div style={{ width: "100%", maxWidth: "600px" }}>
          <p>
            Voila! Enjoy the sharable ux research we’ll create for you!
          </p>
        </div>
      </div>

      <Spacer size={2} />
    </StyledModal>
  );
};

export { Explanation };

const StyledModal = styled.div`
  min-width: 600px;
  padding: 10px;

  &&& {
    .header {
      padding: 0;
      width: 100%;
    }
  }
  h4 {
    font-family: Roboto;
  }
  .image {
    width: 75px;
    align-items: center;
    display: flex;
  }

  .w-100 {
    width: 100;
  }
  .desc {
    width: 480px;
  }
  .d-flex {
    justify-content: space-between;
  }
`;
