import React, { Component } from "react";
import { connect } from "react-redux";
import { ApplicationState, CurrencySymbols } from "store/types";
import * as DataTypes from "store/types";
import { Form, Input, Button, ValidatorTypes, Select } from "comps/form";
import { updateAccountProgramGoalsRequest } from "store/account/actions";

import "css/Setup.scss";
import { SetupStepsEnum } from "./types";

const DATA_TYPES = ["number", "currency"];

const DEFAULT_PROGRAM_GOALS: Array<DataTypes.ProgramGoal> = [
  { name: "revenue", data_type: "currency" },
  { name: "engagement", data_type: "number" },
  { name: "sign ups", data_type: "number" },
  { name: "bookings", data_type: "number" },
  { name: "account opens", data_type: "number" },
  { name: "memberships", data_type: "number" },
  { name: "chats", data_type: "number" },
  { name: "leads", data_type: "number" },
  { name: "downloads", data_type: "number" },
  { name: "orders", data_type: "number" },
];

interface SelectedProgramGoal extends DataTypes.ProgramGoal {
  checked: boolean;
}

interface IProps {
  account: DataTypes.Account | null;
  updateAccountProgramGoalsRequest: (body: any, redirectUrl: string) => void;
}

interface IState {
  program_goals: Array<SelectedProgramGoal>;
  error: boolean;
  showAddProgramGoal: boolean;
}

const buildProgramGoals = (
  program_goals: Array<DataTypes.ProgramGoal>
): Array<SelectedProgramGoal> => {
  let pgs = DEFAULT_PROGRAM_GOALS.map((pg) => {
    const existing = program_goals.find(
      (p) =>
        p.name.toLowerCase().trim() === pg.name.toLowerCase().trim() &&
        p.data_type?.toLowerCase().trim() === pg.data_type?.toLowerCase().trim()
    );
    return {
      ...pg,
      checked: existing ? true : false,
      id: existing && existing.id ? existing.id : undefined,
    };
  });

  program_goals.forEach((pg) => {
    if (
      pgs.findIndex((p) => p.name === pg.name && p.data_type === pg.data_type) <
      0
    ) {
      pgs.push({ ...pg, id: pg.id, checked: true });
    }
  });

  return pgs;
};

class AboutYourGoals extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      program_goals: buildProgramGoals(this.props.account?.program_goals || []),
      error: false,
      showAddProgramGoal: false,
    };
  }

  private toggleAddNewProgramGoal = (toggle: boolean) => {
    this.setState({ showAddProgramGoal: toggle });
  };

  private onSubmit = () => {
    const { program_goals } = this.state;

    if (program_goals.every((pg) => pg.checked === false)) {
      this.setState({ error: true });
      return;
    }

    this.props.updateAccountProgramGoalsRequest(
      {
        program_goals: program_goals.filter((pg) => pg.checked === true),
      },
      `?step=${SetupStepsEnum.CUSTOMIZE_YOUR_ACCOUNT}`
    );

    this.setState({ error: false });
  };

  private onAddProgramGoal = (valid: boolean, dirty: boolean, data: any) => {
    if (valid) {
      let pg = this.state.program_goals;
      if (
        pg.findIndex(
          (p) =>
            p.name.toLowerCase().trim() ===
            data.program_goal.value.toLowerCase().trim()
        ) < 0
      ) {
        pg.push({
          name: data.program_goal.value,
          data_type: data.dataType.value,
          symbol: data.symbol.value,
          checked: true,
        });
        this.setState({
          program_goals: pg,
          error: pg.every((p) => p.checked === false),
          showAddProgramGoal: false,
        });
      }
    }
  };

  private onCheckPg = (programGoal: SelectedProgramGoal, checked: boolean) => {
    let pg = this.state.program_goals;
    const pIdx = pg.findIndex((p) => p.name === programGoal.name);
    if (pg && pIdx >= 0) {
      pg[pIdx].checked = checked;
      this.setState({
        ...this.state,
        program_goals: pg,
        error: pg.every((p) => p.checked === false),
      });
    }
  };

  render() {
    const { account } = this.props;
    const { program_goals, error, showAddProgramGoal } = this.state;

    if (!account) return null;

    return (
      <React.Fragment>
        <h1>About Your Goals</h1>
        <p>
          What metric is your testing program ultimately responsible for
          influencing? You’ll have the ability to specify the impact that each
          test has on this metric, and we’ll track it for you on the dashboard.
        </p>
        <p className="mt-2">
          {" "}
          Don’t worry, each test will have their own set of metrics. This
          question is about your overall program.
        </p>
        <div className="ProgramGoals">
          <ul>
            {program_goals.map((pg, idx) => {
              return (
                <li key={["pg_", idx].join("_")}>
                  <label className="CheckBox">
                    <input
                      type="checkbox"
                      defaultChecked={pg.checked}
                      onChange={(e) => this.onCheckPg(pg, e.target.checked)}
                    />
                    <span className="checkmark"></span>
                  </label>
                  <p>{pg.name}</p>
                </li>
              );
            })}
          </ul>
          <hr />
          {showAddProgramGoal === false && (
            <div>
              <p>Don’t see your metric?</p>
              <button
                className="btn btn-link"
                onClick={() =>
                  this.toggleAddNewProgramGoal(!showAddProgramGoal)
                }
              >
                <i className="fa fa-plus" />
                Add new
              </button>
            </div>
          )}
          {showAddProgramGoal === true && (
            <div>
              <Form
                formFields={{
                  program_goal: {
                    name: "program_goal",
                    value: "",
                    validator: {
                      type: ValidatorTypes.REQUIRED,
                      messages: {
                        required: "Please enter a valid program goal name.",
                      },
                    },
                  },
                  dataType: {
                    name: "dataType",
                    value: "number",
                    validator: {
                      type: ValidatorTypes.REQUIRED,
                    },
                  },
                  symbol: {
                    name: "symbol",
                    value: "DOLLAR",
                  },
                }}
                onSubmit={this.onAddProgramGoal}
                FormComponent={({
                  fields: { program_goal, dataType, symbol },
                  onChange,
                }) => (
                  <div>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <label style={{ marginLeft: "4px" }}>
                        Add Program Goal
                      </label>
                    </div>
                    <div className="add-form">
                      <div className="form-group">
                        <label>Program Goal Name</label>
                        <Input
                          field={program_goal}
                          className="form-control"
                          onChange={(e) =>
                            onChange(program_goal, e.target.value)
                          }
                        />
                      </div>
                      <div className="form-group">
                        <label>Program Goal Data Type</label>

                        <Select
                          className="SelectInput"
                          placeholder="-- Select --"
                          field={dataType}
                          options={DATA_TYPES}
                          onChange={(value: string) =>
                            onChange(dataType, value)
                          }
                        />
                      </div>

                      {dataType.value === "currency" ? (
                        <div className="form-group">
                          <label>Currency Symbol</label>
                          <Select
                            className="SelectInput currency-select"
                            placeholder="-- Select --"
                            field={symbol}
                            options={Object.keys(CurrencySymbols)}
                            onChange={(value: string) =>
                              onChange(symbol, value)
                            }
                            showSymbols={true}
                          />
                        </div>
                      ) : null}
                    </div>
                    <div className="flex frow">
                      <button
                        className="btn btn-link w--unset"
                        type="button"
                        onClick={() =>
                          this.toggleAddNewProgramGoal(!showAddProgramGoal)
                        }
                      >
                        Cancel
                      </button>

                      <Button
                        className="btn btn-primary"
                        disabled={program_goal.value.trim() === ""}
                        text="add"
                        isLoading={false}
                      />
                    </div>
                  </div>
                )}
              />
            </div>
          )}
          {error === true && (
            <div className="error">Please select a program goal.</div>
          )}
        </div>
        {showAddProgramGoal === false && (
          <button
            className="btn btn-primary"
            disabled={program_goals.every((p) => p.checked === false)}
            type="button"
            onClick={this.onSubmit}
          >
            next
          </button>
        )}
      </React.Fragment>
    );
  }
}

const mapStateToProps = ({ account }: ApplicationState) => ({
  account: account,
});

const mapDispatchToProps = {
  updateAccountProgramGoalsRequest,
};

const connectedPage = connect(
  mapStateToProps,
  mapDispatchToProps
)(AboutYourGoals);

export { connectedPage as AboutYourGoals };
