export const appConfig = {
  USER_TOKEN: "user_token",
  ILLUMINATE_TOKEN: "illuminate_token",
  ACCOUNT_TOKEN: "account_token",
  SECRET: "1234567890",
  AUTH_SUBDOMAIN: "auth",
  REDIRECT_PATH: "redirect_path",
  AUTH_SLUG: "auth_slug",
  SHARE_PWD: "share_pwd",
  SHARE_USER: "share_user",
  HELP_URL: "https://intercom.help/illuminate-v2/",
};

export const subDomainRedirect: any = {
  suntrust: "truist",
};

export enum HTTPCodes {
  UNAUTHORIZED = 401,
  FORBIDDEN = 403,
  NOT_FOUND = 404,
  UNPROCESSABLE_ENTITY = 422,
  SYSTEM_ERROR = 500,
}

export enum ExperimentTypes {
  AB = "Experiment::Ab",
  ADOBE = "Experiment::Adobe",
}

export enum AdobeExperimentTypes {
  AB = "ab",
  XT = "xt",
}

export enum ReportTypes {
  ADOBE = "adobe",
  SUMMARY = "Experiment::Report::Summary",
  TARGET = "Experiment::Report::Target",
  ANALYTICS = "Experiment::Report::Analytic",
  CUSTOM_DATA_VIZ = "CUSTOM_DATA_VIZ",
}
