import React from 'react';
import Picker from 'react-month-picker';
import moment from 'moment';

import 'react-month-picker/scss/month-picker.scss';

interface IProps {
  startDate: Date;
  endDate: Date;
  onChange:(start: Date, end: Date) => void;
  onDismiss?:(value: ISelectedRange) => void;
  className?: string;
};

interface IState {
  startDate: Date;
  endDate: Date;

}

interface IDateOption {
  month: number;
  year: number;
};

interface IPickerOptions {
  min?: IDateOption;
  max?: IDateOption;
}

interface ISelectedRange {
  from: IDateOption;
  to: IDateOption;
}

class MonthPicker extends React.Component<IProps, IState> {
private rangeRef: React.RefObject<{ show: Function; }>
  private pickerLang = {
    months: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
    from: 'From',
    to: 'To'
  };

  constructor (props: IProps) {
    super(props);

    this.state = {
      startDate: props.startDate,
      endDate: props.endDate
    } as IState;

    this.rangeRef = React.createRef();
  }

  private getDateOption (date: Date): IDateOption {
    return {
      month: date.getMonth() + 1,
      year: date.getFullYear()
    };
  }

  private getMonthOptions = (): IPickerOptions => {
    return {
      min: this.getDateOption(moment().add(-5, 'years').toDate()),
      max: this.getDateOption(moment().toDate())
    };
  };

  private getSelectedRange (): ISelectedRange {
    const { startDate, endDate } = this.state;

    return {
      from: this.getDateOption(startDate),
      to: this.getDateOption(endDate)
    };
  }

  private onMonthPickerBoxClick = () => {
    if (this?.rangeRef?.current) this.rangeRef.current.show();
  }

  private onPickerChange = (year: number, month: number, listIndex: number) => {
    let startDate = this.state.startDate;
    let endDate = this.state.endDate;
    
    if (listIndex === 0) {
      startDate = moment(`${month}-${year}`, 'MM-YYYY').toDate();
    } else {
      endDate = moment(`${month}-${year}`, 'MM-YYYY').toDate();
    }

    this.props.onChange(startDate, endDate);

    this.setState({ startDate, endDate });
  }

  private onPickerDismiss = (value: ISelectedRange) => {
    if (this.props.onDismiss) this.props.onDismiss(value);
  }

  render () {
    const selectedRange = this.getSelectedRange();

    return (
    <div className={`MonthPicker${this.props.className ? ` ${this.props.className}` : ''}`}>
        <Picker
          ref={this.rangeRef}
          years={this.getMonthOptions()}
          value={selectedRange}
          lang={this.pickerLang}
          theme="light"
          onChange={this.onPickerChange}
          onDismiss={this.onPickerDismiss}
        >
          <div className='MonthPickerBox' onClick={this.onMonthPickerBoxClick}>
            {`${selectedRange.from.month}/${selectedRange.from.year} - ${selectedRange.to.month}/${selectedRange.to.year}`}
          </div>
        </Picker>
      </div>
    );
  }
}

export { MonthPicker };
