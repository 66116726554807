export enum ActionTypes {
  CREATE_METRIC_REQUEST = '@@report_metric/CREATE_METRIC_REQUEST',
  CREATE_METRIC_SUCCESS = '@@report_metric/CREATE_METRIC_SUCCESS',
  CREATE_METRIC_FAILURE = '@@report_metric/CREATE_METRIC_FAILURE',

  DESTROY_METRIC_REQUEST = '@@report_metric/DESTROY_METRIC_REQUEST',
  DESTROY_METRIC_SUCCESS = '@@report_metric/DESTROY_METRIC_SUCCESS',
  DESTROY_METRIC_FAILURE = '@@report_metric/DESTROY_METRIC_FAILURE',

  ADD_RESULT_REQUEST = '@@report_metric/ADD_RESULT_REQUEST',
  ADD_RESULT_SUCCESS = '@@report_metric/ADD_RESULT_SUCCESS',
  ADD_RESULT_FAILURE = '@@report_metric/ADD_RESULT_FAILURE',

  UPDATE_METRIC_REQUEST = '@@report_metric/UPDATE_METRIC_REQUEST',
  UPDATE_METRIC_SUCCESS = '@@report_metric/UPDATE_METRIC_SUCCESS',
  UPDATE_METRIC_ERROR = '@@report_metric/UPDATE_METRIC_ERROR',

  UPDATE_METRIC_ORDER_REQUEST = "@@report_metric/UPDATE_METRIC_ORDER_REQUEST",
  UPDATE_METRIC_ORDER_SUCCESS = "@@report_metric/UPDATE_METRIC_ORDER_SUCCESS",
  UPDATE_METRIC_ORDER_ERROR = "@@report_metric/UPDATE_METRIC_ORDER_ERROR"
}
