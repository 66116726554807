import { Reducer } from 'redux';
import * as DataTypes from '../types';
import { ActionTypes } from './types';

export interface DefaultIdeaBoardManagementState {
  active: DataTypes.IdeaBoard | null;
};

const initialState: DefaultIdeaBoardManagementState = {
  active: null,
}

const reducer: Reducer<DefaultIdeaBoardManagementState> = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.SHOW_IDEA_BOARDS_SUCCESS:
    case ActionTypes.CREATE_IDEA_BOARD_MANAGEMENT_SUCCESS: {
      return {
        ...state,
        active: action.payload.response,
      };
    }
    case ActionTypes.INIT_IDEA_BOARD_MANAGEMENT_CREATE_SUCCESS: {
      return {
        ...state,
        active: null,
      };
    }
    default: {
      return state;
    }
  }
};

export { reducer as ideaBoardManagementReducer };
