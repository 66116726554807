export enum ActionTypes {
  GET_LEADER_BOARD_REQUEST = "@@dashboard/GET_LEADER_BOARD_REQUEST",
  GET_LEADER_BOARD_SUCCESS = "@@dashboard/GET_LEADER_BOARD_SUCCESS",
  GET_LEADER_BOARD_ERROR = "@@dashboard/GET_LEADER_BOARD_ERROR",

  GET_INSIGHT_BOARD_REQUEST = "@@dashboard/GET_INSIGHT_BOARD_REQUEST",
  GET_INSIGHT_BOARD_SUCCESS = "@@dashboard/GET_INSIGHT_BOARD_SUCCESS",
  GET_INSIGHT_BOARD_ERROR = "@@dashboard/GET_INSIGHT_BOARD_ERROR",

  GET_TODAY_BOARD_REQUEST = "@@dashboard/GET_TODAY_BOARD_REQUEST",
  GET_TODAY_BOARD_SUCCESS = "@@dashboard/GET_TODAY_BOARD_SUCCESS",
  GET_TODAY_BOARD_ERROR = "@@dashboard/GET_TODAY_BOARD_ERROR",

  GET_DID_YOU_KNOW_REQUEST = "@@dashboardGET_DID_YOU_KNOW_REQUEST",
  GET_DID_YOU_KNOW_SUCCESS = "@@dashboardGET_DID_YOU_KNOW_SUCCESS",
  GET_DID_YOU_KNOW_ERROR = "@@dashboardGET_DID_YOU_KNOW_ERROR",
}
