import React, { Component } from "react";
import * as DataTypes from "store/types";
import { Avatar, DropdownControl } from "ui";
import { history } from "utils";

interface IProps {
  account: DataTypes.Account;
  experiment: DataTypes.Experiment;
  onChange(key: string, value: string | Array<string>): void;
  onAddInsight(): void;
  onDelete(insight: DataTypes.Insight): void;
  currentUser: any;
  onUploadInsight(): void;
}

interface IState {}

class Insights extends Component<IProps, IState> {
  render() {
    const { experiment, onAddInsight, onUploadInsight } = this.props;

    return (
      <React.Fragment>
        <div className="Insights">
          <div className="container">
            {this.props.currentUser.role !== "read-only" && (
              <button
                style={{ marginRight: "25px" }}
                className="btn btn-primary-light"
                onClick={() =>
                  history.push(`/insights/create?experiment=${experiment.id}`)
                }
              >
                <i className="fa fa-plus" />
                New Insight
              </button>
            )}

            {this.props.currentUser.role !== "read-only" && (
              <button
                className="btn btn-primary-light"
                type="button"
                onClick={onAddInsight}
              >
                <i className="fa fa-link" />
                Existing Insight
              </button>
            )}

            {this.props.currentUser.role !== "read-only" && (
              <button
                className="btn btn-primary-light"
                type="button"
                style={{ marginLeft: "25px" }}
                onClick={onUploadInsight}
              >
                <i className="fa fa-upload" />
                Batch Upload
              </button>
            )}
            <ul>
              {experiment.insights.map((insight) => {
                return (
                  <li key={["insight_", insight.id].join("_")}>
                    <div className="avatar">
                      <div className="avatar-stack">
                        {insight.contributors.reverse().map((user, idx) => {
                          return (
                            <div
                              style={{
                                position: "relative",
                                left: `${8 * idx - 8}px`,
                              }}
                              key={`user_${user.id}`}
                            >
                              <Avatar user={user} showHover={true} />
                            </div>
                          );
                        })}
                        {insight.author ? (
                          <div style={{ position: "relative" }}>
                            <Avatar
                              user={insight.author}
                              showHover={true}
                              className="focus author"
                            />
                          </div>
                        ) : null}
                      </div>
                    </div>
                    <div className="insight">
                      <p>{insight.value}</p>
                    </div>
                    {this.props.currentUser.role !== "read-only" && (
                      <div className="controls">
                        <DropdownControl
                          dropdownClass="w--160 left--105-neg"
                          controlItems={[
                            {
                              name: "Remove Insight",
                              icon: "far fa-trash-alt",
                              onClick: () => this.props.onDelete(insight),
                              splitAbove: true,
                              className: "danger",
                            },
                          ]}
                        />
                      </div>
                    )}
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export { Insights };
