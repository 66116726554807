import { ApiClient } from './client/api_client';

class AdobeExperiments {
  static async create(body: any) {
    return await ApiClient.post('/v1/experiment/adobe', { experiment: body });
  }

  static async update(id: string, body: any) {
    return await ApiClient.put(`/v1/experiment/adobe/${id}`, body);
  }

  static async metrics(experimentId: string, rsid: string) {
    return await ApiClient.get(`/v1/experiment/${experimentId}/report/analytics/${rsid}`);
  }
}

export { AdobeExperiments };
