import { FormField, FormFields } from "./";
import { ValidatorTypes } from "./";

interface ValidatorMessages {
  required?: string;
  type?: string;
  custom?: string;
}

export interface ValidatorRules {
  required?: boolean | false;
  type?: ValidatorTypes | ValidatorTypes.NONE;
  messages?: ValidatorMessages;
  customValidator?: (value: any) => boolean;
}
const PASSWORD_LENGTH = 8;

export class Validator {
  validateForm = (fields: FormFields): boolean => {
    Object.keys(fields).forEach((key) => {
      this.valid(fields[key]);
    });

    let valid = Object.keys(fields).every((key) => fields[key].valid === true);

    return valid;
  };

  validateField = (field: FormField): boolean => {
    const valid = this.valid(field);
    return valid;
  };

  private valid = (field: FormField): boolean => {
    // Reset fields
    field.valid = true;
    field.error = "";

    // In case no validators are on this field
    if (!field.validator || Object.keys(field.validator).length === 0)
      return field.valid;

    // Initialize validator messeges
    if (!field.validator.messages) {
      field.validator.messages = {} as ValidatorMessages;
    }

    // Custom
    if (field.validator.type === ValidatorTypes.CUSTOM) {
      if (!field.validator.customValidator) {
        throw new Error(
          `Field ${field.name} is using custom validation but does not sets the customValidator function`
        );
      }

      if (!field.validator.customValidator(field.value)) {
        field.valid = false;
        field.error = field.validator.messages.custom
          ? field.validator.messages.custom
          : `${field.name} is not valid`;

        return field.valid;
      }
    }

    // Required
    if (
      field.validator.type === ValidatorTypes.CHECKBOX_REQUIRED &&
      !field.value
    ) {
      field.valid = false;
      field.error = field.validator.messages.required
        ? field.validator.messages.required
        : field.name + " is required";
      return field.valid;
    }

    if (
      field.validator.type === ValidatorTypes.TEXT_BOX_REQUIRED &&
      (!field.value ||
        Object.keys(field.value).length === 0 ||
        (field.value.blocks?.length === 1 && !field.value.blocks[0].text))
    ) {
      field.valid = false;
      field.error = field.validator.messages.required
        ? field.validator.messages.required
        : `${field.name} is required`;
      return field.valid;
    }

    if (
      field.validator.type === ValidatorTypes.REQUIRED &&
      field.value.toString().trim().length <= 0
    ) {
      field.valid = false;
      field.error = field.validator.messages.required
        ? field.validator.messages.required
        : field.name + " is required";
      return field.valid;
    }

    if (
      field.validator.type === ValidatorTypes.MIN_LENGTH_3 &&
      field.value.toString().trim().length < 3
    ) {
      field.valid = false;
      field.error = field.validator.messages.required
        ? field.validator.messages.required
        : "required to be at least 3 characters";
    }

    // Email
    if (
      field.validator.type === ValidatorTypes.EMAIL &&
      !this.validateEmail(field.value.toString())
    ) {
      field.valid = false;
      field.error = field.validator.messages.type
        ? field.validator.messages.type
        : "must be valid email address";
      return field.valid;
    }

    // Password
    if (
      field.validator.type === ValidatorTypes.PASSWORD &&
      !this.validatePassword(field.value.toString())
    ) {
      field.valid = false;
      field.error = field.validator.messages.type
        ? field.validator.messages.type
        : "must be valid password";
      return field.valid;
    }

    // Boolean
    if (
      field.validator.type === ValidatorTypes.BOOLEAN &&
      !this.validateBoolean(Boolean(field.value))
    ) {
      field.valid = false;
      field.error = field.validator.messages.type
        ? field.validator.messages.type
        : "must be true";
    }
    if (
      field.validator.type === ValidatorTypes.URL_SAFE &&
      !this.validateSafeURL(field.value)
    ) {
      field.valid = false;
      field.error =
        "Your workspace name can only contain lowercase letters, numbers and dashes. It must begin with a letter. It must have at least 2 characters.";
    }

    return field.valid;
  };

  private validateEmail = (value: string) => {
    const re = /([^@\s]+)@((?:[-a-z0-9]+\.)+[a-z]{2,})/i;
    return re.test(value);
  };

  private validateBoolean = (value: boolean) => {
    return value;
  };

  private validatePassword = (value: string) => {
    return value.length >= PASSWORD_LENGTH;
  };

  private validateSafeURL = (value: string) => {
    if (!value || value.length < 2) return false;

    if (value.toLowerCase() !== value) return false;

    // /^[a-z][a-z|\d|-]*/g
    const urlSafe = /^[a-z|A-Z|][a-z|A-Z|\d|-]*?$/g.test(value);
    if (!urlSafe) return false;

    return true;
  };
}
