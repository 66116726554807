import React from 'react';
import { Helpers } from '../utils';
import { ExperimentTypes } from 'config/app';
import * as DataTypes from 'store/types';

interface IExperimentIconProps {
  experimentType: string;
  adobe?: string;
}

export const UxrIcon = () => {
  return (
    <div className="ExpIcon">
      <div className="text">UXR</div>
    </div>
  );
};

export const ExperimentIcon = ({ experimentType, adobe }: IExperimentIconProps) => {
  return (
    <div className="ExpIcon">
      <div className="text">{Helpers.getExperimentType(experimentType, adobe)}</div>
      {experimentType === ExperimentTypes.ADOBE && <div className="tag adobe" />}
    </div>
  );
};

interface IExperimentStatusIconProps {
  status: string;
  device?: 'desktop' | 'mobile';
}

export const ExperimentStatusIcon = ({ status, device }: IExperimentStatusIconProps) => {
  if (status === 'undecided') return null;

  return (
    <div className={`ExpStatus${device ? ` ExpStatus_${device}` : ''}`}>
      <div className={`status ${status}`}>{Helpers.snakeToTitle(status)}</div>
    </div>
  );
};

interface IAvatarProps {
  experiment?: DataTypes.Experiment;
  className?: string;
}

export const ExperimentAvatar = ({ experiment, className = '' }: IAvatarProps) => {
  if (!experiment || experiment.experiences.length === 0) return null;

  const control = experiment.experiences.find((e) => e.type === 'Experiment::Experience::Control');

  if (!control) {
    return null;
  }

  if (control.images.length === 0) {
    return (
      <div className={ `ExperimentAvatar no-img ${className}` }>
        <div>
          <div className="image"></div>
        </div>
        {experiment.type === ExperimentTypes.ADOBE && <div className="tag adobe" />}
      </div>
    );
  }

  return (
    <div className={ `ExperimentAvatar ${className}` }>
      <div>
        <div className="image">{<img src={control.images[0].url} className="image" alt="experiment" />}</div>
      </div>
      {experiment.type === ExperimentTypes.ADOBE && <div className="tag adobe" />}
    </div>
  );
};
