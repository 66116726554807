import { ApiClient } from "./client/api_client";
import { ApiListOptions } from "store/types";

class Experiments {
  static async index(options: ApiListOptions) {
    return await ApiClient.post("/v1/graphql", {
      query: `query experiments($sort: String, $traits: [String!], $ids: [String!], $search: String, $first: Int, $after: String, $month: String) {
        experiments(sort: $sort, traits: $traits, ids: $ids, search: $search, first: $first, after: $after, month: $month) {
          nodes {
            id
            name
            type
            description
            status
            customOrder
            star
            startDate
            endDate
            brands
            audiences
            devices
            pages
            impactPolarity
            impactValue
            channels
            tags
            teams
            createdAt
            updatedAt
            programGoals {
              impactValue
              impactPolarity
              programGoal {
                id
                name
                dataType
                symbol
              }
            }
            author {
              id
              firstName
              lastName
              imageUrl
            }
            contributors {
              user {
                id
                firstName
                lastName
                imageUrl
              }
            }
            experiences {
              id
              type
              traffic
              name
              images {
                id
                url
              }
            }
          }
          pageInfo {
            endCursor
            startCursor
            hasPreviousPage
            hasNextPage
          }
        }
      }`,
      variables: {
        sort: options.sort,
        month: options.month,
        traits: options.q,
        ids: options.ids,
        search: options.search,
        first: options.limit,
        after: options.starting_after,
      },
    });
  }

  static async show(id: string) {
    return await ApiClient.get(`/v1/experiment/${id}`);
  }

  static async create(body: any) {
    return await ApiClient.post("/v1/experiments", body);
  }

  static async update(id: string, body: any) {
    return await ApiClient.put(`/v1/experiment/${id}`, body);
  }

  static async destroy(id: string) {
    return await ApiClient.delete(`/v1/experiment/${id}`);
  }

  static async addInsight(experimentId: string, insightId: string) {
    return await ApiClient.put(
      `/v1/experiment/${experimentId}/insight/${insightId}`,
      {}
    );
  }

  static async removeInsight(experimentId: string, insightId: string) {
    return await ApiClient.delete(
      `/v1/experiment/${experimentId}/insight/${insightId}`
    );
  }

  static async traits() {
    return await ApiClient.get("/v1/experiment/traits");
  }

  static async addTraits(
    experimentId: string,
    trait: string,
    values: string[]
  ) {
    return await ApiClient.post(`/v1/experiment/${experimentId}/traits`, {
      trait,
      values,
    });
  }

  static async addUpload(experimentId: string, file: File) {
    const formData = new FormData();
    formData.append("upload", file);

    return await ApiClient.postMultipart(
      `/v1/experiment/${experimentId}/upload`,
      formData
    );
  }

  static async deleteUpload(experimentId: string, uploadId: string) {
    return await ApiClient.delete(
      `/v1/experiment/${experimentId}/upload/${uploadId}`
    );
  }

  static async reorder(id: string, position: number) {
    return await ApiClient.put(`/v1/experiment/${id}/order/${position}`, {});
  }

  static async uploadInsights(experimentId: string, file: any) {
    const formData = new FormData();
    formData.append("file", file);

    return await ApiClient.postMultipart(
      `/v1/experiment/${experimentId}/insight/csv`,
      formData
    );
  }

  static async addProgramGoal(id: string, program_goal: any) {
    return await ApiClient.post(`/v1/experiment/${id}/program_goal`, {
      program_goal,
    });
  }

  static async deleteProgramGoal(id: string, program_goal_id: string) {
    return await ApiClient.delete(
      `/v1/experiment/${id}/program_goal/${program_goal_id}`
    );
  }

  static async updateProgramGoal(
    id: string,
    program_goal_id: string,
    body: any
  ) {
    return await ApiClient.put(
      `/v1/experiment/${id}/program_goal/${program_goal_id}`,
      body
    );
  }
}

export { Experiments };
