import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import * as API from "../../api";
import * as Actions from "./actions";
import { ActionTypes } from "./types";
import * as AccountActions from "../account/actions";
import * as IdeaTraitActions from "../idea_trait/actions";
import * as AppTypes from "../app/types";
import { history } from "utils";

function* initIdeaCreate(action: any) {
  try {
    const { traits, apps } = yield all({
      traits: call(API.Account.traits),
      apps: call(API.Apps.index),
    });
    yield all([
      put(AccountActions.getAccountTraitsSuccess(traits.body)),
      put(AccountActions.getAccountAppsSuccess(apps.body)),
      put(Actions.initIdeaCreateSuccess()),
    ]);
  } catch (error: any) {
    yield put(Actions.initIdeaCreateError(error));
  }
}

function* createIdea(action: any) {
  try {
    // @ts-ignore
    const res = yield call(API.Ideas.create, action.payload.body);
    yield put(AccountActions.getAccountSummaryRequest());
    yield put(AccountActions.getAccountTraitsRequestSaga());
    yield put(Actions.createIdeaSuccess(res.body));
    history.push("/ideas");
    yield put({
      type: AppTypes.App.ALERT_SHOW,
      content: "Idea created successfully",
    });
  } catch (error: any) {
    yield put(Actions.createIdeaError(error));
  }
}

function* createShareIdea(action: any) {
  try {
    yield put({ type: AppTypes.App.MODAL_SAVING });
    // @ts-ignore
    const res = yield call(
      API.Share.create_idea,
      action.payload.slug,
      action.payload.idea_board_id,
      action.payload.body,
      action.payload.password
    );
    yield put({ type: AppTypes.App.MODAL_HIDE });
    yield put(Actions.createShareIdeaSuccess(res.body));
    history.push(`/ib/${action.payload.idea_board_id}?id=${res.body.id}`);
    yield put({
      type: AppTypes.App.ALERT_SHOW,
      content: "Idea created successfully",
    });
  } catch (error: any) {
    yield put(Actions.createShareIdeaError(error));
  }
}

function* updateIdea(action: any) {
  let res;
  try {
    // @ts-ignore
    res = yield call(API.Ideas.update, action.payload.id, action.payload.body);
    yield put(AccountActions.getAccountTraitsRequestSaga());

    yield put(Actions.updateIdeaSuccess(res.body));

    yield put({
      type: AppTypes.App.ALERT_SHOW,
      content: "Idea updated successfully",
    });
  } catch (error: any) {
    yield put({
      type: AppTypes.App.ALERT_SHOW,
      content: error.body.errors.params,
      error: true,
    });
    yield put(Actions.updateIdeaError(error));
  }
}

function* archiveIdea(action: any) {
  try {
    // @ts-ignore
    const res = yield call(
      API.Ideas.update,
      action.payload.id,
      action.payload.body
    );

    yield put(AccountActions.getAccountTraitsRequestSaga());
    yield put(Actions.archiveIdeaSuccess(res.body));
    yield put({ type: AppTypes.App.MODAL_HIDE });

    yield put({
      type: AppTypes.App.ALERT_SHOW,
      content: "Idea updated successfully",
    });
  } catch (error: any) {
    yield put(Actions.archiveIdeaError(error));
  }
}

function* uploadIdeas(action: any) {
  try {
    const { file } = action.payload;

    // @ts-ignore
    const res = yield call(API.Ideas.uploadIdeas, file);

    const { errors } = res.body;

    if (errors.length === 0) {
      yield put({ type: AppTypes.App.MODAL_HIDE });
      yield put({
        type: ActionTypes.GET_IDEAS_REQUEST,
        payload: { options: { sort: "updated_at" } },
      });
      yield put({
        type: AppTypes.App.ALERT_SHOW,
        content: "Batch upload complete.",
      });
      yield put(AccountActions.getAccountSummaryRequest());
      yield put(AccountActions.getAccountTraitsRequestSaga());
      yield put(IdeaTraitActions.getIdeasTraitRequestSaga());
    } else {
      yield put({
        type: AppTypes.App.ALERT_SHOW,
        content: "Something went wrong, review your csv and try again.",
        error: true,
      });
      yield put(
        Actions.uploadIdeasError({ statusCode: 206, message: "upload failed" })
      );
    }

    yield put(Actions.uploadIdeasSuccess(res.body));
  } catch (error: any) {
    yield put({
      type: AppTypes.App.ALERT_SHOW,
      content: "Something went wrong, review your csv and try again.",
      error: true,
    });
    yield put(Actions.uploadIdeasError(error));
  }
}

function* deleteIdea(action: any) {
  try {
    yield put({ type: AppTypes.App.MODAL_SAVING });
    // @ts-ignore
    const res = yield call(API.Ideas.destroy, action.payload.id);
    yield put(IdeaTraitActions.getIdeasTraitRequestSaga());
    yield put(AccountActions.getAccountSummaryRequest());
    yield put(Actions.deleteIdeaSuccess(res.body));
    yield put({ type: AppTypes.App.MODAL_HIDE });
    yield put({
      type: AppTypes.App.ALERT_SHOW,
      content: "Idea deleted successfully",
    });
  } catch (error: any) {
    yield put(Actions.deleteIdeaError(error));
  }
}

function* getIdeas(action: any) {
  try {
    // @ts-ignore
    const res = yield call(API.Ideas.index, action.payload.options);
    const append =
      action.payload.scrollOpts && action.payload.scrollOpts.append;
    yield put(Actions.getIdeasSuccess(res.body, append));
  } catch (error: any) {
    yield put(Actions.getIdeasError(error));
  }
}

function* showIdea(action: any) {
  try {
    const ideaId = action.payload.id;
    // @ts-ignore
    const res = yield call(API.Ideas.show, ideaId);
    yield put(Actions.showIdeaSuccess(res.body));

    if (action.payload.callback) {
      action.payload.callback(res.body);
    }
  } catch (error: any) {
    yield put(Actions.showIdeaError(error));
  }
}

function* clearIdea(action: any) {
  try {
    yield put(Actions.clearIdeaSuccess());
  } catch (error: any) {
    yield put(Actions.clearIdeaError(error));
  }
}

function* reorderIdea(action: any) {
  try {
    const { id, position, currentOrder } = action.payload;

    // @ts-ignore
    const res = yield call(API.Ideas.reorder, id, position + 1);
    yield put(Actions.reorderIdeaSuccess(res.body, currentOrder));
  } catch (error: any) {
    yield put(Actions.reorderIdeaError(error));
  }
}

function* exportIdeas(action: any) {
  try {
    const { queryString, fields } = action.payload;

    // @ts-ignore
    const res = yield call(API.Ideas.exportIdeas, queryString, fields);
    yield put({
      type: AppTypes.App.ALERT_SHOW,
      content: "We are working on your export and will send via email shortly.",
    });
    yield put(Actions.exportIdeasSuccess(res.body));
    yield put({ type: AppTypes.App.MODAL_HIDE });
  } catch (error: any) {
    yield put(Actions.exportIdeasError(error));
  }
}

function* watchUploadIdeas() {
  yield takeEvery(ActionTypes.UPLOAD_IDEAS_REQUEST, uploadIdeas);
}

function* watchInitIdeaCreate() {
  yield takeEvery(ActionTypes.INIT_IDEA_CREATE_REQUEST, initIdeaCreate);
}

function* watchIdeaCreate() {
  yield takeEvery(ActionTypes.CREATE_IDEA_REQUEST, createIdea);
}

function* watchArchiveIdea() {
  yield takeEvery(ActionTypes.ARCHIVE_IDEA_REQUEST, archiveIdea);
}

function* watchIdeaUpdate() {
  yield takeEvery(ActionTypes.UPDATE_IDEA_REQUEST, updateIdea);
}

function* watchIdeaDelete() {
  yield takeEvery(ActionTypes.DELETE_IDEA_REQUEST, deleteIdea);
}

function* watchGetIdeas() {
  yield takeEvery(ActionTypes.GET_IDEAS_REQUEST, getIdeas);
}

function* watchShowIdea() {
  yield takeEvery(ActionTypes.SHOW_IDEA_REQUEST, showIdea);
}

function* watchCreateShareIdea() {
  yield takeEvery(ActionTypes.CREATE_SHARE_IDEA_REQUEST, createShareIdea);
}

function* watchClearIdea() {
  yield takeEvery(ActionTypes.CLEAR_IDEA_REQUEST, clearIdea);
}

function* watchReorderIdea() {
  yield takeEvery(ActionTypes.REORDER_REQUEST, reorderIdea);
}

function* watchExportIdeas() {
  yield takeEvery(ActionTypes.EXPORT_IDEAS_REQUEST, exportIdeas);
}

function* ideasSaga() {
  yield all([
    fork(watchGetIdeas),
    fork(watchInitIdeaCreate),
    fork(watchIdeaCreate),
    fork(watchIdeaUpdate),
    fork(watchIdeaDelete),
    fork(watchShowIdea),
    fork(watchUploadIdeas),
    fork(watchCreateShareIdea),
    fork(watchArchiveIdea),
    fork(watchClearIdea),
    fork(watchReorderIdea),
    fork(watchExportIdeas),
  ]);
}

export default ideasSaga;
