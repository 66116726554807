import React, { useCallback } from "react";
import { useDropzone } from "react-dropzone";
import * as DataTypes from "store/types";

interface IUploaderProps {
  data: DataTypes.SupportingData;
  deleteImage: DataTypes.Image | null;
  currentUser: any;
  onView(image: DataTypes.Image): void;
  onImageDrop(files: any): void;
  onToggleDelete(
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    image: DataTypes.Image | null
  ): void;
  onDeleteImage(
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    image: DataTypes.Image
  ): void;
}

export const SupportingDataUploader = ({
  data,
  deleteImage,
  onView,
  onImageDrop,
  onToggleDelete,
  onDeleteImage,
  currentUser,
}: IUploaderProps) => {
  const onDrop = useCallback(
    (acceptedFiles) => {
      onImageDrop(acceptedFiles);
    },
    [onImageDrop]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  return (
    <div className="form-group">
      <label>Screenshots</label>
      <div className="upload-box">
        {data.uploads.map((image: DataTypes.Image, idx: number) => {
          return (
            <div
              key={["c_image", idx].join("_")}
              className={
                deleteImage && image.id === deleteImage.id
                  ? "item delete"
                  : "item"
              }
              onClick={() =>
                deleteImage && image.id === deleteImage.id
                  ? null
                  : onView(image)
              }
            >
              {deleteImage && image.id === deleteImage.id ? (
                <React.Fragment>
                  <div className="delete">
                    <h4>Delete this image?</h4>
                    <button
                      className="btn btn-danger"
                      type="button"
                      onClick={(e) => onDeleteImage(e, image)}
                    >
                      Delete
                    </button>
                    <button
                      className="btn btn-link"
                      type="button"
                      onClick={(e) => onToggleDelete(e, null)}
                    >
                      Cancel
                    </button>
                    <p>
                      <small>Caution: This cannot be undone.</small>
                    </p>
                  </div>
                </React.Fragment>
              ) : (
                <React.Fragment>
                  {currentUser.role === "read-only" ? null : (
                    <div className="cntrls">
                      <button
                        type="button"
                        className="btn btn-cntrl"
                        onClick={(e) => onToggleDelete(e, image)}
                      >
                        <i className="far fa-trash-alt" />
                      </button>
                    </div>
                  )}

                  <div className="item-image">
                    <img src={image.url} alt="" />
                  </div>
                </React.Fragment>
              )}
            </div>
          );
        })}
        {currentUser.role === "read-only" ? null : (
          <div
            {...getRootProps()}
            className={
              data.uploads.length === 0
                ? isDragActive
                  ? "dropzone over no-images"
                  : "dropzone no-images"
                : isDragActive
                ? "dropzone over"
                : "dropzone"
            }
          >
            <div className="item-dropbox">
              <input {...getInputProps()} />
              <i className="fas fa-cloud-upload-alt"></i>
              {isDragActive ? (
                <p>Drop the files here ...</p>
              ) : (
                <p>
                  Drag and drop screenshots here, or click here to select files
                </p>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
