import React, { Component } from "react";
import { connect } from "react-redux";
import { ApplicationState } from "store/types";
import queryString from "query-string";
import * as DataTypes from "store/types";
import { createLoadingSelector } from "store/selectors";
import { AppPage, AppPageProps } from "comps/pages";
import { ValidatorTypes } from "comps/form";

import { getSettingsOptionsRequest } from "store/profile_settings_options/actions";

import "css/Setup.scss";
import { AboutYourProgram } from "./comps/about_your_program";
import { AboutYourGoals } from "./comps/about_your_goals";
import { CustomizeYourAccount } from "./comps/customize_your_account";
import { InviteYourTeam } from "./comps/invite_your_team";
import { Congratulations } from "./comps/congratulations";
import { SetupStepsEnum } from "./comps/types";
import { AboutYourTools } from "./comps/about_your_tools";

interface IProps extends AppPageProps {
  account: DataTypes.Account | null;
  loading: boolean;
  getSettingsOptionsRequest: () => void;
}

class Setup extends Component<IProps> {
  validator = {
    type: ValidatorTypes.EMAIL,
    messages: { type: "Please enter a valid email address." },
  };

  componentDidMount = () => {
    this.props.getSettingsOptionsRequest();
  };

  render() {
    const { account, loading } = this.props;

    if (loading || !account) return null;

    const q = queryString.parse(this.props.location.search, {
      arrayFormat: "bracket",
    });
    const step = q?.step
      ? q?.step.toString()
      : SetupStepsEnum.ABOUT_YOUR_PROGRAM;

    return (
      <div className="Setup">
        <div className="header">
          <div className="logo">
            <img src="/img/logo.png" alt="illuminate" />
          </div>
        </div>
        <div className="body">
          {step === SetupStepsEnum.ABOUT_YOUR_PROGRAM && (
            <AboutYourProgram account={account} />
          )}
          {step === SetupStepsEnum.ABOUT_YOUR_TOOLS && (
            <AboutYourTools account={account} />
          )}
          {step === SetupStepsEnum.ABOUT_YOUR_GOALS && <AboutYourGoals />}
          {step === SetupStepsEnum.CUSTOMIZE_YOUR_ACCOUNT && (
            <CustomizeYourAccount />
          )}
          {step === SetupStepsEnum.INVITE_YOUR_TEAM && <InviteYourTeam />}
          {step === SetupStepsEnum.CONGRATULATIONS && <Congratulations />}
        </div>
      </div>
    );
  }
}

const loadingSelector = createLoadingSelector([
  "@@account/GET_ACCOUNT",
  "@@account/GET_SETTINGS_OPTIONS",
  "@@account/GET_SETTINGS_OPTIONS_AVG_EXPERIMENTS_PER_MONTH",
  "@@account/GET_SETTINGS_OPTIONS_COE",
  "@@account/GET_SETTINGS_OPTIONS_COMPANY_SIZE",
  "@@account/GET_SETTINGS_OPTIONS_COMPANY_EXPERIMENTS_WIN_PER_MONTH",
  "@@account/GET_SETTINGS_OPTIONS_INDUSTRY",
  "@@account/GET_SETTINGS_OPTIONS_KIND_OF_TEST",
  "@@account/GET_SETTINGS_OPTIONS_KIND_OF_TOOL",
  "@@account/GET_SETTINGS_OPTIONS_PRIMARY_ANALYTICS_TOOL",
  "@@account/GET_SETTINGS_OPTIONS_PRIMARY_TESTING_TOOL",
  "@@account/GET_SETTINGS_OPTIONS_PROGRAM_TYPE",
  "@@account/GET_SETTINGS_OPTIONS_TESTING_CHALLENGE",
  "@@account/GET_SETTINGS_OPTIONS_TESTING_PROGRAM_REACH",
]);

const mapStateToProps = ({ app, account }: ApplicationState) => ({
  loading: loadingSelector(app.requests),
  account: account,
});

const mapDispatchToProps = {
  getSettingsOptionsRequest,
};

const connectedPage = connect(
  mapStateToProps,
  mapDispatchToProps
)(AppPage(Setup));

export { connectedPage as Setup };
