import React, { Component } from 'react';
import * as DataTypes from 'store/types';
import { KanbanColumn, KanbanItem } from 'ui';
import { DragDropContext } from 'react-beautiful-dnd';
import update from 'immutability-helper';
import { AccountLoader } from 'ui/loaders';

const columns = [
  { header: { icon: 'far fa-lightbulb', name: 'ideas' }, filter: 'none' },
  { header: { icon: 'fas fa-heart', name: 'love it' }, filter: 'love' },
  { header: { icon: 'fas fa-fire-alt', name: 'back burner' }, filter: 'back_burner' }
];

interface IProps {
  ideas: Array<DataTypes.Idea>;
  selectedIdea: DataTypes.Idea | null;
  currentUser: any;
  loading: boolean;
  onChange(id: string, key: string, value: string | Array<string> | boolean): void;
  onSelect(itemIndex: number): void;
}

interface IState {}

class Kanban extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      ideas: this.props.ideas
    };
  }

  handleDrop = (id: string, status: string) => {
    const { ideas } = this.props;
    const idea = ideas.find(idea => idea.id === id);

    if (idea) {
      idea.status = status;
      const ideaIndex = ideas.indexOf(idea);
      const newIdeas = update(ideas, {
        [ideaIndex]: { $set: idea }
      });

      this.setState({ ...this.state, ideas: newIdeas });
    }
  };

  onDragEnd = (result: any) => {
    if (result.destination) {
      const { ideas } = this.props;
      const idea = ideas.find(idea => idea.id === result.draggableId);

      if (idea) {
        idea.status = result.destination.droppableId;
        idea.index = result.destination.index;
        const ideaIndex = ideas.indexOf(idea);
        const newIdeas = update(ideas, {
          [ideaIndex]: { $set: idea }
        });

        this.setState({ ...this.state, ideas: newIdeas });
        this.props.onChange(idea.id || '', 'status', result.destination.droppableId);
      }
    }
  };

  onItemSelect = (item: DataTypes.Idea) => {
    const itemIdx = this.props.ideas.findIndex(i => i.id === item.id);
    this.props.onSelect(itemIdx);
  };

  render() {
    const { loading, ideas, selectedIdea } = this.props;

    if (loading) {
      return <AccountLoader message='Please wait while we load your ideas' loaded={!loading}><span></span></AccountLoader>;
    }

    return (
      <div className="columns">
        <DragDropContext onDragEnd={this.onDragEnd}>
          {columns.map((column, idx) => {
            return (
              <KanbanColumn
                currentUser={this.props.currentUser}
                id={column.filter}
                header={column.header}
                key={idx}
                items={
                  ideas &&
                  ideas
                    .filter(idea => idea.status === column.filter)
                    .sort((a, b) => (a.index > b.index ? 1 : -1))
                    .map((idea, index) => {
                      return (
                        <KanbanItem currentUser={this.props.currentUser} key={idea.id} item={idea} index={index}>
                          <div className={selectedIdea && selectedIdea.id === idea.id ? 'content active' : 'content'} onClick={() => this.onItemSelect(idea)}>
                            <div>
                              <i className={idea.star === true ? 'fa fa-star active' : 'fa fa-star'}></i>
                            </div>
                            <div className="body">
                              <h4>{idea.name}</h4>
                              <p className="description">{idea.problem?.blocks?.map((block: any) => block.text).join(' ')}</p>
                            </div>
                            <div>
                              <h4>{idea.score}</h4>
                              <p>score</p>
                            </div>
                          </div>
                        </KanbanItem>
                      );
                    })
                }
              />
            );
          })}
        </DragDropContext>
      </div>
    );
  }
}

export { Kanban };
