import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Helpers, history } from 'utils';
import { ApplicationState } from 'store/types';
import { createLoadingSelector, createErrorMessageSelector } from 'store/selectors';
import { initIdeaBoardManagementCreateRequest, createIdeaBoardManagementRequest } from 'store/idea_board_management/actions';
import { AppPage, AppPageProps } from 'comps/pages';
import { getIdeaBoardsTraitsRequest } from 'store/idea_board_trait/actions';
import { Form, Input, ValidatorTypes, Button, MultiSelect } from 'comps/form';

import '../../../css/Screens.scss';
import { EditorInput } from 'ui/editor_input/editor_input';
import { convertFromRaw, EditorState } from 'draft-js';

interface IProps extends AppPageProps {
  saving: boolean;
  loading: boolean;
  ideaBoardTrait: any;
  initIdeaBoardManagementCreateRequest: () => void;
  createIdeaBoardManagementRequest: (body: any) => void;
  getIdeaBoardsTraitsRequest: () => void;
}

interface IState {
  submitted: boolean;
}

class IdeaBoardCreate extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      submitted: false,
    };
  }

  componentDidMount = () => {
    this.props.initIdeaBoardManagementCreateRequest();
    this.props.getIdeaBoardsTraitsRequest();
    document.addEventListener('keydown', this.escFunction, false);
  };

  componentWillUnmount = () => {
    document.removeEventListener('keydown', this.escFunction, false);
  };

  escFunction = (event: any) => {
    if (event.keyCode === 27) {
      history.goBack();
    }
  };

  private close = () => {
    history.goBack();
  };

  private onSubmit = (valid: boolean, dirty: boolean, data: any) => {
    let descriptionStateValid = false;
    if (data.description.value) {
      descriptionStateValid = EditorState.createWithContent(convertFromRaw(data.description.value)).getCurrentContent().hasText();
    }

    if (valid && descriptionStateValid) {
      const ideaBoard = {
        name: data.name.value,
        description: data.description.value,
        pages: data.pages.value,
        devices: data.devices.value,
        audiences: data.audiences.value,
      };

      this.props.createIdeaBoardManagementRequest(ideaBoard);
    } else {
      this.setState({ submitted: true })
    }
  };

  render() {
    const { saving, account, loading, ideaBoardTrait } = this.props;

    if (loading === true) return null;
    if (!account || !ideaBoardTrait) return null;

    return (
      <div className="FullScreen">
        <div className="header">
          <img src="/img/logo_sm.svg" alt="illuminate" className="logo" />
          <button type="button" className="btn btn-close" onClick={this.close}>
            <i className="fas fa-times" />
          </button>
        </div>
        <div className="form-body">
          <div className="content simple">
            <div className="title">
              <h2>Create Idea Board</h2>
              <p>Use the form below to create a new board for your ideas!</p>
            </div>
            <Form
              formFields={{
                name: {
                  name: 'name',
                  value: '',
                  validator: {
                    type: ValidatorTypes.REQUIRED,
                    messages: {
                      required: 'Please enter a valid idea board name'
                    }
                  }
                },
                description: {
                  name: 'description',
                  value: '',
                },
                audiences: {
                  name: 'audiences',
                  value: [],
                  validator: {},
                },
                devices: {
                  name: 'devices',
                  value: [],
                  validator: {},
                },
                pages: {
                  name: 'pages',
                  value: [],
                  validator: {},
                },
              }}
              onSubmit={this.onSubmit}
              FormComponent={({ fields: { name, description, audiences, devices, pages }, onChange }) => (
                <div>
                  <section>
                    <div className="title">
                      <div className="step-circle">1</div>
                      <h4>Idea Board</h4>
                    </div>
                    <div className="body">
                      <div className="form-group">
                        <label>name</label>
                        <Input field={name} className="form-control" onChange={(e) => onChange(name, e.target.value)} />
                        <p>The name of the idea board that will be displayed across illuminate and when you invite others to collaborate on it, so use something unique, yet intuitive. You can use it again and again if you'd like, so try to avoid anything time or test number based.</p>
                      </div>
                      <div className="form-group">
                        <label>Why is this board being created?</label>
                        <EditorInput
                          onBlur={(e: any) => onChange(description, e)}
                          required={true}
                          submitted={this.state.submitted}
                          errorMessage="Please tell us the purpose of this board"
                          area={true} />
                        <p>Describe the purpose of this board. For example, to generate ideas for a specific page, to solve a specific problem, iterate on a past test - anything goes!</p>
                      </div>
                    </div>
                  </section>

                  <section>
                    <div className="title">
                      <div className="step-circle">2</div>
                      <h4>targeting</h4>
                      <p>If you'd like to generate (and request) ideas for specific audiences, devices or pages - feel free to include them here. This is optional.</p>
                    </div>
                    <div className="body">
                      <div className="form-group">
                        <label>audiences</label>
                        <MultiSelect
                          field={audiences}
                          options={
                            ideaBoardTrait.traits && ideaBoardTrait.traits['audiences']
                              ? Helpers.uniqueArray(
                                ideaBoardTrait.traits['audiences'].map((t: any) => {
                                  return t.name;
                                })
                              )
                              : []
                          }
                          className="multiselect_input create"
                          onChange={(value) => onChange(audiences, value)}
                        />
                        <p>Specify what audience(s) are targeted in this test.</p>
                      </div>
                      <div className="form-group">
                        <label>devices</label>
                        <MultiSelect
                          field={devices}
                          options={
                            ideaBoardTrait.traits && ideaBoardTrait.traits['devices']
                              ? Helpers.uniqueArray(
                                ideaBoardTrait.traits['devices'].map((t: any) => {
                                  return t.name;
                                })
                              )
                              : []
                          }
                          className="multiselect_input create"
                          onChange={(value) => onChange(devices, value)}
                        />
                        <p>Specify what audience(s) are targeted in this test.</p>
                      </div>
                      <div className="form-group">
                        <label>pages</label>
                        <MultiSelect
                          field={pages}
                          options={
                            ideaBoardTrait.traits && ideaBoardTrait.traits['pages']
                              ? Helpers.uniqueArray(
                                ideaBoardTrait.traits['pages'].map((t: any) => {
                                  return t.name;
                                })
                              )
                              : []
                          }
                          className="multiselect_input create"
                          onChange={(value) => onChange(pages, value)}
                        />
                        <p>Specify the page(s) that are targeted in this test.</p>
                      </div>
                    </div>
                  </section>

                  <hr />
                  <Button className="btn btn-primary" text="save idea board" isLoading={saving} />
                </div>
              )}
            />
          </div>
        </div>
      </div>
    );
  }
}

const initLoader = createLoadingSelector([
  '@@idea_board_management/INIT_IDEA_BOARD_MANAGEMENT_CREATE',
  '@@idea_board_traits/GET_IDEA_BOARDS_TRAITS',
]);
const initError = createErrorMessageSelector(['@@idea_board_management/INIT_IDEA_BOARD_MANAGEMENT_CREATE']);
const savingSelector = createLoadingSelector(['@@idea_board_management/CREATE_IDEA_BOARD_MANAGEMENT']);

const mapStateToProps = ({ router, app, account, ideaBoardTrait }: ApplicationState) => ({
  router,
  account,
  ideaBoardTrait,
  loading: initLoader(app.requests),
  initError: initError(app.errors),
  saving: savingSelector(app.requests),
});

const mapDispatchToProps = {
  initIdeaBoardManagementCreateRequest,
  createIdeaBoardManagementRequest,
  getIdeaBoardsTraitsRequest,
};

const connectedPage = connect(mapStateToProps, mapDispatchToProps)(AppPage(IdeaBoardCreate));

export { connectedPage as IdeaBoardCreate };
