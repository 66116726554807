import React, { Component } from "react";
import * as DataTypes from "store/types";

interface IProps {
  board: DataTypes.IdeaBoard;
  saving: boolean;
  onSubmit(board: DataTypes.IdeaBoard): void;
}

class DeleteBoard extends Component<IProps> {
  render() {
    const { board, onSubmit, saving } = this.props;

    return (
      <React.Fragment>
        <div className="header">
          <h3>DELETE IDEA BOARD</h3>
          <p>
            Are you sure you want to delete this idea board? This action cannot
            be undone and this idea board will be{" "}
            <strong>permanently deleted</strong> from illuminate. Ideas linked
            to it will remain in the Ideas section.
          </p>
        </div>
        <div className="cntrl-bar">
          {saving === true ? (
            <button className="btn btn-danger" type="submit" disabled={true}>
              <i className="fas fa-circle-notch fa-spin" />
            </button>
          ) : (
            <button className="btn btn-danger" onClick={() => onSubmit(board)}>
              Yes, delete this IDEA BOARD.
            </button>
          )}
        </div>
      </React.Fragment>
    );
  }
}

export { DeleteBoard };
