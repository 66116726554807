import React, { CSSProperties, Component } from "react";
import { ButtonTypes } from "./";

interface ButtonProps {
  style?: CSSProperties;
  text: string;
  className: string;
  disabled?: boolean | false;
  type?: ButtonTypes | ButtonTypes.SUBMIT;
  isLoading?: boolean | false;
  onClick?(): void;
}

class Button extends Component<ButtonProps> {
  render() {
    let button = (
      <button
        style={this.props.style || {}}
        className={this.props.className}
        disabled={this.props.disabled}
        type={this.props.type}
      >
        {this.props.text}
      </button>
    );

    if (this.props.onClick) {
      button = (
        <button
          style={this.props.style || {}}
          className={this.props.className}
          disabled={this.props.disabled}
          type={this.props.type}
          onClick={this.props.onClick}
        >
          {this.props.text}
        </button>
      );
    }

    if (this.props.isLoading) {
      button = (
        <button
          style={this.props.style || {}}
          className={this.props.className}
          disabled={true}
          type={this.props.type}
        >
          <i className="fas fa-circle-notch fa-spin" />
        </button>
      );
    }

    return button;
  }
}

export { Button };
