import React, { Component } from "react";
import { connect } from "react-redux";
import * as DataTypes from "store/types";
import { ReportTypes } from "config/app";
import { AddReport } from "./add_report";
import { AddTargetReport } from "./add_target_report";
import { AddAnalyticstReport } from "./add_analytics_report";

interface IProps {
  experiment: DataTypes.Experiment;
  saving: boolean;
  step: number;
  reportType?: string;
  metrics?: Array<any>;
  onSubmit(name: string, type: string, options?: any): void;
  onNextStep(reportType: string, step: number, csv: boolean): void;
}

interface IState {
  reportType: string;
  csv: boolean;
  selectedAdobeMetrics: Array<DataTypes.AdobeMetric>;
}

class AddReportOptions extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      reportType: this.props.reportType || ReportTypes.SUMMARY,
      csv: false,
      selectedAdobeMetrics: [],
    };
  }

  private onSelectReportType = (type: string, csv: boolean = false) => {
    this.setState({ ...this.state, reportType: type, csv });
  };

  private moveNext = (step: number) => {
    const { reportType, csv } = this.state;
    this.props.onNextStep(reportType, step, csv);
  };

  render() {
    const { experiment, saving, step, metrics } = this.props;
    const { reportType, csv } = this.state;

    if (step === 1) {
      return (
        <React.Fragment>
          <div className="header">
            <h3>Select Report Type</h3>
            <p>
              Which type of report would you like to create for this test?
              Remember that you can add multiple reports - preliminary reports,
              segment reports, you name it!
            </p>
          </div>
          <div className="body">
            <ul className="ReportSelector">
              <li
                className={
                  reportType === ReportTypes.SUMMARY && !csv ? "active" : ""
                }
                onClick={() => this.onSelectReportType(ReportTypes.SUMMARY)}
              >
                <img
                  src="/img/icon_only.png"
                  alt="illuminate"
                  className="mt-2"
                />
                <h4>Manual Data Entry</h4>
                <p>
                  Use the illuminate report builder to type in the data for your
                  test.
                </p>
              </li>
              <li
                className={
                  reportType === ReportTypes.SUMMARY && csv ? "active" : ""
                }
                onClick={() =>
                  this.onSelectReportType(ReportTypes.SUMMARY, true)
                }
              >
                <img
                  src="/img/icon_only.png"
                  alt="illuminate"
                  className="mt-2"
                />
                <h4>CSV Upload</h4>
                <p>Use the CSV template to upload your test data.</p>
              </li>

              {experiment.reports.length > 1 && (
                <li
                  className={
                    reportType === ReportTypes.CUSTOM_DATA_VIZ && !csv
                      ? "active"
                      : ""
                  }
                  onClick={() =>
                    this.onSelectReportType(ReportTypes.CUSTOM_DATA_VIZ)
                  }
                >
                  <img
                    src="/img/icon_only.png"
                    alt="illuminate"
                    className="mt-2"
                  />
                  <h4>Data Visualization</h4>
                  <p>
                    Apply a different data visualization to existing report
                    data.
                  </p>
                </li>
              )}

              {experiment.type === "Experiment::Adobe" &&
                experiment.adobe?.reporting_type === "analytics" && (
                  <li
                    className={
                      reportType === ReportTypes.ANALYTICS ? "active" : ""
                    }
                    onClick={() =>
                      this.onSelectReportType(ReportTypes.ANALYTICS)
                    }
                  >
                    <img src="/img/adobe_analytics.png" alt="adobe analytics" />
                    <h4>Adobe Analytics</h4>
                    <p>Build a report using Adobe Analytics data.</p>
                  </li>
                )}
              {experiment.type === "Experiment::Adobe" &&
                experiment.adobe?.reporting_type === "target" && (
                  <li
                    className={
                      reportType === ReportTypes.TARGET ? "active" : ""
                    }
                    onClick={() => this.onSelectReportType(ReportTypes.TARGET)}
                  >
                    <img src="/img/adobe_target.png" alt="adobe target" />
                    <h4>Adobe Target</h4>
                    <p>Generate a report using your Adobe Target data.</p>
                  </li>
                )}
            </ul>
          </div>
          <div className="cntrl-bar">
            {saving === true ? (
              <button
                aria-label="loading"
                className="btn btn-primary"
                type="button"
                disabled={true}
              >
                <i className="fas fa-circle-notch fa-spin" />
              </button>
            ) : (
              <button
                className="btn btn-primary"
                type="button"
                onClick={() => this.moveNext(2)}
              >
                Next
              </button>
            )}
          </div>
        </React.Fragment>
      );
    }

    if (step === 2 && reportType === ReportTypes.SUMMARY) {
      return (
        <AddReport experiment={this.props.experiment} onSubmit={() => {}} />
      );
    }

    if (step === 2 && reportType === ReportTypes.ANALYTICS) {
      return <AddAnalyticstReport experiment={experiment} metrics={metrics} />;
    }

    if (step === 2 && reportType === ReportTypes.TARGET) {
      return <AddTargetReport experiment={experiment} />;
    }

    return null;
  }
}

const mapStateToProps = () => ({});

const mapDispatchToProps = {};

const connectedComp = connect(
  mapStateToProps,
  mapDispatchToProps
)(AddReportOptions);

export { connectedComp as AddReportOptions };
