import { action } from "typesafe-actions";
import { Dispatch } from "redux";
import { ActionTypes } from "./types";
import * as DataTypes from "../types";

interface RequestError {
  statusCode?: number | undefined;
  message: any;
}

// User Clear
export const clearUser = () => (dispatch: Dispatch) =>
  dispatch(action(ActionTypes.CLEAR_USER, {}));

// User Login
export const loginRequest =
  (slug: string, email: string, password: string) => (dispatch: Dispatch) =>
    dispatch(action(ActionTypes.LOGIN_REQUEST, { slug, email, password }));
export const loginSuccess = (data: DataTypes.User) =>
  action(ActionTypes.LOGIN_SUCCESS, data);
export const loginFailure = (error: RequestError) =>
  action(ActionTypes.LOGIN_FAILURE, error);

// User Forgot Password
export const forgotPwdRequest =
  (slug: string, email: string) => (dispatch: Dispatch) =>
    dispatch(action(ActionTypes.FORGOT_PWD_REQUEST, { slug, email }));
export const forgotPwdSuccess = (data: DataTypes.User) =>
  action(ActionTypes.FORGOT_PWD_SUCCESS, data);
export const forgotPwdFailure = (error: RequestError) =>
  action(ActionTypes.FORGOT_PWD_FAILURE, error);

// User Reset Password
export const resetPwdRequest =
  (slug: string, token: string, password: string) => (dispatch: Dispatch) =>
    dispatch(action(ActionTypes.RESET_PWD_REQUEST, { slug, token, password }));
export const resetPwdSuccess = (data: DataTypes.User) =>
  action(ActionTypes.RESET_PWD_SUCCESS, data);
export const resetPwdFailure = (error: RequestError) =>
  action(ActionTypes.RESET_PWD_FAILURE, error);

// User Signup
export const signupRequest =
  (email: string, selfAccessment?: boolean) => (dispatch: Dispatch) =>
    dispatch(action(ActionTypes.SIGNUP_REQUEST, { email, selfAccessment }));
export const signupSuccess = (data: DataTypes.User) =>
  action(ActionTypes.SIGNUP_SUCCESS, data);
export const signupFailure = (error: RequestError) =>
  action(ActionTypes.SIGNUP_FAILURE, error);

// Init Invited User with Google
export const initInvitedGoogleUserRequest =
  (
    firstName: string,
    lastName: string,
    email: string,
    googleId: string,
    account: string,
    invite: string
  ) =>
  (dispatch: Dispatch) =>
    dispatch(
      action(ActionTypes.INIT_INVITED_GOOGLE_REQUEST, {
        firstName,
        lastName,
        email,
        googleId,
        account,
        invite,
      })
    );
export const initInvitedGoogleUserSuccess = (data: DataTypes.User) =>
  action(ActionTypes.INIT_INVITED_GOOGLE_SUCCESS, data);
export const initInvitedGoogleUserFailure = (error: RequestError) =>
  action(ActionTypes.INIT_INVITED_GOOGLE_FAILURE, error);

// User Google Signup
export const signupGoogleRequest =
  (firstName: string, lastName: string, email: string, googleId: string) =>
  (dispatch: Dispatch) =>
    dispatch(
      action(ActionTypes.GOOGLE_SIGNUP_REQUEST, {
        firstName,
        lastName,
        email,
        googleId,
      })
    );
export const signupGoogleSuccess = (data: DataTypes.User) =>
  action(ActionTypes.GOOGLE_SIGNUP_SUCCESS, data);
export const signupGoogleFailure = (error: RequestError) =>
  action(ActionTypes.GOOGLE_SIGNUP_FAILURE, error);

// User Login
export const loginGoogleRequest =
  (slug: string, tokenId: string) => (dispatch: Dispatch) =>
    dispatch(action(ActionTypes.LOGIN_GOOGLE_REQUEST, { slug, tokenId }));
export const loginGoogleSuccess = (data: DataTypes.User) =>
  action(ActionTypes.LOGIN_GOOGLE_SUCCESS, data);
export const loginGoogleFailure = (error: RequestError) =>
  action(ActionTypes.LOGIN_GOOGLE_FAILURE, error);

// User show
export const getUserRequest = (id: string) =>
  action(ActionTypes.GET_USER_REQUEST, { id });
export const getUserSuccess = (data: DataTypes.User) =>
  action(ActionTypes.GET_USER_SUCCESS, data);
export const getUserFailure = (error: RequestError) =>
  action(ActionTypes.GET_USER_FAILURE, error);

// User Profile Update
export const updateProfileRequest = (id: string, body: any) =>
  action(ActionTypes.UPDATE_PROFILE_REQUEST, { id, body });
export const updateProfileSuccess = (data: DataTypes.User) =>
  action(ActionTypes.UPDATE_PROFILE_SUCCESS, data);
export const updateProfileFailure = (error: RequestError) =>
  action(ActionTypes.UPDATE_PROFILE_FAILURE, error);

export const updateProfileImageRequest = (id: string, file: File) =>
  action(ActionTypes.UPDATE_PROFILE_IMAGE_REQUEST, { id, file });
export const updateProfileImageSuccess = (data: DataTypes.User) =>
  action(ActionTypes.UPDATE_PROFILE_IMAGE_SUCCESS, data);
export const updateProfileImageFailure = (error: RequestError) =>
  action(ActionTypes.UPDATE_PROFILE_IMAGE_FAILURE, error);

// User Password Update
export const updatePasswordRequest = (id: string, body: any) =>
  action(ActionTypes.UPDATE_PASSWORD_REQUEST, { id, body });
export const updatePasswordSuccess = (data: DataTypes.User) =>
  action(ActionTypes.UPDATE_PASSWORD_SUCCESS, data);
export const updatePasswordFailure = (error: RequestError) =>
  action(ActionTypes.UPDATE_PASSWORD_FAILURE, error);

// User Notification Update
export const updateNotificationsRequest = (id: string, body: any) =>
  action(ActionTypes.UPDATE_NOTIFICATIONS_REQUEST, { id, body });
export const updateNotificationsSuccess = (data: DataTypes.User) =>
  action(ActionTypes.UPDATE_NOTIFICATIONS_SUCCESS, data);
export const updateNotificationsFailure = (error: RequestError) =>
  action(ActionTypes.UPDATE_NOTIFICATIONS_FAILURE, error);

// Create Invited User
export const createInvitedUserRequest =
  (token: string, user: any) => (dispatch: Dispatch) =>
    dispatch(
      action(ActionTypes.CREATE_INVITED_USER_REQUEST, { id: token, user })
    );
export const createInvitedUserSuccess = (data: DataTypes.User) =>
  action(ActionTypes.CREATE_INVITED_USER_SUCCESS, data);
export const createInvitedUserError = (error: RequestError) =>
  action(ActionTypes.CREATE_INVITED_USER_FAILURE, error);

// Get Invite
export const getInviteRequest =
  (token: string, user: any) => (dispatch: Dispatch) =>
    dispatch(action(ActionTypes.GET_INVITE_REQUEST, { id: token, user }));
export const getInviteSuccess = (data: DataTypes.User) =>
  action(ActionTypes.GET_INVITE_SUCCESS, data);
export const getInviteError = (error: RequestError) =>
  action(ActionTypes.GET_INVITE_FAILURE, error);
