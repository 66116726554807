import React, { Component } from "react";
import * as DataTypes from "store/types";
import { Chart } from "./chart";
import { Table } from "./table";
import { EditorInput } from "ui/editor_input/editor_input";

interface IProps {
  report: DataTypes.Report;
  experiences: Array<DataTypes.Experience>;
}

interface IState {
  metricIdx: number;
}

class Report extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      metricIdx: 0,
    };
  }

  private setMetricIndex = (metricIdx: number) => {
    this.setState({ ...this.state, metricIdx: metricIdx });
  };

  render() {
    const { report, experiences } = this.props;
    const { metricIdx } = this.state;

    return (
      <React.Fragment>
        <section className="Report">
          <Chart metric={report.metrics[metricIdx]} experiences={experiences} />

          {report.description && (
            <section className="form-group mt__sm">
              <label>Data Storytelling</label>

              <EditorInput
                onBlur={() => {}}
                readOnly={true}
                content={report.description}
              />
            </section>
          )}

          <Table
            report={report}
            experiences={experiences}
            selectedRowIdx={metricIdx}
            onSelectedRow={this.setMetricIndex}
          />
        </section>
      </React.Fragment>
    );
  }
}
export { Report };
