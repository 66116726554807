import { ApiClient } from './client/api_client';

class ReportMetric {
  static async create(experimentId: string, reportId: string, body: any) {
    return await ApiClient.post(`/v1/experiment/${experimentId}/report/${reportId}/metric`, { metric: body });
  }

  static async destroy(experimentId: string, reportId: string, metricId: string) {
    return await ApiClient.delete(`/v1/experiment/${experimentId}/report/${reportId}/metric/${metricId}`);
  }

  static async update(experimentId: string, reportId: string, metric: any) {
    return await ApiClient.put(`/v1/experiment/${experimentId}/report/${reportId}/metric/${metric.id}`, { metric });
  }

  static async updateOrder(experimentId: string, reportId: string, metricId: string, position: number) {
    return await ApiClient.put(`/v1/experiment/${experimentId}/report/${reportId}/metric/${metricId}/order/${position}`, {});
  }
}

export { ReportMetric };
