import React, { useState, useRef, useEffect } from 'react';
import { Editor, EditorState, RichUtils, convertToRaw, convertFromRaw } from 'draft-js';
import { Toolbar } from './toolbar';
import 'draft-js/dist/Draft.css';
import { AuthService } from 'utils/auth';

interface IWorkSheetProps {
  title: string;
  content?: any;
  onChange(key: string, value: any): void;
  readOnly?: boolean;
  changeOnType?: boolean;
  setPristine: (pristine: boolean) => void;
  onInput?: any;
}

export const WorkSheet = ({ title, content, onChange, readOnly, setPristine, onInput }: IWorkSheetProps) => {
  const ref = useRef<any>(null);
  const [editorState, setEditorState] = useState(content ? EditorState.createWithContent(convertFromRaw(content)) : EditorState.createEmpty());
  const [inlineToolbar, setInlineToolbar] = useState({ show: false, position: { top: 0, left: 0 } });

  const styleMap = {
    STRIKETHROUGH: {
      textDecoration: 'line-through',
    },
  };

  useEffect(() => {
    const state = content
      ? EditorState.createWithContent(convertFromRaw(content))
      : EditorState.createEmpty();
    setEditorState(state);
  }, [content]); // add 'value' to the dependency list to recalculate state when value changes.

  const getSelectionRange = () => {
    const selection = window.getSelection();
    if (!selection || selection.rangeCount === 0) return null;
    return selection.getRangeAt(0);
  };

  const getSelectionCoords = (selectionRange: any) => {
    const worksheetElem = document.getElementById('WorkSheet');
    if (worksheetElem && worksheetElem !== null) {
      const editorBounds = worksheetElem.getBoundingClientRect();
      const rangeBounds = selectionRange.getBoundingClientRect();
      const rangeWidth = rangeBounds.right - rangeBounds.left;

      // 240 is width of inline toolbar
      let offsetLeft = rangeBounds.left - editorBounds.left + rangeWidth / 2 - 240 / 2;
      if (offsetLeft <= 0) {
        offsetLeft = 0;
      }

      // 42px is height of inline toolbar
      const offsetTop = rangeBounds.top - editorBounds.top - 30;

      return { offsetLeft, offsetTop };
    }
  };

  const onEditorChange = (editorState: any) => {
    setPristine(false);
    if (onInput) {
      onInput();
    }

    if (!editorState.getSelection().isCollapsed()) {
      const selectionRange = getSelectionRange();

      if (!selectionRange) {
        setInlineToolbar({ show: false, position: { top: 0, left: 0 } });
        return;
      }

      const selectionCoords = getSelectionCoords(selectionRange);
      setInlineToolbar({
        show: true,
        position: {
          top: selectionCoords?.offsetTop || 0,
          left: selectionCoords?.offsetLeft || 0,
        },
      });
    } else {
      setInlineToolbar({ show: false, position: { top: 0, left: 0 } });
    }

    setEditorState(editorState);
  };

  const persistContent = () => {
    const contentState = editorState.getCurrentContent();

    onChange('content', convertToRaw(contentState));
  };

  const toggleInlineStyle = (type: string, style: string) => {
    if (type === 'inline') onEditorChange(RichUtils.toggleInlineStyle(editorState, style));
    if (type === 'block') onEditorChange(RichUtils.toggleBlockType(editorState, style));
  };

  const isReadOnly = () => {
    const user = AuthService.getActiveUser();

    return user?.role === 'read-only';
  }

  return (
    <div ref={ref} className="WorkSheet" id="WorkSheet">
      {!readOnly === true && <Toolbar show={inlineToolbar.show} position={inlineToolbar.position} editorState={editorState} onToggle={toggleInlineStyle} />}
      <div>
        <label>idea board worksheet</label>
        <h1
          className="title"
          contentEditable={readOnly === true ? false : true}
          placeholder="Add a title"
          onBlur={(e) => onChange('title', e.target.innerText)}
        >
          {title}
        </h1>
        <Editor
          customStyleMap={styleMap}
          editorState={editorState}
          onChange={onEditorChange}
          placeholder={
            'Enter your text here. This worksheet can be used to communicate goals, quantitative data, qualitative data, interesting insights, or provide more context around why this idea board was created.'
          }
          spellCheck={true}
          stripPastedStyles={true}
          onBlur={persistContent}
          readOnly={isReadOnly() || readOnly}
        />
      </div>
    </div>
  );
};
