import React from "react";
import { FieldErrors, UseFormRegister } from "react-hook-form";
import { Container } from "./index.style";

interface Props {
  fieldName: string;
  register: UseFormRegister<any>;
  className?: string;
  disabled?: boolean;
  autoFocus?: boolean;
  onBlur?: any;
  value: any;
  placeholder?: string;
  errors: FieldErrors<any>;
  label: string;
  inputClassName?: string;
}

export const UseFormInput: React.FC<Props> = ({
  autoFocus,
  className,
  placeholder,
  fieldName,
  disabled,
  value,
  register,
  errors,
  label,
  inputClassName,
}) => {
  return (
    <Container className={["form-group", className || ""].join(" ")}>
      <label className="mbr-05" htmlFor={fieldName}>
        {label}
      </label>

      <div className={inputClassName || ""}>
        <input
          className={[
            errors[fieldName] ? "error" : "",
            "form-control",
            inputClassName || "",
          ].join(" ")}
          id={fieldName}
          type={"text"}
          defaultValue={value}
          placeholder={placeholder}
          autoFocus={autoFocus}
          autoComplete="none"
          disabled={disabled}
          {...register(fieldName)}
        />
      </div>
      {errors[fieldName] && (
        <div className="error">{errors[fieldName]?.message}</div>
      )}
    </Container>
  );
};
