import React, { Component } from "react";
import { connect } from "react-redux";
import {
  getRattingsRequest,
  createRattingsRequest,
} from "store/ratting/actions";
import { ApplicationState } from "store/types";

interface IProps {
  featureName: string;
  ratting: any;
  getRattingsRequest: any;
  createRattingsRequest: any;
  style?: any;
}

interface IState {}

class DidYouLikeIt extends Component<IProps, IState> {
  componentDidMount() {
    this.props.getRattingsRequest();
  }

  setRatting = (likedIt: boolean) => {
    this.props.createRattingsRequest(this.props.featureName, likedIt);
  };

  currentRatting = () => {
    const rattings = this.props.ratting?.rattings?.filter(
      (rate: any) => rate.feature_name === this.props.featureName
    );

    if (rattings && rattings.length > 0) {
      return rattings[0];
    }

    return null;
  };

  render() {
    return (
      <div style={this.props.style}>
        <p>Was this helpful?</p>

        <div style={{ display: "flex" }}>
          <i
            className="fa fa-thumbs-up"
            style={{
              color:
                this.currentRatting() && this.currentRatting().liked_it
                  ? "#89cad7"
                  : "#aaa",
              cursor: "pointer",
            }}
            onClick={() => this.setRatting(true)}
          ></i>
          <i
            className="fa fa-thumbs-down"
            onClick={() => this.setRatting(false)}
            style={{
              marginLeft: "12px",
              cursor: "pointer",
              color:
                this.currentRatting() && !this.currentRatting().liked_it
                  ? "#ff5a71"
                  : "#aaa",
            }}
          ></i>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ ratting }: ApplicationState) => ({
  ratting,
});

const mapDispatchToProps = {
  getRattingsRequest,
  createRattingsRequest,
};

const connectedPage = connect(
  mapStateToProps,
  mapDispatchToProps
)(DidYouLikeIt);

export { connectedPage as DidYouLikeIt };
