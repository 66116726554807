import { Reducer } from "redux";
import * as DataTypes from "../types";
import { ActionTypes } from "./types";

interface RattingState {
  rattings: Array<DataTypes.Ratting>;
}

const initialState: RattingState | null = null;

const reducer: Reducer<RattingState | null> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case ActionTypes.GET_RATTING_SUCCESS: {
      return {
        ...state,
        rattings: action.payload.response.rattings,
      };
    }
    default: {
      return state;
    }
  }
};

export { reducer as rattingReducer };
