import { action } from "typesafe-actions";
import { Dispatch } from "redux";
import { ActionTypes } from "./types";
import * as DataTypes from "../types";

interface RequestError {
  statusCode?: number | undefined;
  message: any;
}
export const getInsightProposalsRequest =
  (callback: () => void) => (dispatch: Dispatch) =>
    dispatch(action(ActionTypes.GET_INSIGHTS_PROPOSALS_REQUEST, { callback }));
export const getInsightProposalsSuccess = (response: DataTypes.WordCloud) =>
  action(ActionTypes.GET_INSIGHTS_PROPOSALS_SUCCESS, { response });
export const getInsightProposalsError = (error: RequestError) =>
  action(ActionTypes.GET_INSIGHTS_PROPOSALS_FAILURE, error);

export const createInsightProposalsRequest =
  (unselected: string[]) => (dispatch: Dispatch) =>
    dispatch(
      action(ActionTypes.CREATE_INSIGHTS_PROPOSALS_REQUEST, { unselected })
    );
export const createInsightProposalsSuccess = (response: DataTypes.WordCloud) =>
  action(ActionTypes.CREATE_INSIGHTS_PROPOSALS_SUCCESS, { response });
export const createInsightProposalsError = (error: RequestError) =>
  action(ActionTypes.CREATE_INSIGHTS_PROPOSALS_FAILURE, error);
