import React, { Component } from 'react';
import * as DataTypes from 'store/types';
import { Form, Input, Button, ValidatorTypes, Select } from 'comps/form';
import { createReportTargetRequest } from 'store/report_target/actions';
import { ApplicationState } from 'store/types';
import { connect } from 'react-redux';
import { createLoadingSelector } from 'store/selectors';

interface IProps {
  experiment: DataTypes.Experiment;
  saving: boolean;
  createReportTargetRequest(experimentId: string, body: any): void;
}

const COUNTING_MEHODOLOGIES = ['visits', 'visitors', 'impressions'];

class AddTargetReport extends Component<IProps> {
  private onSubmit = (valid: boolean, dirty: boolean, data: any) => {
    const experimentId = this.props.experiment.id;

    if (valid && experimentId) {
      this.props.createReportTargetRequest(experimentId, { name: data.name.value });
    }
  };

  render() {
    const { saving } = this.props;

    return (
      <React.Fragment>
          <div className="header">
            <div className="d-flex">
              <div>
                <img src="/img/adobe_target.png" className="mr-2" alt="adobe target" />
              </div>
              <h3>Adobe Target Data Report</h3>
            </div>

            <p>Use the form below to create a new report using Adobe Target data.</p>
          </div>
          <Form
            formFields={{
              name: { name: 'name', value: '', validator: { type: ValidatorTypes.REQUIRED, messages: { required: 'Please enter a report name.' } } },
              counting_methodology: {
                name: 'counting_methodology',
                value: '',
                validator: { type: ValidatorTypes.REQUIRED, messages: { required: 'Please select a counting methodology.' } },
              },
            }}
            onSubmit={this.onSubmit}
            FormComponent={({ fields: { name, counting_methodology }, onChange }) => (
              <React.Fragment>
                <div className="body">
                  <div className="form-group">
                    <label>Report name</label>
                    <Input field={name} className="form-control" onChange={(e) => onChange(name, e.target.value)} />
                  </div>
                  <div className="form-group">
                    <label>What counting methodology would you like to use to create your report?</label>
                    <Select
                      key="counting_methodology"
                      className="AdobeMetric SelectInput"
                      placeholder="-- Select Counting Methodology --"
                      field={counting_methodology}
                      options={COUNTING_MEHODOLOGIES}
                      onChange={(value: string) => onChange(counting_methodology, value)}
                    />
                  </div>
                </div>
                <div className="cntrl-bar">
                  <Button className="btn btn-primary" text="create report" isLoading={saving} />
                </div>
              </React.Fragment>
            )}
          />
        </React.Fragment>
    );
  }
}

const loadingSelector = createLoadingSelector(['@@report_summary/CREATE_REPORT_SUMMARY']);

const mapStateToProps = ({ app, router }: ApplicationState) => ({
  saving: loadingSelector(app.requests),
  router,
});

const mapDispatchToProps = {
  createReportTargetRequest,
};

const connectedPage = connect(mapStateToProps, mapDispatchToProps)(AddTargetReport);

export { connectedPage as AddTargetReport };