import { Reducer } from 'redux';
import * as DataTypes from '../types';
import { ActionTypes } from './types';

const initialState: DataTypes.Application | null = null;

const reducer: Reducer<DataTypes.ApiList<DataTypes.Application> | null> = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.GET_APPLICATION_SUCCESS: {
      return action.payload.response;
    }
    default: {
      return state;
    }
  }
};

export { reducer as applicationReducer };
