import { Dispatch } from "redux";
import { ActionTypes } from "./types";
import { action } from "typesafe-actions";
import * as DataTypes from '../types';
import { OrderDirection } from "ui/order_icon";

interface RequestError {
  statusCode?: number | undefined;
  message: any;
}

// Init Idea Board Create
export const initIdeaBoardManagementCreateRequest = () => (dispatch: Dispatch) => dispatch(action(ActionTypes.INIT_IDEA_BOARD_MANAGEMENT_CREATE_REQUEST, {}));
export const initIdeaBoardManagementCreateSuccess = () => action(ActionTypes.INIT_IDEA_BOARD_MANAGEMENT_CREATE_SUCCESS, {});
export const initIdeaBoardManagementCreateError = (error: RequestError) => action(ActionTypes.INIT_IDEA_BOARD_MANAGEMENT_CREATE_FAILURE, error);

// Get Idea Boards
export const showIdeaBoardsRequest = (board_id: string) => action(ActionTypes.SHOW_IDEA_BOARDS_REQUEST, { board_id });
export const showIdeaBoardsSuccess = (response: DataTypes.ApiList<DataTypes.IdeaBoard>) => action(ActionTypes.SHOW_IDEA_BOARDS_SUCCESS, { response });
export const showIdeaBoardsError = (error: RequestError) => action(ActionTypes.SHOW_IDEA_BOARDS_FAILURE, error);

// Create Idea Board
export const createIdeaBoardManagementRequest = (body: any) => (dispatch: Dispatch) => dispatch(action(ActionTypes.CREATE_IDEA_BOARD_MANAGEMENT_REQUEST, { body }));
export const createIdeaBoardManagementSuccess = (response: DataTypes.ApiRequest<DataTypes.IdeaBoard>) => action(ActionTypes.CREATE_IDEA_BOARD_MANAGEMENT_SUCCESS, { response });
export const createIdeaBoardManagementError = (error: RequestError) => action(ActionTypes.CREATE_IDEA_BOARD_MANAGEMENT_FAILURE, error);

// Get Idea Boards
export const getIdeaBoardsManagementRequest = (options?: DataTypes.ApiListOptions, scrollOpts?: { append: boolean }) =>
  action(ActionTypes.GET_IDEA_BOARDS_MANAGEMENT_REQUEST, { options, scrollOpts });
export const getIdeaBoardsManagementSuccess = (response: DataTypes.ApiList<DataTypes.IdeaBoard>, append?: boolean) =>
  action(ActionTypes.GET_IDEA_BOARDS_MANAGEMENT_SUCCESS, { response, append });
export const getIdeaBoardsManagementError = (error: RequestError) => action(ActionTypes.GET_IDEA_BOARDS_MANAGEMENT_FAILURE, error);
export const getIdeaBoardsManagementSaga = action(ActionTypes.GET_IDEA_BOARDS_MANAGEMENT_REQUEST, {}, {});

export const deleteIdeaBoardsManagementRequest = (id: string) => (dispatch: Dispatch) => dispatch(action(ActionTypes.DELETE_IDEA_BOARD_MANAGEMENT_REQUEST, { id }));
export const deleteIdeaBoardsManagementSuccess = (response: DataTypes.ApiRequest<DataTypes.IdeaBoard>) => action(ActionTypes.DELETE_IDEA_BOARD_MANAGEMENT_SUCCESS, { response });
export const deleteIdeaBoardsManagementError = (error: RequestError) => action(ActionTypes.DELETE_IDEA_BOARD_MANAGEMENT_FAILURE, error);

export const updateIdeaBoardManagementRequest = (id: string, body: any, onUpdate?: any) => (dispatch: Dispatch) => dispatch(action(ActionTypes.UPDATE_IDEA_BOARD_MANAGEMENT_REQUEST, { id, body, onUpdate }));
export const updateIdeaBoardManagementSuccess = (response: DataTypes.ApiRequest<DataTypes.IdeaBoard>) => action(ActionTypes.UPDATE_IDEA_BOARD_MANAGEMENT_SUCCESS, { response });
export const updateIdeaBoardManagementError = (error: RequestError) => action(ActionTypes.UPDATE_IDEA_BOARD_MANAGEMENT_FAILURE, error);

export const archiveIdeaBoardManagementRequest = (id: string, body: any, onUpdate?: any) => (dispatch: Dispatch) => dispatch(action(ActionTypes.ARCHIVE_IDEA_BOARD_MANAGEMENT_REQUEST, { id, body, onUpdate }));
export const archiveIdeaBoardManagementSuccess = (response: DataTypes.ApiRequest<DataTypes.IdeaBoard>) => action(ActionTypes.ARCHIVE_IDEA_BOARD_MANAGEMENT_SUCCESS, { response });
export const archiveIdeaBoardManagementError = (error: RequestError) => action(ActionTypes.ARCHIVE_IDEA_BOARD_MANAGEMENT_FAILURE, error);

export const clearIdeaBoardManagement = () => action(ActionTypes.CLEAR_IDEA_BOARDS_MANAGEMENT);

export const reorderIdeaBoardRequest = (id: string, position: number, currentOrder: OrderDirection) =>
  (dispatch: Dispatch) => dispatch(action(ActionTypes.REORDER_REQUEST, { id, position, currentOrder }));
export const reorderIdeaBoardSuccess = (response: DataTypes.ApiList<DataTypes.IdeaBoard>, currentOrder: OrderDirection) =>
  action(ActionTypes.REORDER_SUCCESS, { response, currentOrder });
export const reorderIdeaBoardError = (error: RequestError) => action(ActionTypes.REORDER_FAILURE);