import React from 'react';
import styled from 'styled-components';

interface IProps {
  name: string;
  total: number;
}

export const TotalCountStat = ({ total, name }: IProps) => {
  return (
    <StyledTotalCount>
      <div className="count">{total}</div>
      <div className="label">{`TOTAL ${name.toUpperCase()}`}</div>
    </StyledTotalCount>
  );
};

const StyledTotalCount = styled.div`
  text-align: center;
  font-family: Roboto;
  font-weight: 300;
  padding-bottom: 20px;

  .count {
    font-size: 48px;
    color: #2f4760; 
    line-height: 42px;
  }
  .label {
    color: #757e8e;
    font-size: 12px;
    font-weight: 500;
  }
`;
