import styled from "styled-components";

export const Container = styled("div")`
  display: flex;
  flex-direction: column;

  .currency {
    position: relative;

    &.dollar {
      &::before {
        content: "$";
      }
    }

    &.euro {
      &::before {
        content: "€";
      }
    }

    &.pound {
      &::before {
        content: "£";
      }
    }

    &::before {
      z-index: 5;
      position: absolute;
      font-size: 14px;
      left: 14px;
      top: -2px;
      line-height: 43px;
      height: 41px;
      font-weight: bold;
      color: #aeaeae;
    }

    input {
      padding-left: 24px;
    }
  }

  input {
    padding: 12px 14px 12px 14px;

    &::placeholder {
      font-size: 12px;
      position: relative;
      top: -2px;
      color: #aeaeae;
    }
  }
`;
