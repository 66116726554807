import { action } from 'typesafe-actions';
import { Dispatch } from 'redux';
import { ActionTypes } from './types';
import * as DataTypes from '../types';

interface RequestError {
  statusCode?: number | undefined;
  message: any;
}

export const updateExperienceRequest = (experimentId: string, experienceId: string, body: any) => (dispatch: Dispatch) =>
  dispatch(action(ActionTypes.UPDATE_EXPERIENCE_REQUEST, { experimentId, experienceId, body }));
export const updateExperienceSuccess = (response: DataTypes.ApiRequest<DataTypes.Experiment>) =>
  action(ActionTypes.UPDATE_EXPERIENCE_SUCCESS, { response });
export const updateExperienceError = (error: RequestError) =>
  action(ActionTypes.UPDATE_EXPERIENCE_FAILURE, error);

export const createExperienceRequest = (experimentId: string, body: any) => (dispatch: Dispatch) =>
  dispatch(action(ActionTypes.CREATE_EXPERIENCE_REQUEST, { experimentId, body }));
export const createExperienceSuccess = (response: DataTypes.ApiRequest<DataTypes.Experiment>) =>
  action(ActionTypes.CREATE_EXPERIENCE_SUCCESS, { response });
export const createExperienceError = (error: RequestError) =>
  action(ActionTypes.CREATE_EXPERIENCE_FAILURE, error);

export const destroyExperienceRequest = (experimentId: string, experienceId: any) => (dispatch: Dispatch) =>
  dispatch(action(ActionTypes.DESTROY_EXPERIENCE_REQUEST, { experimentId, experienceId }));
export const destroyExperienceSuccess = (response: DataTypes.ApiRequest<DataTypes.Experiment>) =>
  action(ActionTypes.DESTROY_EXPERIENCE_SUCCESS, { response });
export const destroyExperienceError = (error: RequestError) =>
  action(ActionTypes.DESTROY_EXPERIENCE_FAILURE, error);

export const addImageRequest = (experimentId: string, experienceId: string, file: File) => (dispatch: Dispatch) =>
  dispatch(action(ActionTypes.ADD_IMAGE_REQUEST, { experimentId, experienceId, file }));
export const addImageSuccess = (response: DataTypes.ApiRequest<DataTypes.Experiment>) =>
  action(ActionTypes.ADD_IMAGE_SUCCESS, { response });
export const addImageError = (error: RequestError) =>
  action(ActionTypes.ADD_IMAGE_FAILURE, error);

export const deleteImageRequest = (experimentId: string, experienceId: string, imageId: string) => (dispatch: Dispatch) =>
  dispatch(action(ActionTypes.DELETE_IMAGE_REQUEST, { experimentId, experienceId, imageId }));
export const deleteImageSuccess = (response: DataTypes.ApiRequest<DataTypes.Experiment>) =>
  action(ActionTypes.DELETE_IMAGE_SUCCESS, { response });
export const deleteImageError = (error: RequestError) =>
  action(ActionTypes.DELETE_IMAGE_FAILURE, error);

export const updateImageRequest = (experimentId: string, experienceId: string, imageId: string, body: any) => (dispatch: Dispatch) =>
  dispatch(action(ActionTypes.UPDATE_IMAGE_REQUEST, { experimentId, experienceId, imageId, body }));
export const updateImageSuccess = (response: DataTypes.ApiRequest<DataTypes.Experiment>) =>
  action(ActionTypes.UPDATE_IMAGE_SUCCESS, { response });
export const updateImageError = (error: RequestError) =>
  action(ActionTypes.UPDATE_IMAGE_FAILURE, error);

export const addImageCommentRequest = (experimentId: string, experienceId: string, imageId: string, body: any) => (dispatch: Dispatch) =>
  dispatch(action(ActionTypes.ADD_IMAGE_COMMENT_REQUEST, { experimentId, experienceId, imageId, body }));
export const addImageCommentSuccess = (response: DataTypes.ApiRequest<DataTypes.Experiment>) =>
  action(ActionTypes.ADD_IMAGE_COMMENT_SUCCESS, { response });
export const addImageCommentError = (error: RequestError) =>
  action(ActionTypes.ADD_IMAGE_COMMENT_FAILURE, error);

export const deleteImageCommentRequest = (experimentId: string, experienceId: string, imageId: string, commentId: string) => (dispatch: Dispatch) =>
  dispatch(action(ActionTypes.DELETE_IMAGE_COMMENT_REQUEST, { experimentId, experienceId, imageId, commentId }));
export const deleteImageCommentSuccess = (response: DataTypes.ApiRequest<DataTypes.Experiment>) =>
  action(ActionTypes.DELETE_IMAGE_COMMENT_SUCCESS, { response });
export const deleteImageCommentError = (error: RequestError) =>
  action(ActionTypes.DELETE_IMAGE_COMMENT_FAILURE, error);