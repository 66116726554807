import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import * as API from "../../api";
import * as Actions from "./actions";
import * as AccountActions from "../account/actions";
import * as ExperimentTraitActions from "../experiment_trait/actions";
import * as AppTypes from "../app/types";
import { ActionTypes } from "./types";
import * as DataTypes from "store/types";
import { history } from "utils";

function* getExperiments(action: any) {
  try {
    // @ts-ignore
    const res = yield call(API.Experiments.index, action.payload.options);

    const append =
      action.payload.scrollOpts && action.payload.scrollOpts.append;
    yield put(Actions.getExperimentsSuccess(res.body, append));
  } catch (error: any) {
    yield put(Actions.getExperimentsError(error));
    yield put({
      type: AppTypes.App.ALERT_SHOW,
      content: "Something went wrong.",
      error: true,
    });
  }
}

function* getExperiment(action: any) {
  try {
    // @ts-ignore
    const res = yield call(API.Experiments.show, action.payload.id);
    yield put(Actions.getExperimentSuccess(res.body));
  } catch (error: any) {
    yield put(Actions.getExperimentError(error));
  }
}

function* getCaseStudy(action: any) {
  try {
    const { id: experimentId } = action.payload;

    // @ts-ignore
    const res = yield call(API.CaseStudies.create, experimentId);

    yield put(Actions.getCaseStudySuccess(res.body));
  } catch (error: any) {
    yield put(Actions.getCaseStudyError(error));
  }
}

function* getShareCaseStudy(action: any) {
  try {
    // @ts-ignore
    let res = yield call(
      API.Share.case_study,
      action.payload.slug,
      action.payload.uuid,
      action.payload.password
    );

    yield put(Actions.getShareCaseStudySuccess(res.body));
  } catch (error: any) {
    yield put(Actions.getShareCaseStudyError(error));
  }
}

function* updateCaseStudy(action: any) {
  try {
    if (!action.payload.disableHideModal) {
      yield put({ type: AppTypes.App.MODAL_SAVING });
    }

    const { id: experimentId } = action.payload;
    const { id: caseStudyId } = action.payload.body.case_study;

    // @ts-ignore
    const res = yield call(
      API.CaseStudies.update,
      experimentId,
      caseStudyId,
      action.payload.body.case_study
    );

    yield put(Actions.updateCaseStudySuccess(res.body));

    if (!action.payload.disableHideModal) {
      yield put({ type: AppTypes.App.MODAL_HIDE });
    }

    yield put({
      type: AppTypes.App.ALERT_SHOW,
      content: "Case study updated successfully",
    });
  } catch (error: any) {
    yield put(Actions.updateCaseStudyError(error));
  }
}

function* initExpCreate(action: any) {
  try {
    const { apps } = yield all({
      traits: call(API.Account.traits),
      apps: call(API.Apps.index),
    });
    yield all([
      put(ExperimentTraitActions.getExperimentsTraitRequestSaga()),
      put(AccountActions.getAccountAppsSuccess(apps.body)),
      put(Actions.initExpCreateSuccess()),
    ]);
  } catch (error: any) {
    yield put(Actions.initExpCreateError(error));
  }
}

function* createExperiment(action: any) {
  try {
    // @ts-ignore
    const res = yield call(API.Experiments.create, action.payload.body);
    yield all([
      put(ExperimentTraitActions.getExperimentsTraitRequestSaga()),
      put(Actions.createExperimentSuccess(res.body)),
      put(AccountActions.getAccountSummaryRequest()),
    ]);
    history.push("/tests/" + res.body.id);
    yield put({
      type: AppTypes.App.ALERT_SHOW,
      content: "Test created successfully",
    });
  } catch (error: any) {
    yield put(Actions.createExperimentError(error));
  }
}

function* createExperimentAB(action: any) {
  try {
    // @ts-ignore
    const res = yield call(API.AbExperiments.create, action.payload.body);

    yield all([
      put(ExperimentTraitActions.getExperimentsTraitRequestSaga()),
      put(Actions.createExperimentSuccess(res.body)),
      put(Actions.createABExperimentSuccess(res.body)),
      put(AccountActions.getAccountSummaryRequest()),
    ]);

    history.push("/tests/" + res.body.id);
    yield put({
      type: AppTypes.App.ALERT_SHOW,
      content: "Test created successfully",
    });
  } catch (error: any) {
    yield put(Actions.createExperimentError(error));
    yield put(Actions.createAdobeExperimentError(error));
  }
}

function* createExperimentAdobe(action: any) {
  try {
    // @ts-ignore
    const res = yield call(API.AdobeExperiments.create, action.payload.body);

    yield all([
      put(ExperimentTraitActions.getExperimentsTraitRequestSaga()),
      put(Actions.createExperimentSuccess(res.body)),
      put(Actions.createAdobeExperimentSuccess(res.body)),
      put(AccountActions.getAccountSummaryRequest()),
    ]);

    history.push("/tests/" + res.body.id);
    yield put({
      type: AppTypes.App.ALERT_SHOW,
      content: "Test created successfully",
    });
  } catch (error: any) {
    yield put(Actions.createExperimentError(error));
    yield put(Actions.createAdobeExperimentError(error));
  }
}

function* updateExperiment(action: any) {
  try {
    // @ts-ignore
    const res = yield call(
      API.Experiments.update,
      action.payload.id,
      action.payload.body
    );

    yield put(ExperimentTraitActions.getExperimentsTraitRequestSaga());
    yield put(Actions.updateExperimentSuccess(res.body));
    yield put({
      type: AppTypes.App.ALERT_SHOW,
      content: "Test updated successfully",
    });
  } catch (error: any) {
    yield put(Actions.updateExperimentError(error));
  }
}

function* updateABExperiment(action: any) {
  try {
    // @ts-ignore
    const res = yield call(
      API.AbExperiments.update,
      action.payload.id,
      action.payload.body
    );

    yield put(ExperimentTraitActions.getExperimentsTraitRequestSaga());
    yield put(Actions.updateABExperimentSuccess(res.body));
    yield put({
      type: AppTypes.App.ALERT_SHOW,
      content: "Test updated successfully",
    });
  } catch (error: any) {
    yield put(Actions.updateABExperimentError(error));
  }
}

function* updateAdobeExperiment(action: any) {
  try {
    // @ts-ignore
    const res = yield call(
      API.AdobeExperiments.update,
      action.payload.id,
      action.payload.body
    );

    yield put(ExperimentTraitActions.getExperimentsTraitRequestSaga());
    yield put(Actions.updateAdobeExperimentSuccess(res.body));
    yield put({
      type: AppTypes.App.ALERT_SHOW,
      content: "Test updated successfully",
    });
  } catch (error: any) {
    yield put(Actions.updateAdobeExperimentError(error));
  }
}

function* deleteExperience(action: any) {
  try {
    yield put({ type: AppTypes.App.MODAL_SAVING });
    // @ts-ignore
    const res = yield call(
      API.Experiments.update,
      action.payload.id,
      action.payload.body
    );
    yield put(Actions.deleteExperienceSuccess(res.body));
    yield put({ type: AppTypes.App.MODAL_HIDE });
    yield put({
      type: AppTypes.App.ALERT_SHOW,
      content: "Experience deleted successfully",
    });
  } catch (error: any) {
    yield put(Actions.deleteExperienceError(error));
  }
}

function* createReport(action: any) {
  try {
    yield put({ type: AppTypes.App.MODAL_SAVING });
    // @ts-ignore
    const res = yield call(
      API.Experiments.update,
      action.payload.id,
      action.payload.body
    );
    yield put(Actions.createReportSuccess(res.body));
    history.push(
      "/tests/" +
        action.payload.id +
        "/results/" +
        res.body.reports[res.body.reports.length - 1].id
    );

    const { callback } = action.payload;
    if (callback) {
      callback(res.body);
    }

    yield put({ type: AppTypes.App.MODAL_HIDE });
  } catch (error: any) {
    yield put({ type: AppTypes.App.MODAL_ERROR });
    yield put(Actions.createReportError(error));
  }
}

function* deleteReport(action: any) {
  try {
    yield put({ type: AppTypes.App.MODAL_SAVING });
    // @ts-ignore
    const resReport = yield call(
      API.Reports.destroy,
      action.payload.experiment_id,
      action.payload.report_id
    );
    // @ts-ignore
    const resExperiment = yield call(
      API.Experiments.show,
      action.payload.experiment_id
    );
    yield put(Actions.getExperimentSuccess(resExperiment.body));
    yield put(Actions.onDeleteReportSuccess(resReport));
    history.push("/tests/" + action.payload.experiment_id + "/results");
    yield put({ type: AppTypes.App.MODAL_HIDE });
  } catch (error: any) {
    yield put(Actions.onDeleteReportError(error));
  }
}

function* updateReportStats(action: any) {
  try {
    const { experimentId, reportId } = action.payload;
    yield put({ type: AppTypes.App.MODAL_OPTIONS, options: { loading: true } });

    // @ts-ignore
    const resReport = yield call(API.Reports.update, experimentId, reportId, {
      report: { build: true },
    });
    yield put(Actions.onUpdateReportStatsSuccess(resReport.body));
    yield put({
      type: AppTypes.App.MODAL_OPTIONS,
      options: { loading: false },
    });
    yield put({ type: AppTypes.App.MODAL_HIDE });
  } catch (error: any) {
    yield put({
      type: AppTypes.App.MODAL_OPTIONS,
      options: { loading: false, error: error },
    });
    yield put(Actions.onUpdateReportStatsError(error));
  }
}

function* updateReport(action: any) {
  try {
    // @ts-ignore
    const res = yield call(API.Experiments.update, action.payload.id, {
      reports: [action.payload.report],
    });

    yield put({
      type: AppTypes.App.ALERT_SHOW,
      content: "Report updated successfully",
    });
    yield put(Actions.updateReportSuccess(res.body));
  } catch (error: any) {
    yield put(Actions.updateReportError(error));
  }
}

function* linkInsights(action: any) {
  try {
    yield put({ type: AppTypes.App.MODAL_SAVING });
    const { id, insights } = action.payload;

    const promises = insights.map((insight: DataTypes.Insight) =>
      call(API.Experiments.addInsight, id, insight.id || "")
    );

    // @ts-ignore
    const resList = yield all(promises);

    yield put(Actions.onLinkInsightsSuccess(resList[0].body));
    yield put({ type: AppTypes.App.MODAL_HIDE });
    yield put({
      type: AppTypes.App.ALERT_SHOW,
      content: "Insights added successfully",
    });
  } catch (error: any) {
    yield put(Actions.onLinkInsightsError(error));
  }
}

function* unlinkInsights(action: any) {
  try {
    const { id, insightId } = action.payload;

    // @ts-ignore
    const res = yield call(API.Experiments.removeInsight, id, insightId);

    yield put(Actions.onUnLinkInsightsSuccess(res.body));

    yield put({
      type: AppTypes.App.ALERT_SHOW,
      content: "Insight removed successfully",
    });
    yield put({ type: AppTypes.App.MODAL_HIDE });
  } catch (error: any) {
    yield put(Actions.onUnLinkInsightsError(error));
  }
}

function* deleteExperiment(action: any) {
  try {
    yield put({ type: AppTypes.App.MODAL_SAVING });
    // @ts-ignore
    const res = yield call(API.Experiments.destroy, action.payload.id);
    yield put(AccountActions.getAccountSummaryRequest());
    yield put(Actions.deleteExperimentSuccess(res.body));
    yield put({ type: AppTypes.App.MODAL_HIDE });
    history.push("/tests");
    yield put({
      type: AppTypes.App.ALERT_SHOW,
      content: "Test deleted successfully",
    });
  } catch (error: any) {
    yield put(Actions.deleteExperimentError(error));
  }
}

function* createReportNextStep(action: any) {
  try {
    const { experiment, reportType, step } = action.payload;

    if (reportType === "analytics") {
      yield put({ type: AppTypes.App.MODAL_SAVING });

      const rsid = experiment.adobe.analytics_report_suite;
      const experimentId = experiment.id;

      // @ts-ignore
      const res = yield call(API.AdobeExperiments.metrics, experimentId, rsid);
      yield put({
        type: AppTypes.App.MODAL_OPTIONS,
        options: {
          reportType: reportType,
          step: step,
          metrics: res.body.metrics,
        },
      });
    } else {
      yield put({
        type: AppTypes.App.MODAL_OPTIONS,
        options: { reportType: reportType, step: step },
      });
    }
  } catch (error: any) {}
}

function* preUploadReport(action: any) {
  try {
    const { experimentId, file, callback } = action.payload;

    // @ts-ignore
    const res = yield call(API.Reports.preUpload, experimentId, file);

    if (res.body && res.body.length !== 0) {
      yield put({ type: AppTypes.App.MODAL_HIDE });
    }
    yield put(Actions.preUploadReportSuccess());

    if (callback) {
      callback(res.body);
    }
  } catch (error: any) {
    if (error.body.errors.message) {
      yield put({
        type: AppTypes.App.ALERT_SHOW,
        content: error.body.errors.message,
        error: true,
      });
    } else {
      yield put({
        type: AppTypes.App.ALERT_SHOW,
        content: "Something went wrong, try again later",
        error: true,
      });
    }
    yield put(Actions.preUploadReportError(error));
  }
}

function* uploadReport(action: any) {
  try {
    const { experiment, file, name, changedMetrics } = action.payload;

    yield call(API.Reports.upload, experiment.id, file, name, changedMetrics);
    yield call(API.Experiments.show, experiment.id);
    yield put(Actions.uploadReportSuccess());
    yield put({
      type: AppTypes.App.ALERT_SHOW,
      content: "Report created successfully",
    });

    yield put({ type: AppTypes.App.MODAL_HIDE });
  } catch (error: any) {
    if ((error as any).body?.errors?.params) {
      const event = new CustomEvent("reportUploadError", {
        detail: (error as any).body?.errors?.params,
      });
      document.dispatchEvent(event);
      yield put(Actions.uploadReportError(error as any));

      return;
    }
    yield put({
      type: AppTypes.App.ALERT_SHOW,
      content:
        (error as any).body?.errors?.params ||
        "Something went wrong, try again later",
      error: true,
    });
    yield put(Actions.uploadReportError(error as any));
  }
}

function* addUpload(action: any) {
  try {
    const { experimentId, file } = action.payload;
    yield put({ type: AppTypes.App.MODAL_SAVING });

    // @ts-ignore
    const res = yield call(API.Experiments.addUpload, experimentId, file);

    yield put(Actions.addUploadSuccess(res.body));
    yield put(Actions.updateExperimentSuccess(res.body));

    yield put({
      type: AppTypes.App.ALERT_SHOW,
      content: "Attatchement attached successfully",
    });

    yield put({
      type: AppTypes.App.MODAL_OPTIONS,
      options: { experiment: res.body },
    });
    yield put({ type: AppTypes.App.MODAL_SAVE_COMPLETE });
  } catch (error: any) {
    yield put({
      type: AppTypes.App.ALERT_SHOW,
      content: "Something went wrong, try again later",
      error: true,
    });
    yield put(Actions.addUploadError(error));
  }
}

function* deleteUpload(action: any) {
  try {
    const { experimentId, uploadId } = action.payload;

    // @ts-ignore
    const res = yield call(
      API.Experiments.deleteUpload,
      experimentId,
      uploadId
    );

    yield put(Actions.deleteUploadSuccess(res.body));
    yield put(Actions.updateExperimentSuccess(res.body));
    yield put({
      type: AppTypes.App.ALERT_SHOW,
      content: "Attatchement created successfully",
    });
    yield put({
      type: AppTypes.App.MODAL_OPTIONS,
      options: { experiment: res.body },
    });

    yield put({ type: AppTypes.App.MODAL_HIDE });
  } catch (error: any) {
    yield put({
      type: AppTypes.App.ALERT_SHOW,
      content: "Something went wrong, try again later",
      error: true,
    });
    yield put(Actions.deleteUploadError(error));
  }
}

function* reorderExperiment(action: any) {
  try {
    const { id, position, currentOrder } = action.payload;

    // @ts-ignore
    const res = yield call(API.Experiments.reorder, id, position + 1);
    yield put(Actions.reorderExperimentSuccess(res.body, currentOrder));
  } catch (error: any) {
    yield put(Actions.reorderExperimentError(error));
  }
}

function* uploadInsights(action: any) {
  try {
    const { experimentId, file } = action.payload;

    // @ts-ignore
    const res = yield call(API.Experiments.uploadInsights, experimentId, file);

    yield put(Actions.uploadInsightsSuccess(res));
    yield put(Actions.updateExperimentSuccess(res.body));
    yield put({ type: AppTypes.App.MODAL_HIDE });
  } catch (error: any) {
    yield put({
      type: AppTypes.App.ALERT_SHOW,
      content: "Something went wrong, review your csv and try again.",
      error: true,
    });
    yield put(Actions.uploadInsightsError(error));
  }
}

function* addProgramGoal(action: any) {
  try {
    const { experimentId, program_goal } = action.payload;

    // @ts-ignore
    const res = yield call(
      API.Experiments.addProgramGoal,
      experimentId,
      program_goal
    );

    yield put(Actions.addProgramGoalSuccess(res));
    yield put(Actions.updateExperimentSuccess(res.body));
  } catch (error: any) {
    yield put({
      type: AppTypes.App.ALERT_SHOW,
      content: "Something went wrong, please try again latter",
      error: true,
    });
    yield put(Actions.addProgramGoalError(error));
  }
}

function* deleteProgramGoal(action: any) {
  try {
    const { experimentId, program_goal_id } = action.payload;

    // @ts-ignore
    const res = yield call(
      API.Experiments.deleteProgramGoal,
      experimentId,
      program_goal_id
    );

    yield put(Actions.deleteProgramGoalSuccess(res));
    yield put(Actions.updateExperimentSuccess(res.body));
    yield put({ type: AppTypes.App.MODAL_HIDE });
    yield put({
      type: AppTypes.App.ALERT_SHOW,
      content: "Program goal removed successfully",
    });
  } catch (error: any) {
    yield put({
      type: AppTypes.App.ALERT_SHOW,
      content: "Something went wrong, please try again latter",
      error: true,
    });
    yield put(Actions.deleteProgramGoalError(error));
  }
}

function* updateProgramGoal(action: any) {
  try {
    const { experimentId, program_goal_id, body } = action.payload;

    // @ts-ignore
    const res = yield call(
      API.Experiments.updateProgramGoal,
      experimentId,
      program_goal_id,
      body
    );

    yield put(Actions.updateProgramGoalSuccess(res));
    yield put(Actions.updateExperimentSuccess(res.body));
    yield put({
      type: AppTypes.App.ALERT_SHOW,
      content: "Program goal updated successfully",
    });
  } catch (error: any) {
    yield put({
      type: AppTypes.App.ALERT_SHOW,
      content: "Something went wrong, please try again latter",
      error: true,
    });
    yield put(Actions.updateProgramGoalError(error));
  }
}

function* watchUpdateAdobeExperiment() {
  yield takeEvery(
    ActionTypes.UPDATE_ADOBE_EXPERIMENT_REQUEST,
    updateAdobeExperiment
  );
}

function* watchCreateExperimentAdobe() {
  yield takeEvery(
    ActionTypes.CREATE_EXPERIMENT_ADOBE_REQUEST,
    createExperimentAdobe
  );
}

function* watchGetExperiments() {
  yield takeEvery(ActionTypes.GET_EXPERIMENTS_REQUEST, getExperiments);
}

function* watchGetExperiment() {
  yield takeEvery(ActionTypes.GET_EXPERIMENT_REQUEST, getExperiment);
}

function* watchInitExperimentCreate() {
  yield takeEvery(ActionTypes.INIT_EXP_CREATE_REQUEST, initExpCreate);
}

function* watchCreateExperiment() {
  yield takeEvery(ActionTypes.CREATE_EXPERIMENT_REQUEST, createExperiment);
}

function* watchUpdateExperiment() {
  yield takeEvery(ActionTypes.UPDATE_EXPERIMENT_REQUEST, updateExperiment);
}

function* watchUpdateABExperiment() {
  yield takeEvery(ActionTypes.UPDATE_AB_EXPERIMENT_REQUEST, updateABExperiment);
}

function* watchCreateReport() {
  yield takeEvery(ActionTypes.CREATE_REPORT_REQUEST, createReport);
}

function* watchDeleteReport() {
  yield takeEvery(ActionTypes.DELETE_REPORT_REQUEST, deleteReport);
}

function* watchUpdateReport() {
  yield takeEvery(ActionTypes.UPDATE_REPORT_REQUEST, updateReport);
}

function* watchDeleteExperiment() {
  yield takeEvery(ActionTypes.DELETE_EXPERIMENT_REQUEST, deleteExperiment);
}

function* watchDeleteExperience() {
  yield takeEvery(ActionTypes.DELETE_EXPERIENCE_REQUEST, deleteExperience);
}

function* watchLinkInsights() {
  yield takeEvery(ActionTypes.LINK_INSIGHTS_REQUEST, linkInsights);
}

function* watchUnLinkInsights() {
  yield takeEvery(ActionTypes.UNLINK_INSIGHTS_REQUEST, unlinkInsights);
}

function* watchGetCaseStudy() {
  yield takeEvery(ActionTypes.GET_CASE_STUDY_REQUEST, getCaseStudy);
}

function* watchUpdateCaseStudy() {
  yield takeEvery(ActionTypes.UPDATE_CASE_STUDY_REQUEST, updateCaseStudy);
}

function* watchGetShareCaseStudy() {
  yield takeEvery(ActionTypes.GET_SHARE_CASE_STUDY_REQUEST, getShareCaseStudy);
}

function* watchCreateReportNextStep() {
  yield takeEvery(
    ActionTypes.CREATE_REPORT_NEXT_STEP_REQUEST,
    createReportNextStep
  );
}

function* watchUpdateReportStats() {
  yield takeEvery(ActionTypes.UPDATE_REPORT_STATS_REQUEST, updateReportStats);
}

function* watchPreUploadRequest() {
  yield takeEvery(ActionTypes.PRE_UPLOAD_REPORT_REQUEST, preUploadReport);
}

function* watchUploadReportRequest() {
  yield takeEvery(ActionTypes.UPLOAD_REPORT_REQUEST, uploadReport);
}

function* watchCreateExperimentABRequest() {
  yield takeEvery(ActionTypes.CREATE_EXPERIMENT_AB_REQUEST, createExperimentAB);
}

function* watchAddUploadRequest() {
  yield takeEvery(ActionTypes.ADD_UPLOAD_REQUEST, addUpload);
}

function* watchDeleteUploadRequest() {
  yield takeEvery(ActionTypes.DELETE_UPLOAD_REQUEST, deleteUpload);
}

function* watchReorderExperiment() {
  yield takeEvery(ActionTypes.REORDER_REQUEST, reorderExperiment);
}

function* watchUploadInsights() {
  yield takeEvery(ActionTypes.UPLOAD_INSIGHTS_REQUEST, uploadInsights);
}

function* watchAddProgramGoal() {
  yield takeEvery(ActionTypes.ADD_PROGRAM_GOAL_REQUEST, addProgramGoal);
}

function* watchDeleteProgramGoal() {
  yield takeEvery(ActionTypes.DELETE_PROGRAM_GOAL_REQUEST, deleteProgramGoal);
}

function* watchUpdateProgramGoal() {
  yield takeEvery(ActionTypes.UPDATE_PROGRAM_GOAL_REQUEST, updateProgramGoal);
}

function* experimentSaga() {
  yield all([
    fork(watchCreateExperimentAdobe),
    fork(watchGetExperiments),
    fork(watchGetExperiment),
    fork(watchInitExperimentCreate),
    fork(watchCreateExperiment),
    fork(watchUpdateExperiment),
    fork(watchUpdateABExperiment),
    fork(watchDeleteExperiment),
    fork(watchCreateReport),
    fork(watchUpdateReport),
    fork(watchDeleteExperience),
    fork(watchLinkInsights),
    fork(watchUnLinkInsights),
    fork(watchGetCaseStudy),
    fork(watchUpdateCaseStudy),
    fork(watchGetShareCaseStudy),
    fork(watchCreateReportNextStep),
    fork(watchDeleteReport),
    fork(watchUpdateReportStats),
    fork(watchPreUploadRequest),
    fork(watchUploadReportRequest),
    fork(watchCreateExperimentABRequest),
    fork(watchAddUploadRequest),
    fork(watchDeleteUploadRequest),
    fork(watchUpdateAdobeExperiment),
    fork(watchReorderExperiment),
    fork(watchUploadInsights),
    fork(watchAddProgramGoal),
    fork(watchDeleteProgramGoal),
    fork(watchUpdateProgramGoal),
  ]);
}

export default experimentSaga;
