import { ApiClient } from "./client/api_client";

class CustomDataViz {
  static async create(experimentId: string, body: any) {
    return ApiClient.post(
      `/v1/experiment/${experimentId}/report/custom_data_viz`,
      body
    );
  }

  static async update(
    experimentId: string,
    customDataVizId: string,
    body: any
  ) {
    return ApiClient.put(
      `/v1/experiment/${experimentId}/report/custom_data_viz/${customDataVizId}`,
      body
    );
  }

  static async destroy(experimentId: string, customDataVizId: string) {
    return ApiClient.delete(
      `/v1/experiment/${experimentId}/report/custom_data_viz/${customDataVizId}`
    );
  }

  static async updateMetric(
    experimentId: string,
    metricAggregationId: string,
    body: any
  ) {
    return ApiClient.put(
      `/v1/experiment/${experimentId}/report/custom_data_viz/metric/${metricAggregationId}`,
      body
    );
  }
}

export { CustomDataViz };
