import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as DataTypes from 'store/types';
import { clearUser, forgotPwdRequest } from 'store/user/actions';
import { HTTPCodes } from 'config/app';
import { createLoadingSelector, createErrorMessageSelector } from 'store/selectors';
import { Form, Input, Button, ValidatorTypes } from 'comps/form';
import { PublicPage, PublicPageProps } from 'comps/pages';
import { Link } from 'react-router-dom';
import { AsideImage } from 'ui';

interface IProps extends PublicPageProps {
  clearUser: () => void;
  forgotPwdRequest: (slug: string, email: string) => void;
  formLoading: boolean;
  formError: any;
  account: DataTypes.Account | null;
  user: DataTypes.User | null;
}

interface IState {
  passwordEmailSent: boolean;
}

class ForgotPwd extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      passwordEmailSent: false,
    };
  }

  componentDidMount = () => {
    this.props.clearUser();
  };

  private onSubmit = (valid: boolean, dirty: boolean, data: any) => {
    const { account } = this.props;
    if (valid && account) {
      this.props.forgotPwdRequest(account.slug, data.email.value);
    }
  };

  render() {
    const { user, formLoading, formError } = this.props;
    if (formError && formError.statusCode !== HTTPCodes.NOT_FOUND) {
      throw formError;
    }

    if (user && user.forgotPassword === true) {
      return (
        <React.Fragment>
          <div className="content">
            <div className="logo">
              <img src="/img/logo.png" alt="illuminate" />
            </div>
            <div className="form">
              <h1>Check your inbox!</h1>
              <p className="mb-4">We just sent you an email that includes a link to reset your password.</p>
              <Link to="/login">Back to login</Link>
            </div>
          </div>
          <div className="aside"></div>
        </React.Fragment>
      );
    }

    return (
      <React.Fragment>
        <div className="content">
          <div className="logo">
            <img src="/img/logo.png" alt="illuminate" />
          </div>
          <div className="form">
            <h1>Forgot your password?</h1>
            <p className="mb-4">We’ve got you covered! Enter the email address you used to sign in. We’ll email you instructions to reset your password.</p>
            {formError && formError.statusCode === HTTPCodes.NOT_FOUND && (
              <div className="error box">Oops, we are unable to find a user account for this email address.</div>
            )}
            <Form
              formFields={{
                email: {
                  name: 'email',
                  value: '',
                  validator: { type: ValidatorTypes.EMAIL, messages: { type: 'Whoops! Please enter a valid email address.' } },
                },
              }}
              onSubmit={this.onSubmit}
              FormComponent={({ fields: { email }, onChange }) => (
                <div>
                  <div className="form-group">
                    <label>email address</label>
                    <Input field={email} className="form-control" onChange={(e) => onChange(email, e.target.value)} />
                  </div>
                  <Button className="btn btn-primary" text="reset password" isLoading={formLoading} />
                </div>
              )}
            />
            <Link to="/login">Back to login</Link>
          </div>
        </div>
        <div className="aside">
          <AsideImage />
        </div>
      </React.Fragment>
    );
  }
}

const formLoader = createLoadingSelector(['@@user/FORGOT_PWD']);
const loginError = createErrorMessageSelector(['@@user/FORGOT_PWD']);

const mapStateToProps = ({ app, account, user }: DataTypes.ApplicationState) => ({
  formLoading: formLoader(app.requests),
  formError: loginError(app.errors),
  account: account,
  user: user,
});

const mapDispatchToProps = {
  clearUser,
  forgotPwdRequest,
};

const connectedPage = connect(mapStateToProps, mapDispatchToProps)(PublicPage(ForgotPwd));

export { connectedPage as ForgotPwd };
