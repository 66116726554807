import { all, fork, call, put, takeLatest } from "redux-saga/effects";
import * as ActionTypes from "./types";
import * as Actions from "./actions";
import * as AccountActions from "../account/actions";
import * as UserActions from "../user/actions";
import * as TaskActions from "../task/actions";
import * as API from "../../api";

function* handleInitApp(action: any) {
  try {
    const { slug, user } = action.payload;
    if (user) {
      const { account, summary, u } = yield all({
        account: call(API.Account.get, slug),
        summary: call(API.Account.summary),
        u: call(API.User.show, user.id),
      });
      yield all([
        put(AccountActions.getAccountSuccess(account.body)),
        put(AccountActions.getAccountSummarySuccess(summary.body)),
        put(UserActions.getUserSuccess(u.body)),
        put(Actions.initAppSuccess()),
      ]);
      yield put(TaskActions.getTasksRequest());
    } else {
      const { account } = yield all({ account: call(API.Account.get, slug) });
      yield all([
        put(AccountActions.getAccountSuccess(account.body)),
        put(Actions.initAppSuccess()),
      ]);
    }
  } catch (error: any) {
    yield put(Actions.initAppError(error));
  }
}

function* watchInitAppRequest() {
  yield takeLatest(ActionTypes.App.INIT_APP_REQUEST, handleInitApp);
}

function* appSaga() {
  yield all([fork(watchInitAppRequest)]);
}

export default appSaga;
