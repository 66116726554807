import React, { Component, useCallback } from "react";
import * as DataTypes from "store/types";
import { TextInput, DropdownControl, PainsGainsSelectInput, ToolTip } from "ui";
import { useDropzone } from "react-dropzone";
import { ExperimentTypes } from "config/app";
import { AdobePlaceholder } from "ui/adobe_placeholder";
import {
  updateExperienceRequest,
  destroyExperienceRequest,
  addImageRequest,
  deleteImageRequest,
} from "store/experience/actions";
import { connect } from "react-redux";
import { EditorInput } from "ui/editor_input/editor_input";

interface IProps {
  useBBMethods: boolean;
  experience: DataTypes.Experience;
  experimentType: string;
  currentUser: any;
  experiment: DataTypes.Experiment | null;
  onUpdate(experience: DataTypes.Experience): void;
  onDelete?(experience: DataTypes.Experience): void;
  onViewImage(experience: DataTypes.Experience, image: DataTypes.Image): void;
  updateExperienceRequest(
    experimentId: string,
    experienceId: string,
    body: any
  ): void;
  destroyExperienceRequest(experimentId: string, experienceId: string): void;
  addImageRequest(experimentId: string, experienceId: string, file: File): void;
  deleteImageRequest(
    experimentId: string,
    experienceId: string,
    imageId: string
  ): void;
}

interface IState {
  deleteImage: DataTypes.Image | null;
}

class Experience extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      deleteImage: null,
    };
  }

  private onChange = (key: string, value: any) => {
    const experimentId = this.props.experiment?.id || "";

    this.props.updateExperienceRequest(experimentId, this.props.experience.id, {
      [key]: value,
    });
  };

  private onDelete = () => {
    const experimentId = this.props.experiment?.id || "";

    this.props.destroyExperienceRequest(experimentId, this.props.experience.id);
  };

  private uploadImage = (files: any) => {
    if (!this.props.experiment || !files || files.length === 0) {
      return;
    }

    const { id: experimentId } = this.props.experiment;
    const { id: experienceId } = this.props.experience;

    files.forEach((file: any) => {
      this.props.addImageRequest(experimentId || "", experienceId || "", file);
    });
  };

  private viewImage = (image: DataTypes.Image) => {
    const { experience } = this.props;
    this.props.onViewImage(experience, image);
  };

  private toggleDeleteImage = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    image: DataTypes.Image | null
  ) => {
    event.stopPropagation();
    this.setState({ ...this.state, deleteImage: image });
  };

  private onDeleteImage = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    image: DataTypes.Image
  ) => {
    event.stopPropagation();

    const { experiment, experience } = this.props;

    const experimentId = experiment?.id || "";
    const experienceId = experience.id || "";

    this.props.deleteImageRequest(experimentId, experienceId, image.id || "");
    this.setState({ ...this.state, deleteImage: null });
  };

  render() {
    const { experience, useBBMethods } = this.props;
    const { deleteImage } = this.state;
    const controlExperience = this.props.experiment?.experiences.filter(
      (e) => e.type === "Experiment::Experience::Control"
    )[0];

    if (experience === undefined) return null;

    return (
      <React.Fragment>
        {experience.type === "Experiment::Experience::Control" && (
          <Control
            currentUser={this.props.currentUser}
            useBBMethods={useBBMethods}
            experience={experience}
            onChange={this.onChange}
            onImageDrop={this.uploadImage}
            onView={this.viewImage}
            experimentType={this.props.experimentType}
            onToggleDelete={this.toggleDeleteImage}
            deleteImage={deleteImage}
            onDeleteImage={this.onDeleteImage}
            experiment={this.props.experiment!}
            controlExperienceId={controlExperience?.id!}
            singleHyphotesis={controlExperience?.hypothesis}
          />
        )}
        {experience.type === "Experiment::Experience::Variation" && (
          <Variation
            currentUser={this.props.currentUser}
            experimentType={this.props.experimentType}
            useBBMethods={useBBMethods}
            experience={experience}
            onChange={this.onChange}
            onImageDrop={this.uploadImage}
            onView={this.viewImage}
            onToggleDelete={this.toggleDeleteImage}
            deleteImage={deleteImage}
            onDeleteImage={this.onDeleteImage}
            onDelete={this.onDelete}
            experiment={this.props.experiment!}
            controlExperienceId={controlExperience?.id!}
            singleHyphotesis={controlExperience?.hypothesis}
          />
        )}
      </React.Fragment>
    );
  }
}

const mapStateToProps = ({ router }: DataTypes.ApplicationState) => ({
  router,
});

const mapDispatchToProps = {
  updateExperienceRequest,
  destroyExperienceRequest,
  addImageRequest,
  deleteImageRequest,
};

const connectedPage = connect(mapStateToProps, mapDispatchToProps)(Experience);

export { connectedPage as Experience };

interface IExperienceProps {
  experience: DataTypes.Experience;
  deleteImage: DataTypes.Image | null;
  currentUser: any;
  experiment: DataTypes.Experiment;
  experimentType: string;
  onChange(key: string, value: any): void;
  onDelete?(): void;
  onView(image: DataTypes.Image): void;
  onImageDrop(files: any): void;
  onToggleDelete(
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    image: DataTypes.Image | null
  ): void;
  onDeleteImage(
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    image: DataTypes.Image
  ): void;
  useBBMethods: boolean;
  controlExperienceId: string;
  singleHyphotesis: any;
}

const Control = ({
  experience,
  deleteImage,
  onChange,
  onView,
  onImageDrop,
  onToggleDelete,
  onDeleteImage,
  currentUser,
  experiment,
  useBBMethods,
}: IExperienceProps) => {
  const onDrop = useCallback(
    (acceptedFiles) => {
      onImageDrop(acceptedFiles);
    },
    [onImageDrop]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });
  return (
    <div className="Experience">
      <div className="header">
        <div className="form-group">
          <label>Name:</label>
          <TextInput
            value={experience.name}
            onChange={(value) => onChange("name", value)}
          >
            <p style={{ display: "table-cell" }}>{experience.name}</p>
          </TextInput>
        </div>
        <div className="form-group">
          <label>Traffic:</label>
          <TextInput
            value={experience.traffic.toString()}
            dataType="percent"
            onChange={(value) => onChange("traffic", value)}
          >
            <p style={{ display: "table-cell" }}>{experience.traffic}%</p>
          </TextInput>
        </div>
      </div>
      <div className="form-group">
        <label className="normal">
          What problem or opportunity was identified on the Control?
        </label>
        <EditorInput
          content={experience.problem}
          onBlur={(e: any) => onChange("problem", e)}
          area={true}
        />
      </div>
      <hr />
      {experiment.is_single_hypothesis ? (
        <React.Fragment>
          <div className="form-group">
            {useBBMethods ? (
              <React.Fragment>
                <label>
                  Whypothesis<sup>&reg;</sup> Customer Insights Tool
                </label>
                <ToolTip
                  message={
                    "If this wins, we may have learned that customers _______."
                  }
                />
              </React.Fragment>
            ) : (
              <React.Fragment>
                <label>Hypothesis</label>
                <ToolTip
                  message={"Use an if/then statement to define the idea tested"}
                />
              </React.Fragment>
            )}
            <EditorInput
              content={experience.hypothesis}
              onBlur={(e: any) => onChange("hypothesis", e)}
              area={true}
            />
          </div>
          <hr />
        </React.Fragment>
      ) : null}

      <div className="form-group">
        <label>Screenshots</label>
        <div className="upload-box">
          {experience.images.map((image: DataTypes.Image, idx: number) => {
            return (
              <div
                key={["c_image", idx].join("_")}
                className={
                  deleteImage && image.id === deleteImage.id
                    ? "item delete"
                    : "item"
                }
                onClick={() =>
                  deleteImage && image.id === deleteImage.id
                    ? null
                    : onView(image)
                }
              >
                {deleteImage && image.id === deleteImage.id ? (
                  <React.Fragment>
                    <div className="delete">
                      <h4>Delete this image?</h4>
                      <button
                        className="btn btn-danger"
                        type="button"
                        onClick={(e) => onDeleteImage(e, image)}
                      >
                        Delete
                      </button>
                      <button
                        className="btn btn-link"
                        type="button"
                        onClick={(e) => onToggleDelete(e, null)}
                      >
                        Cancel
                      </button>
                      <p>
                        <small>Caution: This cannot be undone.</small>
                      </p>
                    </div>
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    {currentUser.role === "read-only" ? null : (
                      <div className="cntrls">
                        <button
                          type="button"
                          className="btn btn-cntrl"
                          onClick={(e) => onToggleDelete(e, image)}
                        >
                          <i className="far fa-trash-alt" />
                        </button>
                      </div>
                    )}

                    <div className="item-image">
                      <img src={image.url} alt="" />
                    </div>
                  </React.Fragment>
                )}
              </div>
            );
          })}
          {currentUser.role === "read-only" ? null : (
            <div
              {...getRootProps()}
              className={
                experience.images.length === 0
                  ? isDragActive
                    ? "dropzone over no-images"
                    : "dropzone no-images"
                  : isDragActive
                  ? "dropzone over"
                  : "dropzone"
              }
            >
              <div className="item-dropbox">
                <input {...getInputProps()} />
                <i className="fas fa-cloud-upload-alt"></i>
                {isDragActive ? (
                  <p>Drop the files here ...</p>
                ) : (
                  <p>
                    Drag and drop screenshots here, or click here to select
                    files
                  </p>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

const Variation = ({
  experience,
  experimentType,
  deleteImage,
  onChange,
  onView,
  onImageDrop,
  onToggleDelete,
  onDeleteImage,
  onDelete,
  useBBMethods,
  currentUser,
  experiment,
  controlExperienceId,
  singleHyphotesis,
}: IExperienceProps) => {
  const onDrop = useCallback(
    (acceptedFiles) => {
      onImageDrop(acceptedFiles);
    },
    [onImageDrop]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  return (
    <div className="Experience">
      <div className="header">
        <div className="form-group">
          <label>Name:</label>
          <TextInput
            value={experience.name}
            onChange={(value) => onChange("name", value)}
          >
            <p style={{ display: "table-cell" }}>{experience.name}</p>
          </TextInput>
        </div>
        <div className="form-group percent">
          <label>Traffic:</label>
          <TextInput
            value={experience.traffic.toString()}
            onChange={(value) => onChange("traffic", value)}
          >
            <p style={{ display: "table-cell" }}>{experience.traffic}</p>
          </TextInput>
        </div>
        <div className="controls">
          {experimentType === ExperimentTypes.ADOBE ? (
            <AdobePlaceholder />
          ) : (
            onDelete &&
            currentUser.role !== "read-only" && (
              <DropdownControl
                controlItems={[
                  {
                    name: "Delete",
                    icon: "far fa-trash-alt",
                    onClick: () => onDelete(),
                    splitAbove: true,
                    className: "danger",
                  },
                ]}
              />
            )
          )}
        </div>
      </div>
      <div className="form-group">
        <label className="normal">
          How is this experience different from the Control?
        </label>
        <EditorInput
          content={experience.difference}
          onBlur={(e: any) => onChange("difference", e)}
          area={true}
        />
      </div>
      {experiment.is_single_hypothesis ? null : (
        <div className="form-group">
          {useBBMethods ? (
            <React.Fragment>
              <label>
                Whypothesis<sup>&reg;</sup> Customer Insights Tool
              </label>
              <ToolTip
                message={
                  "If this wins, we may have learned that customers _______."
                }
              />
            </React.Fragment>
          ) : (
            <React.Fragment>
              <label>Hypothesis</label>
              <ToolTip
                message={"Use an if/then statement to define the idea tested"}
              />
            </React.Fragment>
          )}

          <EditorInput
            content={experience.hypothesis}
            onBlur={(e: any) => onChange("hypothesis", e)}
            area={true}
          />
        </div>
      )}
      <div className="form-group">
        <label>Pains & Gains™</label>
        <PainsGainsSelectInput
          activeItems={experience.tactics}
          name="pains_gains"
          onChange={(value) => onChange("tactics", value)}
        />
      </div>
      <div className="form-group">
        <label>Outcomes & possible explanations</label>

        <EditorInput
          content={experience.outcome}
          onBlur={(e: any) => onChange("outcome", e)}
          area={true}
        />
      </div>
      <hr />
      <div className="form-group">
        <label>Screenshots</label>
        <div className="upload-box">
          {experience.images &&
            experience.images.map((image: DataTypes.Image, idx: number) => {
              return (
                <div
                  key={["v_image", idx].join("_")}
                  className={
                    deleteImage && image.id === deleteImage.id
                      ? "item delete"
                      : "item"
                  }
                  onClick={() =>
                    deleteImage && image.id === deleteImage.id
                      ? null
                      : onView(image)
                  }
                >
                  {deleteImage && image.id === deleteImage.id ? (
                    <React.Fragment>
                      <div className="delete">
                        <h4>Delete this image?</h4>
                        <button
                          className="btn btn-danger"
                          type="button"
                          onClick={(e) => onDeleteImage(e, image)}
                        >
                          Delete
                        </button>
                        <button
                          className="btn btn-link"
                          type="button"
                          onClick={(e) => onToggleDelete(e, null)}
                        >
                          Cancel
                        </button>
                        <p>
                          <small>Caution: This cannot be undone.</small>
                        </p>
                      </div>
                    </React.Fragment>
                  ) : (
                    <React.Fragment>
                      {currentUser.role === "read-only" ? null : (
                        <div className="cntrls">
                          <button
                            type="button"
                            className="btn btn-cntrl"
                            onClick={(e) => onToggleDelete(e, image)}
                          >
                            <i className="far fa-trash-alt" />
                          </button>
                        </div>
                      )}
                      <div className="item-image">
                        <img src={image.url} alt="" />
                      </div>
                    </React.Fragment>
                  )}
                </div>
              );
            })}
          {currentUser.role === "read-only" ? null : (
            <div
              {...getRootProps()}
              className={
                experience.images && experience.images.length === 0
                  ? isDragActive
                    ? "dropzone over no-images"
                    : "dropzone no-images"
                  : isDragActive
                  ? "dropzone over"
                  : "dropzone"
              }
            >
              <div className="item-dropbox">
                <input {...getInputProps()} />
                <i className="fas fa-cloud-upload-alt"></i>
                {isDragActive ? (
                  <p>Drop the files here ...</p>
                ) : (
                  <p>
                    Drag 'n' drop screen shots here, or click here to select
                    files
                  </p>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
