import { action } from "typesafe-actions";
import { Dispatch } from "redux";
import { ActionTypes } from "./types";
import * as DataTypes from "../types";

interface RequestError {
  statusCode?: number | undefined;
  message: any;
}

export const getRattingRequestSaga = () =>
  action(ActionTypes.GET_RATTING_REQUEST, {});
export const getRattingsRequest = () => (dispatch: Dispatch) =>
  dispatch(action(ActionTypes.GET_RATTING_REQUEST));
export const getRattingsSuccess = (
  response: DataTypes.ApiRequest<DataTypes.Trait>
) => action(ActionTypes.GET_RATTING_SUCCESS, { response });
export const getRattingsError = (error: RequestError) =>
  action(ActionTypes.GET_RATTING_FAILURE, error);

export const createRattingsRequest =
  (feature_name: string, liked_it: boolean) => (dispatch: Dispatch) =>
    dispatch(
      action(ActionTypes.CREATE_RATTING_REQUEST, {
        feature_name,
        liked_it,
      })
    );
export const createRattingsSuccess = (
  response: DataTypes.ApiList<DataTypes.Uxr>
) => action(ActionTypes.CREATE_RATTING_SUCCESS, { response });
export const createRattingsError = (error: RequestError) =>
  action(ActionTypes.CREATE_RATTING_FAILURE, error);
