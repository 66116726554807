import { appConfig } from '../config/app';

class App {
  public static getSubDomain = (): string => {
    const host = window.location.host;
    const parts = host.split('.');

    return parts.length > 2 ? parts[0] : '';
  };

  public static getAuthURL() {
    const loc = window.location;
    const host = loc.host.split('.');
    host.shift();
    const hostName = host.join('.');
    return loc.protocol + '//' + appConfig.AUTH_SUBDOMAIN + '.' + hostName;
  }

  public static getAppURL(slug: string) {
    const loc = window.location;
    let host = loc.host.split('.');
    host.shift();
    const domain = host.join('.');
    return loc.protocol + '//' + slug + '.' + domain;
  }

  public static setRedirectPath(path: string) {
    localStorage.setItem(appConfig.REDIRECT_PATH, path);
  }

  public static getRedirectPath() {
    const path = localStorage.getItem(appConfig.REDIRECT_PATH);
    if (path) return path;
    return '/';
  }
}

export { App };
