import { ApiClient } from "./client/api_client";

class User {
  static async login(slug: string, email: string, password: string) {
    return await ApiClient.post("/v1/auth", { slug, email, password });
  }

  static async loginGoogle(slug: string, tokenId: string) {
    return await ApiClient.post("/v1/auth/google", { slug, token_id: tokenId });
  }

  static async forgotPwd(slug: string, email: string) {
    return await ApiClient.post(`/v1/user/${slug}/forgot_password`, {
      user: { email },
    });
  }

  static async resetPwd(slug: string, token: string, password: string) {
    return await ApiClient.put(`/v1/user/${token}/forgot_password/${slug}`, {
      user: { password: password },
    });
  }

  static async getInvitedUserToken(id: string) {
    return await ApiClient.get(`/v1/user/invite/${id}`);
  }

  static async createInvitedUser(id: string, user: any) {
    return await ApiClient.put(`/v1/user/invite/${id}`, {
      invite: { tos: true, user: { ...user } },
    });
  }

  static async createInvitedSocialUser(token: string) {
    return ApiClient.put(`/v1/user/invite_social/${token}`, {});
  }

  static async update(id: string, body: any) {
    return await ApiClient.put(`/v1/user/${id}`, { user: body });
  }

  static async show(id: string) {
    return await ApiClient.get(`/v1/user/${id}`);
  }

  static async destroy(id: string) {
    return await ApiClient.delete(`/v1/user/${id}`);
  }

  static async updateImage(file: File) {
    const formData = new FormData();
    formData.append("image", file);

    return await ApiClient.postMultipart("/v1/user/image", formData);
  }

  static async updateNotification(id: string, body: any) {
    return await ApiClient.post("/v1/user/notification", body);
  }

  static async accounts() {
    return await ApiClient.get("/v1/user/account");
  }
}

export { User };
