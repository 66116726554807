export enum ActionTypes {
  GET_IDEAS_TRAITS_REQUEST = '@@idea_traits/GET_IDEAS_TRAITS_REQUEST',
  GET_IDEAS_TRAITS_SUCCESS = '@@idea_traits/GET_IDEAS_TRAITS_SUCCESS',
  GET_IDEAS_TRAITS_FAILURE = '@@idea_traits/GET_IDEAS_TRAITS_FAILURE',

  GET_SHARE_IDEAS_TRAITS_REQUEST = '@@idea_traits/GET_SHARE_IDEAS_TRAITS_REQUEST',
  GET_SHARE_IDEAS_TRAITS_SUCCESS = '@@idea_traits/GET_SHARE_IDEAS_TRAITS_SUCCESS',
  GET_SHARE_IDEAS_TRAITS_FAILURE = '@@idea_traits/GET_SHARE_IDEAS_TRAITS_FAILURE',

  CREATE_IDEAS_TRAITS_REQUEST = '@@idea_traits/CREATE_IDEAS_TRAITS_REQUEST',
  CREATE_IDEAS_TRAITS_SUCCESS = '@@idea_traits/CREATE_IDEAS_TRAITS_SUCCESS',
  CREATE_IDEAS_TRAITS_FAILURE = '@@idea_traits/CREATE_IDEAS_TRAITS_FAILURE',
}
