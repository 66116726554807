import { action } from 'typesafe-actions';
import { Dispatch } from 'redux';
import { ActionTypes } from './types';

interface RequestError {
  statusCode?: number | undefined;
  message: any;
}

export const listSlackRequest = () => (dispatch: Dispatch) => dispatch(action(ActionTypes.LIST_SLACK_REQUEST));
export const listSlackSuccess = (response: any) => action(ActionTypes.LIST_SLACK_SUCCESS, { response });
export const listSlackError = (error: RequestError) => action(ActionTypes.LIST_SLACK_FAILURE, error);
export const listSlackRequestSaga = () => action(ActionTypes.LIST_SLACK_REQUEST);

export const authSlackRequest = (code: string, kind: string) => (dispatch: Dispatch) => dispatch(action(ActionTypes.AUTH_SLACK_REQUEST, { code, kind }));
export const authSlackSuccess = (response: any) => (dispatch: Dispatch) => dispatch(action(ActionTypes.AUTH_SLACK_SUCCESS, { response }));
export const authSlackError = (error: RequestError) => action(ActionTypes.AUTH_SLACK_FAILURE, error);

export const deleteSlackRequest = (id: string) => (dispatch: Dispatch) => dispatch(action(ActionTypes.DELETE_SLACK_REQUEST, { id }));
export const deleteSlackSuccess = (response: any) => action(ActionTypes.DELETE_SLACK_SUCCESS, { response });
export const deleteSlackError = (error: RequestError) => action(ActionTypes.DELETE_SLACK_FAILURE, error);

export const saveSlackSettingsRequest = (domainName: string) => (dispatch: Dispatch) => dispatch(action(ActionTypes.SAVE_SLACK_SETTINGS_REQUEST, { domainName }));
export const saveSlackSettingsSuccess = (response: any) => action(ActionTypes.SAVE_SLACK_SETTINGS_SUCCESS, { response });
export const saveSlackSettingsError = (error: RequestError) => action(ActionTypes.SAVE_SLACK_SETTINGS_FAILURE, error);

export const getSlackSettingsRequest = () => (dispatch: Dispatch) => dispatch(action(ActionTypes.GET_SLACK_SETTINGS_REQUEST));
export const getSlackSettingsSuccess = (response: any) => action(ActionTypes.GET_SLACK_SETTINGS_SUCCESS, { response });
export const getSlackSettingsError = (error: RequestError) => action(ActionTypes.GET_SLACK_SETTINGS_FAILURE, error);
export const getSlackSettingsRequestSaga = () => action(ActionTypes.GET_SLACK_SETTINGS_REQUEST);