import React, { Component } from "react";
import queryString from "query-string";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { ApplicationState } from "store/types";
import { AppPage, AppPageProps } from "comps/pages";
import * as DataTypes from "store/types";
import { history, Helpers } from "utils";
import { createLoadingSelector } from "store/selectors";
import { getExperimentRequest } from "store/experiment/actions";
import {
  updateImageRequest,
  addImageCommentRequest,
  deleteImageCommentRequest,
} from "store/experience/actions";

import { AnnotateImage } from "comps";
import { Avatar, TextInput, RichTextInput, CommentInput } from "ui";

enum ViewType {
  FULL = "full",
  SCALED = "scaled",
}

interface IProps extends AppPageProps {
  computedMatch: any;
  account: DataTypes.Account | null;
  experiment: DataTypes.Experiment | null;
  loading: boolean;
  getExperimentRequest: (id: string) => void;
  updateImageRequest: (
    experimentId: string,
    experienceId: string,
    imageId: string,
    body: any
  ) => void;
  addImageCommentRequest: (
    experimentId: string,
    experienceId: string,
    imageId: string,
    body: any
  ) => void;
  deleteImageCommentRequest: (
    experimentId: string,
    experienceId: string,
    imageId: string,
    commentId: string
  ) => void;
}

interface IState {
  view: ViewType;
  showAside: boolean | undefined;
  annotateDisabled: boolean;
  annotation: any;
}

interface IRouteQuery {
  backTo?: string;
}

class ExperimentImages extends Component<IProps, IState> {
  navigationListener: any;

  constructor(props: IProps) {
    super(props);
    this.state = {
      view: ViewType.SCALED,
      showAside: undefined,
      annotateDisabled: true,
      annotation: {},
    };
  }

  componentDidMount = () => {
    this.props.getExperimentRequest(this.props.computedMatch.params.id);
    this.navigationListener = history.listen((location: any) => {
      const q = queryString.parse(this.props.location.search, {
        arrayFormat: "bracket",
      });
      const { backTo } = q;

      if (history.action === "POP" && this.props.experiment) {
        if (!backTo) {
          history.replace(`/tests/${this.props.experiment.id}`);
        }

        if (backTo === "case_study") {
          history.replace(`/case_study/${this.props.experiment.id}`);
        }
      }
    });
  };

  componentWillUnmount = () => {
    this.navigationListener();
  };

  private close = () => {
    history.goBack();
  };

  private showAside = (show: boolean | undefined) => {
    let toggle = false;
    if (show !== undefined) {
      toggle = !show;
    }

    let annoDisbled = this.state.annotateDisabled;
    if (toggle === false) annoDisbled = true;

    this.setState({
      ...this.state,
      showAside: toggle,
      annotateDisabled: annoDisbled,
    });
  };

  private onChange = (
    experience: DataTypes.Experience,
    image: DataTypes.Image,
    key: string,
    value: string
  ) => {
    const { experiment } = this.props;

    if (!experiment) {
      return;
    }

    const experimentId = experiment.id || "";
    const experienceId = experience.id || "";
    const imageId = image.id || "";

    this.props.updateImageRequest(experimentId, experienceId, imageId, {
      [key]: value,
    });
  };

  private onAddComment = (
    experience: DataTypes.Experience,
    image: DataTypes.Image,
    value: string
  ) => {
    const { experiment } = this.props;

    if (!value || !value.trim() || !experiment) {
      return;
    }

    const experimentId = experiment.id || "";
    const experienceId = experience.id || "";
    const imageId = image.id || "";

    this.props.addImageCommentRequest(experimentId, experienceId, imageId, {
      value,
      annotation: this.state.annotation,
    });

    this.setState({ ...this.state, annotateDisabled: true, annotation: {} });
  };

  private onRemoveComment = (comment: DataTypes.Comment) => {
    const {
      id: experimentId,
      experience_id: experienceId,
      image_id: imageId,
    } = this.props.computedMatch.params;

    this.props.deleteImageCommentRequest(
      experimentId || "",
      experienceId || "",
      imageId || "",
      comment.id || ""
    );
  };

  private annotateImage = (annotation: any) => {
    if (annotation !== this.state.annotation) {
      this.setState({ ...this.state, annotation: annotation });
    }
    return;
  };

  private toggleImageAnnotation = (enabled: boolean) => {
    this.setState({ ...this.state, annotateDisabled: !enabled });
  };

  render() {
    const { loading, experiment } = this.props;
    const { showAside, annotation, annotateDisabled } = this.state;

    const q = queryString.parse(this.props.location.search, {
      arrayFormat: "bracket",
    });

    if (loading) return null;

    const experience =
      experiment &&
      experiment.experiences.find(
        (ex) => ex.id === this.props.computedMatch.params.experience_id
      );
    const image =
      experience &&
      experience.images.find(
        (image) => image.id === this.props.computedMatch.params.image_id
      );

    if (!experiment || !experience || !image)
      return (
        <div>
          <h1>No Image</h1>
        </div>
      );

    return (
      <React.Fragment>
        <div className="FullScreen">
          <div className="logo">
            <img src="/img/logo_sm.svg" alt="illuminate" />
          </div>

          <ul className="controls">
            <li>
              <button
                type="button"
                className="btn btn-ctrl"
                onClick={() => this.showAside(showAside)}
              >
                <i
                  className={
                    showAside !== false
                      ? "fas fa-chevron-right"
                      : "fas fa-chevron-left"
                  }
                ></i>
              </button>
            </li>

            <li>
              <button
                type="button"
                className="btn btn-ctrl"
                onClick={this.close}
              >
                <i className="fas fa-times" />
              </button>
            </li>
          </ul>

          <div
            className={
              showAside === false ? "ImageViewer full-size" : "ImageViewer "
            }
          >
            {showAside === false && (
              <div className="title">
                <label>{experience.name}</label>
                <h2>{image.name}</h2>
              </div>
            )}

            <AnnotateImage
              key={image.id}
              image={image}
              annotation={annotation}
              disabled={annotateDisabled}
              onDraw={this.annotateImage}
            />

            <div
              className={
                showAside === undefined
                  ? "Aside"
                  : showAside
                  ? "Aside opened"
                  : "Aside closed"
              }
            >
              <div className="title-bar">
                <label>{experience.name}</label>

                <TextInput
                  value={image.name || ""}
                  placeholder="Add a name here"
                  onChange={(value) =>
                    this.onChange(experience, image, "name", value)
                  }
                >
                  <h2>{image.name}</h2>
                </TextInput>

                <RichTextInput
                  value={image.description || ""}
                  placeholder="Add description here"
                  onChange={(value) =>
                    this.onChange(experience, image, "description", value)
                  }
                />
              </div>

              <hr />

              <div className="comments">
                <Comments
                  comments={image.comments}
                  onDelete={this.onRemoveComment}
                />
                <div className="comment-input-wrapper">
                  <CommentInput
                    value={""}
                    annotateOn={!annotateDisabled}
                    onAnnotateToggle={() =>
                      this.toggleImageAnnotation(annotateDisabled)
                    }
                    onSubmit={(value) =>
                      this.onAddComment(experience, image, value)
                    }
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        {experience.images.length > 1 && (
          <div className="ImageCarousel">
            {experience.images.map((img) => {
              return (
                <div
                  key={["img_", img.id].join("_")}
                  className={img.id === image.id ? "thmb active" : "thmb"}
                >
                  <Link
                    to={`/tests/${experiment.id}/experiences/${
                      experience.id
                    }/images/${img.id}?backTo=${q.backTo || null}`}
                  >
                    <img src={img.url} alt={img.name} />
                  </Link>
                </div>
              );
            })}
          </div>
        )}
      </React.Fragment>
    );
  }
}

const loadingSelector = createLoadingSelector(["@@experiment/GET_EXPERIMENT"]);

const mapStateToProps = ({
  router,
  app,
  account,
  experiment,
}: ApplicationState) => ({
  router: router,
  account: account,
  loading: loadingSelector(app.requests),
  experiment: experiment,
});

const mapDispatchToProps = {
  getExperimentRequest,
  updateImageRequest,
  addImageCommentRequest,
  deleteImageCommentRequest,
};

const connectedPage = connect(
  mapStateToProps,
  mapDispatchToProps
)(AppPage(ExperimentImages));

export { connectedPage as ExperimentImages };

interface ICommentsProps {
  comments?: Array<DataTypes.Comment>;
  onDelete(comment: DataTypes.Comment): void;
}
const Comments = ({ comments, onDelete }: ICommentsProps) => {
  let annoCounter = 0;
  const elem =
    comments &&
    comments.map((comment, idx) => {
      if (comment.annotations && comment.annotations.length > 0) {
        annoCounter = annoCounter + 1;
      }

      return (
        <li key={`comment_${comment.id}_${idx}`}>
          <button
            title="delete comment"
            type="button"
            className="btn btn-control"
          >
            <i
              className="far fa-trash-alt"
              onClick={() => onDelete(comment)}
            ></i>
          </button>
          {comment.annotations && comment.annotations.length > 0 && (
            <div className="tag">{annoCounter}</div>
          )}
          <Avatar user={comment.user} />
          {comment.user && (
            <p className="name">
              {comment.user.first_name} {comment.user.last_name}
            </p>
          )}
          <p className="value">{comment.value}</p>
          {comment.updated_at && (
            <p className="date">{Helpers.formatDate(comment.updated_at)}</p>
          )}
        </li>
      );
    });

  return <ul>{elem}</ul>;
};
