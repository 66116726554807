import styled from "styled-components";

export const RotatingImages = styled.aside`
  position: relative;
  overflow: hidden;

  img {
    position: absolute;
    height: 100vh;
    opacity: 1;
    right: 0;

    transition: opacity 1s;

    &.hidden {
      opacity: 0;
    }
  }
`;

export const Container = styled.section`
  width: 100%;

  display: grid;
  grid-template-columns: 2fr 3fr;

  div {
    .header {
      padding: 2rem 4rem;

      h1,
      h2,
      h3,
      h4,
      h5,
      h6,
      p {
        margin: 0;
        font-family: "Roboto", sans-serif;
      }

      img {
        margin-bottom: 2rem;
      }

      h1 {
        font-weight: 300;
        font-size: 2.5rem;
        margin: 0;
      }

      p {
        margin-top: 1rem;
        margin-bottom: 1rem;
        color: #757e8e;
        font-size: 0.875rem;
      }
    }

    ul {
      margin: 0;

      display: flex;
      flex-direction: column;
      gap: 1rem;

      padding: 2rem 4rem;

      max-height: 50vh;
      overflow: scroll;

      li {
        cursor: pointer;
        display: flex;
        border: 2px solid #eaeaea;
        justify-content: space-between;
        border-radius: 0.4rem;

        align-items: center;
        padding: 0 1rem;

        min-height: 4rem;

        color: #2f4760;

        &:hover {
          background-color: #eaeaea;
        }

        p {
          font-size: 0.875rem;
          margin: 0;

          &.slug {
            font-size: 0.75rem;
          }
        }
      }
    }
  }
`;

export const LoaderContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;
