import { action } from 'typesafe-actions';
import { Dispatch } from 'redux';
import { ActionTypes } from './types';
import * as DataTypes from '../types';

interface RequestError {
  statusCode?: number | undefined;
  message: any;
}

export const getExperimentsTraitRequestSaga = () => action(ActionTypes.GET_EXPERIMENTS_TRAITS_REQUEST, {});
export const getExperimentsTraitsRequest = () => (dispatch: Dispatch) =>
  dispatch(action(ActionTypes.GET_EXPERIMENTS_TRAITS_REQUEST));
export const getExperimentsTraitsSuccess = (response: DataTypes.ApiRequest<DataTypes.Trait>) =>
  action(ActionTypes.GET_EXPERIMENTS_TRAITS_SUCCESS, { response });
export const getExperimentsTraitsError = (error: RequestError) => action(ActionTypes.GET_EXPERIMENTS_TRAITS_FAILURE, error);

export const createExperimentsTraitsRequest = (insightId: string, traitName: string, values: string[]) => (dispatch: Dispatch) =>
  dispatch(action(ActionTypes.CREATE_EXPERIMENTS_TRAITS_REQUEST, { insightId, traitName, values }));
export const createExperimentsTraitsSuccess = (response: DataTypes.ApiList<DataTypes.Insight>) =>
  action(ActionTypes.CREATE_EXPERIMENTS_TRAITS_SUCCESS, { response });
export const createExperimentsTraitsError = (error: RequestError) =>
  action(ActionTypes.CREATE_EXPERIMENTS_TRAITS_FAILURE, error);