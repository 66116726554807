import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import * as DataTypes from 'store/types';
import { clearUser, resetPwdRequest } from 'store/user/actions';
import { HTTPCodes } from 'config/app';
import { createLoadingSelector, createErrorMessageSelector } from 'store/selectors';
import { Form, Input, Button, ValidatorTypes, InputTypes } from 'comps/form';
import { PublicPage, PublicPageProps } from 'comps/pages';
import { AsideImage } from 'ui';

interface IProps extends PublicPageProps {
  computedMatch: any;
  clearUser: () => void;
  resetPwdRequest: (slug: string, token: string, password: string) => void;
  formLoading: boolean;
  formError: any;
  account: DataTypes.Account | null;
  user: DataTypes.User | null;
}

interface IState {
  passwordEmailSent: boolean;
  passwordMatch: boolean;
}

class ResetPwd extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      passwordEmailSent: false,
      passwordMatch: true,
    };
  }

  componentDidMount = () => {
    this.props.clearUser();
  };

  private onSubmit = (valid: boolean, dirty: boolean, data: any) => {
    const { account } = this.props;
    const token = this.props.computedMatch.params.token;
    if (valid && account) {
      if (data.password.value.toString() === data.confirm.value.toString()) {
        this.props.resetPwdRequest(account.slug, token, data.password.value);
      } else {
        this.setState({ ...this.state, passwordMatch: false });
      }
    }
  };

  render() {
    const { user, formLoading, formError } = this.props;
    if (formError && formError.statusCode !== HTTPCodes.NOT_FOUND && formError.statusCode !== HTTPCodes.UNPROCESSABLE_ENTITY) {
      throw formError;
    }

    if (user && user.passwordReset === true) {
      return (
        <React.Fragment>
          <div className="content">
            <div className="logo">
              <img src="/img/logo.png" alt="illuminate" />
            </div>
            <div className="form">
              <h2>Your password has been successfully reset</h2>
              <p className="mb-4">Click the button below to login to your account.</p>
              <Link to="/" className="btn btn-primary">
                Login
              </Link>
            </div>
          </div>
          <div className="aside"></div>
        </React.Fragment>
      );
    }

    return (
      <React.Fragment>
        <div className="content">
          <div className="logo">
            <img src="/img/logo.png" alt="illuminate" />
          </div>
          <div className="form">
            <h1>Reset your password</h1>
            <p className="mb-4">Use the form below to reset your password.</p>
            {formError && formError.statusCode === HTTPCodes.NOT_FOUND && (
              <div className="error box">Sorry, we are unable to reset your password. Please check the email link and try again. </div>
            )}
            <Form
              formFields={{
                password: {
                  name: 'password',
                  value: '',
                  validator: { type: ValidatorTypes.PASSWORD, messages: { type: 'Please enter a password with at least 8 characters' } },
                },
                confirm: {
                  name: 'confirm',
                  value: '',
                  validator: { type: ValidatorTypes.PASSWORD, messages: { type: 'Please enter a password with at least 8 characters' } },
                },
              }}
              onSubmit={this.onSubmit}
              FormComponent={({ fields: { password, confirm }, onChange }) => (
                <div>
                  <div className="form-group">
                    <label>password</label>
                    <Input field={password} type={InputTypes.PASSWORD} className="form-control" onChange={(e) => onChange(password, e.target.value)} />
                  </div>
                  <div className="form-group">
                    <label>confirm password</label>
                    <Input field={confirm} type={InputTypes.PASSWORD} className="form-control" onChange={(e) => onChange(confirm, e.target.value)} />
                    {this.state.passwordMatch === false && <div className="error">Passwords do not match</div>}
                  </div>
                  <Button className="btn btn-primary" text="reset password" isLoading={formLoading} />
                </div>
              )}
            />
            <Link to="/login">Back to login</Link>
          </div>
        </div>
        <div className="aside">
          <AsideImage />
        </div>
      </React.Fragment>
    );
  }
}

const formLoader = createLoadingSelector(['@@user/RESET_PWD']);
const loginError = createErrorMessageSelector(['@@user/RESET_PWD']);

const mapStateToProps = ({ app, account, user }: DataTypes.ApplicationState) => ({
  formLoading: formLoader(app.requests),
  formError: loginError(app.errors),
  account: account,
  user: user,
});

const mapDispatchToProps = {
  clearUser,
  resetPwdRequest,
};

const connectedPage = connect(mapStateToProps, mapDispatchToProps)(PublicPage(ResetPwd));

export { connectedPage as ResetPwd };
