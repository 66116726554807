import React, { Component } from "react";
import { connect } from "react-redux";
import { ApplicationState } from "store/types";
import * as DataTypes from "store/types";
import { updateAccountSettingsRequest } from "store/account/actions";

import "css/Setup.scss";
import { SetupStepsEnum } from "./types";

interface IProps {
  account: DataTypes.Account | null;
  updateAccountSettingsRequest: (settings: any, redirectUrl: string) => void;
}

interface IState {
  use_bb_methods: boolean;
  brands: boolean;
  teams: boolean;
  channels: boolean;
}

class CustomizeYourAccount extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      use_bb_methods: this.props.account?.settings.use_bb_methods || true,
      brands: this.props.account?.settings.brands || false,
      teams: this.props.account?.settings.teams || false,
      channels: this.props.account?.settings.channels || false,
    };
  }

  private onSubmit = () => {
    const { brands, teams, use_bb_methods, channels } = this.state;

    this.props.updateAccountSettingsRequest(
      {
        id: this.props.account?.settings.id,
        brands,
        teams,
        use_bb_methods,
        channels,
      },
      `?step=${SetupStepsEnum.INVITE_YOUR_TEAM}`
    );
  };

  private toggleAccountSettings = (key: string, checked: boolean) => {
    this.setState({ ...this.state, [key]: checked });
  };

  render() {
    const { account } = this.props;
    const { use_bb_methods, brands, teams, channels } = this.state;

    if (!account) return null;

    return (
      <React.Fragment>
        <h1>Customize your account</h1>
        <p>
          Team structures and taxonomy vary across programs. Use these account
          settings to configure illuminate to fit your program structure.
        </p>
        <div className="AccountSettings">
          <section className="blue">
            <div>
              <label className="CheckBox">
                <input
                  type="checkbox"
                  defaultChecked={use_bb_methods}
                  onChange={(e) =>
                    this.toggleAccountSettings(
                      "use_bb_methods",
                      e.target.checked
                    )
                  }
                />
                <span className="checkmark"></span>
              </label>
            </div>
            <div className="body">
              <h3>Brooks Bell Methodology</h3>
              <p>
                Enable the proven methodology that testing experts at Brooks
                Bell use, including the Insight Altitude
                <sup>&reg;</sup>, Whypothesis<sup>&reg;</sup>, and Insight
                Confidence<sup>&reg;</sup> Customer Insight Tools.
                <br />
                <br />
                When enabled, you’ll be able to tag your insights with the
                Insight Altitude taxonomy, you’ll replace a traditional
                hypothesis with our more customer-centric Whypothesis structure,
                and you’ll be able to set your Insight Confidence level so that
                you know which insights are most reliable versus ones still
                gathering supporting evidence.{" "}
              </p>
            </div>
          </section>
          <section>
            <div>
              <label className="CheckBox">
                <input
                  type="checkbox"
                  defaultChecked={brands}
                  onChange={(e) =>
                    this.toggleAccountSettings("brands", e.target.checked)
                  }
                />
                <span className="checkmark"></span>
              </label>
            </div>
            <div className="body">
              <h3>Brands</h3>
              <p>
                Enabling this feature will add a field called "Brands" to
                experiments, ideas, and insights. This will allow you to filter
                by brand, making it easier to organize and review your data if
                you are testing across multiple brands or lines of business.
              </p>
            </div>
          </section>

          <section>
            <div>
              <label className="CheckBox">
                <input
                  type="checkbox"
                  defaultChecked={teams}
                  onChange={(e) =>
                    this.toggleAccountSettings("teams", e.target.checked)
                  }
                />
                <span className="checkmark"></span>
              </label>
            </div>
            <div className="body">
              <h3>Teams</h3>
              <p>
                Enabling this feature will add a field called "Teams" to
                experiments, ideas, and insights. This will allow you to filter
                by team, making it easier to organize and review your data based
                upon the team involved.
              </p>
            </div>
          </section>

          <section>
            <div>
              <label className="CheckBox">
                <input
                  type="checkbox"
                  defaultChecked={channels}
                  onChange={(e) =>
                    this.toggleAccountSettings("channels", e.target.checked)
                  }
                />
                <span className="checkmark"></span>
              </label>
            </div>
            <div className="body">
              <h3>Channels</h3>
              <p>
                Enabling this feature will add a field called "Channels" to
                tests, ideas, and insights. This will allow you to filter by
                channel, making it easier to organize and review your data if
                you are testing across multiple channels.
              </p>
            </div>
          </section>

          <hr />
          <button
            className="btn btn-primary"
            type="button"
            onClick={this.onSubmit}
          >
            next
          </button>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = ({ account }: ApplicationState) => ({
  account: account,
});

const mapDispatchToProps = {
  updateAccountSettingsRequest,
};

const connectedPage = connect(
  mapStateToProps,
  mapDispatchToProps
)(CustomizeYourAccount);

export { connectedPage as CustomizeYourAccount };
