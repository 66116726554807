export enum ActionTypes {
  CLEAR_INSIGHTS = "@@insight/CLEAR_INSIGHTS",
  GET_INSIGHTS_REQUEST = "@@insight/GET_INSIGHTS_REQUEST",
  GET_INSIGHTS_SUCCESS = "@@insight/GET_INSIGHTS_SUCCESS",
  GET_INSIGHTS_FAILURE = "@@insight/GET_INSIGHTS_FAILURE",

  CREATE_INSIGHT_REQUEST = "@@insight/CREATE_INSIGHT_REQUEST",
  CREATE_INSIGHT_SUCCESS = "@@insight/CREATE_INSIGHT_SUCCESS",
  CREATE_INSIGHT_FAILURE = "@@insight/CREATE_INSIGHT_FAILURE",

  UPDATE_INSIGHT_REQUEST = "@@insight/UPDATE_INSIGHT_REQUEST",
  UPDATE_INSIGHT_SUCCESS = "@@insight/UPDATE_INSIGHT_SUCCESS",
  UPDATE_INSIGHT_FAILURE = "@@insight/UPDATE_INSIGHT_FAILURE",

  DELETE_INSIGHT_REQUEST = "@@insight/DELETE_INSIGHT_REQUEST",
  DELETE_INSIGHT_SUCCESS = "@@insight/DELETE_INSIGHT_SUCCESS",
  DELETE_INSIGHT_FAILURE = "@@insight/DELETE_INSIGHT_FAILURE",

  GET_INSIGHTS_TRAITS_REQUEST = "@@insight/GET_INSIGHTS_TRAITS_REQUEST",
  GET_INSIGHTS_TRAITS_SUCCESS = "@@insight/GET_INSIGHTS_TRAITS_SUCCESS",
  GET_INSIGHTS_TRAITS_FAILURE = "@@insight/GET_INSIGHTS_TRAITS_FAILURE",

  CREATE_INSIGHT_NOTE_REQUEST = "@@insight/CREATE_INSIGHT_NOTE_REQUEST",
  CREATE_INSIGHT_NOTE_SUCCESS = "@@insight/CREATE_INSIGHT_NOTE_SUCCESS",
  CREATE_INSIGHT_NOTE_FAILURE = "@@insight/CREATE_INSIGHT_NOTE_FAILURE",

  DELETE_NOTE_REQUEST = "@@insight/DELETE_NOTE_REQUEST",
  DELETE_NOTE_SUCCESS = "@@insight/DELETE_NOTE_SUCCESS",
  DELETE_NOTE_FAILURE = "@@insight/DELETE_NOTE_FAILURE",

  GET_INSIGHT_SHARE_REQUEST = "@@insight/GET_INSIGHT_SHARE_REQUEST",
  GET_INSIGHT_SHARE_SUCCESS = "@@insight/GET_INSIGHT_SHARE_SUCCESS",
  GET_INSIGHT_SHARE_FAILURE = "@@insight/GET_INSIGHT_SHARE_FAILURE",

  REORDER_REQUEST = "@@insight/REORDER_REQUEST",
  REORDER_SUCCESS = "@@insight/REORDER_SUCCESS",
  REORDER_FAILURE = "@@insight/REORDER_FAILURE",

  UPLOAD_INSIGHTS_REQUEST = "@@insight/UPLOAD_INSIGHTS_REQUEST",
  UPLOAD_INSIGHTS_SUCCESS = "@@insight/UPLOAD_INSIGHTS_SUCCESS",
  UPLOAD_INSIGHTS_FAILURE = "@@insight/UPLOAD_INSIGHTS_FAILURE",
}
