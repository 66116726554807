import React, { Component } from "react";
import TimeAgo from "timeago-react";
import { Link } from "react-router-dom";
import * as DataTypes from "store/types";
import { Avatar, SearchBar } from "ui";
import {
  DragDropContext,
  Draggable,
  Droppable,
  DropResult,
  ResponderProvided,
} from "react-beautiful-dnd";

interface IProps {
  loading: boolean;
  ideaBoards: Array<DataTypes.IdeaBoard> | null;
  selectedIdx: number;
  search: string;
  currentUser: any;
  onSelect(itemIndex: number): void;
  reorderable: boolean;
  onReorder(id: string, position: number): void;
  onSearchSubmit: any;
  onSearchChange: any;
  onExport: () => void;
}

class List extends Component<IProps> {
  onDragEnd = (result: DropResult, provided: ResponderProvided) => {
    const { draggableId: id, destination, reason } = result;

    if (reason !== "DROP" || !destination) {
      return;
    }

    const { index } = destination;

    this.props.onReorder(id, index);
  };

  getDragStyle = (isDragging: boolean, draggableStyle: any) => ({
    userSelect: "none",
    ...draggableStyle,
  });

  render() {
    const { loading, ideaBoards, selectedIdx } = this.props;

    if (loading) {
      return (
        <ul className="ListItem placeholder">
          <LoadingListItems count={8} />
        </ul>
      );
    }

    return (
      <React.Fragment>
        <div className="header">
          <h3>Idea Boards</h3>
          <p>
            Idea boards make it easy to manage ideas and collaborate with your
            team. Set up a board to manage ideas for a specific page, ideas
            designed to solve a specific problem, improve a specific metric,
            etc. You can also create an idea board worksheet to provide context
            and helpful data, share it with people to get their ideas, and
            manage them all in illuminate. It's a great way to fill your queue
            with useful, data-driven test ideas!
          </p>
        </div>
        <div className="SearchBar">
          <SearchBar
            onChange={this.props.onSearchChange}
            value={this.props.search}
            placeholder="Search for idea boards"
            onSubmit={this.props.onSearchSubmit}
            enableReset
          />

          <div style={{ display: "flex", justifyContent: "end" }}>
            {/* <button
              type="button"
              onClick={() => this.props.onExport()}
              className="btn btn-link"
              style={{
                textAlign: "end",
                fontSize: "14px",
                paddingBottom: 0,
                marginBottom: 0,
                width: "fit-content",
              }}
            >
              Export
            </button> */}
          </div>
        </div>
        <ul className="ListItem">
          <DragDropContext onDragEnd={this.onDragEnd}>
            <Droppable droppableId="1">
              {(provided, snapshot) => (
                <div className="column" ref={provided.innerRef}>
                  {provided.placeholder}

                  {ideaBoards &&
                    ideaBoards.map(
                      (ideaBoard: DataTypes.IdeaBoard, idx: number) => (
                        <Draggable
                          isDragDisabled={
                            !this.props.reorderable ||
                            this.props.currentUser.role === "read-only"
                          }
                          key={`dragabble_${ideaBoard.id}`}
                          index={idx}
                          draggableId={ideaBoard.id || ""}
                        >
                          {(provided, snapshot) => (
                            <div
                              className={
                                snapshot.isDragging ? "item dragging" : "item"
                              }
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              style={this.getDragStyle(
                                snapshot.isDragging,
                                provided.draggableProps.style
                              )}
                            >
                              <ListItem
                                key={`li_${ideaBoard.id}`}
                                ideaBoard={ideaBoard}
                                selected={idx === selectedIdx}
                                onItemSelect={() => this.props.onSelect(idx)}
                              />
                            </div>
                          )}
                        </Draggable>
                      )
                    )}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </ul>
      </React.Fragment>
    );
  }
}

export { List };

interface IListItemProps {
  ideaBoard: DataTypes.IdeaBoard;
  selected: boolean;
  onItemSelect(): void;
}

const ListItem = ({ ideaBoard, selected, onItemSelect }: IListItemProps) => {
  return (
    <li
      id={"idea_boards_management_" + ideaBoard.id}
      className={selected === true ? "active" : ""}
      onClick={onItemSelect}
    >
      <div className="left">
        <Avatar user={ideaBoard.author} showHover={true} />
      </div>
      <div className="body">
        <h4 className="title">
          <Link to={`/ideas?q[]=idea_board_id=${ideaBoard.id}`}>
            {ideaBoard.name}
          </Link>
        </h4>
        <p className="description">
          {ideaBoard.description.blocks
            .map((block: any) => block.text)
            .join(" ")}
        </p>

        <p className="desc mt-2">
          Updated <TimeAgo datetime={ideaBoard.updated_at} live={false} />
        </p>
      </div>
    </li>
  );
};

interface ILoadingListItemProps {
  count: number;
}

const LoadingListItems = ({ count }: ILoadingListItemProps): any => {
  let elem = [];

  for (let i = 0; i < count; i++) {
    elem.push(
      <li key={"li_" + i}>
        <div className="body">
          <h4 className="title">&nbsp;</h4>
          <p></p>
          <p></p>
          <p></p>
        </div>
        <div className="right">
          <div className="status"></div>
          <p></p>
          <p></p>
        </div>
      </li>
    );
  }

  return elem;
};
