import React, { Component } from 'react';
import { PublicPage, PublicPageProps } from 'comps/pages';
import { Link } from 'react-router-dom';
import { PublicNav } from 'ui/navs';

interface IProps extends PublicPageProps {}

class TermsOfService extends Component<IProps> {
  render() {
    return (
      <React.Fragment>
        <div className="Terms">
          <PublicNav />
          <div className="d-flex">
            <div className="leftNav">
              <div className="selected">
                <Link to="/terms">Terms of Service</Link>
              </div>
              <div>
                <Link to="/privacy_policy">Privacy Policy</Link>
              </div>
            </div>
            <div className="body">
              <h1>Terms of Service</h1>
              <div className="preface">
                <p className="m-0">
                  <strong>BROOKS BELL INTERACTIVE, INC.</strong>
                </p>
                <p className="m-0">
                  <strong>TERMS OF SERVICE FOR ILLUMINATE SOFTWARE</strong>
                </p>
                <p className="underline mt-4 mb-2">
                  <strong>IMPORTANT</strong>
                </p>
                <p>
                  THIS IS A LEGAL AGREEMENT BETWEEN BROOKS BELL INTERACTIVE, INC., A NORTH CAROLINA CORPORATION (“BROOKS BELL”), AND THE COMPANY IDENTIFIED BY
                  YOU ON THE ILLUMINATE ACCOUNT REGISTRATION PAGE (SUCH COMPANY, “CUSTOMER” OR “YOU”). BROOKS BELL IS WILLING TO MAKE AVAILABLE AND LICENSE THE
                  ILLUMINATE SOFTWARE (AS DEFINED BELOW) TO CUSTOMER ONLY UPON THE CONDITION THAT CUSTOMER ACCEPTS ALL OF THESE TERMS OF SERVICE. PLEASE READ
                  THESE TERMS OF SERVICE CAREFULLY BEFORE CHECKING THE BOX MARKED “I AGREE” AND CREATING AN ACCOUNT, AS CHECKING SUCH BOX AND CLICKING THE
                  “CONTINUE” BUTTON WILL INDICATE CUSTOMER’S AGREEMENT THAT THESE TERMS OF SERVICE ARE LEGALLY BINDING UPON CUSTOMER. IF CUSTOMER DOES NOT AGREE
                  TO THESE TERMS OF SERVICE, THEN DO NOT CHECK THE BOX MARKED “I AGREE” AND DO NOT CREATE AN ACCOUNT. IF CUSTOMER DOES NOT AGREE TO THESE TERMS
                  OF SERVICE, BROOKS BELL IS UNWILLING TO LICENSE THE ILLUMINATE SOFTWARE TO IT.
                </p>
              </div>
              <ol>
                <li>
                  <p>
                    Subject to the following terms and conditions, Brooks Bell grants to Customer a nonexclusive, nontransferable limited license to access and
                    use the Illuminate Software solely for Customer’s internal business purposes and subject to the limitations in these Terms of Service.
                    Customer may use the Illuminate Software solely for its noncommercial use. “Illuminate Software” means the Illuminate software as a service
                    offering made available to Customer through the internet by Brooks Bell as of the date on which Customer creates an account as described in
                    Section 2 (the “Effective Date”), including any and all Updates made available to Customer, including optional features for which a fee is
                    or may be charged. “Updates” means all upgrades, enhancements, improvements, maintenance releases, additions, and modifications of the
                    Illuminate Software made available to Customer by Brooks Bell during the time period between the Effective Date and the Termination Date (as
                    defined below) (such time period, the “Subscription Term”). For the avoidance of doubt, Brooks Bell may, in its sole discretion, change or
                    delete any part of the Illuminate Software, including without limitation the web address through which Customer may access the Illuminate
                    Software.
                  </p>
                </li>
                <li>
                  <p>
                    In order to gain access to the Illuminate Software, Customer must create an account with Brooks Bell by submitting all information requested
                    by Brooks Bell on the Illuminate website. Customer represents that all information Customer provides to Brooks Bell in such submission and
                    at any other time during or after the account creation process (the “Registration Information” and such process, the “Registration Process”)
                    will be true, accurate, complete, and current and that Customer will promptly update its Registration Information as necessary such that it
                    is, at all times, true, accurate, complete, and current. Brooks Bell may use all Registration Information, subject to confidentiality
                    limitations set forth in these Terms of Service. You hereby represent and warrant that you have the legal right to open this account on
                    behalf of Customer, and that you have the right and authority to agree to these Terms of Service on behalf of Customer.
                  </p>
                </li>
                <li>
                  <p>
                    Customer’s license set forth in Section 1 may be exercised by Customer’s employees and other service providers who are authorized by
                    Customer (each, a “User”); provided, that Customer will at all times be responsible for any breach of these Terms of Service by any User,
                    whether such action was authorized by Customer; and provided further, that any action taken by a User in connection with the Illuminate
                    Software shall be deemed to be an action taken by Customer for purposes of compliance with these Terms of Service.{' '}
                  </p>
                  <ol className="alpha">
                    <li>
                      <p>
                        Customer must safeguard, and ensure that all Users safeguard, the devices, computers, and networks used to access the Illuminate
                        Software and safeguard all Access Credentials. “Access Credentials” means login information, passwords, security protocols, and policies
                        through which Users access and use the Illuminate Software. Customer will be responsible for all acts and omissions of Users or any
                        unauthorized party that accesses the Illuminate Software using any User’s Access Credentials. Customer agrees to: (1) keep its Access
                        Credentials secure and confidential and not to allow any of Customer’s Users to provide their Access Credentials to anyone else; and (2)
                        not permit anyone other than each User from using such User’s Access Credentials. Customer will notify Brooks Bell immediately if it
                        learns of any unauthorized use of any Access Credentials or any other known or suspected breach of security. Brooks Bell reserves the
                        right, in its sole discretion and without liability to Customer or any User, to take any action Brooks Bell deems necessary or
                        reasonable to ensure the security of the Illuminate Software and Customer’s Access Credentials and account, including suspending or
                        terminating Customer’s access or the access of any of Customer’s Users, changing passwords, or requesting additional information to
                        authorize activities related to Customer’s account.
                      </p>
                    </li>
                    <li>
                      <p>
                        Customer will promptly notify Brooks Bell of any suspected or alleged violation of these Terms of Service and will cooperate with Brooks
                        Bell with respect to: (i) any investigation by Brooks Bell of any suspected or alleged violation of these Terms of Service and (ii)
                        enforcement of these Terms of Service. Brooks Bell may suspend or terminate any User’s access to the Illuminate Software upon notice to
                        Customer in the event Brooks Bell reasonably determines that such User has violated any terms of these Terms of Service.
                      </p>
                    </li>
                  </ol>
                </li>
                <li>
                  <p>
                    Use of the Illuminate Software is provided free of charge during Subscription Term; provided, however, that Brooks Bell may in its sole
                    discretion from time to time offer certain features or functions for a fee or otherwise restrict access to certain features or functions.{' '}
                  </p>
                </li>
                <li>
                  <p>
                    Customer acknowledges and agrees that Customer’s and its Users’ access and use of the Illuminate Software is dependent upon access to
                    telecommunications and Internet services. Customer will be solely responsible for acquiring and maintaining all telecommunications and
                    Internet services and other hardware and software required to access and use the Illuminate Software, including, without limitation, all
                    costs, fees, expenses, and taxes of any kind related to the foregoing. Brooks Bell will not be responsible for any loss or corruption of
                    data, lost communications, or any other loss or damage of any kind arising from any such telecommunications or Internet services or any such
                    hardware or software.
                  </p>
                </li>
                <li>
                  <p>
                    Brooks Bell may use Customer’s name, logo, and trademarks on its website and in any form of advertising, promotion, or publicity, including
                    press releases and in social media, to reflect and communicate that Customer is a customer of Brooks Bell. Customer and its Users may from
                    time to time provide feedback regarding the Illuminate Software to Brooks Bell via surveys, written responses, phone calls, live meeting, or
                    otherwise (“Feedback”). Brooks Bell will have the right to quote any Feedback Customer or any User provides in any marketing or press
                    release. Customer hereby assigns all rights in and to the Feedback, and any ideas, concepts, know-how, techniques, or inventions contained
                    therein, whether or not patentable, to Brooks Bell, and Customer acknowledges and agrees that Brooks Bell may use the Feedback and any such
                    intellectual property contained therein in any way, without obligation of additional consideration.
                  </p>
                </li>
                <li>
                  <p>
                    The Illuminate Software, the Brooks Bell Technology, the Analytics, the Documentation, and all worldwide Intellectual Property Rights in
                    each of the foregoing and in all derivative works of each of the foregoing (together, the “Software Content”) are the exclusive property of
                    Brooks Bell. Subject to these Terms of Service, no Software Content may be modified, copied, distributed, framed, reproduced, republished,
                    downloaded, displayed, posted, transmitted, or sold in any form or by any means, in whole or in part, without the prior written permission
                    of Brooks Bell. Except as otherwise set forth in these Terms of Service, any unauthorized use of the Software Content is strictly
                    prohibited. All trademarks, logos, trade dress and service marks displayed in connection with the Illuminate Software are trademarks or
                    registered trademarks of Brooks Bell and may not be copied, imitated, or used, in whole or in part, without the prior written permission of
                    Brooks Bell. Customer agrees that if Customer or any User infringes Brooks Bell’s intellectual property rights or exceed the scope of
                    permitted use of this license, Brooks Bell will be irreparably injured and may terminate these Terms of Service.
                  </p>
                  <ol className="alpha">
                    <li>
                      <p>
                        “Analytics” means any information, data, statistics, metadata, inferences, interrelationships, and/or associations generated by or
                        conducted on the Illuminate Software, including without limitation aggregated anonymized data and and as derived from aggregated
                        anonymized inputs to, and usage of, the Illuminate Software across Users and Brooks Bell customers.
                      </p>
                    </li>
                    <li>
                      <p>
                        “Brooks Bell Technology” means the computer software, computer code, scripts, neural networks, artificial intelligence, application
                        programming interfaces, methodologies, processes, templates, work flows, diagrams, tools, algorithms, formulas, user interfaces,
                        know-how, trade secrets, techniques, designs, inventions, third party services and other tangible or intangible technical material,
                        information and works of authorship underlying or otherwise used to make available the Illuminate Software, including, without
                        limitation, all upgrades, enhancements, modifications, additions and improvements thereto and all derivative works thereof, and
                        Intellectual Property Rights therein and thereto.
                      </p>
                    </li>
                    <li>
                      <p>
                        “Documentation” means text and/or graphical materials, whether in print or electronic form, that describe the features, functions and
                        use of the Illuminate Software and which are made available to you, as they exist from time to time.
                      </p>
                    </li>
                    <li>
                      <p>
                        “Intellectual Property Rights” mean any and all now known or hereafter existing (a) rights associated with works of authorship,
                        including copyrights, and moral rights; (b) trademark or service mark rights (c) trade secret rights; (d) patents, patent rights, and
                        industrial property rights; (e) layout design rights, design rights, and other proprietary rights of every kind and nature other than
                        trademarks, service marks, trade dress, and similar rights; and (f) registrations, applications, renewals, extensions, or reissues of
                        the foregoing, in each case, in any jurisdiction throughout the world.
                      </p>
                    </li>
                  </ol>
                </li>
                <li>
                  <p>
                    Neither Customer nor any User may take any of the following actions: (i) create derivative works of the Illuminate Software, (ii) access,
                    view, or modify the source code of the Illuminate Software, (iii) interfere with or disrupt the integrity or performance of the Illuminate
                    Software, the Brooks Bell Technology or the data contained therein or disrupt any servers or networks connected to the Illuminate Software,
                    (iv) disobey any requirements, procedures, policies or regulations of networks connected to the Illuminate Software; (v) attempt to gain
                    unauthorized access to the Illuminate Software or the Brooks Bell Technology or any related systems or networks; (vi) use the Illuminate
                    Software in violation of any applicable, law, rule, regulation or guideline; (vii) attempt to probe, scan, or test (including without
                    limitation stress testing or penetration testing) the vulnerability of any system or network associated with the Illuminate Software or use
                    the Illuminate Software to breach any security or authentication measures; or (viii) disclose or transfer any Personal Information to Brooks
                    Bell (or require Brooks Bell to create, receive, transmit, maintain or otherwise process such Personal Information on behalf of Customer or
                    such User). For purposes of this Agreement, “Personal Information” shall include all individually identifiable information or other
                    information that could be used to identify a unique individual or electronic device, including without limitation “personal data” as defined
                    in the General Data Protection Regulation ((EU) 2016/679).
                  </p>
                </li>
                <li>
                  <p>
                    Neither Customer nor any User may sublicense, rent, distribute, transfer, lease or otherwise assign its rights in the Illuminate Software.
                    Neither Customer nor any User may change, alter or modify the Illuminate Software, translate, port, reverse assemble, reverse compile,
                    disassemble, or in any way reverse engineer the Illuminate Software.
                  </p>
                </li>
                <li>
                  <p>
                    To use the Illuminate Software, Customer grants to Brooks Bell a non-exclusive, royalty-free, license, to access, use, and copy, the User
                    Content (as defined below) solely as necessary in connection with its use of the Illuminate Software and for Brooks Bell to create and use
                    Analytics. Brooks Bell agrees that Customer owns all right, title and interest in and to the User Content. “User Content” means information,
                    data, text, content, videos, images, audio clips, photos, graphics, and / or other types of content, information and/or data posted,
                    provided and/or uploaded to the Illuminate Software by Customer or any User. Further, Customer represents, warrants, and covenants that the
                    User Content (i) will not and does not violate the privacy, publicity, or other rights of third parties or any other law, statute, ordinance
                    or regulation; (ii) is not and will not become unlawful, tortious, fraudulent, defamatory or harmful to minors, obscene, pornographic, or
                    offensive as determined by Brooks Bell in its sole discretion; (iii) will not and does not violate its own privacy policy; (iv) will not and
                    does not disclose or provide information protected under any law, agreement or fiduciary relationship, including but not limited to,
                    proprietary or confidential information of others; (v) will not and does not contain any viruses, Trojan horses, spyware, malware, worms,
                    time bombs, cancelbots, or other disabling devices or other harmful component intended to damage, detrimentally interfere with,
                    surreptitiously intercept or expropriate any system, data or personal information; and (vi) will not and does not advocate or encourage any
                    illegal activity.
                  </p>
                </li>
                <li>
                  <p>
                    Brooks Bell may terminate or modify Customer’s or any User’s rights and license under these Terms of Service for any or no reason, including
                    without limitation, at any time after any breach of these Terms of Service by Customer or any User.{' '}
                  </p>
                </li>
                <li>
                  <p>
                    This license is not a sale. Title, ownership rights, and the Intellectual Property Rights in and to the Illuminate Software shall remain
                    with Brooks Bell. Customer agrees to abide by all copyright laws and all other applicable laws of the United States and international
                    treaties.
                  </p>
                </li>
                <li>
                  <p>
                    Although Brooks Bell has no obligation to make Updates to the Illuminate Software, Brooks Bell may from time to time make Updates available
                    to Customer. Any Updates to the Illuminate Software shall be deemed to become part of the Illuminate Software and shall be subject to these
                    Terms of Service.
                  </p>
                </li>
                <li>
                  <p>
                    Each party (the "Disclosing Party") may from time to time during the Subscription Term disclose to the other party (the "Receiving Party")
                    certain confidential information regarding the Disclosing Party's marketing, finances, employees, planning, and business, in writing or
                    visually, that is either marked or designated as confidential or is identified in writing as confidential at the time of disclosure
                    ("Confidential Information"). For the avoidance of doubt, the Illuminate Software, the Brooks Bell Technology, and the Analytics are
                    Confidential Information of Brooks Bell. The Receiving Party will not use any Confidential Information of the Disclosing Party for any
                    purpose not expressly permitted by these Terms of Service, and will disclose the Confidential Information of the Disclosing Party only to
                    service providers of the Receiving Party who have a need to know such Confidential Information for purposes of these Terms of Service and
                    who are under a duty of confidentiality no less restrictive than the Receiving Party's duty hereunder. The Receiving Party will protect the
                    Disclosing Party's Confidential Information from unauthorized use, access, or disclosure in the same manner as the Receiving Party protects
                    its own confidential or proprietary information of a similar nature and with no less than reasonable care. Notwithstanding the termination
                    or expiration of the Subscription, the obligations of the Receiving Party, with respect to the Confidential Information of Disclosing Party,
                    shall be in full force and effect during the Subscription Term and for a period of one (1) year thereafter. The Receiving Party's
                    obligations under this subsection will not apply to any portion of the Disclosing Party's Confidential Information if the Receiving Party
                    can document that such information: (a) was already lawfully known to the Receiving Party at the time of disclosure by the Disclosing Party;
                    (b) is disclosed to the Receiving Party by a third party who had the right to make such disclosure without any confidentiality restrictions;
                    (c) is, or through no fault of the Receiving Party has become, generally available to the public; or (d) was independently developed by the
                    Receiving Party without use of or reference to the Disclosing Party's Confidential Information. In addition, the Receiving Party will be
                    allowed to disclose Confidential Information of the Disclosing Party to the extent that such disclosure is (i) approved in writing by the
                    Disclosing Party, (ii) necessary for the Receiving Party to enforce its rights under these Terms of Service in connection with a legal
                    proceeding; or (iii) required by law or by the order of a court or similar judicial or administrative body, provided that the Receiving
                    Party, as permitted by applicable law, rules, and regulations, notifies the Disclosing Party of such required disclosure in writing
                    promptly, and cooperates with the Disclosing Party, at the Disclosing Party's reasonable request and expense, in any lawful action to
                    contest or limit the scope of such required disclosure.
                  </p>
                </li>
                <li>
                  <p>
                    THE ILLUMINATE SOFTWARE IS PROVIDED “AS IS” AND BROOKS BELL PROVIDES NO WARRANTIES OR REPRESENTATIONS AND EXPRESSLY DISCLAIMS AND EXCLUDES
                    ALL WARRANTIES AND CONDITIONS, INCLUDING WITHOUT LIMITATION THE IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE,
                    TITLE, AND NONINFRINGEMENT. BROOKS BELL DOES NOT WARRANT, GUARANTEE, OR MAKE ANY REPRESENTATIONS REGARDING THE USE, OR THE RESULTS OF THE
                    USE, OF THE ILLUMINATE SOFTWARE IN TERMS OF CORRECTNESS, ACCURACY, RELIABILITY, CURRENTNESS, OR OTHERWISE. THE ENTIRE RISK AS TO THE RESULTS
                    AND PERFORMANCE OF THE ILLUMINATE SOFTWARE IS ASSUMED BY YOU. NO ORAL OR WRITTEN INFORMATION OR ADVICE GIVEN BY BROOKS BELL SHALL CREATE A
                    WARRANTY OR CONDITION OR IN ANY WAY INCREASE THE SCOPE OF THIS WARRANTY, AND CUSTOMER MAY NOT RELY ON ANY SUCH INFORMATION OR ADVICE. BROOKS
                    BELL DOES NOT WARRANT THAT THE ILLUMINATE SOFTWARE WILL BE UNINTERRUPTED, TIMELY, SECURE, OR ERROR-FREE. N NO EVENT WILL BROOKS BELL BE
                    RESPONSIBLE FOR ANY LOST DATA OF FILES RELATED TO ITS USE OF THE SOFTWARE. CUSTOMER HEREBY RELEASES BROOKS BELL FROM ANY AND ALL CLAIMS THAT
                    CUSTOMER MAY HAVE RELATED TO THE USE OF THE SOFTWARE.
                  </p>
                </li>
                <li>
                  <p>
                    Customer hereby agrees that at its sole expense, Customer will indemnify, hold harmless, and defend Brooks Bell from and against any and all
                    loss, liability, expense, damage, claim or judicial action incurred by or brought against Brooks Bell that relates to or arises from (i)
                    Customer’s or any User’s use of the Illuminate Software or (ii) the User Content; provided, however, that Customer shall have no such
                    indemnification obligation with respect to any third party claims, suits, or proceedings brought against Brooks Bell by a third party
                    contending that use of the Illuminate Software in accordance with the Documentation infringes any intellectual property right of a third
                    party.
                  </p>
                </li>
                <li>
                  <p>
                    TO THE MAXIMUM EXTENT PERMITTED BY LAW, IN NO EVENT SHALL BROOKS BELL BE LIABLE FOR ANY SPECIAL, INCIDENTAL, CONSEQUENTIAL OR PUNITIVE
                    DAMAGES WHATSOEVER, INCLUDING ANY CLAIMS FOR LOST PROFITS, BUSINESS INTERRUPTION, LOST INFORMATION OR OTHER DAMAGES ARISING OUT OF THE USE
                    OR INABILITY TO USE THE ILLUMINATE SOFTWARE. IN NO EVENT SHALL BROOKS BELL HAVE ANY LIABILITY FOR ANY DATA OR FILES (INCLUDING WITHOUT
                    LIMITATION, VIDEO, SOUND, DIGITAL PHOTO, ETC.) STORED OR PROCESSED WITH THE ILLUMINATE SOFTWARE, INCLUDING THE COST OF RECOVERING ANY LOST
                    DATA. IN ALL EVENTS, BROOKS BELL’S SOLE OBLIGATION OR LIABILITY UNDER THESE TERMS OF SERVICE IN THE AGGREGATE IS THE AMOUNT CUSTOMER PAID
                    BROOKS BELL TO USE THE ILLUMINATE SOFTWARE DURING THE TWELVE-MONTH PERIOD PRECEDING THE LAST EVENT GIVING RISE SUCH OBLIGATION OR LIABILITY.
                    THE PARTIES AGREE THAT THESE LIMITATIONS ARE AN ESSENTIAL PART OF THESE TERMS OF SERVICE AND THAT BROOKS BELL WOULD NOT MAKE THE ILLUMINATE
                    SOFTWARE COMMERCIALLY AVAILABLE WITHOUT THESE LIMITATIONS. THIS LIMITATION OF LIABILITY CLAUSE WILL SURVIVE FAILURE OF ITS ESSENTIAL
                    PURPOSE.
                  </p>
                </li>
                <li>
                  <p>
                    Customer’s license, access rights, and subscription for the Illuminate Software will expire and terminate upon the earliest of the following
                    (the “Termination Date”): (i) Brooks Bell provides notice to Customer of such termination, for any or no reason, including without
                    limitation its cessation of providing the Illuminate Software on a commercial basis; or (ii) Customer provides notice to Brooks Bell of such
                    termination for any or no reason including through the Illuminate website. Any provisions of these Terms of Service that by their nature
                    should continue after the Subscription Term, and these Terms of Service will continue to apply even after the Termination Date, including
                    without limitation, Sections 4-20.
                  </p>
                </li>
                <li>
                  <p>
                    These Terms of Service shall be governed by and construed in accordance with the laws of the State of North Carolina, without regard to the
                    choice of law provisions thereof. The United Nations Convention on Contracts for the International Sale of Goods shall not apply to these
                    Terms of Service. Any dispute or claim arising out of, or in connection with, these Terms of Service shall be finally settled by binding
                    confidential arbitration in Raleigh, North Carolina, in accordance with the then-current rules and procedures of the American Arbitration
                    Association by one (1) arbitrator appointed by the American Arbitration Association. The arbitrator shall apply the law of the State of
                    North Carolina, without reference to rules of conflict of law or statutory rules of arbitration, to the merits of any dispute or claim.
                    Judgment on the award rendered by the arbitrator may be entered in any court of competent jurisdiction. The parties agree that, any
                    provision of applicable law notwithstanding, they will not request, and the arbitrator shall have no authority to award, punitive or
                    exemplary damages against any party. In the event that any arbitration, action or proceeding is brought in connection with these Terms of
                    Service, the prevailing party shall be entitled to recover its costs and reasonable attorneys' fees. In the event that the above arbitration
                    provision is held invalid or unenforceable, any dispute with respect to these Terms of Service shall be brought and heard either in the
                    North Carolina state courts located in Raleigh, North Carolina or the United States District Court for the Eastern District of North
                    Carolina, located in Raleigh, North Carolina. In such event, Customer consents to the in personam jurisdiction and venue of such courts. The
                    parties agree that service of process upon them in any such action may be made if delivered in person, by courier service, by telegram, by
                    telefacsimile or by first class mail, and shall be deemed effectively given upon receipt.
                  </p>
                </li>
                <li>
                  <p>
                    These Terms of Service and the information submitted by Customer as part of the Registration Process set forth the entire agreement between
                    Customer and Brooks Bell pertaining to the Illuminate Software and supersede in its entirety any and all written or oral agreements
                    previously existing between the parties with respect to such subject matter. If any provision of these Terms of Service is held invalid or
                    unenforceable, such provision shall be revised to the extent necessary to cure the invalidity or unenforceability, and the remainder of
                    these Terms of Service shall continue in full force and effect. In the event of any conflict between any provision of these Terms of Service
                    and any applicable law, the provision or provisions of these Terms of Service affected shall be modified to remove such conflict and permit
                    compliance with such law and as so modified these Terms of Service shall continue in full force and effect. Neither these Terms of Service
                    nor Customer’s right to use the Illuminate Software may be assigned without the consent of Brooks Bell.
                  </p>
                </li>
                <li>
                  <p>
                    Brooks Bell may, in its sole discretion, amend or modify these Terms of Service from time to time and continued use of the Illuminate
                    Software shall be evidence of Customer’s consent to any such amended or modified Terms of Service.
                  </p>
                </li>
              </ol>
              <p>Effective April 22, 2020</p>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const page = PublicPage(TermsOfService);

export { page as TermsOfService };
